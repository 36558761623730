import React from 'react'
import { styled } from '@mui/system';
import { Box,Button,Typography,Link } from "@mui/material";
import Lottie from 'react-lottie-player';
import animation from '../../../../../assets/animation'
import icons from '../../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { PORTAL_CONTACTS } from '../../../../../lib/constants/routes';
const CustomContainer = styled('div')({
  paddingTop:"3rem",
  display: 'grid',
  flexDirection: 'row'
});

export default function MemberConnectedComponent({
  hasConnectedBefore,
  ownerSerialNo,
  profilePicture,
  memberProfilePicture,
  receiverName,
  memberName,
  memberContact,
  accessPath,
  handleDone,
  profileTheme,
}){
  const navigate = useNavigate();
  const handleSaveContact = () =>{
    var contact = {
      name: memberName,
      phone: memberContact,
    };
    // create a vcard file
    var vcard = "BEGIN:VCARD\nVERSION:3.0\nFN:" + contact.name + "\nTEL;TYPE=work,voice:" + contact.phone + "\nEND:VCARD";
    var blob = new Blob([vcard], { type: "text/vcard" });
    var url = URL.createObjectURL(blob);
    
    const newLink = document.createElement('a');
    newLink.download = contact.name + ".vcf";
    newLink.textContent = contact.name;
    newLink.href = url;
    
    newLink.click();
  }

  return <>

  <CustomContainer>
<Box style={{position:'relative'}}>

<Lottie  
    animationData={animation.exchange_connected}      
    play
    style={{ zIndex: "-1" }}/>
<table style={{top:'30%',left:"20%",position: 'absolute',}}> 
  <tbody>
  <tr>
    <td style={{paddingRight:'10px'}}>  <Box style={{
    width: '120px',
    height: '120px',
    flexShrink: 0,
    borderRadius: '50%',
    background: 'var(--neutral-100, #FFF)',
    backgroundImage: `url(${profilePicture || icons.profile_picture_icon})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    border: `3px solid ${profileTheme?.primary_color || '#FECD20'}`
  }}>  </Box></td>
    <td style={{paddingLeft:'10px'}}>  <Box style={{
    width: '120px',
    height: '120px',
    flexShrink: 0,
    borderRadius: '50%',
    background: 'var(--neutral-100, #FFF)',
    backgroundImage: `url(${memberProfilePicture || icons.profile_picture_icon})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    border: `3px solid ${profileTheme?.primary_color || '#FECD20'}`
  }}></Box></td>
  </tr>
  </tbody>
</table>



  {/* <h1 style={{
    position: 'absolute',
    bottom: '0px',
    left: '139px'
  }}>
  Congrats!
  </h1> */}
</Box>
      <Box style={{
        display: 'grid',
        justifyItems: 'center',
        paddingBottom: '3rem'
      }}>
        <h1>
          {hasConnectedBefore ? 'You’re already connected' : 'You’re now connected!'}
        </h1>
        {hasConnectedBefore ? <Typography style={{
          fontSize: '14px', fontWeight: '400', width: '350px',
          textAlign: 'center'
        }}>
          <span style={{ fontWeight: '500' }}>{receiverName}</span> is already in your connections.
          Stay connected to enjoy each other’s latest live updates
        </Typography> : <Typography style={{
          fontSize: '14px', fontWeight: '400', width: '350px',
          textAlign: 'center'
        }}>
          <span style={{ fontWeight: '500' }}>{receiverName}</span> is now in your connections.
          Stay connected to enjoy each other’s latest live updates
        </Typography>}
      </Box>

    <Box style={{
      display: 'grid',
      justifyItems: 'center'
    }}>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDone}
            sx={{ mt: 2, mb:2, gap:'1rem',borderRadius: '30px',width:'280px',height:'52px', 
            backgroundColor: profileTheme?.button?.secondary_color || '#373736',
            color: profileTheme?.button?.secondary_font_color || '#FECD20' }}
          >
            Done
          </Button>
          {memberContact ?
          <Button
            type="button"
            fullWidth
            variant="outlined"
            sx={{ borderRadius: '30px',width:'280px',height:'52px', 
            border: `1px solid ${profileTheme?.primary_color ||'#FECD20'}`,
            color: profileTheme?.primary_color ||'#FECD20' }}
            onClick={() => handleSaveContact()}
          >
            Save Contact
          </Button>:
          <></>
          }
    </Box>
    <Box style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '4rem'
    }}>
      <Typography style={{ fontSize: '17px', fontWeight: '400' }}>
        <Link onClick={() => navigate(PORTAL_CONTACTS)} sx={{ cursor: 'pointer', color: "#000000" }}>Manage your contacts</Link>
      </Typography>
    </Box>
</CustomContainer>
</>
}