// ResponsiveDialog.js
import React from 'react';
import { styled } from '@mui/system';
import { Dialog, DialogTitle, Typography, Box, Button } from '@mui/material';

const DialogMessage = styled('div')({
  display: 'grid',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center'
});

const DialogActions = styled('div')({
  display: 'flex',
  gap: '1rem',
  padding: '0 1rem',
  justifyContent: 'center' 
})


const DeleteDialog = ({isOpen,content,handleConfirm,handleClose}:{
  isOpen: boolean,
  content: {
    title: string,
    description: string,
    fullWidth: boolean
  },
  handleConfirm: () => void,
  handleClose: () => void
}) => {
  
  const onSubmit = () => {
    handleConfirm();
  }

  return (
    <Dialog fullWidth={content.fullWidth} onClose={handleClose} open={isOpen}  PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' }}}>
      <DialogTitle style={{display:'flex',flexDirection: 'row',justifyContent: 'center'}}>
        <Typography style={{
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '500',
          textAlign: 'center',
          overflowWrap: 'break-word'
        }}>{content.title}</Typography> 
        </DialogTitle>
        <Box >
      <DialogMessage >
      <Typography sx={{maxWidth: "70%"}}align="center">
        {content.description}
      </Typography>
      
      </DialogMessage>
      <DialogActions>
      <Button
          type="button"
          fullWidth
          onClick={() => handleClose()}
          variant="outlined"
          sx={{ mt: 3, mb: 2, maxWidth:"150px",borderRadius: '30px',border: "solid #FECD20", background:"white", color: '#373736',
          "&:hover": {
            backgroundColor: "#373736",
            color:"#FECD20"
          } }}
          style={{
            
          }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          fullWidth
          onClick={() => onSubmit()}
          variant="contained"
          sx={{ mt: 3, mb: 2, maxWidth:"150px",borderRadius: '30px', background:"#373736", color: '#FECD20',
          "&:hover": {
            backgroundColor: "#FECD20",
            color:"#373736"
          } }}
          style={{
            
          }}
        >
          Yes
        </Button>
      </DialogActions>
      </Box>
    </Dialog>
  );
    };


  export default DeleteDialog;


