import axios from "axios";

interface Variation {
  variations: string;
  front_thumbnail: string;
  back_thumbnail: string;
  sample_thumbnail:string;
  sample_view_side:string;
  uuid: string;
}

interface Product {
  card_name: string;
  description: string;
  price: number;
  discount_price: number;
  customization_price: number;
  customization_discount_price: number;
  variation: Variation[];
  orientation: any;
  source: any;
}

interface Data {
  success: boolean;
  code: number;
  message: string;
  data: Product;
}

const getProductDetails = (product_uuid: string, base64: boolean = false): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get(`product/getProductDetails?product_uuid=${product_uuid}${base64 ? '&base64=1' : ''}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default getProductDetails;