import React from 'react'
import NotificationBar from './notificationBar.component'
export default function NotificationBarContainer(){  
 
  let authed = false;
  if(JSON.parse(localStorage.getItem('user')) != null){
    const user = JSON.parse(localStorage.getItem('user'));
    authed = user.access_token !== null || user.access_token !== ''

    const sessionExpiration = user.session_expiration;
    var datetime = new Date(0);
    datetime.setUTCSeconds(sessionExpiration)
    if(datetime < Date.now()) authed = false
  }
  return <NotificationBar authed={authed}/>
}