import axios from "axios";
import { VoucherCodeStatistic } from "../../lib/types/Voucher";
interface Data{
  code: number,
  data:  VoucherCodeStatistic,
  message: string | null,
  success: boolean
}

const getVoucherCodeStatistic = ({voucherUUID}:{voucherUUID: string | null}): Promise<Data> => {
    const user = JSON.parse(localStorage.getItem('user') || "")
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${user.access_token}`;
    
    const params = voucherUUID ? new URLSearchParams([['voucher_uuid',voucherUUID]]) : {};
    
    return new Promise((resolve, reject) => {
        axios.get("campaign/getVoucherCodeStatistic", {params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getVoucherCodeStatistic;
