import { createSlice } from '@reduxjs/toolkit';
import { getList } from './campaign.action';
import { Campaign } from '../../lib/types/Voucher';
const initialState = {
  name: "Campaign Name",
  list: [] as Array<Campaign>
};

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignName: (state, action) => {
      const { payload } = action;
      state.name = payload;
    }
    
  },
  extraReducers: (builder) => {
    builder.addCase(getList.fulfilled, (state, action) => {
      const { payload } = action;
      state.list = payload.data as Array<Campaign>;
    });
    builder.addCase(getList.rejected, (state, action) => {
      state.list = [];
    });
  }
});

export const { setCampaignName } = campaignSlice.actions;
export const campaignReducer = campaignSlice.reducer;
