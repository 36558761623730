import axios from "axios";

interface Data {
  success: boolean;
  code: number;
  message: string;
  data: {
    access_right: string[];
    access_limit: {
      profile: number;
      contact: number;
    },
    trialled : boolean,
    subscription_id : number,
    end_date: string,
    subscription_type: string,
    subscription_duration: number,
    selected_subscriptions_id: number
    previous_subscriptions_id: number;
  };
}

const getAccessLimit = (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get('auth/getAccessRightAndLimit')
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default getAccessLimit;