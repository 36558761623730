import { useEffect } from "react";
import NotificationMenuComponent from "./notificationMenu.component";
import { useLocation, useNavigate } from "react-router-dom";
import { NOTIFICATIONS, PORTAL } from "src/lib/constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { setNotifications } from "../../features/notifications/notifications.slice";
import notificationApi from '../../api/notification';

interface NotificationMenuContainerProps {
  anchorEl: null | HTMLElement;
  handleClose: () => void;
}

function NotificationMenuContainer({ anchorEl, handleClose }: NotificationMenuContainerProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const notifications = useSelector((state: any) => state.notifications.notifications);

  const handleMarkAsRead = () => {
    const uuid_list = notifications.map((noti: any) => noti.uuid);
    notificationApi.markAsRead(uuid_list)
      .then(() => dispatch(setNotifications(notifications.map((noti: any) => ({
        ...noti,
        is_read: true,
      })))));

  }

  const handleViewAll = () => {
    navigate(`${PORTAL}/${NOTIFICATIONS}`);
  }

  const handleAction = (path: string) => {
    navigate(path);
  }

  useEffect(() => {
    notificationApi.getList()
      .then(res => {
        if (!Boolean(res)) return;

        dispatch(setNotifications(res.data.map((noti: any) => ({
          ...noti,
          is_checked: false,
          is_read: noti.read === '1' || noti.read === 1,
          description: noti.message,
          timestamp: noti.created_at,
          action: null,
        }))));
      });

    // dispatch(setNotifications([
    //   {
    //     uuid: 'abc123',
    //     is_checked: false,
    //     is_read: false,
    //     title: 'Happy Friendversary!',
    //     description: 'You became friends on PixelCard aeons ago',
    //     timestamp: '2024-04-16 17:00:00',
    //     action: '/portal/contact/61066e2c044e4bb4b760fc768f0797bd/details',
    //   },
    //   {
    //     uuid: '456zxc',
    //     is_checked: false,
    //     is_read: true,
    //     title: "Congrats! You've collected a voucher",
    //     description: 'Check it out in your wallet',
    //     timestamp: '2024-04-15 22:00:00',
    //     action: '/portal/wallet',
    //   },
    // ]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return <NotificationMenuComponent
    notifications={notifications}
    anchorEl={anchorEl}
    handleClose={handleClose}
    handleMarkAsRead={handleMarkAsRead}
    handleViewAll={handleViewAll}
    handleAction={handleAction}
  />
}

export default NotificationMenuContainer;