import axios from "axios";

interface Data {
  success: boolean;
  code: string;
  message: string;
  data: any;
}

interface BusinessContact {
  fullname: string;
  company_name: string;
  email: string;
  phone: string;
  employees_range : string;
}
const addBusinessContact = (requestBody: BusinessContact): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.post("subscription/businessContact", requestBody)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default addBusinessContact;