import { Box, Button, Typography } from "@mui/material";

interface PlaceholderProps {
  icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  title: string;
  description?: string;
  buttonText?: string;
  action?: () => void;
}

export default function Placeholder({ icon, title, description, buttonText, action }: PlaceholderProps) {
  return <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Box sx={{
      width: '65px',
      height: '65px',
      bgcolor: '#F0F0F0',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      mb: '20px',
    }}>
      <div className='icon' style={{
        backgroundColor: '#697077',
        mask: `url(${icon})`,
        maskSize: '35px 35px',
        maskRepeat: 'no-repeat',
        WebkitMask: `url(${icon})`,
        WebkitMaskSize: '35px 35px',
        WebkitMaskRepeat: 'no-repeat',
        width: '35px',
        height: '35px',
      }} />
    </Box>
    <Typography component={'p'} sx={{ color: '#697077', textAlign: 'center', fontSize: '20px', fontWeight: 600 }}>{title}</Typography>
    <Typography component={'p'} sx={{ color: '#697077', textAlign: 'center', fontSize: '13px' }}>{description}</Typography>
    {Boolean(action) && <Button
      variant="outlined"
      sx={{ mt: '20px', color: '#697077', borderColor: '#E0E0E0', borderRadius: '5px' }}
      onClick={action}
    >
      {buttonText}
    </Button>}
  </Box>
} 