import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import animation from '../../assets/animation';
import { Backdrop, Box, Button, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { endLoading } from '../../features/loading';
import { createTheme, ThemeProvider } from '@mui/material/styles';

/**
 * Renders a loading component that displays a loading animation and a countdown timer.
 * If the countdown reaches 0, a button to navigate back to the homepage is displayed.
 */
export default function LoadingLandingPage() {
  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: '#FECD20',
      },
      secondary: {
        main: '#373736',
        contrastText: '#FECD20',
      },
      action: {
        selected: '#FECD20',
        selectedOpacity: 0.08,
      },
    }
  })
  const open = useSelector(state => state.loading.landingPage.open);
  const dispatch = useDispatch();
  const [timeout, setTimeout] = useState(false);
  const [countdown, setCountdown] = useState(10); // Set the initial countdown time in seconds
  const navigate = useNavigate();

  const exitPage = () => {
    setTimeout(false)
    dispatch(endLoading())
    navigate(0)
    // navigate(PORTAL)
  }
  useEffect(() => {
    if (open) {
      const intervalId = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [open]);

  useEffect(() => {
    if (countdown === 0) {
      setTimeout(true);
    }
  }, [countdown, dispatch]);

  return <ThemeProvider theme={defaultTheme}>
    <Backdrop open={open} sx={{ color: '#fff', zIndex: 9999 }}>
      <Lottie
        animationData={animation.loading}
        play
        style={{ zIndex: "-1", maxWidth: "250px", maxHeight: "250px" }}
      />
      {timeout && <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography style={{ maxWidth: "500px", color: "white", textAlign: "center" }}>
          Oops! It looks like things are taking longer than usual. Our system might be having trouble fetching the data. Please try again in a moment.
        </Typography>
        <Button variant="contained" color="secondary" sx={{ maxWidth: "150px", marginTop: "1rem" }} onClick={() => exitPage()}>Refresh</Button>
      </Box>}
    </Backdrop>
  </ThemeProvider>
}