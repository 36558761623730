import React from "react";
import { Avatar, Box, Button, Card, Dialog, Divider, Grid, Slide, TextField, Typography, styled, Select, MenuItem } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from "@mui/material/transitions";
import icons from '../../../../assets/icons';
import jobTitles from '../../../../lib/constants/jobTitles';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const StyledButton = styled(Button)({
  borderRadius: '30px',
  backgroundColor: '#FECD20',
  color: 'black',
  '&:hover': {
    backgroundColor: '#FFC700',
    color: 'black',
  }
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Profile {
  uuid: string;
  name: string;
}

function CreateDialogComponent({
  open,
  handleClose,
  handleSubmit,
  image,
  handleImageChange,
  guestUuid = '',
  contactUuid = '',
  viewOnly = false,
  profiles,
  errors,
  isEditing,
  setIsEditing,
  selectedProfileUUID,
  setSelectedProfileUUID,
  name,
  setName,
  phoneNumbers,
  setPhoneNumbers,
  birthDate,
  setBirthDate,
  email,
  setEmail,
  company,
  setCompany,
  jobTitle,
  setJobTitle,
  otherTitle,
  setOtherTitle,
  website,
  setWebsite,
  address,
  setAddress,
}) {
  return <Dialog
    sx={{ top: { xs: 0, sm: "10%" } }}
    PaperProps={{
      sx: {
        borderTopLeftRadius: { xs: 0, sm: '16px' },
        borderTopRightRadius: { xs: 0, sm: '16px' },
        overflow: 'clip',
        p: {
          xs: '50px 20px 0 20px',
          md: '50px 90px 0 90px',
        }
      }
    }}
    fullScreen
    open={open}
    onClose={() => {
      setIsEditing(false);
      handleClose(false);
    }}
    scroll="paper"
    TransitionComponent={Transition}
  >
    <Button onClick={() => {
      setIsEditing(false);
      handleClose(false);
    }} sx={{ position: 'absolute', top: '10px', right: 0, color: '#373736' }}>
      <CloseIcon />
    </Button>
    <div style={{ height: '40px' }}></div>

    {!isEditing && (contactUuid !== '' || guestUuid !== '') && <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Avatar
          src={image !== undefined ? typeof image === 'string' ? image : URL.createObjectURL(image) : undefined}
          sx={{
            width: 86,
            height: 86,
            marginRight: '20px',
          }}
        />
        <span>
          <Typography component='div' variant='h5' style={{ fontSize: '25px', fontWeight: 700 }}>
            {name}
          </Typography>
          <Typography component='div' variant='h5' style={{ fontSize: '16px', fontWeight: 400 }}>
            {email}
          </Typography>
        </span>
        <span style={{ flex: 1 }}></span>
        {!viewOnly &&
          <StyledButton variant="contained" onClick={() => setIsEditing(true)}>
            Edit Info
          </StyledButton>
        }
      </div>

      <Card sx={{ overflow: 'visible', padding: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <img src={icons.user_circle_icon} alt="user icon" style={{ marginRight: '10px' }} />
          <Typography component='span' variant='h5'>
            User Details
          </Typography>
        </div>

        <Divider />

        <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
          <Typography component='span' variant='h5' sx={{ flex: 3 }}>
            Full name:
          </Typography>
          <Typography component='span' variant='h5' sx={{ flex: 7 }}>
            {name}
          </Typography>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <Typography component='span' variant='h5' sx={{ flex: 3 }}>
            Phone Number:
          </Typography>
          <Typography component='span' variant='h5' sx={{ flex: 7 }}>
            {phoneNumbers}
          </Typography>
        </div>

        <div style={{ display: 'flex' }}>
          <Typography component='span' variant='h5' sx={{ flex: 3 }}>
            Date of Birth:
          </Typography>
          <Typography component='span' variant='h5' sx={{ flex: 7 }}>
            {birthDate}
          </Typography>
        </div>
      </Card>

      <Card sx={{ overflow: 'visible', padding: '20px', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <img src={icons.building_03_icon} alt="user icon" style={{ marginRight: '10px' }} />
          <Typography component='span' variant='h5'>
            Company Details
          </Typography>
        </div>

        <Divider />

        <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
          <Typography component='span' variant='h5' sx={{ flex: 3 }}>
            Job Title:
          </Typography>
          <Typography component='span' variant='h5' sx={{ flex: 7 }}>
            {jobTitle}
          </Typography>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <Typography component='span' variant='h5' sx={{ flex: 3 }}>
            Website:
          </Typography>
          <Typography component='span' variant='h5' sx={{ flex: 7 }}>
            {website}
          </Typography>
        </div>

        <div style={{ display: 'flex', marginBottom: '10px' }}>
          <Typography component='span' variant='h5' sx={{ flex: 3 }}>
            Company Name:
          </Typography>
          <Typography component='span' variant='h5' sx={{ flex: 7 }}>
            {company}
          </Typography>
        </div>

        <div style={{ display: 'flex' }}>
          <Typography component='span' variant='h5' sx={{ flex: 3 }}>
            Company Address:
          </Typography>
          <Typography component='span' variant='h5' sx={{ flex: 7 }}>
            {address}
          </Typography>
        </div>
      </Card>
    </React.Fragment>}

    {(isEditing || (contactUuid === '' && guestUuid === '')) && <React.Fragment>
      <Typography gutterBottom component='h1' variant='h1' sx={{ fontSize: '31px', fontWeight: 400 }}>
        {contactUuid === '' ? 'Create Contact' : 'Edit Contact'}
      </Typography>
      <Divider sx={{ borderColor: '#FECD20', mb: '40px' }} />

      <Box component={'form'} onSubmit={handleSubmit} sx={{ overflowY: 'scroll', pb: '90px' }} noValidate>
        <Grid container spacing={3} sx={{ maxWidth: '700px' }}>
          <Grid item xs={12}>
            <Typography component='label' htmlFor='title' variant='h5'>
              Select Profile
            </Typography>
            <Select
              id="profile"
              value={selectedProfileUUID}
              sx={{ marginTop: '0.6rem', '&:focus': { border: 'solid #FECD20' } }}
              fullWidth
              onChange={e => setSelectedProfileUUID(e.target.value)}
            >
              {profiles.map((prf: Profile) => <MenuItem key={prf.uuid} value={prf.uuid}>{prf.name}</MenuItem>)}
            </Select>
            <Typography component='label' className='validation-error' variant='h5'>
              {errors.profile}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component='label' htmlFor='description' variant='h5'>
              Profile Image
            </Typography>
            <div style={{ display: 'flex' }}>
              <Avatar src={Boolean(image) ? typeof image === 'string' ? image : URL.createObjectURL(image) : icons.profile_picture_icon} sx={{ width: 86, height: 86 }} />
              <Button
                component="label"
                variant="outlined"
                sx={{
                  margin: 3,
                  height: '40px',
                  border: '1px solid #FECD20',
                  borderRadius: '8px',
                  color: 'black'
                }}>
                Upload image
                <VisuallyHiddenInput type="file" onChange={handleImageChange} accept="image/png, image/jpeg" />
              </Button>
            </div>
            <Typography component='h5' variant='h5' sx={{ color: '#AAAAAA' }}>
              Supported file type: PNG and JPEG only. Max file size: 12 MB
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component='label' htmlFor='name' className='required' variant='h5'>
              Full name
            </Typography>
            <TextField
              color='primary'
              margin="dense"
              required
              fullWidth
              name="name"
              autoComplete="name"
              value={name}
              onChange={e => setName(e.target.value)}
              autoFocus
            />
            <Typography component='label' className='validation-error' variant='h5'>
              {errors.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography component='label' htmlFor='name' className="required" variant='h5'>
              Phone Number
            </Typography>
            {phoneNumbers.map((num, i) => <Box key={i} sx={{ display: 'flex' }}>
              <TextField
                color='primary'
                margin="dense"
                fullWidth
                name="phone"
                autoComplete="phone"
                type="tel"
                value={num}
                required
                onChange={e => {
                  let nums = [...phoneNumbers];
                  nums[i] = e.target.value;
                  setPhoneNumbers(nums);
                }}
              />
              {i === 0 && <Button color="secondary" disabled={phoneNumbers.length >= 3} onClick={() => setPhoneNumbers([...phoneNumbers, ''])}>
                + Add
              </Button>}
              {i > 0 && <Button color="secondary" onClick={() => setPhoneNumbers([...phoneNumbers.slice(0, i), ...phoneNumbers.slice(i + 1)])}>
                <div className='icon' style={{ background: '#EB3325', height: '24px', width: '24px', mask: `url(${icons.delete_02})` }} />
              </Button>}
            </Box>)}
            <Typography component='label' className='validation-error' variant='h5'>
              {errors.phone}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component='label' htmlFor='name' className="required" variant='h5'>
              Email
            </Typography>
            <TextField
              color='primary'
              margin="dense"
              fullWidth
              name="email"
              autoComplete="email"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
            />
            <Typography component='label' className='validation-error' variant='h5'>
              {errors.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component='label' htmlFor='name' variant='h5'>
              Date of Birth
            </Typography>
            <TextField
              color='primary'
              margin="dense"
              fullWidth
              name="birthdate"
              type="date"
              value={birthDate}
              onChange={e => setBirthDate(e.target.value)}
            />
            <Typography component='label' className='validation-error' variant='h5'>
              {errors.birthday}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component='label' htmlFor='company' variant='h5'>
              Company Name
            </Typography>
            <TextField
              color='primary'
              margin="dense"
              fullWidth
              name="company"
              value={company}
              onChange={e => setCompany(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component='label' htmlFor='title' variant='h5'>
              Job Title
            </Typography>
            <Select
              id="jobTitle"
              value={jobTitle}
              sx={{
                marginTop: '0.6rem',
                '&:focus': {
                  border: 'solid #FECD20'
                },
              }}
              fullWidth
              onChange={e => setJobTitle(e.target.value)}
            >
              {jobTitles.map((value, index) => <MenuItem key={index} value={value}>{value}</MenuItem>)}
            </Select>
          </Grid>
          {jobTitle === 'Others' && <Grid item xs={12} sm={6}>
            <Typography component='label' htmlFor='title' className='required' variant='h5'>
              Please specify job title
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              id="othertitle"
              name="othertitle"
              value={otherTitle}
              onChange={e => setOtherTitle(e.target.value)}
            />
            <Typography component='label' className='validation-error' variant='h5'>
              {errors.other_title}
            </Typography>
          </Grid>}
          <Grid item xs={12}>
            <Typography component='label' htmlFor='title' variant='h5'>
              Website
            </Typography>
            <TextField
              color='primary'
              margin="dense"
              fullWidth
              name="website"
              type="url"
              value={website}
              onChange={e => setWebsite(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography component='label' htmlFor='address' variant='h5'>
              Company Address
            </Typography>
            <TextField
              color='primary'
              margin="dense"
              fullWidth
              multiline
              rows={4}
              name="address"
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: '30px' }}
            >
              {contactUuid === '' ? 'Create Contact' : 'Edit Contact'}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>}
  </Dialog>
};

export default CreateDialogComponent;