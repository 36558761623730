import React from "react";
import { Box, Typography, Radio } from "@mui/material"
export default function CheckCard({title, description, onClick,checked = false,background = "#EEEEE4",id=null}:
  {title:string, description: string, checked:boolean,background: string, onClick:()=>void,id?:string | undefined | null}) {
  return <Box 
    component="div" // Add the missing component property
    key={id}
    sx={{
    padding: '1rem 2rem 2rem 1.5rem',
    borderRadius:'8px',
    border: checked ? '1px solid #858549' : 'none',
    background: background,
    transition: 'border 0.3s ease-in-out',
    '&:hover':{
      border:'1px solid #858549',
    },
    width:"300px" }} onClick={() => onClick()}>
    <Radio
    checked={checked}
    sx={{ color: checked ? "#26A800 !important" : "" }}/>
    <Typography variant="h3">{title}</Typography>
    <Typography variant="body1" sx={{lineHeight:'1.2'}}>{description}</Typography>
  </Box>
}