import React, {FC, useEffect} from 'react';
import ProfileDetailsComponent from './profileDetails.component';
import {useDispatch} from 'react-redux';
import { getList } from '../../../../features/campaign/campaign.action';
const ProfileDetailsContainer: FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getList());
        // eslint-disable-next-line
    }, []);
    return (
        <div>
            <ProfileDetailsComponent></ProfileDetailsComponent>
        </div>
    );
};

export default ProfileDetailsContainer;
