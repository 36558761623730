import verifyEmail from "./verifyEmail.api";
import resetPassword from "./resetPassword.api";
import getResetPasswordToken from './getResetPasswordToken.api'
import checkSession from './checkSession.api'
import resendEmailVerification from './resendEmailVerification.api'
import resendGuestEmailVerification from "./resendGuestEmailVerification.api";

const accountApi = {
  getResetPasswordToken,
  verifyEmail,
  resetPassword,
  checkSession,
  resendEmailVerification,
  resendGuestEmailVerification
}

export default accountApi 
