import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Checkbox, Tooltip } from "@mui/material";
import icons from "../../../../assets/icons";
import productApi from "../../../../api/product";
import { CART, PORTAL_CARDS } from "../../../../lib/constants/routes";
import { useNavigate } from "react-router-dom";
import FileHelper from "../../../../lib/helper/fileHelper";

export default function CartItem({ currency, item, index, handleDelete, handleEdit, handlePreview, togglePixelCare, getShoppingCartListing }) {
  const [imgUrl, setImgUrl] = useState<string>()
  const navigate = useNavigate();
  const handleQuantity = (quantity) => {
    const cartUUID = item.cart_uuid;
    //if quantity is less than 1, delete the item
    if (quantity < 1) {
      handleDelete(index, cartUUID);
      return;
    }

    const requestBody = {
      back_thumbnail: item?.variant?.back,
      card_name: item.name,
      card_variation: item?.variant?.name,
      cart_uuid: cartUUID,
      customization: item.customization,
      font_color_code: item.fontColor,
      front_thumbnail: item?.variant?.front,
      logo_url: item.logoUrl,
      name: item.personName,
      pixelcare: item.pixelcare,
      position: item.position,
      product_uuid: item.product_uuid,
      quantity: quantity,
    };
    productApi.editCart({ requestBody })
      .then(() => navigate(`${PORTAL_CARDS}/${CART}`))
      .catch(console.log).finally(() => getShoppingCartListing());
  }

  // Get URL for thumbnail image, if needed
  useEffect(() => {
    if (item.variant.front.slice(0, 5) !== 'https') {
      FileHelper.getUrl(item.variant.front).then(setImgUrl);
    } else {
      setImgUrl(item.variant.front);
    }
  }, [item]);

  return <Box key={item?.cart_uuid} sx={{ bgcolor: { xs: 'white', sm: '#FBFAFA' }, borderRadius: '8px', p: '20px' }}>
    <Box sx={{ display: 'flex', gap: '15px', mb: '10px' }}>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{
          flex: 1,
          position: 'relative',
          borderRadius: '8px',
          border: '1px solid #C5C5C5',
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': { bgcolor: '#F9FAFC' },
        }} onClick={() => handlePreview(item)}>
          <div className='icon' style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: '#A7A7A7',
            mask: `url(${icons.image})`,
            maskSize: 'cover',
            maskRepeat: 'no-repeat',
            WebkitMask: `url(${icons.image})`,
            WebkitMaskSize: 'cover',
            WebkitMaskRepeat: 'no-repeat',
            width: '24px',
            height: '24px',
          }} />
          <Box boxShadow={Boolean(item.designid) ? undefined : 2} sx={{
            // Items from Zakeke will have designid
            m: Boolean(item.designid) ? 0 : '20px',
            borderRadius: '8px',
            overflow: 'clip',
          }}>
            <img
              src={imgUrl}
              alt="product thumbnail"
              style={{
                display: 'block',
                maxWidth: '100%',
                maxHeight: '100%',
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            size="small"
            color="warning"
            onClick={handleDelete}
            startIcon={<div className='icon' style={{
              backgroundColor: '#ED6C02',
              mask: `url(${icons.trash_icon_btn})`,
              maskSize: 'cover',
              maskRepeat: 'no-repeat',
              WebkitMask: `url(${icons.trash_icon_btn})`,
              WebkitMaskSize: 'cover',
              WebkitMaskRepeat: 'no-repeat',
              width: '8px',
              height: '10px',
            }} />}
            sx={{ mt: '5px' }}
          >
            Delete
          </Button>
          <Button
            size="small"
            color="secondary"
            onClick={handleEdit}
            startIcon={<div className='icon' style={{
              backgroundColor: '#373736',
              mask: `url(${icons.edit_02})`,
              maskSize: 'cover',
              maskRepeat: 'no-repeat',
              WebkitMask: `url(${icons.edit_02})`,
              WebkitMaskSize: 'cover',
              WebkitMaskRepeat: 'no-repeat',
              width: '12px',
              height: '12px',
            }} />}
            sx={{ mt: '5px' }}
          >
            Edit
          </Button>
        </Box>
      </Box>
      <Box sx={{ flex: 2 }}>
        <Typography fontWeight={500} sx={{ mb: '10px' }}>{item.name}</Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', mb: '5px' }}>
          <Typography sx={{ flex: 1 }}>Price ({currency}):</Typography>
          <Typography sx={{ flex: 1, textAlign: { xs: 'right', sm: 'left' } }}>{(parseFloat(item.price) * item.quantity).toFixed(2)}</Typography>
        </Box>
        {
          Boolean(item.customization) && <Box key={item.totalCustomizationPrice} sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', mb: '5px' }}>
            <Typography sx={{ flex: 1 }}>Customization ({currency}):</Typography>
            <Typography sx={{ flex: 1, textAlign: { xs: 'right', sm: 'left' } }}> {(parseFloat(item.totalCustomizationPrice)).toFixed(2)}</Typography>
          </Box>
        }
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', mb: '5px' }}>
          <Typography sx={{ flex: 1 }}>Quantity:</Typography>
          <Box sx={{ display: 'flex', textAlign: 'center', ml: { xs: 'auto', sm: 0 }, mr: { xs: '0', sm: 'auto' } }}>
            <img src={icons.minus_sign_circle} alt="minus"
              style={{ cursor: 'pointer' }}
              onClick={() => handleQuantity(item.quantity - 1)} />
            <Typography sx={{ flex: 1, mx: '10px' }}>{item.quantity}</Typography>
            <img src={icons.plus_sign_circle} alt="plus" style={{ cursor: 'pointer' }} onClick={() => handleQuantity(item.quantity + 1)} />
          </Box>

        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', mb: '5px' }}>
          <Typography sx={{ flex: 1 }}>Variant:</Typography>
          <Typography sx={{ flex: 1, textAlign: { xs: 'right', sm: 'left' } }}>{item.variant.name}</Typography>
        </Box>
        {Boolean(item.customization) && <>
          {Boolean(item.personName) &&
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', mb: '5px' }}>
              <Typography sx={{ flex: 1 }}>Name:</Typography>
              <Typography sx={{ flex: 1, textAlign: { xs: 'right', sm: 'left' } }}>{item.personName}</Typography>
            </Box>
          }

          {Boolean(item.jobTitle) &&
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', mb: '5px' }}>
              <Typography sx={{ flex: 1 }}>Job title:</Typography>
              <Typography sx={{ flex: 1, textAlign: { xs: 'right', sm: 'left' } }}>{item.jobTitle}</Typography>
            </Box>
          }
        </>}
      </Box>
    </Box>
    <Box sx={{ display: 'flex' }}>
      <Checkbox name="pixelcare" checked={Boolean(Number(item.pixelcare))} sx={{ p: 0, mr: '10px' }} onClick={togglePixelCare} />
      <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <Typography component={'span'} sx={{ mr: '10px 5px 10px 10px' }}>Protect with <Typography component={'span'} sx={{ fontWeight: '600' }}>PixelCare+</Typography> </Typography>
        <Tooltip title='PixelCare+ is committed to provide a reliable and satisfying experience for our customers. Our warranty policy ensures the protection of your investment in Pixel Card.'
          placement='top' arrow><img src={icons.info} alt="plus" style={{ cursor: 'pointer', margin: '10px' }} /></Tooltip>
        <Typography component={'span'} sx={{ textAlign: 'start', fontSize: '13px', fontWeight: 400, color: '#A7A7A7', textDecoration: 'line-through', mr: '5px' }}>+{currency}{item.pixelcarePrice}</Typography>
        <Typography component={'span'} sx={{ flex: 1, textAlign: 'start', fontSize: '13px', fontWeight: 400, color: '#26A800' }}>+{currency}{item.pixelcareDiscountPrice} x {item.quantity}</Typography>
      </Box>
    </Box>
  </Box>
}