import addProfile from "./addProfile.api";
import updateProfile from "./updateProfile.api";
import getProfileDetails from "./getProfileDetails.api";
import getProfilesDropdown from "./getProfilesDropdown.api"
import getList from './getList.api'
import deleteProfile from './deleteProfile'
import editProfileStatus from './editProfileStatus'
import saveCalendarId from './saveCalendarId.api';
import getPublicName from "./getPublicName.api"
import editPublicName from "./editPublicName.api"
import updateAppointmentStatus from "./updateAppointmentStatus.api"
import getCampaignList from './getCampaignList.api'
import getActiveList from './getActiveList.api'
import updateVoucherStatus from "./updateVoucherStatus.api"
import getVoucherStatus from "./getVoucherStatus.api"

const profileApi = {
  addProfile,
  updateProfile,
  getProfileDetails,
  getProfilesDropdown,
  getList,
  deleteProfile,
  editProfileStatus,
  saveCalendarId,
  getPublicName,
  editPublicName,
  updateAppointmentStatus,
  getCampaignList,
  getActiveList,
  updateVoucherStatus,
  getVoucherStatus
}

export default profileApi