import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Avatar,
  Popper,
  IconButton
} from '@mui/material';
import 'chart.js/auto';
import { ChartData } from 'chart.js/auto';
import { Doughnut, Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from './features/admin'
import dashboardApi from './api/dashboard'
import CreateDialog from './modules/contact-list/component/create-dialog';
import moment from 'moment';
import { startLoading, endLoading } from './features/loading';
import { BUY, CARDS, PORTAL_DASHBOARD } from './lib/constants/routes';
import myVoucherApi from './api/myVoucher';
import { CollectedVoucher } from './lib/types/Wallet';
import Coupon from './modules/wallet/component/coupon';
import icons from './assets/icons';
import profileApi from './api/profile';
import randomColor from "randomcolor";
import DashboardFilterDialog from "./components/dialog/dashboardFilterDialog";
import { Value } from 'react-date-picker/dist/cjs/shared/types';
import CardTile from './modules/my-cards/component/cardTile.component';
import cardApi from './api/card';
import FileHelper from './lib/helper/fileHelper';
import GoogleMap from './components/google-map/google-map';
import Placeholder from './components/placeholder/placeholder';
import { useNavigate } from 'react-router-dom';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Powered by © '}
      {/* <Link color="inherit" > */}
      PixelCard
      {/* </Link>{' '} */}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

let doughnutData = {
  labels: ['Guest', 'Member'],
  datasets: [
    {
      label: '# View',
      data: [0, 0],
      backgroundColor: [
        'rgba(254, 205, 32, 1)',
        'rgba(238, 131, 33, 1)',
      ],
      borderColor: [
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

let lineData: ChartData<"line", number[], string> = {
  labels: ['Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      fill: true,
      data: [0, 0, 0, 0, 0, 0, 0],
      backgroundColor: 'rgba(254, 205, 32, 0.2)',
      borderColor: 'rgba(238, 131, 33, 1)',
      borderWidth: 1,
      datalabels: {
        align: 'end',
        anchor: 'end'
      }
    },
  ],
};

let recentConnectData = [];

const TitleIcon = ({ icon }: { icon: string }) => (
  <Box sx={{
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: '#EE8321',
    borderRadius: '100%',
  }}>
    <div className='icon' style={{
      backgroundColor: '#FFFFFF',
      mask: `url(${icon})`,
      maskSize: '15px 15px',
      maskRepeat: 'no-repeat',
      WebkitMask: `url(${icon})`,
      WebkitMaskSize: '15px 15px',
      WebkitMaskRepeat: 'no-repeat',
      width: '15px',
      height: '15px',
    }} />
  </Box>
);

interface Profile {
  profile_uuid: string;
  name: string;
  color: string;
  imgURL: string;
}

export default function Dashboard() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [lineDataIndex, setLineDataIndex] = useState(0)
  const [doughnutIndex, setDoughnutIndex] = useState(0)
  const [recentlyConnectIndex, setRecentlyConnctIndex] = useState(0)
  const [contactOpen, setContactOpen] = useState(false)
  const [openFilter, setOpenFilter] = useState(false);
  const [memberContactUUID, setMemberContactUUID] = useState('')
  const [guestContactUUID, setGuestContactUUID] = useState('')
  const [collectedVouchers, setCollectedVouchers] = useState<Array<CollectedVoucher> | []>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = React.useState<Value>('');
  const [toDate, setToDate] = React.useState<Value>('');
  const [cards, setCards] = React.useState<any[]>([]);
  const [contactLocations, setContactLocations] = React.useState<any[]>([]);
  const [profileViewLocations, setProfileViewLocations] = React.useState<any[]>([]);
  const [mapIndex,setMapIndex] = useState(0);
  const [selectedProfileUUID,setSelectedProfileUUID] = useState('');
  const [selectedProfileName,setSelectedProfileName] = useState('All Profiles');
  const [selectedProfileImage,setSelectedProfileImage] = useState<any>(null);
  const [totalCountryPercentage,setTotalCountryPercentage] = useState<Array<any>>([]);
  const [totalStatePercentage,setTotalStatePercentage] = useState<Array<any>>([]);
  const [totalCityPercentage,setTotalCityPercentage] = useState<Array<any>>([]);
  const [isLoaded,setIsLoaded] = useState(false);
  
  const getList = async (start: string | null, end: string | null, selectedProfileUUID: string) => {
    dispatch(startLoading());
    await myVoucherApi.getList({ start: start, end: end, profileUuid: selectedProfileUUID })
      .then((response) => {
        if (response.success) {
          setCollectedVouchers(response.data);
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        dispatch(endLoading());
      })
  }

  const getCards = () => {
    dispatch(startLoading());
    cardApi.getCards().then(response => {
      const cards = response.data;

      if (Boolean(cards?.length)) {
        Promise.all(cards.map(async (card) => {
          let presigned_url = null;
          if (Boolean(card.font_thumbnail) && card.font_thumbnail?.slice(0, 5) !== 'https') {
            presigned_url = await FileHelper.getUrl(card.font_thumbnail);
          }

          return ({
            presigned_url,
            ...card,
          })
        })).then(setCards)
          .finally(() => {
            dispatch(endLoading());
          });
      }
    });
  }

  const bindProfile = (profile_uuid: string, card_serial_number: string) => {
    cardApi.bindProfile({
      requestBody: {
        card_serial_number,
        profile_uuid,
      }
    })
      .then(getCards)
      .catch(console.log)
      .finally(() => dispatch(endLoading()));
  }

  const getTotalProfileView = async (start: string | null, end: string | null, selectedProfileUUID: string) => {
    const response = await dashboardApi.getTotalProfileView({ start: start, end: end, profileUuid: selectedProfileUUID })
      .then(response => response)
      .catch(error => error)
    if (response.success) {
      const data = response.data.data;
      let member = 0
      let guest = 0

      data.map((value, index) => {
        if (value.name === 'Member') member = Math.round(member + value.count)

        if (value.name === 'Guest') guest = Math.round(guest + value.count)

        return value
      })
      doughnutData.datasets[0].data = [guest, member]
    }
    setDoughnutIndex(doughnutIndex + 1) // force update
    return response
  }
  const getTotalConnection = async (start: string | null, end: string | null, selectedProfileUUID:string) => {
    const response = await dashboardApi.getTotalConnection({ start: start, end: end, profileUuid: selectedProfileUUID })
      .then(response => response)
      .catch(error => error)

    if (response.success) {
      const data = response.data.data
      lineData.datasets[0].data = data.map((value, index) => {
        return value.count
      })
      lineData.labels = data.map((value, index) => {
        let day = moment(value.date, 'YYYY-MM-DD').format('dddd').substring(0, 3)
        return day
      })
    }
    setLineDataIndex(lineDataIndex + 1) // force update
    return response
  }
  const recentConnectionList = async (start: string | null, end: string | null, selectedProfileUUID:string) => {
    const response = await dashboardApi.recentConnectionList({ start: start, end: end, profileUuid: selectedProfileUUID})
      .then(response => response)
      .catch(error => error)

    if (response.success) {
      const data = response?.data || []
      recentConnectData = data;
    }
    setRecentlyConnctIndex(recentlyConnectIndex + 1)
    return response
  }

  const viewContactDetails = ({ memberUUID, guestUUID }) => {
    setMemberContactUUID(memberUUID)
    setGuestContactUUID(guestUUID)
    setContactOpen(true)
  }

  const getProfiles = async () => {
    dispatch(startLoading());
    const response = await profileApi.getList()
      .then(response => response)
      .catch(error => error)
      .finally(() => dispatch(endLoading()));

    if (response.success) {
      Promise.all(response.data.map(async (profile) => ({
        ...profile,
        color: randomColor(),
        imgURL: await FileHelper.getUrl(profile.profile_picture),
        name: profile.profile_name,
        uuid: profile.profile_uuid
      })))
        .then(setProfiles)
        .finally(() => dispatch(endLoading()));
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getName = () => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    setName(user.access_token_payload.name ?? '');
  }

  const getLocationList = (start: string | null, end: string | null,profileUUID: string | null) => {
    dashboardApi.getLocationList({
      start: start || null,
      end: end || null,
      profileUUID: profileUUID || null
    }).then(response => {
      if(response.success){
      setContactLocations(response.data.contact_locations);
      setProfileViewLocations(response.data.profile_view_locations);
      setTotalCountryPercentage(response.data.total_country_percentage.sort((a, b) => b.count - a.count));
      setTotalStatePercentage(response.data.total_state_percentage.sort((a, b) => b.count - a.count));
      setTotalCityPercentage(response.data.total_city_percentage.sort((a, b) => b.count - a.count));
      setMapIndex(mapIndex+1)
      }
    }).finally(() => setIsLoaded(true));
  }

  const handleReceive = (cardUuid) => {
    cardApi.receiveCard(cardUuid)
      .then(getCards);
  }

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [{
        name: 'Dashboard',
        path: PORTAL_DASHBOARD
      },
      {
        name: 'All',
        path: null

      }]
    }))

    dispatch(startLoading())
    getName()
    getProfiles()
    getCards();
    dispatch(endLoading())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const start = fromDate ? moment(`${fromDate}`).format('YYYY-MM-DD') : moment().subtract(7,'d').format('YYYY-MM-DD');
    const end = toDate ? moment(`${toDate}`).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    
    dispatch(startLoading());
    getTotalConnection(start, end, selectedProfileUUID);
    recentConnectionList(start, end, selectedProfileUUID);
    getTotalProfileView(start, end, selectedProfileUUID);
    getList(start, end, selectedProfileUUID);
    getLocationList(start,end,selectedProfileUUID);
    dispatch(endLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, selectedProfileUUID])

  return <>
    <DashboardFilterDialog
      open={openFilter}
      handleClose={() => setOpenFilter(false)}
      fromDate={fromDate}
      setFromDate={setFromDate}
      toDate={toDate}
      setToDate={setToDate}
    />
    <Typography sx={{ fontSize: '25px', fontWeight: 700 }}>Good day, {name}</Typography>
    <Typography>See what's happening today</Typography>
    <Box sx={{
      mt: 3,
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      width: {
        xs: 'calc(100% + 32px)',
        sm: 'calc(100% + 48px)',
      },
      bgcolor: '#EE8321',
      position: 'relative',
      left: {
        xs: '-16px',
        sm: '-24px',
      },
      px: {
        xs: '16px',
        sm: '24px',
      },
      py: '10px',
    }}>
      <Typography sx={{ flex: 1, color: 'white', fontSize: '20px', fontWeight: 500 }}>Overview</Typography>
      <Button
        sx={{ px: '10px', display: 'flex', borderRadius: '30px', bgcolor: '#FECD20', '&:hover': { bgcolor: '#FFEFBA' } }}
        onClick={handleClick}
        startIcon={<Avatar src={selectedProfileImage ?? icons.profile_picture_icon} sx={{ width: 31, height: 31 }} alt="" />}
        endIcon={<img width={16} src={icons.arrow_down_01_icon} alt="" />}
      >
        <Typography color="secondary" sx={{ fontSize: '13px', fontWeight: 500, flex: 1, textAlign: 'start' }}>{selectedProfileName}</Typography>
      </Button>
      <IconButton onClick={() => setOpenFilter(true)}>
        <div className='icon' style={{
          backgroundColor: '#FFFFFF',
          mask: `url(${icons.filter_list})`,
          maskSize: 'cover',
          maskRepeat: 'no-repeat',
          WebkitMask: `url(${icons.filter_list})`,
          WebkitMaskSize: 'cover',
          WebkitMaskRepeat: 'no-repeat',
          width: '24px',
          height: '24px',
        }} />
      </IconButton>
    </Box>
    <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
      <Box boxShadow={3} sx={{ display: 'flex', flexDirection: 'column', gap: '10px', p: 1, bgcolor: '#F9FAFC', borderRadius: '16px', width: '364px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            borderRadius: '9px',
            borderLeft: '9px solid #EBEBEB',
            p: '9px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedProfileUUID('')
            setSelectedProfileName('All Profiles')
            setSelectedProfileImage(null)
            setAnchorEl(null);
          }}
        >
          <Box sx={{ mx: '9px', flex: 1 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#373736' }}>All Profiles</Typography>
          </Box>
          <IconButton>
            <img src={icons.arrow_right_icon} alt="" />
          </IconButton>
        </Box>
        {profiles.map((profile, i) => <Box
          key={i}
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            borderRadius: '9px',
            borderLeft: `9px solid ${profile.color}`,
            p: '9px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedProfileUUID(profile.profile_uuid)
            setSelectedProfileName(profile.name)
            setSelectedProfileImage(profile.imgURL)
            setAnchorEl(null);
          }}
        >
          <Avatar src={profile.imgURL ?? icons.profile_picture_icon} sx={{ width: 54, height: 54 }} alt="" />
          <Box sx={{ mx: '9px', flex: 1 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#373736' }}>{profile.name}</Typography>
            {/* <Typography sx={{ fontSize: '13px', fontWeight: 400, color: '#959595' }}>Name</Typography> */}
          </Box>
          <IconButton>
            <img src={icons.arrow_right_icon} alt="" />
          </IconButton>
        </Box>)}
      </Box>
    </Popper>
    {cards.length > 0 && <Paper sx={{ p: 2, mt: 3 }}>
      <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', mb: '10px' }}>
        <TitleIcon icon={icons.credit_card} />
        <Typography component="h2" variant="h4" color="secondary" flex={1}>
          My Cards
        </Typography>
        <Button
          variant='contained'
          size='small'
          sx={{ px: '20px' }}
          onClick={() => navigate(`${CARDS}/${BUY}`)}
        >
          Add Card
        </Button>
      </Box>
      {cards.length > 0 && profiles.length > 0 && <Grid container spacing={2}>
        {cards.map(card => (
          <Grid item xs={12} sm={6} xl={4} key={card.card_serial_number}>
            <CardTile
              card={card}
              profiles={profiles}
              bindProfile={bindProfile}
              handleReceive={handleReceive}
            />
          </Grid>
        ))}
      </Grid>}
      {cards.length === 0 && <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Placeholder
          icon={icons.credit_card}
          title="No card"
          description="Buy a card now!"
          buttonText="Buy Card"
          action={() => navigate(`${CARDS}/${BUY}`)}
        />
      </Grid>}
    </Paper>}
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={4} lg={4} height="100%">
          <Box>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                <TitleIcon icon={icons.property_view} />
                <Typography component="h2" variant="h4" color="secondary">
                  Total Profile View
                </Typography>
              </Box>
              <Box margin={'32px'} pr={'0px'} pt={'0px'} pl={'8px'}>
                <Doughnut
                  style={{
                    display: 'unset',
                    boxSizing: 'unset'
                  }}
                  key={doughnutIndex}
                  data={doughnutData}
                />
                <Box pt={1} >
                  {doughnutData.labels.map((label, index) => {
                    const color = doughnutData.datasets[0]['backgroundColor'][index];

                    return <Box key={index} display="flex" flexDirection="row" alignItems="center">
                      <Box
                        width="12px"
                        height="12px"
                        borderRadius="20px"
                        bgcolor={color}
                      />
                      <Box pl={1} fontSize="12px">{label}</Box>
                    </Box>
                  })}
                </Box>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Box>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                <TitleIcon icon={icons.share_knowledge} />
                <Typography component="h2" variant="h4" color="secondary">
                  Total Connections
                </Typography>
              </Box>
              <Box pt={1}>
                <Line
                  style={{
                    display: 'unset',
                    boxSizing: 'unset'
                  }}
                  key={lineDataIndex}
                  data={lineData}
                  plugins={[ChartDataLabels]}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                        align: 'start',
                      },
                      datalabels: {
                        color: 'black',
                        formatter: Math.round,
                        padding: 0
                      },
                    },
                    scales: {
                      y: {
                        grid: {
                          display: false
                        },
                        ticks: {
                          padding: 20,
                          stepSize: 1,
                        },
                        min: 0
                      },
                    }
                  }}
                />
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
    {isLoaded && <GoogleMap 
      profileViews={profileViewLocations} 
      contacts={contactLocations} 
      googleMapIndex={mapIndex} 
      totalCountryPercentage={totalCountryPercentage}
      totalStatePercentage={totalStatePercentage}
      totalCityPercentage={totalCityPercentage}
    />}
  
    <Box pt={3}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* View Contact details */}
        <CreateDialog open={contactOpen} handleClose={() => setContactOpen(false)} guestUuid={guestContactUUID} contactUuid={memberContactUUID} viewOnly={true} />
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <TitleIcon icon={icons.user_multiple} />
          <Typography component="h2" variant="h4" color="secondary">
            Recent Connections
          </Typography>
        </Box>
        {recentConnectData.length > 0 ? <Box pt={1}>
          <TableContainer sx={{ boxShadow: 0 }} component={Paper}>
            <Table key={recentlyConnectIndex} sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ bgcolor: '#3C3C3B' }}>
                <TableRow>
                  <TableCell sx={{ color: 'white' }}>Name</TableCell>
                  <TableCell align="center" sx={{ color: 'white' }}>Created Date</TableCell>
                  <TableCell align="center" sx={{ color: 'white' }}>View Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {recentConnectData.map((data: any) => (
                  <TableRow
                    key={data.contact_uuid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{data.name}</TableCell>
                    <TableCell align="center">{moment(data.created_at, 'YYYY-MM-DD HH:mm:ss').format('d MMM YYYY HH:mm')}</TableCell>
                    <TableCell align="center">
                      <Button
                        size="small"
                        variant='contained'
                        color='primary'
                        onClick={() => viewContactDetails({ memberUUID: data.contact_uuid, guestUUID: data.contact_guest_uuid })}
                      >
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box> : <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: '30px',
        }}>
          <Placeholder
            icon={icons.user_multiple}
            title="No recent connections"
            description="Go out and meet new people!"
          />
        </Box>}
      </Paper>
      <Paper sx={{ p: 2, mt: 3 }}>
        <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <TitleIcon icon={icons.privillage_icon} />
          <Typography component="h2" variant="h4" color="secondary">
            Collected Vouchers
          </Typography>
        </Box>
        {Boolean(collectedVouchers.length) && <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          {collectedVouchers.map((voucher, index) => <Coupon key={index} voucher={voucher} />)}
        </Box>}
        {collectedVouchers.length === 0 && <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: '30px',
        }}>
          <Placeholder
            icon={icons.coupon}
            title="No coupons"
            description="It’s time for you to get more coupons to enjoy the benefits!"
          />
        </Box>}
      </Paper>
    </Box>
    <Copyright sx={{ pt: 4 }} />
  </>
}
