import axios from "axios";
import { ListOrder } from "../../lib/types/Order";
interface Data {
  code: number;
  data: ListOrder[];
  message: string | null;
  success: boolean;
}

const getList = (): Promise<Data> => { 
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

    return new Promise((resolve, reject) => {
        axios.get("order/getList")
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getList;
