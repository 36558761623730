import React, { useEffect } from 'react';

export default function ZakekeComponent({ iframeLoaded }) {
  useEffect(() => {
    var iframe: any = document.getElementById('zakeke-container')?.firstChild;

    if (iframe) {
      iframe.style.width = '100%';
      iframe.style.height = '99vh';
    }
  }, [iframeLoaded])

  return <div id="zakeke-container" />
}