import Drawer from '@mui/material/Drawer';
import { Toolbar, Box, List, IconButton, Typography, Divider, ListItem, Button } from '@mui/material';
import icons from "src/assets/icons";
import { useEffect, useState } from 'react';
import FileHelper from 'src/lib/helper/fileHelper';
import Placeholder from '../placeholder/placeholder';

interface CartDrawerComponentProps {
  setOpen: any;
  open: boolean;
  items: any[];
  totalPrice: number;
  handleDelete: (index: number, cart_uuid: string) => any;
  handleBack: () => void;
  handleCheckout: () => void;
}

export default function CartDrawerComponent({
  setOpen,
  open,
  items,
  totalPrice,
  handleDelete,
  handleBack,
  handleCheckout,
}: CartDrawerComponentProps) {
  const [products, setProducts] = useState<any[]>([]);

  // Get URL for thumbnail image, if needed
  useEffect(() => {
    Promise.all(items.map(async (item) => {
      const front_thumb = item.variant.front;

      if (Boolean(front_thumb) && front_thumb.slice(0, 5) === 'https') {
        return item;
      }

      return {
        ...item,
        variant: {
          ...item.variant,
          front: await FileHelper.getUrl(item?.variant.front)
        },
      }
    })).then(setProducts);
  }, [items]);

  return <Drawer
    open={open}
    anchor="right"
    onClose={() => setOpen(false)}
    sx={{ zIndex: 1300 }}
    PaperProps={{ sx: { p: '30px', minWidth: { md: '600px' } } }}
  >
    <Toolbar sx={{ alignItems: 'center', p: [0] }}>
      <img src={icons.shopping_cart.toString()} style={{ width: '43px', marginRight: '20px' }} alt="Shopping cart" />
      <Typography sx={{ flex: 1, fontSize: '31px', fontWeight: 500 }}>Cart</Typography>
      <IconButton onClick={() => setOpen(false)}>
        <img src={icons.close.toString()} alt="Close button" />
      </IconButton>
    </Toolbar>
    <Divider sx={{ borderColor: '#FECD20' }} />

    {products?.length > 0 ? <List sx={{ py: 0, overflowY: 'auto' }}>
      {products.map((item, index) => <ListItem
        key={index}
        disableGutters
        divider={index + 1 !== products.length}
        secondaryAction={<IconButton onClick={() => handleDelete(index, item.cart_uuid)}>
          <img src={icons.trash_icon_btn.toString()} alt="Delete" />
        </IconButton>}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              width: '80px',
              height: '80px',
              marginRight: '10px ',
              border: '1px solid #C5C5C5',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
              <img
                src={item?.variant.front}
                alt="product thumbnail"
                style={{
                  padding: Boolean(item?.designid) ? 0 : '10px',
                  display: 'block',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  borderRadius: '14px',
                }}
              />
            </Box>
            <Box>
              <Box>
                <Typography component={'span'} sx={{ fontSize: '20px', fontWeight: 500, mr: '10px' }}>{item.name}</Typography>
                <Typography component={'span'} sx={{ color: '#AAAAAA', fontWeight: 400 }}>x{item.quantity}</Typography>
              </Box>
              <Typography>{item.variant.name}</Typography>
            </Box>
          </Box>
          {/* <Typography>
            {[item.customization ? 'Customization' : null, item.pixelcare ? 'PixelCare+' : null].filter(Boolean).join(', ')}
          </Typography> */}
        </Box>
        <Typography component={'span'} sx={{ fontSize: '20px', fontWeight: 500, mr: '10px' }}>
          USD{(((parseFloat(item.price)) * item.quantity) + (parseFloat(item.totalCustomizationPrice) * item.quantity)).toFixed(2)}
        </Typography>
      </ListItem>)}
    </List> : <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Placeholder
        icon={icons.shopping_cart}
        title="Cart is empty"
        description="Shop now!"
        buttonText="Buy Card"
        action={handleBack}
      />
    </Box>}
    <Box sx={{ flex: 1 }}></Box>
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      borderTop: '1px solid #AAAAAA',
      borderBottom: '1px solid #AAAAAA',
      py: '20px',
    }}>
      <Typography sx={{ fontSize: '25px', fontWeight: 500 }}>Total</Typography>
      <Typography sx={{ fontSize: '25px', fontWeight: 500 }}>
        USD &nbsp;
        {(totalPrice).toFixed(2)}
      </Typography>
    </Box>
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      pt: '20px',
    }}>
      <Typography sx={{ color: '#006BCF', cursor: 'pointer' }} onClick={handleBack}>Back to Shopping</Typography>
      <Button disabled={products?.length === 0} variant='contained' color='secondary' onClick={handleCheckout}>Check Out</Button>
    </Box>
  </Drawer>
};