import axios from "axios";

interface RequestBody {
  order_uuid: string;
  channel: string;
}

interface Data{
  code: number,
  data:  OrderResponse,
  message: string | null,
  success: boolean
}

interface OrderResponse {
  order_uuid: string;
  merchant_id: string;
  channel: string;
  order_ref_id: string;
  vcode: string;
  total_amount: string;
  country: string;
  currency: string;
  bill_name: string;
  bill_email: string;
  bill_mobile: string;
  bill_desc: string;
  bill_addr1: string;
  bill_addr2: string;
  bill_city: string;
  bill_state: string;
  bill_zip: string;
  returnurl: string;
  callbackurl: string;
  cancelurl: string;
}
const repaid = ({ requestBody }: { requestBody: RequestBody }): Promise<Data>=> {

  return new Promise((resolve, reject) => {
    axios.post("order/repaid", requestBody)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default repaid;