import React, { useEffect, useState } from "react";
import SubscriptionComponent from "./subscriptionPayment.component";
import { PORTAL_SETTINGS, SETTINGS, SUBSCRIPTION } from "../../../../lib/constants/routes";
import { setBreadcrumb } from "../../../../features/admin";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import orderApi from "../../../../api/order";
import userApi from "../../../../api/user";
import { openResponseDialog } from "../../../../features/response-dialog";
import { UserDetails } from "../../../../api/user/getDetail.api";

export default function SubscriptionPaymentContainer() {
  const currency = process.env.REACT_APP_SUBSCRIPTION_CURRENCY || 'USD';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [basicInfo, setBasicInfo] = useState<UserDetails>();
  const [billing, setBilling] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
  });

  const handlePaymentPlan = () => {
    orderApi.testSubscribe({
      bill_name: basicInfo?.name ?? '',
      bill_email: basicInfo?.email ?? '',
      bill_mobile: `${basicInfo?.phone_code}${basicInfo?.phone_number}`,
      bill_addr1: billing.address1,
      bill_addr2: billing.address2,
      bill_city: billing.city,
      bill_state: billing.state,
      bill_zip: billing.postcode,
      bill_country: billing.country,
      currency,
      channel: 'creditT',
      subscription_id : 6, //this is for testing purpose only, make sure the subscription id is correct
      ref_code: '',
    }).then((response) => {
      const data = {
        plan_id: response.data.plan_id,
        currency,
        rcode: response.data.rcode,
        torderid: response.data.order_ref_id,
        vorderid: response.data.order_ref_id,
      };

      const pgUrl = process.env.REACT_APP_FIUU_PAYMENT;
      const merchantId = process.env.REACT_APP_FIUU_MERCHANT_ID;
      const searchParams = new URLSearchParams(data);
      const url = `${pgUrl}/${merchantId}?${searchParams.toString()}`;
      window.open(url, "_self");
    }).catch(error => {
      const errorMessage = typeof error.data === 'object' ? Object.values(error.data).join(', ') : error.message;
      dispatch(openResponseDialog({ title: "Subscription failed", description: errorMessage }));
    });
  }

  const getDetail = async () => {
    await userApi.getDetail()
      .then(response => setBasicInfo(response.data))
      .catch(console.error);
  }

  const getBilling = async () => {
    await userApi.getBilling().then((res) => {
      if (res.success) {
        setBilling({
          address1: res.data.address_1 || '',
          address2: res.data.address_2 || '',
          city: res.data.city || '',
          state: res.data.state || '',
          postcode: res.data.zip || '',
          country: res.data.country || '',
        });
      }
    });
  }

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "Settings", path: SETTINGS },
        { name: "Subscription", path: `${PORTAL_SETTINGS}/${SUBSCRIPTION}/` },
        { name: "Payment", path: null },
      ]
    }));
    getDetail();
    getBilling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <SubscriptionComponent
    currency={currency}
    handleBack={() => navigate(`${PORTAL_SETTINGS}/${SUBSCRIPTION}/`)}
    plan={`Test Subscription `}
    price={0.1}
    handlePaymentPlan={handlePaymentPlan}
  />
}