import React, { useEffect} from 'react';

import VoucherCodeCreate from './voucherCodeCreate.component';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../../../features/admin';
import { PORTAL_CAMPAIGNS,CAMPAIGN,PORTAL } from '../../../../../lib/constants/routes';
import { useParams } from 'react-router-dom';
import campaignApi from '../../../../../api/campaign';
import { startLoading, endLoading } from '../../../../../features/loading';
import { AddVoucherCodeRequest, GenerateVoucherCode } from '../../../../../lib/types/Voucher';
import { openErrorDialog } from '../../../../../features/error-dialog';
import { openResponseDialog } from '../../../../../features/response-dialog';
import { VoucherSettings } from '../../../../../lib/types/Voucher';
import { VoucherCodeStatistic } from '../../../../../lib/types/Voucher';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import auth from '../../../../../api/auth';

export default function VoucherCodeCreateContainer() {
  const dispatch = useDispatch();
  const { id, voucherUUID } = useParams();
  const theme = useTheme();
  const [voucherCodes, setVoucherCodes] = React.useState([]);
  const [recordsTotal, setRecordsTotal] = React.useState(0);
  // const [recordsFiltered, setRecordsFiltered] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order,setOrder] = React.useState<string | null>(null);
  const [orderBy,setOrderBy] = React.useState<string | null>(null);  
  const [search, setSearch] = React.useState<string | null>(null);
  const [filter, setFilter] = React.useState<Object | null>({});
  const [selectedSort, setSelectedSort] = React.useState('Sort by ID (Desc)');
  const [voucher, setVoucher] = React.useState<VoucherSettings>({
    voucher_title: '',
    banner_image: '',
    description: '',
    brand_name: '',
    code_type: '',
    company_name: '',
    company_profile_image: '',
    limit_per_user: '',
    style_type: '',
    terms_and_condition: '',
    validation_type: 0,
    discount_type: '',
    active: false,
    start_date_time: '',
    end_date_time: '',
    collection_start_date: '',
    collection_end_date: ''
  });
  const [voucherCodeStatistic, setVoucherCodeStatistic] = React.useState<VoucherCodeStatistic>({
    total: 0,
    total_activation: 0,
    total_collected: 0,
    total_redemption: 0,
  });
  const [accessRights, setAccessRights] = React.useState<string[]>([]);

  const breakpointMd = useMediaQuery(theme.breakpoints.down('md'));

  const getVoucherSettings = async () => {
    dispatch(startLoading());
    return await campaignApi.getVoucherSettings({ voucherUUID: voucherUUID || ''}).then((res) => {
      if(res.success){
        setVoucher(res.data)
        return res;
      }else{
        dispatch(openErrorDialog({
          title: "Error", 
          description: res.message,
          redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
        }))
      }
    }).catch((err) => {
      dispatch(openErrorDialog({
        title: "Error", 
        description: err.message,
        redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
      }))
      return err
    }).finally(() => {
      dispatch(endLoading());
    })
  }

  const addVoucherCode = ({code}) => {
    if(voucherUUID){
      dispatch(startLoading());
      const addVoucherCodeRequest : AddVoucherCodeRequest = {
        voucher_uuid: voucherUUID ,
        code,
      }
      return campaignApi.addVoucherCode(addVoucherCodeRequest).then((res) => {
        if(res.success){
          dispatch(openResponseDialog({title: "Import Success",description: "Voucher code has successfull created"})) 
          return res;
        }else{
          dispatch(openErrorDialog({
            title: "Error", 
            description: res.message,
            redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
          }))
        }
      }).catch((err) => {
        dispatch(openErrorDialog({
          title: "Error", 
          description: err.message,
          redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
        }))
        return err
      }).finally(() => {
        dispatch(endLoading());
        getVoucherCodeList()
        getVoucherCodeStatistic()
      })
    }
  }

  const generateCode = ({code,quantity}) => {
    if(voucherUUID){
      dispatch(startLoading());
      const generateVoucherCodeRequest : GenerateVoucherCode = {
        voucher_uuid: voucherUUID ,
        code_prefix: code,
        quantity
      }
      return campaignApi.generateVoucherCode(generateVoucherCodeRequest).then((res) => {
        if(res.success){
          dispatch(openResponseDialog({title: "Import Success",description: "Voucher code has successfull generated"})) 
          return res;
        }else{
          dispatch(openErrorDialog({
            title: "Error", 
            description: res.message,
            redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
          }))
        }
      }).catch((err) => {
        dispatch(openErrorDialog({
          title: "Error", 
          description: err.message,
          redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
        }))
        return err
      }).finally(() => {
        dispatch(endLoading());
        getVoucherCodeList()
        getVoucherCodeStatistic()
      })
    }
  }

  const getVoucherCodeList = async () => {
    dispatch(startLoading());
    return await campaignApi.getVoucherCodeList({ 
      voucher_uuid: voucherUUID || '', 
      search: search, 
      filter: filter, 
      order_by: orderBy, 
      order: order, 
      length: rowsPerPage, 
      start: page * rowsPerPage
    }).then((res) => {
      if(res.success){
        setVoucherCodes(res.data.data)
        // setRecordsFiltered(res.data.recordsFiltered)
        setRecordsTotal(res.data.recordsTotal)
        return res;
      }else{
        dispatch(openErrorDialog({
          title: "Error", 
          description: res.message,
          redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
        }))
      }
    }).catch((err) => {
      dispatch(openErrorDialog({
        title: "Error", 
        description: err.message,
        redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
      }))
      return err
    }).finally(() => {
      dispatch(endLoading());
    })
  }

  const handleImport = async (blobFile) =>{
    if(blobFile instanceof Blob){
      const formData = new FormData();
      formData.append('file',blobFile, 'uploaded-file');

      await campaignApi.importVoucherCode({requestBody: formData, voucher_uuid: voucherUUID||''})
      .then(response => {
        dispatch(openResponseDialog({title: "Import Success",description: "Voucher Code has successfull imported"})) 
        return response
      })
      .catch(error =>  { dispatch(openResponseDialog({title: "Import Failed",description: error}))})
      .finally(() => {
        getVoucherCodeList()
        getVoucherCodeStatistic()
      })
    }
  }

  const handleStatus = async ( {voucherCodeUUID,status}:{voucherCodeUUID:string, status: boolean}) => {
    dispatch(startLoading());
    await campaignApi.editVoucherCodeStatus({voucherCodeUUID: voucherCodeUUID, active: status}).finally(() => {
      getVoucherCodeList()
      dispatch(endLoading());
      getVoucherCodeStatistic()
    })
  }
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangePageMobile  =(targetPage:number)=>{
    setPage(targetPage)
  }
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value))
  }

  const handleSearch = (search) => {
    setSearch(search)
  }

  const handleSort = ({field,order,name}:{field:string,order:string,name:string }) => {
    setOrder(order)
    setOrderBy(field)
    setSelectedSort(name);
  }

  useEffect(() => {
    getVoucherCodeList()
    // eslint-disable-next-line
  }, [order, orderBy])

  const getVoucherCodeStatistic = async () => {
    dispatch(startLoading());
    return await campaignApi.getVoucherCodeStatistic({ voucherUUID: voucherUUID || ''}).then((res) => {
      if(res.success){
        setVoucherCodeStatistic(res.data)
        return res;
      }else{
        dispatch(openErrorDialog({
          title: "Error", 
          description: res.message,
          redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
        }))
      }
    }).catch((err) => {
      dispatch(openErrorDialog({
        title: "Error", 
        description: err.message,
        redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
      }))
      return err
    }).finally(() => {
      dispatch(endLoading());
    })
  }

  const handleFilterBy = (filter) => {
    setFilter({
      start_date : filter.option === 'redemption_date' ?  filter.redemptionFrom : null,
      end_date : filter.option === 'redemption_date' ? filter.redemptionTo : null,
      collected_status : filter.option ==='collected_status' ? filter.collectedStatus : null,
      redemption_status : filter.option ==='redemption_status' ? filter.redemptionStatus : null,
      code_activation : filter.option ==='code_activation' ? filter.codeActivation : null,
    })
  }
  const handleRefresh = () =>{
    setSearch(null)
    setFilter({})
    setOrder(null)
    setOrderBy(null)
    setSelectedSort('Sort by ID (Desc)');
  }
  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb:[
      {
        name: "Campaign",
        path: PORTAL_CAMPAIGNS
      },{
        name: "Campaign List",
        path : PORTAL_CAMPAIGNS
      },{
        name: "Campaign Detail",
        path : PORTAL+"/"+CAMPAIGN + "/" + id
      },{
        name: "Voucher Code Create",
        path : null
      }
    ]
    }))

    auth.getAccessLimit().then(value => {
      setAccessRights(value.data.access_right);
    });

    getVoucherSettings()
    getVoucherCodeStatistic()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getVoucherCodeList()
    // eslint-disable-next-line
  }, [search,page, rowsPerPage,filter])

  useEffect(() => {
    setPage(0)
    if(breakpointMd){
      setRowsPerPage(3)
    }else{
      setRowsPerPage(10)
    }
    // eslint-disable-next-line
  }, [breakpointMd])

  return <VoucherCodeCreate
  page={page}
  accessRights={accessRights}
  rowsPerPage={rowsPerPage} 
  voucher={voucher}
  voucherCodes={voucherCodes}
  recordsTotal={recordsTotal}
  voucherCodeStatistic={voucherCodeStatistic}
  breakpointMd={breakpointMd}
  generateCode={generateCode}
  handleSort={handleSort} 
  handleSearch={handleSearch}
  handleStatus={handleStatus} 
  handleImport={handleImport}
  handleChangePage={handleChangePage}
  handleChangePageMobile={handleChangePageMobile}
  handleChangeRowsPerPage={handleChangeRowsPerPage}
  addVoucherCode={addVoucherCode}
  handleFilterBy={handleFilterBy}
  handleRefresh={handleRefresh}
  selectedSort={selectedSort}
  />;
}