import React from "react";
import { Box, Button, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import icons from "../../../../assets/icons";
import PhoneInput from "../../../../components/phoneInput";


export default function ContactFormComponent({ handleBack,handleSubmit }) {
  return <Formik
    initialValues={{
      name: '',
      company_name: '',
      email: '',
      phoneCode: '+60',
      phoneNumber: '',
      employees_range: '10 - 50',
    }}
    onSubmit={values => {
      handleSubmit({
        fullname: values.name,
        company_name: values.company_name,
        email: values.email,
        phone: `${values.phoneCode}${values.phoneNumber}`,
        employees_range: values.employees_range,
      })
    }}
  >
    {({ values, handleChange, handleSubmit, setFieldValue }) => <Box component={'form'} onSubmit={handleSubmit} sx={{ mb: '20px', maxWidth: '700px' }} noValidate>
      <Button color="secondary" startIcon={<img width={12} height={12} src={icons.back_icon} alt="" />} sx={{ mb: '10px' }} onClick={handleBack}>Back</Button>
      <Typography sx={{ fontSize: '25px', fontWeight: 500 }}>Business Package</Typography>
      <Box sx={{ my: '30px' }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Request a Demo</Typography>
        <Typography>
          Let us show you how attractive Pixel Card can be. Our sales team offers one-on-one product demo for companies with more than 10 employees.
        </Typography>
      </Box>
      <Box>
        <Typography component='label' htmlFor='name' className="required" variant='h5'>
          Full Name
        </Typography>
        <TextField
          color='primary'
          margin="dense"
          required
          fullWidth
          id="name"
          name="name"
          autoComplete="name"
          sx={{ bgcolor: 'white', borderRadius: '4px' }}
          value={values.name}
          onChange={handleChange}
        />
      </Box>
      <Box>
        <Typography component='label' htmlFor='company_name' className="required" variant='h5'>
          Company Name
        </Typography>
        <TextField
          color='primary'
          margin="dense"
          required
          fullWidth
          id="company_name"
          name="company_name"
          autoComplete="company_name"
          sx={{ bgcolor: 'white', borderRadius: '4px' }}
          value={values.company_name}
          onChange={handleChange}
        />
      </Box>
      <Box>
        <Typography component='label' htmlFor='email' className="required" variant='h5'>
          Email
        </Typography>
        <TextField
          color='primary'
          margin="dense"
          required
          fullWidth
          id="email"
          name="email"
          sx={{ bgcolor: 'white', borderRadius: '4px' }}
          value={values.email}
          onChange={handleChange}
        />
      </Box>
      <Box>
        <Typography component='label' htmlFor='phone' className="required" variant='h5'>
          Phone Number
        </Typography>
        <Box sx={{ display: 'flex', mt: '5px' }}>
          <PhoneInput
            code={values.phoneCode}
            disabled={false}
            value={values.phoneNumber}
            setVal={(phoneCode, phoneNumber) => {
              if (phoneCode !== undefined) setFieldValue('phoneCode', phoneCode);
              if (phoneNumber !== undefined) setFieldValue('phoneNumber', phoneNumber);
            }}
          />
        </Box>
      </Box>
      <Box>
        <Typography component='label' htmlFor='employees_range' className="required" variant='h5'>
          Number of employees
        </Typography>
        <Select
          id="employees_range"
          name="employees_range"
          value={values.employees_range}
          sx={{
            marginTop: '0.6rem',
            '&:focus': {
              border: 'solid #FECD20'
            },
          }}
          fullWidth
          onChange={handleChange}
        >
          <MenuItem value={'10 - 50'}>10 - 50</MenuItem>
          <MenuItem value={'50 - 100'}>50 - 100</MenuItem>
          <MenuItem value={'100 - 500'}>100 - 500</MenuItem>
          <MenuItem value={'500 - 1000'}>500 - 1000</MenuItem>
          <MenuItem value={'> 1000'}>More than 1000</MenuItem>
        </Select>
      </Box>
      <Button type="submit" variant="contained" color="secondary" sx={{ mt: '20px' }}>
        Request a Demo
      </Button>
    </Box>}
  </Formik>
}