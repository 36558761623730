import React , {useState,useEffect, Dispatch, SetStateAction} from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {Box,Dialog,DialogTitle,Divider,FormControl,InputLabel,Link, MenuItem, Select, Typography} from '@mui/material';
import {useDropzone} from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import icons from 'src/assets/icons';

const Container  = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '0.5px',
  borderRadius: '15px',
  borderStyle: 'dashed',
  backgroundColor: '#F9F9F9',
  color: '#373736',
  outline: 'none',
  transition: 'border .24s ease-in-out'
});

export default function DragAndDropDialogComponent({open,selectedFile,handleClose,handleSubmit,downloadSample,acceptedFormat, profiles = [], selectedProfileUUID, setSelectedProfileUUID, title}:
  {
    open: boolean;
    selectedFile: (files:Array<File>) => void;
    handleClose: () => void;
    handleSubmit: () => void;
    downloadSample: () => void;
    acceptedFormat: string[];
    profiles: any[];
    selectedProfileUUID: string;
    setSelectedProfileUUID: Dispatch<SetStateAction<string>>;
    title: string;
  }) {
    
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
  } = useDropzone({accept: {'text/*':  acceptedFormat.map((value,index) =>{ return '.'+value})}});
  
  const [files, setFiles] = useState<Array<File>>([])
  const removeFiles = () =>{
    setFiles([])
    selectedFile(files)
  }
  useEffect( ()=> {
    if(acceptedFiles.length > 0){
      setFiles(acceptedFiles)
      selectedFile(acceptedFiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[acceptedFiles])
  return (
    <Dialog open={open}  onClose={() => handleClose()}
    PaperProps={{
      style : {
        padding:"1rem 2.5rem 1rem 2.5rem", borderRadius:"10px"},
    }}>

      <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: '1rem',
      maxWidth: '350px'
    }}>
      <DialogTitle sx={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    }}>
        <Typography sx={{
          fontSize: "20px",
          fontWeight: "500"
        }}>
        {title}
        </Typography>
        <Typography sx={{
          fontSize: '13px',

        }}>
          Before proceeding with the upload, please format
          your file as per the <Link color='secondary' sx={{ fontWeight: "700", cursor: 'pointer' }} onClick={() => downloadSample()} >sample spreadsheet. <img alt="link_square" src={icons.link_square_bw.toString()}/></Link>
        </Typography>
      </DialogTitle>
      <Divider />
      {profiles.length > 0 && <FormControl fullWidth>
        <InputLabel id="select-label">Select Profile</InputLabel>
        <Select
          labelId="select-label"
          id="profile"
          value={selectedProfileUUID}
          label="Select Profile"
          fullWidth
          onChange={e => setSelectedProfileUUID(e.target.value)}
        >
          {profiles.map(prf => <MenuItem key={prf.uuid} value={prf.uuid}>{prf.name}</MenuItem>)}
        </Select>
      </FormControl>}
    <Container {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <CloudUploadIcon sx={{width:"51px",height:"51px"}}/>
          <span style={{fontSize:"16px", fontWeight:"500"}}>Drop file here or click upload</span>
          <span style={{fontSize:"10px", fontWeight:"400"}}> {acceptedFormat.reduce((accumulator, currentValue,currentIndex) => {
            let formatString = ''
            if(currentIndex+1 === acceptedFormat.length){
              formatString = accumulator + ' or ' + currentValue.toUpperCase()+' '
            }else{
              formatString = accumulator.toUpperCase() + ', ' + currentValue.toUpperCase()+' '
            }
            return formatString
            }).trim()} files accepted</span>
    </Container>
    <Box sx={{display:'flex',justifyContent:"center"}}>
      <Button disabled={files?.length <= 0 || (profiles.length > 0 && !Boolean(selectedProfileUUID))} variant='contained' color="secondary" onClick={() => handleSubmit()}
          sx={{ mt: 3, mb: 2, width:"112px",height:"42px",borderRadius: '30px' }}>
        Upload
      </Button>
    </Box>
    <Box>
      <Typography sx={{
        fontSize: '12px',
        fontWeight: '400'
      }}>
        {files.length > 0 &&
        <Box sx={{
          display: 'grid',
          gridTemplateColumns: '8fr 1fr',
          alignItems: 'center',
          justifyItems: 'center',

        }}>
          <Box
          style={{
            backgroundColor: '#FFEFBA',
            borderRadius: '15px',
            border: '1px solid var(--Status-Default, #C5C5C5)',
            padding: '0 10px'
          }}>
          <span style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}>{files[0]?.name}</span> 
          <span>({files[0]?.size}kb)</span>
          </Box>
          <img src={icons.trash_icon_btn.toString()} alt="trash-bin" onClick={() => removeFiles()}/>
        </Box>
        }

      </Typography>
    </Box>
    </Box>
  </Dialog>
  )  
}
