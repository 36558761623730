import React from 'react'
import FloatingButton from './floating-button'
import BasicInfo from './basic-info';
import Gallery from './gallery';
import SocialLink from './social-link'
import BasicContact from './basic-contact';
import Files from './files';
import Video from './video';
import Vouchers from './vouchers';
import CustomComponent from './custom-component';
import NotificationBar from './notification-bar';
import { useSelector } from 'react-redux'
import { useLocation } from "react-router-dom";
import { Box } from '@mui/material';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function LandingPageComponent() {
  const profile = useSelector((state: any) => state.landingPage.profile);
  const profileTheme = useSelector((state: any) => state.landingPage.profile.theme) ?? {}
  const backgroundColor = useSelector((state: any) => state.landingPage.background_color) ?? {}
  let query = useQuery();
  const preview = query.get("preview");

  const sections: any[] = [
    {
      section_name: 'social',
      ...profile.social_info,
    },
    {
      section_name: 'contact',
      ...profile.contact_info,
    },
    {
      section_name: 'gallery',
      ...profile.gallery,
    },
    {
      section_name: 'video',
      ...profile.video,
    },
    {
      section_name: 'vouchers',
      sort: profile.video.sort + 0.5,
    },
    {
      section_name: 'files',
      ...profile.file,
    },
    ...profile.custom_component || []
  ].sort((a, b) => a.sort - b.sort);
  return <Box sx={{
    backgroundColor: backgroundColor || '#FFFFFF',
    color: profileTheme?.primary_font_color || '#000000',
  }}>
    {!preview && <NotificationBar />}
    <BasicInfo />

    {sections.map(section => {
      if (section.section_name === "social") return <SocialLink key={section.sort} />
      if (section.section_name === "contact") return <BasicContact key={section.sort} />
      if (section.section_name === "gallery") return <Gallery key={section.sort} />
      if (section.section_name === "video") return <Video key={section.sort} />
      if (section.section_name === "vouchers") return <Vouchers key={section.sort} />
      if (section.section_name === "files") return <Files key={section.sort} />
      if (section.section_name === "custom") return <CustomComponent component={section} key={section.sort} />
      return <></>
    })}

    {!preview && <FloatingButton />}
  </Box>
} 
