import React, { useEffect, useState } from 'react';
import { Button, Box, Link, Typography } from '@mui/material';
import { CollectedVoucher } from '../../../lib/types/Wallet';
import { useNavigate } from 'react-router-dom';
import { WALLET, WALLET_REDEEM_VOUCHER } from '../../../lib/constants/routes';
import FileHelper from '../../../lib/helper/fileHelper';
import DialogVoucher from './dialogVoucher';
import moment from 'moment';

interface CouponProps {
  voucher: CollectedVoucher;
  bgcolor?: string;
  surfaceColor?: string;
}

export default function Coupon({ voucher, bgcolor = '#FFFFFF', surfaceColor = '#FFFFFF' }: CouponProps) {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [backgroundImage, setBackgroundImage] = React.useState('' as string);
  const [isExpired, setIsExpired] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleRedeem = ({ userVoucherUUID }: { userVoucherUUID: string }) => {
    navigate(`/${WALLET}/${userVoucherUUID}/${WALLET_REDEEM_VOUCHER}`)
  }

  const getUrl = (url: string) => {
    return new Promise((resolve) => {
      FileHelper.getUrl(url).then((res) => {
        resolve(res)
      });
    })
  }

  const handleExpired = () => {
    setIsExpired(moment(voucher.end_date_time).isBefore(moment()));
  }
  useEffect(() => {
    getUrl(voucher.banner_image).then((res) => {
      setBackgroundImage(res as string);
    })
    handleExpired();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucher.banner_image]);
  return (
    <>
      <DialogVoucher open={openDialog} handleClose={handleCloseDialog} voucher={voucher} />
      <Box sx={{ m: '1rem', width: '330px' }}>
        <Box sx={{
          bgcolor,
          boxShadow: '0px -2px 8px 0px rgba(0, 0, 0, .1)',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        }}>
          <Box sx={{
            height: '173px',
            background: 'lightgray 50% / cover no-repeat',
            backgroundImage: `url(${backgroundImage})`,
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}>
          </Box>
          <Box sx={{ p: '1rem' }}>
            <Typography sx={{ fontSize: '13px', fontWeight: '500' }}>
              {voucher.voucher_title}
            </Typography>
          </Box>
        </Box>
        <Box sx={{
          bgcolor,
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
        }} >
          <Box sx={{
            height: '32px',
            width: '16px',
            position: 'relative',
            borderRadius: '0 100% 100% 0/0 50% 50% 0',
            background: `linear-gradient(to right, ${surfaceColor}, #EFEFEF)`,
          }} />
          <Box sx={{ flex: 1, border: '0.5px dashed #C5C5C5' }} />
          <Box sx={{
            height: '32px',
            width: '16px',
            position: 'relative',
            borderRadius: '100% 0 0 100%/50% 0 0 50%',
            background: `linear-gradient(to left, ${surfaceColor}, #EFEFEF)`,
          }} />
        </Box>
        <Box sx={{
          bgcolor,
          boxShadow: '0px 5px 8px 0px rgba(0, 0, 0, .1)',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        }}>
          <Box sx={{ padding: '1rem' }}>
            <Typography sx={{ fontSize: '10px' }}>
              Valid until {voucher.end_date_time}
            </Typography>
            <Typography sx={{ fontSize: '10px' }}>
              {voucher.description}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', padding: '1rem', alignItems: 'center', justifyContent: 'space-between' }}>
            {
              (!isExpired && (!voucher.redeemed_at && (moment(voucher.start_date_time).isBefore(moment())))) &&<Button variant="contained" color="secondary" onClick={() => handleRedeem({
                userVoucherUUID: voucher.user_voucher_uuid
              })}>
                Redeem Now
              </Button>
            }
            {
               ((!voucher.redeemed_at && (moment(voucher.start_date_time).isAfter(moment()))) || (!voucher.redeemed_at && isExpired)) &&<Button variant="contained" color="secondary" disabled>
                Redeem Now
              </Button>
            }
            {
              voucher.redeemed_at &&
              <Button variant="contained" color="secondary" disabled>
                Redeemed
              </Button>
            }
            {
              isExpired && <Button variant="contained" color="error">
                Expired
              </Button>
            }
            <Typography sx={{ fontSize: '10px' }}>
              <Link sx={{ cursor: 'pointer' }} onClick={() => setOpenDialog(!openDialog)}>Read T&C</Link>
            </Typography>
          </Box>
        </Box >
      </Box>
    </>
  );
}