import React,{useEffect} from 'react';
import { Box, Typography } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import { WALLET, WALLET_VALIDATE_VOUCHER } from '../../../../../lib/constants/routes';
import { useTheme } from '@mui/material/styles';
export default function SelfValidation  ({voucher}:{voucher:any}) {
  const theme = useTheme();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [validateLink, setValidateLink] = React.useState('');
  useEffect(() => {
    setValidateLink(
      `${baseURL}/${WALLET}/${voucher.voucher_uuid}/${WALLET_VALIDATE_VOUCHER}/${voucher.user_voucher_uuid}`
    )
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>
        <Box>
          <Box sx={{
            [theme.breakpoints.down('sm')]: {
              left:'-13%',
            },
            [theme.breakpoints.up('sm')]: {
              left:'-7%',
            },
            background:'#FECD20',
            borderRadius:'38px',
            width:'62px',
            height:'62px',
            position:'absolute',
 
            top:'40%'
          }}></Box>
          <Box sx={{
            [theme.breakpoints.down('sm')]: {
              right:'-13%',
            },
            [theme.breakpoints.up('sm')]: {
              right:'-7%',
            },
            background:'#FECD20',
            borderRadius:'38px',
            width:'62px',
            height:'62px',
            position:'absolute',
            top:'40%'
          }}></Box>
        </Box>
        <Box sx={{
          display:'flex',
          justifyContent:'center',
          mt:'1rem',
        }}>
        <Box
          sx={{
            [theme.breakpoints.up('sm')]: {
              border:' 1px dashed #C5C5C5',
            },
            display:'flex',
            alignItems:'center',
            width:'310px',

            flexDirection:'column',
            padding:'1rem'
          }}
        >
          <Box sx={{
              display:'flex',
              justifyContent:'center',
              flexDirection:'column',
              alignItems:'center',
              pb:'1rem',
              borderBottom:'1px dashed #C5C5C5',
          }}>
            <Typography variant='h3' sx={{fontWeight:"400"}}>Your coupon code</Typography>
            <Typography variant='h3' sx={{fontWeight:"500"}}>{voucher.code}</Typography>
          </Box>
          <Box sx={{
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            width:'100%',
            height:'100%',
          }}>
            <QRCode
              size='150'
              // logoWidth="80"
              // logoHeight="80"
              // logoImage={companyImage}
              value={validateLink}
              />
          </Box>
        </Box>
        </Box>
        </>
}