import React from "react";
import {Box,Button,Divider, Typography} from "@mui/material";
import CustomSwitch from "../../../components/general/customSwitch";
import icons from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import {VOUCHER_CODE, VOUCHER_CODE_CREATE,VOUCHER, VOUCHER_EDIT} from "../../../lib/constants/routes"
import { SELF_VALIDATION } from '../../../lib/constants/voucherValidationType';
import { SINGLE} from '../../../lib/constants/voucherCodeType';
import { useTheme } from "@mui/material/styles";
import moment from "moment";
import DatetimeHelper from "../../../lib/helper/datetimeHelper";
export default function VoucherRow({voucher, accessRights, handleStatus,handleDelete} :
   {
    voucher: any,
    accessRights: string[], 
    handleStatus: (voucherUUID: string, active: boolean) => Promise<any>,
    handleDelete: (voucherUUID: string) => Promise<any>
  }) {
    const theme = useTheme();
    const navigate = useNavigate()
    const editVoucher = (voucherUUID : string) => {
      navigate(VOUCHER+'/'+voucherUUID+'/'+VOUCHER_EDIT);
    }
    const openVoucherCodeCreate = (voucherUUID: string) => {
      navigate(VOUCHER_CODE+'/'+voucherUUID+'/'+VOUCHER_CODE_CREATE)
    }

    return(
        <Box sx={{padding:"1rem",background:"#EEEEE4", width:"inherit",  borderRadius:"8px"}}>
            <Box sx={{
                pb:'1rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
                }}>
                <Box sx={{
                  display:"flex", 
                  alignItems:'flex-start',
                  flexDirection:"column"
                }}>
                <Typography variant="h3">{voucher.voucher_title}</Typography>
                <Typography>{DatetimeHelper.datetimeToDate(voucher.created_at)}</Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <CustomSwitch disabled = {!accessRights.includes('campaign voucher edit')} checked={voucher.active} onChange={e => handleStatus(voucher.voucher_uuid, !voucher.active)} ></CustomSwitch>
                { accessRights.includes('campaign voucher edit') && <img alt="delete_icon" style={{ cursor: 'pointer' }} src={icons.delete_icon} onClick={() => handleDelete(voucher.voucher_uuid)}/> }
                </Box>
            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap:"0.5rem"}}>
              <Box sx={{display:'flex',
              [theme.breakpoints.down('md')]: {
                flexDirection:"column"
              }
              }}>
              <Typography>Valdiation Options : </Typography>
              <Typography>{
                voucher.validation_type === SELF_VALIDATION?
                "Self Validation":
                "Third-Party Validation"
                }</Typography>
              </Box>
              <Box sx={{display:'flex',
              [theme.breakpoints.down('md')]: {
                flexDirection:"column"
              }
              }}>
              <Typography>Code Type : </Typography>
                <Typography>{
                  voucher.code_type === SINGLE?
                  "Single Code":
                  "Multi Code"
                  }</Typography>
              </Box>
              <Box sx={{
                [theme.breakpoints.down('md')]: {
                  display:"flex",
                  flexDirection:"column",
                }
              }}>
                <Typography>Collection Period:</Typography>
                <Typography fontSize="14px">{moment(voucher.collection_start_date).format('DD MMM YYYY h:mmA')} ~ {moment(voucher.collection_end_date).format('DD MMM YYYY h:mmA')}
                </Typography>
              </Box>
              <Box sx={{
                [theme.breakpoints.down('md')]: {
                  display:"flex",
                  flexDirection:"column",
                }
              }}>
              <Typography>Redemption Period:</Typography>
              <Typography fontSize="14px"> 
                {moment(voucher.start_date_time).format('DD MMM YYYY h:mmA')} ~ {moment(voucher.end_date_time).format('DD MMM YYYY h:mmA')}
              </Typography>
              </Box>
            </Box>
            <Box sx={{pt:"1rem",pb:"1rem"}}>
                <Button
                onClick={()=>editVoucher(voucher.voucher_uuid)}
                sx={{borderRadius:"30px", boxShadow:"none"}}
                    type="button"
                    variant="contained"
                    color="primary">
                    Edit   
                </Button>
            </Box>
            <Divider></Divider>
            <Box sx={{
              [theme.breakpoints.down('md')]: {
                pt:'1rem',
                display:"flex",
                flexDirection:"column",
                gap: '1rem'
              },
              [theme.breakpoints.up('md')]: {
                pt:'1rem',
                display:"flex",
                justifyContent:"space-between",
                alignItems: "center"
              }
             }}>
              <Box sx={{
                display: "flex",
                gap:"1rem",
              }}>
                <Box sx={{
                    display: "flex",
                    padding: "8px 10px",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0.3rem",
                    borderRadius: '8px',
                    background: '#FFFFE3',
                    color:"#71713D",
                    minWidth:"100px",
                    "& .quantity": {
                      fontSize: "20px",
                    },
                    "& .title": {
                      fontSize: "13px",
                    }
                }}>
                  {voucher.code_type === SINGLE ? <Typography className="quantity">{voucher.collect_code_count}</Typography>
                  : <Typography className="quantity">{voucher.collect_code_count}/{voucher.active_code_count}</Typography>}
                  <Typography className="title">Total Collect</Typography>
                </Box>

                {
                  voucher.code_type !== SINGLE &&
                  <Box sx={{
                    display: "flex",
                    padding: "8px 10px",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "0.3rem",
                    borderRadius: '8px',
                    background: '#E5FFE3',
                    color:"#6D936A",
                    minWidth:"100px",
                    "& .quantity": {
                      fontSize: "20px",
                    },
                    "& .title": {
                      fontSize: "13px",
                    }
                }}>
                  <Typography className="quantity">{voucher.active_code_count > voucher.collect_code_count ? 
                    voucher.active_code_count - voucher.collect_code_count :
                    voucher.collect_code_count - voucher.active_code_count
                  }</Typography>
                  <Typography className="title">Remain</Typography>
                </Box>
                }

              </Box>
              <Box>
                <Button
                  fullWidth={false}
                  onClick={()=>openVoucherCodeCreate(voucher.voucher_uuid)}
                  sx={{borderRadius:"30px", boxShadow:"none"}}
                      type="button"
                      variant="contained"
                      color="primary">
                      Manage Code   
                  </Button>
                </Box>
            </Box>
        </Box>
    )
}