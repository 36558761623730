import React from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Link, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import contactListApi from "../../api/contactList/contactList.api";
import icons from "../../assets/icons";
import { FACEBOOK, INSTAGRAM, LINKEDIN, TWITTER, SNAPCHAT, SKYPE, BEHANCE, YOUTUBE, PINTEREST, TELEGRAM, WECHAT, TIKTOK, VIBER } from "../../lib/constants/socialmediatypes";

function SocialDialog({ open, handleClose, uuid }) {
  const [socials, setSocials] = React.useState<{ type: string, link: string }[]>([]);

  React.useEffect(() => {
    if (uuid === '') return;

    contactListApi.getSocialLink(uuid)
      .then((data: any) => {
        let links: { type: string, link: string }[] = [];

        Object.keys(data).forEach(key => {
          data[key].forEach((link: string) => {
            links.push({ type: key, link });
          });
        });
        setSocials(links);
      })
      .catch(data => {
        console.log('error', data);
        setSocials([]);
      })
  }, [uuid]);

  const getIcon = (iconName: string) => {
    var style =  { marginRight: '10px' };

    if (iconName === FACEBOOK) return <img src={icons.facebook_icon} alt='' style={style} />;
    if (iconName === INSTAGRAM) return <img src={icons.instagram_icon} alt='' style={style} />;
    if (iconName === LINKEDIN) return <img src={icons.linkedin_icon} alt='' style={style} />;
    if (iconName === TWITTER) return <img src={icons.x_icon} alt='' style={style} />;
    if (iconName === SNAPCHAT) return <img src={icons.snapchat_icon} alt='' style={style} />;
    if (iconName === SKYPE) return <img src={icons.skype_icon} alt='' style={style} />;
    if (iconName === BEHANCE) return <img src={icons.behance_icon} alt='' style={style} />;
    if (iconName === YOUTUBE) return <img src={icons.youtube_icon} alt='' style={style} />;
    if (iconName === PINTEREST) return <img src={icons.pinterest_icon} alt='' style={style} />;
    if (iconName === TELEGRAM) return <img src={icons.telegram_icon} alt='' style={style} />;
    if (iconName === WECHAT) return <img src={icons.wechat_icon} alt='' style={style} />;
    if (iconName === TIKTOK) return <img src={icons.tiktok_icon} alt='' style={style} />;
    if (iconName === VIBER) return <img src={icons.viber_icon} alt='' style={style} />;

    return <img src={icons.webpage_icon} alt='' style={style} />;
  }

  const papersx = {
    borderRadius: '25px',
    p: {
      xs: '20px',
      sm: '40px',
    },
    minWidth: {
      xs: '400px',
      sm: '500px',
      md: '800px',
      lg: '850px',
    },
    maxHeight: '60%',
  };

  return <Dialog open={open} onClose={handleClose} PaperProps={{ sx: papersx }}>
    <IconButton onClick={handleClose} sx={{ position: 'absolute', top: '10px', right: '10px' }}>
      <CloseIcon />
    </IconButton>
    <DialogTitle style={{ paddingLeft: 0, paddingRight: 0 }}>View Social Media</DialogTitle>
    <Typography sx={{ fontSize: 16, fontWeight: 500 }} gutterBottom>Social Media</Typography>
    <DialogContent style={{ paddingTop: '20px' }} sx={{ borderRadius: '4px', border: '1px solid #C5C5C5', p: '20px' }}>
      {socials.map((sm, index) => (
        <Link href={sm.link} key={sm.link} style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#FAFAFA',
          padding: '10px',
          borderRadius: '8px',
          marginBottom: index === socials.length - 1 ? 0 : '10px',
          textDecoration: 'none',
        }}>
          {getIcon(sm.type)}
          <Box flex={1} overflow={'hidden'}>
            <Typography textTransform={'capitalize'}>{sm.type}</Typography>
            <Typography sx={{ fontSize: 13, color: '#767F8C', overflow: 'hidden', textOverflow: 'ellipsis' }}>{sm.link}</Typography>
          </Box>
        </Link>
      ))}
      {socials.length === 0 && <Typography fontSize={'18px'} color={'#A7A7A7'}>No result</Typography>}
    </DialogContent>
  </Dialog>
};

export default SocialDialog;