import React, { useEffect, useState } from "react";
import { Box, Typography, Checkbox } from "@mui/material";
import icons from "../../../../../assets/icons";
import FileHelper from "../../../../../lib/helper/fileHelper";

export default function Item({ currency, item, index, handlePreview }:{
  currency: string;
  item: {
    name: string;
    price: string;
    quantity: number;
    variant: {
      name: string;
      front: string;
    };
    customization: number;
    personName?: string;
    jobTitle?: string;
    pixelcare: boolean;
    designid?: string;
    pixelcarePrice?: number;
    customizationPrice?: number;

  };
  index: number;
  handlePreview: (item: any) => void
}) {
  const [frontURL, setFrontURL] = useState<string>();

  useEffect(() => {
    const front_thumb = item.variant.front;

    if (Boolean(front_thumb)) {
      if (front_thumb.slice(0, 5) !== 'https' && front_thumb.slice(0, 10) !== 'data:image') {
        FileHelper.urlToBase64(front_thumb).then(response => {
          setFrontURL(response.base64);
        })
      } else {
        setFrontURL(front_thumb);
      }
    }
  }, [item]);

  return <Box key={index} sx={{ bgcolor: '#FBFAFA', borderRadius: '8px', p: '20px' }}>
    <Box sx={{ display: 'flex', gap: '15px', mb: '10px' }}>
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{
          flex: 1,
          position: 'relative',
          borderRadius: '8px',
          border: '1px solid #C5C5C5',
          display: 'flex',
          cursor: 'pointer',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': { bgcolor: '#F9FAFC' },
        }} onClick={() => handlePreview(item)}>
          <div className='icon' style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: '#A7A7A7',
            mask: `url(${icons.image})`,
            maskSize: 'cover',
            maskRepeat: 'no-repeat',
            WebkitMask: `url(${icons.image})`,
            WebkitMaskSize: 'cover',
            WebkitMaskRepeat: 'no-repeat',
            width: '24px',
            height: '24px',
          }} />
          <Box boxShadow={2} sx={{
            // Items from Zakeke will have designid
            width: Boolean(item.designid) ? '100%' : '105px',
            height: Boolean(item.designid) ? '100%' : '67px',
            borderRadius: '8px',
            background: `url("${frontURL}")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }} />
        </Box>
      </Box>
      <Box sx={{ flex: 2 }}>
        <Typography fontWeight={500} sx={{ mb: '10px' }}>{item.name}</Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns:'1fr 1fr', mb: '5px' }}>
        <Typography sx={{ flex: 1 }}>Price (USD):</Typography>
          {/* <Typography sx={{ flex: 1 }}>Price ({currency}):</Typography> */}
          <Typography sx={{ flex: 1 }}>{(parseFloat(item.price)).toFixed(2)}</Typography>
        </Box>
        { item.customization === 1 && <Box sx={{ display: 'grid', gridTemplateColumns:'1fr 1fr', mb: '5px' }}>
          <Typography sx={{ flex: 1 }}>Customization (USD):</Typography>
          {/* <Typography sx={{ flex: 1 }}>Customization ({currency}):</Typography> */}
          <Typography sx={{ flex: 1 }}>{item.customizationPrice}</Typography>
        </Box> }

        <Box sx={{display: 'grid', gridTemplateColumns:'1fr 1fr', mb: '5px' }}>
          <Typography sx={{ flex: 1 }}>Quantity:</Typography>
          <Typography sx={{ flex: 1 }}>{item.quantity}</Typography>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns:'1fr 1fr', mb: '5px' }}>
          <Typography sx={{ flex: 1 }}>Variant:</Typography>
          <Typography sx={{ flex: 1 }}>{item.variant.name}</Typography>
        </Box>
        {item.customization === 1 && <>
          <Box sx={{ display: 'grid', gridTemplateColumns:'1fr 1fr', mb: '5px' }}>
            <Typography sx={{ flex: 1 }}>Name:</Typography>
            <Typography sx={{ flex: 1 }}>{item.personName}</Typography>
          </Box>
          <Box sx={{ display: 'grid', gridTemplateColumns:'1fr 1fr', mb: '5px' }}>
            <Typography sx={{ flex: 1 }}>Job title:</Typography>
            <Typography sx={{ flex: 1 }}>{item.jobTitle}</Typography>
          </Box>
        </>}
      </Box>
    </Box>
    <Box sx={{ display: 'flex' }}>
      <Checkbox name="pixelcare" checked={item.pixelcare} sx={{ p: 0, mr: '10px' }} disabled />
      <Box sx={{ flex: 1 }}>
        <Typography component={'span'} sx={{ mr: '10px' }}>Protect with PixelCare + </Typography>
        <Typography component={'span'} sx={{ flex: 1, textAlign: 'start', fontSize: '13px', fontWeight: 400, color: '#26A800' }}>USD {item?.pixelcarePrice}</Typography>
        {/* <Typography component={'span'} sx={{ flex: 1, textAlign: 'start', fontSize: '13px', fontWeight: 400, color: '#26A800' }}>{currency}{item?.pixelcarePrice}</Typography> */}
      </Box>
    </Box>
  </Box>
}