import React from 'react'
import ExchangeContact from './exchangeContact.component'
import { useSelector } from "react-redux";
import {  useNavigate } from 'react-router-dom';
import { useParams,createSearchParams } from 'react-router-dom';
import { EXCHANGE_CONTACT_MEMBER_SIGN_IN } from '../../../lib/constants/routes';
import {EXCHANGE_CONTACT_PROFILE_LIST} from '../../../lib/constants/routes'
import accessType from "../../../lib/constants/accessType";
export default function ExchangeContactContainer() {
  const { id } = useParams();
  
  const navigate = useNavigate();
  const landingPage = useSelector((state:any) => state.landingPage)
  const userDetails =  landingPage.profile.user_details
  const contactInfo = landingPage.profile.contact_info
  
  const handleMembersExchange = async () =>{
    let authed = false;
    if (localStorage.getItem('user') !== null) {
      const user = JSON.parse(localStorage.getItem('user') || '');
      authed = user.access_token !== null || user.access_token !== '';
      const sessionExpiration = user.session_expiration;

      var datetime = new Date(0);
      datetime.setUTCSeconds(sessionExpiration)
      if(datetime < new Date()) authed = false;
    }
  
  if(id){
      if(!authed){
        return navigate({
          pathname: EXCHANGE_CONTACT_MEMBER_SIGN_IN,
          search: createSearchParams({
            owner_card_no: landingPage.accessType === accessType.CARD ? id : '',
            owner_profile_no: landingPage.accessType === accessType.WEB ? id : '',
            
          }).toString()
        })
      }
      navigate({
        pathname: EXCHANGE_CONTACT_PROFILE_LIST,
        search: createSearchParams({
          owner_card_no: landingPage.accessType === accessType.CARD ? id : '',
          owner_profile_no: landingPage.accessType === accessType.WEB ? id : '',
        }).toString()
      });
    }
  }
  return <ExchangeContact userDetails={userDetails} contactInfo={contactInfo} handleMembersExchange={handleMembersExchange}/>
} 