import React, {useRef,useState, useEffect} from 'react'
import { styled } from '@mui/system';
import icons from '../../../assets/icons';
import { Box,Button,Typography,Tooltip,ClickAwayListener } from "@mui/material";
import image from "../../../assets/img"
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas'
import {  useNavigate,useParams } from 'react-router-dom';
import fileHelper from '../../../lib/helper/fileHelper';
import { useSelector } from "react-redux";
const CustomHeader = styled('div')({
  paddingTop:"1rem",
  display: 'grid',
  flexDirection: 'row',
  gridTemplateColumns: '1fr 7fr',
  paddingLeft: '1.5rem',
  alignItems: 'center'
});
const UserInfo = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '2rem 1.5rem',
  gap: '1rem',
});

export default function ShareQrComponent ({userDetails}:{userDetails: any}) {
  const { public_name, serial_number } = useSelector((state: any) => state.landingPage.profile);
  // const [deferredPrompt, setDeferredPrompt] = useState(null);
  const profileTheme = useSelector((state: any) => state.landingPage.theme)
  const qrCodeRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();
  const [profileLink, setProfileLink] = useState("")
  const[profileImage,setProfileImage] = useState("")
  const [tooltipOpen,setTooltipOpen] = useState(false);

  const landingPage = useSelector((state:any) => state.landingPage)
  const accessPath = landingPage.accessPath
  const handleQRcodeDownload = async () => {
    const element = qrCodeRef.current;
    if(element){
      const canvas = await html2canvas(element),
      data = canvas.toDataURL('image/jpg'),
      link = document.createElement('a');

      link.href = data;
      link.download = userDetails.full_name+'.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  
  // const handleAddToHomescreen = async () => {
  //   if (deferredPrompt) {
  //     deferredPrompt.prompt();
  //     const choiceResult = await deferredPrompt.userChoice;
  //     if (choiceResult.outcome === 'accepted') {
  //       console.log('User accepted the A2HS prompt');
  //     }
  //     setDeferredPrompt(null);
  //   }
  // };

  const getCompanyImage = async() =>{
    // not using at the moment
    // const fullpath = userDetails.company_image
    // const companyPicUrl =  await fileHelper.getUrl(fullpath).then(repsonse => repsonse)
  }

  const getProfileImage = async() => {
    const fullpath = userDetails.profile_image
    const profilePicUrl =  await fileHelper.getUrl(fullpath).then(repsonse => repsonse)
    setProfileImage(profilePicUrl)
  }
  useEffect(() => {
    setProfileLink(`${process.env.REACT_APP_BASE_URL}/w/${public_name ?? serial_number}`);
    getCompanyImage()
    getProfileImage()
    
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      // setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const copyLink = () =>{
    navigator.clipboard.writeText(profileLink)
    setTooltipOpen(true)
    setTimeout(() => {
      handleTooltipClose()
    }, 1000);
  }
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };
  
  const Avatar = styled('img')({
    borderRadius: "79.5px",
    width: "79.5px",
    height: "79.5px",
    flexShrink: 0,
    background: "lightgray 50% / cover no-repeat",
    border: `solid ${profileTheme?.highlight?.primary_color ||'#FECD20'} 2.5px`,
    
  });

  const CustomActionButton = styled('button')({
    borderRadius: '10px',
    border: `2px solid ${profileTheme?.button?.primary_color || '#FECD20'}`,
    maxWidth: '362px',background: '#FFF', 
    color: profileTheme?.button?.primary_color || 'black',
    fontSize:'17px',
    height: '58px',
    width: '100%',
    "&:hover": {
      backgroundColor: profileTheme?.button?.primary_color || "#FECD20",
      color: profileTheme?.button?.primary_font_color || "#FFF"
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
    justifyItems: 'start',
    alignItems: 'center',
    padding: '10px 10%'
  })

  return <>
  <CustomHeader>
    <img src={icons.back_icon} alt="back_icon" onClick={() => navigate(accessPath+`/${id}`)}/>
    <Box>
    <Typography style={{
      fontSize: '1.25rem',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '120%'}}>
      Share Contact
    </Typography>
    </Box>
  </CustomHeader>
  <UserInfo>
    <Avatar sx={{  backgroundImage: `url(${profileImage || image.empty_avatar})`,}}/>
    <Box>
      <Typography style={{
        fontSize: '1.25rem',
        fontStyle: 'normal',
        fontWeight: '700'
      }}>
        {userDetails.full_name}
      </Typography>
      <Typography
      style={{
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: '400'
      }}>
        {userDetails.job_title}
      </Typography>
      <Box style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '5px'
      }}>
      <Typography
      style={{
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: '600'
      }}>
      {userDetails.company_name}
      </Typography>
      </Box>
    </Box>
  </UserInfo>
  <Box style={{
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center'
  }}>
    <div style={{ 
      width: '260px',
      height: '260px',
      borderRadius: '1.07606rem',
      background: '#FFF',
      boxShadow: '0px 0px 17.217px 0px rgba(0, 0, 0, 0.15)',
      display: 'grid',
    }}>
      <div ref={qrCodeRef} style={{
        display: 'grid',
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        padding:'1.18rem',width: "-webkit-fill-available"}}>
      <QRCode
        size={208}
        // logoWidth="80"
        // logoHeight="80"
        // logoImage={companyImage}
        value={profileLink}
      />
      </div>
  </div>
  </Box>
  <Box style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }}>
    <div style={{width: '277px',padding: '35px 0'}}>
      <Typography style={{textAlign: 'center', fontSize:"14px"}}>
      Scan with your phone’s camera or QR code app to view {userDetails.full_name}’s Profile
      </Typography>
    </div>
  </Box>
  <Box
  style={{
    display: 'grid',
    justifyItems: 'center'
  }}>
    
  <Button
    onClick={() => navigator.share({
      title: "Pixel",
      text: "Pixel card",
      url: profileLink,
    })}
    type="button"
    fullWidth
    variant="contained"
    sx={{ 
      maxWidth: '237px',padding: '16px 20px',
      background: profileTheme?.button?.secondary_color || 'black', 
      borderRadius: '30px', 
      color: profileTheme?.button?.secondary_font_color || '#FECD20',
      fontSize:'17px',fontWeight:'600', 
      "&:hover": {
      backgroundColor: profileTheme?.button?.primary_color || "#FECD20",
      color: profileTheme?.button?.primary_font_color || "black"
    } }}
  >
    Share QR Code
  </Button>
  </Box>
  <Box
    style={{
      display: 'grid',
      justifyItems: 'center',
      padding: '38px',
      gap: '0.8rem'
    }}
  >
  {/* <CustomActionButton>
    <img src={icons.apple_wallet_icon}/>
    Add Card to Apple Wallet
  </CustomActionButton> */}
  {/* <CustomActionButton>
  <img src={icons.smart_phone} alt="smart_phone" onClick={() => handleAddToHomescreen()}/>
    Add QR to Home Screen
  </CustomActionButton> */}
  <CustomActionButton onClick={() => handleQRcodeDownload()}>
  <img src={icons.download} alt="download"/>
    Save QR Code to Photos
  </CustomActionButton>
  <ClickAwayListener onClickAway={handleTooltipClose}>
    <Tooltip
          placement='top'
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={tooltipOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Link Copied"
        >
    <CustomActionButton  onClick={() => copyLink()}>
    <img src={icons.copy} alt="copy"/>
      Copy Card Link
    </CustomActionButton>
    </Tooltip>
  </ClickAwayListener>
  </Box>
  </>
}