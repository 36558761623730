import React, { useState } from 'react'
import { Collapse, Dialog, Paper, Typography } from '@mui/material';
import ExpandMore from '../component/expandMore.component';
import fileHelper from '../../../lib/helper/fileHelper';
import images from '../../../assets/img';

export default function GalleryComponent({ itemData }) {
  const [expanded, setExpanded] = useState('gallery');
  const [open, setOpen] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState<string | undefined>();
  const maxRetries = 2;
  let errorCount: any = {};

  const openDialog = (item) => {
    setImgSrc(item.img);
    setOpen(true);
  }

  const onImageError = async (e) => {
    errorCount[e.target.src] = Boolean(errorCount[e.target.src]) ? errorCount[e.target.src] + 1 : 1;

    // If exceeds max retries, just set the src to placeholder image
    if (errorCount[e.target.src] > maxRetries) {
      e.target.src = images.landing_page_default_image;
      return;
    }

    let url = new URL(decodeURI(e.target.src));
    let newURL = await fileHelper.getUrl(decodeURI(url.pathname.slice(1)))
    e.target.src = newURL;
  }

  return (
    <Paper sx={{ m: '10px 14px', p: '5px', borderRadius: '10px' }}>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ margin: '3px 6px', fontSize: '17px', fontWeight: 700, flex: 1 }}>
          Gallery
        </Typography>
        <ExpandMore
          expanded={expanded === 'gallery'}
          onClick={() => setExpanded(expanded === 'gallery' ? '' : 'gallery')}
          aria-expanded={expanded === 'gallery'}
          aria-label="show more"
        />
      </div>

      <Collapse sx={{ p: 0, '& .MuiCollapse-wrapperInner': { display: 'flex ', flexWrap: 'wrap' } }} in={expanded === 'gallery'} timeout="auto" unmountOnExit>
        {itemData.map((item, index) => (
          <div key={index} style={{ aspectRatio: (index === itemData.length - 1 && itemData.length % 2 === 1) ? 2 : 1, flex: '150px', margin: '3px 6px', cursor: 'pointer' }} onClick={() => openDialog(item)}>
            <img onError={onImageError} src={item.img} alt={item.title} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }} />
          </div>
        ))}
      </Collapse>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      >
        <img
          onError={onImageError}
          style={{ maxWidth: "100%", height: "auto" }}
          src={imgSrc}
          alt="popup"
        />
      </Dialog>
    </Paper>
  );
}