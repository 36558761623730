import axios from "axios";

const resendEmailVerification =  ({
    email
}:{email:string}) => {
    var params = new URLSearchParams([['email', email]]);
    return new Promise((resolve, reject) => {
        axios.get("basicauth/resendEmailVerification", {params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
    
};

export default resendEmailVerification;
