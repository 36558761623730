import React, { useEffect } from 'react';
import NotificationsComponent from './notifications.component';
import { setBreadcrumb } from '../../../../features/admin';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setNotifications } from "../../../../features/notifications/notifications.slice";
import { DASHBOARD } from '../../../../lib/constants/routes';
import { startLoading,endLoading} from '../../../../features/loading';
import notificationApi from '../../../../api/notification';

export default function Notifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notifications = useSelector((state: any) => state.notifications.notifications);

  const handleDelete = () => {
    dispatch(startLoading());
    const uuid_list = notifications.filter((noti: any) => noti.is_checked).map((noti: any) => noti.uuid);
    notificationApi.deleteNotification(uuid_list)
      .then(() => dispatch(setNotifications(notifications.filter(noti => !noti.is_checked))))
      .finally(() => dispatch(endLoading()));
  }

  const handleMarkAsRead = () => {
    dispatch(startLoading());
    const uuid_list = notifications.filter((noti: any) => noti.is_checked).map((noti: any) => noti.uuid);
    notificationApi.markAsRead(uuid_list)
      .then(() => dispatch(setNotifications(notifications.map(noti => ({
        ...noti,
        is_read: noti.is_checked ? true : noti.is_read,
      }))))).finally(() => dispatch(endLoading()));
  }

  const handleChange = (index: number) => {
    dispatch(setNotifications([
      ...notifications.slice(0, index),
      {
        ...notifications[index],
        is_checked: !notifications[index].is_checked,
      },
      ...notifications.slice(index + 1),
    ]));
  }

  const handleAllChange = event => {
    if (event.target.checked) {
      dispatch(setNotifications(notifications.map(noti => ({
        ...noti,
        is_checked: true,
      }))));
    } else {
      dispatch(setNotifications(notifications.map(noti => ({
        ...noti,
        is_checked: false,
      }))));
    }
  }

  const handleAction = (path: string) => {
    navigate(path);
  }

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "Dashboard", path: DASHBOARD },
        { name: "All Notifications", path: null },
      ]
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NotificationsComponent
    notifications={notifications}
    handleChange={handleChange}
    handleAllChange={handleAllChange}
    handleDelete={handleDelete}
    handleMarkAsRead={handleMarkAsRead}
    handleAction={handleAction}
  />
}