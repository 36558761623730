import React,{useEffect,Fragment} from 'react';
import {Box, Button, Typography } from "@mui/material";
import Picker from 'react-mobile-picker';
import moment from 'moment';
import { Dialog,Transition} from '@headlessui/react'
let selections: { [key: string]: string[] } = {
  day: Array.from({ length: 31 }, (_, i) => `${(i + 1).toString().padStart(2, '0')}`),
  month: Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('default', { month: 'long' })),
  year: Array.from({ length: 150 }, (_, i) => (new Date().getFullYear() - i).toString()),
}

export default function DatePicker({ openPicker, setOpenPicker, pickerValue, setPickerValue, handleCancel, handleConfirm }:{
  openPicker: boolean,
  setOpenPicker: React.Dispatch<React.SetStateAction<boolean>>,
  pickerValue: { day: string, month: string, year: string},
  setPickerValue: React.Dispatch<React.SetStateAction<{ day: string; month: string; year: string;}>>,
  handleCancel: () => void,
  handleConfirm: () => void
}) {
  useEffect(() => {
    if (pickerValue.month !== '' || pickerValue.year !== '') {
      const daysInMonth = moment(`${pickerValue.year}-${pickerValue.month}`, 'YYYY-MMM').daysInMonth();
      const updatedDaySelections = Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`);
      selections = { ...selections, day: updatedDaySelections };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerValue.month,pickerValue.year]);
  
  return <Transition appear show={openPicker} as={Fragment}>
  <Dialog
    open={openPicker}
    onClose={() => setOpenPicker(false)}
    aria-labelledby="start time picker dialog"
    // PaperProps={{ style: { borderRadius: '16px', color: '#6c6c6c' } }}
    style={{ position: 'relative', zIndex: 10}}
  >
    <Box sx={{display:'flex', justifyContent:'center',mt:'1rem'}}>
      <Typography variant="h4"> Date of Birth</Typography>
    </Box>
    <Dialog.Panel style={{
      minWidth: '330px',
      padding: '20px 70px',
    }}>
      <div style={{}}>
        <div style={{}}>
      <Picker value={pickerValue} onChange={setPickerValue} wheelMode='natural'>
        {Object.keys(selections).map(name => (
          <Picker.Column key={name} name={name}>
            {selections[name].map((option: string) => ( // Fix: Add type annotation for option
              <Picker.Item key={option} value={option}>
                {option}
              </Picker.Item>
            ))}
          </Picker.Column>
        ))}
      </Picker>
      </div>
      </div>
    </Dialog.Panel>
    <Box style={{display:'flex',  justifyContent: 'center' }}>
      <Button
        type="button"
        sx={{ color: '#c4c4c4', textTransform: 'none' }}
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        type="button"
        sx={{ color: '#6c6c6c', textTransform: 'none' }}
        onClick={handleConfirm}
      >
        Confirm
      </Button>
    </Box>
  </Dialog>
  </Transition> 
}
