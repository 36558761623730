import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import MobileFrame from '../../../../../components/live-preview/mobile-frame';
import styled from "@emotion/styled";
import parse from 'html-react-parser';
import { useSelector } from "react-redux";
import images from "../../../../../assets/img";
import VoucherSlider, { PixelThumbComponent } from "../../../../../components/voucher-slider";

export default function Preview({ values }: { values: any }) {
  const backgroundColor = useSelector((state: any) => state.landingPage.background_color) || "#FFF"
  const [value, setValue] = useState(0);
  const [bannerImage, setBannerImage] = useState()
  const [startAt, setStartAt] = useState("");
  const [endAt, setEndAt] = useState("3");
  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [termsCondition, setTermsCondition] = useState('')

  const TitleContainer = styled(Box)({
    display: "flex",
    padding: "1rem",
    ".title": {
      fontSize: "calc(20px - 10%)",
      fontWeight: "500"
    },
  })

  const DescriptionContainer = styled(Box)({
    padding: "1rem",
    ".contain": {
      paddingBottom: "1rem",
    },
    ".description": {
      fontSize: "calc(14px - 10%)",
      fontWeight: "400"
    },
    ".title": {
      fontSize: "calc(16px - 10%)",
      fontWeight: "600"
    }
  })

  useEffect(() => {
    if (values) {
      setBannerImage(values.voucherDetails.bannerImage);
      setStartAt(values.voucherType.startDateTime);
      setEndAt(values.voucherType.endDateTime);
      setTitle(values.voucherDetails.voucherTitle);
      setDescription(values.voucherDetails.description);
      setTermsCondition(values.termsAndCondition);
    }
  }, [values])

  return <Box sx={{
    display: 'flex',
    justifyContent: 'center',
    height: '700px'
  }}>
    <MobileFrame backgroundColor={backgroundColor}>
      <Box sx={{ display: "flex", padding: "1rem", justifyContent: 'center' }}>
        {/* <img src={icons.back_icon} style={{position:"fixed"}}/> */}
        <Typography variant="h4"> Promotions </Typography>
      </Box>
      <img
        alt='Voucher banner'
        src={bannerImage ? URL.createObjectURL(bannerImage) : images.landing_page_default_image}
        style={{ width: '100%' }}
      />
      <TitleContainer>
        <Typography className="title">
          {title}
        </Typography>
      </TitleContainer>

      <Box sx={{ mx: '45px', height: '88px', position: 'relative' }}>
        <Box sx={{
          width: 'calc(100% + 58px)',
          height: 'calc(100% - 30px)',
          position: 'absolute',
          left: '-29px',
          borderRadius: '50px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          my: '15px',
          bgcolor: '#FECD20',
        }}>
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>Slide to Collect</Typography>
        </Box>
        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <VoucherSlider
            slots={{ thumb: PixelThumbComponent }}
            value={value}
            onChange={(e, val) => setValue(Number(val.toString()))}
            onChangeCommitted={() => setValue(0)}
          />
        </Box>
      </Box>

      <Box sx={{ position: "absolute", height: "58px" }}>
      </Box>
      <DescriptionContainer sx={{ padding: "1rem" }}>
        <Box className="contain">
          <Typography className="description">Validity Period</Typography>
          <Typography className="title">{startAt} - {endAt}</Typography>
        </Box>
        <Box className="contain">
          <Typography className="description">
            {description}
          </Typography>
        </Box>

        <Box className="contain">
          <Typography className="title">
            Terms & Conditions
          </Typography>
          <Typography className="description">
            <>
              {parse(termsCondition)}
            </>
          </Typography>
        </Box>
      </DescriptionContainer>
    </MobileFrame>
  </Box>;
}