import React from 'react';
import { Box, Typography } from '@mui/material';
import icons from '../../../../assets/icons';

const DraggableDocItem = ({ file, file_name, handleRemove, disabled = false }) => (
    <div style={{ borderLeft: '2px solid', display: 'flex', flexWrap: 'wrap', alignItems: 'center', opacity: disabled ? 0.5 : 1, justifyContent: 'space-between' }}>
      <img src={icons.more_02} alt='' style={{ marginLeft: '10px', marginRight: '10px', order: 1 }} />
      <Box component={'span'} sx={{ display: 'flex', minWidth: '250px', flex: 1, order: { xs: 3, sm: 2 } }}>
        <img src={icons.file_attachment} alt='' style={{ margin: '10px' }} />
        <span style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          <span>
            <Typography component={'p'} variant='h4'>{typeof file === 'string' ? file_name : (file?.name || "")}</Typography>
            <Typography component={'p'} variant='h5'>{typeof file === 'string' ? '' : `${file?.size} bytes`}</Typography>
          </span>
        </span>
      </Box>
      <Box sx={{ order: { xs: 2, sm: 3 } }}><img src={icons.delete_icon} alt='' style={{ marginLeft: '10px', marginRight: '10px' }} onClick={disabled ? () => {} : handleRemove} /></Box>
    </div>
  );

export default DraggableDocItem;