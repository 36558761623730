import { Theme  } from '../types/Profile';

export const defaultTheme: Theme = {
  name: 'DEFAULT',
  highlight:{
    primary_color: '#FECD20',
    secondary_color: '#373736',
  },
  button:{
    primary_color: '#FECD20',
    primary_font_color: '#000000',
    secondary_color: '#373736',
    secondary_font_color: '#FECD20',
  },

  primary_background: '#FECD20',
  primary_font_color: '#000000',
  secondary_background: '#373736',
  secondary_font_color: '#FECD20',
}

export const blackYellowTheme : Theme ={
  name: 'BLACKYELLOW',
  highlight:{
    primary_color: '#E6F14A',
    secondary_color: '#373736',
  },
  button:{
    primary_color: '#373736',
    primary_font_color: '#E6F14A',
    secondary_color: '#E6F14A',
    secondary_font_color: '#373736',
  },
  primary_background: '#373736',
  primary_font_color: '#E6F14A',
  secondary_background: '#E6F14A',
  secondary_font_color: '#373736',

}

export const roseBlueTheme : Theme ={
  name: 'ROSEBLUE',
  highlight:{
    primary_color: '#EA928B',
    secondary_color: '#476C8B',
  },
  button:{
    primary_color: '#EA928B',
    primary_font_color: '#FFFFFF',
    secondary_color: '#476C8B',
    secondary_font_color: '#FFFFFF',
  },
  primary_background: '#EA928B',
  primary_font_color: '#FFFFFF',
  secondary_background: '#476C8B',
  secondary_font_color: '#FFFFFF',
}

export const purpleTheme : Theme ={
  name: 'PURPLE',
  highlight:{
    primary_color: '#412283',
    secondary_color: '#BDBCDF',
  },
  button:{
    primary_color: '#412283',
    primary_font_color: '#FFFFFF',
    secondary_color: '#BDBCDF',
    secondary_font_color: '#412283',
  },
  primary_background: '#412283',
  primary_font_color: '#412283',
  secondary_background: '#BDBCDF',
  secondary_font_color: '#FFFFFF',
}

export const redBlackTheme: Theme = {
  name: 'REDBLACK',
  highlight: {
    primary_color: '#F76060',
    secondary_color: '#373736',
  },
  button: {
    primary_color: '#F76060',
    primary_font_color: '#FFFFFF',
    secondary_color: '#373736',
    secondary_font_color: '#FFFFFF',
  },
  primary_background: '#F76060',
  primary_font_color: '#FFFFFF',
  secondary_background: '#373736',
  secondary_font_color: '#FFFFFF',
};

export const popsicleBlueTheme: Theme = {
  name: 'POPSICLEBLUE',
  highlight: {
    primary_color: '#66D0DA',
    secondary_color: '#D8EEF0',
  },
  button: {
    primary_color: '#66D0DA',
    primary_font_color: '#FFFFFF',
    secondary_color: '#D8EEF0',
    secondary_font_color: '#373736',
  },
  primary_background: '#66D0DA',
  primary_font_color: '#373736',
  secondary_background: '#D8EEF0',
  secondary_font_color: '#FFFFFF',
};

export const pinkPeachTheme: Theme = {
  name: 'PINKPEACH',
  highlight: {
    primary_color: '#EB8E7D',
    secondary_color: '#FCD5CE',
  },
  button: {
    primary_color: '#EB8E7D',
    primary_font_color: '#FFFFFF',
    secondary_color: '#FCD5CE',
    secondary_font_color: '#373736',
  },
  primary_background: '#EB8E7D',
  primary_font_color: '#373736',
  secondary_background: '#FCD5CE',
  secondary_font_color: '#FFFFFF',
};

export const limeYellowBlackTheme: Theme = {
  name: 'LIMEYELLOWBLACK',
  highlight: {
    primary_color: '#E6F14A',
    secondary_color: '#373736',
  },
  button: {
    primary_color: '#E6F14A',
    primary_font_color: '#373736',
    secondary_color: '#373736',
    secondary_font_color: '#E6F14A',
  },
  primary_background: '#E6F14A',
  primary_font_color: '#E6F14A',
  secondary_background: '#E6F14A',
  secondary_font_color: '#373736',
};

export const limeGreenTheme: Theme = {
  name: 'LIMEGREEN',
  highlight: {
    primary_color: '#439775',
    secondary_color: '#2A4747',
  },
  button: {
    primary_color: '#439775',
    primary_font_color: '#FFFFFF',
    secondary_color: '#2A4747',
    secondary_font_color: '#FFFFFF',
  },
  primary_background: '#439775',
  primary_font_color: '#FFFFFF',
  secondary_background: '#2A4747',
  secondary_font_color: '#FFFFFF',
};

export const bluePurpleTheme: Theme = {
  name: 'BLUEPURPLE',
  highlight: {
    primary_color: '#4A8DCC',
    secondary_color: '#7C17D7',
  },
  button: {
    primary_color: '#4A8DCC',
    primary_font_color: '#FFFFFF',
    secondary_color: '#7C17D7',
    secondary_font_color: '#FFFFFF',
  },
  primary_background: '#4A8DCC',
  primary_font_color: '#FFFFFF',
  secondary_background: '#7C17D7',
  secondary_font_color: '#FFFFFF',
};

export const blackGreyTheme: Theme = {
  name: 'BLACKGREY',
  highlight: {
    primary_color: '#373736',
    secondary_color: '#C5C5C5',
  },
  button: {
    primary_color: '#373736',
    primary_font_color: '#FFFFFF',
    secondary_color: '#C5C5C5',
    secondary_font_color: '#373736',
  },
  primary_background: '#373736',
  primary_font_color: '#373736',
  secondary_background: '#C5C5C5',
  secondary_font_color: '#FFFFFF',
};