import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, Typography, Box, Button, Avatar, Radio } from '@mui/material';
import { closeSelectProfileDialog, confirmSelectProfileDialog } from '../../../features/select-profile-dialog';
import subscriptionApi from 'src/api/subscription';
import { openResponseDialog } from 'src/features/response-dialog';

const DialogActions = styled('div')({
  display: 'flex',
  gap: '1rem',
  padding: '0 1rem',
  justifyContent: 'center'
})

const SelectProfileDialog = () => {
  const [selectedUuid, setSelectedUuid] = useState<string>();
  const { open, profiles, isConfirmed, isFullWidth } = useSelector((state: any) => state.selectProfileDialog);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeSelectProfileDialog());
  }

  const handleConfirm = () => {
    subscriptionApi.keepProfile(`${selectedUuid}`).then(() => {
      dispatch(openResponseDialog({
        title: "Profile Re-enabled",
        description: "Selected profile re-enabled. Consider subscribing to enable other profiles"
      }));
      dispatch(confirmSelectProfileDialog());
    })
  }

  useEffect(() => {
    if (isConfirmed) dispatch(closeSelectProfileDialog())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed])

  return (
    <Dialog fullWidth={isFullWidth} onClose={handleClose} open={open} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
      <DialogTitle style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Typography style={{
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '500',
          textAlign: 'center',
          overflowWrap: 'break-word'
        }}>Select a Profile</Typography>
      </DialogTitle>
      <Typography sx={{ textAlign: 'center', overflowWrap: 'break-word', mx: '20px' }}>
        Your subscription expired. Select a profile to re-enable.
      </Typography>
      <Box sx={{ p: '20px' }}>
        {profiles.map((prf: any, index: number) => <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: `1px solid ${selectedUuid === prf.profile_uuid ? '#26A800' : '#C5C5C5'}`,
            borderRadius: '4px',
            p: '10px',
            cursor: 'pointer',
            mb: '10px',
          }}
          onClick={() => setSelectedUuid(prf.profile_uuid)}
        >
          <Box sx={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
          }}>
            <Avatar src={prf.imgURL} />
            <Box>
              <Typography>{prf.name}</Typography>
              <Typography>{prf.company_name}</Typography>
            </Box>
          </Box>
          <Radio
            checked={selectedUuid === prf.profile_uuid}
            sx={{
              color: '#26A800',
              '&.Mui-checked': {
                color: '#26A800',
              },
            }}
          />
        </Box>)}
        <DialogActions>
          <Button
            type="button"
            fullWidth
            onClick={() => handleClose()}
            variant="outlined"
            sx={{
              mt: 3, mb: 2, maxWidth: "150px", borderRadius: '30px', border: "solid #FECD20", background: "white", color: '#373736',
              "&:hover": {
                backgroundColor: "#373736",
                color: "#FECD20"
              }
            }}
          >
            {'Cancel'}
          </Button>
          <Button
            disabled={!Boolean(selectedUuid)}
            type="button"
            fullWidth
            onClick={() => handleConfirm()}
            variant="contained"
            sx={{
              mt: 3, mb: 2, maxWidth: "150px", borderRadius: '30px', background: "#373736", color: '#FECD20',
              "&:hover": {
                backgroundColor: "#FECD20",
                color: "#373736"
              }
            }}
          >
            {'Confirm'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default SelectProfileDialog;