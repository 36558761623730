const baseURL = process.env.REACT_APP_BASE_URL;

function getShareProfileLink({username,user_uuid}) {
  return { 
    title: 'Pixelcard',
    text: username,
    url: encodeURI(baseURL+`/${username}-${user_uuid}`)
  }
}

function getProfileLink({ accessPath = '/w',serialNo }) {
  return  encodeURI(`${process.env.REACT_APP_BASE_URL}${accessPath}/${serialNo}`)
}

const profileHelper = {
  getShareProfileLink,
  getProfileLink
}
export default profileHelper