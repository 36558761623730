import React from "react";
import { Paper, Typography } from "@mui/material";
import CustomSwitch from "../customSwitch";

export default function AppointmentStatusComponent({ status, onChange }) {
  return <Paper elevation={0} sx={{
    display: 'flex',
    p: '30px',
    bgcolor: '#F9F9F9',
    alignItems: 'center',
  }}>
    <Typography sx={{ flex: 1 }}>Enable appointment booking</Typography>
    <CustomSwitch checked={status} onChange={onChange} />
  </Paper>
}