import React from "react";
import FilesComponent from "./files.component";

export default function FilesContainer({
  totalSections,
  order,
  reorder,
  reorderSection,
  docItems,
  setDocItems,
  isEnabledFiles,
  setIsEnabledFiles,
  getListStyle,
  getItemStyle,
  remove,
  handleDocChange
}) {
  return <FilesComponent
    totalSections={totalSections}
    order={order}
    reorderSection={reorderSection}
    docItems={docItems}
    setDocItems={setDocItems}
    isEnabledFiles={isEnabledFiles}
    setIsEnabledFiles={setIsEnabledFiles}
    getListStyle={getListStyle}
    getItemStyle={getItemStyle}
    remove={remove}
    handleDocChange={handleDocChange}
    reorder={reorder}
  />;
}