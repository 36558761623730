import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  content: {
    title: "",
    description: "",
    fullWidth: false
  },
  isCancelled: false,
  isConfirmed: false,
  cancelAction: null,
  action: null,
  hasCancelButton: true,
};

const actionDialogSlice = createSlice({
  name: 'action_dialog',
  initialState,
  reducers: {
    openActionDialog: (state,action) => {
      state.open = true;
      const { payload } = action;
      state.content = payload;
      if(payload?.fullWidth){ 
        state.content.fullWidth = payload.fullWidth
      }else{
        state.content.fullWidth = false
      }
      payload?.action ?state.action = payload.action : state.action = null
      state.cancelAction = payload.cancelAction;
      state.hasCancelButton = payload.hasCancelButton ?? true;
    },
    closeActionDialog: (state) => {
      state.isConfirmed = false
      state.isCancelled = false;
      state.open = false;
      state.action = null
      state.cancelAction = null;
    },
    cancelActionDialog: (state) => {
      state.isCancelled = true;
    },
    confirmActionDialog: (state) =>{
      state.isConfirmed = true
    }
  },
});

export const { openActionDialog, closeActionDialog, cancelActionDialog, confirmActionDialog } = actionDialogSlice.actions;
export const selectDialogOpen = (state) => state.action_dialog.open;
export const actionDialogReducer = actionDialogSlice.reducer;