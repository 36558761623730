import React from "react";
import { Avatar, Box, Button, Card, CardActions, CardContent, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import icons from "../../../assets/icons";
import moment from "moment";
import ActionsDialog from "./actionsDialog.component";

const HistoryTable = ({ events, profiles }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = React.useState<any>({});
  const chipStyle = {
    mx: '5px',
    borderRadius: '30px',
    fontSize: 13,
    fontWeight: 500,
    textTransform: 'capitalize',
  };
  const completedChipStyle = {
    ...chipStyle,
    background: '#E7F6EA',
    color: '#0BA02C',
    border: '1px solid #47BB68',
  };
  const declinedChipStyle = {
    ...chipStyle,
    background: '#FFECEC',
    color: '#FF0000',
    border: '1px solid #FF0000',
  };

  return <>
    <TableContainer sx={{ display: { xs: 'none', sm: 'block' }, boxShadow: 0, background: 'transparent' }} component={Paper}>
      <Table sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: '0px 10px' }} aria-label="appointment history table">
        <TableHead>
          <TableRow sx={{ '& td, & th': { border: 'none' } }}>
            <TableCell>Name</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="center">Time</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody >
          {events.map((data) => (
            <TableRow
              key={data.appointment_uuid}
              sx={{ background: '#F0F0F0', '& td, & th': { border: 'none' } }}
            >
              <TableCell component="th" scope="row" sx={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', display: 'flex', alignItems: 'center' }}>
                <Avatar src={data.profile_picture ?? icons.profile_picture_icon} sx={{ width: 55, height: 55 }} />
                <Box sx={{ ml: '10px' }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: 400, mb: '5px' }}>{data.name}</Typography>
                  <Box
                    component={'span'}
                    sx={{
                      color: '#EEECFF',
                      background: data.type === 'requester' ? '#826FFF' : '#A638E9',
                      borderRadius: '4px',
                      fontSize: '10px',
                      p: '5px',
                      textTransform: 'capitalize',
                    }}>
                    {data.type}
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="center">
                {moment(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
              </TableCell>
              <TableCell align="center">
                {moment(data.time, 'HH:mm').format('h:mmA')}
              </TableCell>
              <TableCell align="center">
                <Chip sx={data.status === 'completed' ? completedChipStyle : declinedChipStyle} label={data.status} />
              </TableCell>
              <TableCell align="center" sx={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
                <Button
                  size="small"
                  sx={{ color: 'black', backgroundColor: '#FECD20', borderRadius: '30px', px: '20px', marginLeft: '10px' }}
                  onClick={() => {
                    setSelectedAppointment(data);
                    setOpenDialog(true);
                  }}
                >
                  Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ActionsDialog appointment={selectedAppointment} open={openDialog} handleClose={() => setOpenDialog(false)} isHistory={true} profile={profiles.filter(prf => prf.uuid === events.filter(appt => appt.appointment_uuid === selectedAppointment.appointment_uuid)[0]?.profile_uuid)[0]} />
    </TableContainer>
    <Box sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: 'column', gap: '10px' }}>
      {events.map(data => <Card key={data.appointment_uuid} elevation={0} sx={{ display: 'flex', bgcolor: '#F0F0F0', borderRadius: '8px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={data.profile_picture ?? icons.profile_picture_icon} sx={{ width: 57, height: 57 }} alt="" />
            <Box sx={{ ml: '20px', flex: 1 }}>
              <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>{data.name}</Typography>
              <Typography sx={{ fontSize: '13px', fontWeight: 400, color: '#373736' }}>{moment(`${data.date}${data.time}`, 'YYYY-MM-DDTHH:mm').format('D MMMM YYYY, h:mm A')}</Typography>
              <Box component={'span'} sx={{ color: '#EEECFF', background: data.type === 'requester' ? '#826FFF' : '#A638E9', borderRadius: '4px', fontSize: '10px', p: '5px' }}>{data.type === 'requester' ? 'Requester' : 'Host'}</Box>
              <Chip sx={data.status === 'completed' ? completedChipStyle : declinedChipStyle} label={data.status} />
            </Box>
          </CardContent>
          <CardActions sx={{ p: '0 20px 20px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              sx={{ borderRadius: '30px' }}
              onClick={() => {
                setSelectedAppointment(data);
                setOpenDialog(true);
              }}
            >
              Details
            </Button>
          </CardActions>
        </Box>
      </Card>)}
    </Box>
  </>
}

export default HistoryTable;