import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Button,
  Container,
  CssBaseline,
  Paper,
  Typography
} from '@mui/material';
import {useNavigate } from 'react-router-dom';
import pixel_logo from '../../../assets/pixel_logo.svg'
import tick_icon from '../../../assets/tick_icon.svg'
import { SIGN_IN } from "../../../../../lib/constants/routes.js";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

export default function Success(){
  const navigate = useNavigate();
  const openSignIn = () => {
    navigate(SIGN_IN)
  } 
  return (
    <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs" 
    style={{ 
      width: '336px',
      height: '100vh',
      display: 'grid',
      alignContent: 'space-around',
      justifyContent: 'center'
      }}>
      <CssBaseline />
      <Paper elevation={9}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '3rem 2rem 2rem 2rem',
          borderRadius: '8.78px',
          width: "inherit"
        }}
      >
        <div style={{ paddingTop: '0.5rem',paddingBottom:"4rem" }}>
          <img src={pixel_logo} alt='PixelCard logo'/>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          paddingBottom:'3rem'
        }}>
          <img src={tick_icon} alt="tick"/>
        </div>
        <Typography component="h1" variant="h4" style={{paddingBottom:'0.5rem'}}>
          Reset Successful
        </Typography>
        <Typography sx={{fontWeight:"400",textAlign: 'center',paddingBottom:'2rem'}}>         
          You can now log into your account
        </Typography>
        
        <Button
            type="button"
            onClick={() => openSignIn()}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, borderRadius: '30px', color: '#FECD20' }}
          >
            ok
          </Button>
      </Paper>
    </Container>
    </ThemeProvider>
    );
}