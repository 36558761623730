import axios from "axios";

const signup = ({requestBody}) => {
  return new Promise((resolve, reject) => {
    axios.post("basicauth/signup",requestBody)
    .then(response => resolve(response.data))
    .catch(error => reject(error.response.data));
})
};

export default signup;
