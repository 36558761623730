import axios from "axios";

interface Order {
  bill_name: string;
  bill_email: string;
  bill_mobile: string;
  bill_addr1: string;
  bill_addr2?: string;
  bill_city: string;
  bill_state: string;
  bill_zip: string;
  bill_country: string;
  currency: string;
  channel: string;
  subscription_id: number;
  ref_code: string;
}

interface Data {
  code: number,
  data: OrderResponse,
  message: string | null,
  success: boolean
}

interface OrderResponse {
  plan_id: string;
  order_uuid: string;
  order_ref_id: string;
  rcode: string;
}
const testSubscribe = (requestBody: Order): Promise<Data> => {
  return new Promise((resolve, reject) => {
    axios.post("order/testSubscribe", requestBody)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default testSubscribe;