import axios from "axios";

interface ZakekeResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

const getAuthKey = (): Promise<ZakekeResponse> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  return new Promise((resolve, reject) => {
    axios.get(`basiczakeke/getZakekeAuthKey?user_uuid=${user.access_token_payload.uuid}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default getAuthKey;