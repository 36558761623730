import getCheckSession from "./getCheckSession.api"
import guestSignup from "./guestSignup.api"
import signup from "./signup.api"
import signin from "./signin.api"
import voucherSignup from "./voucherSignup.api"

const basicAuthApi = {
  getCheckSession,
  guestSignup,
  signup,
  signin,
  voucherSignup
}

export default basicAuthApi
