import axios from "axios";

interface Data {
  code: number;
  data: any;
  message: string | null;
  success: boolean;
}

const editCart = (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  return new Promise((resolve, reject) => {
    axios.get(`basiczakeke/editCart?user_uuid=${user.access_token_payload.uuid}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default editCart;