import React, {useEffect} from "react";
import CampaignListComponent from "./campaignList.component";
import { useDispatch} from 'react-redux';
import { setBreadcrumb } from "../../../../features/admin";
import { PORTAL_CAMPAIGNS } from "../../../../lib/constants/routes";
import campaignApi from "../../../../api/campaign";
import { openErrorDialog } from "../../../../features/error-dialog";
import { startLoading, endLoading } from "../../../../features/loading";
import { Campaign } from "../../../../lib/types/Voucher";
import auth from "../../../../api/auth";

export default function CampaignListContainer() {
    const dispatch = useDispatch();
    const [campaignList, setCampaignList] = React.useState<Array<Campaign>>([]);
    const [accessRights, setAccessRights] = React.useState<string[]>([]);
    const [hasAccess, setHasAccess] = React.useState<boolean>(true);

    const refreshList = async () => {
      dispatch(startLoading())
      await campaignApi.getList().then(response => {
        dispatch(endLoading())    
          if(response.success){
            setCampaignList(response.data)
          }
        }).catch(error => {
          dispatch(endLoading())
          dispatch(openErrorDialog({
            title: "Get Campaign List Error",
            description: error.message
          }))
        })
    }

    useEffect(() => {
      auth.getAccessLimit().then(value => {
        setAccessRights(value.data.access_right);
        const access_right = value.data.access_right
        //TODO: only check if the user has access to the campaign add seems insufficient
        setHasAccess(access_right.includes("campaign add"))
  

      }).catch(error => {
        setHasAccess(false)
      })
      refreshList()
      dispatch(setBreadcrumb({
        breadcrumb:[
        {
          name: "Campaign",
          path: PORTAL_CAMPAIGNS
        },{
          name: "Campaign List",
          path : null
        }
      ]
      }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[])

    return <CampaignListComponent campaignList={campaignList} accessRights={accessRights} refreshList={refreshList} hasAccess={hasAccess}/>
}