import React from "react";
import SocialInformationComponent from "./socialInformation.component";

export default function SocialInformationContainer({
  totalSections,
  order,
  reorderSection,
  socialItems,
  setSocialItems,
  socialSelect,
  setSocialSelect,
  errors,
  isEnabledSocialInfo,
  setIsEnabledSocialInfo,
  setSocialItem,
  handleSocialSelectClose,
  getListStyle,
  getItemStyle,
  getIcon,
  reorder,
  remove,
}) {
  return <SocialInformationComponent
    totalSections={totalSections}
    order={order}
    reorderSection={reorderSection}
    socialItems={socialItems}
    setSocialItems={setSocialItems}
    socialSelect={socialSelect}
    setSocialSelect={setSocialSelect}
    errors={errors}
    isEnabledSocialInfo={isEnabledSocialInfo}
    setIsEnabledSocialInfo={setIsEnabledSocialInfo}
    setSocialItem={setSocialItem}
    handleSocialSelectClose={handleSocialSelectClose}
    reorder={reorder}
    remove={remove}
    getListStyle={getListStyle}
    getItemStyle={getItemStyle}
    getIcon={getIcon}
  />
}   