import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function TermCondition({ value, errors, handleChange}:
  {value: string,errors: any,handleChange: any}) {
  const onChange = (value) => {
    handleChange("termsAndCondition")(value);
  }
  return <ReactQuill theme="snow" value={value} onChange={onChange} />;
}