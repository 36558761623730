import { useEffect } from "react";
import { Outlet, useParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import './landingPage.css'
import basicContact from '../../api/basicContact';
import { useNavigate } from "react-router-dom";
import {LANDING_PAGE_FROM_CARD} from '../../lib/constants/routes'
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:  'black !important'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          fontWeight: 500,
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

export default function OldLandingPage({from}) {
  const { id } = useParams();
  const ids = id.split('-');
  const username = ids[0];
  const userUUID = ids[ids.length - 1];
  const navigate = useNavigate();
  useEffect(()=>{
    //temp code to redirect Ahmad to his card
    if(username === 'Ahmad'){ 
      navigate("/c/cf99d4b7")
    }else{
     getFirstCard(userUUID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id])

  // redirect user to current version of landing page 
  const getFirstCard = async (userUUID) =>{
    const response = await basicContact.getFirstCard({userUUID:userUUID})
    .then(response => response)
    .catch(error => {
      navigate('error')
      return error
    });
    if(response.success){
      const {card_serial_num} = response.data;
      navigate(LANDING_PAGE_FROM_CARD+`/${card_serial_num}`)
    }else{
      navigate('error')
    }
    return response
  }

  return <ThemeProvider theme={defaultTheme}>
    <CssBaseline/>
    <Box style={{
    background: '#fff',
    margin: 'auto',
    display: 'flex',
    position: 'sticky',
    maxWidth: '425px',
    // overflow: 'scroll',
    flexDirection: 'column',
    minHeight: '100vh'
  }}>
    <Outlet></Outlet>
  </Box>
  </ThemeProvider>
}