import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const getCheckSession = (): Promise<Data> => {

  const user = JSON.parse(localStorage.getItem('user') || "")
  const sessionId =  user.session_id
  const sessionExpiration = user.session_expiration

  const params = new URLSearchParams([['session_id ', sessionId ],['session_expiration',sessionExpiration]]);
  
    return new Promise((resolve, reject) => {
        axios.get(`basicauth/checkSession`,{params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getCheckSession;
