import axios from "axios";
import { VoucherList } from "../../lib/types/Voucher";
interface Data{
  code: number,
  data:  Array<VoucherList>,
  message: string | null,
  success: boolean
}

const getVoucherListByCampaign = ({campaignUUID}:{campaignUUID: string | null}): Promise<Data> => {
    const user = JSON.parse(localStorage.getItem('user') || "")
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${user.access_token}`;
    
    const params = campaignUUID ? new URLSearchParams([['campaign_uuid',campaignUUID]]) : {};
    
    return new Promise((resolve, reject) => {
        axios.get("campaign/getVoucherListByCampaign", {params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getVoucherListByCampaign;
