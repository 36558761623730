import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
import Lottie from 'react-lottie-player';
import completed from './completed.json';
import { useNavigate } from 'react-router-dom';
import { PORTAL } from '../../../../lib/constants/routes';
const CustomContainer = styled("div")({
  display: "grid",
  justifyItems: "center",
  justifyContent: "space-around",
  minHeight: "100vh",
  alignItems: "center"
});
const BackgroundImage = styled("div")({
  display: "grid",
  width: "100%",
  position: "absolute"
})
const ContainBox = styled("div")({
  display: "grid",
  justifyContent: "space-between",
  justifyItems: "center"
})

export default function TrialSubCompletedComponent({ expDate }: {expDate?: string}) {
  const navigate = useNavigate();
  // const subtitle = 'Get ready to engage, explore, and connect with people who share your interests.';
  const lifestyle_sub = 'Thanks for using Lifestyle Plan, your due date will be at';
  
  return (
    <>
      <BackgroundImage>
      </BackgroundImage>
      <CustomContainer>
        <ContainBox>
          <Lottie
            animationData={completed}
            play
            style={{ width: "858px", zIndex: "-1" }} />
          <Box style={{ maxWidth: '400px' }}>
            <Typography style={{
              paddingBottom: '2rem',
              fontSize: '24px',
              fontWeight: '600',
              lineHeight: '29px',
              letterSpacing: '0em',
              textAlign: 'center',
            }}>
              Welcome to the Pixel's Community
            </Typography>
            <Typography style={{
              fontSize: '20px',
              fontWeight: '400',
              lineHeight: '24px',
              letterSpacing: '0em',
              textAlign: 'center',
            }}>
              {lifestyle_sub} <Typography
                component='span'
                style={{ fontSize: '20px', fontWeight: '700' }}
              >
                {expDate}
              </Typography>
            </Typography>
          </Box>

          <Button
            type="button"
            onClick={() => navigate(PORTAL)}
            variant="contained"
            sx={{ mt: 3, mb: 2, borderRadius: '30px', backgroundColor: 'black', color: '#FECD20', padding: '1rem 2rem' }}>
            Back 
          </Button>
        </ContainBox>
      </CustomContainer>
    </>
  );
}