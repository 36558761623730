import * as React from 'react';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, Paper, TextField, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import images from '../../../../assets/img';
import icons from '../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import { openActionDialog } from '../../../../features/action-dialog';
import { openResponseDialog } from '../../../../features/response-dialog';
import * as routes from '../../../../lib/constants/routes';
import basicAuthApi from '../../../../api/basicauth';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});
/**
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
*/
export default function VoucherSignIn() {
  const baseUrl = process.env.REACT_APP_API_HOST_URL;
  const landingPage = useSelector((state: any) => state.landingPage);
  const accessPath = landingPage.accessPath;
  const { id, voucherUUID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //const ownerCardNo = useQuery().get("owner_card_no") || ''
  //const ownerProfileNo = useQuery().get("owner_profile_no") || ''

  async function postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    if (response.ok) {
      return response.json();
    } else {
      dispatch(openResponseDialog({
        title: 'Credentials Incorrect',
        description: "Please check your email and password. If needed, click 'Forgot Password' to reset."
      }))
    }

    return null;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const body = {
      email: data.get('email'),
      password: data.get('password'),
      remember_me: data.get('remember') === 'remember',
    };

    await basicAuthApi.signin({requestBody:body}).then((d) => {
      if (d === null) return;
      if(!d.success) {
        dispatch(openResponseDialog({
          title:'Error',
          description: d.message
        }))
        return;
      }

      localStorage.setItem("user", JSON.stringify(d.data));
      navigate(`${accessPath}/${id}/${routes.VOUCHER_DETAIL}/${routes.COLLECT_VOUCHER_PROFILE_LIST}`.replace(':voucherUUID', `${voucherUUID}`));
      return
    }).catch((error) => {
      dispatch(openResponseDialog({title:'Credentials Incorrect',
      description: error.message}))
      return;
    }
    );
    // postData(baseUrl + "/basicauth/signin", body).then((d) => {
    //   if (d === null) return;
    //   if(!d.success) {
    //     dispatch(openResponseDialog({title:'Credentials Incorrect',
    //     description: "Please check your email and password. If needed, click 'Forgot Password' to reset."}))
    //     return;
    //   }

    //   localStorage.setItem("user", JSON.stringify(d.data));
    //   navigate(`${accessPath}/${id}/${routes.VOUCHER_DETAIL}/${routes.COLLECT_VOUCHER_PROFILE_LIST}`.replace(':voucherUUID', `${voucherUUID}`));
    // });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: function (codeResponse: any) {

      const body = {
        social_signin_type: 'google',
        social_signin_access_token: codeResponse.access_token,
      };

      postData(baseUrl + "/basicauth/socialSignIn", body).then((d) => {
        if (d === null) return;

        localStorage.setItem("user", JSON.stringify(d.data));

        navigate(`${accessPath}/${id}/${routes.VOUCHER_DETAIL}/${routes.COLLECT_VOUCHER_PROFILE_LIST}`.replace(':voucherUUID', `${voucherUUID}`));
        
        /**  TODO: Check the verified logic, the above is code temporarily only 
        if (d.data.access_token_payload.social_info_verified === 1) {
          navigate({
            pathname: routes.VOUCHER_DETAIL,
            // search: createSearchParams({
            //   owner_card_no: ownerCardNo,
            //   owner_profile_no: ownerProfileNo,
            // }).toString()
          });

        } else {
          navigate({
            pathname: routes.VOUCHER_DETAIL,
            // search: createSearchParams({
            //   owner_card_no: ownerCardNo,
            //   owner_profile_no: ownerProfileNo,
            // }).toString()
          })
        }
        */
      });
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  const handleContactUs = () => {
    dispatch(openActionDialog({ title: "We're Here to Help", description: `Let us know the details, and we’ll get back to you promptly. Reach us at ${process.env.REACT_APP_SUPPORT_EMAIL} or call ${process.env.REACT_APP_SUPPORT_CONTACT}` }))
  }

  return <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs" style={{
      height: '100vh',
      display: 'grid',
      alignContent: 'space-around',
      justifyContent: 'center',
      backgroundColor: '#FECD20',
    }}>
      <CssBaseline />
      <Paper elevation={9}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1.3rem',
          borderRadius: '8.78px',
          width: "inherit"
        }}
      >
        <div style={{ margin: '20px' }}>
          <img src={images.pxlicon} style={{ maxWidth: '133px', maxHeight: "33" }} alt="Pixel Logo" />
        </div>
        <Typography component="h1" variant="h4">
          Log in to your account
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Typography component='label' className='required' variant='h5'>
            Email
          </Typography>
          <TextField
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            placeholder='enter your email'
          />
          <Typography component='label' className='required' variant='h5'>
            Password
          </Typography>
          <TextField
            color='secondary'
            margin="dense"
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            placeholder='enter your password'
          />

          <Grid container>
            <Grid item xs>
              <FormControlLabel
                name='remember'
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
            </Grid>
            <Grid item sx={{ mt: '13px' }}>
              <Link href="#" onClick={() => navigate(routes.FORGOT_PASSWORD)} variant="body1">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, borderRadius: '30px', color: '#FECD20' }}
          >
            Log In
          </Button>
          <Typography component="p" variant="body1" align='center'>
            Having trouble logging in? <Link href="#" sx={{ color: '#FECD20' }} onClick={() => handleContactUs()}>Contact us</Link>
          </Typography>
        </Box>
        <hr style={{ width: '100%', marginTop: '20px' }} />
        <Typography component="p" variant="body1" align='center' sx={{
          bgcolor: '#F9F9F9',
          position: 'relative',
          top: '-15px',
          paddingX: '5px',
        }}>
          Or login with
        </Typography>

        <div>
          <img onClick={() => googleLogin()} style={{ marginRight: '10px' }} src={icons.login_google_icon.toString()} alt='Google logo' />
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
            onSuccess={(response) => {
              const body = {
                social_signin_type: 'facebook',
                social_signin_access_token: response.accessToken,
              };

              postData(baseUrl + "/basicauth/socialSignIn", body).then((d) => {
                if (d === null) return;

                localStorage.setItem("user", JSON.stringify(d.data));
                navigate(`${accessPath}/${id}/${routes.VOUCHER_DETAIL}/${routes.COLLECT_VOUCHER_PROFILE_LIST}`.replace(':voucherUUID', `${voucherUUID}`));
                
                /**  TODO: Check the verified logic, the above is code temporarily only
                if (d.data.access_token_payload.verified === 1) {
                  navigate({
                    pathname: routes.EXCHANGE_CONTACT_PROFILE_LIST,
                    search: createSearchParams({
                      owner_card_no: ownerCardNo,
                      owner_profile_no: ownerProfileNo,
                    }).toString()
                  });
                } else {
                  navigate({
                    pathname: routes.EXCHANGE_CONTACT_PERSONAL_INFO,
                    search: createSearchParams({
                      owner_card_no: ownerCardNo,
                      owner_profile_no: ownerProfileNo,
                    }).toString()
                  });
                }
                */
              });
            }}
            onFail={(error) => {
              console.log('Login Failed!', error);
            }}
            onProfileSuccess={(response) => {
              console.log('Get Profile Success!', response);
            }}
            render={({ onClick, logout }) => (
              <img onClick={onClick} src={icons.login_facebook_icon.toString()} alt='Facebook logo' />
            )}
          />
        </div>

        <Typography component="p" variant="body1" align='center' mt={'20px'}>
          Don't have an account? <Link href="#"></Link>
          <Link
            component={RouterLink}
            to={`${accessPath}/${id}/${routes.VOUCHER_DETAIL}/${routes.COLLECT_VOUCHER_GUEST_SIGN_UP}`.replace(':voucherUUID', `${voucherUUID}`)}
            style={{ color: '#FECD20' }}
          >
            Sign Up
          </Link>
        </Typography>
      </Paper>
    </Container>
  </ThemeProvider>
}
