import React from 'react'
import { Typography} from "@mui/material";
import { styled } from '@mui/system';
import icons from '../../../assets/icons';
import {  useNavigate } from 'react-router-dom';
import { SIGN_IN } from '../../../lib/constants/routes';
// import { useDispatch, useSelector } from 'react-redux'

const Bar = styled('div')({
  display: 'flex',
  padding: '10px 24px',
  alignItems: 'center',
  gap: '8px',
  background: 'rgba(55, 55, 54, 0.80)'
});

export default function NotificationBar ({authed}) {
  const navigate = useNavigate();
  // const isConnected = useSelector((state) => state.exchangeContact.isConnected);
  // const fullname = useSelector((state) => state.landingPage.profile.user_details.full_name);
  // const dispatch = useDispatch()

  return <>

    {    !authed && 
    <Bar>
     <img src={icons.megaphone} alt="megaphone"/>
    <Typography style={{      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',color:"#FFFFFF"}}>
     Join our pixel member now. 
     <span onClick={() => navigate(SIGN_IN)} style={{paddingLeft: '3px',fontSize: '12px',color:'var(--Secondary-Color, #FECD20)'}}>
     Signup now
    </span>
    </Typography>

  </Bar>
  }
  </>
// }
  // </>
}