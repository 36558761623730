import React, {useState} from 'react';
import { Box,Tabs, Tab } from '@mui/material';
import Coupon from './component/coupon';
import Placeholder from '../../components/placeholder/placeholder';
import icons from '../../assets/icons';

export default function CollectedVoucherPage ({collectedVouchers,historyVouchers}: 
  {collectedVouchers: any[],historyVouchers: any[]}){

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };  

  return (
    <div>
      <Tabs value={selectedTab} onChange={handleTabChange} 
        sx={{
          borderBottom: '1px solid #EAEAEA',
          '& .Mui-selected':{
            color: 'black'
          }
        }}>
        <Tab label="Collected Coupon" />
        <Tab label="History" />
      </Tabs>

      {selectedTab === 0 && (
        <Box sx={{display:'flex', flexWrap:'wrap'}}>
          {
            collectedVouchers.map((voucher, index) => {
              return (
                <Coupon key={index} voucher={voucher} surfaceColor='#F9FAFC' />
              )
            })
          }
        </Box>
      )}

      {((selectedTab === 0 && collectedVouchers.length === 0) || (selectedTab === 1 && historyVouchers.length === 0)) && <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: '30px',
      }}>
        <Placeholder
          icon={icons.coupon}
          title="No coupons"
          description="It’s time for you to get more coupons to enjoy the benefits!"
        />
      </Box>}

      {selectedTab === 1 && (
        <Box sx={{display:'flex', flexWrap:'wrap'}}>
        {
          historyVouchers.map((voucher, index) => {
            return (
              <Coupon key={index} voucher={voucher}/>
            )
          })
        }
      </Box>
      )}
    </div>
  );
}