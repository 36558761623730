import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../../../features/admin';
import { PORTAL_CARDS,PORTAL_SETTINGS } from "../../../../../lib/constants/routes";
import OrderSummaryComponent from "./orderSummary.component";
import { useNavigate } from "react-router-dom";
import userApi from "../../../../../api/user";
import { endLoading, startLoading } from "../../../../../features/loading";
import orderApi from "../../../../../api/order";
import FiuuHelper  from "../../../../../lib/helper/FiuuuHelper";
// import FiuuPaymentButton from "../../../../../components/fiuu/fiuuPaymentButton";
import { openErrorDialog} from "../../../../../features/error-dialog";
import { useParams } from "react-router-dom";
import {SummaryOrderItem} from "../../../../../lib/types/Order"
import shipppingApi from "../../../../../api/shipping";
import { openResponseDialog } from "../../../../../features/response-dialog";

export default function OrderSummaryContainer() {
  const navigate = useNavigate();
  const {orderUUID} = useParams();
  const [openAddressForm, setOpenAddressForm] = useState(false);
  const [openBillingForm, setOpenBillingForm] = useState(false);
  const dispatch = useDispatch();
  const [items, setItems] = useState<SummaryOrderItem[]>([]);
  const [itemPreview, setItemPreview] = useState<any>();
  const [totalAmount, setTotalAmount] = useState(0);
  const [shippingFee, setShippingFee] = useState(0);
  const [currency,setCurrency] = useState("USD");
  const [shippingRateError, setShippingRateError] = useState(false);
  const [channel,setChannel] = useState('creditAN');
  const [address, setAddress] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
  });
  const [billing, setBilling] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
  });

  const [basicInfo, setBasicInfo] = useState({
    uuid: "",
    name: "",
    email: "",
    phone_number: "",
    phone_code: "+60",
  });

  const handlePreview = item => {
    setItemPreview({
      product_uuid: item.product_uuid,
      jobTitle: item.jobTitle,
      name: item.personName,
      personName: item.name,
      textColor: item.font_color_code,
      logo: item.logo_url,
      price: item.price,
      quantity: item.quantity,
      variant: item.variant
    });
  }

  const handleSubmit = async (value) => {
    dispatch(startLoading());

    if(!value.billDesc || !value.billAddr1 || !value.billCity || !value.billState || !value.billZip || !value.billCountry){
      dispatch(openErrorDialog({
        title: "Purchase Order Error",
        description: "Please insert billing address information.",
      }));
      return
    }

    if(!value.address1 || !value.city || !value.state || !value.zip || !value.country){
      dispatch(openErrorDialog({
        title: "Purchase Order Error",
        description: "Please insert shipping address information.",
      }));
      return
    }
    

    await orderApi.repaid({requestBody:{
      order_uuid: orderUUID|| "",
      channel: channel, 
      }
    }).then((res) => {
      if(res.success){
        const data = res.data;
        const options ={
          orderUUID: data.order_uuid,
          merchantId: data.merchant_id,
          channel: data.channel,
          totalAmount: data.total_amount,
          orderId: data.order_ref_id,
          billName: data.bill_name,
          billEmail: data.bill_email,
          billMobile: data.bill_mobile,
          billAddr1: data.bill_addr1,
          billAddr2: data.bill_addr2,
          billZip: data.bill_zip,
          description: data.bill_desc,
          vcode: data.vcode,
          country: data.country,
          currency: data.currency,
          langCode: 'en'
        }
        // if(data.channel === 'fpx'){
        //   //TODO: this is a temporary solution to use the old fpx.php
        //   // Currently Seamless not  working for fpx
        //   options.channel = 'fpx.php';
        //   const fiuuPaymentButton = new FiuuPaymentButton({paymentInfo: options});
        //   fiuuPaymentButton.openPaymentGateway();
        // }else{
          const fiuuHelper = new FiuuHelper();
          fiuuHelper.openPaymentGateway(options);
        // }

      }
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      dispatch(endLoading());
    })
  }

  const getAddress = async () => {
    await userApi.getShipping().then(async (res) => {
      if (res.success) {
        setAddress({
          address1: res.data.address_1 || '',
          address2: res.data.address_2 || '',
          city: res.data.city || '',
          state: res.data.state || '',
          postcode: res.data.zip || '',
          country: res.data.country || '',
        });

        await getShippingFee({
          postcode: res.data.zip,
          country: res.data.country,
        })
      }
    });
  }

  const getShippingFee = async ({
    postcode,
    country
  }) => {
    dispatch(startLoading());
    await shipppingApi.rate({
      reference_number: 1,
      to_post_code: postcode,
      parcel_type: 'parcel',
      weight: 1,
      country: country, 
    }).then((res) => {
      if(res.success){
        setShippingFee(res.data.shipping_rate);
        setShippingRateError(false);
        return
      }
      setShippingRateError(true);
    }).catch((error) => {
      setShippingRateError(true);
      dispatch(openResponseDialog({
        title: "Shipping Fee",
        description: error.message,
      }))
    })
    .finally(() => dispatch(endLoading())); // Shipping fee is the last to finish loading
  }

  const getBilling = async () => {
    await userApi.getBilling().then((res) => {
      if (res.success) {
        setBilling({
          address1: res.data.address_1 || '',
          address2: res.data.address_2 || '',
          city: res.data.city || '',
          state: res.data.state || '',
          postcode: res.data.zip || '',
          country: res.data.country || '',
        });
      }
    });
  }
  const getDetail = async () => {
    const response = await userApi.getDetail()
      .then(response => response)
      .catch(error => error)
      .finally(() => dispatch(endLoading()));

    if (!response.success) return;

    setBasicInfo(response.data);
  }

  const getOrder = async () => {
    await orderApi.getDetails(orderUUID).then((res) => {
      if (res.success) {
        const data = res.data;
        let products = [];
        if(data.products?.length > 0){
          products =  data.products.map((item,index) => {
            return {
              product_uuid: item.product_uuid,
              cart_uuid: item.cart_uuid,
              name: item.name,
              price: item.price,
              quantity: item.quantity,
              designid: item.design_id,
              variant: {
                name: item.card_variation,
                front: item.front_thumbnail,
                back: item.back_thumbnail,
              },
              fontColor: item.font_color_code,
              logoUrl: item.logo_url,
              personName: item.person_name,
              jobTitle: item.position,
              customization: item.customization,
              customizationPrice: item.customization_price,
              customizationDiscountPrice: item.customization_discount_price,
              pixelcare: item.pixelcare === 1,
              pixelcarePrice: item.pixelcare_price,
              pixelcareDiscountPrice: item.pixelcare_discount_price,
              totalCustomizationPrice: item.total_customization_price,
              totalPixelcarePrice: item.total_pixelcare_price,
          }
          })
        }
        setItems(products);
        setChannel(data.channel);
        setCurrency(data.currency);
        setTotalAmount(data.total_amount);
        setShippingFee(data.shipping_fee);
      }
    });
  }
  useEffect(() => {
    dispatch(startLoading());
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "My NFC Card", path: PORTAL_CARDS },
        { name: "Cart", path: null },
      ]
    }))
    getOrder();
    getAddress();
    getBilling();
    getDetail();

    dispatch(endLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <OrderSummaryComponent
    currency={currency}
    basicInfo={basicInfo}
    handleBack={() => navigate(`${PORTAL_SETTINGS}?tab=3`)}
    items={items}
    itemPreview={itemPreview}
    setItemPreview={setItemPreview}
    handlePreview={handlePreview}
    openAddressForm={openAddressForm}
    setOpenAddressForm={setOpenAddressForm}
    address={address}
    setAddress={setAddress}
    handleSubmit={handleSubmit}
    openBillingForm={openBillingForm}
    setOpenBillingForm={setOpenBillingForm}
    billing={billing}
    setBilling={setBilling}
    totalAmount={totalAmount}
    shippingFee={shippingFee}
    setShippingFee={setShippingFee}
    shippingRateError={shippingRateError}
    setShippingRateError={setShippingRateError}
    channel={channel}
    setChannel={setChannel}
  />
}