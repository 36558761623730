
import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  TextField,
  Button,
  IconButton, Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function MultipleDialog({open,handleClose,handleSubmit}:{
  open: boolean,
  handleClose: () => void, 
  handleSubmit: ({code,quantity}:{
    code: string,
    quantity: number
  }) => void
}) {
  const [code, setCode] = useState('');
  const [quantity, setQuantity] = useState(0);

  return (
    <>
      <Dialog open={open} fullWidth={true} onClose={() => handleClose()}>
        <Box sx={{
          position:'absolute',
          right:'1px',
          pr:'1rem'
        }}>
        <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle sx={{
          display: 'flex',
          justifyContent: 'center'
        }}>
          <Typography variant='h2' fontWeight='500'>
          Generate Code 
          </Typography>

        </DialogTitle>
        <Box>
          
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '1rem'
        }}>
          <Box>
          <Typography>Prefix</Typography>
          <TextField
            name="code"
            type="text"
            fullWidth={true}
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          </Box>
          <Box>
          <Typography>Quantity</Typography>
          <TextField
            name="quantity"
            type="number"
            fullWidth={true}
            value={quantity}
            onChange={(e) => setQuantity(Number(e.target.value))}
          />
          </Box>

        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
          padding: '1rem',
          gap: '2rem'
        }}>
          <Button size='large' fullWidth={true} onClick={() => handleClose()} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button size='large' fullWidth={true} onClick={() => handleSubmit({code,quantity})} variant="contained" color="secondary">
            Add
          </Button>
        </Box>
      </Dialog>
    </>
  );
}
