import React from 'react';
import { Box, Typography, Select, MenuItem, Button } from '@mui/material';
import CustomSwitch from '../../customSwitch';
import icons from '../../../../../../assets/icons';
import {CampaignList} from '../../../../../../lib/types/Profile';
import moment from 'moment';

export default function VoucherComponent ({
  profileVoucherStatus,
  campaignList,
  campaignOptions,
  handleSelectCampaign,
  handleDelete,
  handleVoucherStatus,
  handleProfileVoucherStatus
}: {
  profileVoucherStatus: number,
  campaignList: CampaignList[],
  campaignOptions: any[],
  handleSelectCampaign: (campaignUUID:string) => void,
  handleDelete: (campaignUUID:string) => void,
  handleVoucherStatus: ({campaignUUID,voucherUUID,status}:{campaignUUID:string, voucherUUID:string, status:boolean}) => void,
  handleProfileVoucherStatus: (status:string) => void,
}){

  const [selected, setSelected] = React.useState('' as string);
  return (
<Box>
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    }}>
      <Typography>
        Voucher
      </Typography>
      <CustomSwitch
          checked={(profileVoucherStatus === 1) ? true : false} onChange={(e) => {
          const checked = (e.target.checked) ? "1" : "0";
          handleProfileVoucherStatus(checked);
        }
      }
      />
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
        gap: '1rem',
      }}
    >
      <Select 
        value={selected} 
        onChange={(e) => setSelected(e.target.value as string)}
        fullWidth>
        {campaignOptions.map((campaign, index) => (
          <MenuItem key={index} value={campaign.campaign_uuid} selected>{campaign.campaign_name}</MenuItem>
        ))}
      </Select>
      <Button 
        onClick={() => handleSelectCampaign(selected)} 
        variant="contained" color="secondary" sx={{borderRadius:'30px'}}
        startIcon={<img alt="plus_icon" src={icons.plus_icon}/>}
      > Add </Button>
    </Box>
    <hr/>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: '20px',
    }}>
      {
        campaignList?.map((campaign, index) => {
        return (
          <Box key={index}>
            <Box sx={{display:'flex',justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{mb:'20px', fontWeight:'500'}}>
                {campaign.campaign_name} 
              </Typography>
              <img src={icons.delete_icon} alt="delete_icon" onClick={() => handleDelete(campaign.campaign_uuid) }></img>
            </Box>
          <Voucher vouchers={campaign.vouchers} campaignUUID={campaign.campaign_uuid}  handleVoucherStatus={handleVoucherStatus} />

        </Box>
        )
        })
      }

    </Box>
  </Box>
  );
};

const Voucher = ({vouchers,campaignUUID,handleVoucherStatus}:{vouchers:any,campaignUUID,handleVoucherStatus}) => {
  return (
    <Box>
    {
      vouchers?.map((voucher, index) => {
      return (
          <Box sx={{
            backgroundColor:'#FFFFFF',
            display: 'flex',
            justifyContent: 'space-between',  
            alignItems: 'center', 
            padding: '1rem',
            borderRadius: '4px',
            mb: '1rem',
          }}
          key={index}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}>

              <img src={(voucher.banner_image_url)} alt="voucher_image"
                style={{
                  width: '85px',
                  height: '55px',
                }}
              ></img>
              <Box>
                <Typography fontWeight="500">
                  {voucher.voucher_title}
                </Typography>
                <Typography sx={{fontSize:'12px'}}>
                  Collection Date: 
                  {moment(voucher.collection_start_date).format('DD/MM/YYYY hh:mm A')}
                  - 
                  {moment(voucher.collection_end_date).format('DD/MM/YYYY hh:mm A')}
                </Typography>
                <Typography sx={{fontSize:'12px'}}>
                  Redeemption Date: 
                  {moment(voucher.start_date_time).format('DD/MM/YYYY hh:mm A')}
                  - 
                  {moment(voucher.end_date_time).format('DD/MM/YYYY hh:mm A')}
                </Typography>
              </Box>
            </Box>
            <CustomSwitch checked={(voucher.active === 1) ? true : false} onChange={(e) => {
                handleVoucherStatus({
                  campaignUUID: campaignUUID,
                  voucherUUID: voucher.voucher_uuid,
                  status: e.target.checked
                })
            }} />
          </Box>
        )
      })
    }

  </Box>
  )
}
