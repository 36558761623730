import PhoneInput, { PhoneInputProps } from './phoneInput.component'

export default function PhoneInputContainer(props: PhoneInputProps){
  const { value,code = "+60", disabled, setVal, defaultValue } = props;
  
  return <PhoneInput
    code={code}
    value={value}
    disabled={disabled}
    setVal={setVal}
    defaultValue={defaultValue}
  />
}