import React, { useEffect, useState } from "react";
import Setting from "./setting.component";
import userApi from '../../api/user';
import { useDispatch} from 'react-redux';
import { setBreadcrumb } from "../../features/admin";
import { PORTAL_SETTINGS } from "../../lib/constants/routes";
export default function SettingContainer(){
  const [isSocialSignin,setIsSocialSignin] = useState(false)
  const dispatch = useDispatch();

  const getSignInType = async () =>{
    const response = await userApi.checkSignType()
    .then(response => response)
    .catch(response => response)
    if(response.success) setIsSocialSignin(response.data.is_social_signin)
  }

  useEffect(() => {
    getSignInType()
    dispatch(setBreadcrumb({
      breadcrumb:[
      {
        name: "Setting",
        path: PORTAL_SETTINGS
      },{
        name: "Account Setting",
        path : null
      }
    ]
  }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return <Setting isSocialSignin={isSocialSignin}/>
}