import React, {useState,useEffect} from 'react';
import { Box, Button, Container, Divider, ThemeProvider, Typography, createTheme } from '@mui/material';
import icons from '../../../../../assets/icons';
import orderApi from '../../../../../api/order';
import { useLocation,useNavigate } from 'react-router-dom';
import { PORTAL} from '../../../../../lib/constants/routes';
import { useDispatch } from 'react-redux';
import { setItems } from "../../../../../features/cart";
const defaultTheme = createTheme({
  palette: {
    secondary: {
      main: '#373736',
      contrastText: '#FECD20',
    },
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    button: {
      textTransform: 'none',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "30px"
        }
      },
    },
  },
});
function useQuery() {
  const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function Pending() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const orderUUID = useQuery().get("order_uuid") || ''
  const getDetails = async(orderUUID: string) => {
    await orderApi.getDetails(orderUUID)
      .then((response) => {
        setOrderDetails(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if(orderUUID) getDetails(orderUUID)
  }, [orderUUID]);

  useEffect(() => {
    // Clear cart
    dispatch(setItems([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="sm" sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      alignContent: 'space-around',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
    }}>
      <img
        src={icons.buycard_pending}
        alt="Card being delivered on a truck"
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '270px'
        }}
      />
      <Typography sx={{ fontSize: '31px', fontWeight: 500, textAlign: 'center', mt: '20px' }}>
        Payment Pending
      </Typography>
      <Typography sx={{ fontSize: '20px', fontWeight: 400, textAlign: 'center' }}>
      Payment takes two business days to process.
      </Typography>
      <Box sx={{
        bgcolor: '#F5F5F5',
        borderRadius: '10px',
        p: '20px',
        width: '-webkit-fill-available',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>Order ID</Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>{orderDetails?.order_ref_id}</Typography>
        </Box>
        <Divider sx={{ my: '20px' }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Total</Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: 500,color:'red' }}>{orderDetails?.currency} {orderDetails?.total_amount}</Typography>
        </Box>
      </Box>
      {/* <Box sx={{
        bgcolor: '#F5F5F5',
        borderRadius: '10px',
        p: '20px',
        width: '-webkit-fill-available',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Receipt</Typography>
          <Link sx={{ color: '#006BCF', textDecorationColor: '#006BCF' }}>
            <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Download</Typography>
          </Link>
        </Box>
      </Box> */}
      <Typography sx={{ fontWeight: 400, textAlign: 'center', mb: '20px' }} gutterBottom>
        Your order is on the way! Please allow 4-7 days for shipping.
      </Typography>
      <Button variant='contained' color='secondary' sx={{ px: '40px', maxWidth: '115px' }} 
      onClick={()=>navigate(PORTAL)}>Done</Button>
    </Container>
  </ThemeProvider>
}