import React, { useState } from 'react'
import { Collapse, Paper, Typography } from '@mui/material';
import ExpandMore from '../component/expandMore.component';

export default function CustomComponent({ component }) {
  const [expanded, setExpanded] = useState(true);

  return <Paper sx={{ m: '10px 14px', p: '5px', borderRadius: '10px' }}>
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ margin: '3px 6px', fontSize: '17px', fontWeight: 700, fontFamily: 'Rubik Variable, sans-serif', flex: 1 }}>
        {component['name']}
      </Typography>
      <ExpandMore
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
        aria-label="show more"
      />
    </div>
    <Collapse sx={{ p: 0 }} in={expanded} timeout="auto" unmountOnExit>
      <div style={{ overflowWrap: 'break-word', padding: '6px' }}>
        {component['content']}
      </div>
    </Collapse>
  </Paper>
}