import React, { useEffect, useState } from "react";
import SubscriptionTabComponent from "./subscriptionTab.component";
import { useNavigate } from "react-router-dom";
import { BUY, PORTAL_CARDS, PORTAL_SETTINGS, SUBSCRIPTION } from "../../../../lib/constants/routes";
import tiers from "../../../../lib/constants/pricing";
import authApi from "../../../../api/auth";
import subscriptionApi from "../../../../api/subscription";
import { startLoading, endLoading } from "../../../../features/loading";
import { useDispatch, useSelector } from "react-redux";
import { openResponseDialog } from "../../../../features/response-dialog";

export default function LoginSecurityContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [accessLimit, setAccessLimit] = React.useState({});
  const [tier, setTier] = useState(tiers.month[0]);
  const isConfirmed = useSelector<any>(state => state.actionDialog.isConfirmed)
  const dialogAction = useSelector<any>(state => state.actionDialog.action)
  const [toggleStatus, setToggleStatus] = useState<boolean | undefined>();

  const handleViewPricing = () => {
    navigate(`${PORTAL_SETTINGS}/${SUBSCRIPTION}`);
  }

  const unsubscribe = () => {
    subscriptionApi.downgradeToFree().then(() => {
      setToggleStatus(true);
      dispatch(openResponseDialog({
        title: "Unsubscribed",
        description: "Your subscription will not be renewed automatically",
        action: 'disableAutoRenewal'
      }));
    });
  }

  useEffect(() => {
    if (isConfirmed && dialogAction === "unsubscribe") unsubscribe()

    if (isConfirmed && dialogAction === "refreshPage") {
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed, dialogAction])

  const handleBuyCard = () => {
    navigate(`${PORTAL_CARDS}/${BUY}`);
  }

  useEffect(() => {
    const getAccessLimit = async () => {
      dispatch(startLoading());
      try {
        await authApi.getAccessLimit()
          .then(response => {
            if (response.success === true) {
              setAccessLimit(response.data);
              setTier([
                ...tiers.month,
                ...tiers.year,
              ].filter(t => t.id === Number(response.data.subscription_id))[0] ?? tiers.month[0]);
            }
          })
          .catch(error => error)
          .finally(() => {
            dispatch(endLoading());
          });

      } catch (error) {
        console.log(error);
      }
    }
    getAccessLimit();

  }, [dispatch, setAccessLimit, setTier]);
  return <SubscriptionTabComponent
    handleViewPricing={handleViewPricing}
    handleBuyCard={handleBuyCard}
    accessLimit={accessLimit}
    tier={tier}
    toggleStatus={toggleStatus}
  />
}