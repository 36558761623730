import React,{useEffect,useState} from 'react'
import CompletedComponent from "./completed.component";
import accountApi from '../../../../../../api/account';
import {
  useLocation
} from "react-router-dom";
import { useNavigate,createSearchParams} from "react-router-dom";
import { openResponseDialog } from '../../../../../../features/response-dialog';
import { useDispatch, useSelector } from 'react-redux'
import { EXCHANGE_CONTACT_EXPIRED, EXCHANGE_CONTACT_MEMBER_SIGN_IN } from '../../../../../../lib/constants/routes';
function useQuery() {
  const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Completed(){
  const [verifyFail,setVerifyFail] = useState(false)
  const dispatch = useDispatch();
  
  const isResponseDialogOpen = useSelector((state: any) => state.responseDialog.open);
  let query = useQuery();
  const email  = query.get("email") || ""
  const email_verification_token:any = query.get("email_verification_token")
  const ownerCardNo  = query.get("owner_card_no") || ""
  const ownerProfileNo  = query.get("owner_profile_no") || "" 

  const navigate = useNavigate();

  async function openProfileList() {
    navigate({
      pathname: EXCHANGE_CONTACT_MEMBER_SIGN_IN,
      search: createSearchParams({
        owner_card_no: ownerCardNo,
        owner_profile_no: ownerProfileNo,
      }).toString()
    })
  }

  useEffect(() => {
    const verifyEmail = async () =>{

      const response = await accountApi.verifyEmail({
            email,
            email_verification_token
          }).then(response => response).catch(error => error)
          
      if(response){
        if(!response?.success){
          setVerifyFail(true)
          dispatch(openResponseDialog({title: "Email verification failed",description: response.message}))
        }else{
          setVerifyFail(false)
        }
      }
  
      return response    
    }
    verifyEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(verifyFail && !isResponseDialogOpen){
      navigate({
        pathname: EXCHANGE_CONTACT_EXPIRED,
        search: createSearchParams({
            email: email 
        }).toString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isResponseDialogOpen])
  return <CompletedComponent verifyFail={verifyFail} openProfileList={openProfileList}/>
}