import getAuthKey from "./getAuthKey.api";
import getS2SAuthKey from "./getAuthKeyS2S.api";
import addToCart from "./addToCart.api";
import editCart from "./editCart.api";
import getProductInfo from "./getProductInfo";

const cardApi = {
  getAuthKey,
  getS2SAuthKey,
  addToCart,
  editCart,
  getProductInfo,
};

export default cardApi;