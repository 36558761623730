import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource-variable/rubik';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux'
import store from './app/config/store'
import AxiosDefaultSetting from "./app/axiosDefaultSetting";

import  ResponseDialog from "./components/redux-dialog/response"
import ActionDialog from "./components/redux-dialog/action"
import SelectProfileDialog from "./components/redux-dialog/selectProfile"
import ErrorDialog from "./components/redux-dialog/error"
import { Loading, LoadingLandingPage} from "./components/redux-loading"

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(en);

// const store = createStore()

// const API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY; 
const GOOGlE_CLIENT_KEY = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// const REACT_APP_PRIVACY_POLICY = process.env.REACT_APP_PRIVACY_POLICY;
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <GoogleOAuthProvider clientId={GOOGlE_CLIENT_KEY}>
    <BrowserRouter>
    <Provider store={store}>
      <AxiosDefaultSetting>
      <Loading/>
      <LoadingLandingPage/>
      <ResponseDialog title="Response" description="response description" />
      <ActionDialog />
      <SelectProfileDialog />
      <ErrorDialog/>
      <App />
      </AxiosDefaultSetting>
      </Provider>
    </BrowserRouter>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
