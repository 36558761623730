import { Box, Button, Checkbox, Divider, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import React from 'react';
import ReactTimeAgo from 'react-time-ago';
import icons from '../../../../assets/icons';
import moment from 'moment';

export default function NotificationsComponent({ notifications, handleChange, handleAllChange, handleDelete, handleMarkAsRead, handleAction }) {
  return <>
    <Typography sx={{ fontSize: '31px' }}>All Notifications</Typography>
    {!Boolean(notifications.length) && <Box sx={{ color: '#C5C5C5', p: '16px 32px', textAlign: 'center' }}>
      No notifications
    </Box>}
    {Boolean(notifications.length) && <>
      <Box sx={{ display: 'flex', gap: '10px', my: '20px' }}>
        <Button
          disabled={!Boolean(notifications.filter(noti => noti.is_checked).length)}
          variant='contained'
          startIcon={<img src={icons.double_tick.toString()} alt="" />}
          onClick={handleMarkAsRead}
        >
          Mark selected as read
        </Button>
        <Button
          disabled={!Boolean(notifications.filter(noti => noti.is_checked).length)}
          variant='contained'
          startIcon={<img src={icons.delete_02.toString()} alt="" />}
          onClick={handleDelete}
        >
          Delete selected notifications
        </Button>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', p: '8px 16px' }}>
        <Checkbox
          checked={notifications.filter(noti => noti.is_checked).length > 0}
          indeterminate={notifications.filter(noti => noti.is_checked).length > 0 && notifications.filter(noti => noti.is_checked).length < notifications.length}
          onChange={handleAllChange}
          tabIndex={-1}
          disableRipple
        />
        <Typography sx={{ p: '8px 16px' }}>Select/Deselect All</Typography>
      </Box>
      <Divider sx={{ borderColor: '#BFBFBF' }} />

      <List sx={{ bgcolor: 'background.paper' }}>
        {notifications.map((noti, i: number) => {
          return (
            <ListItem
              key={noti.title}
              sx={{
                bgcolor: noti.is_read ? undefined : '#FFF8E0',
                borderBottom: `1px solid ${(i + 1) === notifications.length ? 'transparent' : '#C5C5C5'}`,
              }}
            >
              <Checkbox
                checked={noti.is_checked}
                onChange={() => handleChange(i)}
                tabIndex={-1}
                disableRipple
              />
              <ListItemButton onClick={() => handleAction(noti.action)}>
                <ListItemText
                  disableTypography
                  primary={<Typography sx={{ fontWeight: 500 }}>
                    {noti.title}<label style={{fontWeight: 400,paddingLeft:"0.5rem"}}>{noti.description}</label>
                  </Typography>}
                  secondary={
                    <>
                      {/* <Typography variant="body2" color="text.primary">
                        {noti.description}
                      </Typography> */}
                      <Typography variant="body2" color='#858585'>
                        <ReactTimeAgo date={moment(noti.timestamp, 'YYYY-MM-DD HH:mm:ss').toDate()} locale="en-US" />
                      </Typography>
                    </>
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>}
  </>
}