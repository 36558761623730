import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import "react-phone-input-2/lib/bootstrap.css";
import basicAuthApi from '../../../../api/basicauth'
import { openResponseDialog } from '../../../../features/response-dialog';
import { useDispatch } from 'react-redux';
import { CHECK_INBOX } from '../../../../lib/constants/routes';
import SignUpComponent from './signUp.component';
import moment from 'moment';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

export default function SignUp() {
  const navigate = useNavigate();
  const [location, setLocation] = React.useState({ latitude: 0, longitude: 0, isGeoLocationAllowed: false });
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    localStorage.removeItem("user"); //remove previous login user if any

    let dateString = pickerToString(values.datePickerValue);
    if (!moment(dateString, "MM/DD/YYYY", true).isValid()) {
      dateString = '';
    }

    const body = {
      email: values.email,
      name: values.name,
      phone_code: values.phoneCode,
      phone_number: values.phoneNumber,
      referral_code: values.referralCode,
      birthday: moment(dateString).format('YYYY-MM-DD'),
      password: values.password,
      confirm_password: values.confirmpw,
      latitude: location.latitude,
      longitude: location.longitude,
      ip_address: null,
      is_geolocation_allowed: location.isGeoLocationAllowed
    };

    const response = await basicAuthApi.signup({ requestBody: body }).then(response => response)
      .catch(error => error);
    if (response?.success) {
      localStorage.setItem("user", JSON.stringify(response.data));
      navigate(CHECK_INBOX);
    } else {
      dispatch(openResponseDialog({ title: "Registration failed", description: response.message }))
    }
  };

  const pickerToString = ({ day, month, year }) => {
    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    if (localStorage.getItem('location') !== null) {
      const location = JSON.parse(localStorage.getItem('location') || '{}')
      setLocation(location)
    }
  }, [])
  return (
    <ThemeProvider theme={defaultTheme}>
      <SignUpComponent handleSubmit={handleSubmit} />
    </ThemeProvider>
  );
}