import axios from 'axios';

interface Data{
  code: number,
  data:  any,
  message: string | null,
  success: boolean
}

interface RequestBody{
  profile_uuid: string,
  campaign_uuid: string
}

const deleteFromProfile = (requestBody:RequestBody): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;
  
  return new Promise((resolve, reject) => {
    axios.post("campaign/deleteFromProfile",requestBody)
    .then(response => resolve(response.data))
    .catch(error => reject(error.response.data));
})
};

export default deleteFromProfile;
