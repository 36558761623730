import React, {useEffect,useState} from 'react'
import { Collapse, Link, Paper, Typography } from '@mui/material';
import ExpandMore from '../component/expandMore.component';
import icons from '../../../assets/icons'
import { useSelector } from 'react-redux';

export default function FilesComponent({ files }) {
  const [expanded, setExpanded] = useState('files');
  const profileTheme = useSelector((state: any) => state.landingPage.theme);
  const[key, setKey] = useState(1)
  useEffect(() =>{
    setTimeout(() => {
      setKey(key+1)
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[files])

  return (
    <Paper key={key} sx={{ m: '10px 14px', p: '5px', borderRadius: '10px' }}>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ margin: '3px 6px', fontSize: '17px', fontWeight: 700, fontFamily: 'Rubik Variable, sans-serif', flex: 1 }}>
          Files
        </Typography>
        <ExpandMore
          expanded={expanded === 'files'}
          onClick={() => setExpanded(expanded === 'files' ? '' : 'files')}
          aria-expanded={expanded === 'files'}
          aria-label="show more"
        />
      </div>
      <Collapse sx={{ p: 0 }} in={expanded === 'files'} timeout="auto" unmountOnExit>
        {files.map((item, index) => (
          <div key={index} style={{ backgroundColor:  profileTheme?.primary_background|| '#F4F4F4', display: 'flex', alignItems: 'center', padding: '5px', margin: '5px', borderRadius: '8px' }}>
            <div style={{ margin: '15px',mask:`url(${icons.pdf_01_icon})`,WebkitMask: `url(${icons.pdf_01_icon})`, 
            height:"25px",width:'25px',background:profileTheme?.button?.primary_font_color }}/>
            <span style={{ flex: 1 }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 500, fontFamily: 'Rubik Variable, sans-serif', 
                color: profileTheme?.button?.primary_font_color}}>
                {item.title}
              </Typography>
              {/* <Typography sx={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Rubik Variable, sans-serif', color: '#A7A7A7' }}>
                {item.size}
              </Typography> */}
            </span>
            <div style={{height:"25px",width:'25px', background: profileTheme?.button?.primary_font_color, mask:`url(${icons.eye_open_icon})`,WebkitMask: `url(${icons.eye_open_icon})`}} />
            <Link color={profileTheme?.button?.primary_font_color} href={item.url} sx={{ fontSize: '12px', fontWeight: 400, fontFamily: 'Rubik Variable, sans-serif', textDecoration: 'underline', margin: '15px 15px 15px 5px' }}>
              View
            </Link>
          </div>
        ))}
      </Collapse>
    </Paper>
  );
}