import axios from "axios";
import COUNTRIES_CODE_ISO from "../../lib/constants/countriesCodeISO";

interface Data {
  code: number;
  data: any;
  message: string | null;
  success: boolean;
}

interface RequestBody {
  reference_number: number;
  to_post_code: string;
  parcel_type: string;
  weight: number;
  country: string;
  order_uuid?: string;
}

const convertToAlpha3 = (code: string) => {
  if (code.length === 3) return code;

  return COUNTRIES_CODE_ISO.find(iso => iso.alpha === code)?.value;
}

const rate = (requestBody: RequestBody): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  const headers = { 'Authorization': `Bearer ${user.access_token}` };
  const body = {
    ...requestBody,
    country: convertToAlpha3(requestBody.country),
  };

  return new Promise((resolve, reject) => {
    axios.post("shipping/rate", body, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default rate;
