import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const voucherSignup = ({requestBody}): Promise<Data> => {
  return new Promise((resolve, reject) => {
    axios.post("basicauth/voucherSignup",requestBody)
    .then(response => resolve(response.data))
    .catch(error => reject(error.response.data));
})
};

export default voucherSignup;
