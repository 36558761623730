import React, { useState } from 'react'
import {
  Typography,
  Collapse,
  CardContent,
  Paper
} from '@mui/material'
import { ContactBar } from './component';
import ExpandMore from '../component/expandMore.component';

export default function BasicContactComponent({ contactInfo }) {
  const [expanded, setExpanded] = useState('basicinfo');
  const content = contactInfo?.content || []

  return <Paper className="landingpage-component" sx={{ m: '10px 14px', p: '5px', borderRadius: '10px' }}>
    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ margin: '3px 6px', fontSize: '17px', fontWeight: 700, flex: 1 }}>
        Basic Info
      </Typography>
      <ExpandMore
        expanded={expanded === 'basicinfo'}
        onClick={() => setExpanded(expanded === 'basicinfo' ? '' : 'basicinfo')}
        aria-expanded={expanded === 'basicinfo'}
        aria-label="show more"
      />
    </div>
    <Collapse in={expanded === 'basicinfo'} timeout="auto" unmountOnExit>
      <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        {content.map((value: any, key) => {
          return Boolean(value.info) ? <ContactBar key={key} value={value} index={key} /> : <></>
        })}
      </CardContent>
    </Collapse>
  </Paper>
}