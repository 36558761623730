import React, {useEffect, useState} from "react";
import { Box,Typography,TextField, Button, Divider} from "@mui/material";
import 'react-quill/dist/quill.snow.css';
import { styled } from '@mui/system';
import icons from "../../../../../assets/icons";
import FormBox from "../../../component/formBox";

interface VoucherDetailsForm {
  voucherTitle: string,
  brandName: string,
  description: string,
  bannerImage: string,
  companyProfileImage: string,
  companyName: string
}
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function VoucherDetails({values,errors,handleChange,setVoucherIndex}:
  {values: VoucherDetailsForm, errors: VoucherDetailsForm,handleChange: any,setVoucherIndex: any}) {
  const [hasCompanyInfo] = useState(false);
  const [companyProfileFile, setCompanyProfileFile] = useState<File | null>();
  const [bannerIndex,setBannerIndex] = useState(0);
  function handleBannerChange(e) {
    values.bannerImage = e.target.files[0];
    setBannerIndex(bannerIndex+1);
    setVoucherIndex();
  }

  function handleCompanyProfileChange(e) {
    setCompanyProfileFile(e.target.files[0]);
    values.companyProfileImage = e.target.files[0];
  }

  const removeBannerImage = () =>{
    values.bannerImage = '';
    setBannerIndex(bannerIndex+1);
    setVoucherIndex();
  }

  useEffect(() => {
    setBannerIndex(bannerIndex+1);
    // eslint-disable-next-line
  }, [values.bannerImage])
  return <>
      <FormBox>
      {/* Title Name */}
      <Typography sx={{pb:"0.5rem"}} >Voucher Title Name</Typography>
      <TextField name="voucherDetails.voucherTitle" value={values.voucherTitle} onChange={handleChange} fullWidth placeholder="Enter voucher name"></TextField>
      { errors?.voucherTitle && <Typography sx={{color:"red"}}>{errors.voucherTitle}</Typography>}
      {/* Brand name */}
      <Typography sx={{pt:"1rem",pb:"0.5rem"}} >Brand Name</Typography>
      <TextField value={values.brandName} name='voucherDetails.brandName' onChange={handleChange} fullWidth placeholder="Enter brand name"></TextField>
      { errors?.brandName && <Typography sx={{color:"red"}}>{errors.brandName}</Typography>}
      {/* Description */}
      <Typography sx={{pt:"1rem",pb:"0.5rem"}} >Description</Typography>
      <TextField
      id="description"
      fullWidth
      rows={5}
      multiline
      variant="outlined"
      name="voucherDetails.description" 
      value={values.description}
      onChange={handleChange}
    />
      {/* <textarea
        style={{
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary,
        }}  
        name="voucherDetails.description" value={values.description} onChange={handleChange} rows={5} /> */}
      { errors?.description && <Typography sx={{color:"red"}}>{errors.description}</Typography>}
      {/* Upload Banner */} 
      <Typography sx={{pt:"1rem",pb:"0.5rem"}} >Upload Banner</Typography>
      <Button variant="outlined" component="label" sx={{color:"black"}}>
        Upload Image
        <VisuallyHiddenInput type="file" onChange={(e) => handleBannerChange(e)} accept=".png, .jpeg, .jpg" />
      </Button>
      <Typography fontSize="13px" sx={{color:"#AAA"}}>Upload multiple files, do not exceed 25MB</Typography>
        <Box           
        key={bannerIndex}>
        {values.bannerImage && <>
          <Box 
          key={bannerIndex}
          sx={{
            mt: "1rem",
            display:"flex",
            border:"1px solid #C5C5C5",
            borderRadius:"4px",
            padding: "0.5rem", 
            justifyContent:"space-between",
          }}>
            <Box sx={{ display:'flex', gap:'1rem'}}>
              <img src={URL.createObjectURL(values.bannerImage)} alt="banner" width="85px"/>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}>
                <Typography> {values.bannerImage.name}</Typography>
                <Typography> 1MB</Typography>
              </Box>
            </Box>
            <img src={icons.delete_icon} alt="delete_banner" onClick={() => removeBannerImage()}/>
          </Box>
        </>}
        </Box>
      {/* <Checkbox onChange={(e) => handleCompanyInfo(!hasCompanyInfo)}/>I want to add Company details */}
      {/* Company Info */}
      { hasCompanyInfo &&
      <>
        <Divider/>
        {/* Upload Company Profile */}
        <Typography sx={{pt:"1rem",pb:"0.5rem"}} >Upload Company Profile</Typography>
          <Button variant="outlined" component="label" sx={{color:"black"}}>
            Upload Image
            <VisuallyHiddenInput type="file" onChange={handleCompanyProfileChange} accept=".png, .jpeg, .jpg" />
          </Button>
          <Typography fontSize="13px" sx={{color:"#AAA"}}>File support png and jpeg only. The maximum file we can handle 12MB.</Typography>
        
          {companyProfileFile && <>
          <Box sx={{
            mt: "1rem",
            display:"flex",
            border:"1px solid #C5C5C5",
            borderRadius:"4px",
            padding: "0.5rem", 
            justifyContent:"space-between",
          }}>
            <Box sx={{ display:'flex', gap:'1rem'}}>
              <img src={URL.createObjectURL(companyProfileFile)} alt="banner" width="85px"/>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}>
                <Typography> {companyProfileFile.name}</Typography>
                <Typography> 1MB</Typography>
              </Box>
            </Box>
            <img src={icons.delete_icon}  alt="delete_company"/>
          </Box>
        </>}
        {/* Company Name */}
        <Typography >Company Name</Typography>
        <TextField value={values.companyName} name="voucherDetails.companyName" onChange={handleChange} fullWidth placeholder="Enter company name"></TextField>
      </>
      }
      </FormBox>
  </>
}