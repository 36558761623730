import React, { useEffect } from "react";
import TemplateTile from "./templateTile.component";
import { Box, CssBaseline, Typography, Container, Grid, Divider, Dialog, Button } from "@mui/material";
import FilterCheckboxes from "./filterCheckboxes.component";
import SortDropdown from "./sortDropdown.component";
import images from "../../../../assets/img";
import icons from "../../../../assets/icons";

export default function SelectTemplateComponent({ type, templates, handleSelect, setCategories, setSubCategories, ordering, setOrdering }) {
  const [showDialog, setShowDialog] = React.useState(false);
  const [cats, setCats] = React.useState<any[]>([]);

  useEffect(() => {
    let catgrs = {};

    templates.forEach(product => {
      if (catgrs[product.category] === undefined) catgrs[product.category] = [];
      catgrs[product.category].push(product.sub_category);
    });

    const cats_arr = Object.keys(catgrs).map(cat => {
      const uniq_sc = [...new Set(catgrs[cat])];
      let category = {
        name: cat,
        subcategories: uniq_sc.map(sc => ({
          name: sc,
          checked: false,
        })),
      };

      // Set checked property only if it doesn't have subcategories
      if (uniq_sc.length === 0) {
        category['checked'] = false;
      }

      return category;
    });
    setCats(cats_arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    <img src={images.cardlist_banner.toString()} style={{ width: '100%' }} alt="Pixel Logo" />
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Container maxWidth={false} style={{ padding: 0 }} sx={{ mt: 4, mb: 4, containerType: 'inline-size' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center' }}>
          <Typography variant="h3">
            All Templates ({templates.length} product{templates.length > 1 && 's'})
          </Typography>
          <Box sx={{
            "@container (max-width: 900px)": {
              display: 'none',
            },
          }}>
            <SortDropdown ordering={ordering} setOrdering={setOrdering} />
          </Box>
        </Box>

        <Divider sx={{ mt: '10px', mb: '30px' }} />

        <Box sx={{
          display: 'none',
          "@container (max-width: 900px)": {
            display: 'flex',
          },
          justifyContent: 'space-between',
          mb: '30px',
        }}>
          <Box>
            <Button
              startIcon={<img src={icons.filter_list} alt="filter" />}
              variant="outlined"
              color="secondary"
              onClick={() => setShowDialog(true)}
            >Filters</Button>
            <Dialog
              open={showDialog}
              onClose={() => setShowDialog(false)}
              fullWidth
              maxWidth='md'
              PaperProps={{ sx: { p: '30px' } }}
            >
              <Box sx={{ display: 'flex', gap: '20px', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'flex-start' }}>
                <FilterCheckboxes
                  cats={cats}
                  setCats={setCats}
                  setCategories={setCategories}
                  setSubCategories={setSubCategories}
                  setShowDialog={setShowDialog}
                />
              </Box>
            </Dialog>
          </Box>
          <Box>
            <SortDropdown ordering={ordering} setOrdering={setOrdering} />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', gap: '20px', flexDirection: { xs: 'column', md: 'row' }, alignItems: { xs: 'stretch', md: 'flex-start' } }}>
          <Box sx={{
            display: 'block',
            "@container (max-width: 900px)": {
              display: 'none',
            },
          }}>
            <FilterCheckboxes
              cats={cats}
              setCats={setCats}
              setCategories={setCategories}
              setSubCategories={setSubCategories}
              setShowDialog={undefined}
            />
          </Box>

          {templates.length > 0 && <Grid container spacing={2}>
            {templates.map(template => (
              <Grid item xs={12} lg={6} xl={4} key={template.uuid}>
                <TemplateTile currency='USD' type={type} template={template} handleSelect={handleSelect} />
              </Grid>
            ))}
          </Grid>}

          {templates.length === 0 && <Grid
            flex={1}
            container
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography fontSize={'18px'} color={'#A7A7A7'}>No result</Typography>
          </Grid>}
        </Box>
      </Container>
    </Box>
  </>
}