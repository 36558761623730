
const getProfileMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);
}
const getProfileWithCardMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);
}
const getActiveProfileByEncodeMiddleware = ({ getState, dispatch }) => next => action => {
  next(action);

  // const { payload, type } = action;
  // const lang = getState().constants.languages;

  // switch (type) {
  //   case signup.fulfilled.type:
  //     localStorage.setItem('session_id', payload.session_id)
  //     localStorage.setItem('session_expiration', payload.session_expiration)
  //     dispatch(setSession(payload))
  //     break;
  //   case signup.rejected.type:
  //     next(
  //       addAlert({
  //         severity: "error",
  //         message: getApiLang(lang, payload.code)
  //       })
  //     );
  //     break;

  //   case resendEmailVerification.fulfilled.type:
  //     next(addAlert({ severity: "success", message: getApiLang(lang, payload.code) }));
  //     break;

  //   case resendEmailVerification.rejected.type:
  //     next(addAlert({ severity: "error", message: getApiLang(lang, payload.code) }));
  //     break;

  //   default:
  //     break;
  // }
};

export const landingPageMdl = [getActiveProfileByEncodeMiddleware,getProfileMiddleware,getProfileWithCardMiddleware ];
