import axios from 'axios';

const approve = (appointment_uuid: string, event_id: string | null) => {
  const storedUser = localStorage.getItem('user');

  if (storedUser === null) {
    return Promise.resolve({
      code: 404,
      data: null,
      message: 'Please log in',
      success: false,
    });
  }

  const user = JSON.parse(storedUser || '{}')
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return axios.get('/appointment/approve', { params: { appointment_uuid, event_id } });
}

export default approve;