import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Container,
  CssBaseline,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
// import pixel_logo from '../../assets/pixel_logo.svg'
import images from '../../../../assets/img';
import icons from '../../../../../src/assets/icons'
import accountApi from '../../../../api/account';
import { useDispatch } from 'react-redux'
import { openActionDialog} from '../../../../features/action-dialog'
import { openResponseDialog} from '../../../../features/response-dialog'
import { UNVERIFIED, WALLET,WALLET_VALIDATE_SUCCESS } from "../../../../lib/constants/routes.js";
import myVoucherApi from '../../../../api/myVoucher';
import { useParams } from 'react-router-dom';
import basicAuthApi from '../../../../api/basicauth';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

export default function ValidateVoucherComponent() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [location, setLocation] = React.useState({ latitude: 0, longitude: 0, isGeoLocationAllowed: false});
  const baseUrl =  process.env.REACT_APP_API_HOST_URL;
  const { userVoucherUUID,voucherCodeUUID} = useParams();

  async function postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });

    if (response.ok) {
      return response.json();
    }

    dispatch(openResponseDialog({title:'Credentials Incorrect',
    description: "Please check your email and password. If needed, click 'Forgot Password' to reset."}))
    return null;
  }

  const handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    setIsLoading(true);

    const data = new FormData(event.currentTarget);
    const body = {
      email: data.get('email')?.toString() || null,
      password: data.get('password')?.toString() || null,
      remember_me: data.get('remember') === 'remember' ? true : false,
      voucher_code_uuid: voucherCodeUUID,  
      langitude: 0,
      latitude: 0,
    };

    if(!body.email || !body.password) { 
      dispatch(openResponseDialog({
        title:'Credentials Incorrect',
        description: "Please check your email and password. If needed, click 'Forgot Password' to reset."
      }))
      setIsLoading(false);
      return;
    }

    await basicAuthApi.signin({requestBody:body}).then(async (response) => {
      setIsLoading(true);
      if(response.success){
        localStorage.setItem("user", JSON.stringify(response.data));

        if (response?.data.access_token_payload.verified !== 1) {
          const email = response?.data.access_token_payload.email
          accountApi.resendEmailVerification({email: email})
          navigate(UNVERIFIED);
        }

        await myVoucherApi.selfValidateRedeem({
          voucher_code_uuid: voucherCodeUUID,
          longitude: location.longitude,
          latitude: location.latitude,
          ip_address: null
        }).then((value) => {
          if (value.success) {
            navigate(`/${WALLET}/${userVoucherUUID}/${WALLET_VALIDATE_SUCCESS}`);
            return;
          }
          dispatch(openResponseDialog({
            title:"Error",
            description: value.message
          })) 
        }).catch(error => {
          dispatch(openResponseDialog({
            title:'ERROR',
            description: error.message
          }))
        })
        return;
      }
      dispatch(openResponseDialog({
        title:"Error",
        description: response.message
      }))
    }).catch(error => {
      dispatch(openResponseDialog({
        title:'ERROR',
        description: error.message
      }))
    }).finally(() => {
      setIsLoading(false);
    })
  };

  const googleLogin = useGoogleLogin({
    onSuccess: function(codeResponse: any) {
      const body = {
        social_signin_type: 'google',
        social_signin_access_token: codeResponse.access_token,
      };

      postData(baseUrl+"/basicauth/socialSignIn", body).then((d) => {
        if (d === null) return;

        localStorage.setItem("user", JSON.stringify(d.data));
        new Promise(() => {
          // TODO: check where to get ipaddress for this 
          myVoucherApi.selfValidateRedeem({
            voucher_code_uuid: voucherCodeUUID,
            longitude: location.lng,
            latitude: location.lat,
            ip_address: null
          }).then((value) => {
            if (value.success) {
              navigate(`/${WALLET}/${userVoucherUUID}/${WALLET_VALIDATE_SUCCESS}`);
            } 
          }
          )
        })
      });
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  const dispatch = useDispatch()
  const handleContactUs= () =>{
    dispatch(openActionDialog( {title: "We're Here to Help",description:`Let us know the details, and we’ll get back to you promptly. Reach us at ${process.env.REACT_APP_SUPPORT_EMAIL} or call ${process.env.REACT_APP_SUPPORT_CONTACT}.`}))
  }

  useEffect(() => {
    if (localStorage.getItem('location') !== null) {
      const location = JSON.parse(localStorage.getItem('location') || '{}')
      setLocation(location)
    }
  }, [])
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs"       style={{ 
        width: '336px',
        height: '100vh',
        display: 'grid',
        alignContent: 'space-around',
        justifyContent: 'center'
        }}>
        <CssBaseline />
        <Paper elevation={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1.3rem',
            borderRadius: '8.78px',
            width: "inherit"
          }}
        >
          <div style={{ margin: '20px' }}>
          <img src={images.pxlicon.toString()} style={{maxWidth:'133px',maxHeight:"33"}} alt="Pixel Logo"/>
            {/* <img src={pixel_logo} alt='PixelCard logo'/> */}
          </div>
          <Typography component="h1" variant="h4">
            Log in to your account
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography component='label' className='required' variant='h5'>
              Email
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              placeholder='enter your email'
            />
            <Typography component='label' className='required' variant='h5'>
              Password
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              name="password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              placeholder='enter your password'
              InputProps={{
                endAdornment: <IconButton sx={{ opacity: 0.5 }} aria-label="show" size="small" onClick={() => setShowPassword(!showPassword)}>
                  <img src={showPassword ? icons.eye_close : icons.eye_open_icon} alt="eye_open_icon"/>
                </IconButton>,
              }}
            />

            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              loadingPosition="start"
              startIcon={isLoading ? <div /> : undefined}
              sx={{ mt: 3, mb: 2, borderRadius: '30px', color: '#FECD20' }}
            >
              <span>{isLoading ? 'Logging in' : 'Log In'}</span>
            </LoadingButton>
            <Typography component="p" variant="body1" align='center'>
              Having trouble logging in? <Link href="#"  onClick={()=> handleContactUs() } sx={{ color: '#FECD20'}}>Contact us</Link>
            </Typography>
          </Box>
          <hr style={{ width: '100%', marginTop: '20px' }} />
          <Typography component="p" variant="body1" align='center' sx={{
            bgcolor: '#F9F9F9',
            position: 'relative',
            top: '-15px',
            paddingX: '5px',
          }}>
            Or login with
          </Typography>

          <div>
            <img onClick={() => googleLogin()} style={{ marginRight: '10px' }} src={icons.login_google_icon.toString()} alt='Google logo'/>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
              onSuccess={(response) => {
                const body = {
                  social_signin_type: 'facebook',
                  social_signin_access_token: response.accessToken,
                };

                postData(baseUrl+"/basicauth/socialSignIn", body).then((d) => {
                  if (d === null) return;
                  console.log(d);

                  localStorage.setItem("user", JSON.stringify(d.data));

                  new Promise(() => {
                    myVoucherApi.selfValidateRedeem({
                      voucher_code: voucherCode,
                      longitude: location.lat,
                      latitude: location.lng,
                      ip_address: null
                    }).then((value) => {
                      if (value.success) {
                        navigate(`/${WALLET}/${userVoucherUUID}/${WALLET_VALIDATE_SUCCESS}`);
                      } 
                    }
                    )
                  })
                });
              }}
              onFail={(error) => {
                console.log('Login Failed!', error);
              }}
              onProfileSuccess={(response) => {
                console.log('Get Profile Success!', response);
              }}
              render={({ onClick, logout }) => (
                <img onClick={onClick} src={icons.login_facebook_icon.toString()} alt='Facebook logo'/>
              )}
            />
          </div>
        </Paper>
      </Container>
    </ThemeProvider>
  )
}