import React, {useState} from "react";
import { Box } from "@mui/system";
import { TextField, Typography,IconButton,Button } from "@mui/material";
import icons from "../../../../assets/icons";
import VoucherRow from "../../component/voucherRow";
import { useNavigate } from "react-router-dom";
import { VOUCHER_CREATE } from "../../../../lib/constants/routes";
import { VoucherList } from "../../../../lib/types/Voucher";
import { useTheme } from "@mui/material/styles";

export default function CampaignDetailComponent({campaignName, accessRights, handleNameChange, voucherList, updateCampaign, handleStatus,handleDelete }:
  {
    campaignName: string,
    handleNameChange: (campaignName: string) => void,
    accessRights: string[],
    voucherList: Array<VoucherList>,
    updateCampaign: (campaignName: string) => void, 
    handleStatus: (voucherUUID: string, active: boolean) => Promise<void>,
    handleDelete: (voucherUUID: string) => Promise<void>
  }) {
    const theme = useTheme();
    const [isEditingCampaignName, setIsEditingCampaignName] = useState(false)
    const navigate = useNavigate(); 
    const openVoucherCreate = () => {
        navigate(VOUCHER_CREATE)
    }
    return (<>
        <Box sx={{
          [theme.breakpoints.down('md')]: {
            display:"flex",flexDirection:"column",justifyContent:"flex-start",pb:"2rem"
          },
          [theme.breakpoints.up('md')]: {
            display:"flex",alignItems:"center",justifyContent:"space-between",pb:"2rem"
          }
          }}>
        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <span style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
            {isEditingCampaignName ? (
                <TextField
                  color='secondary'
                  margin="dense"
                  required
                  id="campaignName"
                  name="campaignName"
                  value={campaignName}
                  onChange={e => handleNameChange(e.target.value)}
                  autoFocus
                />
              ) : (
                <Typography component='span' variant='h1'>
                  {campaignName}
                </Typography>
              )}
              <IconButton aria-label="edit" onClick={e => setIsEditingCampaignName(!isEditingCampaignName)}>
                {isEditingCampaignName ? <img src={icons.thumbs_up} alt='' onClick={() => updateCampaign(campaignName)} /> : <img src={icons.edit_02} alt='' />}
              </IconButton>
            </span>
          </Box>
          <Box
          sx={{
            display: 'flex',
            gap: '1rem',
          }}>
          <Button
                type="button"
                variant="contained"
                color="secondary"
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  textTransform: 'none',
                  borderRadius: '30px',
                  '& .icon': {
                    background: '#FECD20',
                  },  
                }}
                disabled={!accessRights.includes('campaign voucher add')}
                onClick={()=>openVoucherCreate()}
                startIcon={
                <div className='icon' style={{ height: '24px', width: '24px', mask: `url(${icons.privillage_icon})` }}/>
                }
              >
                Create Voucher 
              </Button>
          </Box>

        </Box>
        <Box sx={{width:"inherit",display:"flex", flexDirection:"column",gap:"1rem"}}>
          {voucherList.length > 0 
          ? <>{ voucherList.map((voucher, index) => {
                return <VoucherRow key={index} accessRights={accessRights} voucher={voucher} handleStatus={handleStatus} handleDelete={handleDelete}/>
              })}</>  
          : <Box
            sx={{
              display:"flex",
              flexDirection:"column",
              alignItems:"center",
              pt:"5rem",
              gap:"1rem"
            }}>
            <img src={icons.empty} alt="empty_icon"/>
            <Typography variant="h3" sx={{fontWeight:400, color: '#A7A7A7'}}>Empty List. You can start now.</Typography>
          </Box>
          }
        </Box>
    </>)
}