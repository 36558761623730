import React, { useState,useEffect } from "react";
import { Dialog, Box, Typography } from "@mui/material";
import icons from "src/assets/icons";
import { useTheme } from '@mui/material/styles';
export default function Advertisement({children,display = true}:{children:React.ReactNode,display:boolean|null}) {
  const [advertisement, setAdvertisement] = useState<boolean>(true);
  const theme = useTheme();
  const handleClose = () => {
    setAdvertisement(false);
  }

  useEffect(() => {
    if (display === false) {
      setAdvertisement(() => false)
    }else if(display){
      setAdvertisement(() => true)
    }else{
      setAdvertisement(() => true)
    }
  }, [display])
  
  return (<>
      <Dialog fullWidth  onClose={handleClose} open={advertisement}
      sx={{"& .MuiPaper-root":{
        padding:'2rem',
        [theme.breakpoints.down('md')]: {
          maxWidth:"875px", height:"50%"
        },
        [theme.breakpoints.up('md')]: {
        maxWidth:"875px", height:"100%"
        }
      }}}>
        <img src={icons.close.toString()} alt="close" 
        style={{position:'absolute',right:'10px',top:'10px',cursor:'pointer', height:"15px",width:"15px" }} onClick={handleClose}/>
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '1rem',
      maxWidth:"875px", height:"100%"
    }}>
      {children}
      <Typography>
        Coming Soon
      </Typography>
    </Box>
    </Dialog>
  </>)
}