import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  IconButton,
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import authApi from '../../../../api/auth'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import accountApi from '../../../../api/account';
import { PORTAL } from '../../../../lib/constants/routes';
import DatePicker from '../../../../components/datePicker';
import moment from 'moment';
import icons  from '../../../../assets/icons';
import { SIGN_IN } from '../../../../lib/constants/routes';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

interface Error {
  name: string | null,
  birthday: string | null,
  phoneNumber: string | null
}


export default function PersonalInfoComponent() {

  const navigate = useNavigate();
  const pixel_logo = require("../../assets/pixel_logo.png") as string;
  const [isErrorName, setIsErrorName] = React.useState(false);
  const [birthday, setBirthday] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const [datePickerValue, setDatePickerValue] = React.useState({ day: '01', month: '01', year: '2000' });
  const [errors, setErrors] = React.useState<Error>({
    name: null,
    birthday: null,
    phoneNumber: null,
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let hasError = false;
    const data = new FormData(event.currentTarget);
    const body = {
      name: data.get('name')?.toString() || "",
      phone_number: phoneNumber || "",
      birthday: moment(birthday).format('YYYY-MM-DD') || ""
    };
    const validationErrors = errors

    setIsErrorName(false);
    validationErrors.name = "";
    validationErrors.birthday = ""

    if (body.name === '') {
      hasError = true;
      validationErrors.name = "Incorrect name"
      setIsErrorName(true);
    }
    if (body.phone_number === '') {
      hasError = true;
      validationErrors.phoneNumber = "Incorrect phone"
    }

    if (body.birthday === '' || body.birthday === 'Invalid date') {
      hasError = true;
      validationErrors.birthday = "Incorrect birthday"
    }

    if (!hasError) {
      const user = JSON.parse(localStorage.getItem('user') ?? '{}')
      if (!user) {
        navigate(SIGN_IN);
      } else {
        const response = await authApi.updateSocialSigninInfo({ requestBody: body })
          .then(response => response)
          .catch(error => error)
        if (!response.success) {
          // Ensure user has already verified
          return navigate(PORTAL)
        } else {
          const sessionExpiration = user.session_expiration;
          const sessionId = user.session_id
          const session = await accountApi.checkSession({ session_id: sessionId, session_expiration: sessionExpiration }).then(response => response)

          if (session.success) {
            // Update social_info_verified
            user.access_token_payload.social_info_verified = 1;
            localStorage.setItem("user", JSON.stringify(user));
            return navigate(PORTAL)
          }
          return navigate(PORTAL)
        }
      }
    }
    setErrors(validationErrors)
    console.log(validationErrors)
    return;
  }    

  const handlePhoneChange = (e) =>{
    setPhoneNumber(e)
  }

  const stringToPicker = (dateString: string) => {
    const date = moment(dateString, ['DD/MMM/YYYY']);
    return {
      day: date.format('DD'),
      month: date.format('MMM'),
      year: date.format('YYYY'),
    };
  }

  const pickerToString = ({ day, month, year }) => {
    return `${day}/${month}/${year}`;
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" 
      style={{ 
        maxWidth: '444px',
        height: '100vh',
        display: 'grid',
        alignContent: 'space-around',
        alignItems: 'center',
        justifyItems: 'center'
        }}>
        <CssBaseline />
        <Paper elevation={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem',
            borderRadius: '8.78px',
            maxWidth: '337px'
          }}
        >
          <div style={{ margin: '20px' }}>
            <img src={pixel_logo} alt='PixelCard logo'/>
          </div>
          <Typography component="h1" variant="h4">
            Let's get started!
          </Typography>
          <Typography>         
            Personal Info
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Box>
                <Typography component='label' htmlFor='name' className='required' variant='h5'>
                  Full name
                </Typography>
                <TextField
                  error={isErrorName}
                  color='secondary'
                  margin="dense"
                  required
                  fullWidth
                  id="name"
                  name="name"
                  autoFocus
                />
                <Typography component='label' className='validation-error' variant='h5'>
                  {errors.name}
                </Typography>
              </Box>
              <Box>
              <Typography component='label' htmlFor='birthday' className='required' variant='h5'>
                Date of birth
              </Typography>

              <TextField
                type='text'
                color='secondary'
                margin="dense"
                required
                fullWidth
                id="birthday"
                name="birthday"
                placeholder='DD/MM/YYYY'
                value={Boolean(birthday) ? moment(birthday,'DD/MMM/YYYY').format('DD/MM/YYYY') : birthday}
                error={Boolean(errors.birthday)}
                helperText={errors.birthday}
                onClick={() => setOpenDatePicker(true)}
                InputProps={{
                  endAdornment: <IconButton sx={{ opacity: 0.23, '&:hover': { opacity: 1 } }} aria-label="show" size="small" onClick={() => {}}>
                    <div className='icon' style={{ background: 'black', width: '24px', height: '24px',WebkitMask:`url(${icons.calendar})`, mask: `url(${icons.calendar})` }} />
                  </IconButton>,
                }}
              />
              <DatePicker
                openPicker={openDatePicker}
                setOpenPicker={() => {}}
                pickerValue={datePickerValue}
                setPickerValue={setDatePickerValue}
                handleCancel={() => {
                  setOpenDatePicker(false);
                  if (Boolean(birthday)) {
                    setDatePickerValue(stringToPicker(birthday));
                  }
                }}
                handleConfirm={() => {
                  setOpenDatePicker(false);
                  setBirthday(pickerToString(datePickerValue))
                }}
              />
              <Typography component='label' className='validation-error' variant='h5'>
                  {errors.birthday}
                </Typography>
              </Box>
              <Box>
              <Typography component='label' htmlFor='phoneNumber' className='required' variant='h5'>
                Phone number
              </Typography>
              <PhoneInput
                country="my"
                inputStyle={{
                  width: 'inherit',
                  padding:'20px 20px 14px 60px',
                  fontSize: '1rem'
                }}
                  value={phoneNumber}
                  onChange={handlePhoneChange}
                />
                <Typography component='label' className='validation-error' variant='h5'>
                  {errors.phoneNumber}
                </Typography>
              </Box>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2, borderRadius: '30px' }}
            >
              Continue
            </Button>

          </Box>


        </Paper>
      </Container>
    </ThemeProvider>
  );
}