// ResponsiveDialog.js
import React, {useEffect} from 'react';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, Typography, Box, Button } from '@mui/material';
import { cancelActionDialog, closeActionDialog, confirmActionDialog } from '../../../features/action-dialog';
import images from 'src/assets/img';

const DialogMessage = styled('div')({
  display: 'grid',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center'
});

const DialogActions = styled('div')({
  display: 'flex',
  gap: '1rem',
  padding: '0 1rem',
  justifyContent: 'center' 
})


const ActionDialog = () => {
  const isOpen = useSelector((state) => state.actionDialog.open);
  const content = useSelector((state) => state.actionDialog.content);
  const cancelAction = useSelector((state) => state.actionDialog.cancelAction);
  const isConfirmed = useSelector((state) => state.actionDialog.isConfirmed);
  const isCancelled = useSelector((state) => state.actionDialog.isCancelled);
  const hasCancelButton = useSelector((state) => state.actionDialog.hasCancelButton);
  const dispatch = useDispatch();
  
  const handleClose = () => {
    dispatch(closeActionDialog());
  }

  const handleCancel = () => {
    dispatch(cancelActionDialog());
  }

  const handleConfirm = () => {
    dispatch(confirmActionDialog()) // After confirm dialog most close;
  }
  useEffect(() => {
    if (isConfirmed || isCancelled) handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed, isCancelled])
  return (
    <Dialog fullWidth={content.fullWidth} onClose={handleClose} open={isOpen}  PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' }}}>
      {content.title === 'Your free trial has expired' && <img
        src={images.expired_calendar}
        alt=''
        style={{ display: 'block', margin: '70px auto 30px' }}
      />}
      <DialogTitle style={{display:'flex',flexDirection: 'row',justifyContent: 'center'}}>
        <Typography style={{
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '500',
          textAlign: 'center',
          overflowWrap: 'break-word'
        }}>{content.title}</Typography> 
        </DialogTitle>
        <Box >
      <DialogMessage >
      <Typography sx={{maxWidth: "70%"}}align="center">
        {content.description}
      </Typography>
      
      </DialogMessage>
      <DialogActions>
        {hasCancelButton && <Button
          type="button"
          fullWidth
          onClick={() => Boolean(cancelAction) ? handleCancel() : handleClose()}
          variant="outlined"
          sx={{ mt: 3, mb: 2, maxWidth:"150px",borderRadius: '30px',border: "solid #FECD20", background:"white", color: '#373736',
          "&:hover": {
            backgroundColor: "#373736",
            color:"#FECD20"
          } }}
        >
          {content.cancelText ?? 'Cancel'}
        </Button>}
        <Button
          type="button"
          fullWidth
          onClick={() => handleConfirm()}
          variant="contained"
          sx={{ mt: 3, mb: 2, maxWidth:"150px",borderRadius: '30px', background:"#373736", color: '#FECD20',
          "&:hover": {
            backgroundColor: "#FECD20",
            color:"#373736"
          } }}
          style={{
            
          }}
        >
          {content.confirmText ?? 'Yes'}
        </Button>
      </DialogActions>
      </Box>
    </Dialog>
  );
    };


  export default ActionDialog;


