import React, {useEffect,useState} from 'react'
import FilesComponent from './files.component'
import { useSelector } from "react-redux";
import FileHelper from '../../../lib/helper/fileHelper';

export default function FilesContainer(){
  const file =  useSelector(state => state.landingPage.profile.file)
  const [files, setFiles] = useState([])

  const setFileUrl = async () =>{
    let itemData : any = []
    if(file.content.length > 0){
      file.content.map(async (value,index) =>{
        const url = await FileHelper.getUrl(value.url).then(repsonse => repsonse)
        itemData.push(
          {
            sort: value.sort,
            size:  "1 MB",
            url: url,
            title: value.file_name
          }
        )
      })
    }
    setFiles(itemData)
  }

  useEffect(() => {
    setFileUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    setFileUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  return file.active ? <FilesComponent files={files} /> : <></>
}