
import FilterComponent from "./filter.component";
export default function FilterContainer({handleSearch, handleSort, sortList,handleFilterOption,handleRefresh, selectedSort}:
  {
    handleSort:({field,order,name}:{field:string,order:string,name:string })=>void,
    handleSearch:(search: string | null)=>void,
    sortList: Array<{
      name: string,
      order: string,
      field: string
    }>,
    handleFilterOption: ()=>void,
    handleRefresh: ()=>void,
    selectedSort: string
}) {
    return <FilterComponent 
    handleSearch={handleSearch} 
    handleSort={handleSort} 
    sortList={sortList} 
    handleFilterOption={handleFilterOption}
    handleRefresh={handleRefresh}
    selectedSort={selectedSort}
    />
}