import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Container,
  CssBaseline,
  Paper,
  Typography
} from '@mui/material';
import verify_account_icon from '../../assets/verify_account_icon.svg'

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

export default function CheckInboxComponent(
){
  const pixel_logo = require("../../assets/pixel_logo.png") as string;
  return (
    <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs" 
    style={{ 
      width: '336px',
      height: '100vh',
      display: 'grid',
      alignContent: 'space-around',
      justifyContent: 'center'
      }}>
      <CssBaseline />
      <Paper elevation={9}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '3rem 2rem 5rem 2rem',
          borderRadius: '8.78px',
          width: "inherit"
        }}
      >
        <div style={{ paddingTop: '0.5rem',paddingBottom:"4rem" }}>
          <img src={pixel_logo} alt='PixelCard logo'/>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          paddingBottom:'3rem'
        }}>
          <img src={verify_account_icon} alt="verify_account_icon"/>
        </div>
        <Typography component="h1" variant="h4" style={{paddingBottom:'1rem'}}>
          Check your inbox now!
        </Typography>
        <Typography sx={{fontWeight:"400",textAlign: 'center'}}>         
          Please check your inbox and click in<br/>
          the received link to reset a password.
        </Typography>
      </Paper>
    </Container>
    </ThemeProvider>
  )
}