import React from "react";
import ContactInformationComponent from "./contactInformation.component";

export default function ContactInformationContainer({
  errors,
  totalSections,
  order,
  reorderSection,
  contactSelect,
  setContactSelect,
  isEnabledContactInfo,
  setIsEnabledContactInfo,
  items,
  setItems,
  setItem,
  handleSelectClose,
  reorder,
  remove,
  getListStyle,
  getItemStyle,
  getIcon
}) {
  return (
    <ContactInformationComponent
      errors={errors}
      totalSections={totalSections}
      order={order}
      reorderSection={reorderSection}
      contactSelect={contactSelect}
      setContactSelect={setContactSelect}
      isEnabledContactInfo={isEnabledContactInfo}
      setIsEnabledContactInfo={setIsEnabledContactInfo}
      items={items}
      setItems={setItems}
      setItem={setItem}
      handleSelectClose={handleSelectClose}
      reorder={reorder}
      remove={remove}
      getListStyle={getListStyle}
      getItemStyle={getItemStyle}
      getIcon={getIcon}
    />
  )
}