import React from "react";
import CustomDescriptionComponent from "./customDescription.component";
export default function CustomDescriptionContainer({
  totalSections,
  reorderSection,
  active,
  name,
  content,
  handleDeleteComponent,
  handleEditComponentName,
  handleEditComponentContent,
  handleActiveComponent,
  index
}) {
  return (
    <CustomDescriptionComponent
      totalSections={totalSections}
      reorderSection={reorderSection}
      active={active}
      name={name}
      content={content}
      handleDeleteComponent={handleDeleteComponent}
      handleEditComponentName={handleEditComponentName}
      handleEditComponentContent={handleEditComponentContent}
      handleActiveComponent={handleActiveComponent}
      index={index}
    />
  );
}