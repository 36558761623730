import React, { useEffect, useState } from "react";
import userApi from "../../../../../api/user";
import { startLoading, endLoading } from '../../../../../features/loading';
import { useDispatch } from "react-redux";
import { openResponseDialog } from '../../../../../features/response-dialog';
import { Shipping } from "../../../../../lib/types/User";
import { Skeleton } from "@mui/material";
import AddressForm from "../components/addressForm";


export default function ShippingAddressContainer() {
  const dispatch = useDispatch();
  const [initialAddress, setInitialAddress] = useState<Shipping>();

  const getShipping = async () => {
    dispatch(startLoading());
    await userApi.getShipping()
      .then((res) => {
        if (res.success) {
          setInitialAddress({ ...res.data });
        }
      })
      .catch((err) => {
        console.error(err);
        setInitialAddress({
          address_1: '',
          address_2: '',
          city: '',
          country: 'MY',
          state: 'Kuala Lumpur',
          zip: '',
        });
      })
      .finally(() => dispatch(endLoading()));
  }

  const handleSubmit = (values: any) => {
    dispatch(startLoading());
    userApi.updateShipping({ requestBody: values }).then((res) => {
      if (res.success) {
        dispatch(openResponseDialog({ title: 'Success', description: 'Shipping address updated successfully' }));
      } else {
        dispatch(openResponseDialog({ title: 'Error', description: 'Failed to update billing address' }));
      }
    }).catch((err) => {
      dispatch(openResponseDialog({ title: 'Error', description: 'Failed to update billing address' }));
    }).finally(() => {
      dispatch(endLoading());
    });
  }

  useEffect(() => {
    getShipping();
    // eslint-disable-next-line
  }, []);

  // return <ShippingAddressComponent handleSubmit={handleSubmit} initialAddress={initialAddress}/>;
  return Boolean(initialAddress) ?
    <AddressForm title="Shipping Address" handleSubmit={handleSubmit} initialValues={initialAddress!} />
    : <Skeleton variant="rectangular" width={651} height={200} />;
}