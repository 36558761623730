import React, {useState} from 'react';
import CollectedVoucherPage from './collectedVoucherPage.component';
import myVoucherApi from '../../api/myVoucher';
import { useDispatch } from 'react-redux';
import { startLoading,endLoading } from '../../features/loading'
import { CollectedVoucher } from '../../lib/types/Wallet';
import { setBreadcrumb } from '../../features/admin';
import { PORTAL_WALLET } from '../../lib/constants/routes';

export default function CollectedVoucherPageContainer() {
  const dispatch = useDispatch();
  const [collectedVouchers, setCollectedVouchers] = useState<Array<CollectedVoucher> | []>([]);
  const [historyVouchers, setHistoryVouchers] = useState<Array<CollectedVoucher> | []>([]);
  const getList = async () => {
    dispatch(startLoading());
    await myVoucherApi.getList({start: '', end: ''})
      .then((response) => {
        if(response.success){
          setCollectedVouchers(response.data);
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        dispatch(endLoading());
      })
  }

  const getHistoryList = async () => {
    dispatch(startLoading());
    await myVoucherApi.getHistoryList()
      .then((response) => {
        if(response.success){
          setHistoryVouchers(response.data);
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        dispatch(endLoading());
      })
  }

  React.useEffect(() => {
    getList();
    getHistoryList();
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "Wallet", path: PORTAL_WALLET  },
        { name: "Voucher", path: null }
      ]
    }))
    // eslint-disable-next-line
  }, []);
  return <CollectedVoucherPage 
    collectedVouchers={collectedVouchers}
    historyVouchers={historyVouchers}
  />;
};
