import SelectSearch from 'react-select-search';
import 'react-select-search/style.css';
import './select.css';
import COUNTRIES_CODE from '../../lib/constants/countriesCode';
import COUNTRIES_CODE_ISO from '../../lib/constants/countriesCodeISO';
export default function CountryOption({
  country,
  isISO,
  handleChange
}: {
  country: any;
  isISO?: boolean;
  handleChange: (value: any) => void;
}) {
  const handleCountry = (value:any) => {
    handleChange(value);
  }
  return (
    <SelectSearch  options={isISO?COUNTRIES_CODE_ISO :COUNTRIES_CODE} value={country} search onChange={(value) => handleCountry(value)} />
  );
}