import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  refresh: false,
  refreshDetail: false,
};

const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    setContacts: (state,action) => {
      state.list = [];
      const { payload } = action;
      state.list = payload.contacts;
      state.refresh = false
    },
    refreshContacts: (state,action) =>{
      const { payload } = action;
      state.refresh =  payload;
    },
    refreshDetail: (state,action) =>{
      const { payload } = action;
      state.refreshDetail =  payload;
    },
  },
});

export const { setContacts,refreshContacts,refreshDetail } = contactSlice.actions;
export const contactReducer = contactSlice.reducer;