import axios from "axios";

interface Data {
  code: number;
  data: any;
  message: string | null;
  success: boolean;
}

const getProductInfo = (): Promise<Data> => {
  return new Promise((resolve, reject) => {
    axios.get(`basiczakeke/productInfo`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default getProductInfo;