import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  content: {
    title: "",
    description: "",
    fullWidth: false,
    redirectPath: null,
  },
};

const errorDialogSlice = createSlice({
  name: 'error_dialog',
  initialState,
  reducers: {
    openErrorDialog: (state,action) => {
      state.open = true;
      const { payload } = action;
      state.content.title = payload.title;
      state.content.description = payload.description;
      if(payload?.fullWidth){ 
        state.content.fullWidth = payload.fullWidth
      }else{
        state.content.fullWidth = false
      }
      state.content.redirectPath = payload.redirectPath || null;
    },
    closeErrorDialog: (state) => {
      state.open = false;
    },
  },
});

export const { openErrorDialog, closeErrorDialog } = errorDialogSlice.actions;
export const selectDialogOpen = (state) => state.error_dialog.open;
export const errorDialogReducer = errorDialogSlice.reducer;