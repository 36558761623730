import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import contactListApi from "../../../../api/contactList";
import profileApi from '../../../../api/profile';
import FileHelper from '../../../../lib/helper/fileHelper';
import CreateDialogComponent from "./createDialog.component";

const baseUrl = process.env.REACT_APP_API_HOST_URL;
const getUser = () => JSON.parse(localStorage.getItem('user') ?? '{}');

// TODO: Use axios
// TODO: Factor out this function to keep code DRY
async function uploadFile(filePath: string, file) {
  let user = getUser();
  let fullPath = `user/${user.access_token_payload.uuid}/contacts/${filePath}/${uuidv4()}_${file.name}`;

  // Create presigned post
  const { url, fields } = await FileHelper.createUrl(fullPath).then(repsonse => repsonse)
  // Upload the file
  const formData = new FormData();
  Object.keys(fields).forEach(key => {
    formData.append(key, fields[key]);
  });
  formData.append("file", file);
  const res = await fetch(url, {
    method: "POST",
    body: formData,
  });

  // Get the URL if file uploaded successfully
  if (res.status === 204) {
    return fullPath;
  }
}

interface FormError {
  profile: string;
  name: string;
  phone: string;
  birthday: string;
  email: string;
}

interface Profile {
  uuid: string;
  name: string;
}

function CreateDialog({ open, handleClose, guestUuid = '', contactUuid = '', viewOnly = false }) {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [selectedProfileUUID, setSelectedProfileUUID] = useState('');
  const [imageFullPath, setImageFullPath] = useState<string>('');
  const [image, setImage] = useState<any>();
  const [name, setName] = useState<any>();
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(['']);
  const [birthDate, setBirthDate] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [company, setCompany] = useState<any>();
  const [jobTitle, setJobTitle] = useState('');
  const [otherTitle, setOtherTitle] = useState('');
  const [website, setWebsite] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormError>({
    profile: '',
    name: '',
    phone: '',
    birthday: '',
    email: ''
  });

  const emptyData = () => {
    setImageFullPath('');
    setImage('');
    setName('');
    setPhoneNumbers(['']);
    setBirthDate('');
    setEmail('');
    setCompany('');
    setJobTitle('');
    setWebsite('');
    setAddress('');
  }

  const getProfiles = () => {
    profileApi.getProfilesDropdown()
      .then((response: any) => {
        const prfs = (response.data ?? []).map(prf => ({
          uuid: prf.profile_uuid,
          name: prf.profile_name,
        }));

        if (prfs.length === 1) setSelectedProfileUUID(prfs[0].uuid);

        setProfiles(prfs);
      });
  }

  useEffect(() => {
    getProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (contactUuid === '' && guestUuid === '') {
      emptyData();
      return;
    }

    if (contactUuid !== '') {
      contactListApi.contactList.getDetails(contactUuid)
        .then(data => {
          setImageFullPath(data.profile_picture);
          contactListApi.contactList.getUrl(data.profile_picture)
            .then(url => setImage(url));
          data = data[0];
          setName(data.name);
          setPhoneNumbers(data.phone_number.toString());
          setBirthDate(data.birthday);
          setEmail(data.email.toString());
          setCompany(data.company_name);
          setJobTitle(data.job_title);
          setWebsite(data.website.toString());
          setAddress(data.company_address.toString());
        })
    } else if (guestUuid !== '') {
      contactListApi.contactList.getGuest(guestUuid)
        .then(data => {
          setImageFullPath(data.profile_picture);
          contactListApi.contactList.getUrl(data.profile_picture)
            .then(url => setImage(url));

          setName(data.name);
          setPhoneNumbers(data.phone_number.toString());
          setBirthDate(data.birthday);
          setEmail(data.email.toString());
          setCompany(data.company_name);
          setJobTitle(data.job_title);
          setWebsite(data.company_website.toString());
          setAddress(data.company_address.toString());
        })
    }
  }, [contactUuid, guestUuid]);

  useEffect(() => {
    setErrors({
      profile: '',
      name: '',
      phone: '',
      birthday: '',
      email: ''
    });
  }, [open])


  function handleImageChange(e) {
    setImage(e.target.files[0]);
  }

  function isValidDate(dateString) {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;  // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    var currentDate = new Date(Date.now());
    if (d > currentDate) return false;
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

    setErrors({
      profile: '',
      name: '',
      phone: '',
      birthday: '',
      email: ''
    });
    errors.name = '';
    errors.phone = '';
    errors.birthday = '';
    errors.email = '';

    event.preventDefault();
    var hasError = false;

    if (email === '' || email === undefined) {
      setErrors({
        ...errors,
        email: 'Email required'
      });
      errors.email = 'Email required';
      hasError = true;
    } else {
      var mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!email.match(mailformat)) {
        setErrors({
          ...errors,
          email: 'Invalid email'
        });
        errors.email = 'Invalid email';
        errors.email = 'Invalid email';
        hasError = true;
      }
    }

    if (selectedProfileUUID === '') {
      setErrors({
        ...errors,
        profile: 'Profile required'
      });
      errors.profile = 'Profile required';
      hasError = true;
    }

    if (name === '') {
      setErrors({
        ...errors,
        name: 'Name required'
      });
      errors.name = 'Name required';
      hasError = true;
    }

    if (!Boolean(phoneNumbers[0])) {
      setErrors({
        ...errors,
        phone: 'Phone number required'
      });
      errors.phone = 'Phone number required';
      hasError = true;
    }

    if (birthDate === '') {
      // setErrors({
      //   ...errors,
      //   birthday: 'Invalid birthday'
      // });
      // errors.birthday = 'Invalid birthday';
      // hasError = true;
    } else {
      if (!isValidDate(birthDate)) {
        setErrors({
          ...errors,
          birthday: 'Invalid birthday'
        });
        errors.birthday = 'Invalid birthday';
        hasError = true;
      }
    }

    // if (image === undefined) hasError = true;
    // if (website === '') hasError = true;
    // if (jobTitle === '' || jobTitle === undefined) hasError = true;
    // if (address === '') hasError = true;

    if (hasError) return;

    event.preventDefault();

    let formData = {};

    if (Boolean(selectedProfileUUID)) formData['profile_uuid'] = selectedProfileUUID;
    if (Boolean(name)) formData['name'] = name;
    if (Boolean(phoneNumbers[0])) formData['phone_number1'] = phoneNumbers[0];
    if (Boolean(phoneNumbers[1])) formData['phone_number2'] = phoneNumbers[1];
    if (Boolean(phoneNumbers[2])) formData['phone_number3'] = phoneNumbers[2];
    if (email !== undefined && email !== '') formData['email'] = email;
    if (jobTitle !== undefined && jobTitle !== '') formData['job_title'] = jobTitle;
    if (company !== undefined && company !== '') formData['company_name'] = company;
    if (birthDate !== undefined && birthDate !== '') formData['birthday'] = birthDate;
    if (website !== undefined && website !== '') formData['company_website'] = website;
    if (address !== undefined && address !== '') formData['company_address'] = address;

    let pfp = image !== undefined ? typeof image === 'string' ? imageFullPath : await uploadFile('profile_picture', image) : undefined
    if (pfp !== undefined && pfp !== '') formData['profile_picture'] = pfp;

    // let formData = {
    //   name,
    //   phone_number: phone,
    //   email,
    //   job_title: jobTitle,
    //   company_name: company,
    //   birthday: birthDate,
    //   company_website: website,
    //   company_address: address,
    //   profile_picture: image !== undefined ? typeof image === 'string' ? imageFullPath : await uploadFile('profile_picture', image) : undefined,
    // }
    setIsEditing(false);

    let url = baseUrl + '/contact/addGuest';
    let body = JSON.stringify(formData);

    if (guestUuid !== undefined && guestUuid !== '') {
      body = JSON.stringify({
        guest_uuid: guestUuid,
        ...formData,
      });
      url = baseUrl + '/contact/editGuest';
    }

    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getUser().access_token}`,
      },
      body,
    });

    if (response.ok) {
      // emptyData();
      handleClose(true);
    }
  }
  React.useEffect(() => {
    setIsEditing(false)
  }, [viewOnly])

  return <CreateDialogComponent
    open={open}
    handleClose={handleClose}
    handleSubmit={handleSubmit}
    image={image}
    handleImageChange={handleImageChange}
    guestUuid={guestUuid}
    contactUuid={contactUuid}
    viewOnly={viewOnly}
    profiles={profiles}
    errors={errors}
    isEditing={isEditing}
    setIsEditing={setIsEditing}
    selectedProfileUUID={selectedProfileUUID}
    setSelectedProfileUUID={setSelectedProfileUUID}
    name={name}
    setName={setName}
    phoneNumbers={phoneNumbers}
    setPhoneNumbers={setPhoneNumbers}
    birthDate={birthDate}
    setBirthDate={setBirthDate}
    email={email}
    setEmail={setEmail}
    company={company}
    setCompany={setCompany}
    jobTitle={jobTitle}
    setJobTitle={setJobTitle}
    otherTitle={otherTitle}
    setOtherTitle={setOtherTitle}
    website={website}
    setWebsite={setWebsite}
    address={address}
    setAddress={setAddress}
  />
};

export default CreateDialog;