import add_card_icon from './add_card_icon.svg'
import credit_card from './credit-card.svg'
import back_page_icon from './back_page_icon.svg'
import contacts_icon from './contacts_icon.svg'
import dashbaord_icon from './dashbaord_icon.svg'
import date_filter_icon from './date_filter_icon.svg'
import elements from './elements.svg'
import email_signature_icon from './email _signature_icon.svg'
import environmetal_impact_icon from './environmetal_impact_icon.svg'
import insights_icon from './insights_icon.svg'
import mail from './mail.svg'
import menu_icon from './menu_icon.svg'
import myprofile_icon from './myprofile_icon.svg'
import next_page_icon from './next_page_icon.svg'
import order_list_icon from './order_list_icon.svg'
import privillage_icon from './privillege_icon.svg'
import search_icon from './search_icon.svg'
import settings_icon from './settings_icon.svg'
import support_icon from './support_icon.svg'
import logout_icon from './logout_icon.svg'
import more_landingpage_icon from './more_landingpage_icon.svg'
import close_floatingbutton_landingpage_icon from './close_floatingbutton_landingpage_icon.svg'
import group from './group.svg'
import scan_QR_landingpage_icon from './scan_QR_landingpage_icon.svg'
import building_03_icon from './building-03.svg'
import user_circle_icon from './user-circle.svg'
import user_multiple from './user-multiple.svg'
import share_knowledge from './share-knowledge.svg'
import login_facebook_icon from './login_facebook_icon.svg'
import check_correct from './check_correct.svg'
import check_incorrect from './check_incorrect.svg'
import arrow_left_icon from './arrow-left-01-round.svg'
import arrow_up_01_icon from './arrow-up-01-round.svg'
import arrow_down_01_icon from './arrow-down-01-round.svg'
import arrow_right_icon from './arrow-right-01-round.svg'
import arrow_right from './arrow-right-02-round.svg'
import facebook_landingpage_icon from './facebook_landingpage_icon.svg'
import behance_landingpage_icon from './behance_landingpage_icon.svg'
import instagram_landingpage_icon from './instagram_landingpage_icon.svg'
import linkedin_landingpage_icon from './linkedin_landingpage_icon.svg'
import pinterest_landingpage_icon from './pinterest_landingpage_icon.svg'
import skype_landingpage_icon from './skype_landingpage_icon.svg'
import telegram_landingpage_icon from './telegram_landingpage_icon.svg'
import tiktok_landingpage_icon from './tiktok_landingpage_icon.svg'
import wechat_landingpage_icon from './wechat_landingpage_icon.svg'
import youtube_landingpage_icon from './youtube_landingpage_icon.svg'
import webpage_landingpage_icon from './webpage_landingpage_icon.svg'
import snapchat_landingpage_icon from './snapchat_landingpage_icon.svg'
import facebook_icon from './facebook_icon.svg'
import instagram_icon from './instagram_icon.svg'
import line_icon from './line_icon.svg'
import linkedin_icon from './linkedin_icon.svg'
import x_icon from './X_icon.svg'
import snapchat_icon from './snapchat_icon.svg'
import skype_icon from './skype_icon.svg'
import behance_icon from './behance_icon.svg'
import youtube_icon from './youtube_icon.svg'
import pinterest_icon from './pinterest_icon.svg'
import telegram_icon from './telegram_icon.svg'
import wechat_icon from './wechat_icon.svg'
import tiktok_icon from './tiktok_icon.svg'
import viber_icon from './viber_icon.svg'
import webpage_icon from './webpage_icon.svg'
import property_view from './property-view.svg'
import delete_02 from './delete-02.svg'
import delete_icon from './delete_icon.svg'
import email_icon from './email_icon.svg'
import fax_icon from './fax_icon.svg'
import file_icon from './file_icon.svg'
import link_icon from './link_icon.svg'
import location_icon from './location_icon.svg'
import mobile_icon from './mobile_icon.svg'
import telephone_icon from './telephone_icon.svg'
import whatsapp_icon from './whatsapp_icon.svg'
import login_google_icon from './login_google_icon.svg'
import pdf_01_icon from './pdf-01.svg'
import eye_open_icon from './eye-open.svg'
import eye_close from './eye-close.svg'
import mail_at_sign_02 from './mail-at-sign-02.svg'
import call_ringing_02 from './call-ringing-02.svg'
import more_horizontal_circle_02 from './more-horizontal-circle-02.svg'
import filter_list from './filter-list.svg'
import arrow_up_down_round from './arrow-up-down-round.svg'
import call from './call.svg'
import edit_02 from './edit-02.svg'
import facebook_02 from './facebook-02.svg'
import file_attachment from './file-attachment.svg'
import instagram from './instagram.svg'
import line from './line.svg'
import linkedin_02 from './linkedin-02.svg'
import snapchat from './snapchat.svg'
import skype from './skype.svg'
import behance from './behance-01.svg'
import youtube from './youtube.svg'
import pinterest from './pinterest.svg'
import telegram from './telegram.svg'
import wechat from './wechat.svg'
import tiktok from './tiktok.svg'
import viber from './viber.svg'
import location_04 from './location-04.svg'
import mail_01 from './mail-01.svg'
import more_02 from './more-02.svg'
import smart_phone_01 from './smart-phone-01.svg'
import telephone from './telephone.svg'
import thumbs_up from './thumbs-up.svg'
import twitter_x from './twitter_x.svg'
import back_icon from './back_icon.svg'
import apple_wallet_icon from './apple_wallet_icon.svg'
import copy from './copy.svg'
import rotate from './rotate-360.svg'
import download from './download.svg'
import smart_phone from './smart_phone.svg'
import connect_landingpage_icon from './connect_landingpage_icon.svg'
import exchange_contact_icon from './exchange_contact_icon.svg'
import landingpage_link_broken_icon from './landingpage_link_broken_icon.svg'
import refresh from './refresh.svg'
import action_icon from './action_icon.svg'
import share_contact_icon from './share_contact_icon.svg'
import pixel_logo_square from './PIXEL-Logo-01.svg'
import megaphone from './megaphone.svg'
import tick from './tick.svg'
import close from './close.svg'
import add_icon from './add_icon.svg'
import internet from './internet.svg'
import image from './image-01.svg'
import expand_less_icon from './expand_less_icon.svg'
import expand_more_icon from './expand_more_icon.svg'
import grey_behance_icon from './grey_behance_icon.svg'
import grey_x_icon from './grey_X_icon.svg'
import grey_email_icon from './grey_email_icon.svg'
import grey_facebook_icon from './grey_facebook_icon.svg'
import grey_fax_icon from './grey_fax_icon.svg'
import grey_file_icon from './grey_file_icon.svg'
import grey_instagram_icon from './grey_instagram_icon.svg'
import grey_link_icon from './grey_link_icon.svg'
import grey_linkedin_icon from './grey_linkedin_icon.svg'
import grey_location_icon from './grey_location_icon.svg'
import grey_mobile_icon from './grey_mobile_icon.svg'
import grey_pinterest_icon from './grey_pinterest_icon.svg'
import grey_skype_icon from './grey_skype_icon.svg'
import grey_snapchat_icon from './grey_snapchat_icon.svg'
import grey_telegram_icon from './grey_telegram_icon.svg'
import grey_tiktok_icon from './grey_tiktok_icon.svg'
import grey_viber_icon from './grey_viber_icon.svg'
import grey_webpage_icon from './grey_webpage_icon.svg'
import grey_whatsapp_icon from './grey_whatsapp_icon.svg'
import grey_youtube_icon from './grey_youtube_icon.svg'
import grey_telephone_icon from './grey_telephone_icon.svg'
import grey_wechat_icon from './grey_wechat_icon.svg'
import x_landingpage_icon from './X_landingpage_icon.svg'
import viber_landingpage_icon from './viber_landingpage_icon.svg'
import phone_multiplecontact_icon from './phone_multiplecontact_icon.svg'
import email_multiplecontact_icon from './email_multiplecontact_icon.svg'
import telephone_multiplecontact_icon from './telephone_multiplecontact_icon.svg'
import share_icon from './share-07.svg'
import profile_picture_icon from './profile_picture_icon.svg'
import trash_icon_btn from './trash_icon_btn.svg'
import plus_icon from './plus_icon.svg'
import plus_sign_circle from './plus-sign-circle.svg'
import minus_sign_circle from './minus-sign-circle.svg'
import link_square_bw from './link-square-bw.svg'
import download_attachment_icon from './download_attachment_icon.svg'
import file_attachment_icon from './file_attachment_icon.svg'
import pixel_logo_white from './pixel_logo_white.svg'
import menu_burger from './menu_burger.svg'
import member_badge_icon from './member_badge_icon.svg'
import connection_icon from './connection.svg'
import wifi_icon from './wifi.svg'
import battery_icon from './battery.svg'
import user_add from './user-add--01.svg'
import user_lock from './user-lock-01.svg'
import user_unlock from './user-unlock-01.svg'
import qr_code from './qr-code.svg'
import calendar_number from './calendar-01.svg'
import calendar_appt from './calendar-02.svg'
import calendar from './calendar-03.svg'
import calendar_remove from './calendar-remove-01.svg'
import calendar_upload from './calendar-upload-01.svg'
import link_gcal from './link_gcal.svg'
import unlink_gcal from './unlink_gcal.svg'
import message_edit from './message-edit-01.svg'
import time from './time-04.svg'
import checkmark_icon from './checkmark_icon.svg'
import discount from './discount.svg'
import wallet from './wallet.svg'
import list_dash from './left-to-right-list-dash.svg'
import multiplication_sign from './multiplication-sign.svg'
import minus_blue from './minus_blue.svg'
import checkmark_circle_white from './checkmark-circle-white.svg'
import slider_thumb from './slider_thumb.svg'
import empty from './empty.svg'
import arrow_left from './arrow_left.svg'
import arrow_right_no_margin from './arrow_right.svg'
import default_theme from './default_theme.svg'
import rose_blue_theme from './rose_blue_theme.svg'
import purple_theme from './purple_theme.svg'
import black_grey_theme from './black_grey_theme.svg'
import blue_purple_theme from './blue_purple_theme.svg'
import lime_green_theme from './lime_green_theme.svg'
import lime_yellow_black_theme from './lime_yellow_black_theme.svg'
import pink_peach_theme from './pink_peach_theme.svg'
import popsicle_blue_theme from './popsicle_blue_theme.svg'
import purple_blue_theme from './purple_blue_theme.svg'
import red_black_theme from './red_black_theme.svg'
import shopping_cart from './shopping-cart.svg'
import folders from './folders.svg'
import validate_success from './validate_success.svg'
import buycard_success from './buycard_success.svg'
import buycard_pending from './buycard_pending.svg'
import invoice_logo from './invoice_logo.svg'
import arrow_right_round from './arrow-right-round.svg'
import arrow_right_double_round from './arrow-right-double-round.svg'
import notification from './notification-02.svg'
import double_tick from './tick-double-02.svg'
import lock from './lock.svg'
import marker_contact from './marker_contact.svg'
import marker_profile from './marker_profile.svg'
import marker_contact_cluster from './marker_contact_cluster.svg'
import marker_profile_cluster from './marker_profile_cluster.svg'
import info from './info_icon.svg'
import save_contact from './save_contact_icon.svg'
import marketing from './marketing1.svg'
import coupon from './coupon.svg'
const icons = {
  coupon,
  marketing,
  marker_contact_cluster,
  marker_profile_cluster,
  notification,
  double_tick,
  arrow_right_round,
  arrow_right_double_round,
  invoice_logo,
  buycard_pending,
  buycard_success,
  validate_success,
  wallet,
  arrow_right,
  discount,
  list_dash,
  time,
  calendar_number,
  calendar_appt,
  calendar,
  calendar_remove,
  calendar_upload,
  link_gcal,
  unlink_gcal,
  message_edit,
  folders,
  arrow_left,
  arrow_right_no_margin,
  empty,
  slider_thumb,
  checkmark_circle_white,
  minus_blue,
  red_black_theme,
  purple_blue_theme,
  popsicle_blue_theme,
  pink_peach_theme,
  lime_yellow_black_theme,
  lime_green_theme,
  blue_purple_theme,
  black_grey_theme,
  purple_theme,
  default_theme,
  rose_blue_theme,
  user_add,
  user_lock,
  user_unlock,
  qr_code,
  connection_icon,
  wifi_icon,
  battery_icon,
  checkmark_icon,
  menu_burger,
  pixel_logo_white,
  download_attachment_icon,
  file_attachment_icon,
  phone_multiplecontact_icon,
  email_multiplecontact_icon,
  telephone_multiplecontact_icon,
  link_square_bw,
  share_icon,
  profile_picture_icon,
  trash_icon_btn,
  x_landingpage_icon,
  viber_landingpage_icon,
  member_badge_icon,
  grey_wechat_icon,
  grey_telephone_icon,
  grey_youtube_icon,
  grey_whatsapp_icon,
  grey_webpage_icon,
  grey_viber_icon,
  grey_tiktok_icon,
  grey_telegram_icon,
  grey_snapchat_icon,
  grey_skype_icon,
  grey_pinterest_icon,
  grey_mobile_icon,
  grey_location_icon,
  grey_linkedin_icon,
  grey_link_icon,
  grey_instagram_icon,
  grey_file_icon,
  grey_fax_icon,
  grey_facebook_icon,
  grey_email_icon,
  grey_x_icon,
  grey_behance_icon,
  internet,
  image,
  expand_less_icon,
  expand_more_icon,
  add_icon,
  close,
  tick,
  megaphone,
  pixel_logo_square,
  share_contact_icon,
  action_icon,
  call,
  edit_02,
  facebook_02,
  file_attachment,
  instagram,
  line,
  linkedin_02,
  snapchat,
  skype,
  behance,
  youtube,
  pinterest,
  telegram,
  wechat,
  tiktok,
  viber,
  location_04,
  mail_01,
  more_02,
  smart_phone_01,
  telephone,
  thumbs_up,
  twitter_x,
  filter_list,
  arrow_up_down_round,
  mail_at_sign_02,
  call_ringing_02,
  more_horizontal_circle_02,
  delete_02,
  property_view,
  pdf_01_icon,
  eye_open_icon,
  eye_close,
  arrow_left_icon,
  arrow_up_01_icon,
  arrow_down_01_icon,
  arrow_right_icon,
  building_03_icon,
  user_circle_icon,
  user_multiple,
  share_knowledge,
  landingpage_link_broken_icon,
  exchange_contact_icon,
  connect_landingpage_icon,
  copy,
  rotate,
  download,
  smart_phone,
  apple_wallet_icon,
  back_icon,
  whatsapp_icon,
  telephone_icon,
  mobile_icon,
  location_icon,
  link_icon,
  file_icon,
  fax_icon,
  email_icon,
  delete_icon,
  credit_card,
  add_card_icon,
  back_page_icon,
  contacts_icon,
  dashbaord_icon,
  date_filter_icon,
  elements,
  email_signature_icon,
  environmetal_impact_icon,
  insights_icon,
  mail,
  menu_icon,
  myprofile_icon,
  next_page_icon,
  order_list_icon,
  privillage_icon,
  search_icon,
  settings_icon,
  support_icon,
  logout_icon,
  more_landingpage_icon,
  close_floatingbutton_landingpage_icon,
  group,
  scan_QR_landingpage_icon,
  check_correct,
  facebook_landingpage_icon,
  behance_landingpage_icon,
  instagram_landingpage_icon,
  linkedin_landingpage_icon,
  pinterest_landingpage_icon,
  skype_landingpage_icon,
  telegram_landingpage_icon,
  tiktok_landingpage_icon,
  wechat_landingpage_icon,
  youtube_landingpage_icon,
  webpage_landingpage_icon,
  snapchat_landingpage_icon,
  facebook_icon,
  instagram_icon,
  line_icon,
  linkedin_icon,
  x_icon,
  snapchat_icon,
  skype_icon,
  behance_icon,
  youtube_icon,
  pinterest_icon,
  telegram_icon,
  wechat_icon,
  tiktok_icon,
  viber_icon,
  webpage_icon,
  login_facebook_icon,
  login_google_icon,
  check_incorrect,
  refresh,
  plus_icon,
  plus_sign_circle,
  minus_sign_circle,
  multiplication_sign,
  shopping_cart,
  lock,
  marker_contact,
  marker_profile,
  info,
  save_contact
}

export default icons