import axios from "axios";

interface Data {
  success: boolean;
  code: number;
  message: string;
  data: null;
}

const editPixelcare = (cart_uuid: string, status: number): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get(`product/editPixelcare?cart_uuid=${cart_uuid}&pixelcare=${status}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default editPixelcare;