import React, { useState } from 'react';
import { styled } from '@mui/system';
import icons from '../../../../assets/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Box,Typography, TextField, IconButton } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { clearAppointmentRequest, setAppointmentRequest } from '../../../../features/book-appointment';
import { BOOK_APPOINTMENT_SUMMARY } from '../../../../lib/constants/routes';
import DatePicker from 'react-date-picker';
import './DatePicker.css';
import './Calendar.css';
import moment from 'moment';
import TimePicker from './timePicker';
import appointmentApi from '../../../../api/appointment';
import LoadingButton from '@mui/lab/LoadingButton';
import PhoneInput from '../../../../components/phoneInput';
import { openResponseDialog } from '../../../../features/response-dialog';
const CustomContainer = styled('div')({
  paddingTop: "1rem",
});

const CustomHeader = styled('div')({
  paddingTop: "1rem",
  paddingLeft: '1.5rem',
  display: 'flex',
  alignItems: 'center',
})

const stringToPicker = (timeString: string) => {
  const time = moment(timeString, ['H:mm']);
  return {
    hour: time.format('h'),
    colon: ':',
    minute: time.format('mm'),
    kosong: ' ',
    amPm: time.format('A'),
  };
}

const pickerToString = ({ hour, minute, amPm }) => {
  const hr = `0${(Number(hour) + (amPm === 'PM' ? (hour < 12 ? 12 : 0) : (hour < 12 ? 0 : 12))) % 24}`.slice(-2);
  return `${hr}:${minute}`;
}

export default function AppointmentForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [startPickerValue, setStartPickerValue] = useState(stringToPicker(moment().format('HH:mm')));
  // const [endPickerValue, setEndPickerValue] = useState(stringToPicker(moment().format('HH:mm')));

  interface Error {
    // title: string | null;
    email: string | null;
    name: string | null;
    phone: string | null;
    date: string | null;
    start_time: string | null;
    // end_time: string | null;
    notes: string | null;
  }

  const [errors, setErrors] = useState<Error>({
    // title: null,
    email: null,
    name: null,
    phone: null,
    date: null,
    start_time: null,
    // end_time: null,
    notes: null,
  })

  const landingPage = useSelector((state: any) => state.landingPage);
  const userUUID = landingPage.profile.user_uuid;
  const accessPath = landingPage.accessPath;
  const dispatch = useDispatch();
  const bookAppointment = useSelector((state: any) => state.bookAppointment);
  const appointmentRequest = bookAppointment.appointmentRequest;
  const [appointment, setAppointment] = useState(appointmentRequest);
  const [openStartPicker, setOpenStartPicker] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCode, setPhoneCode] = useState('+60');
  // const [openEndPicker, setOpenEndPicker] = useState(false);

  const handleBack = () => {
    dispatch(clearAppointmentRequest());
    navigate(`${accessPath}/${id}`);
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();

    let hasError = false;
    // const phoneNo = parsePhoneNumber(appointment.phone_number, 'MY');
    const body = {
      ...appointment,
      user_uuid: userUUID,
      phone_number: phoneCode+phoneNumber,
    };

    const validationErrors = { ...errors }

    // validationErrors.title = "";
    validationErrors.name = "";
    validationErrors.email = "";
    validationErrors.phone = "";
    validationErrors.date = "";
    validationErrors.start_time = "";
    // validationErrors.end_time = "";
    validationErrors.notes = "";

    // if (body.title === '') {
    //   hasError = true;
    //   validationErrors.title = "Title required"
    // }
    if (body.name === '') {
      hasError = true;
      validationErrors.name = "Name required"
    }
    if (body.email === '') {
      hasError = true;
      validationErrors.email = "Email required"
    } else {
      var mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      var email = body.email as any;

      if (!email.match(mailformat)) {
        hasError = true;
        validationErrors.email = "Incorrect email format"
      }
    }
    if (body.phone_number === '') {
      hasError = true;
      validationErrors.phone = "Phone number required"
    }

    if (body.date === '') {
      hasError = true;
      validationErrors.date = "Date required"
    }
    if (body.start_time === '') {
      hasError = true;
      validationErrors.start_time = "Start time required"
    } else {
      let now = moment();
      let date = moment(body.date, 'YYYY-MM-DD');
      let start_time = moment(body.start_time, 'HH:mm');

      if (date.isSameOrBefore(now) && start_time.isSameOrBefore(now)) {
        hasError = true;
        validationErrors.start_time = "That time has passed";
      }
    }
    // if (body.end_time === '') {
    //   hasError = true;
    //   validationErrors.end_time = "End time required"
    // }
    if (body.notes === '') {
      hasError = true;
      validationErrors.notes = "Appointment notes required"
    }

    setErrors(validationErrors)
    if (!hasError) {
      await appointmentApi.bookAppointment({
        profile_uuid: landingPage.profile.profile_uuid,
        name: body.name,
        phone_number: body.phone_number,
        email: body.email,
        date: body.date,
        time: `${body.start_time}:00`,
        note: body.notes,
      }).then((response)=>{
        if (response?.data.success) {
          dispatch(setAppointmentRequest(body));
          navigate(BOOK_APPOINTMENT_SUMMARY);
        }else{
          dispatch(openResponseDialog({title: "Book Appointment failed",description: response.message}))
        }
      }).catch((err)=>{
        const message = err?.response?.data?.message || null;  
        if(message){
          dispatch(openResponseDialog({title: "Book Appointment failed",description: message}))
        }else{
          dispatch(openResponseDialog({title: "Book Appointment failed",description: err.message}))
        }
      })
    }
    setIsLoading(false);
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAppointment({
      ...appointment,
      [e.target.name]: e.target.value,
    });
  };

  const handlePhoneChange = (phoneCodes: string, phoneNo: string | undefined) =>{

    let phone: any = phoneNo?.replace(/\D/g, '');

    if (phoneNo !== undefined && phoneNo !== '') setPhoneNumber(phone);

    if (phoneCodes !== undefined && phoneCodes !== '') setPhoneCode(phoneCodes)
  }

  const handleDateChange = (date) => {
    setAppointment({
      ...appointment,
      date: moment(date).format('YYYY-MM-DD'),
    });
  };

  return <>
    <CustomHeader>
      <IconButton onClick={handleBack}>
        <img src={icons.back_icon} alt="back_icon" />
      </IconButton>
      <Typography sx={{ fontSize: '20px', fontWeight: 500, color: '#373736', ml: '20px' }}>
        Appointment
      </Typography>
    </CustomHeader>
    <CustomContainer>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, padding: '0rem 2rem' }}>
        {/* <div>
          <Typography component='label' className='required' variant='h5'>
            Title
          </Typography>
          <TextField
            type='text'
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="title"
            name="title"
            autoComplete="title"
            autoFocus
            placeholder='e.g. Meeting'
            value={appointment.title}
            onChange={handleInputChange}
            error={Boolean(errors.title)}
            helperText={errors.title}
          />
        </div> */}
        <div>
          <Typography component='label' className='required' variant='h5'>
            Name
          </Typography>
          <TextField
            type='text'
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="name"
            name="name"
            autoComplete="name"
            autoFocus
            value={appointment.name}
            onChange={handleInputChange}
            error={Boolean(errors.name)}
            helperText={errors.name}
          />
        </div>
        <div>
          <Typography component='label' className='required' variant='h5'>
            Phone Number
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <PhoneInput
              code={phoneCode}
              value={phoneNumber}
              disabled={false}
              setVal={handlePhoneChange}
            />
          </Box>
          {/* <TextField
            type='tel'
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="phone_number"
            name="phone_number"
            autoComplete="tel"
            value={appointment.phone_number}
            onChange={handleInputChange}
            error={Boolean(errors.phone)}
            helperText={errors.phone}
          /> */}
        </div>
        <div>
          <Typography component='label' className='required' variant='h5'>
            Email
          </Typography>
          <TextField
            type='email'
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            value={appointment.email}
            onChange={handleInputChange}
            error={Boolean(errors.email)}
            helperText={errors.email}
          />
        </div>
        <div className={`datepicker ${Boolean(errors.date) ? 'has-error' : ''}`}>
          <Typography component='label' className='required' variant='h5'>
            Date
          </Typography>
          <DatePicker
            id='date'
            name='date'
            required={true}
            locale='en-MY'
            calendarIcon={<div className='icon' style={{ mask: `url(${icons.calendar})` }} />}
            clearIcon={null}
            value={appointment.date}
            onChange={handleDateChange}
            minDate={new Date()}
          />
          {Boolean(errors.date) && <p style={{ color: '#d32f2f', margin: '3px 14px 0', fontSize: '12px' }}>{errors.date}</p>}
        </div>
        <div>
          <Typography component='label' className='required' variant='h5'>
            Time
          </Typography>
          <TextField
            type='text'
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="start_time"
            name="start_time"
            value={Boolean(appointment.start_time) ? moment(appointment.start_time, ['H:mm']).format('hh:mm A') : appointment.start_time}
            error={Boolean(errors.start_time)}
            helperText={errors.start_time}
            onClick={() => setOpenStartPicker(true)}
            InputProps={{
              endAdornment: <IconButton sx={{ opacity: 0.23, '&:hover': { opacity: 1 } }} aria-label="show" size="small">
                <div className='icon' style={{ background: 'black', width: '24px', height: '24px', mask: `url(${icons.time})` }} />
              </IconButton>,
            }}
          />
          <TimePicker
            openPicker={openStartPicker}
            setOpenPicker={setOpenStartPicker}
            pickerValue={startPickerValue}
            setPickerValue={setStartPickerValue}
            handleCancel={() => {
              setOpenStartPicker(false);

              if (Boolean(appointment.start_time)) {
                setStartPickerValue(stringToPicker(appointment.start_time));
              }
            }}
            handleConfirm={() => {
              setAppointment({
                ...appointment,
                start_time: pickerToString(startPickerValue)
              });
              setOpenStartPicker(false);
            }}
          />
        </div>
        {/* <div>
          <Typography component='label' className='required' variant='h5'>
            End Time
          </Typography>
          <TextField
            type='text'
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="end_time"
            name="end_time"
            value={Boolean(appointment.end_time) ? moment(appointment.end_time, ['H:mm']).format('hh:mm A') : appointment.end_time}
            error={Boolean(errors.end_time)}
            helperText={errors.end_time}
            onClick={() => setOpenEndPicker(true)}
            InputProps={{
              endAdornment: <IconButton sx={{ opacity: 0.23, '&:hover': { opacity: 1 } }} aria-label="show" size="small" onClick={() => {}}>
                <div className='icon' style={{ background: 'black', width: '24px', height: '24px', mask: `url(${icons.time})` }} />
              </IconButton>,
            }}
          />
          <TimePicker
            openPicker={openEndPicker}
            setOpenPicker={setOpenEndPicker}
            pickerValue={endPickerValue}
            setPickerValue={setEndPickerValue}
            handleCancel={() => {
              setOpenEndPicker(false);

              if (Boolean(appointment.end_time)) {
                setEndPickerValue(stringToPicker(appointment.end_time));
              }
            }}
            handleConfirm={() => {
              setAppointment({
                ...appointment,
                end_time: pickerToString(endPickerValue)
              });
              setOpenEndPicker(false);
            }}
          />
        </div> */}
        <div>
          <Typography component='label' className='required' variant='h5'>
            Notes
          </Typography>
          <TextField
            type='text'
            color='secondary'
            margin="dense"
            fullWidth
            multiline
            rows={4}
            id="notes"
            name="notes"
            value={appointment.notes}
            onChange={handleInputChange}
            error={Boolean(errors.notes)}
            helperText={errors.notes}
          />
        </div>
        <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loading={isLoading}
            loadingPosition="start"
            startIcon={isLoading ? <div /> : undefined}
            sx={{
              my: 2,
              gap: '1rem',
              borderRadius: '30px',
              height: '52px',
              color: '#FECD20',
              textTransform: 'none',
            }}
          >
            <span>{isLoading ? 'Arranging Appointment' : 'Make Appointment'}</span>
          </LoadingButton>
      </Box>
    </CustomContainer>
  </>
}
