import React from "react";
import { Box, Button, Dialog, DialogTitle, IconButton, Typography, TextField } from "@mui/material";
import icons from "../../../assets/icons";
import { styled } from "@mui/system";
import campaignApi from "../../../api/campaign";
import { Formik } from "formik";
import { openErrorDialog } from '../../../features/error-dialog';
import { openResponseDialog } from '../../../features/response-dialog';
import { useDispatch } from "react-redux";

const DialogActions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})
const DialogCloseBtn = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end'
})
const createCampaign = (campaignName) => {
  return new Promise((resolve, reject) => {
    campaignApi.add({ campaignName: campaignName })
    .then(response => resolve(response))  
    .catch(error => reject(error))
  })
}

export default function CreateCampaignDialog({ open, onClose }: { open: boolean, onClose: () => void }) {
  const dispatch = useDispatch();
  return (
    <Dialog fullWidth maxWidth='md' PaperProps={{ sx: { borderRadius: "25px", p: '20px 32px', padding: "3rem" } }} onClose={onClose} open={open}>
      <DialogCloseBtn>
        <IconButton onClick={onClose}>
          <img src={icons.close} alt="close" />
        </IconButton>
      </DialogCloseBtn>

      <DialogTitle style={{
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        paddingBottom: '1rem',
      }}>
        <Typography variant='h3'>
          Create Campaign
        </Typography>

      </DialogTitle>
      <Formik
        initialValues={{
          campaignName: '',
        }}
        validate={
          values => {
            const errors: any = {};
            if (!values.campaignName) {
              errors.campaignName = "Required"
            }
            return errors;
          }
        }
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          createCampaign(values.campaignName).then(response => {
            dispatch(openResponseDialog({
              title: "Create Campaign Success",
              description: "Create Campaign Success", 
            }));
            setSubmitting(false);
            onClose();
            return response
          }).catch(error => { 
            dispatch(openErrorDialog({
              title: "Create Campaign Failed",
              description: error.message, 
            }));
            setSubmitting(false);
            return error 
          })
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleChange,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Typography sx={{ fontWeight: "500", pb: "0.5rem" }}>Campaign Name</Typography>
            <TextField name="campaignName" 
            value={values.campaignName}
            onChange={handleChange}
            disabled={isSubmitting} 
            type="text" 
            size="small" 
            fullWidth 
            placeholder="Campaign Name"></TextField>
            {
              errors?.campaignName ? <Typography sx={{color:"red"}}>{errors.campaignName}</Typography> : null
            }
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              pb: '4rem',
            }}>

            </Box>

            <DialogActions>
              <Box sx={{
                width: '145px',
                display: 'flex',
                flexDirection: {
                  xs: 'row',
                  md: 'column'
                }
              }}>
                <Button  type="submit" variant="contained" color="secondary"> Create</Button>
                <Button onClick={() => onClose()}> Cancel </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  )
}