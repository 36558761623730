
export const getLocation = () =>{
  const location = new Promise((resolve, reject) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          resolve({ lat, lng, isGeoLocationAllowed: true});
        },
        function (error) {
          console.error("Error Code = " + error.code + " - " + error.message);
          reject();
        }
      );
    }else {
      reject();
    }
  });
  location.then((e) => {
    localStorage.setItem("location", JSON.stringify({
      latitude: e.lat,
      longitude: e.lng,
      isGeoLocationAllowed: true
    }));
  }).catch(() => {
    localStorage.setItem("location", JSON.stringify({
      latitude: 0, 
      longitude: 0,
      isGeoLocationAllowed: false
    }));
  });
  return location;
}

