import React from "react";
import { Avatar, Box, Chip, Dialog, IconButton, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import icons from "../../../assets/icons";
import moment from "moment";
import ActionsDialog from "./actionsDialog.component";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppointmentsDialog({ dialogAppts, setDialogAppts, profiles }) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = React.useState<any>({});
  const chipStyle = {
    mx: '5px',
    borderRadius: '30px',
    fontSize: 13,
    fontWeight: 500,
    textTransform: 'capitalize',
  };
  const approvedChipStyle = {
    ...chipStyle,
    background: '#E7F6EA',
    color: '#0BA02C',
  };
  const declinedChipStyle = {
    ...chipStyle,
    background: '#FFF0DA',
    color: '#FFB443',
  };

  return <Dialog
    sx={{ top: '50%' }}
    PaperProps={{
      sx: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        bgcolor: '#F9FAFC',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        overflow: 'clip',
        p: '20px',
      }
    }}
    fullScreen
    open={Boolean(dialogAppts.length)}
    onClose={() => setDialogAppts([])}
    scroll="paper"
    TransitionComponent={Transition}
  >
    {dialogAppts.map(appointment => <Box
      onClick={() => {
        setSelectedAppointment(appointment);
        setOpenDialog(true);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        background: 'white',
        borderRadius: '10px',
        borderLeft: `10px solid ${profiles.filter(prf => prf.uuid === appointment.profile_uuid)[0]?.color ?? 'transparent'}`,
        p: '10px',
        cursor: 'pointer'
      }}
      key={appointment.appointment_uuid}
    >
      <Avatar src={appointment.profile_picture ?? icons.profile_picture_icon} sx={{ width: 57, height: 57 }} alt="" />
      <Box sx={{ mx: '5px', flex: 1 }}>
        <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>{appointment.name}</Typography>
        <Typography sx={{ fontSize: '13px', fontWeight: 400, color: '#373736' }}>{moment(`${appointment.date}${appointment.time}`, 'YYYY-MM-DDTHH:mm').format('D MMMM YYYY, h:mm A')}</Typography>
        <Box component={'span'} sx={{ color: '#EEECFF', background: appointment.type === 'requester' ? '#826FFF' : '#A638E9', borderRadius: '4px', fontSize: '10px', p: '5px' }}>{appointment.type === 'requester' ? 'Requester' : 'Host'}</Box>
        <Chip sx={appointment.status === 'approved' ? approvedChipStyle : declinedChipStyle} label={appointment.status} />
      </Box>
      <IconButton>
        <img src={icons.arrow_right_icon} alt="" />
      </IconButton>
    </Box>)}
    <ActionsDialog appointment={selectedAppointment} open={openDialog} handleClose={() => setOpenDialog(false)} profile={profiles.filter(prf => prf.uuid === dialogAppts.filter(appt => appt.appointment_uuid === selectedAppointment.appointment_uuid)[0]?.profile_uuid)[0]} />
  </Dialog>
};