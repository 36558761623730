import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Chip, Divider, Link, Typography } from "@mui/material";
import icons from "../../../assets/icons";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { endLoading, startLoading } from "../../../features/loading";
import appointmentApi from "../../../api/appointment";
import ActionsDialog from "./actionsDialog.component";
import FileHelper from '../../../lib/helper/fileHelper';

function AppointmentDetails({ mode = '', appointment, profile }) {
  const dispatch = useDispatch();
  const [presignedUrl, setPresignedUrl] = useState<string>();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any>({});
  const [status] = React.useState('');
  const [gcalEventId, setGcalEventId] = React.useState<string>();
  const [name, setName] = React.useState('');
  const [date, setDate] = React.useState('');
  const [time, setTime] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [type, setType] = React.useState('');
  const [profileName, setProfileName] = React.useState('');
  const [note, setNote] = React.useState('');
  const accessRights: any = useSelector<any>(state => state.access.access_right);

  useEffect(() => {
    dispatch(startLoading());
    appointmentApi.getDetails(appointment.appointment_uuid).then(res => {
      const details = res.data.data;
      setGcalEventId(details.gcal_event_id);
      setName(details.name);
      setDate(details.date);
      setTime(details.time);
      setPhone(details.phone[0]);
      setEmail(details.email[0]);
      setType(details.type);
      setProfileName(details.profile_name);
      setNote(details.note);

      // Get profile image if set
      if (Boolean(details.profile_image)) {
        FileHelper.getUrl(details.profile_image).then(url => {
          setPresignedUrl(url);
        });
      }
    }).finally(() => dispatch(endLoading()));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chipStyle = {
    borderRadius: '30px',
    fontSize: 13,
    fontWeight: 500,
    textTransform: 'capitalize',
  };
  const approvedChipStyle = {
    ...chipStyle,
    background: '#E7F6EA',
    color: '#0BA02C',
  };
  const declinedChipStyle = {
    ...chipStyle,
    background: '#FFECEC',
    color: '#FF0000',
  };

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    minWidth: '345px',
    // border: '1px solid var(--Status-Default, #C5C5C5)',
    // borderRadius: '4px',
    padding: '16px',
  }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#826FFF', borderRadius: '4px', p: '5px 10px', mb: '10px' }}>
      <Typography sx={{ color: '#EEECFF', fontSize: '13px', fontWeight: 500, textTransform: 'capitalize' }}>
        {type}
      </Typography>
      {Boolean(status) && <Chip sx={status === 'approved' ? approvedChipStyle : declinedChipStyle} label={status} />}
    </Box>
    {type === 'requester' && <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '16px', height: '16px', background: profile?.color ?? 'white', borderRadius: '100%' }} />
      <Typography sx={{ ml: '10px', flex: 1, fontSize: 13, fontWeight: 400, color: '#18191C', textAlign: 'start' }}>
        {profileName}
      </Typography>
    </Box>}
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <img src={icons.time} alt="" width={'16px'} height={'16px'} />
      <Typography sx={{ ml: '10px', flex: 1, fontSize: 13, fontWeight: 500, color: '#18191C', textAlign: 'start' }}>
        {moment(`${date}${time}`, 'YYYY-MM-DDTHH:mm').format('D MMMM YYYY, h:mm A')}
      </Typography>
    </Box>
    <Divider sx={{ my: '10px' }} />
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar src={presignedUrl ?? icons.profile_picture_icon} sx={{ width: 56, height: 56 }} />
      <Box sx={{ flex: 1, ml: '10px' }}>
        <Typography sx={{ fontSize: 16, fontWeight: 500, textAlign: 'start' }}>
          {name}
        </Typography>
        <Typography sx={{ fontSize: 13, fontWeight: 400, textAlign: 'start' }}>
          {email}
        </Typography>
      </Box>
    </Box>
    <Typography sx={{ fontSize: 13, fontWeight: 400, textAlign: 'start' }}>
      {note}
    </Typography>
    <Divider sx={{ my: '10px' }} />
    {(mode === 'history' || mode === 'calendar') && <Box sx={{ display: 'flex', gap: 1 }}>
      <Link href={`tel:${phone}`}>
        <Button
          size="small"
          startIcon={<img width={16} src={icons.call} alt='' />}
          style={{ height: '100%' }}
          sx={{ px: '20px', border: '1px solid #C5C5C5', color: 'black', backgroundColor: 'white', borderRadius: '16px' }}
        >
          Call
        </Button>
      </Link>
      <Link href={`mailto:${email}`}>
        <Button
          size="small"
          startIcon={<img width={30} src={icons.email_icon} alt='' />}
          style={{ height: '100%' }}
          sx={{ pl: '10px', pr: '20px', border: '1px solid #C5C5C5', color: 'black', backgroundColor: 'white', borderRadius: '16px' }}
        >
          Email
        </Button>
      </Link>
      {accessRights.includes('appointment edit') && type === 'requester' && mode === 'calendar' && <Button
        size="small"
        endIcon={<img width={16} src={icons.arrow_right} alt='' />}
        sx={{ p: '10px 20px', color: "black", backgroundColor: 'white', borderRadius: '16px', border: '1px solid #C5C5C5' }}
        onClick={() => {
          setSelectedAppointment({
            ...appointment,
            gcal_event_id: gcalEventId,
            profile_picture: presignedUrl,
          })
          setOpenDialog(true);
        }}
      >
        Actions
      </Button>}
    </Box>}
    <ActionsDialog appointment={selectedAppointment} open={openDialog} handleClose={() => setOpenDialog(false)} profile={profile} />
  </Box>
};

export default AppointmentDetails;