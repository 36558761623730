import React, { useState,useEffect } from 'react';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css';
import {
  GetState,
  GetCountries
} from 'react-country-state-city';
import './select.css';

export default function StateOption({
  state = 'Kuala Lumpur',
  countryIso2 = 'MY',
  countryIso3 = 'MYS',
  handleChange
}) {

  const [states, setStates] = useState([]);

  const setState = async () => {
    let country  = [];

    await GetCountries().then((result) => {
      if(countryIso2 !== null){
        country = result.find((country) => country.iso2 === countryIso2);
      }

      if(countryIso3 !== null){
        country = result.find((country) => country.iso3 === countryIso3);
      }
      if(country){
        GetState(country.id).then((result) =>{
          const modifiedResult = result.map((state) => ({
            ...state,
            value: state.name, 
          }));

          setStates(modifiedResult);
        })
      }
    })
  }
  
  const handleState = (value) => {
    handleChange(value);
  }

  useEffect(() => {
    setState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryIso2]);

  useEffect(() => {
    setState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryIso3]);

  return (<>
    <SelectSearch options={states} value={state} search onChange={(value) => handleState(value)} />
    </>
  );
}