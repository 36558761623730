import React from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import icons from "../../../../assets/icons";
import { Formik } from "formik";
// import TextField from '@mui/material/TextField';

export default function CartComponent({
  currency,
  handleBack,
  plan,
  price,
  handlePaymentPlan,
}) {
  // const [refCode, setRefCode] = React.useState('');

  return <>
    <Button color="secondary" startIcon={<img width={12} height={12} src={icons.back_icon} alt="" />} sx={{ mb: '10px' }} onClick={handleBack}>Back</Button>
    <Typography sx={{ fontSize: '31px', fontWeight: 400 }} gutterBottom>Payment</Typography>
    <Formik
      initialValues={{
        refCode: '',
      }}
      onSubmit={() => handlePaymentPlan()}
    >
      {({
        handleSubmit,
        setFieldValue,
      }) => <Box component="form" onSubmit={handleSubmit} sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
          <Box sx={{ flex: 1, bgcolor: 'white', borderRadius: '8px', border: '1px solid #C5C5C5', p: '15px', height: '100%' }}>
            <Typography fontWeight={500} sx={{ mb: '30px' }}>Summary</Typography>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '15px',
            }}>
              <Typography fontWeight={500}>Item</Typography>
              <Typography fontWeight={500}>Price ({currency})</Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '15px',
            }}>
              <Typography>{plan}</Typography>
              <Typography>{price.toFixed(2)}</Typography>
            </Box>

            {/* <Divider sx={{ my: '15px' }} /> */}
            {/* <Typography component='label' htmlFor='name' variant='h5'>
                  Referral code
            </Typography> */}
            {/* <Box>
                <TextField
                  color='secondary'
                  margin="dense"
                  sx={{width: '80%'}}
                  id="referralCode"
                  name="referralCode"
                  size="small"
                  autoFocus
                />
                <Button
                variant='contained'
                size='small'
                sx={{ px: '15px', marginLeft: '10px', marginTop: '10px' }}
                startIcon={<img src={icons.privillage_icon} alt="" />}
                >
                  Apply
                </Button>
            </Box> */}

              {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                <TextField
                  color='primary'
                  margin="dense"
                  fullWidth
                  id="refCode"
                  name="refCode"
                  sx={{ flex: 1, bgcolor: 'white', borderRadius: '4px' }}
                  value={refCode}
                  onChange={e => setRefCode(e.target.value)}
                />
                <Button
                  size="large"
                  variant="contained"
                  startIcon={<img src={icons.privillage_icon} alt="" />}
                  onClick={() => setFieldValue('refCode', refCode)}
                >
                  Apply
                </Button>
              </Box> */}

            <Divider sx={{ my: '15px' }} />
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '15px',
              borderRadius: '5px',
              bgcolor: '#FFF2C5',
              mb: '15px',
            }}>
              <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Total ({currency})</Typography>
              <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                {price.toFixed(2)}
              </Typography>
            </Box>
            <Typography sx={{ color: '#617271', fontSize: '12.47px', my: '20px' }}>
              By purchasing, you authorize PIXEL to charge you the price above for the duration you selected.
              It will resume at the price in effect at the end of your prepaid period, unless you cancel earlier.
              No partial refunds.
            </Typography>
            <Box sx={{display:'flex',justifyContent:'flex-end'}}>
            <Button
              color="secondary"
              size="large"
              variant="contained"
              type="submit"
              sx={{ p: '5px 30px' }}
            >
              Next
            </Button>
            </Box>
          </Box>
          {/* Element use for spacing only */}
          <Box> </Box>
        </Box>}
    </Formik>
  </>
}