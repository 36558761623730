import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  calView: [],
  listView: [],
  shouldRefresh: false,
};

const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    setCalView: (state, action) => {
      state.calView = action.payload;
      state.shouldRefresh = false;
    },
    setListView: (state, action) => {
      state.listView = action.payload;
      state.shouldRefresh = false;
    },
    setShouldRefresh: (state, action) => {
      state.shouldRefresh = action.payload;
    },
  },
});

export const { setCalView, setListView, setShouldRefresh } = appointmentsSlice.actions;
export const appointmentsReducer = appointmentsSlice.reducer;