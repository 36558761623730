import React from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Button, Paper, Typography, TextField } from "@mui/material";
import CustomSwitch from "../customSwitch";
import DraggableVideoItem from "../draggableVideoItem";

export default function VideosComponent({
  totalSections,
  order,
  reorderSection,
  videoUrls,
  setVideoUrls,
  isEnabledVideos,
  setIsEnabledVideos,
  reorder,
  getListStyle,
  getItemStyle,
  remove,
  urlInput,
  setUrlInput
}) {
  return <Paper component={'section'} elevation={0} sx={{ mt: '26px', padding: '30px', borderRadius: '8.78px' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '10px', marginBottom: '20px' }}>
      <select style={{ borderRadius: '5px', padding: '5px', borderColor: '#C5C5C5' }} defaultValue={order + 1} onChange={event => reorderSection(order, event.target.value)}>
        {Array(totalSections).fill(undefined).map((_, i) => <option key={i}>{i + 1}</option>)}
      </select>
      <Typography component='span' variant='h4' sx={{ flex: 1 }}>
        Videos
      </Typography>
      <CustomSwitch checked={isEnabledVideos} onChange={e => setIsEnabledVideos(e.target.checked)} />
    </div>

    <Typography component='h5' variant='h5'>
      Enter YouTube link
    </Typography>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        color='secondary'
        margin="dense"
        type="url"
        required
        value={urlInput}
        onChange={e => setUrlInput(e.target.value)}
        sx={{ flex: 1 }}
        disabled={!isEnabledVideos}
      />
      <Button
        variant="contained"
        disabled={!isEnabledVideos}
        sx={{
          ml: '10px',
          borderRadius: '30px',
          color: 'black',
          backgroundColor: '#FECD20',
        }}
        onClick={async e => {
          let url = `https://youtube.com/oembed?url=${urlInput}&format=json`;
          let meta = await fetch(url).then(res => res.json()).catch(() => ({ title: '<Error getting title>' }));

          setVideoUrls([
            ...videoUrls,
            {
              id: Date.now().toString(),
              url: urlInput,
              meta,
            }
          ]);
          setUrlInput('');
        }}
      >
        Insert Link
      </Button>
    </div>

    <DragDropContext onDragEnd={function (result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      setVideoUrls(reorder(
        videoUrls,
        result.source.index,
        result.destination.index
      ));
    }}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {videoUrls.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!isEnabledVideos}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <DraggableVideoItem url={item.url} meta={item.meta} handleRemove={() => setVideoUrls(remove(videoUrls, item.id))} disabled={!isEnabledVideos} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </Paper>
}