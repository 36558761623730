import React from "react";
import { Paper, TextField, IconButton } from "@mui/material";
import CustomSwitch from "../customSwitch";
import icons from "../../../../../assets/icons";

export default function CustomDescriptionComponent({
  totalSections,
  reorderSection,
  active,
  name,
  content,
  handleDeleteComponent,
  handleEditComponentName,
  handleEditComponentContent,
  handleActiveComponent,
  index
}) {
  return <Paper key={index} elevation={0} sx={{ mt: '26px', padding: '30px', borderRadius: '8.78px' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '20px' }}>
      <select style={{ borderRadius: '5px', padding: '5px', borderColor: '#C5C5C5' }} defaultValue={index + 1} onChange={event => reorderSection(index, event.target.value)}>
        {Array(totalSections).fill(undefined).map((_, i) => <option key={i}>{i + 1}</option>)}
      </select>
      <span style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <TextField
          color='secondary'
          margin="dense"
          required
          id="email"
          name="email"
          disabled={!active}
          value={name}
          onChange={e => handleEditComponentName(index, e.target.value)}
        />
      </span>
      <CustomSwitch checked={active} onChange={e => handleActiveComponent(index)} />
      <IconButton onClick={() => handleDeleteComponent(index)}>
        <img src={icons.delete_icon} alt="filter" />
      </IconButton>
    </div>

    <TextField
      color='secondary'
      margin="dense"
      fullWidth
      multiline
      rows={4}
      id="description"
      name="description"
      disabled={!active}
      value={content}
      onChange={e => handleEditComponentContent(index, e.target.value)}
    />
  </Paper>
}