import React, { Dispatch, SetStateAction } from "react";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import DatePicker from 'react-date-picker';
import { Value } from "react-date-picker/dist/cjs/shared/types";
import icons from "src/assets/icons";
import moment from "moment";

interface DashboardFilterDialogProps {
  open: boolean;
  handleClose: () => void;
  fromDate?: Value;
  setFromDate: Dispatch<SetStateAction<Value | undefined>>;
  toDate?: Value;
  setToDate: Dispatch<SetStateAction<Value | undefined>>;
}

export default function DashboardFilterDialog({ open, handleClose, fromDate, setFromDate, toDate, setToDate }: DashboardFilterDialogProps) {
  const [period, setPeriod] = React.useState<string>();
  const [internalFromDate, setInternalFromDate] = React.useState(fromDate);
  const [internalToDate, setInternalToDate] = React.useState(toDate);

  const handlePeriodChange = (e: SelectChangeEvent<string>) => {
    const prd = e.target.value;

    if (prd === 'today') {
      setInternalFromDate(moment().toDate());
      setInternalToDate(moment().toDate());
    } else if (prd === 'last7') {
      setInternalFromDate(moment().subtract(6, 'd').toDate());
      setInternalToDate(moment().toDate());
    } else if (prd === 'last14') {
      setInternalFromDate(moment().subtract(13, 'd').toDate());
      setInternalToDate(moment().toDate());
    } else if (prd === 'thismonth') {
      setInternalFromDate(moment().startOf('month').toDate());
      setInternalToDate(moment().toDate());
    } else if (prd === 'lastmonth') {
      setInternalFromDate(moment().subtract(1, 'months').startOf('month').toDate());
      setInternalToDate(moment().subtract(1, 'months').endOf('month').toDate());
    }

    setPeriod(prd);
  }

  const differenceInDays = moment(moment(internalToDate?.toString().slice(4, 15), 'MMM DD YYYY')).diff(moment(internalFromDate?.toString().slice(4, 15), 'MMM DD YYYY'), 'days');

  return <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { overflow: 'visible', borderRadius: '25px', minWidth: '400px' } }}>
    <DialogTitle>Filter by</DialogTitle>
    <DialogContent sx={{ overflow: 'visible' }}>
      <FormControl fullWidth sx={{ mt: '20px' }}>
        <InputLabel id="period-label">Select period</InputLabel>
        <Select
          value={period}
          label="Select Period"
          labelId="period-label"
          onChange={handlePeriodChange}
        >
          <MenuItem value={'today'} selected>Today</MenuItem>
          <MenuItem value={'last7'}>Last 7 days</MenuItem>
          <MenuItem value={'last14'}>Last 14 days</MenuItem>
          <MenuItem value={'thismonth'}>This month</MenuItem>
          <MenuItem value={'lastmonth'}>Last month</MenuItem>
          <MenuItem value={'custom'}>Custom period</MenuItem>
        </Select>
      </FormControl>

      {period === 'custom' && <>
        <FormControl className="datepicker" fullWidth sx={{ mt: '20px' }}>
          <Typography component='label' className='required' variant='h5'>
            From
          </Typography>
          <DatePicker
            id='date'
            name='date'
            required={true}
            locale='en-MY'
            calendarIcon={<div className='icon' style={{ mask: `url(${icons.calendar})` }} />}
            clearIcon={null}
            value={internalFromDate}
            onChange={setInternalFromDate}
          />
          {/* {Boolean(errors.date) && <p style={{ color: '#d32f2f', margin: '3px 14px 0', fontSize: '12px' }}>{errors.date}</p>} */}
        </FormControl>

        <div className={`datepicker`}>
          <Typography component='label' className='required' variant='h5'>
            To
          </Typography>
          <DatePicker
            id='date'
            name='date'
            required={true}
            locale='en-MY'
            calendarIcon={<div className='icon' style={{ mask: `url(${icons.calendar})` }} />}
            clearIcon={null}
            value={internalToDate}
            onChange={setInternalToDate}
          />
          {differenceInDays < 0 && <p style={{ color: '#d32f2f', margin: '3px 14px 0', fontSize: '12px' }}>
            End date must be the same or later than the start date
          </p>}
        </div>
      </>}

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
          disabled={!Boolean(internalFromDate) || !Boolean(internalToDate) || differenceInDays < 0}
          sx={{
            padding: '10px 35px',
            borderRadius: '30px',
            backgroundColor: '#373736',
            color: '#FECD20',
            '&:hover': {
              bgcolor: '#252525',
              color: '#FECD20',
            },
            '&:disabled': {
              bgcolor: '#C5C5C5',
            },
          }}
          onClick={() => {
            setFromDate(internalFromDate);
            setToDate(internalToDate);
            handleClose();
          }}
        >
          Apply
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClose} color="secondary" sx={{ mt: '5px' }}>Cancel</Button>
      </div>
    </DialogContent>
  </Dialog>
}