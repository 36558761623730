import React from 'react';
import FilesComponent from '../../../../landing-page/files/files.component';

export default function FilesContainer({ file }) {
  const files = file.content.map(f => ({
    sort: f.sort,
    url: '#',
    title: f.file_name,
  }));

  return file.active ? <FilesComponent files={files} /> : <></>
}