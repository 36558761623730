import React from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Toolbar,Box, List,Typography, IconButton, Button} from '@mui/material';
import {ListItems, SecondaryListItems} from './components';
import { useLocation, useNavigate } from 'react-router-dom'
import { PORTAL_SETTINGS, SUBSCRIPTION } from "src/lib/constants/routes";
import { useTheme } from "@mui/material/styles"; 

// import pixel_logo from './assets/pixel_logo.svg'
import icons from "src/assets/icons";
const drawerWidth: number = 240;

const drawerTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#000000',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    text:{
      primary: "#FFF"
    },
    background: {
      paper: "#3C3C3B",
    },
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          fontWeight: 500,
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: "rgb(177, 143, 22)",
          },
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            '& .MuiListItemText':{
              // '&.MuiTypography-root':{
                color: '#000000 !important',
              },
            // },
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            color: "#000000",
          },
          "&:selected": {
            color: "#000000"
          } 
        },
      },
    },
  },

});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        width: drawerWidth,
      },
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        // overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.down('md')]: {
          width: '0%',
        },
        [theme.breakpoints.up('md')]: {
          width: 0,
        }
      }),
    },
  }),
);
const ListTitle = styled('div')({
  padding: '1rem'
})
export default function DrawerComponent(
  {toggleDrawer,open, subscriptionType, endDate, remainingTrial}:{toggleDrawer:any,open:boolean, subscriptionType:string, endDate:string, remainingTrial:number}
){
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const trialDuration = process.env.REACT_APP_LIFESTYLE_TRIAL_DURATION;
  return(
    <ThemeProvider theme={drawerTheme}>
      <Drawer  PaperProps={{ xs : {position: 'absolute'  }}}variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: [1],
              minHeight: 'unset'
            }}
          >
            <Box style={{
              justifyContent: 'flex-end',
              width: '-webkit-fill-available',
              display: 'flex',
              flexDirection: 'row',
              paddingTop: '1rem',
            }}>
            <IconButton onClick={toggleDrawer}>
              <img src={icons.menu_burger.toString()} alt="menu burger"/>
            </IconButton>
            </Box>

            

          </Toolbar>
            <Box sx={{
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
              [theme.breakpoints.up('sm')]: {
                width: 'fit-content',
              },
         
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div style={{padding: '1rem 0 1rem 0',            
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
              }}>
                <img src={icons.pixel_logo_white.toString()} style={{maxWidth:'133px',maxHeight:"33"}} alt="Pixel Logo"/>
            </div>

            <div style={{
              [theme.breakpoints.down('md')]: {
                width: 'max-content',
              },
              [theme.breakpoints.up('md')]: {
                width: 'fit-content',
              },
              display:"flex",
              flexDirection: 'column',
              padding: '1rem 0 1rem 0', 
              margin: '0.2rem', 
              border: '1px solid black', 
              borderRadius: '0.75rem', 
              background: 'repeating-linear-gradient(145deg, rgba(45,45,45,1), rgba(75,75,75,1) 20%, rgba(45,45,45,1) 40%)', 
    
              flexFlow: 'inherit',
              alignItems: 'center'}}>

              {
                (subscriptionType === 'Free Plan') &&
                <>
                <Typography sx={{ maxWidth:'220px', fontSize: '15px', fontWeight: 700 }}> Basic Account </Typography>
                  <Typography sx={{ maxWidth:'220px', padding: '0 1rem 0.5rem 1rem',fontSize: '12px', fontWeight: 300, whiteSpace: 'initial', textAlign: 'center' }}> Upgrade your subscription to enjoy more features </Typography>
                  <Button
                      variant='contained'
                      size='small'
                      sx={{ px: '20px', borderRadius: '20px', color: 'black', background: '#FECD20' }}
                      onClick={()=>{navigate(`${PORTAL_SETTINGS}/${SUBSCRIPTION}`);}}
                    >
                  <img src={icons.lock.toString()} style={{marginLeft: '-6px', paddingRight: '6px', paddingBottom: '3px'}} alt="Lock Logo"/> Upgrade
                </Button>
                </>
              }

              {
                (subscriptionType === 'Lifestyle' || subscriptionType === 'Lifestyle Lifetime') &&
                <>
                  <Typography sx={{ maxWidth:'220px', fontSize: '15px', fontWeight: 700 }}> Premium Account </Typography>
                  <Typography sx={{ maxWidth:'220px', padding: '0 1rem 0.5rem 1rem',fontSize: '12px', fontWeight: 300, whiteSpace: 'initial', textAlign: 'center' }}> You're a member of Lifestyle plan, enjoy the exclusive features! </Typography>
                  <div style={{padding: '0.5rem', margin: '0.2rem', color:"black", border: '1px solid black', borderRadius: '20px', background: 'lightgray'}}>
                    <Typography sx={{}}>
                      {(subscriptionType === 'Lifestyle') ? 'Expired: '+ endDate : 'Lifetime'}
                    </Typography>
                  </div>
                </>
              }

              {
                (subscriptionType === 'Lifestyle Free Trial') &&
                <>
                  <Typography sx={{ maxWidth:'220px', fontSize: '15px', fontWeight: 700 }}> Premium Account </Typography>
                  <Typography sx={{ maxWidth:'220px', padding: '0 1rem 0.5rem 1rem',fontSize: '12px', fontWeight: 300, whiteSpace: 'initial', textAlign: 'center' }}> You've unlocked {trialDuration} Days of free trial, enjoy exclusive features just for you! </Typography>
                  <div style={{padding: '0.5rem', margin: '0.2rem', color:"black", border: '1px solid black', borderRadius: '20px', background: 'lightgray'}}>
                    <Typography sx={{}}>
                      {/* TODO: the "exteneded" is temporary until payment gateway is installed */}
                      Countdown: {remainingTrial <= 0 ? "Extended" : remainingTrial + ' Days'}
                    </Typography>
                  </div>
                </>
              }

            </div>
            </Box>
          <List component="nav">
            
            <ListItems callback={navigate} currentPath={location.pathname} />
            <ListTitle>
              <Typography> Setting </Typography>
            </ListTitle>
            
            <SecondaryListItems callback={navigate} currentPath={location.pathname}/>
          </List>
        </Drawer>
    </ThemeProvider>
  ) ;
};