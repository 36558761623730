import React from 'react'
import {
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import icons from '../../../../assets/icons'
import { EMAIL, PHONE_HOME, PHONE_MOBILE, ADDRESS,WEBSITE,WHATSAPP,FAX } from '../../../../lib/constants/basiccontacttypes'
import { useSelector } from 'react-redux'
export default function ContactBar({ value, index }){
  const profileTheme = useSelector((state: any) => state.landingPage.theme);
  const ContactBarContainer = styled('div')({
    display: 'flex',
    maxWidth: '22.0625rem',
    height: '54px',
    padding: '0.5rem 1rem',
    alignItems: 'center',
    gap: '1rem',
    borderRadius: '0.5rem',
    backgroundColor: profileTheme?.primary_background || '#FECD20',
  })

  let iconSrc = '';
  let  action = '';
  const info = value.info;

  switch(value.category) {
    case EMAIL:
      iconSrc = icons.grey_email_icon;
      action = `mailto:${info}`
      break;  
    case PHONE_MOBILE:
      iconSrc = icons.grey_mobile_icon;
      action = `tel:${info}`
      break;      
    case PHONE_HOME:
      iconSrc = icons.grey_telephone_icon;
      action = `tel:${info}`
      break;      
    case ADDRESS:
      iconSrc = icons.grey_location_icon;
      action = `http://maps.google.com/?q=${info}`
      break;
    case WEBSITE:
      iconSrc = icons.grey_webpage_icon;
      action = info
      break;
    case FAX: 
      iconSrc = icons.grey_fax_icon;
      action = 'copy'
      break;
    case WHATSAPP:
      iconSrc = icons.grey_whatsapp_icon;
      action = `https://wa.me/${info}` 
      break;
    default :
    iconSrc = icons.grey_webpage_icon;
    action = info
      // code block
  } 

  return (
    <>
      { action === 'copy' ?
        <ContactBarContainer  className="component-item" key={index} onClick={() =>  navigator.clipboard.writeText(info) }>
        <img src={iconSrc} alt="contact-icon" style={{width:"38px",height:"38px"}}/>
        <Typography  sx={{color: profileTheme?.button?.primary_font_color, overflow:'hidden'}}>
          {info}
        </Typography>
    </ContactBarContainer> 
      :
        <ContactBarContainer  className="component-item" key={index} onClick={() => window.open(action) }>
        <img src={iconSrc} alt="contact-icon" style={{width:"38px",height:"38px"}}/>
        <Typography sx={{color: profileTheme?.button?.primary_font_color, overflow:'hidden'}} >
          {info}
        </Typography>
    </ContactBarContainer>
      }
    </>
  )
}