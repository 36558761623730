import axios from "axios";

interface Password {
  old_password: FormDataEntryValue  | null,
  new_password: FormDataEntryValue  | null,
  confirm_password: FormDataEntryValue  | null,
}
const changePassword = ({requestBody}:{requestBody: Password | {}}): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

    return new Promise((resolve, reject) => {
        axios.post("user/changePassword",requestBody)
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default changePassword;
