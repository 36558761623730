import axios from "axios";
interface Response {
  code: number;
  data: Data;
  message: string | null;
  success: boolean;
}
interface Data {
  start_date: string;
  end_date: string;
}
const subscribeFreeTrial = (): Promise<Response> => { 
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

    return new Promise((resolve, reject) => {
        axios.get("subscription/subscribeFreeTrial")
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default subscribeFreeTrial;
