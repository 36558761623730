import * as React from 'react';
import { useNavigate,useLocation,createSearchParams } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import authApi from '../../../../../api/auth';
import images from '../../../../../assets/img';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import { EXCHANGE_CONTACT_PROFILE_LIST } from '../../../../../lib/constants/routes';
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

function useQuery() {
  const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function PersonalInfoComponent() {

  const navigate = useNavigate();
  const [phoneNumber,setPhoneNumber] = React.useState('')
  const ownerCardNo = useQuery().get("owner_card_no") || ''
  const ownerProfileNo = useQuery().get("owner_profile_no") || ''

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const body = {
      name: data.get('name'),
      birthday: data.get('birthday'),
      phone_number: phoneNumber
    };

    await authApi.updateSocialSigninInfo({requestBody: body})
    .then(response => {
      // Update social_info_verified
      const user = JSON.parse(localStorage.getItem('user') ?? '{}');
      user.access_token_payload.social_info_verified = 1;
      localStorage.setItem("user", JSON.stringify(user));

      navigate({
        pathname: EXCHANGE_CONTACT_PROFILE_LIST,
        search: createSearchParams({
          owner_card_no: ownerCardNo,
          owner_profile_no: ownerProfileNo,
        }).toString()
      });
      return response
    })
    .catch(error => error)

    
  }
  const handlePhoneChange = (e) =>{
    setPhoneNumber(e)
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" 
      style={{ 
        maxWidth: '444px',
        height: '100vh',
        display: 'grid',
        alignContent: 'space-around',
        alignItems: 'center',
        justifyItems: 'center'
        }}>
        <CssBaseline />
        <Paper elevation={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem',
            borderRadius: '8.78px',
            maxWidth: '337px'
          }}
        >
          <div style={{ margin: '20px' }}>
            <img src={images.pixel_logo} alt='PixelCard logo'/>
          </div>
          <Typography component="h1" variant="h4">
            Let's get started!
          </Typography>
          <Typography>         
            Personal Info
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography component='label' className='required' variant='h5'>
              Full name
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              id="name"
              name="name"
              autoComplete="name"
              autoFocus
            />
            <Typography component='label' className='required' variant='h5'>
              Date of birth
            </Typography>
            <TextField
              type='date'
              color='secondary'
              margin="dense"
              required
              fullWidth
              id="birthday"
              name="birthday"
              autoComplete="birthday"
              autoFocus
            />

              <Typography component='label' htmlFor='phone' className='required' variant='h5'>
                Phone number
              </Typography>

              <PhoneInput
                country="my"
                inputStyle={{
                  width: 'inherit',
                  padding:'20px 20px 14px 60px',
                  fontSize: '1rem'
                }}
                value={phoneNumber}
                onChange={handlePhoneChange}
              />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: '30px', color: '#FECD20' }}
            >
              Continue
            </Button>

          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
