// import React from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import icons from '../../../assets/icons'
import { PORTAL, PORTAL_APPOINTMENT, PORTAL_CAMPAIGNS, PORTAL_CARDS, PORTAL_CONTACTS, PORTAL_DASHBOARD, PORTAL_PROFILE_LIST, PORTAL_WALLET } from '../../../lib/constants/routes';
import { NavigateOptions, To } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToAppt } from '../../../features/nav';

interface CustomListItemButtonProps {
  icon: string; callback: (to: To, options?: NavigateOptions) => void;
  text: string;
  path: string;
  selected: boolean;
}

const CustomListItemButton = ({ icon, callback, text, path, selected = false }: CustomListItemButtonProps) => {
  const dispatch = useDispatch();
  const sx = {
    '&:hover': {
      color: 'white',
    },
    '& .icon': {
      background: selected ? 'black' : 'white',
    },
    '&:hover .icon': {
      background: 'white',
    },
  }

  const cb = () => {
    dispatch(setToAppt(path === PORTAL_APPOINTMENT));
    callback(path);
  }

  return (
    <ListItemButton onClick={cb} selected={selected} sx={sx}>
      <ListItemIcon>
        <div className='icon' style={{ height: '24px', width: '24px', mask: `url(${icon})`, WebkitMask: `url(${icon})` }} />
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  )
}

// const CustomCollapsibleButton = (
//   {icon,list,text,callback,currentPath} :
//   {icon:any,list:any,text: any, callback: (result: string) => void,currentPath: string }) => {
//     const [open, setOpen] = React.useState(false);

//     const handleClick = () => {
//       setOpen(!open)
//     }
//     useEffect(() => {
//       if(currentPath.includes('profile')) setOpen(true)
//     },[currentPath])

//     return (
//       <List component='nav' aria-labelledby='nested-list-subheader'>
//         <div>
//             <ListItemButton  onClick={handleClick} >
//                 <ListItemIcon>
//                   <img src={icon} alt="icon"/>
//                 </ListItemIcon>
//                 <ListItemText primary={text} />
//                 {open ? <ExpandLess /> : <ExpandMore />}

//             </ListItemButton>
//             <Collapse
//                 in={open}
//                 timeout='auto'
//                 unmountOnExit
//             >

//                 <List component='li' disablePadding>
//                 {list.length ? 
//                   list.map((value : any,index: any)=>{
//                     return(
//                       <ListItemButton key='create' onClick={() => callback(`/portal/profile/${value.id}/update`)}>
//                         <ListItemText style={{textAlign: "center"}} key='create' primary={value.name}/>
//                     </ListItemButton>
//                     )
//                   })
//                   :
//                     <ListItemButton key='create' onClick={() => callback(`/portal/profile`)}>
//                       <ListItemText style={{textAlign: "center"}} key='create' primary='Create Profile'/>
//                     </ListItemButton>}

//                     <ListItemButton key='list' onClick={() => callback(`/portal/profile-list`)} selected={currentPath === '/portal/profile-list'}>
//                       <ListItemText  style={{textAlign: "center"}} key='list' primary='Profile List'/>
//                     </ListItemButton>
//                   </List>
//               </Collapse>
//           </div>
//     </List>
//     );
//   } 

// const getProfilesDropdown = async ({setProfileList}:{setProfileList: any}) => {
//   const user = JSON.parse(localStorage.getItem('user') || "")
//   if(!user) return;
//   const profileResposne = await profileApi.getProfilesDropdown()
//   .then(response => response)
//   .catch(error => error);
//   if(profileResposne.success){
//     const list = profileResposne.data.map((value : any) =>{
//       return {name: value.profile_name, id: value.profile_uuid}
//     })
//     return setProfileList(list)
//   } 

//   return false
// }

export default function ListItems({ callback , currentPath = '' }: 
  {callback: (to: To, options?: NavigateOptions) => void, currentPath: string}){
    // React.useEffect(() => {
    //   getProfilesDropdown({setProfileList});
    // }, []);

    return(
    <>
    <CustomListItemButton icon={icons.dashbaord_icon.toString()} callback={callback} path={PORTAL} text={'Dashboard'} selected={currentPath === PORTAL || currentPath === PORTAL_DASHBOARD} />
    <CustomListItemButton icon={icons.myprofile_icon.toString()} callback={callback} path={PORTAL_PROFILE_LIST} text={'My Profile'} selected={currentPath.includes(PORTAL_PROFILE_LIST)} />
    {/* <CustomCollapsibleButton list={profileList} icon={icons.contacts_icon.toString()}  callback={callback}  text={'My Profile'}  currentPath={currentPath}/> */}
    <CustomListItemButton icon={icons.contacts_icon.toString()} callback={callback} path={PORTAL_CONTACTS} text={'Contacts'} selected={currentPath === PORTAL_CONTACTS} />
    <CustomListItemButton icon={icons.privillage_icon.toString()} callback={callback} path={PORTAL_CAMPAIGNS} text={'Campaign'} selected={currentPath === '/privillege'} />
    <CustomListItemButton icon={icons.wallet.toString()} callback={callback} path={PORTAL_WALLET} text={'Wallet'} selected={currentPath === PORTAL_WALLET} />
    <CustomListItemButton icon={icons.calendar_appt.toString()} callback={callback} path={PORTAL_APPOINTMENT} text={'Appointment'} selected={currentPath === PORTAL_APPOINTMENT} />
    {/* <CustomListItemButton icon={icons.myprofile_icon.toString()} callback={callback} path={'profile'} text={'My Profile'} selected={currentPath === '/portal/profile'} />
    <CustomListItemButton icon={icons.contacts_icon.toString()} callback={callback} path={'contacts'} text={'Contacts'} selected={currentPath === '/portal/contacts'} /> */}
    {/* <CustomListItemButton icon={icons.privillage_icon.toString()} callback={callback} path={'/'} text={'Privillege'} selected={currentPath === '/privillege'} />
    <CustomListItemButton icon={icons.insights_icon.toString()} callback={callback} path={'/'} text={'Insights'} selected={currentPath === '/insight'} />
    <CustomListItemButton icon={icons.environmetal_impact_icon.toString()} callback={callback} path={'/'} text={'Enviroment Impact'} selected={currentPath === '/enviroment'} />
    <CustomListItemButton icon={icons.email_signature_icon.toString()} callback={callback} path={'/'} text={'Email Signature'} selected={currentPath === '/email'} />
    <CustomListItemButton icon={icons.order_list_icon.toString()} callback={callback} path={'/'} text={'Order List'} selected={currentPath === '/order'} /> */}
    <CustomListItemButton icon={icons.credit_card.toString()} callback={callback} path={PORTAL_CARDS} text={'My NFC Cards'} selected={currentPath === PORTAL_CARDS} />
    </>
  );
  }