import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
  shouldRefresh: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
      state.shouldRefresh = false;
    },
    setShouldRefresh: (state, action) => {
      state.shouldRefresh = action.payload;
    },
  },
});

export const { setNotifications, setShouldRefresh } = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;