import axios from "axios";
interface UserDetails {
  name: FormDataEntryValue  | null,
  birthday: FormDataEntryValue  | null,
  phone_number: FormDataEntryValue  | null,
  profile_image: FormDataEntryValue  | null,
  // address_1: FormDataEntryValue  | null,
  // address_2: FormDataEntryValue  | null,
}
const update = ({requestBody}:{requestBody: UserDetails}): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;
    return new Promise((resolve, reject) => {
        axios.post("user/update",requestBody)
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default update;
