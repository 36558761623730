import React, { useState } from "react";
import { styled } from '@mui/system';
import { Autocomplete, Button, createFilterOptions } from "@mui/material";
import { Box,Typography,TextField } from "@mui/material";
import UserInfo from "./userInfo";
import { ResponseDialog } from "../../../../components/dialog";
import PhoneInput from '../../../../components/phoneInput';
import timezones from "../../../../lib/constants/timezones";
import icons from "../../../../assets/icons";
import { Formik,Form } from "formik";
import BillingAddress from "./billingAddress";
import ShippingAddress from "./shippingAddress";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Timezone {
  id: string;
  text: string;
  value: string;
}

const filterOptions = () => createFilterOptions({
  stringify: (option: Timezone) => option.id + option.text + option.value,
});

interface PersonalInfoComponentProps {
  userInfo: UserInfo; 
  defaultTimezone: Timezone;
  handleSubmit: any;
  handleInputChange;
  handlePhoneChange;
  handleBirthdayChange;
  handleTimezoneChange;
  handleFileChange;
  handleResponseClose;
  responseDialogOpen;
  phoneNumber;
  file;
  form;
  formErrors;
  initialInfo;
}

export default function PersonalInfoComponent(
  { userInfo,
    defaultTimezone,
    handleSubmit,
    handleInputChange,
    handleBirthdayChange,
    handleTimezoneChange,
    handlePhoneChange,
    handleFileChange,
    handleResponseClose,
    responseDialogOpen,
    phoneNumber,
    file,
    form,
    formErrors,
    initialInfo }: PersonalInfoComponentProps
  ){
    const [phoneError, setPhoneError] = useState<string>('');

  return <>
  <ResponseDialog onClose={handleResponseClose}
    selectedValue={''}
    title="Change Info Success" 
    description="Your info has been changed."
    open={responseDialogOpen}/>

    <Box className="shipping-address-form" sx={{
          display:"flex",
          flexDirection:"column",
          gap:'1rem',
          maxWidth:'651px'}}>
          <Formik
            enableReinitialize
            initialValues={initialInfo}
            onSubmit={async (values) => {
              setPhoneError('');
              if (phoneNumber === '') {
                setPhoneError("Required")
                return false;
              }
              
              if (phoneNumber.length <= 7 || phoneNumber.length >= 15) {
                setPhoneError('Phone number invalid')
                return false;
              }

              handleSubmit({});
            }}
          >      
          {({
            values,
            errors,
            handleSubmit,
            handleChange
          }) => (
            <Form onSubmit={handleSubmit}>

          <Typography component="h1" variant="h4" style={{paddingTop: '30px', paddingBottom: '10px'}}>
            Edit Personal Info
          </Typography>

          <Box>
        <Box>
          <div style={{ display: 'flex' }}>
            {!file ?
            (<Box 
              style={{
                borderRadius: "79.5px",
                width: "79.5px",
                height: "79.5px",
                flexShrink: 0,
                background: "lightgray 50% / cover no-repeat",
                backgroundImage: `url(${userInfo?.profileImage || icons.profile_picture_icon})`
              }}
            />) :
            (
              <Box 
              style={{
                borderRadius: "79.5px",
                width: "79.5px",
                height: "79.5px",
                flexShrink: 0,
                background: "lightgray 50% / cover no-repeat",
                backgroundImage: `url(${file !== undefined ? URL.createObjectURL(file) : icons.profile_picture_icon})`
              }}
            />
           )
            }
            <Button
              component="label"
              variant="outlined"
              sx={{
                margin: 3,
                height: '40px',
                border: '1px solid #FECD20',
                borderRadius: '8px',
                color: 'black'
              }}>
              Upload image
              <VisuallyHiddenInput type="file" onChange={handleFileChange} accept=".png, .jpeg, .jpg" />
            </Button>
          </div>
        </Box>

        <Typography component='label' sx={{color: 'text.secondary',fontWeight: '400'}}>
          File support png and jpeg only. The maximum file we can handle 12MB.
        </Typography>
      </Box>
      <Box>
      <Typography component='label' className='required' variant='h5'>
        Full Name
      </Typography>
      <TextField
        color='secondary'
        margin="dense"
        required
        size="small"
        fullWidth
        id="name"
        name="name"
        autoFocus
        value={userInfo.name}
        onChange={handleInputChange}
      />
      <Typography component='label' className='validation-error' variant='h5'>
      {formErrors.name}
      </Typography>
      </Box>
      <Box>
      <Typography component='label' className='required' variant='h5'>
        Phone Number
      </Typography>
      <Box sx={{ display: 'flex' }}>
          <PhoneInput
          code={userInfo.phoneCode}
          value={userInfo.phoneNumber}
          disabled={false}
          setVal={handlePhoneChange}
          />
      </Box>
      <Typography component='label' className='validation-error' variant='h5'>
        {formErrors.phone_number}
      </Typography>
      <Typography key={phoneError} component='label' className='validation-error' variant='h5'>
        {phoneError}
      </Typography>
      </Box>
      <Box>
        <Typography component='label' className='required' variant='h5'>
          Timezone
        </Typography>
        <Autocomplete
          value={timezones.filter(timezone => timezone.id === userInfo.timezone)[0]}
          options={timezones}
          autoHighlight
          disableClearable
          clearOnBlur
          popupIcon={<img src={icons.arrow_down_01_icon as unknown as string} alt='Open dropdown' />}
          getOptionLabel={option => option.text}
          filterOptions={filterOptions()}
          defaultValue={defaultTimezone}
          onChange={(event: any, newValue: Timezone | null) => handleTimezoneChange(newValue?.id)}
          renderOption={(props, option: any) => (
            <Box component="li" {...props} key={option.code}>
              {option.text}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              color='secondary'
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              sx={{ background: 'white' }}
            />
          )}
        />
      </Box>
      <Box>
      <Typography component='label' className='required' variant='h5'>
        Date of birth
      </Typography>
      <TextField
        type='date'
        color='secondary'
        margin="dense"
        required
        fullWidth
        id="birthday"
        name="birthday"
        size="small"
        placeholder="DD/MM/YYYY"
        value={userInfo.birthday}
        onChange={handleBirthdayChange}
      />

      <Typography component='label' className='validation-error' variant='h5'>
        {formErrors.birthday}
      </Typography>
      </Box>
            <Box sx={{pt:'1rem'}}>
            <Button variant="contained" color="secondary" type="submit">
            Save Changes
            </Button>
          </Box>
          </Form>
          )}
          </Formik>
        </Box>

    <ShippingAddress/>
    <BillingAddress/>
  </>
} 