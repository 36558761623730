import React from "react";
import GalleryComponent from "./gallery.component";

export default function GalleryContainer({
  totalSections,
  order,
  reorder,
  reorderSection,
  galleryItems,
  setGalleryItems,
  isEnabledGallery,
  setIsEnabledGallery,
  handleGalleryChange,
  getListStyle,
  getItemStyle,
  remove,
}) {
  return <GalleryComponent
    totalSections={totalSections}
    order={order}
    reorderSection={reorderSection}
    galleryItems={galleryItems}
    setGalleryItems={setGalleryItems}
    isEnabledGallery={isEnabledGallery}
    setIsEnabledGallery={setIsEnabledGallery}
    handleGalleryChange={handleGalleryChange}
    reorder={reorder}
    getListStyle={getListStyle}
    getItemStyle={getItemStyle}
    remove={remove}
  />;
}