import axios from "axios";

interface Data{
  code: number,
  data:  {
    new_contacts: boolean
  },
  message: string | null,
  success: boolean
}
interface requestBody{
  voucher_code : string,
  email: string,
  password: string,
  longitude: number,
  latitude: number,
  ip_address: string,  
}
const selfValidateRedeem = (requestBody:requestBody): Promise<Data> => {

  const user = JSON.parse(localStorage.getItem('user') || '{}')
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;
  
  return new Promise((resolve, reject) => {
        axios.post(`myvoucher/selfValidateRedeem`,requestBody)
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default selfValidateRedeem;
