import React from 'react'
import ConfirmDialog from './confirmDialog.component'

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string | null;
  onClose: (value: string | null) => void;
  handleConfirm: () => void;
}

export default function ConfirmDialogContainer(props: SimpleDialogProps){
  const { onClose, selectedValue, handleConfirm,open } = props;
  
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return <ConfirmDialog
    handleClose={handleClose}
    handleListItemClick={handleListItemClick}
    handleConfirm={handleConfirm}
    open={open}
  />
}