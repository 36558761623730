import updateSocialSigninInfo from "./updateSocialSigninInfo.api"
import getAccessLimit from "./getAccessLimit.api"
import verifyToken from "./verifyToken.api"

const authApi = {
  updateSocialSigninInfo,
  getAccessLimit,
  verifyToken,
}

export default authApi
