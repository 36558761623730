import React from "react";
import { Button, Paper, Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import CustomSwitch from "../customSwitch";
import DraggableGalleryItem from "../draggableGalleryItem";
import VisuallyHiddenInput from "../visuallyHiddenInput";

export default function GalleryComponent({
  totalSections,
  order,
  reorderSection,
  galleryItems,
  setGalleryItems,
  isEnabledGallery,
  setIsEnabledGallery,
  handleGalleryChange,
  reorder,
  getListStyle,
  getItemStyle,
  remove
}) {
  return (
    <Paper elevation={0} sx={{ mt: '26px', padding: '30px', borderRadius: '8.78px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '10px', marginBottom: '20px' }}>
        <select style={{ borderRadius: '5px', padding: '5px', borderColor: '#C5C5C5' }} defaultValue={order + 1} onChange={event => reorderSection(order, event.target.value)}>
          {Array(totalSections).fill(undefined).map((_, i) => <option key={i}>{i + 1}</option>)}
        </select>
        <Typography component='span' variant='h4' sx={{ flex: 1 }}>
          Gallery
        </Typography>
        <CustomSwitch checked={isEnabledGallery} onChange={e => setIsEnabledGallery(e.target.checked)} />
      </div>

      <Typography component='h5' variant='h5'>
        Upload images
      </Typography>
      <Button
        component="label"
        variant="outlined"
        disabled={!isEnabledGallery}
        sx={{
          mt: '10px',
          mb: '10px',
          height: '40px',
          border: '1px solid #FECD20',
          borderRadius: '8px',
          color: 'black'
        }}>
        Upload image
        <VisuallyHiddenInput type="file" onChange={handleGalleryChange} disabled={!isEnabledGallery} accept="image/*" />
      </Button>
      <Typography component='h5' variant='h5' sx={{ color: '#AAAAAA' }}>
        Upload multiple image files. Do not exceed 25MB.
      </Typography>

      <DragDropContext onDragEnd={function (result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        setGalleryItems(reorder(
          galleryItems,
          result.source.index,
          result.destination.index
        ));
      }}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {galleryItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!isEnabledGallery}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <DraggableGalleryItem file={item.file} image_name={item.image_name} handleRemove={() => setGalleryItems(remove(galleryItems, item.id))} disabled={!isEnabledGallery} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  )
}