import exchange_contact_banner from './exchange_contact_banner.svg'
import empty_avatar from './empty_avatar.png'
import pixel_logo from './pixel_logo.svg'
import error_page from './error_page.svg'
import pxlicon from './pxlicon.png'
import order_otw from './order_otw.svg'
import landing_page_default_image from './landing_page_default_image.png'
import expired_calendar from './expired_calendar.svg'
import appointment_calendar from './appointment_calendar.svg'
import appointment_confirmed from './appointment_confirmed.svg'
import cardlist_banner from './cardlist_banner.png'
import apply_template from './apply_template.png'
import apply_custom from './apply_custom.png'
import buycard_banner from './buycard_banner.svg'
import invoice_logo from './invoice_logo.png'
import refer_friends from './refer_friends.svg'
import cloud from './cloud.png'
import campaign_ads from './campaign_ads.png'
import line_logo from './LINE_logo.png'
import apple_logo from './apple_logo.svg'
const images = {
  apple_logo,
  line_logo,
  invoice_logo,
  expired_calendar,
  appointment_calendar,
  appointment_confirmed,
  landing_page_default_image,
  pxlicon,
  error_page,
  pixel_logo,
  exchange_contact_banner,
  empty_avatar,
  cardlist_banner,
  apply_template,
  apply_custom,
  order_otw,
  buycard_banner,
  refer_friends,
  cloud,
  campaign_ads
}

export default images