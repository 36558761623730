import React, { useState, useEffect } from "react";
import { Box, Typography,Button } from "@mui/material";
import icons from "../../../../../assets/icons";
import VoucherType from "../component/voucherType";
import VoucherDetails from "../component/voucherDetails";
import TermCondition from "../component/termCondition";
import Preview from "../component/preview";
import { Formik} from 'formik';
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { VoucherForm } from "../../../../../lib/types/Voucher";
import {  useTheme } from '@mui/material/styles';
import moment from "moment";
import { PORTAL, VOUCHER_CODE, VOUCHER_CODE_CREATE } from "../../../../../lib/constants/routes";

function VoucherTabs({currentTab, values, errors,handleChange,setVoucherIndex,isEdit}:{currentTab: number, values: any, errors: any,handleChange: any,setVoucherIndex?: any,isEdit:boolean}){
  const renderTab = (currentTab : number) => {
    switch (currentTab) {
      case 1:
        return <VoucherType values={values.voucherType} errors={errors.voucherType} handleChange={handleChange} isEdit={isEdit}/>
      case 2:
        return <VoucherDetails values={values.voucherDetails} errors={errors.voucherDetails} handleChange={handleChange} setVoucherIndex={setVoucherIndex}/>
      case 3:
        return <TermCondition value={values.termsAndCondition} errors={errors.termsAndCondition} handleChange={handleChange}/>
      case 4:
        return <Preview values={values}/>
      default:
        return <VoucherType values={values.voucherType} errors={errors.voucherType} handleChange={handleChange} isEdit={isEdit}/>
    }
  }

  return (
    <>{renderTab(currentTab)}</>
  )
}

export default function VoucherCreateComponent({initialVoucher, onSubmit,isEdit=false}:
  {initialVoucher: VoucherForm, onSubmit: (values: VoucherForm) =>any,isEdit?:boolean}
  ) {
    const [voucherIndex,setVoucherIndex] = useState(0);
  const [currentTab,setCurrentTab] = useState(1); // 1: Voucher Type, 2: Voucher Details, 3: Create Code, 4: Term&Condition, 5: Preview
  const [currentTabTitle,setCurrentTabTitle] = useState("Voucher Type");
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const nextPage = () => {
    if(currentTab === 4) return;
    setCurrentTab(currentTab+1);
  }

  useEffect(() => {
    switch (currentTab) {
      case 1:
        setCurrentTabTitle("Voucher Type")
        break;
      case 2:
        setCurrentTabTitle("Voucher Details")
        break;
      case 3:
        setCurrentTabTitle("Term&Condition")
        break;
      case 4:
        setCurrentTabTitle("Preview")
        break;
      default:
        setCurrentTabTitle("Voucher Type")
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 
  [currentTab]);

  return (
    <Box sx={{
      display: 'flex',
      gap: '2rem',
      containerType: 'inline-size',
    }}>
      <Box sx={{
        border: '1px solid var(--Status-Default, #C5C5C5)',
        padding: '1rem 1rem 1rem 2rem',
        borderRadius: '8px',
        "@container (max-width: 900px)": {
          display: 'none',
        },
        height : 'fit-content',
      }}>
        <Typography sx={{fontWeight:500, fontSize:'20px'}}> Step by step</Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          
          '& .step':{
            padding:"0.5rem",
            display:"flex",
            cursor: 'pointer',
            '& .step-icon-box':{
              textAlign: 'center',
              display: 'flex',
              pl:'10px',
              pr:'10px',
            },
          },

          '& .step-title':{
            fontSize: '20px',
          }
        }}>
          {/* Voucher Type */}
            <Box className="step" sx={{
              background: currentTab > 1 ? "#2A83FF" : (currentTab === 1 ? "#E2EEFF" : ""),
              color: currentTab > 1 ? "white" : (currentTab === 1 ? "black" : "black"),
              borderRadius: '4px',
            }}
            onClick={() => {if(isEdit) setCurrentTab(1)}}>
              <Box className="step-icon-box">
                { currentTab > 1  && <img width="14" className="step-icon" src={icons.checkmark_circle_white}  alt="checkmark"/> }
                { currentTab <= 1  && <img width="14" className="step-icon" src={icons.minus_blue}  alt="checkmark"/> }
              </Box>
              <Typography className="step-title"> Voucher Type </Typography>
            </Box>

          {/* Voucher Details */}
            <Box className="step" sx={{
              background: currentTab > 2 ? "#2A83FF" : (currentTab === 2 ? "#E2EEFF" : ""),
              color: currentTab > 2 ? "white" : (currentTab === 2 ? "black" : "black"),
              borderRadius: '4px',
            }}
            onClick={() => {if(isEdit) setCurrentTab(2)}}>
              <Box className="step-icon-box">
                { currentTab > 2  && <img width="14" className="step-icon" src={icons.checkmark_circle_white}  alt="checkmark"/> }
                { currentTab <= 2  && <img width="14" className="step-icon" src={icons.minus_blue}  alt="checkmark"/> }
              </Box>
              <Typography className="step-title"> Voucher Details </Typography>
            </Box>

            {/* Terms and Condition */}
            <Box className="step" sx={{
              background: currentTab > 3 ? "#2A83FF" : (currentTab === 3 ? "#E2EEFF" : ""),
              color: currentTab > 3 ? "white" : (currentTab === 3 ? "black" : "black"),
              borderRadius: '4px',
            }} 
            onClick={() => {if(isEdit) setCurrentTab(3)}}>
              <Box className="step-icon-box">
                { currentTab > 3  && <img width="14" className="step-icon" src={icons.checkmark_circle_white}  alt="checkmark"/> }
                { currentTab <= 3  && <img width="14" className="step-icon" src={icons.minus_blue}  alt="checkmark"/> }
              </Box>
              <Typography className="step-title"> Term&Condition </Typography> 
            </Box>

            {/* Preview */}
            <Box className="step" sx={{
              background: currentTab > 4 ? "#2A83FF" : (currentTab === 4 ? "#E2EEFF" : ""),
              color: currentTab > 4 ? "white" : (currentTab === 4 ? "black" : "black"),
              borderRadius: '4px',
            }}
            onClick={() => {if(isEdit) setCurrentTab(4)}}>
              <Box className="step-icon-box">
                { currentTab > 4  && <img width="14" className="step-icon" src={icons.checkmark_circle_white}  alt="checkmark"/> }
                { currentTab <= 4  && <img width="14" className="step-icon" src={icons.minus_blue}  alt="checkmark"/> }
              </Box>
              <Typography className="step-title"> Preview</Typography>
            </Box>
        </Box>
      </Box>
      <Box sx={{ flex: 1 }}>
      <Formik
          enableReinitialize
          initialValues={initialVoucher}
          validate={
            values => {
              let errors: any = {
                voucherType:{},
                voucherDetails:{},

              };
              // voucher Type
              if(!values.voucherType.discountType){
                errors.voucherType.discountType = "Required"
              }
              if(!values.voucherType.validationType){
                errors.voucherType.validationType = "Required"
              }
              
              if(!values.voucherType.limitPerUser || values.voucherType.limitPerUser < 0){
                errors.voucherType.limitPerUser = "Required"
              }
              
              if(!values.voucherType.startDateTime){
                errors.voucherType.startDateTime = "Required"
              }
              if(!values.voucherType.endDateTime){
                errors.voucherType.endDateTime = "Required"
              }

              if( moment(values.voucherType.endDateTime).isBefore(moment())) {
                errors.voucherType.endDateTime = "Date should be greater than today"
              }
              if(moment(values.voucherType.startDateTime).isAfter(moment(values.voucherType.endDateTime))){
                errors.voucherType.endDateTime = "End date should be greater than start date"
              }

              if(moment(values.voucherType.startDateTime).isSame(moment(values.voucherType.endDateTime))){
                errors.voucherType.endDateTime = "End date should be greater than start date"
              }

              if(moment(values.voucherType.startDateTime).isBefore(moment(values.voucherType.collectionStartDate)) || 
              moment(values.voucherType.endDateTime).isBefore(moment(values.voucherType.collectionStartDate))) {
                errors.voucherType.startDateTime = "Date should be greater than collection today"
                errors.voucherType.endDateTime = "Date should be greater than collection today"
              }

              if(!values.voucherType.collectionStartDate){
                errors.voucherType.collectionStartDate = "Required"
              }
              if(!values.voucherType.collectionEndDate){
                errors.voucherType.collectionEndDate = "Required"
              }

              if(moment(values.voucherType.collectionEndDate).isBefore(moment())) {
                errors.voucherType.collectionEndDate = "Date should be greater than today"
              }

              if(moment(values.voucherType.collectionStartDate).isAfter(moment(values.voucherType.collectionEndDate))){
                errors.voucherType.collectionStartDate = "End date should be greater than start date"
              }

              if(moment(values.voucherType.collectionStartDate).isSame(moment(values.voucherType.collectionEndDate))){
                errors.voucherType.collectionEndDate = "End date should be greater than start date"
              }

              if(moment(values.voucherType.collectionStartDate).isAfter(moment(values.voucherType.endDateTime))){
                errors.voucherType.collectionStartDate = "Start Date should be within before redemption date"
              }

              // voucher Type end
              // voucher Details
              if(!values.voucherDetails.voucherTitle){
                errors.voucherDetails.title = "Required"
              }

              if(!values.voucherDetails.brandName){
                errors.voucherDetails.brandName = "Required"
              }

              if(!values.voucherDetails.description){
                errors.voucherDetails.description = "Required"
              }

              if(!values.voucherDetails.bannerImage){
                errors.voucherDetails.bannerImage = "Required"
              }

              // if(!values.voucherDetails.companyName){
              //   errors.voucherDetails.companyName = "Required"
              // }

              // if(!values.voucherDetails.companyProfileImage){
              //   errors.voucherDetails.companyProfileImage = "Required"
              // }
              // voucher Details end

              if(!values.termsAndCondition){
                errors.termsAndCondition = "Required"
              }

              errors = Object.fromEntries(
                Object.entries(errors).filter(([key, value]) => Object.keys(value).length !== 0)
              );
                return errors;
            }
          }
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values).then(response => {
              setSubmitting(false);

              // When creating voucher, the response will contain the UUID
              // for the newly created voucher
              if (Boolean(response.data)) {
                navigate(`${PORTAL}/campaign/${id}/${VOUCHER_CODE}/${response.data}/${VOUCHER_CODE_CREATE}`);
              } else {
                navigate(`${PORTAL}/campaign/${id}`);
              }
            })
              .catch(console.log)
              .finally(() => setSubmitting(false))
          }}
          validator={() => ({})}
          >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            /* and other goodies */
          }) => (
        <form onSubmit={handleSubmit}>
      <Box sx={{
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap:'1rem',
          paddingBottom:"0.8rem",
          borderBottom: '1px solid var(--Status-Default, #C5C5C5)'
        },
        [theme.breakpoints.up('md')]: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom:"0.8rem",
          borderBottom: '1px solid var(--Status-Default, #C5C5C5)'
        }

      }}>
        <Typography variant="h3" sx={{fontWeight:'500'}}> {currentTabTitle}</Typography>
        <Box>
        { currentTab > 1 &&
        <Button
          type="button"
          variant="outlined"
          color="secondary" 
          sx={{mr:'1rem'}}
          startIcon={<img src={icons.back_icon} alt="previous page"/>}
          onClick={() => setCurrentTab(currentTab-1)}
          >
          Previous page
        </Button>
        }

        { currentTab === 1 &&
              <Button
                type="button"
                variant="contained"
                color="secondary"
                disabled={
                  values?.voucherType?.discountType === '' ? true : false ||
                  values?.voucherType?.validationType < 0 ? true : false ||
                  values?.voucherType?.limitPerUser < 0? true : false ||
                  values?.voucherType?.startDateTime === '' ? true : false || 
                  values?.voucherType?.endDateTime  === ''? true : false
                }
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  textTransform: 'none',
                  borderRadius: '30px',
                  '& .icon': {
                    background: '#FECD20',
                  },  
                }}
                onClick={()=>nextPage()}
              >
                Next 
              </Button>
        }
        { currentTab === 2 &&
              <Button
                key={voucherIndex}
                type="button"
                variant="contained"
                color="secondary"
                disabled={
                  values?.voucherDetails?.voucherTitle === '' ? true : false ||
                  values?.voucherDetails?.brandName === ''? true : false ||
                  (values?.voucherDetails?.bannerImage.name === null || values?.voucherDetails?.bannerImage.name === undefined ) ? true : false ||
                  values?.voucherDetails?.description === '' ? true : false 
                }
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  textTransform: 'none',
                  borderRadius: '30px',
                  '& .icon': {
                    background: '#FECD20',
                  },  
                }}
                onClick={()=>nextPage()}
              >
                Next 
              </Button>
        }
        { currentTab === 3 &&
              <Button
                type="button"
                variant="contained"
                color="secondary"
                disabled={
                  values?.termsAndCondition === '' ? true : false
                }
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  textTransform: 'none',
                  borderRadius: '30px',
                  '& .icon': {
                    background: '#FECD20',
                  },  
                }}
                onClick={()=>nextPage()}
              >
                Next 
              </Button>
        }
        {
          currentTab === 4 &&
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            sx={{
              fontSize: '16px',
              fontWeight: '500',
              textTransform: 'none',
              borderRadius: '30px',
              '& .icon': {
                background: '#FECD20',
              },  
            }}
          >
            Done 
          </Button>
        }
        </Box>
      </Box>
      <Box sx={{
        pt:"2.5rem",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}>
      <VoucherTabs values={values} errors={errors} currentTab={currentTab} handleChange={handleChange}  setVoucherIndex={() => setVoucherIndex(voucherIndex+1)} isEdit={isEdit}/>
      </Box>
      </form>)}
      </Formik>
      </Box>

    </Box>
  );
}