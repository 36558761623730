import axios from "axios";

interface Card {
  card_serial_number: string;
}

interface Data {
  data: Card;
}

const addCard = (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get(`card/add?user_uuid=${user.access_token_payload.uuid}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default addCard;