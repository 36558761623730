import getDetail from "./getDetail.api";
import update from "./update.api"
import changePassword from "./changePassword.api" 
import checkSignType from "./checkSignType.api"
import updateShipping from "./updateShipping.api"
import updateBilling from "./updateBilling.api"
import getShipping from "./getShipping.api"
import getBilling from './getBilling.api'
import getReferralCode from './getReferralCode.api'
import checkReferralCode from './checkReferralCode.api'
import getReferralStatistics from './getReferralStatistics.api'

const userApi = {
  getDetail,
  update,
  changePassword,
  checkSignType,
  updateShipping,
  updateBilling,
  getShipping,
  getBilling,
  getReferralCode,
  checkReferralCode,
  getReferralStatistics
}

export default userApi 
