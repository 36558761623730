import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../../../../features/admin';
import { BUY, PORTAL_CARDS, TEMPLATES, ZAKEKE_CUSTOMIZER } from "../../../../../../lib/constants/routes";
import EditTemplateComponent from "./editTemplate.component";
import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import { setItems, toggleCartDrawer } from "../../../../../../features/cart";
import productApi from "../../../../../../api/product";
import { openErrorDialog } from "../../../../../../features/error-dialog";
import FileHelper from "../../../../../../lib/helper/fileHelper";
import { v4 as uuidv4 } from 'uuid';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function EditTemplateContainer() {
  const query = useQuery();
  const type: string = query.get('type') ?? 'template';
  const { productUUID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [template, setTemplate] = useState<any>();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleBack = () => {
    if (type === 'template') {
      navigate(`${PORTAL_CARDS}/${BUY}/${TEMPLATES}`);
    } else {
      navigate({
        pathname: `${PORTAL_CARDS}/${BUY}/${TEMPLATES}`,
        search: createSearchParams({ type: 'custom' }).toString(),
      })
    }
  }

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    let logo = '';

    if (values.logo !== undefined) {
      const fullPath = `user/${user.access_token_payload.uuid}/card/logo/${uuidv4()}_${values.logo.name}`;
      logo = await FileHelper.uploadFile(fullPath, values.logo);
    }

    if (values.customization) {
      // Hide name and position if no value entered
      const card_name: any = document.getElementById('card-name');
      const card_position: any = document.getElementById('card-position');

      if (Boolean(card_name) && !Boolean(values.personName)) card_name.style.visibility = 'hidden';
      if (Boolean(card_position) && !Boolean(values.jobTitle)) card_position.style.visibility = 'hidden';
    }

    if (!values.personName && !values.jobTitle) {
      values.customization = 0;
    }

    const requestBody = {
      product_uuid: template.uuid,
      card_name: template.name,
      quantity: values.quantity,
      card_variation: values.selectedVariant.name,
      font_color_code: values.textColor,
      name: values.customization ? values.personName : '',
      position: values.customization ? values.jobTitle : '',
      logo_url: values.customization ? logo : '',
      pixelcare: values.pixelcare ? 1 : 0,
      front_thumbnail: (values.customization && values.selectedVariant?.type === 1 && values.selectedVariant?.sampleViewSide === 1) ? values.selectedVariant.sample : values.selectedVariant.front,
      back_thumbnail: (values.customization && values.selectedVariant?.type === 1 && values.selectedVariant?.sampleViewSide === 2) ? values.selectedVariant.sample : values.selectedVariant.back,
      customization: values.customization,
    };
    productApi.addToCart({ requestBody })
      .catch(console.log)
      .finally(() => {
        getShoppingCartList();
        dispatch(toggleCartDrawer());
        setIsLoading(false);
      });
  }

  const getShoppingCartList = () => {
    productApi.getShoppingCartListing()
      .then(response => {
        if (response.success) {
          if (response.data?.cart === undefined) return;
          const items = response.data?.cart.map(item => ({
            product_uuid: item.product_uuid,
            cart_uuid: item.cart_uuid,
            name: item.card_name,
            price: item.price,
            quantity: item.quantity,
            designid: item.design_id,
            variant: {
              name: item.card_variation,
              front: item.front_thumbnail,
              back: item.back_thumbnail,
            },
            fontColor: item.font_color_code,
            logoUrl: item.logo_url,
            personName: item.name,
            jobTitle: item.position,
            customization: item.customization,
            customizationPrice: item.customization_price,
            customizationDiscountPrice: item.customization_discount_price,
            pixelcare: item.pixelcare === 1,
            pixelcarePrice: item.pixelcare_price,
            pixelcareDiscountPrice: item.pixelcare_discount_price,
            totalCustomizationPrice: item.total_customization_price,
            totalPixelcarePrice: item.total_pixelcare_price,
          }));
          dispatch(setItems(items));
        }
      })
      .catch((e) => {
        console.log(e)
      });
  }

  const handleCustomize = (productUUID: string, productName: string, quantity: number, attrUuid: string) => {
    navigate({
      pathname: `/${ZAKEKE_CUSTOMIZER}`,
      search: createSearchParams({
        productid: productUUID,
        productname: productName,
        quantity: quantity.toString(),
        attrid: attrUuid
      }).toString()
    });
  }

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "My NFC Card", path: PORTAL_CARDS },
        { name: "Buy Card", path: `${PORTAL_CARDS}/${BUY}` },
        { name: "Select Template", path: `${PORTAL_CARDS}/${BUY}/${TEMPLATES}?type=${type}` },
        { name: "Edit Template", path: null },
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get product details
  useEffect(() => {
    productApi.getProductDetails(`${productUUID}`, type === 'template').then(response => {
      if (response.success) {
        const product = response.data;
        setTemplate({
          uuid: productUUID,
          name: product.card_name,
          description: product.description,
          orientation: product.orientation,
          currency: 'USD',
          current_price: {
            base: product.discount_price,
            customization: product.customization_discount_price,
            pixelcare: process.env.REACT_APP_NFC_PIXELCARE_PRICE,
          },
          previous_price: {
            base: product.price,
            customization: product.customization_price,
            pixelcare: process.env.REACT_APP_NFC_CUSTOM_PRICE_ORIGINAL,
          },
          is_popular: true,
          variants: product.variation.map(variation => ({
            name: variation.variations,
            front: variation.front_thumbnail,
            back: variation.back_thumbnail,
            sample: variation.sample_thumbnail,
            sampleViewSide: variation.sample_view_side,
            uuid: variation.uuid,
            type: product.source
          })),
          textColors: [
            {
              name: 'Dark Gray',
              value: '#586C6C',
            },
            {
              name: 'White',
              value: '#F9FAFC',
            },
            {
              name: 'Black',
              value: '#373736',
            },
          ]
        });
      }
    }).catch((e) => {
      dispatch(openErrorDialog({
        title: "Product Details Error",
        description: e?.message
      }))
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Boolean(template) && <EditTemplateComponent
    type={type}
    template={template}
    handleBack={handleBack}
    handleSubmit={handleSubmit}
    isLoading={isLoading}
    handleCustomize={handleCustomize}
  />
}