import React from 'react'
import ResponseDialog from './responseDialog.component'

export interface SimpleDialogProps {
  open: boolean;
  title: string;
  description: string;
  selectedValue: string | null;
  onClose: (value: string | null) => void
}

export default function ResponseDialogContainer(props: SimpleDialogProps){
  const { title, description, onClose, selectedValue ,open } = props;
  
  const handleClose = () => {
    onClose(selectedValue);
  };


  return <ResponseDialog
    title={title}
    description={description}
    handleClose={handleClose}
    open={open}
  />
}