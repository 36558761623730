import React, {useEffect,useState} from 'react'
import MemberConnected from './memberConnected.component'
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import basicContactApi from '../../../../../api/basicContact'
import accountApi from '../../../../../api/account'
import FileHelper from '../../../../../lib/helper/fileHelper'
import { PHONE_MOBILE } from '../../../../../lib/constants/basiccontacttypes';
import {startLoadingLP, endLoadingLP} from '../../../../../features/loading';
import { openResponseDialog } from '../../../../../features/response-dialog';
import { EXCHANGE_CONTACT_EXPIRED } from '../../../../../lib/constants/routes';

function useQuery() {
  const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function MemberConnectedContainer(){
  let query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ownerCardNo  = query.get("owner_card_no") || ""
  const ownerProfileNo  = query.get("owner_profile_no") || ""
  const email  = query.get("email") || ""
  const email_verification_token:any = query.get("email_verification_token")
  const receiverProfileNo = query.get("receiver_profile_no")
  const hasConnectedBefore = Boolean(query.get("hasConnectedBefore"));
  const accessPath = query.get("owner_profile_no") ? "w" : "c";
  const isResponseDialogOpen = useSelector((state: any) => state.responseDialog.open);
  const[memberProfilePicture,setMemberProflePicture] = useState();
  const[profileTheme,setProfileTheme] = useState({
    highlight:{
      primary_color: '#FECD20',
      primary_font_color: '#000000',
      secondary_color: '#373736',
      secondary_font_color: '#FECD20',
    },
    button:{
      primary_color: '#FECD20',
      primary_font_color: '#000000',
      secondary_color: '#373736',
      secondary_font_color: '#FECD20',
    },
    primary_background: '#FECD20',
    primary_font_color: '#000000',
    secondary_background: '#373736',
    secondary_font_color: '#FECD20',
  });
  const [verifyFail,setVerifyFail] = useState(false)
  const[profilePicture,setProfilePicture] = useState();
  const[memberName, setMemberName] = useState("");
  const[memberContact,setMemberContact] = useState("");
  const[receiverName, setReceiverName] = useState("");

  const getReceiverActiveProfile = async() =>{
    if (localStorage.getItem('user') !== null) { 
      const response = await basicContactApi.getActiveProfile({serialNo: receiverProfileNo})
      .then(response => response)
      .catch(response => response)

      if(response.success){
        if(response?.data.user_details){
          if(response.data.user_details.profile_image){
            const profilePicUrl =  await getUrl(response.data.user_details.profile_image)
            setProfilePicture(profilePicUrl)
          }
        }
        if(response.data.user_details.full_name){
          setReceiverName(response.data.user_details.full_name)
        }
      }
    }
  }
  const getActiveProfile = async() =>{
    let response;
    if(ownerCardNo){
      response = await basicContactApi.getActiveProfileByCard({serialNo: ownerCardNo})
     .then(response => response)
     .catch(response => response)
     }else{
       response = await basicContactApi.getActiveProfile({serialNo: ownerProfileNo})
     .then(response => response)
     .catch(response => response)
     }

    if(response.success){
      response.data.contact_info.content.map((value)=>{
        if(value.category === PHONE_MOBILE){
          setMemberContact(value.info)
        }
        return value
      })
      setMemberName(response.data.profile_name)
      if(response.data.user_details.profile_image){
        const profilePicUrl =  await getUrl(response.data.user_details.profile_image)
        setMemberProflePicture(profilePicUrl)
      }
      if(response?.data.theme){
        setProfileTheme(response.data.theme)
      }
    }
  }
  async function getUrl(fullPath: string) {
    const response = await FileHelper.getUrl(fullPath).then(repsonse => repsonse)
    return response
  }
  useEffect(() => {
    verifyEmail();
    const fetchData = async () => {
      await getActiveProfile();
      await getReceiverActiveProfile();
      dispatch(endLoadingLP());
    };

    dispatch(startLoadingLP());
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyEmail = async () =>{
    if(email === undefined || !email){
      return true
    }

    const response = await accountApi.verifyEmail({
          email,
          email_verification_token
        }).then(response => response).catch(error => error)
        
    if(response){
      if(!response?.success){
        setVerifyFail(true)
        dispatch(openResponseDialog({title: "Email verification failed",description: response.message}))
      }else{
        setVerifyFail(false)
      }
    }

    return response    
  }

  useEffect(() => {
    if(verifyFail && !isResponseDialogOpen){
      navigate({
        pathname: EXCHANGE_CONTACT_EXPIRED,
        search: createSearchParams({
            email: email 
        }).toString()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isResponseDialogOpen])

  return <MemberConnected 
  hasConnectedBefore={hasConnectedBefore}
  ownerSerialNo={ownerCardNo || ownerProfileNo}
  profilePicture={profilePicture}
  memberProfilePicture={memberProfilePicture}
  memberContact={memberContact}
  receiverName={receiverName}
  memberName={memberName}
  accessPath={accessPath}
  handleDone={() => {
    const landingpagePath = '/'+accessPath+"/"+(ownerCardNo || ownerProfileNo);
    navigate(landingpagePath,{replace: true});
  }}
  profileTheme={profileTheme}/>
}