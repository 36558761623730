import { createAsyncThunk } from "@reduxjs/toolkit";
import campaignApi from "../../api/campaign";
import { CAMPAIGN } from "../../lib/constants/slices";

export const getList = createAsyncThunk(
  `${CAMPAIGN}/getList`,
  async (_, { rejectWithValue }) => {
    return campaignApi.getList()
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);
