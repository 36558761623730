import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  access_right: [],
  access_limit: {
    profile: 0,
    contact: 0,
  },
};

const accessLimitSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    setAccessLimit: (state, action) => {
      const { access_right, access_limit } = action.payload;
      state.access_right = access_right;
      state.access_limit = access_limit;
    },
  },
});

export const { setAccessLimit } = accessLimitSlice.actions;
export const accessLimitReducer = accessLimitSlice.reducer;