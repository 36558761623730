import React, {useEffect,useState} from 'react';
import {Button,Box,Dialog, DialogTitle, IconButton, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import myVoucherApi from '../../../../../api/myVoucher';
import { useDispatch } from 'react-redux';
import { startLoading, endLoading } from '../../../../../features/loading';
import { openResponseDialog } from '../../../../../features/response-dialog';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import FileHelper from '../../../../../lib/helper/fileHelper'
import { styled } from '@mui/material/styles';
import icons from '../../../../../assets/icons';
import moment from 'moment';
interface ProfileDialogProps {
  open: boolean;
  onClose: () => void;
  profileUUID: string;
}

const ProfileImage = styled(Box)({
  borderRadius: "79.5px",
  width: "79.5px",
  height: "79.5px",
  flexShrink: 0,
  background: "lightgray 50% / cover no-repeat",
  backgroundImage: `url(${icons.profile_picture_icon})`,
  backgroundSize: 'cover',
  backgroundRepeatX: 'no-repeat',
  backgroundRepeatY: 'no-repeat',
});

const ProfileDialog: React.FC<ProfileDialogProps> = ({ open, onClose, profileUUID }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [profile, setProfile] = React.useState({} as any);
  const [profileImage, setProfileImage] = useState("");
  const [updateKey, setUpdateKey] = useState(0);

  const getProlfile = async (profileUUID: string) => {
    dispatch(startLoading());
    await myVoucherApi.getProfile(profileUUID).then((response) => {
      if(response.success){
        setProfile(response.data);
      }else{
        onClose();
        dispatch(openResponseDialog({title: "Error",description: response.message}))
      }
    }).catch((error) => {
      onClose();
      dispatch(openResponseDialog({title: "Error",description: error.message}))
    }).finally(() => {
      dispatch(endLoading());
    })
  }
  
  const viewProfile = (serialNumber) => {
    navigate(`/w/${serialNumber}`);
  }

  const getProfileImage = async(profileImage) =>{
    if(profileImage){
      const fileResponse =  await FileHelper.getUrl(profileImage).then(fileResponse =>  fileResponse)
      setProfileImage(fileResponse)
      setUpdateKey(updateKey + 1) //force update component
    }
    return null;
  }

  useEffect(() => {
    if(profileUUID){
      getProlfile(profileUUID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileUUID]);

  useEffect(() => {
    getProfileImage(profile.profile_image)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box sx={{
        padding:'1rem 1.5rem'
      }}>
        <Box sx={{
          display:"flex",
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: '1.5rem',
        }}>
          <ProfileImage key={updateKey} sx={{ backgroundImage: `url( ${ profileImage? profileImage : icons.profile_picture_icon})` }}></ProfileImage>
          <Box sx={{
            display:'flex',
            flexDirection: 'column',
          }}>
            <Typography variant="h3" color="initial">
              {profile?.profile_name}
            </Typography>
            <Typography color="grey" sx={{
              fontSize: '15px'
            }}>
              {profile?.job_title} 
              
              { profile?.company_name? 
                '('+profile?.company_name+')':
                ''
              }
            </Typography>
            <Box sx={{
              [theme.breakpoints.down('md')]: {
                display: 'flex',
                flexDirection: 'column',
              },
              [theme.breakpoints.up('md')]: {
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem'
              },
              '& .MuiTypography-root': {
                fontSize: '15px'
              }
            }}>
            <Typography color="grey">
              Date of birth: {moment(profile?.birthday).format('DD MMM YYYY')}
            </Typography>
            <Typography color="grey" sx={{
              [theme.breakpoints.down('md')]: {
                display: 'none'
              },
            }}>
            |
            </Typography>
            <Typography color="grey">
              Date of join: {profile?.created_at}
            </Typography>
            </Box>

          </Box>
        </Box>
          <Box sx={{
            display:'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '1rem 0',
            alignItems: 'center'
          }}>
            <Typography variant="h3" color="initial">
              {profile?.profile_name}
            </Typography>
            <Button
              variant='contained'
              color='primary'
              onClick={()=>viewProfile(profile?.serial_number.toString())}
            >
              View Page
            </Button>
          </Box>
          <Box>
            <Box sx={{
              borderBottom: '1px solid #D5D5D5',
              mb: '1.5rem'
            }}>
              <Typography fontWeight='500' color="initial">Personal Details</Typography>
            </Box>
            <Box sx={{
              display:'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}>
                <Typography>Email : </Typography>
                <Typography>{
                  profile?.email?.length ?
                  profile?.email :
                  'N/A' 
                  }</Typography>
              </Box>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}>
                <Typography>Job Title : </Typography>
                <Typography>{profile?.job_title?
                  profile?.job_title :
                  'N/A'  
                }</Typography>
              </Box>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}>
                <Typography>Company Name : </Typography>
                <Typography>{profile?.company_name?
                  profile?.company_name :
                  'N/A'  
                }</Typography>  
              </Box>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}>
                <Typography>Company Address : </Typography>
                <Typography>{profile?.company_address?.length ?
                  profile?.company_address.map((value: string) => value).join(', '):
                  'N/A'  
                }</Typography>  
              </Box>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}>
                <Typography>Website : </Typography>
                <Typography>{profile?.website?.length ?
                  profile?.website.map((value: string) => value).join(', '):
                  'N/A'  
                }</Typography>  
              </Box>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}>
                <Typography>Phone Number : </Typography>
                <Typography>{profile?.phone_number?.length ?
                  profile?.phone_number.map((value: string) => value).join(', '):
                  'N/A'  
                }</Typography>  
              </Box>
              
            </Box>
          </Box>
          
      </Box>
      {/* Add your dialog content here */}
    </Dialog>
  );
};

export default ProfileDialog;

