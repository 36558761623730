import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, Divider, Slide, TextField, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Formik } from "formik";
import userApi from "../../../../api/user";
import { useDispatch } from "react-redux";
import { startLoading,endLoading } from "../../../../features/loading";
import { openResponseDialog } from "../../../../features/response-dialog";
import CountryOption from "../../../../components/country-option";
import StateOption from "../../../../components/state-option";
import {
  GetState,
  GetCountries
} from 'react-country-state-city';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BillingDialog({ open, setOpen, billing, setBilling }) {
  const dispatch = useDispatch();
  const [country, setCountry] = React.useState(billing.country || "MY")
  const [state, setState] = React.useState<string | null>("Kuala Lumpur");

  const handleState = (value:any) => {
    setState(value);
  }
  
  const handleCountry = async (value:any) => {
    setCountry(value);
    let selectedCountry =   {
      id:132,
      iso2: "MY",
      iso3: "MYS",
      name: "Malaysia"
    };
    await GetCountries().then((result) => {
      selectedCountry = result.find((country) => country.iso2 === value);

      let selectedState = [];
      GetState(selectedCountry.id).then((result) =>{
        const modifiedResult = result.map((state) => ({
          ...state,
          value: state.name, 
        }));

        selectedState = modifiedResult;
      }).finally(() => {
        if(selectedState.length > 0) setState(selectedState[0]?.value);
      })
    })
  }

  const handleSubmit = async (values) => {
    dispatch(startLoading());
    const requestBody = {
      address_1: values.address1,
      address_2: values.address2,
      city: values.city,
      state: state,
      zip: values.postcode,
      country: country,
    };
    await userApi.updateBilling({ requestBody: requestBody }).then((res) => {
      if (res.success) {
        setBilling({
          address1: values.address1,
          address2: values.address2,
          city: values.city,
          state: state,
          postcode: values.postcode,
          country: country,
        });
        dispatch(openResponseDialog({
          title: "Success",
          description: "Billing address has been updated successfully."
        }));
        setOpen(false);
      }else{
        dispatch(openResponseDialog({
          title: "Error",
          description: "Failed to update billing address."
        }));
      }
    }).catch((err) => { 
      dispatch(openResponseDialog({
        title: "Error",
        description: err.message
      }));
    }).finally(() => {  
      dispatch(endLoading());
    });
  }
  return <Dialog
  sx={{ top: { xs: 0, sm: "10%" }, bottom: { xs: 0, sm: '10%'} }}
    PaperProps={{
      sx: {
        borderRadius: {xs: 0, sm: '16px'},
        overflow: 'clip',
        m : {
          xs: 0,
          md: '250px'
        },
        p: {
          xs: '50px 20px 0 20px',
          md: '50px 90px 0 90px',
        }
      }
    }}
    fullScreen
    open={open}
    onClose={() => setOpen(false)}
    scroll="paper"
    TransitionComponent={Transition}
  >
    <Button onClick={() => setOpen(false)} sx={{ position: 'absolute', top: '10px', right: 0, color: '#373736' }}>
      <CloseIcon />
    </Button>
    <Typography gutterBottom component='h1' variant='h1' sx={{ fontSize: '31px', fontWeight: 400 }}>
      Billing Address
    </Typography>
    <Divider sx={{ borderColor: 'white', mb: '20px' }} />
    <Formik
      initialValues={billing}
      onSubmit={values => {
        setBilling(values);
        setOpen(false);
        handleSubmit(values);
      }}
    >
      {({ values, handleChange, handleSubmit }) => <Box component={'form'} onSubmit={handleSubmit} sx={{ mb: '20px', maxWidth: '700px' }} noValidate>
        <Box>
          <Typography component='label' htmlFor='address1' className="required" variant='h5'>
            Address Line 1
          </Typography>
          <TextField
            color='primary'
            margin="dense"
            required
            fullWidth
            size="small"
            id="address1"
            name="address1"
            autoComplete="address-line1"
            sx={{ bgcolor: 'white', borderRadius: '4px' }}
            value={values.address1}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <Typography component='label' htmlFor='address2' variant='h5'>
            Address Line 2
          </Typography>
          <TextField
            color='primary'
            margin="dense"
            fullWidth
            size="small"
            id="address2"
            name="address2"
            autoComplete="address-line2"
            sx={{ bgcolor: 'white', borderRadius: '4px' }}
            value={values.address2}
            onChange={handleChange}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: '15px' }}>
          <Box sx={{ flex: 1 }}>
            <Typography component='label' htmlFor='city' className="required" variant='h5'>
              City
            </Typography>
            <TextField
              color='primary'
              margin="dense"
              required
              fullWidth
              size="small"
              id="city"
              name="city"
              sx={{ bgcolor: 'white', borderRadius: '4px' }}
              value={values.city}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography component='label' htmlFor='country' className="required" variant='h5'>
              State
            </Typography>
            <StateOption
              countryIso2={country}
              countryIso3={null}
              state={state || values.state}
              handleChange={(value)=>handleState(value)}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: '15px' }}>
          <Box sx={{ flex: 1 }}>
            <Typography component='label' htmlFor='postcode' className="required" variant='h5'>
              Postal code
            </Typography>
            <TextField
              color='primary'
              margin="dense"
              required
              fullWidth
              size="small"
              id="postcode"
              name="postcode"
              autoComplete="postal-code"
              sx={{ bgcolor: 'white', borderRadius: '4px' }}
              value={values.postcode}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography  component='label' htmlFor='country' className="required" variant='h5'>
              Country
            </Typography>
            <Box sx={{mt:'9px'}}>
            <CountryOption 
              country={country}
              handleChange={(value)=>handleCountry(value)}
              />
            </Box>
          </Box>
        </Box>
        <Button type="submit" variant="contained" color="secondary" sx={{ mt: '20px' }}>
          Set Address
        </Button>
      </Box>}
    </Formik>
  </Dialog>
}