import React from "react";
import { Avatar, Box, Button, Card, CardContent, Divider, Typography } from "@mui/material";
import icons from "../../../assets/icons";
import moment from "moment";
import ActionsDialog from "./actionsDialog.component";

const AppointmentCard = ({
  appointment,
  getCall,
  getEmail,
  status,
  profile,
}) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedAppointment, setSelectedAppointment] = React.useState<any>({});

  return <Card elevation={0} sx={{ height: '100%', display: 'flex', flexDirection: 'column', background: `linear-gradient(to right, ${status === 'approved' ? '#EEFFF1' : '#FFF5E6'}, white)`, borderRadius: '8px', border: '1px solid #E4E5E8' }}>
    <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: '23px' }}>
      <Box sx={{ background: appointment.type === 'requester' ? '#826FFF' : '#A638E9', borderRadius: '4px', p: '5px 10px', mb: '10px' }}>
        <Typography sx={{ color: '#EEECFF', fontSize: '13px', fontWeight: 500, textTransform: 'capitalize' }}>
          {appointment.type}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '9px', height: '9px', background: profile?.color, borderRadius: '100%' }} />
        <Typography sx={{ ml: '10px', flex: 1, fontSize: 13, fontWeight: 400, color: '#18191C', textAlign: 'start' }}>
          {profile?.name}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src={icons.time} alt="" width={'17px'} height={'17px'} />
        <Typography sx={{ ml: '10px', flex: 1, fontSize: 13, fontWeight: 500, color: '#18191C', textAlign: 'start' }}>
          {moment(`${appointment.date}${appointment.time}`, 'YYYY-MM-DDTHH:mm').format('D MMMM YYYY, h:mm A')}
        </Typography>
      </Box>
      <Divider sx={{ my: '10px' }} />
      <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
        <Avatar src={appointment?.profile_picture ?? icons.profile_picture_icon} sx={{ width: 56, height: 56 }} />
        <Box sx={{ flex: 1, ml: '10px' }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, textAlign: 'start' }}>
            {appointment.name}
          </Typography>
          <Typography sx={{ fontSize: 13, fontWeight: 400, textAlign: 'start' }}>
            {appointment.email}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
        {/* <Button size="small" sx={{ p: '10px 20px', mt: '20px', color: "black", backgroundColor: 'white', borderRadius: '8px', border: '1px solid #C5C5C5' }} startIcon={<img width={16} src={icons.call_ringing_02} alt='' />} onClick={getCall}>Call</Button>
        <Button size="small" sx={{ p: '10px 20px', mt: '20px', color: "black", backgroundColor: 'white', borderRadius: '8px', border: '1px solid #C5C5C5' }} startIcon={<img width={16} src={icons.mail_at_sign_02} alt='' />} onClick={getEmail}>Email</Button> */}
        <Button
          size="small"
          sx={{ p: '10px 20px', mt: '20px', color: "black", backgroundColor: 'white', borderRadius: '8px', border: '1px solid #C5C5C5' }}
          endIcon={<img width={16} src={icons.arrow_right} alt='' />}
          onClick={() => {
            setSelectedAppointment(appointment);
            setOpenDialog(true);
          }}>
          Actions
        </Button>
      </Box>
    </CardContent>
    <ActionsDialog appointment={selectedAppointment} open={openDialog} handleClose={() => setOpenDialog(false)} profile={profile} />
  </Card>
}

export default AppointmentCard;