import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const getActiveProfileByEncode = ({userUUID}: {userUUID: string}): Promise<Data> => {
    return new Promise((resolve, reject) => {
        axios.get(`basiccontact/getActiveProfile?user_uuid=${userUUID}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getActiveProfileByEncode;
