import React, {useEffect,useState} from "react";
import { Button, Checkbox, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, styled } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import contactApi from "../../../api/contact";
const StyledButton = styled(Button)({
  padding: '10px 35px',
  borderRadius: '30px',
  backgroundColor: '#373736',
  color: '#FECD20',
  '&:hover': {
    backgroundColor: '#252525',
    color: '#FECD20',
  }
});

function FilterDialog({ open, handleClose, filterFn, setFilterField }) {
  const [selectedJobTitles, setSelectedJobTitles] = useState<string[]>([]);
  const [accountType, setAccountType] = useState<string>('');
  const [jobTitles, setJobTitles]  = useState<string[]>([]);

  useEffect(() => {
    setSelectedJobTitles([]);
    handleJobTitles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, accountType]);

  const handleJobTitles = async () =>{
    const response = await contactApi.getJobTitles({type: accountType})
    .then(response => response)
    .catch(error => error)

    if(response.success){
      setJobTitles(response.data);
    }else{
      setJobTitles([]);
    }
  }

  const handleChange = (event: SelectChangeEvent<typeof selectedJobTitles>) => {
    const {
      target: { value },
    } = event;
    setSelectedJobTitles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
    <DialogTitle>Filter by categories</DialogTitle>
    <DialogContent>
      <FormControl fullWidth sx={{ mt: '10px' }}>
        <InputLabel id="demo-multiple-checkbox-label">Job Title</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedJobTitles}
          onChange={handleChange}
          input={<OutlinedInput label="Job Title" />}
          renderValue={(selected) => selected.join(', ')}
        >
          {jobTitles.map((title) => (
            <MenuItem key={title} value={title}>
              <Checkbox checked={selectedJobTitles.indexOf(title) > -1} />
              <ListItemText primary={title} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mt: '10px' }}>
        <InputLabel id="account-type-label">Account Type</InputLabel>
        <Select
          value={accountType}
          label="Account Type"
          labelId="account-type-label"
          onChange={e => setAccountType(e.target.value)}
        >
          <MenuItem value={''} selected>All</MenuItem>
          <MenuItem value={'member'}>Member</MenuItem>
          <MenuItem value={'guest'}>Guest</MenuItem>
        </Select>
      </FormControl>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <StyledButton onClick={() => {
          // filterFn(selectedJobTitles.join(','), accountType);

          let position = {"position": selectedJobTitles};

          let type = {"type": accountType};
          setFilterField(filterField => ({
                ...filterField,
                ...position,
                ...type
              }));
          handleClose();
        }}>Search</StyledButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </DialogContent>
  </Dialog>
};

export default FilterDialog;