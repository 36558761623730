
import memberToMemberExchange from "./memberToMemberExchange.api";
import importContact from './importContact.api'
import getJobTitles from "./getJobTitles.api";
import getDetails from "./getDetails.api";
const contactApi = {
  memberToMemberExchange,
  importContact,
  getJobTitles,
  getDetails
}

export default contactApi 
