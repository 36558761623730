import React from 'react';
import { Box, IconButton, createTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import icons from '../../../assets/icons';

interface ExpandMoreProps {
  expanded: boolean;
  onClick: () => void;
}

export default function ExpandMoreButton({ expanded, onClick }: ExpandMoreProps) {
  const theme = createTheme({});
  const profileTheme = useSelector((state: any) => state.landingPage.theme);

  return <IconButton sx={{
    m: '8px',
    transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    border: `1px solid ${expanded ? profileTheme?.button?.secondary_color ?? '#373736' : 'black'}`,
    bgcolor: expanded ? profileTheme?.button?.secondary_color ?? '#373736' : 'transparent',
    '&:hover .icon': {
      bgcolor: expanded ? profileTheme?.button?.secondary_color ?? '#373736' : 'black',
    },
  }} onClick={onClick}>
    <Box className='icon' sx={{
      backgroundColor: expanded ? profileTheme?.button?.secondary_font_color ?? '#FECD20' : 'black',
      mask: `url(${icons.arrow_down_01_icon})`,
      maskSize: 'cover',
      maskRepeat: 'no-repeat',
      WebkitMask: `url(${icons.arrow_down_01_icon})`,
      WebkitMaskSize: 'cover',
      WebkitMaskRepeat: 'no-repeat',
      width: '15px',
      height: '15px',
    }} />
  </IconButton>
}