const makeVCardVersion = () => `VERSION:3.0`;
const makeVCardName = (name) => `N:;${name};;`;
const makeVCardOrg = (org) => `ORG:${org}`;
const makeVCardTitle = (title) => `TITLE:${title}`;

const makeVCardPhoto = (img) => {
  switch (img.type) {
    case 'image/jpeg':
      return `PHOTO;TYPE=JPEG;ENCODING=b:[${img.base64String}]`
    case 'image/jpg':
      return `PHOTO;TYPE=JPG;ENCODING=b:[${img.base64String}]`
    case 'image/png':
      return `PHOTO;TYPE=PNG;ENCODING=b:[${img.base64String}]`
    default:
      return `PHOTO;TYPE=JPEG;ENCODING=b:[${img.base64String}]`
  }
};

const makeVCardTel = (phone) => `TEL;TYPE=WORK,VOICE:${phone}`;
const makeVCardAdr = (address) => `ADR;TYPE=WORK,PREF:;;${address}`;
const makeVCardEmail = (email) => `EMAIL:${email}`;
const makeVCardSource = (url) => `pxlink.URL:${url}\npxlink.X-ABLabel;CHARSET=UTF-8:eBusiness Card\nSOURCE:${url}`;
const makeVCardTimeStamp = () => `REV:${new Date().toISOString()}`;
const makeVCardNote = (note) => Boolean(note) ? `NOTE;CHARSET=UTF-8:${note}` : '';

const makeVCardWebsites = (websites?: any[]) => {
  const links = (websites ?? []).map((site, index) => `item${index}.URL:${site.info}\nsite${index}.X-ABLabel;CHARSET=UTF-8:Visit Website`);
  return links.join('\n');
}

const makeVCardSocialSites = (social_sites?: any[]) => {
  const links = (social_sites ?? []).map(site => `X-SOCIALPROFILE;type=${site.category};x-user=${site.category}:${site.info}`);
  return links.join('\n');
}

const makeVCard = (contact) => {
  return `BEGIN:VCARD
${makeVCardVersion()}
${makeVCardName(contact.name ?? '')}
${makeVCardNote(contact.note)}
${makeVCardOrg(contact.company ?? '')}
${makeVCardTitle(contact.title ?? '')}
${makeVCardPhoto(contact.img ?? '')}
${makeVCardTel(contact.phone ?? '')}
${makeVCardAdr(contact.address ?? '')}
${makeVCardEmail(contact.email ?? '')}
${makeVCardWebsites(contact.websites)}
${makeVCardSocialSites(contact.social_sites)}
${makeVCardSource(contact.source ?? '')}
${makeVCardTimeStamp()}
END:VCARD`;
}

export default makeVCard;