import React, { useState, useEffect } from "react";
import ReferralTabComponent from "./referralTab.component";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "../../../../features/loading";
import { openResponseDialog } from "../../../../features/response-dialog";
import userApi from "../../../../api/user";

export default function LoginSecurityContainer() {
  const dispatch = useDispatch();
  const [referralCode, setReferralCode] = useState('');
  const [statistics, setStatistics] = useState({});

  const getList = async () => {
    dispatch(startLoading());
    await userApi.getReferralCode()
      .then((response) => {
        if (response.success) {
          setReferralCode(response.data.referral_code ?? '');
        }
      }).catch((error) => {
        dispatch(openResponseDialog({ title: 'error', description: error.message }));
      }).finally(() => {
        dispatch(endLoading());
      })
  }

  const getStatistics = async () => {
    dispatch(startLoading());
    await userApi.getReferralStatistics()
      .then((response) => {
        if (response.success) {
          setStatistics({
            signup_count: response.data.signup_count,
            subscription_count: response.data.subscription_count,
            buy_card_count: response.data.buy_card_count
          });
        }
      }).catch((error) => {
        dispatch(openResponseDialog({ title: 'error', description: error.message }));
      }).finally(() => {
        dispatch(endLoading());
      })
  }

  useEffect(() => {
    dispatch(startLoading())
    getList();
    getStatistics();
    dispatch(endLoading())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ReferralTabComponent
    referralCode={referralCode}
    statistics={statistics}
  />
}