import React, { useEffect, useState } from "react";
import CardTile from "./component/cardTile.component";
import { Box, CssBaseline, Typography, Container, Grid, Button, Tabs, Tab } from "@mui/material";
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../features/admin';
import { BUY, PORTAL_CARDS } from "../../lib/constants/routes";
import profileApi from "../../api/profile";
import { useNavigate } from "react-router-dom";
import cardApi from "../../api/card";
import FileHelper from "../../lib/helper/fileHelper";
// import Advertisement from "../../components/advertisement";
import icons from "../../assets/icons";
import Placeholder from "../../components/placeholder/placeholder";

export default function CardListContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [profiles, setProfiles] = useState([]);
  const [cards, setCards] = useState<any[]>([]);

  const getCards = () => {
    cardApi.getCards().then(response => {
      const cards = response.data;

      if (Boolean(cards?.length)) {
        Promise.all(cards.map(async (card) => {
          let presigned_url = null;
          if (Boolean(card.font_thumbnail) && card.font_thumbnail?.slice(0, 5) !== 'https') {
            presigned_url = await FileHelper.getUrl(card.font_thumbnail);
          }

          return ({
            presigned_url,
            ...card,
          })
        })).then(setCards);
      }
    });
  }

  const bindProfile = (profile_uuid: string, card_serial_number: string) => {
    cardApi.bindProfile({
      requestBody: {
        card_serial_number,
        profile_uuid,
      }
    })
      .then(getCards)
      .catch(console.log);
  }

  const handleReceive = (cardUuid) => {
    cardApi.receiveCard(cardUuid)
      .then(getCards);
  }

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "My NFC Card", path: PORTAL_CARDS },
        { name: "Card List", path: null }
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    getCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfiles = () => {
    profileApi.getProfilesDropdown()
      .then((response: any) => {
        const prfs = (response.data ?? []).map((prf: any) => ({
          uuid: prf.profile_uuid,
          name: prf.profile_name,
        }));

        // if (prfs.length === 1) setSelectedProfileUUID(prfs[0].uuid);

        setProfiles(prfs);
      });
  }

  React.useEffect(() => {
    getProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <>
    {/* <Advertisement >
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/9QsuODQOnA4?si=tMb2ALCQk5Xc7N-U"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen>
      </iframe>
    </Advertisement> */}
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={0} onChange={() => { }}>
        <Tab
          iconPosition="end"
          sx={{ textTransform: "none", color: "black !important" }}
          label="Card List"
          {...a11yProps(0)}
        />
      </Tabs>
    </Box>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Container maxWidth="lg" style={{ padding: 0 }} sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '20px', flexWrap: 'wrap', alignItems: 'center' }}>
          <Typography variant="h1">
            Card List
          </Typography>
          {/* TODO: temporary disable buy card feature */}
          <Button variant="contained" color="secondary" sx={{ borderRadius: '30px', px: '20px', ml: '10px' }} onClick={() => navigate(BUY)}>
            Buy Card
          </Button>
        </Box>

        {cards.length > 0 && <Grid container spacing={2}>
          {cards.map(card => (
            <Grid item xs={12} sm={6} xl={4} key={card.card_serial_number}>
              <CardTile
                card={card}
                profiles={profiles}
                bindProfile={bindProfile}
                handleReceive={handleReceive}
              />
            </Grid>
          ))}
        </Grid>}

        {cards.length === 0 && <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Placeholder
            icon={icons.credit_card}
            title="No card"
            description="Buy a card now!"
            buttonText="Buy Card"
            action={() => navigate(BUY)}
          />
        </Grid>}
      </Container>
    </Box>
  </>
}