import React from "react";
import ContactFormComponent from "../../../subscription/pages/contact-form/contactForm.component";
import { useNavigate } from "react-router-dom";
import { Container, ThemeProvider, createTheme } from "@mui/material";
import subscriptionApi from "../../../../api/subscription";
import { useDispatch } from "react-redux";
import { openResponseDialog } from "../../../../features/response-dialog";
import { endLoading, startLoading } from "../../../../features/loading";
import { SIGN_IN } from "../../../../lib/constants/routes";
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#FECD20',
    },
    secondary: {
      main: '#373736',
      contrastText: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h1: {
      fontSize: '42px',
      fontWeight: 400,
    },
    h2: {
      fontSize: '35px',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '16px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#FFF',
          backgroundColor: '#3C3C3B',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        // root: {
        //   fontSize: '13px',
        //   fontWeight: 500,
        // }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "30px"
        }
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between'
        }
      }
    },
  },
});

export default function ContactFormContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleBack = () => {
    navigate(-1);
  }

  const handleSubmit = async (values) => {
    dispatch(startLoading());
    await subscriptionApi.businessContact(values).then((response) => {
      if (response.success) {
        dispatch(endLoading());
        dispatch(openResponseDialog({
          title: 'Success',
          description: 'Your request has been submitted successfully. Our sales team will contact you soon.',
        }));
        navigate(SIGN_IN);
      }
    }).catch((error) => {
      dispatch(openResponseDialog(
        {
          title: 'Error',
          description: error.message,
        }
      ));
    }).finally(() => {
      dispatch(endLoading());
    });
  }

  return <Container sx={{ p: '30px' }}>
    <ThemeProvider theme={defaultTheme}>
      <ContactFormComponent handleBack={handleBack} handleSubmit={handleSubmit} />
    </ThemeProvider>
  </Container>
}