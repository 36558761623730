import React, { useEffect, useState } from 'react';
import ProfileOptionComponent from './profileOption.component';
import profileApi from '../../../../api/profile';
import FileHelper from '../../../../lib/helper/fileHelper'
import contactApi from '../../../../api/contact';
import { useDispatch } from 'react-redux';
import { openErrorDialog } from '../../../../features/error-dialog';
import { useNavigate,useLocation,createSearchParams } from 'react-router-dom';
import { EXCHANGE_CONTACT_MEMBER_CONNECTED, EXCHANGE_CONTACT_MEMBER_SIGN_IN } from '../../../../lib/constants/routes';

function useQuery() {
  const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ProfileOptionContainer() {
  const user = localStorage.getItem('user');
  const dispatch = useDispatch();

  let query = useQuery();
  const ownerCardNo  = query.get("owner_card_no") || ""
  const ownerProfileNo  = query.get("owner_profile_no") || "" 
  const accessPath = ownerProfileNo !== "" ? "w" : "c"

  const [profileList, setProfileList] = useState<Array<Object>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [location, setLocation] = React.useState({ latitude: 0, longitude: 0, isGeoLocationAllowed: false});
  const navigate = useNavigate();
  const getProfileList = async () => {
    if (user) {
      const response = await profileApi.getActiveList().then(response => response).catch(error => {
        if (error.code === 3) { // expired token
          navigate ({
            pathname: EXCHANGE_CONTACT_MEMBER_SIGN_IN,
            search: createSearchParams({
              owner_card_no: ownerCardNo,
              owner_profile_no: ownerProfileNo,
            }).toString()
          })
        }
      })
      if (response?.success) {
        const data = response.data
        const profiles = await Promise.all(data.map(async (profile: any) => {
          return {
            name: profile.name,
            companyName: profile.company_name,
            serialNumber: profile.serial_number,
            profileName: profile.profile_name,
            profileUUID: profile.profile_uuid,
            profileActive: profile.profile_active,
            profilePicture: profile.profile_picture ? await FileHelper.getUrl(profile.profile_picture).then(repsonse => repsonse) : null
          }

        }))
        setProfileList(profiles)
        return response.data
      }
    }
  }

  const handleSelected = async ({ serialNumber }:{ serialNumber: string}) => {
    setIsLoading(true);
    await contactApi.memberToMemberExchange({
              ownerCardNo: ownerCardNo,
              ownerProfileNo: ownerProfileNo,
              receiverProfileNo: serialNumber,
              longitude: location.longitude,
              latitude: location.latitude,
              ip_address: "",
              is_geolocation_allowed: location.isGeoLocationAllowed
            })
        .then(response => {
          if (response.success) {
            navigate({
              pathname: '/'+accessPath+'/'+EXCHANGE_CONTACT_MEMBER_CONNECTED,
              search: createSearchParams({
                owner_card_no: ownerCardNo,
                owner_profile_no: ownerProfileNo,
                receiver_profile_no: serialNumber,
                access_path: accessPath
            }).toString()
            });
          }else{
            dispatch(openErrorDialog({
              title: "Member Exchange Failed",
              description: response.message, 
              redirectPath: "/"+accessPath+"/"+(ownerProfileNo !== ""? ownerProfileNo : ownerCardNo),
            }));
          }
        })
        .catch(error => {
          if (error.message === 'The contact was connected before') {
            return navigate({
              pathname: '/' + accessPath + '/' + EXCHANGE_CONTACT_MEMBER_CONNECTED,
              search: createSearchParams({
                owner_card_no: ownerCardNo,
                owner_profile_no: ownerProfileNo,
                receiver_profile_no: serialNumber,
                access_path: accessPath,
                hasConnectedBefore: '1',
              }).toString()
            });
          }
          dispatch(openErrorDialog({
            title: "Member Exchange Failed",
            description: error.message ?? "Member exchange has failed. Please try again later.", 
            redirectPath: "/"+accessPath+"/"+(ownerProfileNo !== ""? ownerProfileNo : ownerCardNo),
          }));
          return error
        }).then(() => {
          setIsLoading(false)
        });
  }

  useEffect(() => {
    getProfileList()
    if (localStorage.getItem('location') !== null) {
      const location = JSON.parse(localStorage.getItem('location') || '{}')
      setLocation(location)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <ProfileOptionComponent profileList={profileList} handleSelected={handleSelected} isLoading={isLoading}/>;
}
