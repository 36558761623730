import React, {useEffect} from "react";
import DetailComponent from "./detail.component";
import orderApi from "../../../../../api/order";
import { startLoading, endLoading } from '../../../../../features/loading';
import { useDispatch } from 'react-redux';
export default function DetailContainer({handleView,orderUUID}:{
  handleView:()=>void,
  orderUUID:string
}) {
  const dispatch = useDispatch();

  const [orderDetail, setOrderDetail] = React.useState({} as any);
  const [payment,setPayment] = React.useState({} as any)
  const [shipping,setShipping] = React.useState({} as any)
  const [products,setProducts] = React.useState([] as any)
  const [shippingStatus, setShippingStatus] = React.useState(-1);
  const getDetails = async () => {
    dispatch(startLoading());
    await orderApi.getDetails(orderUUID).then((res) => {
      if(res.success){
        setOrderDetail(res.data);
        if(res.data?.payment){
          setPayment(res.data.payment)
        }
        if(res.data?.shipping){
          setShipping(res.data.shipping)
          switch(res.data.shipping.status){
            case 1:
              setShippingStatus(0)
              break;
            case 2:
              setShippingStatus(1)
              break;
            case 3:
              setShippingStatus(1)
              break;
            case 4:
              setShippingStatus(2)
              break;
            default:
              setShippingStatus(-1)
          }
        }
        if(res.data?.products){
          setProducts(res.data.products)
        }
      }
    }).finally(() => {
      dispatch(endLoading());
    });
  }

  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, [orderUUID]);
  return <DetailComponent 
  handleView={handleView} 
  orderDetail={orderDetail}
  payment={payment}
  shipping={shipping}
  products={products}
  shippingStatus={shippingStatus}
  />;
}