import React from 'react';
import { Typography } from '@mui/material';
import icons from '../../../../assets/icons';

const DraggableVideoItem = ({ url, meta, handleRemove, disabled = false }) => (
  <div style={{ borderLeft: '2px solid', display: 'flex', alignItems: 'center', gap: '10px', opacity: disabled ? 0.5 : 1 }}>
    <img src={icons.more_02} style={{ marginLeft: '10px' }} alt='Drag handle' />
    <img
      width={'85px'}
      height={'56px'}
      src={meta.thumbnail_url}
      alt='Video thumbnail'
      style={{ objectFit: 'contain', flex: 1 }}
    />
    <span style={{ flex: 6, display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
      <span>
        <Typography component={'p'} variant='h4'>{meta.title}</Typography>
        <Typography component={'p'} variant='h5'>{url}</Typography>
      </span>
    </span>
    <img src={icons.delete_icon} alt='' onClick={disabled ? () => { } : handleRemove} />
  </div>
);

export default DraggableVideoItem;