import React, { useEffect, useState } from 'react'
import GalleryComponent from '../../../../landing-page/gallery/gallery.component'
import FileHelper from '../../../../../lib/helper/fileHelper';

export default function GalleryContainer({ gallery }) {
  const [itemData, setItemData] = useState<any[]>([])

  const setGallery = () => {
    if (gallery.content.length > 0) {
      Promise.all(gallery.content.map(async (value) => {
        const img = value.url.slice(0, 4) === 'blob' ? value.url : await FileHelper.getUrl(value.url)
        return {
          sort: value.sort,
          img: img,
          title: img
        }
      })).then(galleryData => setItemData(galleryData.sort((a, b) => a.sort - b.sort)));
    }
  }

  useEffect(() => {
    setGallery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gallery])

  return gallery.active ? <GalleryComponent itemData={itemData} /> : <></>
}