import axios from "axios";
import { EditVoucherRequest } from "../../lib/types/Voucher";
interface Data{
  code: number,
  data:  any,
  message: string | null,
  success: boolean
}

const editVoucher = (voucher : EditVoucherRequest): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;
  
  return new Promise((resolve, reject) => {
      axios.post("campaign/editVouchers",voucher)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default editVoucher;
