import React from "react";
import {Box} from "@mui/material"
export default function FormBox({children}) {
  return <Box
  sx={{ 
    padding: '2rem 2rem 2rem 2rem',
    borderRadius:"8px",
    background:"#FFF",
    border: '1px solid #C5C5C5',
    mb:"1rem"
    }}>
  {children}
  </Box>;
}