import { createSlice } from "@reduxjs/toolkit";
import { LANDING_PAGE } from "../../lib/constants/slices";
import { getActiveProfileByEncode,getProfile,getProfileWithCard } from "./landingPage.action";
import accessType from "src/lib/constants/accessType";
const initialState = {
  isLoading: true,
  accessType: 'web',
  accessPath: '/w',
  profile: {
    user_uuid: "",
    profile_uuid: "",
    public_name: '',
    serial_number: '',
    profileName: "",
    profileActive: false,
    user_details: {
      full_name: "",
      job_title: '',
      company_image: '',
      description: '',
      profile_image: ''
    },
    contact_info: {
      active: false,
      content: []
    },
    social_info: {
      active: false,
      content: []
    },
    gallery: {
      active: false,
      content: []
    },
    file: {
      active: false,
      content: []
    },
    video: {
      active: false,
      content: []
    },
    custom_component: [],

  },
  // Default theme for  profile
  theme:{
    name: 'DEFAULT',
    highlight:{
      primary_color: '#FECD20',
      primary_font_color: '#000000',
      secondary_color: '#373736',
      secondary_font_color: '#FECD20',
    },
    button:{
      primary_color: '#FECD20',
      primary_font_color: '#000000',
      secondary_color: '#373736',
      secondary_font_color: '#FECD20',
    },
    primary_background: '#FECD20',
    primary_font_color: '#000000',
    secondary_background: '#373736',
    secondary_font_color: '#FECD20',
  },
  background_color: '',
  initialBgColor: '',
  initialTheme: {}
};

const landingPageSlice = createSlice({
  name: LANDING_PAGE,
  initialState,
  reducers: {
    setProfile(state, action) {
      const { payload } = action;
      state.profile = payload;
      state.theme = payload?.theme || initialState.theme
      state.background_color = payload?.background_color || initialState.background_color
      // state.initialBgColor = payload?.background_color || initialState.initialBgColor
    },
    setTheme(state, action) {
      const { payload } = action;
      state.theme = payload;
      if (state.initialTheme) {
        if (Object.keys(state.initialTheme).length === 0) {
          state.initialTheme = payload;
        }  
      }
    },
    setThemeBackground(state, action) {
      const { payload } = action;
      state.background_color = payload;
      if (state.initialBgColor === '') {
        state.initialBgColor = payload;
      }
    },
    setInitialThemeBackground(state, action) {
      const { payload } = action;
      state.initialBgColor = payload;
    },
    setInitialTheme(state, action){
      const { payload } = action;
      state.initialTheme = payload;
    },
    setAccessType(state,action){
      const {payload} = action;
      state.accessType =  payload;
      accessType.CARD === state.accessType ? state.accessPath = '/c' :state.accessPath = '/w'
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveProfileByEncode.fulfilled, (state, action) => {
      const { payload } = action
      state.isLoading = false;
      const profile = payload.data || initialState.profile
      state.profile = profile
      state.theme = profile?.theme || initialState.theme

    });
    builder.addCase(getActiveProfileByEncode.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getProfile.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getProfile.fulfilled, (state, action) => {
      const { payload } = action
      state.isLoading = false;
      const profile = payload.data || initialState.profile
      state.profile = profile
      state.theme = profile?.theme || initialState.theme
      state.background_color = profile?.background_color || initialState.background_color
      // state.initialBgColor = profile?.background_color || initialState.initialBgColor
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(getProfileWithCard.fulfilled, (state, action) => {
      const { payload } = action
      state.isLoading = false;
      const profile = payload.data || initialState.profile
      state.profile = profile
      state.theme = profile?.theme || initialState.theme
      state.background_color = profile?.background_color || initialState.background_color
    });
    builder.addCase(getProfileWithCard.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setProfile,
  setAccessType,
  setTheme,
  setThemeBackground,
  setInitialThemeBackground,
  setInitialTheme,
} = landingPageSlice.actions;

export const landingPageReducer = landingPageSlice.reducer;
