import React, { useEffect, useState } from 'react'
import CompletedComponent from "./completed.component";
import accountApi from '../../../../api/account';
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { EXPIRED, PORTAL_CARDS_BUY, SIGN_IN } from '../../../../lib/constants/routes';
import useQuery from '../../../../lib/helper/queryHelper';
import { useDispatch, useSelector } from 'react-redux';
import { openResponseDialog } from '../../../../features/response-dialog';

export default function Completed() {
  const { state } = useLocation();
  const [verifyFail, setVerifyFail] = useState(false)
  const dispatch = useDispatch();
  const isResponseDialogOpen = useSelector((state: any) => state.responseDialog.open);
  let query = useQuery();
  const email = query.get("email") || ""
  const email_verification_token = query.get("email_verification_token") ?? ''
  const navigate = useNavigate();

  async function openCreateProfile() {
    await checkSession();
  }

  const checkSession = async () => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')

    if (user && user.session_id && user.session_expiration) {
      await accountApi.checkSession({
        session_id: user.session_id,
        session_expiration: user.session_expiration
      }).then(response => {
        if (response.success) {
          localStorage.setItem("user", JSON.stringify(response.data));
          navigate(PORTAL_CARDS_BUY)
        } else {
          navigate(SIGN_IN)
        }
      }).catch(error => {
        navigate(SIGN_IN)
      })

    } else {
      navigate(SIGN_IN)
    }
  }

  const verifyEmail = async () => {
    const response = await accountApi.verifyEmail({
      email,
      email_verification_token,
    }).then(response => response).catch(error => console.log(error))
    if (response) {
      if (response?.success) {
        setVerifyFail(false)
      } else {
        setVerifyFail(true)
        dispatch(openResponseDialog({ title: "Email verification failed", description: response.message }))
      }
    }

    return response
  }

  useEffect(() => {
    verifyEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (verifyFail && !isResponseDialogOpen) {
      navigate({
        pathname: EXPIRED,
        search: createSearchParams({ email }).toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResponseDialogOpen]);

  return <CompletedComponent
    // verifyFail={verifyFail} 
    openCreateProfile={openCreateProfile}
    expDate={state?.exp_date || null}
  />
}