import React,{useState} from 'react';
import { Box, Typography,Slider,SliderThumb } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import Barcode from 'react-barcode';
import styled from "@emotion/styled";
import icons from "../../../../../assets/icons";
import { useTheme } from '@mui/material/styles';
interface PixelThumbComponentProps extends React.HTMLAttributes<unknown> { }

function PixelThumbComponent(props: PixelThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <div className='icon' style={{
        height: '20px',
        width: '21px',
        mask: `url(${icons.slider_thumb})`,
        WebkitMask: `url(${icons.slider_thumb})`,
      }} />
    </SliderThumb>
  );
}

const VoucherSlider = styled(Slider)(({ theme }) => ({
  pointerEvents: 'none',
  color: '#FECD20',
  height: 48,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 48,
    width: 48,
    backgroundColor: '#fff',
    pointerEvents: 'all',
  },
  '& .MuiSlider-thumb .icon': {
    background: 'linear-gradient(to right, #FFECAB, #FECD20, #FFECAB, #FECD20)',
    backgroundSize: '400% 400%',
    animation: 'gradient 1s ease infinite',
  },
  "@keyframes gradient": {
    "0%": { backgroundPosition: 'left' },
    "50%": { backgroundPosition: 'center' },
    "100%": { backgroundPosition: 'right' },
  },
  '& .MuiSlider-rail': {
    opacity: 0,
  },
  // Change default disabled grey color to pixel yellow color
  '& .MuiSlider-track':{
    color:'#FECD20'
  }

}));

export default function NoValidation ({voucher,noValidationRedeem}:{voucher:any,noValidationRedeem:({voucherCode}:{voucherCode:string}) => void}) {
  const [sliderValue, setSliderValue] = useState(0);
  const theme = useTheme();
  return <>
    <Box sx={{
      display:'flex',
      justifyContent:'center',
    }}>  
    <Box sx={{ mx: '45px', height: '88px',  width: '70%',position: 'relative' }}>
      <Box sx={{
        width: 'calc(100% + 58px)',
        height: 'calc(100% - 30px)',
        position: 'absolute',
        left: '-29px',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: '15px',
        bgcolor: '#FECD20',
        flexDirection: 'column',
      }}>
        {
          sliderValue < 100 ? 
          <>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>Your coupon code</Typography>
            <Typography sx={{ fontSize: 16 }}>{voucher.code}</Typography>
          </>
          :  <Typography sx={{ fontSize: 16,zIndex:'100' }}>Redeemed</Typography>
        }

      </Box>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <VoucherSlider
          slots={{ thumb: PixelThumbComponent }}
          value={sliderValue}
          disabled={sliderValue === 100}
          onChange={(e, val) => {
        
            if(sliderValue < 100){
              setSliderValue(Number(val.toString()))
            }
            if(val === 100){
              noValidationRedeem({voucherCode:voucher.code})
            }
          }}
          onChangeCommitted={(e, value) => {
            if (value === 100) {

            } else {
              setSliderValue(0);
            }
          }}
        />
      </Box>
    </Box>
        </Box>
        <Box sx={{
          [theme.breakpoints.down('sm')]: {
            left:'-13%',
          },
          [theme.breakpoints.up('sm')]: {
            left:'-7%',
          },
          background:'#FECD20',
          borderRadius:'38px',
          width:'62px',
          height:'62px',
          position:'absolute',
          top:'40%'
        }}></Box>
        <Box sx={{
          [theme.breakpoints.down('sm')]: {
            right:'-13%',
          },
          [theme.breakpoints.up('sm')]: {
            right:'-7%',
          },
          background:'#FECD20',
          borderRadius:'38px',
          width:'62px',
          height:'62px',
          position:'absolute',
          top:'40%'
        }}></Box>
        <Box sx={{
          display:'flex',
          justifyContent:'center'
        }}>
        <Box
          sx={{
            [theme.breakpoints.up('sm')]: {
              border:' 1px dashed #C5C5C5',
            },
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
            width:'310px'
          }}
        >
            <Barcode format='CODE39' width="1" height="50" value={voucher.code} />
            <QRCode
                  size='150'
              // logoWidth="80"
              // logoHeight="80"
              // logoImage={companyImage}
              value={voucher.code}
              />

        </Box>
        </Box>
        </>
}