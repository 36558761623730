import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

interface userInfo{
  name: string,
  birthday: string ,
  phone_number: string 
}
const updateSocialSigninInfo = ({requestBody}: {requestBody: userInfo}): Promise<Data> => {

  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;
  
  return new Promise((resolve, reject) => {
    axios.post("auth/updateSocialSigninInfo",requestBody)
    .then(response => resolve(response.data))
    .catch(error => reject(error.response.data));
})
};

export default updateSocialSigninInfo;
