import axios from "axios";

interface RequestBody{
  email: string,
  password: string,
  remember_me: boolean,
  voucher_code: string,  
  langitude: number,
  latitude: number,
}
interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const signin = ({requestBody}:{requestBody:RequestBody}): Promise<Data> => {

  return new Promise((resolve, reject) => {
    axios.post("basicauth/signin",requestBody)
    .then(response => resolve(response.data))
    .catch(error => reject(error.response.data));
})
};

export default signin;
