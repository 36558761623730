import axios from "axios";

interface Data{
  code: number,
  data:  any,
  message: string | null,
  success: boolean
}

const getList = ({start, end, profileUuid}:{
  start: string | null,
  end: string | null,
  profileUuid: string
}): Promise<Data> => {

  const user = JSON.parse(localStorage.getItem('user') || '{}')
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  let query: Array<[string, string]> = [];
  if(start) query.push(['start_date', start]);
  if(end) query.push(['end_date', end]);
  if(profileUuid) query.push(['profile_uuid', profileUuid]);

  const params = new URLSearchParams(query);

    return new Promise((resolve, reject) => {
        axios.get(`myvoucher/getList`,{params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getList;
