import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Container,
  CssBaseline, Typography,
} from '@mui/material';
import icons from '../../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { PORTAL } from '../../../../lib/constants/routes';
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FFFFFF',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        }, 
      },
    },
  },
});


export default function ValidateVoucherComponent() {
  const navigate = useNavigate();
  const handleGoToWallet = () => {
    navigate(`${PORTAL}`);
  }
  return    <ThemeProvider theme={defaultTheme}>
  <Container component="main" maxWidth="xs"
    style={{ 
      width: '336px',
      height: '100vh',
      display: 'grid',
      alignContent: 'space-around',
      justifyContent: 'center'
    }}>
    <CssBaseline />
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width: 'max-content',
      alignItems: 'center',
      gap:'3rem'
    }}>
      <Box><img src={icons.validate_success.toString()} alt="validate_success"/></Box>
      <Box>
        <Typography variant="h3" fontWeight='500'>
          Successful verified Code!
        </Typography>
      </Box>
      <Box>
        <Button variant='contained' 
          sx={{
            width: '178px',
            height: '52px',
            padding: '16px, 20px, 16px, 20px',
            borderRadius: '30px',
            gap: '8px',
            color:'#FECD20'
          }}
          onClick={() => {handleGoToWallet()}}
        >
          Go To Dashboard
        </Button>
      </Box>
    </Box>
    </Container>
    </ThemeProvider>

}