import React, { useEffect, useState } from 'react'
import GalleryComponent from './gallery.component'
import { useSelector } from "react-redux";
import FileHelper from '../../../lib/helper/fileHelper';

export default function GalleryContainer() {
  const gallery = useSelector((state: any) => state.landingPage.profile.gallery)
  const [itemData, setItemData] = useState<any[]>([])

  const setGallery = () => {
    if (gallery.content.length > 0) {
      Promise.all(gallery.content.map(async (value) => {
        try{
          return {
            sort: value.sort,
            img: await FileHelper.getUrl(value.url),
            title: value.image_name
          }
        }catch (err) {

          return {
            sort: value.sort,
            img: null,
            title: value.image_name
          };
        }
      })).then(galleryData => setItemData(galleryData.sort((a, b) => a.sort - b.sort)));
    }
  }
  useEffect(() => {
    setGallery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gallery])

  return gallery.active ? <GalleryComponent itemData={itemData} /> : <></>
}