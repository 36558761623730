import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const memberToMemberExchange = ({
  ownerCardNo = null,ownerProfileNo = null,receiverProfileNo,
  longitude = 0,latitude = 0,ip_address = null,is_geolocation_allowed = false
}:{
  ownerCardNo: string | null,
  ownerProfileNo: string | null,
  receiverProfileNo: string,
  longitude: number | 0,
  latitude: number | 0,
  ip_address: string | null,  
  is_geolocation_allowed: boolean
}): Promise<Data> => {
  const storedUser = localStorage.getItem('user');

  if (storedUser === null) {
    return Promise.resolve({
      code: 404,
      data: null,
      message: 'Please log in',
      success: false,
    });
  }

  const user = JSON.parse(storedUser || '{}')
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;
  
  var params = new URLSearchParams([
    ['owner_card',ownerCardNo  || ''],
    ['owner_profile',ownerProfileNo || ''],
    ['receiver_profile',receiverProfileNo],
    ['longitude',longitude.toString() || ''],
    ['latitude',latitude.toString() || ''],
    ['ip_address',ip_address || ''],
    ['is_geolocation_allowed',is_geolocation_allowed ? 'true' : 'false']
  ]);
    
  return new Promise((resolve, reject) => {
      axios.get("contact/memberToMemberExchange", {params})
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default memberToMemberExchange;
