import React from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Button, Paper, Typography } from "@mui/material";
import CustomSwitch from "../customSwitch";
import DraggableDocItem from "../draggableDocItem";
import VisuallyHiddenInput from "../visuallyHiddenInput";
export default function FilesComponent({
  totalSections,
  order,
  reorderSection,
  docItems,
  setDocItems,
  isEnabledFiles,
  setIsEnabledFiles,
  getListStyle,
  getItemStyle,
  remove,
  handleDocChange,
  reorder
}) {
  return <Paper component={'section'} elevation={0} sx={{ mt: '26px', padding: '30px', borderRadius: '8.78px' }}>
    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '10px', marginBottom: '20px' }}>
      <select style={{ borderRadius: '5px', padding: '5px', borderColor: '#C5C5C5' }} defaultValue={order + 1} onChange={event => reorderSection(order, event.target.value)}>
        {Array(totalSections).fill(undefined).map((_, i) => <option key={i}>{i + 1}</option>)}
      </select>
      <Typography component='span' variant='h4' sx={{ flex: 1 }}>
        Files
      </Typography>
      <CustomSwitch checked={isEnabledFiles} onChange={e => setIsEnabledFiles(e.target.checked)} />
    </div>

    <Typography component='h5' variant='h5'>
      Upload PDF files
    </Typography>
    <Button
      component="label"
      variant="outlined"
      disabled={!isEnabledFiles}
      sx={{
        mt: '10px',
        mb: '10px',
        height: '40px',
        border: '1px solid #FECD20',
        borderRadius: '8px',
        color: 'black'
      }}>
      Upload file
      <VisuallyHiddenInput type="file" onChange={handleDocChange} accept='application/pdf' disabled={!isEnabledFiles} />
    </Button>
    <Typography component='h5' variant='h5' sx={{ color: '#AAAAAA' }}>
      Upload multiple files. Do not exceed 25MB.
    </Typography>

    <DragDropContext onDragEnd={function (result) {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      setDocItems(reorder(
        docItems,
        result.source.index,
        result.destination.index
      ));
    }}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {docItems.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!isEnabledFiles}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <DraggableDocItem file={item.file} file_name={item.file_name} handleRemove={() => setDocItems(remove(docItems, item.id))} disabled={!isEnabledFiles} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  </Paper>
}