import React,{useEffect} from 'react';
import {Button,Box,Container,Paper,CssBaseline, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {SELF_VALIDATION,NO_VALIDATION}  from "../../../../lib/constants/voucherValidationType";
import SelfValidation from './component/selfVadilation';
import NoValidation from './component/noValidation';
import redeemVoucherBg from './redeemVoucherBg.svg';
import { useTheme } from "@mui/material/styles";
import FileHelper from '../../../../lib/helper/fileHelper';
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h1: {
      fontSize: '42px', 
      fontWeight: 400,
    },
    h2: {
      fontSize: '35px', 
    },
    h3: {
      fontSize: '20px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '16px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
    }
  },
  }
  );
  
export default function RedeemVoucherComponent({voucher,handleDone,noValidationRedeem}:
  {
    voucher:any,
    handleDone:() => void,
    noValidationRedeem:({voucherCode}:{voucherCode:string}) => void
  }){
  
  const [backgroundImage, setBackgroundImage] = React.useState('' as string);
  const theme = useTheme();
  const getUrl = (url: string) => {
    const getUrl = new Promise((resolve)=>{
      FileHelper.getUrl(url).then((res) => {
        resolve(res)
      });
    })
    getUrl.then(res => {
      if(res) setBackgroundImage(res.toString())
      }
    );
  }
  useEffect(() => {
    getUrl(voucher.banner_image)
  }, [voucher.banner_image]);
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
    <Box style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      position: 'sticky',
      // overflow: 'scroll',
      flexDirection: 'column',
      minHeight: '100vh',
      padding:'3rem 0'
    }}>
    <Box sx={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      top:'-12%',
      zIndex: -1,
      background: `url(${redeemVoucherBg}) no-repeat center center`,
    }}></Box>
    <Paper sx={{
      [theme.breakpoints.down('sm')]: {
        width: '95%' 
      },
      [theme.breakpoints.down('md')]: {
        width: '80%', 
        maxWidth: '573px'
      },
      [theme.breakpoints.up('md')]: {
        width:"573px"
      },
      mb:'1rem',pb:'2rem'
      }}>
        <Box sx={{
          width:'100%',
          height:'299px',
          background: 'lightgray 50% / cover no-repeat',  
          backgroundImage: `url(${backgroundImage})`
        }}>
        </Box>
      <Container sx={{
        position:'relative',
      }}>

        <Box sx={{pt:'1rem',pb:'1rem'}}>
          <Typography variant="h3">
            {voucher.voucher_title}
            
          </Typography>
        </Box>
        {
          voucher.validation_type.toString() === SELF_VALIDATION.toString() && <SelfValidation voucher={voucher}/>
        }
        {
          voucher.validation_type.toString()  === NO_VALIDATION.toString() && <NoValidation noValidationRedeem={noValidationRedeem} voucher={voucher}/>
        }
        
      </Container>
    </Paper>
    <Box sx={{width:'inherit', mb:'4rem'}}>
    <Typography>Note: Once redeem, code unable reuse again</Typography>
    </Box>
    <Button
      onClick={() => handleDone()}
      variant="contained" sx={{
      borderRadius: '30px',
      color:'#FECD20',
      }}
      >
        Done
    </Button>
  </Box>

    </ThemeProvider>
  );
}