import React, { ReactNode } from 'react'
import './frame.css';
import icons from '../../../assets/icons';

export default function MobileFrame({ children, backgroundColor = '#FFF' }: { children: ReactNode; backgroundColor: string; }) {
  const time = new Date().toLocaleTimeString();

  return <div className='bezel'>
    <div className='screen' style={{ backgroundColor }}>
      <div className='bar'>
        <div className='clock'>{time.slice(0, time.charAt(1) === ':' ? 4 : 5)}</div>
        <div className='notch'>
          <div className='left sensor'></div>
          <div className='left sensor'></div>
          <div className='speaker'></div>
          <div className='right sensor'></div>
          <div className='right sensor'></div>
        </div>
        <div className='status'>
          <div className='icon' style={{
            width: '14px', height: '14px',
            mask: `url(${icons.connection_icon})`,
            WebkitMask: `url(${icons.connection_icon})`
          }} />
          <div className='icon' style={{
            width: '16px', height: '12px',
            mask: `url(${icons.wifi_icon})`,
            WebkitMask: `url(${icons.wifi_icon})`
          }} />
          <div className='icon' style={{
            width: '25px', height: '12px',
            mask: `url(${icons.battery_icon})`,
            WebkitMask: `url(${icons.battery_icon})`
          }} />
        </div>
      </div>
      {children}
    </div>
  </div>
} 