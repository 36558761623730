import React from 'react'
import LivePreview from "./livePreview.component";

export interface LivePreviewProps {
  profileName: string;
  isProfileActive: boolean;
  isEnabledAppointment: boolean;
  name: string;
  jobTitle: string;
  otherTitle: string;
  company: string;
  description: string;
  file: any;
  profileImageFullPath: string;
  companyImage: any;
  companyImageFullPath: string;
  isEnabledContactInfo: boolean;
  items: any[];
  isEnabledSocialInfo: boolean;
  socialItems: any[];
  isEnabledGallery: boolean;
  galleryItems: any[];
  isEnabledFiles: boolean;
  docItems: any[];
  isEnabledVideos: boolean;
  videoUrls: any[];
  componentInfo: any[];
  sections: any[];
  vouchers: any[];
}

export default function LivePreviewContainer(props: LivePreviewProps){
  const {
    profileName,
    isProfileActive,
    isEnabledAppointment,
    name,
    jobTitle,
    otherTitle,
    company,
    description,
    file,
    profileImageFullPath,
    companyImage,
    companyImageFullPath,
    isEnabledContactInfo,
    items,
    isEnabledSocialInfo,
    socialItems,
    isEnabledGallery,
    galleryItems,
    isEnabledFiles,
    docItems,
    isEnabledVideos,
    videoUrls,
    componentInfo,
    sections,
    vouchers,
  } = props;
  const profile = {
    "profile_name": profileName,
    "profile_active": isProfileActive,
    "appointment_status": isEnabledAppointment,
    "user_details": {
      "full_name": name,
      "job_title": jobTitle !== 'Others' ? jobTitle : otherTitle,
      "company_name": company,
      "description": description,
      "profile_image": file !== undefined ? typeof file === 'string' ? profileImageFullPath : URL.createObjectURL(file) : undefined,
      "company_image": companyImage !== undefined ? typeof companyImage === 'string' ? companyImageFullPath : URL.createObjectURL(companyImage) : undefined,
    },
    "contact_info": {
      "active": isEnabledContactInfo,
      "content": items.map(({ category, info }, index) => ({
        sort: index,
        category,
        info,
        style: [],
      })),
    },
    "social_info": {
      "active": isEnabledSocialInfo,
      "content": socialItems.map(({ category, info }, index) => ({
        sort: index,
        category,
        info,
        style: [],
      })),
    },
    "gallery": {
      "active": isEnabledGallery,
      "content": galleryItems.map(({ file, image_name, full_path }, index) => ({
        sort: index,
        image_name: image_name ?? file.name ?? '',
        url: file !== undefined ? typeof file === 'string' ? full_path : URL.createObjectURL(file) : undefined,
      })),
    },
    "file": {
      "active": isEnabledFiles,
      "content": docItems.map(({ file, file_name, full_path }, index) => ({
        sort: index,
        file_name: file_name ?? file.name ?? '',
        url: file !== undefined ? typeof file === 'string' ? full_path : URL.createObjectURL(file) : undefined,
      })),
    },
    "video": {
      "active": isEnabledVideos,
      "content": videoUrls.map(({ url }, index) => ({ sort: index, url }))
    },
    "custom_component": [...componentInfo],
  };

  return <LivePreview profile={profile} sections={sections} vouchers={vouchers} />
}
