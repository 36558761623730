import axios from "axios";

const resendGuestEmailVerification =  ({
    ownerCardNo,ownerProfileNo, email
}:{ownerCardNo:string,ownerProfileNo:string,email:string}) => {
    var params = new URLSearchParams([
        ['ownerCardNo', ownerCardNo], 
        ['ownerProfileNo', ownerProfileNo],
        ['email',email]]);
    return new Promise((resolve, reject) => {
        axios.get("basicauth/resendGuestEmailVerification", {params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
    
};

export default resendGuestEmailVerification;
