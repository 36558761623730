import React from "react";
import { Avatar, Button, Grid, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import jobTitles from "../../../../../lib/constants/jobTitles";
import VisuallyHiddenInput from "../visuallyHiddenInput";
export default function UserDetailsComponent({
    errors,
    name,
    description,
    otherTitle,
    company,
    companyImage,
    handleChange,
    setDescription,
    setOtherTitle,
    setCompany,
    file,    
    setName,
    jobTitle,
    setJobTitle,
    handleCompanyImageChange
}){
    return(
        <Paper elevation={0} sx={{ padding: '30px', borderRadius: '8.78px' }}>
        <Typography component='h4' variant='h4' >
          User Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component='label' htmlFor='name' className='required' variant='h5'>
              Full name
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              id="name"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <Typography component='label' className='validation-error' variant='h5'>
              {errors.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={jobTitle === 'Others' ? 6 : 12}>
            <Typography component='label' htmlFor='title' className='required' variant='h5'>
              Job Title
            </Typography>
            <Select
              id="jobTitle"
              value={jobTitle}
              sx={{
                marginTop: '0.6rem',
                '&:focus': {
                  border: 'solid #FECD20'
                },
              }}
              fullWidth
              onChange={e => setJobTitle(e.target.value)}
            >
              {
                jobTitles.map((value,index)=>{
                  return(
                    <MenuItem key={index} value={value}>{value}</MenuItem>
                  )
                })
              }
            </Select>
            <Typography component='label' className='validation-error' variant='h5'>
              {errors.job_title}
            </Typography>
          </Grid>
          {jobTitle === 'Others' && <Grid item xs={12} sm={6}>
            <Typography component='label' htmlFor='title' className='required' variant='h5'>
              Please specify job title
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              id="othertitle"
              name="othertitle"
              value={otherTitle}
              onChange={e => setOtherTitle(e.target.value)}
            />
            <Typography component='label' className='validation-error' variant='h5'>
              {errors.other_title}
            </Typography>
          </Grid>}
          <Grid item xs={12}>
            <Typography component='label' htmlFor='company' className='required' variant='h5'>
              Company Name
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              id="company"
              name="company"
              value={company}
              onChange={e => setCompany(e.target.value)}
            />
            <Typography component='label' className='validation-error' variant='h5'>
              {errors.company_name}
          </Typography>
          </Grid>
          
          <Grid item xs={12}>
            <Typography component='label' htmlFor='description' variant='h5'>
              Description
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              fullWidth
              multiline
              rows={4}
              id="description"
              name="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
            {/* <Typography component='label' className='validation-error' variant='h5'>
              {errors.description}
            </Typography> */}
          </Grid>

          <Grid item xs={12}>
            <Typography component='label' htmlFor='description' variant='h5'>
              Profile Image
            </Typography>
            <div style={{ display: 'flex' }}>
              <Avatar src={file !== undefined ? typeof file === 'string' ? file : URL.createObjectURL(file) : undefined} sx={{ width: 86, height: 86 }} />
              <Button
                component="label"
                variant="outlined"
                sx={{
                  margin: 3,
                  height: '40px',
                  border: '1px solid #FECD20',
                  borderRadius: '8px',
                  color: 'black'
                }}>
                Upload image
                <VisuallyHiddenInput type="file" id="profile_image" onChange={handleChange} accept="image/png, image/jpeg" />
              </Button>
            </div>
            <Typography component='h5' variant='h5' sx={{ color: '#AAAAAA' }}>
              Supported file type: PNG and JPEG only. Max file size: 12 MB
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography component='label' htmlFor='description' variant='h5'>
              Company Logo
            </Typography>
            <div style={{ display: 'flex' }}>
              <Avatar src={companyImage !== undefined ? typeof companyImage === 'string' ? companyImage : URL.createObjectURL(companyImage) : undefined} 
                sx={{ borderRadius: "0", width: 86, height: 86 }} imgProps={{ sx: { objectFit:'contain' } }} />
              <Button
                component="label"
                variant="outlined"
                sx={{
                  margin: 3,
                  height: '40px',
                  border: '1px solid #FECD20',
                  borderRadius: '8px',
                  color: 'black'
                }}>
                Upload image
                <VisuallyHiddenInput type="file" id="upload_image"  onChange={handleCompanyImageChange} accept="image/png, image/jpeg" />
              </Button>
            </div>
            <Typography component='h5' variant='h5' sx={{ color: '#AAAAAA' }}>
              Supported file type: PNG and JPEG only. Max file size: 12 MB
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    )
}