import axios from "axios";

interface Data{
  code: number,
  data:  any,
  message: string | null,
  success: boolean
}

const editProfileStatus = (profileUuid:String, active:boolean): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;
  
  return new Promise((resolve, reject) => {
      axios.post("profile/editProfileStatus",{profile_uuid: profileUuid, active: active.toString()})
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default editProfileStatus;
