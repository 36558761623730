import React from 'react';
import { styled } from '@mui/system';
import { Backdrop, Button, IconButton } from "@mui/material";
import { Box } from "@mui/material";
import icons from '../../../assets/icons';
import { useSelector } from 'react-redux';

const FloatingContainer = styled('div')({
  boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
  bottom: 0,
  position: 'sticky',
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
  justifyContent: 'space-evenly',
  backgroundColor: 'white',
  padding: '1rem',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  gap: '0.5rem',
});

export default function FloatingButtonCommponent({
  isEnabledAppointment,
  handleSaveContact,
  handleShareQR,
  handleBookAppointment,
  handleExchangeContact,
}) {
  const [open, setOpen] = React.useState(false);
  const profileTheme = useSelector((state: any) => state.landingPage.theme);

  const handleOptionClick = (option) => {
    setOpen(!open)
  };

  const addIconStyle = {
    transform: !open ? 'rotate(0deg)' : 'rotate(225deg)',
    background: !open ? (profileTheme?.button?.primary_color || '#FECD20') : (profileTheme?.button?.secondary_color || '#373736'),
    color: !open ? (profileTheme?.button?.primary_font_color || 'black') : (profileTheme?.button?.secondary_font_color || '#FECD20'),
    transition: 'all 150ms',
    '&:hover': {
      color: profileTheme?.button?.primary_color || 'white',
    },
    '& .icon': {
      background: !open ? 'black' : 'white',
    },
    '&:hover .icon': {
      background: profileTheme?.button?.secondary_color || 'black',
    },
  };
  const connectButtonIconStyle = {
    height: '24px',
    width: '24px',
    mask: `url(${icons.connect_landingpage_icon})`,
    WebkitMask: `url(${icons.connect_landingpage_icon})`,
    background: profileTheme?.button?.secondary_font_color
  };
  const iconStyle = {
    height: '24px',
    width: '24px',
    WebkitMask: `url(${icons.plus_icon})`,
    mask: `url(${icons.plus_icon})`,
    // icon width + both margin + IconButton both padding = 56px
    // 24px + 2(8)px + 2(8)px = 56px
    background: !open ? profileTheme?.button?.primary_font_color || 'black' : profileTheme?.button?.secondary_font_color || '#FECD20',
    margin: '8px',
  };
  const actionButtonStyle = {
    '&:hover': { backgroundColor: (profileTheme?.button?.primary_color || '#FECD20') },
    whiteSpace: 'nowrap',
    borderRadius: '30px',
    p: '10px 20px',
    background: (profileTheme?.button?.primary_color || '#FECD20'),
    color: (profileTheme?.button?.primary_font_color || 'black'),
  };

  const connectButtonStyle = {
    '&:hover': { backgroundColor: profileTheme?.button?.secondary_color || '#373736' },
    backgroundColor: (profileTheme?.button?.secondary_color || '#373736'),
    flex: 1,
    height: '56px',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '24px',
    borderRadius: '30px',
    color: (profileTheme?.button?.secondary_font_color || '#FECD20'),
    gap: '1rem',
    textTransform: 'none',
  };

  return <>
    <Backdrop open={open} sx={{ backdropFilter: 'blur(3px)' }} onClick={() => setOpen(false)} />
    <FloatingContainer>
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={handleOptionClick} sx={addIconStyle}>
          <div className='icon' style={iconStyle}></div>
        </IconButton>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          gap: '10px',
          top: isEnabledAppointment ? '-180px' : '-125px',
          alignItems: 'flex-start',
        }}>
          {open &&
            <Button startIcon={<div style={{
              height: '24px', width: '24px',
              mask: `url(${icons.user_add})`,
              WebkitMask: `url(${icons.user_add})`,
              background: profileTheme?.button?.primary_font_color
            }} />} sx={actionButtonStyle} onClick={handleSaveContact}>Save Contact</Button>
          }
          {open &&
            <Button startIcon={<div style={{
              height: '24px', width: '24px', mask: `url(${icons.qr_code})`,
              WebkitMask: `url(${icons.qr_code})`,
              background: profileTheme?.button?.primary_font_color
            }} />} sx={actionButtonStyle} onClick={handleShareQR}>Share QR Code</Button>
          }
          {isEnabledAppointment && open &&
            <Button startIcon={<div style={{
              height: '24px', width: '24px', mask: `url(${icons.calendar})`,
              WebkitMask: `url(${icons.calendar})`,
              background: profileTheme?.button?.primary_font_color
            }} />} sx={actionButtonStyle} onClick={handleBookAppointment}>Book Appointment</Button>
          }
        </Box>
      </Box>
      <Button
        type="button"
        fullWidth
        variant="contained"
        startIcon={<div className='icon' style={connectButtonIconStyle} />}
        sx={connectButtonStyle}
        onClick={handleExchangeContact}
      >
        Let's Connect
      </Button>
    </FloatingContainer>
  </>
}