import axios from "axios";

interface Data{
  code: number,
  data:  any,
  message: string | null,
  success: boolean
}

const details = ({userVoucherUUID}:{userVoucherUUID:string}): Promise<Data> => {

  const user = JSON.parse(localStorage.getItem('user') || '{}')
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  const params = userVoucherUUID ? new URLSearchParams([['user_voucher_uuid',userVoucherUUID]]) : {};
    return new Promise((resolve, reject) => {
        axios.get(`myvoucher/details`,{params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default details;
