import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const updateViewCount = ({user_type,profile_uuid,       
  latitude,
  longitude,
  ip_address,
  is_geolocation_allowed
}): Promise<Data> => {
  const requestBody = {
    user_type : user_type,
    profile_uuid: profile_uuid,
    latitude: latitude,
    longitude: longitude,
    ip_address: ip_address,
    is_geolocation_allowed: is_geolocation_allowed
  }
  return new Promise((resolve, reject) => {
    axios.post("basicdashboard/updateViewCount",requestBody)
    .then(response => resolve(response.data))
    .catch(error => reject(error.response.data));
})
};

export default updateViewCount;
