import { Box, Button, Card, CardActions, CardContent, Link, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

interface SubscriptionColumnProps {
  tier?: any;
  currency: string;
  status: string;
  handleSubmit?: () => void;
  buttonText: string;
  handleUnsubscribe?: () => void;
  variant: "outlined" | "contained";
}

export default function SubscriptionColumn({
  tier,
  currency,
  status,
  handleSubmit,
  buttonText,
  handleUnsubscribe,
  variant,
}: SubscriptionColumnProps) {
  return <Box
    sx={{
      flex: 1,
      minWidth: '330px',
      maxWidth: '370px',
    }}
  >
    <Card
      sx={{
        borderRadius: '13px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box sx={{ background: tier.background, color: 'white', py: '40px' }}>
        <Box sx={{
          ml: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }}>
          {tier.is_recommended && <Box sx={{
            position: 'absolute',
            bgcolor: '#0DBD3F',
            top: '-30px',
            left: '40px',
            width: 'calc(100% - 100px)',
            p: '2px 30px',
            borderRadius: '8px',
            textAlign: 'center',
            border: '1px solid white',
            boxSizing: 'border-box',
          }}>
            RECOMMENDED
          </Box>}
          <Typography sx={{ fontSize: '32px', fontWeight: 800 }} gutterBottom>
            {tier.title}
          </Typography>
          <Box sx={{
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            fontSize: '13px',
            color: '#617271',
            bgcolor: '#FFFFFF',
            p: '5px 10px',
            display: 'flex',
            alignItems: 'center',
          }}>
            <PersonIcon />
            {tier.user}
          </Box>
        </Box>
        <Box sx={{ ml: '20px' }}>
          {tier.title !== 'Business' && <Typography component="span" sx={{ fontSize: '32px', fontWeight: 800 }}>
            {currency} {status === 'monthly' ? tier.price : tier.yearly_price}
          </Typography>}
          {tier.title === 'Business' ? <Typography component="span" sx={{ fontSize: '32px' }}>
            Contact Us
          </Typography> : <Typography component="span" sx={{ fontSize: '20px', ml: '5px' }}>
            /mo
          </Typography>}
        </Box>
        <Typography sx={{ ml: '20px' }}>
          {tier.subtitle}
        </Typography>
      </Box>
      <CardContent sx={{ flex: 1, px: '30px', pt: '30px' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>{tier.summary}</Typography>
        <Box
          sx={{
            pt: 2,
            pb: 1,
            display: 'flex',
            gap: 1.5,
            alignItems: 'center',
          }}
        >
          <CheckCircleOutlineIcon
            sx={{
              width: 16,
              color: 'transparent',
            }}
          />
          <Typography
            variant="subtitle2"
            fontWeight={700}
            color={'#373736'}
          >
            {tier.desc_title}
          </Typography>
        </Box>
        {tier.description.map((line: string) => (
          <Box
            key={line}
            sx={{
              py: 1,
              display: 'flex',
              gap: 1.5,
              alignItems: 'center',
            }}
          >
            <CheckCircleOutlineIcon
              sx={{
                width: 16,
                color: 'primary.main',
              }}
            />
            <Typography
              variant="subtitle2"
              color={'#373736'}
            >
              {line}
            </Typography>
          </Box>
        ))}
      </CardContent>
      <CardActions sx={{ p: '30px', justifyContent: 'center' }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
          {Boolean(buttonText) && <Button
            fullWidth
            disabled={!Boolean(handleSubmit)}
            variant={variant}
            color='secondary'
            onClick={handleSubmit}
          >
            {buttonText}
          </Button>}
          {handleUnsubscribe && <Link underline="hover" sx={{ fontSize: '12px', color: "#007AFF" }} href='#' onClick={handleUnsubscribe}>
            Cancel Auto-Renewal
          </Link>}
        </Box>
      </CardActions>
    </Card>
  </Box>;
}