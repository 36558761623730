import React from "react";
import UserDetailsComponent from "./userDetails.component";

export default function UserDetailsContainer({
    errors,
    name,
    description,
    otherTitle,
    company,
    companyImage,
    handleChange,
    setDescription,
    setOtherTitle,
    setCompany,
    file,
    setName,
    jobTitle,
    setJobTitle,
    handleCompanyImageChange
}){
    return(
        <div>
            <UserDetailsComponent
                errors={errors}
                name={name}
                description={description}
                otherTitle={otherTitle}
                company={company}
                companyImage={companyImage}
                handleChange={handleChange}
                setDescription={setDescription}
                setOtherTitle={setOtherTitle}
                setCompany={setCompany}
                file={file}
                setName={setName}
                jobTitle={jobTitle}
                setJobTitle={setJobTitle}
                handleCompanyImageChange={handleCompanyImageChange}
            />
        </div>
    )
}