import React, {useEffect} from 'react';
import { createTheme, ThemeProvider,styled } from '@mui/material/styles';
import icons from '../../../../assets/icons';
import images from '../../../../assets/img';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { ScrollMenu,publicApiType } from 'react-horizontal-scrolling-menu';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Container,
  CssBaseline,
  Paper,
  Typography
} from '@mui/material';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#FECD20',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  }
});


  const NoScrollbar = styled('div')({
    '& .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar': {
      display: 'none',
    },
    '& .react-horizontal-scrolling-menu--scroll-container': {
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
    },
  });

  function onWheel(apiObj: publicApiType, ev: React.WheelEvent): void {
    // NOTE: no good standart way to distinguish touchpad scrolling gestures
    // but can assume that gesture will affect X axis, mouse scroll only Y axis
    // of if deltaY too small probably is it touchpad
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  
    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }
  
    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

const ProfileOptionComponent = ({profileList,handleSelected,isLoading}:{
  profileList: Array<any>,
  isLoading: boolean,
  handleSelected: ({ serialNumber }: { serialNumber: string; }) => Promise<void>}) => {
  const [profileSelected, setProfileSelected] = React.useState<any>(
    profileList[0]
  );
  const isProfileSelected = (profileUUID: string): boolean => {
    if(profileSelected){
      return profileSelected?.profileUUID === profileUUID;
    }
    return false
  }
  const handleProfileClick = (profileUUID: string) => {
    setProfileSelected(profileList.find((profile) => profile.profileUUID === profileUUID))
  };
  
  useEffect(() => {
    if(profileList.length > 0){
      setProfileSelected(profileList[0])
    }
   }, 
  [profileList]);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" style={{
        height: '100vh',
        display: 'grid',
        alignContent: 'space-around',
        justifyContent: 'center',
        backgroundColor: '#FECD20',
        }}>
        <CssBaseline />
        <Paper elevation={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            justifyContent: 'center',
            padding: '1.3rem',
            borderRadius: '8.78px',
            width: "inherit",
            maxWidth: '335px',
          }}
        >
        <div style={{ 
          margin: '20px',
          display: 'flex',
          justifyContent:'center' 
          }}>
          <img src={images.pxlicon.toString()} style={{maxWidth:'133px',maxHeight:"33"}} alt="Pixel Logo"/>
            {/* <img src={pixel_logo} alt='PixelCard logo'/> */}
        </div> 
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent:'center',
          alignItems: 'center'
        }}>   
          <Typography variant="h4"> Soon connected</Typography>
          <Typography sx={{fontSize:"14px", fontWeight:'400'}}> Select your profile decide to exchange</Typography>
        </Box>
        <Box>
        <NoScrollbar style={{paddingTop:"22px"}}>
              <ScrollMenu
                onWheel={onWheel}
              >
                {profileList.map(({ profileUUID,name,profileName,profilePicture,companyName},index) => (
                  <ProfileCard
                    key={index}
                    name={name}
                    profileName={profileName}
                    profilePicture={profilePicture}
                    profileUUID={profileUUID}
                    companyName={companyName}
                    isProfileSelected={isProfileSelected}
                    selected={isProfileSelected(profileUUID)}
                    handleProfileClick={handleProfileClick}
                  />
                ))}
              </ScrollMenu>
            </NoScrollbar>
        </Box>
        {/* <Button variant="contained" color="primary"
          onClick={() => 
          handleSelected(profileSelected)}> Select </Button> */}
        <LoadingButton
          id="select-button"
          fullWidth
          variant="contained"
          loading={isLoading}
          loadingPosition="start"
          onClick={() => 
            handleSelected(profileSelected)}
          startIcon={isLoading ? <div /> : undefined}
          sx={{ mt: 3, mb: 2, borderRadius: '30px', color: '#FECD20' }}
        >
          <span>{isLoading ? 'Collecting' : 'Select'}</span>
        </LoadingButton>
        <Box sx={{height:'2rem'}}></Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

function ProfileCard({name,profileName,profilePicture,profileUUID,companyName,isProfileSelected,selected,handleProfileClick}: 
  {
    name: string,
    profileName: string,
    profilePicture: string,
    profileUUID: string,
    companyName: string,
    selected: boolean,
    isProfileSelected: (profileUUID:string) => boolean, 
    handleProfileClick: (profileUUID: string) => Promise<void>
  }) {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent:'center',
      alignItems: 'center',
      width: "280px",

    }}> 
    <Box 
      onClick={() => handleProfileClick(profileUUID)}
    sx={{
      width: "270px",
      height: "170px",
      borderRadius: '16px',
      border: '1px solid var(--Secondary-Color, #FECD20)',
      background: '#FFF',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '&:hover':{
        border: '2px solid var(--Secondary-Color, #FECD20)',
      },
      '$:touch':{
        border: '2px solid var(--Secondary-Color, #FECD20)',
      }
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        pt: '0.8rem',
        pr: '0.8rem',
        justifyContent: 'flex-end',
      }}>
       <img alt="checkmark" src={icons.checkmark_icon} style={{visibility: isProfileSelected(profileUUID)? 'visible': 'hidden'}}/>
      </Box>
      <img  style={{width:"72px",height:"72px",borderRadius: "79.5px"}}src={profilePicture ? profilePicture: icons.profile_picture_icon} alt="profile_picture_icon"/>
      <Typography sx={{pt:"16px", fontSize:"13px"}}>{name}</Typography>
      <Typography sx={{fontSize:"13px",color: '#A7A7A7', fontWeight:"400"}}>{companyName}</Typography>
    </Box>
      <Typography variant="h4" sx={{fontWeight:"400",pt:"46px",pb:"5rem" }}>{profileName}</Typography>
    </Box>
  );
}

export default ProfileOptionComponent;
