import React from 'react'
import { styled } from '@mui/system';
import { Box,Button,Dialog , DialogTitle,Typography} from '@mui/material';

const DialogMessage = styled('div')({
  
});

const DialogActions = styled('div')({
  display: 'flex',
  gap: '1rem',
  padding: '0 1rem',
  justifyContent: 'center' 
})

export default function ConfirmDialogCompontent(
  {handleClose,handleListItemClick,handleConfirm ,open}:{
    handleClose:any,handleListItemClick:any,handleConfirm:any ,open:any
  }
){

  return (
    <Dialog fullWidth  onClose={handleClose} open={open}>
      <DialogTitle style={{display:'flex',flexDirection: 'row',justifyContent: 'center'}}>
        <Typography style={{
          fontSize: '31px',
          fontStyle: 'normal',
          fontWeight: '500',
          textAlign: 'center'
        }}>Save Changes ?</Typography> 
        </DialogTitle>
        <Box >
      <DialogMessage >
      <Typography align="center">
      Your unsaved changes will be lost.<br/> 
      Save changes before close?
      </Typography>
      
      </DialogMessage>
      <DialogActions>
      <Button
          type="button"
          fullWidth
          onClick={() => handleClose(true)}
          variant="outlined"
          style={{ gridColumn: '1', gridRow: '1'}}
          sx={{ mt: 3, mb: 2, maxWidth:"150px",borderRadius: '30px', color: 'black',border: '1px solid #FECD20', }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          fullWidth
          onClick={() => handleConfirm()}
          variant="contained"
          style={{ gridColumn: '2', gridRow: '1'}}
          color="primary"
          sx={{ mt: 3, mb: 2, maxWidth:"150px",borderRadius: '30px'}}
        >
          Yes
        </Button>
      </DialogActions>
      </Box>
    </Dialog>
  );
}