import axios from "axios";

interface Data{
  code: number,
  data:  {
    new_contacts: boolean
  },
  message: string | null,
  success: boolean
}

const noValidateRedeem = ({voucher_code}:{voucher_code : string}): Promise<Data> => {

  const user = JSON.parse(localStorage.getItem('user') || '{}')
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;
  
  const params = new URLSearchParams([['voucher_code', voucher_code  ]]);

    return new Promise((resolve, reject) => {
        axios.get(`myvoucher/noValidateRedeem`,{params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default noValidateRedeem;
