import React, { useEffect, useState } from 'react';
import accountApi from '../../../../api/account';
import { useLocation } from "react-router-dom";
import { useNavigate, createSearchParams } from "react-router-dom";
import { openResponseDialog } from '../../../../features/response-dialog';
import { useDispatch, useSelector } from 'react-redux';
import { COMPLETED, CONTACT, EXPIRED, SIGN_IN } from '../../../../lib/constants/routes';
import Pricing from './pricing.component';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container } from '@mui/material';
import moment from 'moment';
import subscriptionApi from '../../../../api/subscription';
import { endLoading, startLoading } from '../../../../features/loading';
import campaignApi from '../../../../api/campaign';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#FECD20',
    },
    secondary: {
      main: '#373736',
      contrastText: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h1: {
      fontSize: '42px',
      fontWeight: 400,
    },
    h2: {
      fontSize: '35px',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 500,
    },
    h4: {
      fontSize: '16px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#FFF',
          backgroundColor: '#3C3C3B',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        // root: {
        //   fontSize: '13px',
        //   fontWeight: 500,
        // }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "30px"
        }
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between'
        }
      }
    },
  },
});

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function PricingContainer() {
  const [verifyFail, setVerifyFail] = useState(false)
  const dispatch = useDispatch();
  const isResponseDialogOpen = useSelector((state: any) => state.responseDialog.open);
  const expDate = moment().add(7, 'd').format('YYYY-MM-DD');
  let query = useQuery();
  const email = query.get("email") || ""
  const email_verification_token = query.get("email_verification_token") ?? ''
  const voucherUUID = query.get("voucher_uuid") || ""
  const ownerProfileUUID = query.get("owner_profile_uuid") || ""
  const navigate = useNavigate();

  const checkSession = async () => {
    const user = JSON.parse(localStorage.getItem('user') ?? '{}')

    if (user && user.session_id && user.session_expiration) {
      const response = await accountApi.checkSession({
        session_id: user.session_id,
        session_expiration: user.session_expiration
      })

      if (response.success) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
    } else {
      navigate(SIGN_IN)
    }
  }

  async function handleFree() {
    navigate(
      {
        pathname: COMPLETED,
        search: createSearchParams({ email, email_verification_token }).toString(),
      }
    );
  }

  async function handleSubscribe(expiration_date?: string) {
    dispatch(startLoading());
    await checkSession().then(async (response) => {
      
      await subscriptionApi.subscribeFreeTrial()
        .then(response => {
          if(response.success){
            const exp_date = response.data.end_date || expiration_date;
              navigate(
              {
                pathname: COMPLETED,
                search: createSearchParams({ email, email_verification_token }).toString(),
              },
              { state: { exp_date } },
            );
            return true
          }
          dispatch(openResponseDialog({ title: "Subscription failed", description: response.message }))
        })
        .catch(error => {
          dispatch(endLoading());
          dispatch(openResponseDialog({ title: "Subscription failed", description: error.message }))
        }).finally(() => {
          dispatch(endLoading());
        })

    }).catch(error => {
      navigate(SIGN_IN)
    })


  }

  function handleContact() {
    navigate(`/${CONTACT}`);
  }

  const collectVoucherCode = async () => {
    dispatch(startLoading());
    await campaignApi.collectVoucherCode({
      voucher_uuid: `${voucherUUID}`,
      voucher_owner_profile: ownerProfileUUID,
      user_profile: null,
    }).then(response => {
      if (response.success) {
        dispatch(openResponseDialog({ title: "Voucher Collection Success", description: "Select a subscription plan to complete account sign up." }))
        return
      } else {
        dispatch(openResponseDialog({
          title: "Voucher Collection Failed",
          description: response.message
        }));
        return 
      }
    }).catch(error => {
      dispatch(openResponseDialog({
        title: "Voucher Collection Failed",
        description: error.message
      }));
      return 
    }).then(() => {
      dispatch(endLoading());
    });
  }

  useEffect(() => {
    const verifyEmail = async () => {
      await accountApi.verifyEmail({
        email,
        email_verification_token,
      }).then(response => {
          if (!response?.success) {
            dispatch(openResponseDialog({ title: "Email verification failed", description: response.message }))
          }
          setVerifyFail(false)
          return 
      }).catch(error => {
        setVerifyFail(true)
        dispatch(openResponseDialog({ title: "Email verification failed", description: error.message }))
        return 
      })

      return 
    }
    verifyEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(voucherUUID && ownerProfileUUID && !verifyFail){
      collectVoucherCode()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [voucherUUID, ownerProfileUUID,verifyFail])
  useEffect(() => {
    if (verifyFail && !isResponseDialogOpen) {
      navigate({
        pathname: EXPIRED,
        search: createSearchParams({ email }).toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyFail,isResponseDialogOpen])
   
  return <Container sx={{ p: '30px' }}>
    <ThemeProvider theme={defaultTheme}>
      <Pricing
        expDate={expDate}
        handleFree={() => handleFree()}
        handleSubscribe={() => handleSubscribe(expDate)}
        handleContact={handleContact}
        handleTrial={() => handleSubscribe(expDate)}
      />
    </ThemeProvider>
  </Container>
}