import React from 'react';
// import { styled } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Divider, Typography } from "@mui/material";
import images from '../../../../../assets/img';
// import icons from '../../../../../assets/icons';
import { useDispatch, useSelector } from 'react-redux';
// import { BOOK_APPOINTMENT, BOOK_APPOINTMENT_CONFIRMED } from '../../../../../lib/constants/routes';
import moment from 'moment';
import { clearAppointmentRequest } from '../../../../../features/book-appointment';

// const CustomHeader = styled('div')({
//   paddingTop: "1rem",
//   paddingLeft: '1.5rem',
//   display: 'flex',
//   alignItems: 'center',
// });

export default function SummaryComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const landingPage = useSelector((state: any) => state.landingPage);
  const accessPath = landingPage.accessPath;
  const bookAppointment = useSelector((state: any) => state.bookAppointment);
  const appointmentRequest = bookAppointment.appointmentRequest;

  const handleConfirm = () => {
    dispatch(clearAppointmentRequest());
    // navigate('../' + BOOK_APPOINTMENT_CONFIRMED);
    navigate(`${accessPath}/${id}`)
  }

  return <div>
    {/* <CustomHeader>
      <IconButton onClick={() => navigate(`${accessPath}/${id}/${BOOK_APPOINTMENT}`)}>
        <img src={icons.back_icon} alt="back_icon" />
      </IconButton>
      <Typography sx={{ fontSize: '20px', fontWeight: 500, color: '#373736', ml: '20px' }}>
        Appointment Summary
      </Typography>
    </CustomHeader> */}
    <img
      src={images.appointment_calendar}
      alt=''
      style={{ display: 'block', margin: '70px auto 30px' }}
    />
    <Box sx={{ mx: '30px' }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 500, mx: '50px', color: '#373736', textAlign: 'center' }}>
        We’ve submitted your appointment request.
      </Typography>

      <Box sx={{ alignItems: 'center', m: '20px', background: '#F8F8F8', borderRadius: '8px', p: '20px' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography style={{ flex: 1, color: '#373736', fontSize: '14px', fontWeight: '400', textAlign: 'start' }}>
            Date:
          </Typography>
          <Typography style={{ flex: 1, color: '#000000', fontSize: '14px', fontWeight: '500', textAlign: 'start' }}>
            {moment(appointmentRequest.date, 'YYYY-MM-DD').format('D MMMM YYYY')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography style={{ flex: 1, color: '#373736', fontSize: '14px', fontWeight: '400', textAlign: 'start' }}>
            Time:
          </Typography>
          <Typography style={{ flex: 1, color: '#000000', fontSize: '14px', fontWeight: '500', textAlign: 'start' }}>
            {moment(appointmentRequest.start_time, ['H:mm']).format('hh:mm A')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Typography style={{ flex: 1, color: '#373736', fontSize: '14px', fontWeight: '400', textAlign: 'start' }}>
            Appointment to:
          </Typography>
          <Typography style={{ flex: 1, color: '#000000', fontSize: '14px', fontWeight: '500', textAlign: 'start' }}>
            {landingPage.profile.user_details.full_name}
          </Typography>
        </Box>
        <Divider sx={{ my: '20px' }} />
        {Boolean(appointmentRequest.notes) && <>
          <Typography style={{ flex: 1, fontSize: '14px', fontWeight: '400', textAlign: 'start' }}>
            Notes:
          </Typography>
          <Typography style={{ flex: 1, fontSize: '14px', fontWeight: '400', textAlign: 'start' }}>
            {appointmentRequest.notes}
          </Typography>
        </>}
      </Box>
    </Box>

    <Typography sx={{ mx: '50px', color: '#A7A7A7', fontSize: '12px', fontWeight: '400', textAlign: 'start' }}>
      You’ll receive a confirmation appointment status once he or she accept your request. Please check your email inbox for updated status.
    </Typography>

    <Box style={{
      display: 'grid',
      justifyItems: 'center'
    }}>
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{
          my: 2,
          gap: '1rem',
          borderRadius: '30px',
          width: '280px',
          height: '52px',
          color: '#FECD20',
          textTransform: 'none',
        }}
        onClick={handleConfirm}
      >
        Back To Home
      </Button>
    </Box>
  </div>
}