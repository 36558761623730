import axios from "axios";

interface Data {
  code: number;
  data: null;
  message: string | null;
  success: boolean;
}

const getResetPasswordToken = (email: string): Promise<Data> => {
  const params = new URLSearchParams([['email', email]]);

  return new Promise((resolve, reject) => {
    axios.get("basicauth/sendResetPasswordToken", { params, headers: {} })
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  })
};

export default getResetPasswordToken;
