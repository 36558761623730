import axios from "axios";

interface Card {
  product_uuid: string;
  cart_uuid: string;
  card_name: string;
  price: string;
  quantity: string;
  card_variation: string;
  name: string;
  position: string;
  design_id: string;
  front_thumbnail: string;
  back_thumbnail: string;
  pixelcare: number;
  customization: boolean;
  customization_price: string;
  customization_discount_price: string;
  font_color_code: string;
  logo_url: string
  pixelcare_price: string;
  pixelcare_discount_price: string;
  total_customization_price: string;
  total_pixelcare_price: string;
}

interface Data {
  success: boolean;
  code: number;
  message: string;
  data: {
  cart : Card[];
  total_price: number;
  }
}

const getShoppingCartListing = (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get(`product/getShoppingCartListing`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default getShoppingCartListing;