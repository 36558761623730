import noValidateRedeem from "./noValidateRedeem"
import selfValidateRedeem from "./selfValidateRedeem"
import getList from "./getList.api"
import details from "./details.api"
import getHistoryList from "./getHistoryList.api"
import getProfile from "./getProfile.api"
const myVoucherApi = {
  noValidateRedeem,
  selfValidateRedeem,
  getList,
  details,
  getHistoryList,
  getProfile
}

export default myVoucherApi
