export const FACEBOOK = 'facebook';
export const INSTAGRAM = 'instagram';
export const LINKEDIN = 'linkedin';
export const TWITTER = 'twitter';
export const LINE = 'line';
export const SNAPCHAT = 'snapchat';
export const SKYPE = 'skype';
export const BEHANCE = 'behance';
export const YOUTUBE = 'youtube';
export const PINTEREST = 'pinterest';
export const TELEGRAM = 'telegram';
export const WECHAT = 'wechat';
export const TIKTOK = 'tiktok';
export const VIBER = 'viber';