import React from 'react'
import FloatingButton from './floating-button'
import BasicInfo from './basic-info';
import Gallery from './gallery';
import SocialLink from './social-link'
import BasicContact from './basic-contact';
import Files from './files';
import Video from './video';
import Vouchers from './vouchers';
import CustomComponent from './custom-component';
import NotificationBar from './notification-bar';
import './frame.css';
import { useSelector } from 'react-redux'
import MobileFrame from '../../../../components/live-preview/mobile-frame';

interface LivePreviewComponentProp {
  profile: any;
  sections: any[];
  vouchers?: any[];
}

export default function LivePreviewComponent({ profile, sections, vouchers = [] }: LivePreviewComponentProp) {
  const backgroundColor = useSelector((state: any) => state.landingPage.background_color) || "#FFF"
  const injectedSections = [
    ...sections,
    {
      section_name: 'vouchers',
      sort: sections.filter(sec => sec.section_name === 'video')[0].sort + 0.5,
    },
  ].sort((a, b) => a.sort - b.sort);

  return <MobileFrame backgroundColor={backgroundColor}>
    <NotificationBar authed={false} />
    <BasicInfo userDetails={profile.user_details} />

    {injectedSections.map((section, index) => {
      if (section.section_name === "social") return <SocialLink key={index} socialInfo={profile.social_info} />
      if (section.section_name === "contact") return <BasicContact key={index} contactInfo={profile.contact_info} />
      if (section.section_name === "gallery") return <Gallery key={index} gallery={profile.gallery} />
      if (section.section_name === "video") return <Video key={index} video={profile.video} />
      if (section.section_name === "vouchers") return <Vouchers key={index} vouchers={vouchers} />
      if (section.section_name === "files") return <Files key={index} file={profile.file} />
      if (section.section_name === "custom") return <CustomComponent key={index} component={section} />
      return <></>
    })}

    <FloatingButton isEnabledAppointment={profile.appointment_status} />
  </MobileFrame>
} 