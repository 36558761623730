import { createSlice } from "@reduxjs/toolkit";
import { EXCHANGE_CONTACT } from "../../lib/constants/slices";

const initialState = {
  isLoading: true,
  memberRequest:{
    ownerCardNo:"",
    ownerProfileNo:"",
  },
  guestRequest: {
    user_uuid:"", 
    name: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
    companyName: ""
  },
  isConnected : false
};
const exchangeContactSlice = createSlice({
  name: EXCHANGE_CONTACT,
  initialState,
  reducers: {
    setMemberRequest(state,action){
      const {payload} = action;
      state.memberRequest = payload
    },
    setGuestRequest(state, action) {
      const { payload } = action;
   
      state.guestRequest = payload;
    },
    clearGuestRequest (state) {
      state.guestRequest = initialState.guestRequest;
    },
    setConnected(state){
      state.isConnected = true
    },
    closeConnected(state){
      state.isConnected = false
    }
  }
});


export const { setMemberRequest,setGuestRequest, clearGuestRequest,setConnected,closeConnected } = exchangeContactSlice.actions;
export const exchangeContactReducer = exchangeContactSlice.reducer;
