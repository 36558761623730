import React, { useState } from "react";
import { Box, Button, Divider, MenuItem, Select, Typography } from "@mui/material";
import icons from "../../../assets/icons";
import images from "../../../assets/img";

interface CardTileProps {
  card: any;
  profiles: any[];
  bindProfile: (profile_uuid: string, card_serial_number: string) => void;
  handleReceive: any;
}

const CardTile = ({ card, profiles, bindProfile, handleReceive }: CardTileProps) => {
  const [profileName, setProfileName] = useState(card.profile_name ?? '-');
  const [selectedProfile, setSelectedProfile] = useState(profiles.filter(prf => prf.name === card.profile_name)[0] ?? {
    uuid: '',
    name: '-',
  });

  const handleBind = () => {
    bindProfile(selectedProfile.uuid, card.card_serial_number);
    setProfileName(selectedProfile.name);
  }

  return <Box sx={{ height: '100%', minWidth: '318px', display: 'flex', flexDirection: 'column', gap: '20px', bgcolor: 'white', p: '20px', borderRadius: '8px', border: '1px solid #C5C5C5', textAlign: 'center', justifyContent: 'center' }}>
    <Box sx={{
      width: card.card_orientation === "PORTRAIT" ? '175px' : '276px',
      height: card.card_orientation === "PORTRAIT" ? '276px' : '175px',
      boxShadow: '0px 0px 20px 0px gray',
      background: `url("${card?.presigned_url ?? card?.font_thumbnail ?? images.landing_page_default_image}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      opacity: (card.status === 3) ? '100%' : '50%',
      borderRadius: '8px', mx: 'auto', position: 'relative',
    }} />

    <Box sx={{ width: '276px', p: '15px', bgcolor: '#F3F8FF', borderRadius: '8px', mx: 'auto' }}>
      <>
        {
          (card.status === 3) &&
          <div>
            {profiles.length > 0 ? <>
              <Typography variant='h5' textAlign={'start'}>
                Select Profile
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Select
                  IconComponent={(props) => (<img src={icons.arrow_down_01_icon.toString()} {...props} alt='' />)}
                  size="small"
                  id="profile"
                  value={selectedProfile.uuid}
                  sx={{
                    flex: 1,
                    fontSize: '12px',
                    textAlign: 'start',
                    '&:focus': { border: 'solid #FECD20' },
                    '& img': { top: 'inherit' },
                  }}
                  fullWidth
                  onChange={e => {
                    const filteredProfile = profiles.filter((prf: any) => prf.uuid === e.target.value)
                    if (filteredProfile.length > 0) setSelectedProfile(filteredProfile[0]);
                  }}
                >
                  {profiles.map((prf: any) => <MenuItem key={prf.uuid} value={prf.uuid}>{prf.name}</MenuItem>)}
                </Select>
                <Button
                  disabled={!Boolean(selectedProfile.uuid)}
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: '30px', px: '20px', ml: '10px', fontSize: '12px', fontWeight: 500 }}
                  onClick={handleBind}
                >
                  Apply
                </Button>
              </Box>
            </> : <Typography variant='h5' textAlign={'start'} color={'#BEBEBE'}>
              Please create a profile to bind
            </Typography>}
            <Divider sx={{ my: '10px' }} />
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500 }}>Profile Name:</Typography>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 400 }}>{profileName}</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500 }}>Job Title:</Typography>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 400 }}>{card.job_title ?? '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500 }}>Company Name:</Typography>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 400 }}>{card.company_name ?? '-'}</Typography>
            </Box>
            {Boolean(card.pixelcare_end_date) && <Box sx={{ display: 'flex' }}>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500 }}>Pixelcare+ Expiry:</Typography>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 400 }}>{card.pixelcare_end_date}</Typography>
            </Box>}
          </div>
        }

        {
          (card.status === 1 || card.status === 2) &&
          <div style={{ marginTop: '-30px' }}>
            {
              (card.status === 1) &&
              <Typography sx={{ position: 'relative', border: '1px solid #09BB3B', color: '#09BB3B', padding: '4px 8px', borderRadius: '10px', fontWeight: '600', backgroundColor: '#E8FFEF', fontSize: '14px', width: '100px', bottom: (card.card_orientation === 'LANDSCAPE' ? '165px' : '270px') }}>
                Processing
              </Typography>
            }
            {
              (card.status === 2) &&
              <Typography sx={{ position: 'relative', border: '1px solid #A32EFF', color: '#A32EFF', padding: '4px 8px', borderRadius: '10px', fontWeight: '600', backgroundColor: '#F3E4FF', fontSize: '14px', width: '100px', bottom: (card.card_orientation === 'LANDSCAPE' ? '165px' : '270px') }}>
                Shipping
              </Typography>
            }

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Typography variant='h5' sx={{ fontWeight: 600 }}>
                Order Received?
              </Typography>
              <Button
                disabled={Boolean(card.status === 1)}
                variant="contained"
                color="secondary"
                sx={{ borderRadius: '30px', padding: '2px 15px', fontSize: '12px', margin: '5px 0', fontWeight: 500 }}
                onClick={() => { handleReceive(card.uuid) }}
              >
                Yes, Received
              </Button>
              <Typography sx={{ fontSize: '11px' }}>
                Haven't received yet? Call us +6011-21127187
              </Typography>
            </div>

            <Divider sx={{ my: '10px' }} />
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500 }}>Order ID:</Typography>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 400 }}>{profileName}</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500 }}>Courier Name:</Typography>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 400 }}>{card.job_title ?? '-'}</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500 }}>Tracking Number:</Typography>
              <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 400 }}>{card.company_name ?? '-'}</Typography>
            </Box>
          </div>
        }
      </>
    </Box>
  </Box>
}

export default CardTile;