import axios from "axios";

interface Data{
  code: number,
  data:  ProfileDetails | null,
  message: string | null,
  success: boolean
}

interface ProfileDetails{
  appointment_status: boolean,
  background_color: string,
  contact_info:object,
  custom_component: Array<object>,
  file: object,
  gallery: object,
  profile_active: boolean,
  profile_name: string,
  social_info: object,
  theme: object,
  user_details: {
    company_image: string,
    company_name: string,
    description: string,
    full_name: string,
    job_title: string,
    profile_image: string,
  },
  video: object
}
const getProfileDetails = (profileUUID: string): Promise<Data> => { 
  const params = new URLSearchParams([['profile_uuid', profileUUID]]);
    return new Promise((resolve, reject) => {
        axios.get("profile/details",{params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getProfileDetails;
