import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb } from '../../../../features/admin';
import { BUY, CART, EDIT, PORTAL_CARDS, TEMPLATES, ZAKEKE_CUSTOMIZER } from "../../../../lib/constants/routes";
import CartComponent from "./cart.component";
import { createSearchParams, useNavigate } from "react-router-dom";
import { removeItem, setItems } from "../../../../features/cart";
import productApi from "../../../../api/product";
import userApi from "../../../../api/user";
import { endLoading, startLoading } from "../../../../features/loading";
import orderApi from "../../../../api/order";
import FiuuHelper  from "../../../../lib/helper/FiuuuHelper";
// import FiuuPaymentButton from "../../../../components/fiuu/fiuuPaymentButton";
import { openErrorDialog} from "../../../../features/error-dialog";
import { openResponseDialog } from "../../../../features/response-dialog";
import shipppingApi from "../../../../api/shipping";
export default function CartContainer() {
  const navigate = useNavigate();
  const [openAddressForm, setOpenAddressForm] = useState(false);
  const [openBillingForm, setOpenBillingForm] = useState(false);
  const [expanded, setExpanded] = useState('credit');
  const [totalPrice, setTotalPrice] = useState(0);
  const dispatch = useDispatch();
  const items = useSelector((state: any) => state.cart.items);
  const [itemPreview, setItemPreview] = useState<any>();
  const [shippingFee, setShippingFee] = useState(0);
  const [validCode, setValidCode] = useState(false);
  const [refCode, setRefCode] = useState('');
  const [shippingRateError, setShippingRateError] = useState(true);
  const [addressLoaded, setAddressLoaded] = useState(false);
  const [billingLoaded, setBillingLoaded] = useState(false);
  const [discountPct, setDiscountPct] = useState(0);
  const [address, setAddress] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
  });
  const [billing, setBilling] = useState({
    address1: '',
    address2: '',
    city: '',
    state: '',
    postcode: '',
    country: '',
  });

  const [basicInfo, setBasicInfo] = useState({
    uuid: "",
    name: "",
    email: "",
    phone_number: "",
    phone_code: "+60",
  });

  const handleDelete = (index: number, cart_uuid: string) => {
    dispatch(startLoading());
    productApi.deleteCart(cart_uuid)
      .then(() => {
        dispatch(removeItem(index));
        getShoppingCartListing();
      })
      .finally(() => dispatch(endLoading()));
  }

  const handleEdit = item => {
    if (Boolean(item.designid)) {
      productApi.getCartDetails(item.cart_uuid)
        .then(response => {
          const itm = response.data;
          navigate({
            pathname: `/${ZAKEKE_CUSTOMIZER}`,
            search: createSearchParams({
              productid: itm.product_uuid,
              productname: itm.card_name,
              designid: itm.zakeke_design_id,
              quantity: itm.quantity.toString(),
              attrid: itm.card_variation_uuid
            }).toString(),
          });
        })
    } else {
      navigate(`${PORTAL_CARDS}/${CART}/:cartUUID/${EDIT}`.replace(':cartUUID', item.cart_uuid));
    }
  }

  const handlePreview = item => {
    productApi.getCartDetails(item.cart_uuid)
      .then(response => {
        const itm = response.data;
        setItemPreview({
          product_uuid: itm.product_uuid,
          cart_uuid: itm.cart_uuid,
          jobTitle: itm.position,
          name: itm.card_name,
          personName: itm.name,
          textColor: itm.font_color_code,
          logo: itm.logo_url,
          price: item.price,
          quantity: item.quantity,
          orientation: itm.orientation,
          variant: {
            name: itm.card_variation,
            front: itm.front_thumbnail,
            back: itm.back_thumbnail,
          },
        });
      })
  }

  const togglePixelCare = (item: any, checked: boolean) => {
    productApi.editPixelcare(item.cart_uuid, checked ? 1 : 0)
      .then(() => {
        getShoppingCartListing()
      })
  }

  const handleSubmit = async (value) => {
    dispatch(startLoading());

    if(!value.address1 || !value.city || !value.state || !value.zip || !value.country){
      dispatch(endLoading())
      dispatch(openErrorDialog({
        title: "Purchase Order Error",
        description: "Please insert shipping address information.",
      }));
      return
    }
    
    if(!value.billDesc || !value.billAddr1 || !value.billCity || !value.billState || !value.billZip || !value.billCountry){
      dispatch(endLoading())
      dispatch(openErrorDialog({
        title: "Purchase Order Error",
        description: "Please insert billing address information.",
      }));
      return
    }
    const order = {
      bill_name: basicInfo.name,
      bill_email: basicInfo.email,
      bill_mobile: basicInfo.phone_code + basicInfo.phone_number,
      bill_desc: value.billDesc,
      bill_addr1: value.billAddr1,
      bill_addr2: value.billAddr2,
      bill_city: value.billCity,
      bill_state: value.billState,
      bill_zip: value.billZip,
      bill_country: value.billCountry,
      shipping_addr1: value.address1,
      shipping_addr2: value.address2,
      shipping_city: value.city,
      shipping_state: value.state,
      shipping_zip: value.zip,
      shipping_country: value.country,
      country: value.country,
      currency: value.currency,
      merchant_id: value.merchantId,
      channel: value.channel,
      prod_type: "NFC",
      shipping_fee: shippingFee,
      ref_code: value.refCode,
      cart_uuids: items.map(item => item.cart_uuid),
    }

    // For now online banking only support MYR
    if(value.channel === 'fpx'){
      order.currency = "MYR";
    }
    await orderApi.addCard({requestBody:order})
      .then(response => {
        if(!response.success){
          dispatch(openErrorDialog({
            title: "Purchase Order Error",
            description: "An error occurred while processing your order. Please try again later.",
          }));
          return false;
        }
        const options ={
          orderUUID: response.data.order_uuid,
          merchantId: response.data.merchant_id,
          channel: response.data.channel,
          totalAmount: response.data.total_amount,
          orderId: response.data.order_ref_id,
          billName: response.data.bill_name,
          billEmail: response.data.bill_email,
          billMobile: response.data.bill_mobile,
          billAddr1: response.data.bill_addr1,
          billAddr2: response.data.bill_addr2,
          billZip: response.data.bill_zip,
          description: response.data.bill_desc,
          vcode: response.data.vcode,
          country: response.data.country,
          currency: response.data.currency,
          langCode: 'en'
        }
        // if(response.data.channel === 'fpx'){
        //   //TODO: this is a temporary solution to use the old fpx.php
        //   // Currently Seamless not  working for fpx
        //   options.channel = 'fpx.php';
        //   const fiuuPaymentButton = new FiuuPaymentButton({paymentInfo: options});
        //   fiuuPaymentButton.openPaymentGateway();
        // }else{
          const fiuuHelper = new FiuuHelper();
          fiuuHelper.openPaymentGateway(options);
        // }

        // dispatch(setItems([]));
      })
      .catch(async(error) => {
        dispatch(openErrorDialog({
          title: "Purchase Order Error",
          description: error?.data?
          Object.values(error.data).join(", ")
          :"An error occurred while processing your order. Please try again later.",
        }));
        if(error.data?.order_uuid){
          await orderApi.fail(error.data.order_uuid)
            .then(response => response)
            .catch(error => error)
        }
      })
      .finally(() => dispatch(endLoading()));
  }

  const getAddress = async () => {
    await userApi.getShipping().then(async (res) => {
      if (res.success) {
        setAddress({
          address1: res.data.address_1 || '',
          address2: res.data.address_2 || '',
          city: res.data.city || '',
          state: res.data.state || '',
          postcode: res.data.zip || '',
          country: res.data.country || '',
        });
        await getShippingFee({
          postcode: res.data.zip,
          country: res.data.country,
        })
      }
    }).catch((error) => {
      dispatch(openResponseDialog({
        title: "Shipping Address",
        description: "Please insert shipping address information before proceeding.",
      }))
    }).finally(() => {
      setAddressLoaded(true)
    })
  }

  const getBilling = async () => {
    await userApi.getBilling().then((res) => {
      if (res.success) {
        setBilling({
          address1: res.data.address_1 || '',
          address2: res.data.address_2 || '',
          city: res.data.city || '',
          state: res.data.state || '',
          postcode: res.data.zip || '',
          country: res.data.country || '',
        });
        setBillingLoaded(true);
      }
    }).catch((error) => {
      setBillingLoaded(true);
      dispatch(openResponseDialog({
        title: "Billing Address",
        description: "Please insert shipping address information before proceeding.",
      }))
      }).finally(() => setBillingLoaded(true));
  }

  const getShoppingCartListing = async () => {
    dispatch(startLoading());
    await productApi.getShoppingCartListing()
    .then(response => {
      if(response.data?.cart === undefined) return;
      const items = response.data?.cart.map(item => ({
        product_uuid: item.product_uuid,
        cart_uuid: item.cart_uuid,
        name: item.card_name,
        price: item.price,
        quantity: item.quantity,
        designid: item.design_id,
        variant: {
          name: item.card_variation,
          front: item.front_thumbnail,
          back: item.back_thumbnail,
        },
        fontColor: item.font_color_code,
        logoUrl: item.logo_url,
        personName: item.name,
        jobTitle: item.position,
        customization: item.customization,
        customizationPrice: item.customization_price,
        customizationDiscountPrice: item.customization_discount_price,
        pixelcare: item.pixelcare === 1,
        pixelcarePrice: item.pixelcare_price,
        pixelcareDiscountPrice: item.pixelcare_discount_price, 
        totalCustomizationPrice: item.total_customization_price,
        totalPixelcarePrice: item.total_pixelcare_price,
      }));
      dispatch(setItems(items));
      setTotalPrice(response.data.total_price);
    })
    .finally(() => dispatch(endLoading()));
  }

  const handleApplyCode = async (referralCode:string) => {
    await userApi.checkReferralCode(referralCode).then((res) => {
      if (res.success) {
        setValidCode(true);
        // TODO: this hardcode, best is to get from backend
        // Check if referral code is PIXELPROMO
        if(referralCode === 'PIXELPROMO'){
          setDiscountPct(50); 
          return
        }
        setDiscountPct(10); 
        return

      } else {
        setValidCode(false);
      }
    }).catch((error) => {
      setValidCode(false);
      dispatch(openResponseDialog({
        title: "Invalid Referral Code",
        description: "Please insert correct referral code before proceeding.",
      }))
    });
  }

  const getShippingFee = async ({
    postcode,
    country
  }) => {
    dispatch(startLoading());
    await shipppingApi.rate({
      reference_number: 1,
      to_post_code: postcode,
      parcel_type: 'parcel',
      weight: 1,
      country: country, 
    }).then((res) => {
      if(res.success){
        setShippingFee(res.data.shipping_rate);
        setShippingRateError(false);
        return
      }
      setShippingRateError(true);
    }).catch((error) => {
      setShippingRateError(true);
      dispatch(openResponseDialog({
        title: "Shipping Fee",
        description: error.message,
      }))
    })
    .finally(() => dispatch(endLoading())); // Shipping fee is the last to finish loading
  }

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "My NFC Card", path: PORTAL_CARDS },
        { name: "Cart", path: null },
      ]
    }))

    getBilling();
    const getDetail = async () => {
      const response = await userApi.getDetail()
        .then(response => response)
        .catch(error => error)
        .finally(() => dispatch(endLoading()));

      if (!response.success) return;

      setBasicInfo(response.data);
    }
    getDetail();
    getShoppingCartListing();
    getAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <CartComponent
    expanded={expanded}
    setExpanded={setExpanded}
    currency='USD'
    basicInfo={basicInfo}
    handleBack={() => navigate(`${PORTAL_CARDS}/${BUY}/${TEMPLATES}`)}
    handleDelete={handleDelete}
    handleEdit={handleEdit}
    items={items}
    itemPreview={itemPreview}
    setItemPreview={setItemPreview}
    handlePreview={handlePreview}
    togglePixelCare={togglePixelCare}
    openAddressForm={openAddressForm}
    setOpenAddressForm={setOpenAddressForm}
    address={address}
    setAddress={setAddress}
    handleSubmit={handleSubmit}
    openBillingForm={openBillingForm}
    setOpenBillingForm={setOpenBillingForm}
    billing={billing}
    setBilling={setBilling}
    getShoppingCartListing={getShoppingCartListing}
    totalPrice={totalPrice}
    shippingFee={shippingFee}
    refCode={refCode}
    setRefCode={setRefCode}
    handleApplyCode={handleApplyCode}
    validCode={validCode}
    setShippingFee={setShippingFee}
    shippingRateError={shippingRateError}
    setShippingRateError={setShippingRateError}
    addressLoaded={addressLoaded}
    billingLoaded={billingLoaded}
    discountPct={discountPct}
  />
}