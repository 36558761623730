import React from 'react'
import { styled } from '@mui/system';
import { Box,Button,Dialog , DialogTitle,Typography} from '@mui/material';

const DialogMessage = styled('div')({
});

const DialogActions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
})

export default function ResponseDialogCompontent(
  {title,description,handleClose,open}:
  {title:string,description:string,handleClose: () => void, open: any}
){

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle style={{display:'flex',flexDirection: 'row',justifyContent: 'center'}}>
        <Typography style={{
          fontSize: '31px',
          fontStyle: 'normal',
          fontWeight: '500',
          textAlign: 'center',
          overflowWrap: 'break-word'
        }}>{title}</Typography> 
        </DialogTitle>
        <Box >
      <DialogMessage >
      <Typography align="center">
        {description}
      </Typography>
      
      </DialogMessage>
      <DialogActions>
        <Button
          type="button"
          fullWidth
          onClick={() => handleClose()}
          variant="contained"
          sx={{ mt: 3, mb: 2, maxWidth:"150px",borderRadius: '30px', }}
        >
          Okay
        </Button>
      </DialogActions>
      </Box>
    </Dialog>
  );
}