import bookAppointment from "./bookAppointment.api";
import getInRange from "./getInRange.api";
import getCalendarView from "./getCalendarView.api";
import getListView from "./getListView";
import getDetails from "./getDetails.api";
import approve from "./approve";
import decline from "./decline";
import reschedule from "./reschedule";
import getHistory from "./getHistory";

const appointmentApi = {
  bookAppointment,
  getInRange,
  getCalendarView,
  getListView,
  getDetails,
  approve,
  decline,
  reschedule,
  getHistory,
}

export default appointmentApi