import axios from "axios";

interface CartDetails {
  cart_uuid: string;
  front_thumbnail: string;
  back_thumbnail: string;
  product_uuid: string;
  card_name: string;
  card_variation: string;
  font_color_code: string;
  logo_url: string;
  quantity: number;
  name: string;
  position: string;
  pixelcare: number;
}

const editCart = ({ requestBody }: { requestBody: CartDetails }): Promise<any> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.post("product/editCart", requestBody)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default editCart;