import React from 'react'
import { styled } from '@mui/material/styles';
import { Box,Button,Typography } from '@mui/material';
import {useNavigate } from 'react-router-dom';
import {
  useLocation
} from "react-router-dom";
import accountApi from '../../../../../api/account';
import landingpage_link_broken_icon from '../../../assets/landingpage_link_broken_icon.svg'
import { CHECK_INBOX, FORGOT_PASSWORD } from '../../../../../lib/constants/routes';


const CustomContainer = styled("div")({
  display: "grid",
  justifyItems: "center",
  justifyContent: "space-around",
  minHeight: "100vh",
  alignItems: "center"
  
});
const ContainBox = styled("div")({
  display: "grid",
  justifyContent: "space-between",
  justifyItems: "center"
})

const CustomButton = styled(Button)({
  background: "#000000",
  color: "#FECD20",
  border: "30px"
});

export default function Expired(){
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const ResendResetPassword = async() =>{
    if(email === undefined || !email){
      navigate(FORGOT_PASSWORD)
      return true
    }
    console.log(email)
    const response = await accountApi.getResetPasswordToken(email)

    .then(response => response)
    .catch(error => error);
    
    if(response.success){
      navigate(CHECK_INBOX)
    }
    return true
  }
  return (
  <>

    <CustomContainer>
      <ContainBox>
        <Box style={{minHeight: '200px'}}>
          <img src={landingpage_link_broken_icon} alt="link broken"/>  
        </Box>
      
      <Typography variant="h4" paddingBottom={"1rem"}>
        Link Expired !
      </Typography>
      <Typography sx={{fontWeight:"400",textAlign: 'center',paddingBottom:'2rem'}}>         
        To reset your password, please click “Resend” to receive a new reset 
        <br/>password link in your email.
        </Typography>
      <CustomButton 
        sx={{ padding: "0.5rem 1.5rem", borderRadius: '30px', color: '#FECD20' }} 
        onClick={() => ResendResetPassword()}> 
        Resend</CustomButton>
      </ContainBox>
    </CustomContainer>
  </>
  );
}