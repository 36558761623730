import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const getProfilePicture = ({profile_uuid}:{profile_uuid: string}): Promise<Data> => {

  return new Promise((resolve, reject) => {
    axios.get("basiccontact/getProfilePicture?profile_uuid="+profile_uuid)
    .then(response => resolve(response.data))
    .catch(error => reject(error.response.data));
})
};

export default getProfilePicture;
