import axios from "axios";
interface Shipping {
  address_1: string  | null,
  address_2: string  | null,
  city: string  | null,
  state: string  | null,
  zip: string  | null,
  country: string  | null,
}
const updateShipping = ({requestBody}:{requestBody: Shipping}): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("user/updateShipping",requestBody)
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default updateShipping;
