import getList from "./getList.api";
import markAsRead from "./markAsRead.api";
import deleteNotification from "./delete.api";

const appointmentApi = {
  getList,
  markAsRead,
  deleteNotification,
}

export default appointmentApi