import React ,{useState}from 'react'
import { styled } from '@mui/material/styles';
import { Box,Button, Typography } from '@mui/material';
import {useNavigate } from 'react-router-dom';
import {
  useLocation
} from "react-router-dom";
import accountApi from '../../../../../api/account';
import icons from '../../../../../assets/icons'
import { CHECK_INBOX } from '../../../../../lib/constants/routes';

const CustomContainer = styled("div")({
  display: "grid",
  justifyItems: "center",
  justifyContent: "space-around",
  minHeight: "100vh",
  alignItems: "center"
  
});
const ContainBox = styled("div")({
  display: "grid",
  justifyContent: "space-between",
  justifyItems: "center"
})

const CustomButton = styled(Button)({
  background: "#000000",
  color: "#FECD20",
  border: "30px"
});

function useQuery() {
  const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default function ExContactExpired(){
  const navigate = useNavigate();
  let query = useQuery();
  const [ownerCardNo] = useState(query.get("owner_card_no") || "")
  const [ownerProfileNo] = useState(query.get("owner_profile_no") || "")
  const [email] = useState(query.get("email") || "")
  const resendGuestEmailVerification = async() =>{

    const response = await accountApi.resendGuestEmailVerification({
      ownerCardNo: ownerCardNo,
      ownerProfileNo: ownerProfileNo,
      email: email })
    .then(response => response)
    .catch(error => error);
    
    if(response.success){
      navigate(CHECK_INBOX)
    }
    return false
  }
  return (
  <>

    <CustomContainer>
      <ContainBox>
        <Box style={{minHeight: '200px'}}>
          <img src={icons.landingpage_link_broken_icon} alt="landingpage_link_broken_icon"/>  
        </Box>
      
      <Typography variant="h4" paddingBottom={"1rem"}>
        Link Expired !
      </Typography>
      <Typography sx={{fontWeight:"400",textAlign: 'center',paddingBottom:'2rem'}}>         
        To reset your password, please click “Resend” to receive a new reset 
        <br/>password link in your email.
        </Typography>
      <CustomButton 
        sx={{ padding: "0.5rem 1.5rem", borderRadius: '30px', color: '#FECD20' }} 
        onClick={() => resendGuestEmailVerification()}> 
        Resend</CustomButton>
      </ContainBox>
    </CustomContainer>
  </>
  );
}