import React from "react";
import { Typography, Paper, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import CustomSwitch from "../customSwitch";
import { EMAIL, PHONE_HOME, PHONE_MOBILE, ADDRESS, WHATSAPP, FAX, WEBSITE } from "../../../../../lib/constants/basiccontacttypes";
import DraggableItem from "../draggableItem";

export default function ContactInformationComponent({
  errors,
  totalSections,
  order,
  reorderSection,
  contactSelect,
  setContactSelect,
  isEnabledContactInfo,
  setIsEnabledContactInfo,
  items,
  setItems,
  setItem,
  handleSelectClose,
  reorder,
  remove,
  getListStyle,
  getItemStyle,
  getIcon
}) {
  return (
    <Paper elevation={0} sx={{ mt: '26px', padding: '30px', borderRadius: '8.78px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '10px', marginBottom: '20px' }}>
        <select style={{ borderRadius: '5px', padding: '5px', borderColor: '#C5C5C5' }} defaultValue={order + 1} onChange={event => reorderSection(order, event.target.value)}>
          {Array(totalSections).fill(undefined).map((_, i) => <option key={i}>{i + 1}</option>)}
        </select>
        <Typography component='span' variant='h4' sx={{ flex: 1 }}>
          Contact Information
        </Typography>
        <CustomSwitch checked={isEnabledContactInfo} onChange={e => setIsEnabledContactInfo(e.target.checked)} />
      </div>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Add Component</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Add Component"
          value={contactSelect}
          onChange={e => setContactSelect(e.target.value)}
          onClose={handleSelectClose}
          disabled={!isEnabledContactInfo}
        >
          <MenuItem value={EMAIL}>Email</MenuItem>
          <MenuItem value={PHONE_HOME}>Phone Number (Home)</MenuItem>
          <MenuItem value={PHONE_MOBILE}>Phone Number (Mobile)</MenuItem>
          <MenuItem value={ADDRESS}>Address</MenuItem>
          <MenuItem value={WHATSAPP}>Whatsapp</MenuItem>
          <MenuItem value={FAX}>Fax</MenuItem>
          <MenuItem value={WEBSITE}>Website</MenuItem>
        </Select>
      </FormControl>

      <DragDropContext onDragEnd={function (result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        setItems(reorder(
          items,
          result.source.index,
          result.destination.index
        ));
      }}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!isEnabledContactInfo}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <DraggableItem
                        category={item.category}
                        icon={getIcon(item.category)}
                        value={item.info}
                        setVal={e => setItem(e, index)}
                        errors={errors}
                        handleRemove={() => setItems(remove(items, item.id))}
                        disabled={!isEnabledContactInfo}
                        isSocial={item.category === WEBSITE} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  )
}