import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material';
import FileHelper from '../../../../../lib/helper/fileHelper'
import icons from '../../../../../assets/icons'

const DocumentAttachment = ({ index, file }: { index: string, file: any }) => {
  const [fileUrl, setFileUrl] = useState("")
  const [fileSize, setFileSize] = useState("0")
  const getFile = async (url) => {
    const fileResponse = await FileHelper.getUrl(url).then(fileResponse => fileResponse)
    setFileUrl(fileResponse);
    return fileResponse;
  }
  const downloadFile = async ({ name }: { name: string }) => {
    // const fileResponse  = await FileHelper.getUrl(filepath).then(fileResponse => fileResponse)  
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.download = `${name}.pdf`; // Set the desired file name here
    link.click();
  }

  useEffect(() => {
    const getFileSize = () => {
      getFile(file.url)
        .then(url => fetch(url)
          .then(result => result.blob())
          .then(blob => {
            // Convert bytes to megabytes
            const fileSizeInMB = blob.size / 1048576;
            parseInt(fileSizeInMB.toString()) > 0 ? setFileSize(fileSizeInMB.toString()) : setFileSize(fileSizeInMB.toFixed(2).toString())
          }));
    }
    getFileSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div key={index}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 3fr 1fr', justifyItems: 'start', alignItems: 'center' }}>
        <img src={icons.file_attachment_icon} alt="download" />
        <Box >
          <Typography>{file.name}</Typography>
          <Typography key={fileSize} sx={{ fontSize: 13, fontStyle: 'normal', fontWeight: 400 }}>{fileSize + "MB"}</Typography>
        </Box>
        <img style={{ height: 40, width: 40 }} src={icons.download_attachment_icon} alt="download"
          onClick={() => downloadFile({
            name: file.name
          })} />
      </Box>
    </div>
  )
}

export default DocumentAttachment