import React, {useEffect, useState} from "react";
import VoucherCreateComponent from "../create/voucherCreate.component";
import { useDispatch} from 'react-redux';
import { setBreadcrumb } from "../../../../../features/admin";
import {PORTAL, PORTAL_CAMPAIGNS, CAMPAIGN } from "../../../../../lib/constants/routes";
import campaignApi from "../../../../../api/campaign";
import {useParams} from 'react-router-dom';
import { startLoading, endLoading } from "../../../../../features/loading";
import { openErrorDialog } from "../../../../../features/error-dialog";
import FileHelper from "../../../../../lib/helper/fileHelper";
import { VoucherForm } from "../../../../../lib/types/Voucher";
import { EditVoucherRequest } from "../../../../../lib/types/Voucher";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";

export default function VoucherEditContainer() {
  const dispatch = useDispatch();
  const {id, voucherUUID} = useParams();
  const [initialVoucher, setInitialVoucher] = useState<VoucherForm>({
    voucherType : {
      validationType:1,
      codeType:'',
      discountType:'',
      limitPerUser:0,
      styleType:'',
      startDateTime:'',
      endDateTime:'',
      collectionStartDate:'',
      collectionEndDate:'',
    },
    voucherDetails:{
      voucherTitle:'',
      brandName:'',
      description:'',
      bannerImage:'',
      companyName:'',
      companyProfileImage:''
    },
    termsAndCondition:''
  })
  
  const getVoucherSettings = async () => {
    dispatch(startLoading());
    const response = await campaignApi.getVoucherSettings({ voucherUUID: voucherUUID || ''}).then(async (res) => {
      if(res?.success){
        setInitialVoucher({
          voucherType : {
            validationType: parseInt(res.data.validation_type),
            discountType:res.data.discount_type,
            codeType:res.data.code_type,
            styleType:res.data.style_type,
            limitPerUser:parseInt(res.data.limit_per_user),
            startDateTime:res.data.start_date_time,
            endDateTime:res.data.end_date_time,
            collectionStartDate:res.data.collection_start_date,
            collectionEndDate:res.data.collection_end_date
          },
          voucherDetails:{
            voucherTitle:res.data.voucher_title,
            brandName:res.data.brand_name,
            description:res.data.description,
            bannerImage:res.data.banner_image && await FileHelper.imagePathToFile(res.data.banner_image).then(repsonse => repsonse) ,
            companyName:res.data.company_name, 
            companyProfileImage:res.data.company_profile_image && await FileHelper.imagePathToFile(res.data.company_profile_image).then(repsonse => repsonse)
          },
          termsAndCondition:res.data.terms_and_condition
        })
        return res;
      }else{
        dispatch(openErrorDialog({
          title: "Error", 
          description: res.message,
          redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
        }))
      }
    }).catch((err) => {
      dispatch(openErrorDialog({
        title: "Error", 
        description: err.message,
        redirectPath: `${PORTAL}/${CAMPAIGN}/${id}`
      }))
      return err
    }).finally(() => {
      dispatch(endLoading());
    })

    if(response?.success){

      setInitialVoucher({
        voucherType : {
          validationType: parseInt(response.data.validation_type),
          discountType:response.data.discount_type,
          codeType:response.data.code_type,
          styleType:response.data.style_type,
          limitPerUser:response.data.limit_per_user,
          startDateTime:response.data.start_date_time,
          endDateTime:response.data.end_date_time,
          collectionStartDate:response.data.collection_start_date,
          collectionEndDate:response.data.collection_end_date
        },
        voucherDetails:{
          voucherTitle:response.data.voucher_title,
          brandName:response.data.brand_name,
          description:response.data.description,
          bannerImage:response.data.banner_image && await FileHelper.imagePathToFile(response.data.banner_image).then(repsonse => repsonse) ,
          companyName:response.data.company_name, 
          companyProfileImage:response.data.company_profile_image && await FileHelper.imagePathToFile(response.data.company_profile_image).then(repsonse => repsonse)
        },
        termsAndCondition:response.data.terms_and_condition
      })
    }
  }

  const getUser = () => JSON.parse(localStorage.getItem('user') ?? '{}');

  async function uploadFile(filePath: string, file) {
    
    let user = getUser();
    let fullPath = `voucher/${user.access_token_payload.uuid}/profiles/${filePath}/${uuidv4()}_${file.name}`;
  
    // Create presigned post
    const { url, fields } = await FileHelper.createUrl(fullPath).then(repsonse => repsonse)
  
    // Upload the file
    const formData = new FormData();
    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });
    formData.append("file", file);
    const res = await fetch(url, {
      method: "POST",
      body: formData,
    });
  
    // Get the URL if file uploaded successfully
    if (res.status === 204) {
      return fullPath;
    }
  }

  const editVoucher = async (values) => {
    dispatch(startLoading());
    const bannerFile = values.voucherDetails.bannerImage;
    const requestBody: EditVoucherRequest = {
      voucher_uuid: voucherUUID || "",
      voucher_title: values.voucherDetails.voucherTitle,
      brand_name: values.voucherDetails.brandName,
      description: values.voucherDetails.description,
      banner_image:  await uploadFile('voucher', bannerFile) || "",
      terms_and_condition: values.termsAndCondition,
      validation_type: values.voucherType.validationType,
      discount_type: values.voucherType.discountType,
      style_type: values.voucherType.styleType,
      code_type: values.voucherType.codeType,
      limit_per_user: values.voucherType.limitPerUser,
      start_date_time: moment(values.voucherType.startDateTime).format("YYYY-MM-DD HH:mm:ss") || "",
      end_date_time: moment(values.voucherType.endDateTime).format("YYYY-MM-DD HH:mm:ss") || "",
      collection_start_date: moment(values.voucherType.collectionStartDate).format("YYYY-MM-DD HH:mm:ss") || "",
      collection_end_date: moment(values.voucherType.collectionEndDate).format("YYYY-MM-DD HH:mm:ss") || "",
    };  
    return new Promise((resolve, reject) => {
      campaignApi.editVoucher(requestBody)
      .then(response => resolve(response))  
      .catch(error => reject(error))
      .finally(() => dispatch(endLoading()))
    })
  }

  useEffect(() => {
      dispatch(setBreadcrumb({
        breadcrumb:[
        {
          name: "Campaign",
          path: PORTAL_CAMPAIGNS
        },{
          name: "Campaign List",
          path : PORTAL_CAMPAIGNS
        },{
          name: "Campaign Detail",
          path : PORTAL+"/"+CAMPAIGN + "/" + id
        },{
          name: "Voucher Edit",
          path : null
        }
      ]
    }))
    // new Promise((resolve, reject) => {
      getVoucherSettings()
    // })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  return <VoucherCreateComponent initialVoucher={initialVoucher} onSubmit={editVoucher} isEdit={true}/>;
}