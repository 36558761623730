// ResponsiveDialog.js
import React from 'react';
import { styled } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, Typography, Box, Button } from '@mui/material';
import { closeErrorDialog } from '../../../features/error-dialog';
import { useNavigate } from 'react-router-dom';

const DialogMessage = styled('div')({
  display: 'grid',
  flexDirection: 'column',
  alignItems: 'center',
  justifyItems: 'center'
});

const DialogActions = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center'
})


const ErrorDialog = () => {
  const isOpen = useSelector((state) => state.errorDialog.open);
  const content = useSelector((state => state.errorDialog.content))

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(closeErrorDialog());
    navigate(content.redirectPath);
  }

  return (
    <Dialog fullWidth={false}  onClose={handleClose} open={isOpen} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' }}}> 
      <DialogTitle style={{display:'flex',flexDirection: 'row',justifyContent: 'center'}}>
        <Typography style={{
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: '700',
          textAlign: 'center',
          overflowWrap: 'break-word'
        }}>{content.title}</Typography> 
        </DialogTitle>
        <Box >
      <DialogMessage >
      <Typography sx={{maxWidth: "70%"}}align="center">
        {content.description}
      </Typography>
      
      </DialogMessage>
      <DialogActions>
        <Button
          type="button"
          fullWidth
          onClick={() => handleClose()}
          variant="contained"
          sx={{ mt: 3, mb: 2, maxWidth:"150px",borderRadius: '30px', background:"#373736", color: '#FECD20',
          "&:hover": {
            backgroundColor: "#FECD20",
            color:"#373736"
          } }}
          style={{
            
          }}
        >
          Back
        </Button>
      </DialogActions>
      </Box>
    </Dialog>
  );
    };


  export default ErrorDialog;


