import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, Link } from "@mui/material";
import contactListApi from "../../../api/contactList/contactList.api";

function EmailDialog({ open, handleClose, uuid }) {
  const [emails, setEmails] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (uuid === '') return;

    contactListApi.getEmailAndCall('email', uuid)
      .then(data => {
        setEmails(data.email || [])
      })
  }, [uuid]);

  return <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
    <DialogTitle>Email</DialogTitle>
    <DialogContent>
      {emails.map(email => (<div key={email} style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#FAFAFA',
        padding: '10px',
        gap: '1rem',
        borderRadius: '8px',
      }}>
        {email}
        <Link href={`mailto:${email}`}><Button sx={{
            padding: '10px 35px',
            borderRadius: '30px',
        }} variant="contained" color="secondary">Send</Button></Link>
      </div>))}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button color="secondary" onClick={handleClose}>Cancel</Button>
      </div>
    </DialogContent>
  </Dialog>
};

export default EmailDialog;