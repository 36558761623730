import React, {useState,useRef} from 'react'
import { styled } from '@mui/system';
import { Box,Button,Dialog , DialogTitle,Typography,Tooltip,ClickAwayListener, IconButton } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import html2canvas from 'html2canvas'
import icons from '../../../../../assets/icons';

const DialogMessage = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '1rem',
  gap: '20px',
  flexWrap: 'wrap',
});

export default function ShareProfileDialog(
  {profileLink,open,onClose}:
  {profileLink:string,open: boolean,onClose: () => void}
){
  const [tooltipOpen,setTooltipOpen] = useState(false);
  const qrCodeRef = useRef();
  const handleQRcodeDownload = async () => {
    const element = qrCodeRef.current;
    if(element){
      const canvas = await html2canvas(element),
      data = canvas.toDataURL('image/jpg'),
      link = document.createElement('a');

      link.href = data;
      link.download = 'downloaded-image.jpg';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const copyLink = () =>{
    navigator.clipboard.writeText(profileLink)
    setTooltipOpen(true)
    setTimeout(() => {
      handleTooltipClose()
    }, 1000);
  }

  return (
    <Dialog fullWidth maxWidth='md' PaperProps={{ sx: { borderRadius: "25px", p: '20px 32px' } }} onClose={onClose} open={open}>
      <DialogTitle style={{
        padding: 0,
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography style={{
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '500',
          textAlign: 'center',
          overflowWrap: 'break-word'
        }}>
          Share Profile
        </Typography>
        <IconButton onClick={onClose}>
          <img src={icons.close} alt="close"/>
        </IconButton>
      </DialogTitle>
      <DialogMessage>
        <Box ref={qrCodeRef}>
          <QRCode
            size={172}
            // logoWidth="80"
            // logoHeight="80"
            value={profileLink}
          />
        </Box>
        <Box style={{ flex: 1 }}>
          <Typography gutterBottom>Copy Profile Link</Typography>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              placement='top'
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title="Link Copied"
            >
              <Box
                style={{
                  borderRadius: '7px',
                  background: '#F7F7F7',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '0.8rem 1rem',
                  justifyContent: 'space-between'
                }}
                onClick={() =>copyLink()}
              >
                <Typography sx={{
                  flex: 1,
                  minWidth: 0,
                  width: '160px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontWeight: '400'
                }}>
                  {profileLink}
                </Typography>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px'
                }}>
                  <img src={icons.copy} alt="copy"/>
                  <Typography style={{fontWeight: '400'}}>
                    Copy link
                  </Typography>
                </Box>
              </Box >
            </Tooltip>
          </ClickAwayListener>

          <Button
            variant="outlined"
            sx={{
              my:2,
              borderRadius: '8px',
              color: 'black',
              width: {
                xs: '100%',
                sm: 'unset',
              }
            }}
            onClick={() => handleQRcodeDownload()}
            startIcon={<img src={icons.download} alt="download"/>}
          >
            Download QR code
          </Button>
        </Box>
      </DialogMessage>
    </Dialog>
  );
}