import axios from "axios";
interface Billing {
  address_1: string  | null,
  address_2: string  | null,
  city: string  | null,
  state: string  | null,
  zip: string  | null,
  country: string  | null,
}
const updateBilling = ({requestBody}:{requestBody: Billing}): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("user/updateBilling",requestBody)
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default updateBilling;
