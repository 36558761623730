import React,{useState} from "react"
import { Box, TextField } from "@mui/material"
import icons from "../../assets/icons"
import {ChromePicker} from 'react-color'

export default function ColorInput({label,value, setCustomColor}){
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'flex-end',
      position: 'relative',
    }}>
            { displayColorPicker ? <div style={{
          position: 'absolute',
          zIndex: 2,
          top: '0px',
        }}>
        <div style={{
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        } } onClick={() => setDisplayColorPicker(false) }/>
          <ChromePicker
          color={value}
          onChange={
            (color) =>{
              setCustomColor(color.hex)
            } 
          } />
      </div> : null }
      <Box sx={{
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        height:"40px",
        width:"40px",
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: value,
      }}
      onClick={handleClick}
      >

        <img src={icons.edit_02.toString()} alt="colorpicker"/>
      </Box>
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        size='small'
        onChange={(e) => setCustomColor(e.target.value)}
        sx={{width:'100%',marginTop:'1rem'}}
        value={value}
      />
    </Box>
  )
}