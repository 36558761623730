import axios from "axios";

interface Data {
  success: boolean;
  code: number;
  message: string;
  data: null;
}

interface verifyEmailProps {
  email: string;
  email_verification_token: string;
}

const verifyEmail = ({ email, email_verification_token }: verifyEmailProps): Promise<Data> => {
  var requestBody = { email, email_verification_token };

  return new Promise((resolve, reject) => {
    axios.post("basicauth/emailVerification", requestBody)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default verifyEmail;