import React, { useEffect, useState } from 'react';
import VouchersComponent from './vouchers.component';
import { useSelector } from "react-redux";
import FileHelper from '../../../lib/helper/fileHelper';
import getVoucherListByProfile, { VoucherType } from '../../../api/basicCampaign/getVoucherListByProfile.api';


export default function VouchersContainer() {
  const profile: any = useSelector<any>(state => state.landingPage.profile);
  const [vouchers, setVouchers] = useState<VoucherType[]>([]);

  useEffect(() => {
    getVoucherListByProfile({ profileUUID: profile.profile_uuid })
      .then(res => Promise.all(res.data.map(async (vc) => ({
        ...vc,
        banner_image: await FileHelper.getUrl(vc.banner_image),
      }))).then(setVouchers));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return vouchers.length > 0 ? <VouchersComponent vouchers={vouchers} /> : <></>
}