import React, {useEffect} from "react";
import VoucherCreateComponent from "./voucherCreate.component";
import { useDispatch} from 'react-redux';
import { setBreadcrumb } from "../../../../../features/admin";
import { PORTAL_CAMPAIGNS, PORTAL, CAMPAIGN } from "../../../../../lib/constants/routes";
import { VoucherForm, CreateVoucherRequest } from "../../../../../lib/types/Voucher";
import { useParams } from "react-router-dom";
import campaignApi from "../../../../../api/campaign";
import FileHelper from "../../../../../lib/helper/fileHelper";
import moment from "moment";
import { openErrorDialog} from "../../../../../features/error-dialog";
import { v4 as uuidv4 } from 'uuid';
import { BOTH } from "../../../../../lib/constants/voucherStyle";
import { SINGLE } from "../../../../../lib/constants/voucherCodeType";

export default function VoucherCreateContainer() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const [initialVoucher] = React.useState<VoucherForm>({
    voucherType : {
      validationType:1,
      codeType: SINGLE,
      discountType:'',
      limitPerUser:0,
      styleType: BOTH,
      startDateTime:'',
      endDateTime:'',
      collectionStartDate:'',
      collectionEndDate:'',
    },
    voucherDetails:{
      voucherTitle:'',
      brandName:'',
      description:'',
      bannerImage:'',
      companyName:'',
      companyProfileImage:''
    },
    termsAndCondition:''
  })

  const getUser = () => JSON.parse(localStorage.getItem('user') ?? '{}');
  async function uploadFile(filePath: string, file) {
    
    let user = getUser();
    let fullPath = `user/${user.access_token_payload.uuid}/profiles/${filePath}/${uuidv4()}_${file.name}`;
  
    // Create presigned post
    const { url, fields } = await FileHelper.createUrl(fullPath).then(repsonse => repsonse)
  
    // Upload the file
    const formData = new FormData();
    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });
    formData.append("file", file);
    const res = await fetch(url, {
      method: "POST",
      body: formData,
    });
  
    // Get the URL if file uploaded successfully
    if (res.status === 204) {
      return fullPath;
    }
  }

  const createVoucher = async (values) => {
    const bannerFile = values.voucherDetails.bannerImage;
    const companyProfileFile = values.voucherDetails.companyProfileImage;
    const requestBody: CreateVoucherRequest = {
      campaign_uuid: id || "",
      voucher_title: values.voucherDetails.voucherTitle,
      brand_name: values.voucherDetails.brandName,
      description: values.voucherDetails.description,
      banner_image:  await uploadFile('voucher', bannerFile) || "",
      company_name: values.voucherDetails.companyName,
      company_profile_image: await uploadFile('voucher', companyProfileFile) || "",
      terms_and_condition: values.termsAndCondition,
      validation_type: values.voucherType.validationType,
      discount_type: values.voucherType.discountType,
      style_type: values.voucherType.styleType,
      code_type: values.voucherType.codeType,
      limit_per_user: values.voucherType.limitPerUser,
      start_date_time: moment(values.voucherType.startDateTime).format("YYYY-MM-DD HH:mm:ss") || "",
      end_date_time: moment(values.voucherType.endDateTime).format("YYYY-MM-DD HH:mm:ss") || "",
      collection_start_date: moment(values.voucherType.collectionStartDate).format("YYYY-MM-DD HH:mm:ss") || "",
      collection_end_date: moment(values.voucherType.collectionEndDate).format("YYYY-MM-DD HH:mm:ss") || "",
    };
    return new Promise((resolve, reject) => {
      campaignApi.addVoucher(requestBody)
      .then(response => resolve(response))  
      .catch(error => {
        dispatch(openErrorDialog({
          title: "Voucher Create Error",
          description: error?.message
        }))
        reject(error)
      })
    })
  }

  useEffect(() => {
      dispatch(setBreadcrumb({
        breadcrumb:[
        {
          name: "Campaign",
          path: PORTAL_CAMPAIGNS
        },{
          name: "Campaign List",
          path : PORTAL_CAMPAIGNS
        },{
          name: "Campaign Detail",
          path : PORTAL+"/"+CAMPAIGN + "/" + id
        },{
          name: "Voucher Create",
          path : null
        }
      ] 
    }))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
  return <VoucherCreateComponent initialVoucher={initialVoucher} onSubmit={createVoucher}/>;
}