import { useSelector } from 'react-redux'
import { Breadcrumbs, Link, Typography } from "@mui/material";
import icons from '../../assets/icons';

export default function ContentBreadcrumb() {
  const breadcrumb = useSelector((state: any) => state.admin.breadcrumb)

  return <Breadcrumbs
    aria-label="breadcrumb"
    sx={{ display: { xs: 'none', md: 'block' } }}
    separator={<div className='icon' style={{
      backgroundColor: '#A7A7A7',
      mask: `url(${icons.arrow_right_double_round})`,
      maskSize: '16px 16px',
      maskRepeat: 'no-repeat',
      WebkitMask: `url(${icons.arrow_right_double_round})`,
      WebkitMaskSize: '16px 16px',
      WebkitMaskRepeat: 'no-repeat',
      width: '16px',
      height: '16px',
    }} />}
  >
    {breadcrumb.map((item: any, index: number) => {
      if (item.path === null) return <Typography key={index} sx={{ color: "white" }}>{item.name}</Typography>

      return <Link key={index} underline="hover" sx={{ color: "#A7A7A7" }} href={item.path}>
        {item.name}
      </Link>
    })}
  </Breadcrumbs>
}