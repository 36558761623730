import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  landingPage:{
    open: false
  }
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.open = true;
    },
    endLoading: (state) => {
      state.open = false;
    },
    startLoadingLP: (state) => {
      state.landingPage.open = true;
    },
    endLoadingLP: (state) => {
      state.landingPage.open = false;
    },
  },
});

export const { startLoading, endLoading, startLoadingLP,endLoadingLP } = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;