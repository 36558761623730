export const COUNTRIES_CODE_ISO = [
  {"name":"Afghanistan", "alpha":" AF", "value":"AFG"},
  {"name":"Albania", "alpha":"AL", "value":"ALB"},
  {"name":"Algeria", "alpha":"DZ", "value":"DZA"},
  {"name":"American Samoa", "alpha":"AS", "value":"ASM"},
  {"name":"Andorra", "alpha":"AD", "value":"AND"},
  {"name":"Angola", "alpha":"AO", "value":"AGO"},
  {"name":"Anguilla", "alpha":"AI", "value":"AIA"},
  {"name":"Antigua and Barbuda", "alpha":"AG", "value":"ATG"},
  {"name":"Argentina", "alpha":"AR", "value":"ARG"},
  {"name":"Armenia", "alpha":"AM", "value":"ARM"},
  {"name":"Aruba", "alpha":"AW", "value":"ABW"},
  {"name":"Australia", "alpha":"AU", "value":"AUS"},
  {"name":"Austria", "alpha":"AT", "value":"AUT"},
  {"name":"Azerbaijan", "alpha":"AZ", "value":"AZE"},
  {"name":"Bahamas", "alpha":"BS", "value":"BHS"},
  {"name":"Bahrain", "alpha":"BH", "value":"BHR"},
  {"name":"Bangladesh", "alpha":"BD", "value":"BGD"},
  {"name":"Barbados", "alpha":"BB", "value":"BRB"},
  {"name":"Belarus", "alpha":"BY", "value":"BLR"},
  {"name":"Belgium", "alpha":"BE", "value":"BEL"},
  {"name":"Belize", "alpha":"BZ", "value":"BLZ"},
  {"name":"Benin", "alpha":"BJ", "value":"BEN"},
  {"name":"Bermuda", "alpha":"BM", "value":"BMU"},
  {"name":"Bhutan", "alpha":"BT", "value":"BTN"},
  {"name":"Bolivia", "alpha":"BO", "value":"BOL"},
  {"name":"Bosnia and Herzegovina", "alpha":"BA", "value":"BIH"},
  {"name":"Botswana", "alpha":"BW", "value":"BWA"},
  {"name":"Brazil", "alpha":"BR", "value":"BRA"},
  {"name":"British Virgin Islands", "alpha":"VG", "value":"VGB"},
  {"name":"Brunei Darussalam", "alpha":"BN", "value":"BRN"},
  {"name":"Bulgaria", "alpha":"BG", "value":"BGR"},
  {"name":"Burkina Faso", "alpha":"BF", "value":"BFA"},
  {"name":"Burundi", "alpha":"BI", "value":"BDI"},
  {"name":"Cambodia", "alpha":"KH", "value":"KHM"},
  {"name":"Cameroon", "alpha":"CM", "value":"CMR"},
  {"name":"Canada", "alpha":"CA", "value":"CAN"},
  {"name":"Cape Verde", "alpha":"CV", "value":"CPV"},
  {"name":"Central African Republic", "alpha":"CF", "value":"CAF"},
  {"name":"Chad", "alpha":"TD", "value":"TCD"},
  {"name":"Chile", "alpha":"CL", "value":"CHL"},
  {"name":"China", "alpha":"CN", "value":"CHN"},
  {"name":"Hong Kong", "alpha":"HK", "value":" HKG"},
  {"name":"Macao", "alpha":"MO", "value":"MAC"},
  {"name":"Colombia", "alpha":"CO", "value":"COL"},
  {"name":"Comoros", "alpha":"KM", "value":"COM"},
  {"name":"Congo", "alpha":"CG", "value":"COG"},
  {"name":"Costa Rica", "alpha":"CR", "value":"CRI"},
  {"name":"Côte d'Ivoire", "alpha":"CI", "value":"CIV"},
  {"name":"Croatia", "alpha":"HR", "value":"HRV"},
  {"name":"Cuba", "alpha":"CU", "value":"CUB"},
  {"name":"Cyprus", "alpha":"CY", "value":"CYP"},
  {"name":"Czech Republic", "alpha":"CZ", "value":"CZE"},
  {"name":"Denmark", "alpha":"DK", "value":"DNK"},
  {"name":"Djibouti", "alpha":"DJ", "value":"DJI"},
  {"name":"Dominica", "alpha":"DM", "value":"DMA"},
  {"name":"Dominican Republic", "alpha":"DO", "value":"DOM"},
  {"name":"Ecuador", "alpha":"EC", "value":"ECU"},
  {"name":"Egypt", "alpha":"EG", "value":"EGY"},
  {"name":"El Salvador", "alpha":"SV", "value":"SLV"},
  {"name":"Equatorial Guinea", "alpha":"GQ", "value":"GNQ"},
  {"name":"Eritrea", "alpha":"ER", "value":"ERI"},
  {"name":"Estonia", "alpha":"EE", "value":"EST"},
  {"name":"Ethiopia", "alpha":"ET", "value":"ETH"},
  {"name":"European Union", "alpha":"EU", "value":""},
  {"name":"Faroe Islands", "alpha":"FO", "value":"FRO"},
  {"name":"Fiji", "alpha":"FJ", "value":"FJI"},
  {"name":"Finland", "alpha":"FI", "value":"FIN"},
  {"name":"France", "alpha":"FR", "value":"FRA"},
  {"name":"French Guiana", "alpha":"GF", "value":"GUF"},
  {"name":"French Polynesia", "alpha":"PF", "value":"PYF"},
  {"name":"Gabon", "alpha":"GA", "value":"GAB"},
  {"name":"Gambia", "alpha":"GM", "value":"GMB"},
  {"name":"Georgia", "alpha":"GE", "value":"GEO"},
  {"name":"Germany", "alpha":"DE", "value":"DEU"},
  {"name":"Ghana", "alpha":"GH", "value":"GHA"},
  {"name":"Greece", "alpha":"GR", "value":"GRC"},
  {"name":"Greenland", "alpha":"GL", "value":"GRL"},
  {"name":"Grenada", "alpha":"GD", "value":"GRD"},
  {"name":"Guadeloupe", "alpha":"GP", "value":"GLP"},
  {"name":"Guam", "alpha":"GU", "value":"GUM"},
  {"name":"Guatemala", "alpha":"GT", "value":"GTM"},
  {"name":"Guinea-Bissau", "alpha":"GW", "value":"GNB"},
  {"name":"Haiti", "alpha":"HT", "value":"HTI"},
  {"name":"Honduras", "alpha":"HN", "value":"HND"},
  {"name":"Hungry", "alpha": "HU", "value":"HUN"},
  {"name":"Iceland", "alpha":"IS", "value":"ISL"},
  {"name":"India", "alpha":"IN", "value":"IND"},
  {"name":"Indonesia", "alpha":"ID", "value":"IDN"},
  {"name":"Iran (Islamic Republic of)", "alpha":"IR", "value":"IRN"},
  {"name":"Iraq", "alpha":"IQ", "value":"IRQ"},
  {"name":"Ireland", "alpha":"IE", "value":"IRL"},
  {"name":"Israel", "alpha":"IL", "value":"ISR"},
  {"name":"Italy", "alpha":"IT", "value":"ITA"},
  {"name":"Japan", "alpha":"JP", "value":"JPN"},
  {"name":"Jordan", "alpha":"JO", "value":"JOR"},
  {"name":"Kazakhstan", "alpha":"KZ", "value":"KAZ"},
  {"name":"Kenya", "alpha":"KE", "value":"KEN"},
  {"name":"Kiribati", "alpha":"KI", "value":"KIR"},
  {"name":"Korea", "alpha":"KP", "value":"PRK"},
  {"name":"Korea", "alpha":"KR", "value":"KOR"},
  {"name":"Kuwait", "alpha":"KW", "value":"KWT"},
  {"name":"Kyrgyzstan", "alpha":"KG", "value":"KGZ"},
  {"name":"Lao PDR", "alpha":"LA", "value":"LAO"},
  {"name":"Latvia", "alpha":"LV", "value":"LVA"},
  {"name":"Lebanon", "alpha":"LB", "value":"LBN"},
  {"name":"Lesotho", "alpha":"LS", "value":"LSO"},
  {"name":"Liberia", "alpha":"LR", "value":"LBR"},
  {"name":"Libya", "alpha":"LY", "value":"LBY"},
  {"name":"Liechtenstein", "alpha":"LI", "value":"LIE"},
  {"name":"Lithuania", "alpha":"LT", "value":"LTU"},
  {"name":"Luxembourg", "alpha":"LU", "value":"LUX"},
  {"name":"Madagascar", "alpha":"MG", "value":"MDG"},
  {"name":"Malawi", "alpha":"MW", "value":"MWI"},
  {"name":"Malaysia", "alpha":"MY", "value":"MYS"},
  {"name":"Maldives", "alpha":"MV", "value":"MDV"},
  {"name":"Mali", "alpha":"ML", "value":"MLI"},
  {"name":"Malta", "alpha":"MT", "value":"MLT"},
  {"name":"Marshall Islands", "alpha":"MH", "value":"MHL"},
  {"name":"Martinique", "alpha":"MQ", "value":"MTQ"},
  {"name":"Mauritania", "alpha":"MR", "value":"MRT"},
  {"name":"Mauritius", "alpha":"MU", "value":"MUS"},
  {"name":"Mexico", "alpha":"MX", "value":"MEX"},
  {"name":"Micronesia, Federated States of", "alpha":"FM", "value":"FSM"},
  {"name":"Moldova", "alpha":"MD", "value":"MDA"},
  {"name":"Monaco", "alpha":"MC", "value":"MCO"},
  {"name":"Mongolia", "alpha":"MN", "value":"MNG"},
  {"name":"Montenegro", "alpha":"ME", "value":"MNE"},
  {"name":"Montserrat", "alpha":"MS", "value":"MSR"},
  {"name":"Morocco", "alpha":"MA", "value":"MAR"},
  {"name":"Mozambique", "alpha":"MZ", "value":"MOZ"},
  {"name":"Myanmar", "alpha":"MM", "value":"MMR"},
  {"name":"Namibia", "alpha":"NA", "value":"NAM"},
  {"name":"Nauru", "alpha":"NR", "value":"NRU"},
  {"name":"Nepal", "alpha":"NP", "value":"NPL"},
  {"name":"Netherlands", "alpha":"NL", "value":"NLD"},
  {"name":"Netherlands Antilles", "alpha":"AN", "value":"ANT"},
  {"name":"New Caledonia", "alpha":"NC", "value":"NCL"},
  {"name":"New Zealand", "alpha":"NZ", "value":"NZL"},
  {"name":"Nicaragua", "alpha":"NI", "value":"NIC"},
  {"name":"Niger", "alpha":"NE", "value":"NER"},
  {"name":"Nigeria", "alpha":"NG", "value":"NGA"},
  {"name":"Northern Mariana Islands", "alpha":"MP", "value":"MNP"},
  {"name":"Norway", "alpha":"NO", "value":"NOR"},
  {"name":"Oman", "alpha":"OM", "value":"OMN"},
  {"name":"Pakistan", "alpha":"PK", "value":"PAK"},
  {"name":"Palau", "alpha":"PW", "value":"PLW"},
  {"name":"Palestinian Territory", "alpha":"PS", "value":"PSE"},
  {"name":"Panama", "alpha":"PA", "value":"PAN"},
  {"name":"Papua New Guinea", "alpha":"PG", "value":"PNG"},
  {"name":"Paraguay", "alpha":"PY", "value":"PRY"},
  {"name":"Peru", "alpha":"PE", "value":"PER"},
  {"name":"Philippines", "alpha":"PH", "value":"PHL"},
  {"name":"Pitcairn", "alpha":"PN", "value":"PCN"},
  {"name":"Poland", "alpha":"PL", "value":"POL"},
  {"name":"Portugal", "alpha":"PT", "value":"PRT"},
  {"name":"Puerto Rico", "alpha":"PR", "value":"PRI"},
  {"name":"Qatar", "alpha":"QA", "value":"QAT"},
  {"name":"Réunion", "alpha":"RE", "value":"REU"},
  {"name":"Romania", "alpha":"RO", "value":"ROU"},
  {"name":"Russian Federation", "alpha":"RU", "value":"RUS"},
  {"name":"Rwanda", "alpha":"RW", "value":"RWA"},
  {"name":"Saint Kitts and Nevis", "alpha":"KN", "value":"KNA"},
  {"name":"Saint Lucia", "alpha":"LC", "value":"LCA"},
  {"name":"Saint Vincent and Grenadines", "alpha":"VC", "value":"VCT"},
  {"name":"Samoa", "alpha":"WS", "value":"WSM"},
  {"name":"San Marino", "alpha":"SM", "value":"SMR"},
  {"name":"Sao Tome and Principe", "alpha":"ST", "value":"STP"},
  {"name":"Saudi Arabia", "alpha":"SA", "value":"SAU"},
  {"name":"Senegal", "alpha":"SN", "value":"SEN"},
  {"name":"Serbia", "alpha":"RS", "value":"SRB"},
  {"name":"Seychelles", "alpha":"SC", "value":"SYC"},
  {"name":"Sierra Leone", "alpha":"SL", "value":"SLE"},
  {"name":"Singapore", "alpha":"SG", "value":"SGP"},
  {"name":"Slovakia", "alpha":"SK", "value":"SVK"},
  {"name":"Slovenia", "alpha":"SI", "value":"SVN"},
  {"name":"Solomon Islands", "alpha":"SB", "value":"SLB"},
  {"name":"Somalia", "alpha":"SO", "value":"SOM"},
  {"name":"South Africa", "alpha":"ZA", "value":"ZAF"},
  {"name":"Spain", "alpha":"ES", "value":"ESP"},
  {"name":"Sri Lanka", "alpha":"LK", "value":"LKA"},
  {"name":"Sudan", "alpha":"SD", "value":"SDN"},
  {"name":"Suriname", "alpha":"SR", "value":"SUR"},
  {"name":"Swaziland", "alpha":"SZ", "value":"SWZ"},
  {"name":"Sweden", "alpha":"SE", "value":"SWE"},
  {"name":"Switzerland", "alpha":"CH", "value":"CHE"},
  {"name":"Syrian Arab Republic", "alpha":"SY", "value":"SYR"},
  {"name":"Taiwan (Province of China)", "alpha":"TW", "value":"TWN"},
  {"name":"Tajikistan", "alpha":"TJ", "value":"TJK"},
  {"name":"Tanzania", "alpha":"TZ", "value":"TZA"},
  {"name":"Thailand", "alpha":"TH", "value":"THA"},
  {"name":"Timor-Leste", "alpha":"TL", "value":"TLS"},
  {"name":"Togo", "alpha":"TG", "value":"TGO"},
  {"name":"Tonga", "alpha":"TO", "value":"TON"},
  {"name":"Trinidad and Tobago", "alpha":"TT", "value":"TTO"},
  {"name":"Tunisia", "alpha":"TN", "value":"TUN"},
  {"name":"Turkey", "alpha":"TR", "value":"TUR"},
  {"name":"Turkmenistan", "alpha":"TM", "value":"TKM"},
  {"name":"Tuvalu", "alpha":"TV", "value":"TUV"},
  {"name":"Uganda", "alpha":"UG", "value":"UGA"},
  {"name":"Ukraine", "alpha":"UA", "value":"UKR"},
  {"name":"United Arab Emirates", "alpha":"AE", "value":"ARE"},
  {"name":"United Kingdom", "alpha":"GB", "value":"GBR"},
  {"name":"United States of America", "alpha":"US", "value":"USA"},
  {"name":"Uruguay", "alpha":"UY", "value":"URY"},
  {"name":"Uzbekistan", "alpha":"UZ", "value":"UZB"},
  {"name":"Vanuatu", "alpha":"VU", "value":"VUT"},
  {"name":"Venezuela", "alpha":"VE", "value":"VEN"},
  {"name":"Viet Nam", "alpha":"VN", "value":"VNM"},
  {"name":"Virgin Islands, US", "alpha":"VI", "value":"VIR"},
  {"name":"Yemen", "alpha":"YE", "value":"YEM"},
  {"name":"Zambia", "alpha":"ZM", "value":"ZMB"},
  {"name":"Zimbabwe", "alpha":"ZW", "value":"ZWE"}
]

export default COUNTRIES_CODE_ISO;