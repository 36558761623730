import React, {useState} from "react";
import { Box } from "@mui/system";
import { Typography,Button } from "@mui/material";
import icons from "../../../../assets/icons";
import CampaignRow from "../../component/campaignRow";
import CreateCampaignDialog from "../../component/createCampaignDialog";
import { Campaign } from "../../../../lib/types/Voucher";
import { useTheme } from "@mui/material/styles";
import Advertisement from "../../../../components/advertisement";
import images from "../../../../assets/img";
import Placeholder from "../../../../components/placeholder/placeholder";

export default function CampaignListComponent({
  campaignList,
  accessRights,
  refreshList,
  hasAccess = false}:{campaignList: Array<Campaign | null>, accessRights: string[], refreshList:()=>void,hasAccess:boolean}) {
    const theme = useTheme();
    const [open, setOpen] = useState(false)
    const onClose = () => {
        setOpen(false)
        refreshList()
    }

    return (<>
      <Advertisement display={!hasAccess}>
      <img alt="advertisement" src={images.campaign_ads} 
        style={{
          width:"100%",
          // height:"100%" 
        }}/>
      </Advertisement>

        <Box sx={{borderBottom:"solid #DDE1E6 1px"}}>
        <Typography  sx={{borderBottom:"solid black 1px",width:"fit-content", fontSize:"16px",paddingBottom:"0.8rem"}}>Campaign list</Typography>
        </Box>
        <CreateCampaignDialog open={open} onClose={()=>onClose()}/>
        <Box sx={{
          padding: '1rem',
          [theme.breakpoints.down('md')]: {
            display:"flex",flexDirection:"column",justifyContent:"flex-start"
          },
          [theme.breakpoints.up('md')]: {
            display:"flex",alignItems:"center",justifyContent:"space-between"
          } 

          }}>
        <Typography variant="h1" fontWeight="400" >Campaign List</Typography>
        <Button
                type="button"
                variant="contained"
                color="secondary"
                fullWidth={false}
                disabled={!hasAccess}
                sx={{
                  fontSize: '16px',
                  fontWeight: '500',
                  textTransform: 'none',
                  borderRadius: '30px',
                  mt: '20px',
                }}
                onClick={()=>setOpen(true)}
                startIcon={<img alt="discount_icon" src={icons.discount} />}
              >
                Create Campaign 
              </Button>
        </Box>
        <Box sx={{width:"inherit"}}>
          {
            campaignList.map((campaign,index) => {
              if(campaign){
                return <CampaignRow key={index} campaign={campaign} accessRights={accessRights} refreshList={refreshList}/>
              }
              return null
            })
          }

        {campaignList.length === 0 &&
          <Placeholder
            icon={icons.marketing}
            title="No campaign created"
            description="Let's create the best campaign ever."
            buttonText="New Campaign"
            action={()=>setOpen(true)}
          />}
        </Box>
    </>)
}