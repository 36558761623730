import React from "react";
import BindCardComponent from "./bindCard.component";
import { endLoading, startLoading } from "../../../../../features/loading";
import cardApi from "../../../../../api/card";
import { useDispatch } from "react-redux";
import FileHelper from "../../../../../lib/helper/fileHelper";
import { useParams } from "react-router-dom";

export default function BindCardContainer({ currentProfileName }) {
  const [cards, setCards] = React.useState<any[]>([]);
  const dispatch = useDispatch();
  const { profileUuid } = useParams();

  const getCards = () => {
    dispatch(startLoading());
    cardApi.getCards().then(response => {
      const cards = response.data;

      if (Boolean(cards?.length)) {
        Promise.all(cards.map(async (card) => {
          let presigned_url = '';
          if (Boolean(card.image)) presigned_url = await FileHelper.getUrl(card.image);

          return ({
            presigned_url,
            ...card,
          })
        })).then(setCards);
      }
    }).finally(() => dispatch(endLoading()));
  }

  const bindProfile = (card_serial_number: string) => {
    cardApi.bindProfile({
      requestBody: {
        card_serial_number,
        profile_uuid: `${profileUuid}`,
      }
    })
      .then(getCards)
      .catch(console.log);
  }

  React.useEffect(() => {
    getCards();
    // eslint-disable-next-line
  }, []);

  return <BindCardComponent cards={cards} currentProfileName={currentProfileName} bindProfile={bindProfile} />
}