import React from "react";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function HistoryFilterDialog({ open, handleClose, historyStatus, setHistoryStatus, historyType, setHistoryType }) {
  const [status, setStatus] = React.useState(historyStatus);
  const [type, setType] = React.useState(historyType);

  return <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
    <DialogTitle>Filter Appointment History</DialogTitle>
    <DialogContent>
      <FormControl fullWidth sx={{ mt: '20px' }}>
        <InputLabel id="appointment-status-label">Appointment Status</InputLabel>
        <Select
          value={status}
          label="Account Status"
          labelId="appointment-status-label"
          onChange={e => setStatus(e.target.value)}
        >
          <MenuItem value={''} selected>All</MenuItem>
          <MenuItem value={'completed'}>Completed</MenuItem>
          <MenuItem value={'declined'}>Declined</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ mt: '20px' }}>
        <InputLabel id="appointment-type-label">Appointment Type</InputLabel>
        <Select
          value={type}
          label="Appointment Type"
          labelId="appointment-type-label"
          onChange={e => setType(e.target.value)}
        >
          <MenuItem value={''} selected>All</MenuItem>
          <MenuItem value={'requester'}>Requester</MenuItem>
          <MenuItem value={'respondent'}>Respondent</MenuItem>
        </Select>
      </FormControl>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <Button
          sx={{
            padding: '10px 35px',
            borderRadius: '30px',
            backgroundColor: '#373736',
            color: '#FECD20',
            '&:hover': {
              backgroundColor: '#252525',
              color: '#FECD20',
            }
          }}
          onClick={() => {
            setHistoryStatus(status);
            setHistoryType(type);
            handleClose();
          }}
        >
          Apply
        </Button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </DialogContent>
  </Dialog>
};

export default HistoryFilterDialog;