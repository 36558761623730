const timezones = [
  {
    "id": "Pacific/Midway",
    "text": "(GMT-11:00) Midway Island",
    "value": "-11:00"
  },
  {
    "id": "US/Samoa",
    "text": "(GMT-11:00) Samoa",
    "value": "-11:00"
  },
  {
    "id": "US/Hawaii",
    "text": "(GMT-10:00) Hawaii",
    "value": "-10:00"
  },
  {
    "id": "US/Alaska",
    "text": "(GMT-09:00) Alaska",
    "value": "-09:00"
  },
  {
    "id": "US/Pacific",
    "text": "(GMT-08:00) Pacific Time (US & Canada)",
    "value": "-08:00"
  },
  {
    "id": "America/Tijuana",
    "text": "(GMT-08:00) Tijuana",
    "value": "-08:00"
  },
  {
    "id": "US/Arizona",
    "text": "(GMT-07:00) Arizona",
    "value": "-07:00"
  },
  {
    "id": "US/Mountain",
    "text": "(GMT-07:00) Mountain Time (US & Canada)",
    "value": "-07:00"
  },
  {
    "id": "America/Chihuahua",
    "text": "(GMT-07:00) Chihuahua",
    "value": "-07:00"
  },
  {
    "id": "America/Mazatlan",
    "text": "(GMT-07:00) Mazatlan",
    "value": "-07:00"
  },
  {
    "id": "America/Mexico_City",
    "text": "(GMT-06:00) Mexico City",
    "value": "-06:00"
  },
  {
    "id": "America/Monterrey",
    "text": "(GMT-06:00) Monterrey",
    "value": "-06:00"
  },
  {
    "id": "Canada/Saskatchewan",
    "text": "(GMT-06:00) Saskatchewan",
    "value": "-06:00"
  },
  {
    "id": "US/Central",
    "text": "(GMT-06:00) Central Time (US & Canada)",
    "value": "-06:00"
  },
  {
    "id": "US/Eastern",
    "text": "(GMT-05:00) Eastern Time (US & Canada)",
    "value": "-05:00"
  },
  {
    "id": "US/East-Indiana",
    "text": "(GMT-05:00) Indiana (East)",
    "value": "-05:00"
  },
  {
    "id": "America/Bogota",
    "text": "(GMT-05:00) Bogota",
    "value": "-05:00"
  },
  {
    "id": "America/Lima",
    "text": "(GMT-05:00) Lima",
    "value": "-05:00"
  },
  {
    "id": "America/Caracas",
    "text": "(GMT-04:30) Caracas",
    "value": "-04:30"
  },
  {
    "id": "Canada/Atlantic",
    "text": "(GMT-04:00) Atlantic Time (Canada)",
    "value": "-04:00"
  },
  {
    "id": "America/La_Paz",
    "text": "(GMT-04:00) La Paz",
    "value": "-04:00"
  },
  {
    "id": "America/Santiago",
    "text": "(GMT-04:00) Santiago",
    "value": "-04:00"
  },
  {
    "id": "Canada/Newfoundland",
    "text": "(GMT-03:30) Newfoundland",
    "value": "-03:30"
  },
  {
    "id": "America/Buenos_Aires",
    "text": "(GMT-03:00) Buenos Aires",
    "value": "-03:00"
  },
  {
    "id": "Greenland",
    "text": "(GMT-03:00) Greenland",
    "value": "-03:00"
  },
  {
    "id": "Atlantic/Stanley",
    "text": "(GMT-02:00) Stanley",
    "value": "-02:00"
  },
  {
    "id": "Atlantic/Azores",
    "text": "(GMT-01:00) Azores",
    "value": "-01:00"
  },
  {
    "id": "Atlantic/Cape_Verde",
    "text": "(GMT-01:00) Cape Verde Is.",
    "value": "-01:00"
  },
  {
    "id": "Africa/Casablanca",
    "text": "(GMT) Casablanca",
    "value": "+00:00"
  },
  {
    "id": "Europe/Dublin",
    "text": "(GMT) Dublin",
    "value": "+00:00"
  },
  {
    "id": "Europe/Lisbon",
    "text": "(GMT) Lisbon",
    "value": "+00:00"
  },
  {
    "id": "Europe/London",
    "text": "(GMT) London",
    "value": "+00:00"
  },
  {
    "id": "Africa/Monrovia",
    "text": "(GMT) Monrovia",
    "value": "+00:00"
  },
  {
    "id": "Europe/Amsterdam",
    "text": "(GMT+01:00) Amsterdam",
    "value": "+01:00"
  },
  {
    "id": "Europe/Belgrade",
    "text": "(GMT+01:00) Belgrade",
    "value": "+01:00"
  },
  {
    "id": "Europe/Berlin",
    "text": "(GMT+01:00) Berlin",
    "value": "+01:00"
  },
  {
    "id": "Europe/Bratislava",
    "text": "(GMT+01:00) Bratislava",
    "value": "+01:00"
  },
  {
    "id": "Europe/Brussels",
    "text": "(GMT+01:00) Brussels",
    "value": "+01:00"
  },
  {
    "id": "Europe/Budapest",
    "text": "(GMT+01:00) Budapest",
    "value": "+01:00"
  },
  {
    "id": "Europe/Copenhagen",
    "text": "(GMT+01:00) Copenhagen",
    "value": "+01:00"
  },
  {
    "id": "Europe/Ljubljana",
    "text": "(GMT+01:00) Ljubljana",
    "value": "+01:00"
  },
  {
    "id": "Europe/Madrid",
    "text": "(GMT+01:00) Madrid",
    "value": "+01:00"
  },
  {
    "id": "Europe/Paris",
    "text": "(GMT+01:00) Paris",
    "value": "+01:00"
  },
  {
    "id": "Europe/Prague",
    "text": "(GMT+01:00) Prague",
    "value": "+01:00"
  },
  {
    "id": "Europe/Rome",
    "text": "(GMT+01:00) Rome",
    "value": "+01:00"
  },
  {
    "id": "Europe/Sarajevo",
    "text": "(GMT+01:00) Sarajevo",
    "value": "+01:00"
  },
  {
    "id": "Europe/Skopje",
    "text": "(GMT+01:00) Skopje",
    "value": "+01:00"
  },
  {
    "id": "Europe/Stockholm",
    "text": "(GMT+01:00) Stockholm",
    "value": "+01:00"
  },
  {
    "id": "Europe/Vienna",
    "text": "(GMT+01:00) Vienna",
    "value": "+01:00"
  },
  {
    "id": "Europe/Warsaw",
    "text": "(GMT+01:00) Warsaw",
    "value": "+01:00"
  },
  {
    "id": "Europe/Zagreb",
    "text": "(GMT+01:00) Zagreb",
    "value": "+01:00"
  },
  {
    "id": "Europe/Athens",
    "text": "(GMT+02:00) Athens",
    "value": "+02:00"
  },
  {
    "id": "Europe/Bucharest",
    "text": "(GMT+02:00) Bucharest",
    "value": "+02:00"
  },
  {
    "id": "Africa/Cairo",
    "text": "(GMT+02:00) Cairo",
    "value": "+02:00"
  },
  {
    "id": "Africa/Harare",
    "text": "(GMT+02:00) Harare",
    "value": "+02:00"
  },
  {
    "id": "Europe/Helsinki",
    "text": "(GMT+02:00) Helsinki",
    "value": "+02:00"
  },
  {
    "id": "Europe/Istanbul",
    "text": "(GMT+02:00) Istanbul",
    "value": "+02:00"
  },
  {
    "id": "Asia/Jerusalem",
    "text": "(GMT+02:00) Jerusalem",
    "value": "+02:00"
  },
  {
    "id": "Europe/Kiev",
    "text": "(GMT+02:00) Kyiv",
    "value": "+02:00"
  },
  {
    "id": "Europe/Minsk",
    "text": "(GMT+02:00) Minsk",
    "value": "+02:00"
  },
  {
    "id": "Europe/Riga",
    "text": "(GMT+02:00) Riga",
    "value": "+02:00"
  },
  {
    "id": "Europe/Sofia",
    "text": "(GMT+02:00) Sofia",
    "value": "+02:00"
  },
  {
    "id": "Europe/Tallinn",
    "text": "(GMT+02:00) Tallinn",
    "value": "+02:00"
  },
  {
    "id": "Europe/Vilnius",
    "text": "(GMT+02:00) Vilnius",
    "value": "+02:00"
  },
  {
    "id": "Asia/Baghdad",
    "text": "(GMT+03:00) Baghdad",
    "value": "+03:00"
  },
  {
    "id": "Asia/Kuwait",
    "text": "(GMT+03:00) Kuwait",
    "value": "+03:00"
  },
  {
    "id": "Africa/Nairobi",
    "text": "(GMT+03:00) Nairobi",
    "value": "+03:00"
  },
  {
    "id": "Asia/Riyadh",
    "text": "(GMT+03:00) Riyadh",
    "value": "+03:00"
  },
  {
    "id": "Europe/Moscow",
    "text": "(GMT+03:00) Moscow",
    "value": "+03:00"
  },
  {
    "id": "Asia/Tehran",
    "text": "(GMT+03:30) Tehran",
    "value": "+03:30"
  },
  {
    "id": "Asia/Baku",
    "text": "(GMT+04:00) Baku",
    "value": "+04:00"
  },
  {
    "id": "Europe/Volgograd",
    "text": "(GMT+04:00) Volgograd",
    "value": "+04:00"
  },
  {
    "id": "Asia/Muscat",
    "text": "(GMT+04:00) Muscat",
    "value": "+04:00"
  },
  {
    "id": "Asia/Tbilisi",
    "text": "(GMT+04:00) Tbilisi",
    "value": "+04:00"
  },
  {
    "id": "Asia/Yerevan",
    "text": "(GMT+04:00) Yerevan",
    "value": "+04:00"
  },
  {
    "id": "Asia/Kabul",
    "text": "(GMT+04:30) Kabul",
    "value": "+04:30"
  },
  {
    "id": "Asia/Karachi",
    "text": "(GMT+05:00) Karachi",
    "value": "+05:00"
  },
  {
    "id": "Asia/Tashkent",
    "text": "(GMT+05:00) Tashkent",
    "value": "+05:00"
  },
  {
    "id": "Asia/Kolkata",
    "text": "(GMT+05:30) Kolkata",
    "value": "+05:30"
  },
  {
    "id": "Asia/Kathmandu",
    "text": "(GMT+05:45) Kathmandu",
    "value": "+05:45"
  },
  {
    "id": "Asia/Yekaterinburg",
    "text": "(GMT+06:00) Ekaterinburg",
    "value": "+06:00"
  },
  {
    "id": "Asia/Almaty",
    "text": "(GMT+06:00) Almaty",
    "value": "+06:00"
  },
  {
    "id": "Asia/Dhaka",
    "text": "(GMT+06:00) Dhaka",
    "value": "+06:00"
  },
  {
    "id": "Asia/Novosibirsk",
    "text": "(GMT+07:00) Novosibirsk",
    "value": "+07:00"
  },
  {
    "id": "Asia/Bangkok",
    "text": "(GMT+07:00) Bangkok",
    "value": "+07:00"
  },
  {
    "id": "Asia/Jakarta",
    "text": "(GMT+07:00) Jakarta",
    "value": "+07:00"
  },
  {
    "id": "Asia/Krasnoyarsk",
    "text": "(GMT+08:00) Krasnoyarsk",
    "value": "+08:00"
  },
  {
    "id": "Asia/Chongqing",
    "text": "(GMT+08:00) Chongqing",
    "value": "+08:00"
  },
  {
    "id": "Asia/Hong_Kong",
    "text": "(GMT+08:00) Hong Kong",
    "value": "+08:00"
  },
  {
    "id": "Asia/Kuala_Lumpur",
    "text": "(GMT+08:00) Kuala Lumpur",
    "value": "+08:00"
  },
  {
    "id": "Australia/Perth",
    "text": "(GMT+08:00) Perth",
    "value": "+08:00"
  },
  {
    "id": "Asia/Singapore",
    "text": "(GMT+08:00) Singapore",
    "value": "+08:00"
  },
  {
    "id": "Asia/Taipei",
    "text": "(GMT+08:00) Taipei",
    "value": "+08:00"
  },
  {
    "id": "Asia/Ulaanbaatar",
    "text": "(GMT+08:00) Ulaan Bataar",
    "value": "+08:00"
  },
  {
    "id": "Asia/Urumqi",
    "text": "(GMT+08:00) Urumqi",
    "value": "+08:00"
  },
  {
    "id": "Asia/Irkutsk",
    "text": "(GMT+09:00) Irkutsk",
    "value": "+09:00"
  },
  {
    "id": "Asia/Seoul",
    "text": "(GMT+09:00) Seoul",
    "value": "+09:00"
  },
  {
    "id": "Asia/Tokyo",
    "text": "(GMT+09:00) Tokyo",
    "value": "+09:00"
  },
  {
    "id": "Australia/Adelaide",
    "text": "(GMT+09:30) Adelaide",
    "value": "+09:30"
  },
  {
    "id": "Australia/Darwin",
    "text": "(GMT+09:30) Darwin",
    "value": "+09:30"
  },
  {
    "id": "Asia/Yakutsk",
    "text": "(GMT+10:00) Yakutsk",
    "value": "+10:00"
  },
  {
    "id": "Australia/Brisbane",
    "text": "(GMT+10:00) Brisbane",
    "value": "+10:00"
  },
  {
    "id": "Australia/Canberra",
    "text": "(GMT+10:00) Canberra",
    "value": "+10:00"
  },
  {
    "id": "Pacific/Guam",
    "text": "(GMT+10:00) Guam",
    "value": "+10:00"
  },
  {
    "id": "Australia/Hobart",
    "text": "(GMT+10:00) Hobart",
    "value": "+10:00"
  },
  {
    "id": "Australia/Melbourne",
    "text": "(GMT+10:00) Melbourne",
    "value": "+10:00"
  },
  {
    "id": "Pacific/Port_Moresby",
    "text": "(GMT+10:00) Port Moresby",
    "value": "+10:00"
  },
  {
    "id": "Australia/Sydney",
    "text": "(GMT+10:00) Sydney",
    "value": "+10:00"
  },
  {
    "id": "Asia/Vladivostok",
    "text": "(GMT+11:00) Vladivostok",
    "value": "+11:00"
  },
  {
    "id": "Asia/Magadan",
    "text": "(GMT+12:00) Magadan",
    "value": "+12:00"
  },
  {
    "id": "Pacific/Auckland",
    "text": "(GMT+12:00) Auckland",
    "value": "+12:00"
  },
  {
    "id": "Pacific/Fiji",
    "text": "(GMT+12:00) Fiji",
    "value": "+12:00"
  }
]

export default timezones;