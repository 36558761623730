import axios from "axios";

interface Data {
  code: number;
  data: null;
  message: string | null;
  success: boolean;
}

interface RequestBody {
  email: string;
  reset_password_token: string;
  new_password: string;
  confirm_password: string;
}

const resetPassword = ({ requestBody }: { requestBody: RequestBody }): Promise<Data> => {
  return new Promise((resolve, reject) => {
    axios.post("basicauth/resetPassword", requestBody, { headers: {} })
      .then(response => resolve(response.data))
      .catch(reject);
  })
}

export default resetPassword