import ApiCalendar from "react-google-calendar-api";

const config = {
  clientId: `${process.env.REACT_APP_GOOGLE_CALENDAR_CLIENT_ID}`,
  apiKey: `${process.env.REACT_APP_GOOGLE_CALENDAR_API_KEY}`,
  scope: "https://www.googleapis.com/auth/calendar.app.created",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

export const apiCalendar = new ApiCalendar(config);

export const linkGoogleCalendar = async () => {
  const gcal_auth = localStorage.getItem('gcal_auth');

  if (Boolean(gcal_auth)) {
    const parsed_auth = JSON.parse(gcal_auth ?? '{}');
    const { initTime, expires_in } = parsed_auth;
    const gapiToken = gapi.client.setToken(parsed_auth);

    // Reuse token if not expired
    if (Date.now() - Number(initTime ?? 0) < Number(expires_in ?? 0) * 1000) {
      return gapiToken;
    }
  }

  return apiCalendar.handleAuthClick().then((res: any) => {
    const auth = {
      ...res,
      initTime: Date.now(),
    }

    localStorage.setItem('gcal_auth', JSON.stringify(auth));
    return auth;
  });
}

export const getToken = () => {
  const gcal_auth = localStorage.getItem('gcal_auth');

  if (Boolean(gcal_auth)) {
    const { initTime, expires_in } = JSON.parse(gcal_auth ?? '{}');

    // // Return null if expired
    if (Date.now() - Number(initTime ?? 0) > Number(expires_in ?? 0) * 1000) {
      return null;
    }
  }

  return gapi.client.getToken();
}