import React from 'react'
import images from '../../../../assets/img'
import { Box,Typography,Button } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom'
import { PORTAL_DASHBOARD } from '../../../../lib/constants/routes';
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background:  'black !important'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '13px',
          fontWeight: 500,
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});
export default function Error(){
  const navigate = useNavigate();
  return(
  <ThemeProvider theme={defaultTheme}>
   <Box style={{
    height:'100vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'space-evenly'
    }}>
  <img src={images.error_page} alt="error"/>
  <Box  style={{maxWidth:"646px",display:'flex',alignItems:'center',flexDirection:'column'}}>
  <h1> Page Not Found</h1>
  <Typography style={{textAlign: 'center',fontSize:'1.2rem'}}> We're sorry, the page you requested could not be found. Please go back to the homepage!</Typography>
  </Box>
  <Box>
  <Button
    type="button"
    fullWidth
    variant="contained"
    onClick={() => navigate(PORTAL_DASHBOARD)}
    sx={{ mt: 2, mb:2, gap:'1rem',borderRadius: '30px',height:'52px', color: '#FECD20' }}
  >
    Return to Homepage
  </Button>
  </Box>
  </Box>
  </ThemeProvider>
  )
}