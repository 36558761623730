import React from "react";
import { Box, Button, List, ListItem, Paper, Typography } from "@mui/material";
import images from "../../../../../assets/img";

export default function BindCardComponent({ cards, currentProfileName, bindProfile }) {
  return <Paper elevation={0} sx={{
    display: 'flex',
    p: '30px',
    bgcolor: '#F9F9F9',
    alignItems: 'center',
  }}>
    <List sx={{ py: 0, width: '100%' }}>
      {cards.map((card, index) => <ListItem
        key={index}
        disableGutters
        divider={index + 1 !== cards.length}
        secondaryAction={<Button
          disabled={currentProfileName === card.profile_name}
          variant="contained"
          color="secondary"
          onClick={() => bindProfile(card.card_serial_number)}
        >
          Bind
        </Button>}
      >
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={Boolean(card.image) ? card.image : images.landing_page_default_image} alt='Card thumbnail' width={80} style={{ marginRight: '10px ', border: '1px solid #C5C5C5', borderRadius: '8px' }} />
            <Box>
              <Box>
                <Typography component={'span'} sx={{ fontSize: '20px', fontWeight: 500, mr: '10px' }}>Card #{index + 1}</Typography>
              </Box>
              <Typography>Profile name: {card.profile_name}</Typography>
            </Box>
          </Box>
        </Box>
      </ListItem>)}
    </List>
  </Paper>
}