import React, { useEffect } from 'react'
import ThemeOptionComponent from './theme-option.component'
import { setTheme, setThemeBackground } from '../../../../../features/landing-page';
import { useDispatch } from 'react-redux';
import { defaultTheme } from '../../../../../lib/constants/themeOptions';
import { useParams } from 'react-router-dom';

interface Theme {
  name: string;
  highlight: {
    primary_color: string,
    secondary_color: string,
  },
  button: {
    primary_color: string,
    primary_font_color: string,
    secondary_color: string,
    secondary_font_color: string,
  },
  primary_background: string,
  primary_font_color: string,
  secondary_background: string,
  secondary_font_color: string,
}

interface ThemeOptionContainerProps {
  handleTheme: (theme: Theme) => void;
  handleBackground: (color: string) => void;
}

export default function ThemeOptionContainer({ handleTheme, handleBackground }: ThemeOptionContainerProps) {
  const dispatch = useDispatch()
  const params = useParams();
  const [currentTheme, setCurrentTheme] = React.useState<Theme>((params.profileUuid === undefined) ? defaultTheme : {});
  const [background, setBackground] = React.useState<string>((params.profileUuid === undefined) ? "#FFFFFF" : "");

  const handleThemeChange = (theme: Theme) => {
    setCurrentTheme(theme)
    handleTheme(theme)
  }
  const handleBackgroundChange = (color: string) => {
    setBackground(color)
    handleBackground(color)
  }

  const setCustomColor = (color: object) => {
    setCurrentTheme({ ...currentTheme, ...color })
  }
  const setCustomBackground = (color: string) => {
    setBackground(color)
  }
  useEffect(() => {
    if (Boolean(currentTheme?.name)) dispatch(setTheme(currentTheme))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme])

  useEffect(() => {
    if (Boolean(background)) dispatch(setThemeBackground(background))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [background])

  return <ThemeOptionComponent
    handleThemeChange={handleThemeChange}
    handleBackgroundChange={handleBackgroundChange}
    setCustomColor={setCustomColor}
    setCustomBackground={setCustomBackground}
  />
}