import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const getLocationList = ({start,end,profileUUID}:{
  start: string | null,
  end: string | null,
  profileUUID : string | null
}): Promise<Data> => {

  const user = JSON.parse(localStorage.getItem('user'))
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  let query: Array<[string, string]> = [];
  if(start) query.push(['start_date', start]);
  if(end) query.push(['end_date', end]);
  if(profileUUID) query.push(['profile_uuid', profileUUID]);

  const params = new URLSearchParams(query);

  return new Promise((resolve, reject) => {
      axios.get(`dashboard/getLocationList`,{params})
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default getLocationList;
