import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../../../../features/admin';
import { CART, PORTAL_CARDS } from "../../../../../../lib/constants/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import productApi from "../../../../../../api/product";
import FileHelper from "../../../../../../lib/helper/fileHelper";
import { v4 as uuidv4 } from 'uuid';
import EditTemplateComponent from "../../../select-template/pages/edit/editTemplate.component";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function EditTemplateContainer() {
  const query = useQuery();
  const type: string = query.get('type') ?? 'template';
  const { cartUUID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cartItem, setCartItem] = useState<any>();
  const [template, setTemplate] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const handleBack = () => {
    navigate(`${PORTAL_CARDS}/${CART}`);
  }

  const handleSubmit = async (values) => {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    let logo_url = cartItem.logo_url;

    if (Boolean(values.logo) && values.logo !== cartItem.logo_url) {
      const logoFullPath = `user/${user.access_token_payload.uuid}/card/logo/${uuidv4()}_${values.logo.name}`;
      logo_url = await FileHelper.uploadFile(logoFullPath, values.logo);
    }

    if (values.customization) {
      // Hide name and position if no value entered
      const card_name: any = document.getElementById('card-name');
      const card_position: any = document.getElementById('card-position');

      if (Boolean(card_name) && !Boolean(values.personName)) card_name.style.visibility = 'hidden';
      if (Boolean(card_position) && !Boolean(values.jobTitle)) card_position.style.visibility = 'hidden';
    }

    const item = {
      product_uuid: template.uuid,
      card_name: template.name,
      quantity: values.quantity,
      card_variation: values.selectedVariant.name,
      font_color_code: values.textColor,
      name: values.customization ? values.personName : '',
      position: values.customization ? values.jobTitle : '',
      logo_url: values.customization ? logo_url : '',
      pixelcare: values.pixelcare ? 1 : 0,
      front_thumbnail: values.selectedVariant.front,
      back_thumbnail: values.selectedVariant.back,
      customization: values.customization,
    };

    const requestBody = {
      ...item,
      cart_uuid: `${cartUUID}`,
    };
    productApi.editCart({ requestBody })
      .then(() => navigate(`${PORTAL_CARDS}/${CART}`))
      .catch(console.log)
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "My NFC Card", path: PORTAL_CARDS },
        { name: "Cart", path: `${PORTAL_CARDS}/${CART}` },
        { name: "Edit Cart Item", path: null },
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get product or cart item details
  useEffect(() => {
    productApi.getCartDetails(`${cartUUID}`).then(res => {
      const cart_item = res.data;
      setCartItem(cart_item);
      productApi.getProductDetails(cart_item.product_uuid, true).then(response => {
        const product = response.data;
        setTemplate({
          uuid: cart_item.product_uuid,
          name: product.card_name,
          description: product.description,
          currency: 'USD',
          current_price: {
            base: product.discount_price,
            customization: product.customization_discount_price,
            pixelcare: 6,
          },
          previous_price: {
            base: product.price,
            customization: product.customization_price,
            pixelcare: 10,
          },
          is_popular: true,
          variants: product.variation.map(variation => ({
            name: variation.variations,
            front: variation.front_thumbnail,
            back: variation.back_thumbnail,
            sample: variation.sample_thumbnail,
            sampleViewSide: variation.sample_view_side,
            uuid: variation.uuid,
            type: product.source
          })),
          textColors: [
            {
              name: 'Dark Gray',
              value: '#586C6C',
            },
            {
              name: 'White',
              value: '#F9FAFC',
            },
            {
              name: 'Black',
              value: '#373736',
            },
          ]
        });
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return Boolean(template) && <EditTemplateComponent
    isLoading={isLoading}
    type={type}
    cartItem={cartItem}
    template={template}
    handleBack={handleBack}
    handleSubmit={handleSubmit}
    handleCustomize={() => { }}
  />
}