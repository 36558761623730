import axios from "axios";

export interface UserDetails {
  uuid: string | null,
  name: string | null,
  email: string | null,
  birthday?: string | null,
  phone_code: string | null,
  phone_number: string | null,
  profile_image?: string | null,
  address_1?: string | null,
  address_2?: string | null,
}
interface Data {
  data: UserDetails
}

const getDetail = (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get("user/details")
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default getDetail;
