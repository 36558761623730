import React from "react";
import { Box,Button, Divider, IconButton, Typography} from "@mui/material";
import CustomSwitch from "../../../components/general/customSwitch";
import icons from "../../../assets/icons";
import { useNavigate } from "react-router-dom";
import {PORTAL_CAMPAIGN_DETAIL} from "../../../lib/constants/routes"
import campaignApi from "../../../api/campaign";
import { useDispatch } from "react-redux";
import { openErrorDialog } from "../../../features/error-dialog";
import { openResponseDialog } from "../../../features/response-dialog";
import { startLoading, endLoading } from "../../../features/loading";
import { Campaign } from "../../../lib/types/Voucher";
import { setCampaignName } from "../../../features/campaign/campaign.slice";
import DeleteDialog from "../../../components/dialog/delete-dialog";
export default function CampaignRow({campaign, accessRights, refreshList}:{campaign: Campaign, accessRights: string[], refreshList:()=>void}) {

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = React.useState(false);

    const viewMore = (id : string) => {
        dispatch(setCampaignName(campaign.campaign_name))
        navigate(PORTAL_CAMPAIGN_DETAIL.replace(":id",id)+`?campaignName=${campaign.campaign_name}`);
    }

    const deleteCampaign = async (campaignUUID:string) => {
        setIsOpen(false)
        dispatch(startLoading())
        await campaignApi.deleteCampaign({campaignUUID:campaignUUID}).then(response => {
            if(response.success){
                dispatch(openResponseDialog({
                    title: "Success",
                    description: "Delete Campaign Success"
                }))
                refreshList()
            }else{
                dispatch(openErrorDialog({
                    title: "Delete Campaign Error",
                    description: response.message
                }))
            }
        }).catch(error => {
            dispatch(openErrorDialog({
                title: "Delete Campaign Error",
                description: error.message
            }))
        }).finally(() => {
            dispatch(endLoading())
        })
    }

    const updateStatus = async ({campaignUUID, active}) => {
        dispatch(startLoading())
        await campaignApi.editStatus({campaignUUID:campaignUUID, active: active})
        .then(response => {
            if(response.success){
                dispatch(openResponseDialog({
                    title: "Success",
                    description: "Update Campaign Status Success"
                }))
                refreshList()
            }else{
                dispatch(openErrorDialog({
                    title: "Update Campaign Status Error",
                    description: response.message
                }))
            }
        }).catch(error => {
            dispatch(openErrorDialog({
                title: "Update Campaign Status Error",
                description: error.message
            }))
        }).finally(() => {
            dispatch(endLoading())
        })
    }

    return(<>
        <DeleteDialog
            key={campaign.campaign_uuid}
            isOpen={isOpen}
            content={{
                title: "Delete Campaign",
                description: `Are you sure you want to delete this campaign - ${campaign.campaign_name} ?`,
                fullWidth: false
            }}
            handleConfirm={()=>deleteCampaign(campaign.campaign_uuid)}
            handleClose={()=>setIsOpen(false)}
        />
        <Box sx={{mb:"1rem",padding:"1rem",background:"#EEEEE4", width:"inherit",  borderRadius:"8px"}}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
                }}>
                <Typography variant="h3">{campaign.campaign_name}</Typography>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                <CustomSwitch
                    disabled = {!accessRights.includes('campaign edit')}
                    checked={campaign.active === 1 ? true : false} 
                    onChange={e => updateStatus(
                        {campaignUUID:campaign.campaign_uuid, active:e.target.checked}
                    )}>    
                </CustomSwitch>
                {accessRights.includes('campaign edit') && <IconButton onClick={() => setIsOpen(true)}>
                    <img src={icons.delete_icon} alt="trash can" />
                </IconButton>}
                </Box>

            </Box>
            <Box sx={{pt:"1rem",pb:"1rem"}}>
                <Button
                onClick={()=>viewMore(campaign.campaign_uuid)}
                sx={{borderRadius:"30px", boxShadow:"none"}}
                    type="button"
                    variant="contained"
                    color="primary">
                    View More   
                </Button>
            </Box>
            <Divider></Divider>
            <Box sx={{pt:'1rem'}}>
                <Typography>Profile Link</Typography>
                <ul>
                    { campaign.linked_profile.map((link,index) => {
                        return <li key={index}>{link}</li>
                    })}
                    { campaign.linked_profile.length === 0 && <li>No profile link</li>}
                </ul>
                {}
            </Box>
        </Box>
        </>
    )
}