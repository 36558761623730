import React from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, styled } from "@mui/material";

const StyledButton = styled(Button)({
  padding: '10px 35px',
  borderRadius: '30px',
  backgroundColor: '#373736',
  color: '#FECD20',
  '&:hover': {
    backgroundColor: '#252525',
    color: '#FECD20',
  }
});

function ConfirmationDialog({ open, handleClose, handleDelete, contact }) {

  return <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { borderRadius: '25px', minWidth: '400px' } }}>
    <DialogTitle>Delete Contact?</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to delete {contact !== undefined ? contact['name'] : 'this contact'} from your contacts?
      </DialogContentText>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <StyledButton onClick={() => {
          handleDelete();
          handleClose();
        }}>Delete</StyledButton>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </DialogContent>
  </Dialog>
};

export default ConfirmationDialog;