import axios from 'axios';

interface Reschedule {
  send_email?: boolean;
  appointment_uuid: string;
  date: string;
  time: string;
  remark: string;
}

const reschedule = (requestBody: Reschedule) => {
  const storedUser = localStorage.getItem('user');
  const body = {
    send_email: true,
    ...requestBody,
  }

  if (storedUser === null) {
    return Promise.resolve({
      code: 404,
      data: null,
      message: 'Please log in',
      success: false,
    });
  }

  const user = JSON.parse(storedUser || '{}')
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return axios.post('/appointment/reschedule', body);
}

export default reschedule;