import React from 'react';
import { Box, Button, Container, ThemeProvider, Typography, createTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const defaultTheme = createTheme({
  palette: {
    secondary: {
      main: '#373736',
      contrastText: '#FECD20',
    },
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    button: {
      textTransform: 'none',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "30px"
        }
      },
    },
  },
});

export default function Fail() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/portal/settings`);
  }

  return <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="sm" sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      alignContent: 'space-around',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
    }}>
      <Box sx={{
        display:'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
      <Typography sx={{ fontSize: '31px', fontWeight: 500, textAlign: 'center', mt: '20px' }}>
        Failed
      </Typography>
      <Typography sx={{ fontSize: '20px', fontWeight: 400, textAlign: 'center' }}>
        Wifi connection low
      </Typography>
      <Typography sx={{ fontSize: '20px', fontWeight: 400, textAlign: 'center' }}>
        Incorrect OTP
      </Typography>
      <Typography sx={{ fontWeight: 400, textAlign: 'center', mb: '20px' }} gutterBottom>
        Try Again
      </Typography>
      </Box>
      <Button onClick={handleBack} variant='contained' color='secondary' sx={{ px: '40px', maxWidth: '115px' }}>Back</Button>
    </Container>
  </ThemeProvider>
}