import React from 'react'
import { Box, TextField } from '@mui/material'
import icons from '../../../../assets/icons'
import { EMAIL, PHONE_HOME, PHONE_MOBILE, ADDRESS, WEBSITE, WHATSAPP, FAX } from '../../../../lib/constants/basiccontacttypes';
import { BEHANCE, FACEBOOK, INSTAGRAM, LINKEDIN, PINTEREST, SKYPE, SNAPCHAT, TELEGRAM, TIKTOK, TWITTER, VIBER, WECHAT, YOUTUBE } from '../../../../lib/constants/socialmediatypes';

const DraggableItem = ({ icon, value, setVal, handleRemove, disabled = false, errors, isSocial = false, category = '' }) => {

  const getPlaceholder = () => {
    switch (category) {
      case EMAIL:
        return "Enter Email here";
      case PHONE_HOME:
        return "Enter Home Phone here";
      case WHATSAPP:
        return "Enter Whatsapp here";
      case FAX:
        return "Enter Fax here";
      case PHONE_MOBILE:
        return "Enter Mobile Phone here";
      case ADDRESS:
        return "Enter Address here";
      case WEBSITE:
        return "Enter Website here";
      case FACEBOOK:
        return "Enter Facebook here";
      case INSTAGRAM:
        return "Enter Instagram here";
      case LINKEDIN:
        return "Enter LinkedIn here";
      case TWITTER:
        return "Enter Twitter here";
      case SNAPCHAT:
        return "Enter Snapchat here";
      case SKYPE:
        return "Enter Skype here";
      case BEHANCE:
        return "Enter Behance here";
      case YOUTUBE:
        return "Enter Youtube here";
      case PINTEREST:
        return "Enter Pinterest here";
      case TELEGRAM:
        return "Enter Telegram here";
      case WECHAT:
        return "Enter Wechat here";
      case TIKTOK:
        return "Enter Tiktok here";
      case VIBER:
        return "Enter Viber here";
      default:
        return "Enter here";
    }
  }

  return (
    <Box sx={{ borderLeft: '2px solid', display: 'flex', flexWrap: 'wrap', alignItems: 'center', opacity: disabled ? 0.5 : 1, justifyContent: 'space-between' }}>
      <img src={icons.more_02} alt='' style={{ marginLeft: '10px', marginRight: '10px', order: 1 }} />
      <Box component={'span'} sx={{ display: 'flex', minWidth: '250px', flex: 1, order: { xs: 3, sm: 2 } }}>
        {icon}
        <TextField
          color='secondary'
          margin="dense"
          required
          value={value}
          onChange={setVal}
          disabled={disabled}
          placeholder={getPlaceholder()}
          inputProps={isSocial ? { pattern: "[Hh][Tt][Tt][Pp][Ss]?:(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::{2,5})?(?:[^]*)?" } : undefined}
          helperText={isSocial ? !/[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/.test(value) ? 'Please enter valid URL (including https://...)' : undefined : undefined}
          error={isSocial ? !/[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?/.test(value) : false}
          sx={{ flex: 1, background: 'white' }}
        />
      </Box>
      <Box sx={{ order: { xs: 2, sm: 3 } }}>
        <img
          src={icons.delete_icon}
          alt=''
          style={{ marginLeft: '10px', marginRight: '10px' }}
          onClick={disabled ? () => { } : handleRemove}
        />
      </Box>
    </Box>
  );
}
export default DraggableItem