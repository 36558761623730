import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import CountryOption from "../../../../../components/country-option";
import StateOption from "../../../../../components/state-option";
import { GetCountries, GetState } from 'react-country-state-city';
import { Formik, Form } from "formik";
import { Billing } from "../../../../../lib/types/User";

interface AddressFormProps {
  title: string;
  initialValues: Billing;
  handleSubmit: (values: any) => void;
}

export default function AddressForm({ title, initialValues: initialBilling, handleSubmit }: AddressFormProps) {
  return <Box sx={{
    display: "flex",
    flexDirection: "column",
    gap: '1rem',
    maxWidth: '651px',
    mt: '20px',
  }}>
    <Typography fontWeight={500}>
      {title}
    </Typography>
    <Formik
      enableReinitialize
      initialValues={initialBilling}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleSubmit,
        handleChange,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box>
            <Typography component='label' variant='h5' className="required">
              Address
            </Typography>
            <Box sx={{ display: "flex", gap: '1rem' }}>
              <TextField
                fullWidth
                value={values.address_1}
                id="address_1"
                name="address_1"
                size="small"
                variant="outlined"
                onChange={handleChange}
                required
              />
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                id="address_2"
                name="address_2"
                value={values.address_2}
                onChange={handleChange}
              />
            </Box>
            <Typography component='label' variant='h5' className="required">
              City
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              id="city"
              name="city"
              value={values.city}
              onChange={handleChange}
              required
            />

            <Typography component='label' variant='h5' className="required">
              State
            </Typography>
            <StateOption
              countryIso2={values.country}
              countryIso3={null}
              state={values.state}
              handleChange={(value) => setFieldValue('state', value)}
            />

            <Typography component='label' variant='h5' className="required">
              Zip
            </Typography>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              id="zip"
              name="zip"
              value={values.zip}
              onChange={handleChange}
              required
            />

            <Typography component='label' variant='h5' className="required">
              Country
            </Typography>
            <CountryOption
              country={values.country}
              handleChange={(value: string) => {
                GetCountries().then((result) => {
                  const country = result.find((ctry) => ctry.iso2 === value);
                  GetState(country.id).then((result) => {
                    setFieldValue('state', result.length > 0 && country.name !== 'Malaysia' ? result[0].name : 'Kuala Lumpur');
                  });
                });
                setFieldValue('country', value);
              }}
            />
          </Box>
          <Box sx={{ pt: '1rem' }}>
            <Button variant="contained" color="secondary" type="submit">
              Save Changes
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  </Box>;
}
