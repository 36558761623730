import React, {useEffect,useState} from 'react'
import VideoComponent from './video.component'
import { useSelector } from "react-redux";

export default function VideoContainer(){
  const video =  useSelector(state => state.landingPage.profile.video)
  const [videoData,setVideoData] =  useState([])
  const setVideo = () =>{
    let  videos : any = []
    if(video.content.length > 0){
      video.content.map(async (value) =>{
        videos.push(
          {
            sort:  value.sort,
            url: value.url
          }
        )
      })
    }
    setVideoData(videos)
  }

  useEffect(() => {
    setVideo()
    // eslint-disable-next-line react-hooks/exhaustive-deps  
  },[video])


  return video.active ? <VideoComponent videoData={videoData} /> : <></>
}