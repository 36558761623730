import React, { useEffect } from "react";
import { Avatar, Box, Popper, Typography } from "@mui/material";
import icons from "../../../assets/icons";
import AppointmentDetails from "./appointmentDetails.component";
import AppointmentsDialog from "./appointmentsDialog.component";
import moment from "moment";

const AppointmentCount = ({ appointments, status, setDialogAppts }) => {
  const relevant_statuses = status === 'approved' ? ['approved', 'updated'] : ['pending'];
  const relevant_appointments = appointments.filter(apt => relevant_statuses.includes(apt.status));

  if (relevant_appointments.length === 0) return <></>

  return <Box
    sx={{
      mx: 'auto',
      display: {
        xs: 'flex',
        md: 'none',
      },
      justifyContent: 'right',
      textAlign: 'center',
      cursor: 'pointer'
    }}
    onClick={() => setDialogAppts(relevant_appointments)}
  >
    <Typography sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 400,
      fontSize: '12px',
      width: '30px',
      height: '30px',
      borderRadius: '50%',
      color: 'white',
      bgcolor: relevant_statuses.includes('approved') ? '#6DC44F' : '#FFA800',
    }}>
      {relevant_appointments.length}
    </Typography>
  </Box>
}

export default function Calendar({ currentYear, currentMonth, appointments, profiles }) {
  const today = new Date();
  const startDay = new Date(currentYear, currentMonth, 1).getDay();
  const startTileNo = 0 - startDay;
  const lastDate = new Date(currentYear, currentMonth + 1, 0).getDate();
  const abbr_days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMore = Boolean(anchorEl2);
  const [dialogAppts, setDialogAppts] = React.useState([]);
  const [selectedAppointment, setSelectedAppointment] = React.useState<any>();
  const [currentDateStr, setCurrentDateStr] = React.useState('');

  // Close all the popups when an appointment is changed
  useEffect(() => {
    setAnchorEl(null);
    setAnchorEl2(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments]);

  let appointmentsByDate = {};
  appointments.forEach(appointment => appointmentsByDate[appointment.date] = [...(appointmentsByDate[appointment.date] || []), appointment]);
  const eventful_dates = Object.keys(appointmentsByDate);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  const popperModifiers = [
    {
      name: 'flip',
      enabled: true,
      options: {
        altBoundary: true,
        rootBoundary: 'document',
        padding: 8,
      },
    },
    {
      name: 'preventOverflow',
      enabled: true,
      options: {
        altAxis: true,
        altBoundary: true,
        tether: true,
        rootBoundary: 'document',
        padding: 8,
      },
    },
  ];

  return <>
    <Box display='flex' gap={1}>
      {abbr_days.map((_, j) => <Box sx={{
        flex: 1,
        textAlign: 'right',
        p: {
          xs: '5px 8px',
          md: '5px 17px',
        },
      }} key={j}>
        <Typography sx={{
          color: '#A7A7A7',
          fontSize: {
            xs: '12px',
            md: '16px',
          },
          fontWeight: 400,
        }}>{_}</Typography>
      </Box>)}
    </Box>
    <Box display='flex' flexDirection='column' height='100%' gap={1}>
      {Array.from({ length: 6 }, (_, i) => {
        if (startTileNo + 1 + 7 * i > lastDate) return <React.Fragment key={i}></React.Fragment>;

        return <Box key={i} display='flex' flex={1} gap={1}>
          {Array.from({ length: 7 }, (_, j) => {
            const sx = {
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
              flex: 1,
              background: '#FFEBE2',
              borderRadius: '12px',
              padding: {
                xs: '5px',
                md: '10px',
              },
            };
            let currentTileNo = startTileNo + j + 1 + 7 * i;
            let displayTileNo = currentTileNo;

            if (currentTileNo < 1 || currentTileNo > lastDate) {
              sx['color'] = '#C5C5C5';
              sx['background'] = '#FFF9E3';
              displayTileNo = new Date(currentYear, currentMonth, currentTileNo).getDate();
            }

            if (currentTileNo === today.getDate() && currentMonth === today.getMonth() && currentYear === today.getFullYear()) {
              sx['& .tileno'] = { color: 'white', background: '#F28554' };
            }

            return <Box sx={sx} key={j}>
              <Box sx={{ display: 'flex', justifyContent: 'right', textAlign: 'right' }}>
                <Typography className="tileno" sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 400,
                  fontSize: {
                    xs: '12px',
                    md: '16px',
                  },
                  width: {
                    xs: '30px',
                    md: '40px',
                  },
                  height: {
                    xs: '30px',
                    md: '40px',
                  },
                  borderRadius: '50%',
                }}>
                  {displayTileNo}
                </Typography>
              </Box>
              {eventful_dates.map(date => {
                // Current date represented by the current tile in this format: YYYY-MM-DD
                const currentTileDateStr = moment(new Date(currentYear, currentMonth, currentTileNo)).format('YYYY-MM-DD');

                // If the date represented by the current tile is the same as the event date
                if (currentTileDateStr === date) {
                  const apts = appointmentsByDate[date];
                  if (apts.length < 3) return <React.Fragment key={date}>
                    {apts.map(appointment => <Box
                      sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', background: 'white', borderRadius: '5px', borderLeft: `5px solid ${profiles.filter(prf => prf.uuid === appointment.profile_uuid)[0]?.color}`, p: '5px', cursor: 'pointer' }}
                      key={appointment.appointment_uuid}
                      onClick={e => {
                        handleClick(e);
                        setSelectedAppointment(appointment);
                      }}
                    >
                      <Avatar src={appointment.profile_picture ?? icons.profile_picture_icon} sx={{ width: 19, height: 19 }} alt="" />
                      <Box sx={{ mx: '5px', flex: 1 }}>
                        <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#373736' }}>{moment(appointment.time, 'HH:mm:ss').format('h:mm a')}</Typography>
                        <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#373736' }}>{appointment.name}</Typography>
                      </Box>
                      <Box sx={{ width: '9px', height: '9px', background: ['approved', 'updated'].includes(appointment.status) ? '#6DC44F' : '#FFA800', borderRadius: '100%' }} />
                    </Box>)}
                    <AppointmentCount appointments={apts} status={'approved'} setDialogAppts={setDialogAppts} />
                    <AppointmentCount appointments={apts} status={'pending'} setDialogAppts={setDialogAppts} />
                  </React.Fragment>

                  return <React.Fragment key={date}>
                    <Box
                      sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', background: 'white', borderRadius: '5px', borderLeft: `5px solid ${profiles.filter(prf => prf.uuid === apts[0].profile_uuid)[0]?.color}`, p: '5px', cursor: 'pointer' }}
                      onClick={e => {
                        handleClick(e);
                        setSelectedAppointment(apts[0]);
                      }}
                    >
                      <Avatar src={apts[0].profile_picture ?? icons.profile_picture_icon} sx={{ width: 19, height: 19 }} alt="" />
                      <Box sx={{ mx: '5px', flex: 1 }}>
                        <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#373736' }}>{moment(apts[0].time, 'HH:mm:ss').format('h:mm a')}</Typography>
                        <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#373736' }}>{apts[0].name}</Typography>
                      </Box>
                      <Box sx={{ width: '9px', height: '9px', background: ['approved', 'updated'].includes(apts[0].status) ? '#6DC44F' : '#FFA800', borderRadius: '100%' }} />
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', background: '#CAD8FF', borderRadius: '5px', p: '5px 10px', cursor: 'pointer' }} onClick={e => {
                      setCurrentDateStr(currentTileDateStr);
                      handleClick2(e);
                    }}>
                      <Typography sx={{ color: '#0651E8', fontSize: '10px', fontWeight: 400 }}>{apts.length - 1}+ more</Typography>
                    </Box>
                    <AppointmentCount appointments={apts} status={'approved'} setDialogAppts={setDialogAppts} />
                    <AppointmentCount appointments={apts} status={'pending'} setDialogAppts={setDialogAppts} />
                  </React.Fragment>
                }

                return <React.Fragment key={date}></React.Fragment>
              })}
            </Box>
          })}
        </Box>
      })}
      <Popper sx={{ bgcolor: '#F9FAFC', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', zIndex: 1300 }} open={open} anchorEl={anchorEl} placement="bottom-end" modifiers={popperModifiers}>
        <AppointmentDetails mode="calendar" appointment={selectedAppointment} profile={profiles.filter(prf => prf.uuid === appointments.filter(appt => appt.appointment_uuid === selectedAppointment?.appointment_uuid)[0]?.profile_uuid)[0]} />
      </Popper>
      <Popper sx={{ bgcolor: '#F9FAFC', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', borderRadius: '10px', zIndex: 1300 }} open={openMore} anchorEl={anchorEl2} placement="bottom-end" modifiers={popperModifiers}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', p: '10px' }}>
          {(appointmentsByDate[currentDateStr] ?? []).slice(1).map(appointment => <Box
            sx={{ display: 'flex', alignItems: 'center', background: 'white', borderRadius: '5px', borderLeft: `5px solid ${profiles.filter(prf => prf.uuid === appointment.profile_uuid)[0]?.color}`, p: '5px', cursor: 'pointer' }}
            key={appointment.appointment_uuid}
            onClick={e => {
              handleClick(e);
              setSelectedAppointment(appointment);
            }}
          >
            <Avatar src={appointment.profile_picture ?? icons.profile_picture_icon} sx={{ width: 19, height: 19 }} alt="" />
            <Box sx={{ mx: '5px', flex: 1 }}>
              <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#373736' }}>{moment(appointment.time, 'HH:mm:ss').format('h:mm a')}</Typography>
              <Typography sx={{ fontSize: '10px', fontWeight: 400, color: '#373736' }}>{appointment.name}</Typography>
            </Box>
            <Box sx={{ width: '9px', height: '9px', background: ['approved', 'updated'].includes(appointment.status) ? '#6DC44F' : '#FFA800', borderRadius: '100%' }} />
          </Box>)}
        </Box>
      </Popper>
      <AppointmentsDialog dialogAppts={dialogAppts} setDialogAppts={setDialogAppts} profiles={profiles} />
    </Box>
  </>
};