import React, { useState } from "react";
import { Box, Typography, Button, Divider, Radio, Dialog, Link, TextField } from "@mui/material";
import icons from "../../../../assets/icons";
import CartItem from "./cartItem";
import ItemPreview from "./itemPreview";
import { Formik, Form } from "formik";
import AddressDialog from "./addressDialog";
import BillingDialog from "./billingDialog";
import Placeholder from "../../../../components/placeholder/placeholder";

export default function CartComponent({
  expanded,
  setExpanded,
  currency,
  basicInfo,
  handleBack,
  handleDelete,
  handleEdit,
  items,
  itemPreview,
  setItemPreview,
  handlePreview,
  togglePixelCare,
  openAddressForm,
  setOpenAddressForm,
  address,
  setAddress,
  handleSubmit,
  billing,
  setBilling,
  openBillingForm,
  setOpenBillingForm,
  getShoppingCartListing,
  totalPrice,
  shippingFee,
  refCode,
  setRefCode,
  handleApplyCode,
  validCode,
  setShippingFee,
  shippingRateError,
  setShippingRateError,
  addressLoaded = false,
  billingLoaded = false,
  discountPct
}) {

  const [channel, setChannel] = useState('creditAN')
  return <>
    <Button color="secondary" startIcon={<img width={12} height={12} src={icons.back_icon} alt="" />} sx={{ mb: '10px' }} onClick={handleBack}>Back</Button>
    <Typography sx={{ fontSize: '31px', fontWeight: 400 }} gutterBottom>Shopping Cart</Typography>
    <Formik
      enableReinitialize
      initialValues={{
        products: items,
        billDesc: billing.address1 + ' ' + billing.address2,
        billAddr1: billing.address1,
        billAddr2: billing.address2,
        billCity: billing.city,
        billState: billing.state,
        billZip: billing.postcode,
        billCountry: billing.country,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zip: address.postcode,
        refCode: '',
        country: billing.country,
        currency: 'USD',
        channel: channel,
        prodType: 'NFC',
      }}
      onSubmit={(values, { setSubmitting }) => {
        values.refCode = refCode;
        handleSubmit(values).finally(() => setSubmitting(false));
      }}
    >
      {({
        handleSubmit
      }) => <Form role="molpayseamless" onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap' }}>
          <Box sx={{ flex: 1, minWidth: '390px' }}>
            <Box sx={{ bgcolor: 'white', borderRadius: '8px', border: '1px solid #C5C5C5', p: '15px', mb: '20px' }}>
              <Typography fontWeight={500} gutterBottom>Basic Info</Typography>
              <Typography>{basicInfo.name}</Typography>
              <Typography>{`${basicInfo.phone_code}${basicInfo.phone_number}`}</Typography>
              <Typography>{basicInfo.email}</Typography>
              <Divider sx={{ my: '15px' }} />
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                <Box sx={{ pr: "1rem" }}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem', alignItems: 'center' }}>
                    <Typography fontWeight={500} gutterBottom>Shipping Details</Typography>
                    <img src={icons.edit_02} alt="edit-icon" style={{ height: '1rem', width: '1rem', cursor: 'pointer' }} onClick={() => setOpenAddressForm(true)} />
                  </Box>

                  <Typography>{address?.address1}</Typography>
                  <Typography>{address?.address2}</Typography>
                  <Typography>{address?.postcode} {address?.city}</Typography>
                  <Typography>{address?.state}</Typography>
                  <Typography>{address?.country}</Typography>
                </Box>
                <Box>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '1rem', alignItems: 'center' }}>
                    <Typography fontWeight={500} gutterBottom>Billing Address</Typography>
                    <img src={icons.edit_02} alt="edit-icon"
                      style={{ height: '1rem', width: '1rem', cursor: 'pointer' }} onClick={() => setOpenBillingForm(true)} />
                  </Box>

                  <Typography>{billing?.address1}</Typography>
                  <Typography>{billing?.address2}</Typography>
                  <Typography>{billing?.postcode} {billing?.city}</Typography>
                  <Typography>{billing?.state}</Typography>
                  <Typography>{billing?.country}</Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mb: '20px' }}>
              <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>Payment Method</Typography>
              <Box sx={{ border: '1px solid #C5C5C5', borderRadius: '8px', overflow: 'hidden', mb: '15px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: expanded === 'credit' ? '#FECD20' : 'white',
                    cursor: 'pointer',
                    p: '15px',
                  }}
                  onClick={() => {
                    setChannel('creditAN')
                    setExpanded('credit')
                  }}
                >
                  <Typography sx={{ fontWeight: 500 }}>Credit or debit card</Typography>
                  <Radio checked={expanded === 'credit'} color="secondary" />
                </Box>
              </Box>
              <Box sx={{ border: '1px solid #C5C5C5', borderRadius: '8px', overflow: 'hidden' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: expanded === 'online' ? '#FECD20' : 'white',
                    cursor: 'pointer',
                    p: '15px',
                  }}
                  onClick={() => {
                    setChannel('fpx')
                    setExpanded('online')
                  }}
                >
                  <Typography sx={{ fontWeight: 500 }}>Online banking</Typography>
                  <Radio checked={expanded === 'online'} color="secondary" />
                </Box>
              </Box>
            </Box>

            <Box sx={{
              width: '100%',
              borderRadius: '8px',
              bgcolor: '#E4FFDF',
              p: '15px',
            }}>
              <Typography gutterBottom>
                Only shipping is supported; please allow 4–7 days for delivery.
              </Typography>
              <Link onClick={() => {
                window.open('https://pixelcard.co/return-refund-policy/', '_blank');
              }} sx={{ color: '#006BCF', textDecorationColor: '#006BCF', cursor: 'pointer' }}><Typography sx={{ fontSize: '12px' }}>Shipping & Return Policy</Typography></Link>
              <Link sx={{ color: '#006BCF', textDecorationColor: '#006BCF' }}><Typography sx={{ fontSize: '12px' }}>Payment Policy</Typography></Link>
              <Link sx={{ color: '#006BCF', textDecorationColor: '#006BCF' }}><Typography sx={{ fontSize: '12px' }}>PixelCare+ Policy</Typography></Link>
            </Box>
          </Box>
          <Box sx={{
            flex: 1,
            bgcolor: { sm: 'white' },
            borderRadius: '8px',
            border: { sm: '1px solid #C5C5C5' },
            p: { sm: '15px' },
            minWidth: { sm: '450px' }
          }}>
            <Typography fontWeight={500} gutterBottom>Summary</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              {items?.length > 0 ? items.map((item, i) => <CartItem
                key={i}
                currency={currency}
                item={item}
                index={i}
                handleDelete={() => handleDelete(i, item.cart_uuid)}
                handleEdit={() => handleEdit(item)}
                handlePreview={handlePreview}
                togglePixelCare={() => togglePixelCare(item, !item.pixelcare)}
                getShoppingCartListing={getShoppingCartListing}
              />) : <Placeholder
                icon={icons.shopping_cart}
                title="Cart is empty"
                description="Shop now!"
                buttonText="Buy Card"
                action={handleBack}
              />}
            </Box>
            <Divider sx={{ my: '15px' }} />
            <Box>
              <Typography component='label' htmlFor='name' variant='h5'>
                Referral code
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                <TextField
                  color='primary'
                  margin="dense"
                  fullWidth
                  // id="refCode"
                  // name="refCode"
                  sx={{ flex: 1, bgcolor: 'white', borderRadius: '4px' }}
                  value={refCode}
                  onChange={e => setRefCode(e.target.value)}
                />
                <Button
                  size="large"
                  variant="contained"
                  startIcon={<img src={icons.privillage_icon} alt="" />}
                  onClick={() => handleApplyCode(refCode)}
                >
                  Apply
                </Button>
              </Box>
            </Box>
            <Divider sx={{ my: '15px' }} />
            {validCode && <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '0 15px 15px',
            }}>
              <Typography>Discount</Typography>
              <Typography>
                {(items?.length > 0) ? discountPct+'%' : "-"}
              </Typography>
            </Box>}
            {
              shippingRateError && <Typography color="error" variant="caption" sx={{ textAlign: 'start', display: 'block' }}>
                Shipping rate is not available for this shipping address.
              </Typography>
            }
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '0 15px 15px',
            }}>
              <Typography>Shipping fee</Typography>
              <Typography>
                {(items?.length > 0) ? shippingFee : "0.00"}
              </Typography>
            </Box>

            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '15px',
              borderRadius: '5px',
              bgcolor: '#FFF2C5',
              mb: '15px',
            }}>
              <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Total ({currency})</Typography>
              <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                {(items?.length > 0) ? ((Number(totalPrice) + Number(shippingFee)) * ((validCode) ? (discountPct / 100): 1)).toFixed(2) : "0.00"}
              </Typography>
            </Box>
            <Button disabled={shippingRateError} color="secondary" size="large" variant="contained" type="submit">Next</Button>
          </Box>
        </Form>}
    </Formik>
    <Dialog
      open={Boolean(itemPreview)}
      onClose={() => setItemPreview(undefined)}
      fullWidth
      maxWidth='md'
      PaperProps={{ sx: { p: '30px' } }}
    >
      <ItemPreview
        formValues={{
          ...itemPreview,
          selectedVariant: itemPreview?.variant,
        }}
        showPlaceholder={false}
        isZakeke={itemPreview?.variant.front.includes('zakeke.com')}
      />
    </Dialog>
    {addressLoaded &&
      <AddressDialog
        open={openAddressForm}
        setOpen={setOpenAddressForm}
        address={address}
        setAddress={setAddress}
        setShippingFee={setShippingFee}
        setShippingRateError={setShippingRateError}
      />
    }{
      billingLoaded &&
      <BillingDialog
        open={openBillingForm}
        setOpen={setOpenBillingForm}
        billing={billing}
        setBilling={setBilling}
      />
    }

  </>
}