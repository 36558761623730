import axios from 'axios';

interface Booking {
  profile_uuid: string;
  name: string;
  phone_number: string;
  email: string;
  date: string;
  time: string;
  note: string;
}

const bookAppointment = (requestBody: Booking) => {
  return axios.post('/basicappointment/book', requestBody);
}

export default bookAppointment;