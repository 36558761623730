import React, { useState } from 'react';
import DragAndDropDialogComponent from './dragAndDropDialog.component'
import profileApi from '../../../api/profile';

interface DragAndDropDialogContainerProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: (blobFile: Blob | undefined, profile_uuid: string) => void;
  acceptedFormat?: string[];
  sampleFile: string;
  sampleFileName?: string;
  title: string;
  shouldSelectProfile: boolean;
}

export default function DragAndDropDialogContainer({
  open,
  handleClose,
  handleSubmit,
  acceptedFormat = ['csv'],
  sampleFile,
  sampleFileName = 'sample.csv',
  title = 'Import Group of Contact List',
  shouldSelectProfile = false,
}: DragAndDropDialogContainerProps) {
  const [profiles, setProfiles] = useState([]);
  const [selectedProfileUUID, setSelectedProfileUUID] = useState('');

  // const [file, setFile] = useState<File>()
  const [blobFile, setBlobFile] = useState<Blob>()

  const selectedFile = (files: Array<File>) => {
    if (files.length > 0) {
      let uploadedFile = files[0]
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e?.target?.result) {
          const blob = new Blob([e.target.result], { type: uploadedFile.type })
          setBlobFile(blob)
        }
      }
      reader.readAsArrayBuffer(uploadedFile);
      // setFile(uploadedFile)
    }
  }

  const submitFile = () => {
    handleSubmit(blobFile, selectedProfileUUID);
  }
  const getSampleFile = async (filepath: string) => {
    const result = await fetch(filepath).then(result => result.blob())
      .then(blob => blob);
    return result
  }

  const downloadSample = async () => {
    const blobFile = await getSampleFile(sampleFile);
    const url = window.URL.createObjectURL(
      new Blob([blobFile]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      sampleFileName,
    )
    document.body.appendChild(link)
    link.click()
    // link?.parentNode.removeChild(link);
  }

  const getProfiles = () => {
    profileApi.getProfilesDropdown()
      .then((response: any) => {
        const prfs = (response.data ?? []).map((prf: any) => ({
          uuid: prf.profile_uuid,
          name: prf.profile_name,
        }));

        if (prfs.length === 1) setSelectedProfileUUID(prfs[0].uuid);

        setProfiles(prfs);
      });
  }

  React.useEffect(() => {
    getProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <DragAndDropDialogComponent
    open={open}
    selectedFile={selectedFile}
    handleClose={handleClose}
    handleSubmit={submitFile}
    downloadSample={downloadSample}
    acceptedFormat={acceptedFormat}
    profiles={shouldSelectProfile ? profiles : []}
    selectedProfileUUID={selectedProfileUUID}
    setSelectedProfileUUID={setSelectedProfileUUID}
    title={title} />
}