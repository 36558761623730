import getTotalProfileView from './getTotalProfileView.api'
import getTotalConnection from './getTotalConnection.api'
import recentConnectionList from './getRecentConnectionList.api'
import getLocationList from './getLocationList.api'
const dashboardApi= {
  getTotalProfileView,
  getTotalConnection,
  recentConnectionList,
  getLocationList
}

export default dashboardApi