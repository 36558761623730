import React from "react";
import { Paper, Button, Typography } from "@mui/material";
import icons from "../../../../../../assets/icons";
import images from "../../../../../../assets/img";

export default function ApplyCardComponent({ handleBack, handleApplyTemplate, handleApplyCustom }) {
  const imgStyle = {
    flex: 1,
    width: '100%',
    maxWidth: '466px',
    backgroundColor: '#232322',
    borderRadius: '30px'
  };

  return <>
    <Button color="secondary" startIcon={<img width={12} height={12} src={icons.back_icon} alt="" />} sx={{ mb: '10px' }} onClick={handleBack}>Back</Button>
    <Typography sx={{ fontSize: '31px', fontWeight: 700, textAlign: 'center' }}>
      Begin integrating technologies into your business
    </Typography>
    <Paper elevation={0} sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: '20px',
      flexWrap: 'wrap',
      p: '20px'
    }}>
      <Button onClick={handleApplyTemplate}>
        <img src={images.apply_template} style={imgStyle} alt="Buy card using a template" />
      </Button>
      <Button onClick={handleApplyCustom}>
        <img src={images.apply_custom} style={imgStyle} alt="Buy card with customized design" />
      </Button>
    </Paper>
  </>
}