import axios from "axios";

interface Data {
  success: boolean;
  code: number;
  message: string;
  data: any
}

const verifyToken = (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get('auth/verifyToken')
      .then(response => resolve(response.data))
      .catch(error => reject(error));
  });
};

export default verifyToken;