import React, { useEffect, useState } from 'react'
import MemberConnected from '../../../exchange-contact/pages/member-connected/memberConnected.component'
import { useDispatch, useSelector } from 'react-redux';
import { createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import basicContactApi from '../../../../../api/basicContact'
import FileHelper from '../../../../../lib/helper/fileHelper'
import { PHONE_MOBILE } from '../../../../../lib/constants/basiccontacttypes';
import { startLoadingLP, endLoadingLP } from '../../../../../features/loading';
import { VOUCHER_DETAIL } from '../../../../../lib/constants/routes';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function MemberConnectedContainer() {
  let query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessPath = useSelector((state: any) => state.landingPage.accessPath);
  const { id, voucherUUID } = useParams();
  const ownerCardNo = query.get("owner_card_no") || ""
  const ownerProfileNo = query.get("owner_profile_no") || ""
  const receiverProfileNo = query.get("receiver_profile_no")
  const hasConnectedBefore = Boolean(query.get("hasConnectedBefore"));
  const [memberProfilePicture, setMemberProflePicture] = useState();
  const [profilePicture, setProfilePicture] = useState();
  const [memberName, setMemberName] = useState("");
  const [memberContact, setMemberContact] = useState("");
  const [receiverName, setReceiverName] = useState("");

  const getReceiverActiveProfile = async () => {
    if (localStorage.getItem('user') !== null) {
      const response = await basicContactApi.getActiveProfile({ serialNo: `${receiverProfileNo}` })
        .then(response => response)
        .catch(response => response)

      if (response.success) {
        if (response?.data.user_details) {
          if (response.data.user_details.profile_image) {
            const profilePicUrl = await getUrl(response.data.user_details.profile_image)
            setProfilePicture(profilePicUrl)
          }
        }
        if (response.data.user_details.full_name) {
          setReceiverName(response.data.user_details.full_name)
        }
      }
    }
    return true
  }
  const getActiveProfile = async () => {
    let response;
    if (ownerCardNo) {
      response = await basicContactApi.getActiveProfileByCard({ serialNo: ownerCardNo })
        .then(response => response)
        .catch(response => response)
    } else {
      response = await basicContactApi.getActiveProfile({ serialNo: ownerProfileNo })
        .then(response => response)
        .catch(response => response)
    }

    if (response.success) {
      response.data.contact_info.content.map((value) => {
        if (value.category === PHONE_MOBILE) {
          setMemberContact(value.info)
        }
        return value
      })
      setMemberName(response.data.profile_name)
      if (response.data.user_details.profile_image) {
        const profilePicUrl = await getUrl(response.data.user_details.profile_image)
        setMemberProflePicture(profilePicUrl)
      }
    }
    return true
  }
  async function getUrl(fullPath: string) {
    const response = await FileHelper.getUrl(fullPath).then(repsonse => repsonse)
    return response
  }
  
  useEffect(() => {
    const fetchData = async () => {
      await getActiveProfile();
      await getReceiverActiveProfile();
      return true;
    };

    dispatch(startLoadingLP());
    fetchData().finally(() => { dispatch(endLoadingLP());});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MemberConnected
    hasConnectedBefore={hasConnectedBefore}
    ownerSerialNo={ownerCardNo || ownerProfileNo}
    profilePicture={profilePicture}
    memberProfilePicture={memberProfilePicture}
    memberContact={memberContact}
    receiverName={receiverName}
    memberName={memberName}
    accessPath={accessPath}
    handleDone={() => {
      navigate(
        {
          pathname: `${accessPath}/${id}/${VOUCHER_DETAIL}`.replace(':voucherUUID', `${voucherUUID}`),
          search: createSearchParams({ collected: '1' }).toString()
        },
        { replace: true },
      );
    }}
  />
}