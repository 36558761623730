import React from "react";
import { Paper, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import {
  FACEBOOK,
  INSTAGRAM,
  LINKEDIN,
  TWITTER,
  SNAPCHAT,
  SKYPE,
  BEHANCE,
  YOUTUBE,
  PINTEREST,
  TELEGRAM,
  WECHAT,
  TIKTOK,
  VIBER,
  LINE,
} from "../../../../../lib/constants/socialmediatypes";
import CustomSwitch from "../customSwitch";
import DraggableItem from "../draggableItem";

export default function SocialInformationComponent({
  totalSections,
  order,
  reorderSection,
  socialItems,
  setSocialItems,
  socialSelect,
  setSocialSelect,
  errors,
  isEnabledSocialInfo,
  setIsEnabledSocialInfo,
  setSocialItem,
  handleSocialSelectClose,
  reorder,
  remove,
  getListStyle,
  getItemStyle,
  getIcon
}) {
  return (
    <Paper elevation={0} sx={{ mt: '26px', padding: '30px', borderRadius: '8.78px' }}>
      <div style={{ display: 'flex', alignItems: 'center', gap: '5px', marginTop: '10px', marginBottom: '20px' }}>
        <select style={{ borderRadius: '5px', padding: '5px', borderColor: '#C5C5C5' }} defaultValue={order + 1} onChange={event => reorderSection(order, event.target.value)}>
          {Array(totalSections).fill(undefined).map((_, i) => <option key={i}>{i + 1}</option>)}
        </select>
        <Typography component='span' variant='h4' sx={{ flex: 1 }}>
          Social Information
        </Typography>
        <CustomSwitch checked={isEnabledSocialInfo} onChange={e => setIsEnabledSocialInfo(e.target.checked)} />
      </div>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Add Component</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Add Component"
          value={socialSelect}
          onChange={e => setSocialSelect(e.target.value)}
          onClose={handleSocialSelectClose}
          disabled={!isEnabledSocialInfo}
        >
          <MenuItem value={FACEBOOK}>Facebook</MenuItem>
          <MenuItem value={INSTAGRAM}>Instagram</MenuItem>
          <MenuItem value={LINKEDIN}>LinkedIn</MenuItem>
          <MenuItem value={TWITTER}>Twitter / X</MenuItem>
          <MenuItem value={LINE}>LINE</MenuItem>
          <MenuItem value={SNAPCHAT}>Snapchat</MenuItem>
          <MenuItem value={SKYPE}>Skype</MenuItem>
          <MenuItem value={BEHANCE}>Behance</MenuItem>
          <MenuItem value={YOUTUBE}>YouTube</MenuItem>
          <MenuItem value={PINTEREST}>Pinterest</MenuItem>
          <MenuItem value={TELEGRAM}>Telegram</MenuItem>
          <MenuItem value={WECHAT}>Wechat</MenuItem>
          <MenuItem value={TIKTOK}>Tiktok</MenuItem>
          <MenuItem value={VIBER}>Viber</MenuItem>
        </Select>
      </FormControl>

      <DragDropContext onDragEnd={function (result) {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        setSocialItems(reorder(
          socialItems,
          result.source.index,
          result.destination.index
        ));
      }}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {socialItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={!isEnabledSocialInfo}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <DraggableItem
                        category={item.category}
                        icon={getIcon(item.category)}
                        value={item.info}
                        setVal={e => setSocialItem(e, index)}
                        errors={errors}
                        handleRemove={() => setSocialItems(remove(socialItems, item.id))}
                        disabled={!isEnabledSocialInfo} isSocial={true} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Paper>
  )
}