import React from "react";
import { Box, Card, CardContent, Dialog, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PersonIcon from '@mui/icons-material/Person';

function PlanDialog({ open, handleClose, currency, tier, period }) {
  return <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { bgcolor: 'transparent',overflow:'hidden' } }}>
    <Box
      key={tier.title}
      sx={{
        flex: 1,
        minWidth: '330px',
        maxWidth: '370px',
      }}
    >
      <Card
        sx={{
          borderRadius: '13px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <Box sx={{ background: tier.background, color: 'white', py: '40px' }}>
          <Box sx={{
            ml: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
          }}>
            <Typography sx={{ fontSize: '32px', fontWeight: 800 }} gutterBottom>
              {tier.title}
            </Typography>
            <Box sx={{
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              fontSize: '13px',
              color: '#617271',
              bgcolor: '#FFFFFF',
              p: '5px 10px',
              display: 'flex',
              alignItems: 'center',
            }}>
              <PersonIcon />
              {tier.user}
            </Box>
          </Box>
          <Box sx={{ ml: '20px' }}>
            {tier.title !== 'Business' && <Typography component="span" sx={{ fontSize: '32px', fontWeight: 800 }}>
              {currency} {period === 'monthly' ? tier.price : tier.yearly_price}
            </Typography>}
            {tier.title === 'Business' ? <Typography component="span" sx={{ fontSize: '32px' }}>
              Contact Us
            </Typography> : <Typography component="span" sx={{ fontSize: '20px', ml: '5px' }}>
              /mo
            </Typography>}
          </Box>
          <Typography sx={{ ml: '20px' }}>
            {tier.subtitle}
          </Typography>
        </Box>
        <CardContent sx={{ flex: 1, px: '30px', pt: '30px' }}>
          <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>{tier.summary}</Typography>
          <Box
            sx={{
              pt: 2,
              pb: 1,
              display: 'flex',
              gap: 1.5,
              alignItems: 'center',
            }}
          >
            <CheckCircleOutlineIcon
              sx={{
                width: 16,
                color: 'transparent',
              }}
            />
            <Typography
              variant="subtitle2"
              fontWeight={700}
              color={'#373736'}
            >
              {tier.desc_title}
            </Typography>
          </Box>
          {tier.description.map((line) => (
            <Box
              key={line}
              sx={{
                py: 1,
                display: 'flex',
                gap: 1.5,
                alignItems: 'center',
              }}
            >
              <CheckCircleOutlineIcon
                sx={{
                  width: 16,
                  color: 'primary.main',
                }}
              />
              <Typography
                variant="subtitle2"
                color={'#373736'}
              >
                {line}
              </Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
    </Box>
  </Dialog>
};

export default PlanDialog;