import React from "react";
import { Box, CssBaseline, Typography, Container, Tabs, Tab, IconButton, Grid, Button, Avatar, Menu, Tooltip } from "@mui/material";
import Calendar from "./component/calendar.component";
import icons from "../../assets/icons";
import AppointmentCard from "./component/appointmentCard.component";
import HistoryTable from "./component/historyTable.component";
import HistoryFilterDialog from "./component/historyFilterDialog.component";
import Advertisement from "../../components/advertisement";
import Placeholder from "../../components/placeholder/placeholder";

const AppointmentPlaceholder = () => <Box sx={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: '30px',
}}>
  <Placeholder
    icon={icons.calendar_appt}
    title="No appointments"
  />
</Box>

const iconStyle = {
  display: 'inline-block',
  height: '24px',
  width: '24px',
};

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  if (value === index) return (
    <Container
      maxWidth="lg"
      style={{ padding: 0 }}
      sx={{ mt: 4, mb: 4 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </Container>
  );

  return <></>
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function AppointmentComponent({
  profiles,
  setSelectedProfileUUID,
  month,
  year,
  changeMonth,
  viewMode,
  setViewMode,
  appointments,
  history,
  listViewAppointments,
  status,
  setStatus,
  historyStatus,
  setHistoryStatus,
  historyType,
  setHistoryType,
  historyOrder,
  setHistoryOrder,
  hasAccess,
  hasLinked,
  handleLinkGcal,
  handleUnlinkGcal,
}) {
  const [tab, setTab] = React.useState(0);
  const [openHistoryFilter, setOpenHistoryFilter] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const today = new Date();
  const today_str = `${today.getFullYear()}-${('0' + (today.getMonth() + 1)).slice(-2)}-${today.getDate()}`;
  const [selectedProfile, setSelectedProfile] = React.useState<any>();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return <>
    <Advertisement display={!hasAccess}>
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/jrJMyABmpYI?si=6147HXreuOlC5ZTB"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      />
    </Advertisement>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography sx={{ fontSize: '31px', fontWeight: 400 }}>
        Appointments
      </Typography>
      <Button
        sx={{ px: '10px', display: 'flex', borderRadius: '30px', bgcolor: '#FECD20', '&:hover': { bgcolor: '#FFEFBA' } }}
        onClick={handleClick}
        startIcon={<Avatar src={selectedProfile?.image ?? icons.profile_picture_icon} sx={{ width: 31, height: 31 }} alt="" />}
        endIcon={<img width={16} src={icons.arrow_down_01_icon} alt="" />}
      >
        <Typography color="secondary" sx={{ fontSize: '13px', fontWeight: 500, flex: 1, textAlign: 'start' }}>
          {selectedProfile?.name ?? 'All Profiles'}
        </Typography>
      </Button>
    </Box>
    <Menu slotProps={{ paper: { sx: { borderRadius: '16px' } } }} open={open} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} MenuListProps={{ sx: { p: 0 } }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', p: 1, bgcolor: '#F9FAFC', borderRadius: '16px', width: '364px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            borderRadius: '9px',
            borderLeft: '9px solid #EBEBEB',
            p: '9px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedProfileUUID('');
            setSelectedProfile(undefined);
            setAnchorEl(null);
          }}
        >
          <Box sx={{ mx: '9px', flex: 1 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#373736' }}>All Profiles</Typography>
          </Box>
          <IconButton>
            <img src={icons.arrow_right_icon} alt="" />
          </IconButton>
        </Box>
        {profiles.map((profile, i) => <Box
          key={i}
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'white',
            borderRadius: '9px',
            borderLeft: `9px solid ${profile.color}`,
            p: '9px',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedProfileUUID(profile.uuid);
            setSelectedProfile(profile);
            setAnchorEl(null);
          }}
        >
          <Avatar src={profile.image ?? icons.profile_picture_icon} sx={{ width: 54, height: 54 }} alt="" />
          <Box sx={{ mx: '9px', flex: 1 }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 500, color: '#373736' }}>{profile.name}</Typography>
            {/* <Typography sx={{ fontSize: '13px', fontWeight: 400, color: '#959595' }}>Name</Typography> */}
          </Box>
          <IconButton>
            <img src={icons.arrow_right_icon} alt="" />
          </IconButton>
        </Box>)}
      </Box>
    </Menu>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab
          sx={{ textTransform: "none", color: "black !important" }}
          label="All Appointments"
          {...a11yProps(0)}
        />
        <Tab
          sx={{ textTransform: "none", color: "black !important" }}
          label="History"
          {...a11yProps(1)}
        />
      </Tabs>
    </Box>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <CustomTabPanel value={tab} index={0}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', mb: '20px' }}>
          <Box sx={{ display: 'flex', flex: 1, gap: 1, alignItems: 'center' }}>
            <img src={icons.calendar_number} alt="" />
            <Typography sx={{ fontSize: { xs: '12px', md: '16px', }, fontWeight: 400, lineHeight: '20px' }}>
              All Appointments
            </Typography>
          </Box>

          {viewMode === 'list' && <Box sx={{ background: '#EDEDED', borderRadius: '60px', p: '6px' }}>
            <Button sx={{ color: 'black', borderRadius: '30px', background: status === 'approved' ? '#FFC700' : '', mr: '10px' }} onClick={() => setStatus('approved')}>Approved</Button>
            <Button sx={{ color: 'black', borderRadius: '30px', background: status === 'approved' ? '' : '#FFC700' }} onClick={() => setStatus('pending')}>Pending</Button>
          </Box>}

          <Box>
            {!hasLinked && <Tooltip title="Link Google Calendar">
              <IconButton sx={{ p: 0 }} onClick={handleLinkGcal}>
                <img height={40} src={icons.link_gcal} alt="" />
              </IconButton>
            </Tooltip>}
            {hasLinked && <Tooltip title="Unlink Google Calendar">
              <IconButton sx={{ p: 0 }} onClick={handleUnlinkGcal}>
                <img height={40} src={icons.unlink_gcal} alt="" />
              </IconButton>
            </Tooltip>}
            <Tooltip title="Calendar View">
              <IconButton onClick={() => setViewMode('calendar')}>
                <div style={{ ...iconStyle, background: viewMode === 'calendar' ? '#FECD20' : 'black', mask: `url(${icons.calendar_number})` }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="List View">
              <IconButton onClick={() => setViewMode('list')}>
                <div style={{ ...iconStyle, background: viewMode === 'list' ? '#FECD20' : 'black', mask: `url(${icons.list_dash})` }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>

        {viewMode === 'calendar' && <>
          <Box sx={{ display: 'flex', gap: 1, mb: '20px' }}>
            <Box sx={{ display: 'inline-block', background: '#6DC44F', borderRadius: '100%', width: '21px', height: '21px' }} />
            <Typography sx={{ fontSize: { xs: '12px', md: '16px', }, fontWeight: 400, mr: '20px' }}>
              Approved
            </Typography>

            <Box sx={{ display: 'inline-block', background: '#FFA800', borderRadius: '100%', width: '21px', height: '21px' }} />
            <Typography sx={{ fontSize: { xs: '12px', md: '16px', }, fontWeight: 400 }}>
              Pending
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'left' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => changeMonth('-')}><img src={icons.arrow_left_icon} alt="" /></IconButton>
              <Typography minWidth={100} textAlign={'center'} fontWeight={500} fontSize={20}>{monthNames[month]} {year}</Typography>
              <IconButton onClick={() => changeMonth('+')}><img src={icons.arrow_right_icon} alt="" /></IconButton>
            </Box>
          </Box>
          <Calendar currentMonth={month} currentYear={year} appointments={appointments} profiles={profiles} />
        </>}

        {viewMode === 'list' && status === 'pending' && <Grid container spacing={2}>
          {listViewAppointments.map(event => <Grid item xs={12} sm={6} lg={4} key={event.appointment_uuid}>
            <AppointmentCard
              appointment={event}
              getCall={() => { }}
              getEmail={() => { }}
              status={status}
              profile={profiles.filter(prf => prf.uuid === event.profile_uuid)[0]}
            />
          </Grid>)}
        </Grid>}
        {viewMode === 'list' && status === 'pending' && listViewAppointments.length === 0 && <AppointmentPlaceholder />}
        {viewMode === 'list' && status === 'approved' && <>
          <Typography gutterBottom sx={{ color: '#373736', fontSize: '20px', fontWeight: 500 }}>Today</Typography>
          {Boolean(listViewAppointments.filter(appt => appt.date === today_str).length) ? <>
            <Grid container spacing={2}>
              {listViewAppointments.filter(appt => appt.date === today_str).map(event => <Grid item xs={12} sm={6} lg={4} key={event.appointment_uuid}>
                <AppointmentCard
                  appointment={event}
                  getCall={() => { }}
                  getEmail={() => { }}
                  status={status}
                  profile={profiles.filter(prf => prf.uuid === event.profile_uuid)[0]}
                />
              </Grid>)}
            </Grid>
          </> : <AppointmentPlaceholder />}

          <Typography gutterBottom sx={{ mt: '20px', color: '#373736', fontSize: '20px', fontWeight: 500 }}>Upcoming</Typography>
          {Boolean(listViewAppointments.filter(appt => appt.date !== today_str).length) ? <>
            <Grid container spacing={2}>
              {listViewAppointments.filter(appt => appt.date !== today_str).map(event => <Grid item xs={12} sm={6} lg={4} key={event.appointment_uuid}>
                <AppointmentCard
                  appointment={event}
                  getCall={() => { }}
                  getEmail={() => { }}
                  status={status}
                  profile={profiles.filter(prf => prf.uuid === event.profile_uuid)[0]}
                />
              </Grid>)}
            </Grid>
          </> : <AppointmentPlaceholder />}
        </>}
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box>
            <IconButton onClick={() => setHistoryOrder(historyOrder === 'desc' ? 'asc' : 'desc')}>
              <img src={icons.arrow_up_down_round} alt="toggle list order" />
            </IconButton>
            <IconButton onClick={() => setOpenHistoryFilter(true)}>
              <img src={icons.filter_list} alt="filter list" />
            </IconButton>
          </Box>
        </Box>
        {history.length > 0 ? <HistoryTable events={history} profiles={profiles} /> : <AppointmentPlaceholder />}
        <HistoryFilterDialog
          open={openHistoryFilter}
          handleClose={() => setOpenHistoryFilter(false)}
          historyStatus={historyStatus}
          setHistoryStatus={setHistoryStatus}
          historyType={historyType}
          setHistoryType={setHistoryType}
        />
      </CustomTabPanel>
    </Box>
  </>
}