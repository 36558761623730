import React from "react";
import { Box, IconButton, Popper, TextField, Typography, Button } from "@mui/material";
import icons from "../../assets/icons";

export default function FilterComponent({sortList,handleFilterOption,handleSearch,handleSort,handleRefresh,selectedSort}:
{
  handleSearch:(search:string|null)=>void,
  handleSort:({field,order,name}:{field:string,order:string,name:string })=>void,
  sortList: Array<{
    name: string,
    order: string,
    field: string
  }>,
  handleFilterOption: ()=>void,
  handleRefresh: ()=>void,
  selectedSort: string
  }
) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [search, setSearch] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);
  const openSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(!open);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  // When press "Enter" key, it will call handleSearch function
  const keyPress = (e: any ) => {
    if(e.keyCode === 13){
      handleSearch(search)
    }
  }

return <>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '20px' }}>
      <TextField
        fullWidth
        onChange={(e) => {setSearch(e.target.value)}}
        placeholder="Search"
        onKeyDown={keyPress}
        InputProps={{
          startAdornment: (
            <IconButton onClick={() => handleSearch(search)}>
              <img src={icons.search_icon.toString()} alt="filter" />
            </IconButton>
          ),
        }}
        variant="standard"
      />
    <IconButton onClick={openSort}>
      <img src={icons.arrow_up_down_round.toString()} alt="sort" />
    </IconButton>
    <IconButton onClick={() => handleFilterOption()}>
        <img src={icons.filter_list.toString()} alt="filter" />
    </IconButton>
    <IconButton onClick={() => handleRefresh()}>
        <img src={icons.refresh.toString()} alt="refresh" />
    </IconButton>
    <Popper open={open} anchorEl={anchorEl} placement="bottom-end">
      <Box boxShadow={3} sx={{ p: 1, px: 0, bgcolor: 'background.paper', borderRadius: '7px', width: '160px' }}>
        {
          sortList?.map((sort, index) => {
            return <>
            {
              (selectedSort === sort.name) ?              
              <Button key={index} fullWidth sx={{ px: '15px', display: 'flex', borderRadius: 0,color:'#000000', backgroundColor:'#FECD20', '&:hover': { backgroundColor: '#FFEFBA' } }} onClick={() => {
                handleSort({field: sort.field, order: sort.order, name: sort.name});
              }}>
              <Typography  sx={{ fontSize: '13px', fontWeight: 500, flex: 1, textAlign: 'start' }}>{sort.name}</Typography>
              </Button>
               :
               <Button key={index} fullWidth sx={{ px: '15px', display: 'flex', borderRadius: 0,color:'#000000', '&:hover': { backgroundColor: '#FFEFBA' } }} onClick={() => {
                handleSort({field: sort.field, order: sort.order, name: sort.name});
              }}>
              <Typography  sx={{ fontSize: '13px', fontWeight: 500, flex: 1, textAlign: 'start' }}>{sort.name}</Typography>
              </Button>
          }
            </>
          })
        }

      </Box>
    </Popper>
  </Box>
  </>
}