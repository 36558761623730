import React, { useEffect, useState } from 'react'
import ProfileListComponent from './profileList.component'
import profileApi from '../../../../api/profile'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb } from '../../../../features/admin'
import { startLoading, endLoading } from '../../../../features/loading';
import { PORTAL_PROFILE, PORTAL_PROFILE_LIST, PORTAL_UPDATE_PROFILE } from '../../../../lib/constants/routes';
import FileHelper from '../../../../lib/helper/fileHelper';
import auth from '../../../../api/auth';
import { openSelectProfileDialog } from '../../../../features/select-profile-dialog';

export default function ProfileListContainer() {
  const [profiles, setProfiles] = useState<any[]>([])
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const isConfirmed = useSelector<any>(state => state.selectProfileDialog.isConfirmed);
  const [access, setAccess] = useState<any>({
    access_right: [],
    access_limit: {
      profile: 1,
      contact: 200,
    }
  });

  const getProfileList = () => {
    dispatch(startLoading());
    profileApi.getList()
      .then(response => {
        if (response.success) {
          Promise.all(response.data.map(async (profile) => ({
            ...profile,
            imgURL: await FileHelper.getUrl(profile.profile_picture),
          }))
          ).then(profs => {
            setProfiles(profs);

            if (profs.filter(prof => !prof.is_expired).length === 0) {
              dispatch(openSelectProfileDialog(profs));
            }
          }).finally(() => dispatch(endLoading()));
        }
      })
      .catch(error => error)
      .finally(() => dispatch(endLoading()));
  }

  const editProfileStatus = async (profileUuid, active) => {
    await profileApi.editProfileStatus(profileUuid, active)
      .then(response => response)
      .catch(error => error)
  }

  const openProfileUpdate = (uuid: string) => {
    navigate(PORTAL_UPDATE_PROFILE.replace(':profileUuid', uuid));
  }

  // const deleteProfile = async (profileUUID: string) =>{
  //   await profileApi.deleteProfile(profileUUID)
  //   .then(response => response)
  //   .catch(error => error)
  // }

  useEffect(() => {
    auth.getAccessLimit().then(response => {
      setAccess(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isConfirmed) getProfileList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed])

  useEffect(() => {
    getProfileList()
    dispatch(setBreadcrumb({
      breadcrumb: [
        {
          name: "My Profile",
          path: PORTAL_PROFILE_LIST
        }, {
          name: "Profiles",
          path: null
        }
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleActive = (profileUuid, active) => {
    editProfileStatus(profileUuid, active).then(() => getProfileList());
  }

  const handleDuplicate = (profileUuid: string) => {
    navigate(
      {
        pathname: PORTAL_PROFILE,
      },
      { state: { profileUuid } },
    );
  }

  return <ProfileListComponent profiles={profiles} handleActive={handleActive} handleDuplicate={handleDuplicate} openProfileUpdate={openProfileUpdate} access={access} />
}