import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Link, TextField, Typography } from "@mui/material";
import icons from "../../../assets/icons";
import moment from "moment";
import DatePicker from "react-date-picker";
import TimePicker from "../../landing-page/book-appointment/component/timePicker";
import { useDispatch, useSelector } from "react-redux";
import { endLoading, startLoading } from "../../../features/loading";
import { setShouldRefresh } from "../../../features/appointments/appointments.slice";
import appointmentApi from "../../../api/appointment";
import profileApi from "../../../api/profile";
import { Formik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { openActionDialog } from "../../../features/action-dialog";
import { openResponseDialog } from "../../../features/response-dialog";
import { apiCalendar, getToken, linkGoogleCalendar } from "../apiCalendar";

interface Appointment {
  appointment_uuid: string;
  date: string;
  name: string;
  email: string[];
  phone: string[];
  note: string;
  profile_picture: string;
  profile_name: string;
  profile_uuid: string;
  status?: string;
  time: string;
  type: string; // requester | respondent
}

function ActionsDialog({ appointment, open, handleClose, isHistory = false, profile }) {
  const isConfirmed = useSelector<any>(state => state.actionDialog.isConfirmed);
  const isCancelled = useSelector<any>(state => state.actionDialog.isCancelled);
  const action = useSelector<any>(state => state.actionDialog.action);
  const cancelAction = useSelector<any>(state => state.actionDialog.cancelAction);
  const dispatch = useDispatch();
  const [appt, setAppt] = useState<Appointment>({
    appointment_uuid: '',
    date: '',
    name: '',
    email: [''],
    phone: [''],
    note: '',
    profile_picture: '',
    profile_name: '',
    profile_uuid: '',
    status: '',
    time: '',
    type: '',
  });
  const [openForm, setOpenForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState<any>();
  const [openStartPicker, setOpenStartPicker] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState('');
  const [status, setStatus] = React.useState('');
  const accessRights: any = useSelector<any>(state => state.access.access_right);

  const stringToPicker = (timeString: string) => {
    const time = moment(timeString, ['H:mm']);
    return {
      hour: time.format('h'),
      colon: ':',
      minute: time.format('mm'),
      kosong: ' ',
      amPm: time.format('A'),
    };
  }

  const chipStyle = {
    borderRadius: '30px',
    fontSize: 13,
    fontWeight: 500,
    textTransform: 'capitalize',
  };
  const pendingChipStyle = {
    ...chipStyle,
    background: '#FFF0DA',
    color: '#FFB443',
  };
  const approvedChipStyle = {
    ...chipStyle,
    background: '#E7F6EA',
    color: '#0BA02C',
  };
  const declinedChipStyle = {
    ...chipStyle,
    background: '#FFECEC',
    color: '#FF0000',
  };

  const pickerToString = ({ hour, minute, amPm }) => {
    const hr = `0${(Number(hour) + (amPm === 'PM' ? (hour < 12 ? 12 : 0) : (hour < 12 ? 0 : 12))) % 24}`.slice(-2);
    return `${hr}:${minute}`;
  }

  const [startPickerValue, setStartPickerValue] = useState(stringToPicker(moment().format('HH:mm')));

  const deleteEvent = async (eventId, calendarId) => {
    try {
      const deletedEvent = await apiCalendar.deleteEvent(eventId, calendarId);

      return deletedEvent;
    } catch (e) {
      console.log('Error in deleteEvent:', e);
      return Promise.resolve(null);
    }
  }

  const handleDecline = () => {
    const isUsingGcal: boolean = Boolean(Number(localStorage.getItem('isUsingGcal')));
    const setDeclined = () => {
      return appointmentApi.decline(appointment?.appointment_uuid).then(response => {
        if (response.data.success) {
          setStatus('declined');
          setOpenConfirmDialog('');
          dispatch(setShouldRefresh(true));
        }
      });
    }

    if (appointment?.gcal_event_id !== null && isUsingGcal) {
      console.log('appointment', appointment)
      deleteEvent(`${appointment?.gcal_event_id}`, profile.calendar_id)
        .then(() => setDeclined())
        .finally(() => setIsSubmitting(false));
    } else {
      setDeclined().then(() => setIsSubmitting(false));
    }
  }

  const createEvent = async (event, calendarId) => {
    try {
      const createdEvent = await apiCalendar.createEvent(event, calendarId);

      if (!createdEvent) throw new Error('gapi not loaded');

      return createdEvent;
    } catch (e) {
      if (e.message === 'gapi not loaded') {
        return linkGoogleCalendar().then(() => createEvent(event, calendarId));
      }

      return Promise.resolve(null);
    }
  }

  const handleApprove = async () => {
    const isUsingGcal: boolean = Boolean(Number(localStorage.getItem('isUsingGcal')));
    let calendarId: string | null = null;

    const saveEvent = (cId: string) => {
      const startTime = `${appt.date}T${appt.time}+08:00`;
      const endTime = moment(startTime).add(1, 'h').toISOString();
      const timeZone = 'Asia/Kuala_Lumpur';
      const event = {
        summary: `PixelCard - ${appt.name}`,
        description: appt.note,
        start: {
          dateTime: startTime,
          timeZone,
        },
        end: {
          dateTime: endTime,
          timeZone,
        },
        attendees: [
          { email: appt.email[0] },
        ],
      };

      return createEvent(event, cId);
    }

    const setApproved = (eventId = null) => {
      return appointmentApi.approve(appointment?.appointment_uuid, eventId)
        .then(response => {
          console.log('res', response)
          if (response.data.success) {
            setStatus('approved');
            setOpenConfirmDialog('');
            dispatch(setShouldRefresh(true));
          }

          return response;
        });
    }

    if (!isUsingGcal) {
      return setApproved().then(() => setIsSubmitting(false));
    }

    // Check Google token
    if (getToken() === null) {
      console.log(await linkGoogleCalendar());
    }

    if (profile.calendar_id === null) {
      apiCalendar.createCalendar(`PixelCard - ${profile.name}`)
        .then(({ result }) => {
          if (Boolean(result)) {
            calendarId = result.id;
            profileApi.saveCalendarId(profile.uuid, `${calendarId}`);

            saveEvent(`${calendarId}`)
              .then((event: any) => event?.result.id)
              .then(eventId => setApproved(eventId))
              .finally(() => setIsSubmitting(false));
          }
        })
        .catch(({ result }) => dispatch(openResponseDialog({
          title: 'Calendar Creation Failed',
          description: result.error.message,
        })))
        .finally(() => setIsSubmitting(false));
    } else {
      calendarId = profile.calendar_id;
      saveEvent(`${calendarId}`)
        .then((event: any) => setApproved(event?.result.id))
        .finally(() => setIsSubmitting(false));
    }
  }

  useEffect(() => {
    if (!Boolean(appointment?.appointment_uuid)) return;
    dispatch(startLoading());
    appointmentApi.getDetails(appointment?.appointment_uuid).then(response => {
      const details = response.data.data;
      setAppt(details);
      setInitialValues({
        date: details.date,
        start_time: details.time.slice(0, -3),
        remark: '',
      })
      setStatus(details.status);
      dispatch(endLoading());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment]);

  useEffect(() => {
    if (isConfirmed && action === 'handleAcceptGcal') {
      localStorage.setItem('isUsingGcal', '1');
      handleApprove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed, action])

  useEffect(() => {
    if (isCancelled && cancelAction === 'handleDeclineGcal') {
      localStorage.setItem('isUsingGcal', '0');
      handleApprove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelled, cancelAction])

  return <Dialog open={open} onClose={handleClose} fullWidth PaperProps={{ sx: { borderRadius: '16px' } }}>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      border: '1px solid var(--Status-Default, #C5C5C5)',
      borderRadius: '4px',
      padding: '32px',
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: '#826FFF', borderRadius: '4px', p: '5px 10px', mb: '10px' }}>
        <Typography sx={{ color: '#EEECFF', fontSize: '13px', fontWeight: 500, textTransform: 'capitalize' }}>
          {appt.type}
        </Typography>
        {Boolean(status) && <Chip sx={['approved', 'updated', 'completed'].includes(status) ? approvedChipStyle : status === 'pending' ? pendingChipStyle : declinedChipStyle} label={status} />}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '9px', height: '9px', background: profile?.color, borderRadius: '100%' }} />
        <Typography sx={{ ml: '10px', flex: 1, fontSize: 13, fontWeight: 400, color: '#18191C', textAlign: 'start' }}>
          {appt.profile_name}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <img src={icons.time} alt="" width={'17px'} height={'17px'} />
        <Typography sx={{ ml: '10px', flex: 1, fontSize: 13, fontWeight: 500, color: '#18191C', textAlign: 'start' }}>
          {moment(`${appt.date}${appt.time}`, 'YYYY-MM-DDTHH:mm').format('D MMMM YYYY, h:mm A')}
        </Typography>
      </Box>
      <Divider sx={{ my: '10px' }} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar src={appointment?.profile_picture ?? icons.profile_picture_icon} sx={{ width: 56, height: 56 }} />
        <Box sx={{ flex: 1, ml: '10px' }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, textAlign: 'start' }}>
            {appt.name}
          </Typography>
          <Link href={`mailto:${appt.email[0]}`} sx={{ color: '#373736', textDecoration: 'none' }}>
            <Typography sx={{ fontSize: 13, fontWeight: 400, textAlign: 'start' }}>
              {appt.email[0]}
            </Typography>
          </Link>
          <Link href={`tel:${appt.phone[0]}`} sx={{ color: '#373736', textDecoration: 'none' }}>
            <Typography sx={{ fontSize: 13, fontWeight: 400, textAlign: 'start' }}>
              {appt.phone[0]}
            </Typography>
          </Link>
        </Box>
      </Box>
      <Typography sx={{ fontSize: 13, fontWeight: 400, textAlign: 'start' }}>
        {appt.note}
      </Typography>
      <Divider sx={{ my: '10px' }} />
      {isHistory && <Box sx={{ display: 'flex', gap: 1 }}>
        <Link href={`tel:${appt.phone[0]}`}>
          <Button startIcon={<img src={icons.call} alt='' />} sx={{ pl: '10px', height: '100%', border: '1px solid #C5C5C5', color: 'black' }}>Call</Button>
        </Link>
        <Link href={`mailto:${appt.email[0]}`}>
          <Button startIcon={<img src={icons.email_icon} alt='' />} sx={{ border: '1px solid #C5C5C5', color: 'black' }}>Email</Button>
        </Link>
      </Box>}
      {!isHistory && accessRights.includes('appointment edit') && <Box sx={{ display: 'flex', gap: 1 }}>
        {appt.status === 'pending' && <Button startIcon={<img src={icons.tick} alt='' />} sx={{ border: '1px solid #C5C5C5', color: 'black', background: status === 'approved' ? '#B5FFC1' : '' }} onClick={() => setOpenConfirmDialog('approve')}>Accept</Button>}
        <Button startIcon={<img width={16} src={icons.multiplication_sign} alt='' />} sx={{ border: '1px solid #C5C5C5', color: 'black', background: status === 'declined' ? '#FFD4D4' : '' }} onClick={() => setOpenConfirmDialog('decline')}>Decline</Button>
        <Button startIcon={<img width={16} src={icons.calendar} alt='' />} sx={{ border: `1px solid ${openForm ? '#FECD20' : '#C5C5C5'}`, color: 'black', background: openForm ? '#FFF1BE' : '' }} onClick={() => setOpenForm(true)}>Reschedule</Button>
      </Box>}
      {openForm && Boolean(initialValues) && <Formik
        initialValues={initialValues}
        validate={values => {
          const errors: any = {};

          if (values.date === '') {
            errors.date = "Date required"
          }

          if (values.start_time === '') {
            errors.start_time = "Start time required"
          }

          return errors;
        }}
        onSubmit={values => {
          const isUsingGcal = Boolean(Number(localStorage.getItem('isUsingGcal')));
          const body = {
            appointment_uuid: appointment.appointment_uuid,
            date: values.date,
            time: `${values.start_time}:00`,
            remark: values.remark,
          }

          appointmentApi.reschedule(body)
            .then(async (response) => {
              const postReschedule = () => {
                setStatus('updated');
                setAppt({
                  ...appt,
                  time: values.start_time,
                  date: values.date,
                });
                setOpenForm(false);
                dispatch(setShouldRefresh(true));
              }

              // Update Google Calendar event
              if (Boolean(appointment.gcal_event_id) && isUsingGcal) {
                const startTime = `${values.date}T${`${values.start_time}:00`}+08:00`;
                const endTime = moment(startTime).add(1, 'h').toISOString();
                const timeZone = 'Asia/Kuala_Lumpur';
                const event = {
                  start: {
                    dateTime: startTime,
                    timeZone,
                  },
                  end: {
                    dateTime: endTime,
                    timeZone,
                  },
                };

                try {
                  const updatedEvent = await apiCalendar.updateEvent(event, appointment.gcal_event_id, profile.calendar_id);

                  return updatedEvent;
                } catch (e) {
                  console.log(e);
                  return Promise.resolve(null);
                } finally {
                  postReschedule();
                }
              } else {
                postReschedule();
              }
            })
            .catch(console.log)
            .finally(() => setOpenForm(false));
        }}
      >{({
        values,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (<Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <div className={`datepicker ${Boolean(errors.date) ? 'has-error' : ''}`}>
          <Typography component='label' className='required' variant='h5'>
            Schedule Date
          </Typography>
          <DatePicker
            id='date'
            name='date'
            required={true}
            locale='en-MY'
            calendarIcon={<div className='icon' style={{ mask: `url(${icons.calendar})` }} />}
            clearIcon={null}
            value={values.date}
            onChange={(date: any) => {
              setFieldValue('date', moment(date).format('YYYY-MM-DD'));
            }}
            minDate={new Date()}
          />
          {Boolean(errors.date) && <p style={{ color: '#d32f2f', margin: '3px 14px 0', fontSize: '12px' }}>{`${errors.date}`}</p>}
        </div>
        <div>
          <Typography component='label' className='required' variant='h5'>
            Schedule Time
          </Typography>
          <TextField
            type='text'
            color='secondary'
            margin="dense"
            required
            fullWidth
            id="start_time"
            name="start_time"
            value={Boolean(values.start_time) ? moment(values.start_time, ['H:mm']).format('hh:mm A') : values.start_time}
            error={Boolean(errors.start_time)}
            helperText={`${errors.start_time}`}
            onClick={() => setOpenStartPicker(true)}
            InputProps={{
              endAdornment: <IconButton sx={{ opacity: 0.23, '&:hover': { opacity: 1 } }} aria-label="show" size="small" onClick={() => { }}>
                <div className='icon' style={{ background: 'black', width: '24px', height: '24px', mask: `url(${icons.time})` }} />
              </IconButton>,
            }}
          />
          <TimePicker
            openPicker={openStartPicker}
            setOpenPicker={setOpenStartPicker}
            pickerValue={startPickerValue}
            setPickerValue={setStartPickerValue}
            handleCancel={() => {
              setOpenStartPicker(false);

              if (Boolean(values.start_time)) {
                setStartPickerValue(stringToPicker(values.start_time));
              }
            }}
            handleConfirm={() => {
              setFieldValue('start_time', pickerToString(startPickerValue));
              setOpenStartPicker(false);
            }}
          />
        </div>
        <div>
          <Typography component='label' variant='h5'>
            Remark
          </Typography>
          <TextField
            type='text'
            color='secondary'
            margin="dense"
            fullWidth
            multiline
            rows={4}
            id="remark"
            name="remark"
            value={values.remark}
            placeholder="Remark"
            onChange={handleChange}
          />
        </div>
        {/* <Button type="submit" variant="contained" sx={{ borderRadius: '30px', background: '#373736', color: '#FECD20' }}>Reschedule</Button> */}
        <LoadingButton
          id="reschedule-button"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          loadingPosition={isSubmitting ? "start" : undefined}
          startIcon={isSubmitting ? <div /> : undefined}
          sx={{ borderRadius: '30px', background: '#373736', color: '#FECD20' }}
        >
          <span>{isSubmitting ? 'Rescheduling' : 'Reschedule'}</span>
        </LoadingButton>
        <Button onClick={() => setOpenForm(false)} sx={{ color: 'black' }}>Cancel</Button>
      </Box>)}
      </Formik>}
    </Box>
    <Dialog open={Boolean(openConfirmDialog)} PaperProps={{ sx: { borderRadius: '10px' } }}>
      <DialogTitle style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Typography style={{
          fontSize: '31px',
          fontStyle: 'normal',
          fontWeight: '500',
          textAlign: 'center'
        }}>Confirm {openConfirmDialog === 'decline' ? 'Decline' : 'Accept'}?</Typography>
      </DialogTitle>
      <Box>
        <DialogContent>
          <Typography align="center">
            Are you sure to {openConfirmDialog === 'decline' ? 'decline' : 'accept'} appointment request<br />
            from {appt.name} at {moment(`${appt.date}${appt.time}`, 'YYYY-MM-DDTHH:mm').format('D MMMM YYYY, h:mm A')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            fullWidth
            onClick={() => setOpenConfirmDialog('')}
            variant="outlined"
            sx={{ borderRadius: '30px', color: 'black', border: '1px solid #FECD20', }}
          >
            Cancel
          </Button>
          <LoadingButton
            id="update-button"
            type="button"
            fullWidth
            variant="contained"
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={isSubmitting ? <div /> : undefined}
            sx={{ borderRadius: '30px', background: '#373736', color: '#FECD20' }}
            onClick={() => {
              const isUsingGcal: string | null = localStorage.getItem('isUsingGcal');
              setIsSubmitting(true);
              setOpenForm(false);
              if (openConfirmDialog === 'decline') {
                handleDecline();
              } else if (openConfirmDialog === 'approve') {
                if (isUsingGcal === null) {
                  // Ask the user whether they want to link with Google Calendar or not
                  dispatch(openActionDialog({
                    title: 'Google Calendar Integration',
                    description: 'Approve this appointment request and put it in Google Calendar?',
                    action: 'handleAcceptGcal',
                    cancelAction: 'handleDeclineGcal',
                    cancelText: 'Not Now',
                  }));
                } else {
                  handleApprove();
                }
              }
            }}
          >
            <span>{isSubmitting ? 'Working...' : 'Yes'}</span>
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  </Dialog>
};

export default ActionsDialog;