import React, {useEffect, useState} from "react";
import LoginSecurity from "./loginSecurity.component";
import userApi from "../../../../api/user";
import {  useNavigate } from 'react-router-dom';

export default function LoginSecurityContainer(){
  const [email,setEmail] = useState("")
  const [errors] = useState<Error>({
    oldPassword: null,
    newPassword: null,
    confirmPassword: null
  })
  const [body,setBody] = useState({})
  const [hasError,setHasError] = useState(false)
  const [openDialog,setOpenDialog] = useState(false)
  const [responseDialogOpen,setResponseDialogOpen] = useState(false)
  const [responseMessage] = useState({
    title: 'Change Password Success',
    description: 'Your password has been changed.'
  })
  const navigate = useNavigate();
  interface Error {
    oldPassword: string | null,
    newPassword: string | null,
    confirmPassword: string | null
  }

  const getEmail = () =>{
    const user = JSON.parse(localStorage.getItem('user') || "")
    const email = user.access_token_payload.email
    return  setEmail(email)
  }

  useEffect(() =>{
    getEmail()
  },[])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const body = {
      old_password: data.get('oldPassword'),
      new_password:  data.get('newPassword'),
      confirm_password: data.get('confirmPassword')
    };
    setHasError(false)
    await formValidate(body);
    if(hasError) return 
    setOpenDialog(true)
    setBody(body)
    return true
  };

  const formValidate = async (requestBody) =>{
    const validationErrors = errors;
    if(!requestBody.old_password){
      setHasError(true)
      validationErrors.oldPassword = "Current password is required"
    }
    if(!requestBody.new_password){
      setHasError(true)
      validationErrors.newPassword = "New password is required"
    }

    if(!requestBody.confirm_password){
      setHasError(true)
      validationErrors.confirmPassword = "Confirm password is required"
    }

    if(requestBody.new_password === requestBody.confirm_password){
      validationErrors.confirmPassword = "Passwords does not match"
    }
    return true;
  }

  const handleDialogConfirm = async () => {
    const response = await userApi.changePassword({requestBody: body})
    .then(response => response)
    .catch(error => error)

    if(!response.success){
      responseMessage.title ="Change Password Failed"
      responseMessage.description = "Your password has not been changed"
    }
    setResponseDialogOpen(true)
  }
  const handleDialogClose = () =>{
      setOpenDialog(false)
  }

  const handleResponseClose = () =>{
    setResponseDialogOpen(false)
    navigate(0)
  }
  return <LoginSecurity 
  email={email}
  handleSubmit={handleSubmit}
  handleDialogConfirm={handleDialogConfirm}
  handleDialogClose={handleDialogClose}
  openDialog={openDialog}
  handleResponseClose={handleResponseClose}
  responseDialogOpen={responseDialogOpen}
  responseMessage={responseMessage}
  />
}