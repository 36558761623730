import React from "react";
import { MenuItem, Select, Typography } from "@mui/material";
import icons from "../../../../assets/icons";

const SortDropdown = ({ ordering, setOrdering }) => {
  return <>
    <Typography component={'span'} sx={{ mr: '10px' }}>Sort By:</Typography>
    <Select
      IconComponent={(props) => (<img src={icons.arrow_down_01_icon.toString()} {...props} alt='' />)}
      size="small"
      id="profile"
      value={ordering}
      sx={{
        flex: 1,
        fontSize: '12px',
        textAlign: 'start',
        width: '160px',
        '&:focus': { border: 'solid #FECD20' },
        '& img': { top: 'inherit' },
      }}
      onChange={event => setOrdering(event.target.value as string)}
    >
      <MenuItem value='newest' selected={true}>Newest</MenuItem>
      <MenuItem value='oldest'>Oldest</MenuItem>
      <MenuItem value='low_high'>Price (Low to High)</MenuItem>
      <MenuItem value='high_low'>Price (High to Low)</MenuItem>
    </Select>
  </>
}

export default SortDropdown;