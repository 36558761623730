import { useEffect } from "react";
import { getProfile,getProfileWithCard} from '../../features/landing-page'
import { useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import {useSelector} from 'react-redux';
import { setAccessType } from "../../features/landing-page";
import './landingPage.css'
import accessType from '../../lib/constants/accessType'
import { startLoadingLP, endLoadingLP } from "src/features/loading";


export default function LandingPage({from}) {
  const profileTheme = useSelector(state => state.landingPage.theme);

  const defaultTheme = createTheme({
    palette: {
      primary: {
        main: '#000000',
      },
      secondary: {
        main: profileTheme?.highlight?.secondary_color|| '#FECD20',
      },
      action: {
        selected: '#FECD20',
        selectedOpacity: 0.08,
      }
    },
    typography: {
      fontFamily: 'Rubik Variable, sans-serif',
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            background:  'black !important'
          }
        }
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            color: '#000000',
            backgroundColor: '#FFEFBA',
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: '13px',
            fontWeight: 500,
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            backgroundColor: "white",
            borderRadius: "4px",
            // ... other input styles
          },
        },
      },

      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            color: '#A7A7A7',
            '&.Mui-selected': {
              color: '#000000',
              backgroundColor: '#FECD20',
            },
            "&:hover": {
              backgroundColor: "#FFEFBA",
            },
          },
        },
      },
    },
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const landingPage = useSelector(state => state.landingPage);
  // useEffect(() => {
  //   getProfileWithSerialNumber()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [dispatch]);

  useEffect(()=>{
    getProfileWithSerialNumber()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[id])

  useEffect(()=>{
    if(landingPage.isLoading){
      dispatch(startLoadingLP())
    }else{
      dispatch(endLoadingLP())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[landingPage.isLoading])

  useEffect(()=>{
    dispatch(setAccessType(from))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[landingPage.accessType])

  const getProfileWithSerialNumber = () =>{
    const serialNo = id;
    if(from === accessType.CARD){
      dispatch(getProfileWithCard({serialNo: serialNo}));
      return true
    }
    dispatch(getProfile({serialNo:serialNo}));
  } 

  return <ThemeProvider theme={defaultTheme}>
    <CssBaseline/>
    <Box style={{
    background: '#FFF',
    margin: 'auto',
    display: 'flex',
    position: 'sticky',
    maxWidth: '425px',
    // overflow: 'scroll',
    flexDirection: 'column',
    minHeight: '100vh',
    color: '#000000',
  }}>
    <Outlet/>
  </Box>
  </ThemeProvider>
}