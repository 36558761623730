import React, {useState} from 'react'
import ExchangeForm from './component/exchangeForm'
import GuestConnected from './component/guestConnected';

export default function ExchangeContactComponent ({userDetails,contactInfo,handleMembersExchange}) {
  const [contacted, setContacted] = useState(false)

  return <>
  {
    !contacted ? 
    <ExchangeForm userDetails={userDetails} setContacted={setContacted} contactInfo={contactInfo} handleMembersExchange={handleMembersExchange}/>:
    <GuestConnected userDetails={userDetails} contactInfo={contactInfo} contacted={contacted}/>
  }
  </> 
    
}