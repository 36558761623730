import axios from "axios";

interface CartDetails {
  cart_uuid: string;
  front_thumbnail: string;
  back_thumbnail: string;
  product_uuid: string;
  card_name: string;
  card_variation: string;
  font_color_code: string;
  logo_url: string;
  quantity: number;
  name: string;
  position: string;
  pixelcare: number;
  zakeke_design_id: string;
  card_variation_uuid: string;
  orientation: string;
}

interface Data {
  success: boolean;
  code: number;
  message: string;
  data: CartDetails;
}

const getCartDetails = (cart_uuid: string): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get(`product/getCartDetails?cart_uuid=${cart_uuid}`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default getCartDetails;