import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Container,
  CssBaseline,
  Link,
  Paper,
  SvgIcon,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '14px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

const MailSent = () => (
  <SvgIcon sx={{ fontSize: 120, position: 'relative', right: '20px' }} >
    <svg width="112" height="54" viewBox="0 0 112 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.936" fill-rule="evenodd" clip-rule="evenodd" d="M0 17.8427C0 17.243 0 16.6436 0 16.044C0.361644 15.7436 0.760738 15.4812 1.19728 15.257C9.57825 15.107 17.9592 15.107 26.3402 15.257C27.7645 15.8041 28.1237 16.741 27.4177 18.0675C27.2183 18.2548 27.0186 18.4424 26.8191 18.6297C18.1987 18.7796 9.57825 18.7796 0.957825 18.6297C0.514776 18.4745 0.1955 18.2123 0 17.8427Z" fill="#FECD20" />
      <path opacity="0.93" fill-rule="evenodd" clip-rule="evenodd" d="M8.61604 25.2641C14.6828 25.2265 20.749 25.2641 26.8147 25.3765C27.7996 26.1655 27.9991 27.1024 27.4134 28.187C27.1329 28.5003 26.7738 28.6878 26.3358 28.7492C20.5889 28.8991 14.8419 28.8991 9.09495 28.7492C8.13421 28.5583 7.6553 27.9962 7.65822 27.0628C7.72577 26.3364 8.04503 25.7367 8.61604 25.2641Z" fill="#FECD20" />
      <path opacity="0.919" fill-rule="evenodd" clip-rule="evenodd" d="M16.7655 34.0297C20.1188 33.9922 23.4712 34.0297 26.8227 34.1421C27.9824 35.1269 28.0623 36.1761 27.0621 37.2899C26.8227 37.3648 26.5832 37.4399 26.3438 37.5148C23.3106 37.6648 20.2775 37.6648 17.2444 37.5148C15.95 37.0696 15.511 36.2075 15.9274 34.9291C16.2568 34.6581 16.5362 34.3584 16.7655 34.0297Z" fill="#FECD20" />
      <path opacity="0.96" fill-rule="evenodd" clip-rule="evenodd" d="M112 4.58153C112 19.2979 112 34.0143 112 48.7307C111.238 51.0423 109.612 52.5465 107.12 53.2428C85.1389 53.3354 63.1576 53.3354 41.1762 53.2428C38.6849 52.5465 37.0585 51.0423 36.2969 48.7307C36.2969 34.0143 36.2969 19.2979 36.2969 4.58153C37.0585 2.2699 38.6849 0.765867 41.1762 0.0694205C63.1576 -0.0231402 85.1389 -0.0231402 107.12 0.0694205C109.612 0.765867 111.238 2.2699 112 4.58153ZM42.2112 3.19319C63.4779 3.03129 84.7693 3.00815 106.085 3.12377C96.0159 11.9779 85.8138 20.6781 75.479 29.2246C74.1841 29.7077 73.0012 29.5226 71.9304 28.6692C61.9989 20.1998 52.0924 11.7077 42.2112 3.19319ZM39.5497 5.41453C39.7053 5.39384 39.8531 5.41689 39.9933 5.48395C48.1514 12.4734 56.2836 19.4845 64.3897 26.5173C56.0741 33.538 47.8433 40.6649 39.6976 47.8977C39.5497 33.7369 39.5004 19.5759 39.5497 5.41453ZM108.303 5.41453C108.451 5.41453 108.599 5.41453 108.747 5.41453C108.796 19.5759 108.747 33.7369 108.599 47.8977C100.453 40.6649 92.2225 33.538 83.9069 26.5173C92.0419 19.4799 100.174 12.4458 108.303 5.41453ZM66.9033 28.7386C68.2006 29.701 69.4821 30.6959 70.7476 31.7236C73.0147 32.9268 75.2819 32.9268 77.549 31.7236C78.7756 30.8035 79.9585 29.8317 81.0976 28.8081C81.3058 28.7643 81.5028 28.8106 81.689 28.9469C90.0036 35.8742 98.1851 42.9547 106.233 50.1885C84.8432 50.2811 63.4533 50.2811 42.0633 50.1885C50.1948 42.8526 58.4748 35.7027 66.9033 28.7386Z" fill="black" />
    </svg>
  </SvgIcon>
)

export default function Unverified() {
  const navigate = useNavigate();
  const pixel_logo = require("./pixel_logo.png") as string;
  const { access_token_payload } = JSON.parse(localStorage.getItem('user') ?? '{}');

  const handleSignout = () => {
    localStorage.removeItem("cart");
    localStorage.removeItem("user");
    localStorage.setItem("contactGuide", 'false')
    navigate('/')
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper elevation={9}
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            borderRadius: '8.78px',
          }}
        >
          <div style={{ margin: '20px' }}>
            <img src={pixel_logo} alt='PixelCard logo' />
          </div>
          <MailSent />
          <Typography component="h1" variant="h4" marginBottom={'10px'}>
            Verify your account now!
          </Typography>
          <Typography component="h3" variant="h5" paddingX={'30px'} textAlign={'center'}>
            We’ve already sent out the verification link. Please check your email inbox and confirm it’s really you.
          </Typography>
          {Boolean(access_token_payload) && <Typography sx={{ mt: '20px' }}>
            Not {access_token_payload.name}?&nbsp;
            <Link underline="hover" sx={{ color: "#007AFF" }} href='#' onClick={handleSignout}>
              Sign in
            </Link>
            &nbsp;as different user.
          </Typography>}
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
