import axios from "axios";

const guestSignup = ({requestBody}) => {

  return new Promise((resolve, reject) => {
    axios.post("basicauth/guestsignup",requestBody)
    .then(response => resolve(response.data))
    .catch(error => reject(error.response.data));
})
};

export default guestSignup;
