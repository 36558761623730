import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const checkSession = (
  {session_id,session_expiration}:{session_id:string,session_expiration:string}): Promise<Data> => {
  
  const params = new URLSearchParams([['session_id', session_id],["session_expiration",session_expiration]]);
    return new Promise((resolve, reject) => {
        axios.get("basicauth/checkSession",{params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default checkSession;
