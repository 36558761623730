import React from "react"
import { Slider, SliderThumb, styled } from "@mui/material"
import icons from "../../assets/icons"

const VoucherSlider = styled(Slider)({
  pointerEvents: 'none',
  color: '#FECD20',
  height: 48,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 48,
    width: 48,
    backgroundColor: '#fff',
    pointerEvents: 'all',
  },
  '& .MuiSlider-thumb .icon': {
    background: 'linear-gradient(to right, #FFECAB, #FECD20, #FFECAB, #FECD20)',
    backgroundSize: '400% 400%',
    animation: 'gradient 1s ease infinite',
  },
  "@keyframes gradient": {
    "0%": { backgroundPosition: 'left' },
    "50%": { backgroundPosition: 'center' },
    "100%": { backgroundPosition: 'right' },
  },
  '& .MuiSlider-rail': {
    opacity: 0,
  },
});

interface PixelThumbComponentProps extends React.HTMLAttributes<unknown> { }

export function PixelThumbComponent(props: PixelThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <div className='icon' style={{
        height: '20px',
        width: '21px',
        mask: `url(${icons.slider_thumb})`,
        WebkitMask: `url(${icons.slider_thumb})`,
      }} />
    </SliderThumb>
  );
}

export default VoucherSlider;