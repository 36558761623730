import React,{useEffect} from 'react';
import { Box,Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Typography } from '@mui/material';
import icons from '../../../assets/icons';
import { CollectedVoucher } from '../../../lib/types/Wallet';
import parse from 'html-react-parser';
import FileHelper from '../../../lib/helper/fileHelper';
import moment from 'moment';
export default function DialogVoucher({ open, handleClose,voucher }: 
  { open: boolean, handleClose: () => void, voucher: CollectedVoucher }) {
  const [backgroundImage, setBackgroundImage] = React.useState('' as string);
  const getUrl = (url: string) => {
    return new Promise((resolve)=>{
      FileHelper.getUrl(url).then((res) => {
        resolve(res)
      });
    })
  }

  useEffect(() => {
    getUrl(voucher.banner_image).then((res) => {
      setBackgroundImage(res as string);
    })
  }, [voucher.banner_image]);
  return (
    <Dialog sx={{
      '& .MuiPaper-root':{
        width:'430px'
      }
    }}open={open} onClose={handleClose} >
      <DialogTitle sx={{padding: 0, height:'100%'}}>
        <div style={{ 
          background: 'lightgray 50% / cover no-repeat',
          backgroundImage: `url(${backgroundImage})`,
          height: '223px', width: '100%' }} />
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', top: 0, right: 0 }}>
          <img src={icons.close.toString()} alt="close dialog"/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <h3>{voucher?.voucher_title}</h3>
        <Box sx={{mb:"1rem"}}>
          <Typography fontSize="14px"  color="grey">Valid Period</Typography>
          <Typography fontSize="16px">{moment(voucher?.start_date_time).format('DD MMM YYYY')} - {moment(voucher?.end_date_time).format('DD MMM YYYY')}</Typography>
        </Box>
        <Typography sx={{mb:"1rem"}}>
          {voucher?.description}
        </Typography>
        <Box>
          <Typography fontSize="16px" fontWeight='500'>Terms & Conditions</Typography>
          <Typography fontSize="14px">
            {parse(voucher?.terms_and_condition)}
          </Typography> 
        </Box>
      </DialogContent>
      <DialogActions>
        {/* Add your action buttons here */}
      </DialogActions>
    </Dialog>
  );
}
