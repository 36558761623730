import React, { useEffect } from 'react'
import { Paper, Box, Typography, Tooltip, IconButton, Collapse, createTheme, Button } from '@mui/material';
import icons from '../../../../../assets/icons';
import { useSelector } from 'react-redux';
import { Theme } from '../../../../../lib/types/Profile';
import * as themeOptions from '../../../../../lib/constants/themeOptions';
import ColorInput from '../../../../../components/color-input/colorInput'
import { useParams } from 'react-router-dom';

interface ExpandMoreProps {
  expanded: boolean;
  onClick: () => void;
}

function ExpandMoreButton({ expanded, onClick }: ExpandMoreProps) {
  const theme = createTheme({});
  return <IconButton sx={{
    m: '8px',
    transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    border: `1px solid ${expanded ? '#373736' : 'black'}`,
    bgcolor: expanded ? '#373736' : 'transparent',
    '&:hover .icon': {
      bgcolor: expanded ? '#373736' : 'black',
    },
  }} onClick={onClick}>
    <Box className='icon' sx={{
      backgroundColor: expanded ? '#FECD20' : 'black',
      mask: `url(${icons.arrow_down_01_icon})`,
      maskSize: 'cover',
      maskRepeat: 'no-repeat',
      WebkitMask: `url(${icons.arrow_down_01_icon})`,
      WebkitMaskSize: 'cover',
      WebkitMaskRepeat: 'no-repeat',
      width: '15px',
      height: '15px',
    }} />
  </IconButton>
}

export default function ThemeOptionComponent({
  handleThemeChange,
  handleBackgroundChange,
  setCustomColor,
  setCustomBackground
}: {
  handleThemeChange: (theme: Theme) => void
  handleBackgroundChange: (color: string) => void,
  setCustomColor: (color: object) => void,
  setCustomBackground: (color: string) => void
}) {
  const backgroundColor = useSelector((state: any) => state.landingPage.background_color);
  const initBackgroundColor = useSelector((state: any) => state.landingPage.initialBgColor) ?? '#FFFFFF';
  const initTheme = useSelector((state: any) => state.landingPage.initialTheme) ?? {};
  const profileTheme = useSelector((state: any) => state.landingPage.theme);
  const [backgroundOpen, setBackgroundOpen] = React.useState(true);
  const [colorOpen, setColorOpen] = React.useState(true);
  const params = useParams();
  const colorThemes = [
    {
      name: 'Pixel',
      icon: icons.default_theme,
      alt: 'default_theme',
      theme: themeOptions.defaultTheme,
    },
    {
      name: 'Rose Blue',
      icon: icons.rose_blue_theme,
      alt: 'rose_blue_theme',
      theme: themeOptions.roseBlueTheme,
    },
    {
      name: 'Dark Purple',
      icon: icons.purple_theme,
      alt: 'purple_theme',
      theme: themeOptions.purpleTheme,
    },
    {
      name: 'Black Grey',
      icon: icons.black_grey_theme,
      alt: 'black_grey_theme',
      theme: themeOptions.blackGreyTheme,
    },
    {
      name: 'Peach Pink',
      icon: icons.pink_peach_theme,
      alt: 'pink_peach_theme',
      theme: themeOptions.pinkPeachTheme,
    },
    {
      name: 'Neon Yellow',
      icon: icons.lime_yellow_black_theme,
      alt: 'lime_yellow_black_theme',
      theme: themeOptions.limeYellowBlackTheme,
    },
    {
      name: 'Fragrant Purple',
      icon: icons.blue_purple_theme,
      alt: 'blue_purple_theme',
      theme: themeOptions.bluePurpleTheme,
    },
    {
      name: 'Lime Green',
      icon: icons.lime_green_theme,
      alt: 'lime_green_theme',
      theme: themeOptions.limeGreenTheme,
    },
    {
      name: 'Popsicle Blue',
      icon: icons.popsicle_blue_theme,
      alt: 'popsicle_blue_theme',
      theme: themeOptions.popsicleBlueTheme,
    },
    {
      name: 'Red Black',
      icon: icons.red_black_theme,
      alt: 'red_black_theme',
      theme: themeOptions.redBlackTheme,
    },
  ];

  // set the background color and theme color initially
  useEffect(() => {
    handleBackgroundChange(backgroundColor)
  }, [backgroundColor, handleBackgroundChange])

  useEffect(() => {
    handleThemeChange(profileTheme)
  }, [profileTheme, handleThemeChange])
  return <Paper elevation={0} sx={{ padding: '30px', borderRadius: '8.78px' }}>
    <Typography component='h4' variant='h4' >
      Select Theme
    </Typography>
    {/* Background Theme Start */}

    <Paper sx={{ mt: "2rem", borderRadius: '10px', background: '#FFFFFF' }}>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', height: "4rem" }}>
        <Typography sx={{ paddingLeft: '1rem', fontSize: '17px', fontWeight: 700, flex: 1 }}>
          Background Theme
        </Typography>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          fullWidth={false}
          sx={{
            fontSize: '15px',
            fontWeight: '500',
            textTransform: 'none',
            borderRadius: '30px',
            marginRight: '10px',
            display: (!backgroundOpen || params.profileUuid === undefined) ? 'none' : 'inline-flex'
          }}
          onClick={() => { setCustomBackground(initBackgroundColor) }}
          startIcon={<img alt="discount_icon" src={icons.refresh} />}
        >
          Reset
        </Button>
        <ExpandMoreButton
          expanded={backgroundOpen}
          onClick={() => setBackgroundOpen(!backgroundOpen)}
          aria-expanded={backgroundOpen}
          aria-label="show more"
        />
      </div>
      <Collapse in={backgroundOpen} timeout="auto" unmountOnExit sx={{ p: "1rem" }}>
        {/* <Typography component='p' variant='h5' sx={{ pt: '1rem', pb: '1rem', color: '#AAAAAA' }}>
          Background Theme
        </Typography> */}
        <Box sx={{ display: 'flex', gap: '1rem', marginTop: '1rem', flexWrap: 'wrap' }}>
          {['#FFFFFF', '#476C8B', '#BDBCDF', '#C5C5C5', '#FCD5CE', '#373736', '#7C17D7', '#2A4747', '#D8EEF0'].map((color, index) => <Box
            key={index}
            sx={{
              boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
              background: color,
              height: '50px',
              width: '50px',
              cursor: 'pointer',
              borderRadius: "48px",
              border: backgroundColor === color ? '2px solid #FECD20' : 'none',
            }}
            onClick={() => handleBackgroundChange(color)}
          />)}
        </Box>
        <ColorInput
          value={backgroundColor}
          label="Background Color"
          setCustomColor={(color) => { setCustomBackground(color) }}
        />
      </Collapse>
    </Paper>
    {/* Background Theme End */}
    {/* Color Theme Start */}
    <Paper sx={{ mt: "2rem", borderRadius: '10px', background: '#FFFFFF' }}>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center', height: "4rem" }}>
        <Typography sx={{ paddingLeft: '1rem', fontSize: '17px', fontWeight: 700, flex: 1 }}>
          Color Theme
        </Typography>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          fullWidth={false}
          sx={{
            fontSize: '15px',
            fontWeight: '500',
            textTransform: 'none',
            borderRadius: '30px',
            marginRight: '10px',
            display: (!colorOpen || params.profileUuid === undefined) ? 'none' : 'inline-flex'
          }}
          onClick={() => {
            setCustomColor(initTheme)
          }}
          startIcon={<img alt="discount_icon" src={icons.refresh} />}
        >
          Reset
        </Button>
        <ExpandMoreButton
          expanded={colorOpen}
          onClick={() => setColorOpen(!colorOpen)}
          aria-expanded={colorOpen}
          aria-label="show more"
        />
      </div>
      <Collapse in={colorOpen} timeout="auto" unmountOnExit sx={{ p: "1rem" }}>
        <Typography component='p' variant='h5' sx={{ pb: '1rem', color: '#AAAAAA' }}>
          Color Theme
        </Typography>
        <Box sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
          {colorThemes.map((colorTheme, index) => <Tooltip key={index} title={colorTheme.name} placement="top">
            <img
              style={{
                height: '50px',
                width: '50px',
                cursor: 'pointer',
                border: profileTheme?.name === colorTheme.theme.name ? '2px solid #FECD20' : 'none',
                borderRadius: '48px',
              }}
              src={colorTheme.icon}
              alt={colorTheme.alt}
              onClick={() => handleThemeChange(colorTheme.theme)}
            />
          </Tooltip>)}
        </Box>
        <Box sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "0.5rem"
        }}>
          <ColorInput
            value={profileTheme?.primary_background}
            label="Primary Color"
            setCustomColor={(color) => { setCustomColor({ primary_background: color }) }}
          />
          <ColorInput
            value={profileTheme?.secondary_background}
            label="Secondary Color"
            setCustomColor={(color) => { setCustomColor({ secondary_background: color }) }}
          />
          <ColorInput
            value={profileTheme?.primary_font_color}
            label="Primary Font"
            setCustomColor={(color) => { setCustomColor({ primary_font_color: color }) }}
          />
          <ColorInput
            value={profileTheme?.secondary_font_color}
            label="Secondary Font"
            setCustomColor={(color) => { setCustomColor({ secondary_font_color: color }) }}
          />
        </Box>

        {/* Color Theme End */}

        {/* Button Theme Start */}
        <Box>
          <Typography component='p' variant='h5' sx={{ pt: '1rem', pb: '1rem', color: '#AAAAAA' }}>
            Button Theme
          </Typography>
          <Box sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "0.5rem"
          }}>
            <ColorInput
              value={profileTheme?.button?.primary_color}
              label="Primary Color"
              setCustomColor={(color) => {
                setCustomColor({
                  button: {
                    ...profileTheme.button,
                    primary_color: color
                  }
                })
              }}
            />
            <ColorInput
              value={profileTheme?.button?.secondary_color}
              label="Secondary Color"
              setCustomColor={(color) => {
                setCustomColor({
                  button: {
                    ...profileTheme.button,
                    secondary_color: color
                  }
                })
              }}
            />
            <ColorInput
              value={profileTheme?.button?.primary_font_color}
              label="Primary Font"
              setCustomColor={(color) => {
                setCustomColor({
                  button: {
                    ...profileTheme.button,
                    primary_font_color: color
                  }
                })
              }}
            />
            <ColorInput
              value={profileTheme?.button?.secondary_font_color}
              label="Primary Font"
              setCustomColor={(color) => {
                setCustomColor({
                  button: {
                    ...profileTheme.button,
                    secondary_font_color: color
                  }
                })
              }}
            />
          </Box>
        </Box>
        {/* Button Theme End */}
      </Collapse>
    </Paper>
  </Paper>
}
