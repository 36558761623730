import React from "react";
import { Box, Card, CardActionArea, CardContent, Chip, Typography } from "@mui/material";

const TemplateTile = ({ currency, type, template, handleSelect }) => {
  return <Card elevation={0} sx={{ bgcolor: 'white', borderRadius: '8px', position: 'relative', minWidth: '356px', flex: 1, display: 'flex', height: '100%' }}>
    <CardActionArea sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }} onClick={() => handleSelect(template.uuid)}>
      {template.is_popular && <Chip label="Popular" sx={{ color: 'white', fontSize: '10px', fontWeight: 500, bgcolor: '#EB3325', position: 'absolute', left: '20px', top: '20px', zIndex: 10 }} />}
      <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', p: type === 'template' ? '50px' : 0 }}>
        <Box boxShadow={type === 'template' ? 5 : undefined} sx={{
          borderRadius: '18px',
          position: 'relative',
          overflow: 'clip',
          maxWidth: (template.orientation === 'PORTRAIT') ? '58%' : '90%'
        }}>
          <img
            src={template.thumbnail}
            alt="template design"
            style={{
              display: 'block',
              maxWidth: '100%'
            }}
          />
          {/* {type === 'template' && <>
            <Typography sx={{ fontSize: '12px', position: 'absolute', left: '15px', bottom: '30px', color: '#586C6C' }}>Your Name</Typography>
            <Typography sx={{ fontSize: '12px', position: 'absolute', left: '15px', bottom: '15px', color: '#586C6C' }}>Your Position</Typography>
          </>} */}
        </Box>
      </Box>
      <CardContent sx={{ width: '100%', p: '15px', bgcolor: '#FECD20', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', mx: 'auto' }}>
        <Typography sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500 }}>{template.name}</Typography>
        {!Boolean(template.current_price) ?
          <Typography component={'span'} sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500 }}>{currency} {template.previous_price}</Typography>
          :
          <Box>
            <Typography component={'span'} sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500, color: '#A7A7A7', textDecoration: 'line-through', mr: '5px' }}>{currency} {template.previous_price}</Typography>
            <Typography component={'span'} sx={{ flex: 1, fontSize: '12px', textAlign: 'start', fontWeight: 500, color: '#EB3325' }}>{currency} {template.current_price}</Typography>
          </Box>
        }
      </CardContent>
    </CardActionArea>
  </Card>
}

export default TemplateTile;