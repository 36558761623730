import axios from "axios";

interface Data{
  code: number,
  data:  Array<VoucherCode>,
  message: string | null,
  success: boolean
}

interface VoucherCode{
  contact_uuid: string,
  profile_uuid: string,
  contact_guest_uuid: string,
  name: string,
  job_title: string,
  company_name : string,
  profile_picture: string,
  is_new: number,
}
interface RequestBody{
  voucher_uuid: string,
  search: string | null,
  filter: {
    start_date: string | null,
    end_date: string | null,
    collected_status: boolean | null,
    redemption_status: boolean | null
  } | null,
  order_by: string | null,
  order: string | null,
  length: number | null,
  start: number | null
}

const getVoucherCodeList = (requestBody: RequestBody): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
      axios.post("campaign/getVoucherCodeList",requestBody)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default getVoucherCodeList;
