import imageCompression from "browser-image-compression";

const baseURL = process.env.REACT_APP_SERVER_HOST_URL;
function getUrl(fullPath) {
  return fetch(baseURL+'/get-presigned-url', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ fileName: fullPath }),
  })
  .then(res => res.json())
  .then(data => data.url)
  .catch(err => console.log(err));
}

function createUrl(fullPath) {
  return fetch(baseURL+'/create-resigned-post', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ fileName: fullPath }),
  })
  .then(res => res.json());

}

async function imageUrlToBlob(imageUrl)  {
  return fetch(imageUrl)
    .then(response => response.blob()).catch(err => null);
}
async function urlToBase64(fullpath) {
  const imageUrl = await getUrl(fullpath).then(response => response);
  const blob = await imageUrlToBlob(imageUrl).then(response =>{
    if (!response) return null;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        resolve({
          size : response.size,
          type: response.type,
          base64String: base64String.split(",")[1],
          base64:base64String});
      };
      reader.onerror = reject;
      reader.readAsDataURL(response);
    });
  });
  return blob
}

const imagePathToFile = async (fullPath) => {
  try {
    const imageUrl = await getUrl(fullPath).then(response => response);
    const blob = await imageUrlToBlob(imageUrl).then(response => response);
    const file = new File([blob], 'image.jpg', { type: blob.type });
    return file;
  } catch (error) {
    return null;
  }
};

function compressImage(file, maxSizeMB, maxWidthOrHeight) {
  try {
    return imageCompression(file, {
      maxSizeMB,
      maxWidthOrHeight,
      useWebWorker: true,
    });
  } catch (error) {
    console.error(error);
  }
}

async function uploadFile(fullPath, file, shouldCompress = true, maxSizeMB = 5, maxWidthOrHeight = 1920) {
  // Create presigned post
  const { url, fields } = await createUrl(fullPath);

  // Compress the file if it's an image
  if (shouldCompress && file.type.includes("image")) {
    file = await compressImage(file, maxSizeMB, maxWidthOrHeight);
  }

  // Upload the file
  const formData = new FormData();
  Object.keys(fields).forEach(key => {
    formData.append(key, fields[key]);
  });
  formData.append("file", file);
  const res = await fetch(url, {
    method: "POST",
    body: formData,
  });

  // Get the URL if file uploaded successfully
  if (res.status === 204) {
    return fullPath;
  }
}

const FileHelper = {
  getUrl,
  createUrl,
  imageUrlToBlob,
  urlToBase64,
  imagePathToFile,
  uploadFile,
}
export default FileHelper