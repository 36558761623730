import React , {useState} from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import contactApi from '../../../api/contact';
import DragAndDropDialog from '../../../components/dialog/drap-and-drop-dialog'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { openResponseDialog } from '../../../features/response-dialog';
import { useDispatch} from 'react-redux';
import { refreshContacts } from "../../../features/contact";
import csv from "../../../assets/csv"
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


const UploadFileDialog =  ({open,handleClose}) => {
  const dispatch =  useDispatch();
  const uploadFile = async (blobFile, profile_uuid: string) =>{

    if(blobFile instanceof Blob){
      const formData = new FormData();
      formData.append('file',blobFile, 'uploaded-file');
      await contactApi.importContact({requestBody:formData, profile_uuid})
      .then(response => {
        dispatch(openResponseDialog({title: "Import Success",description: "Contact group list has successfull imported"})) 
        dispatch(refreshContacts(true))
        return response
      })
      .catch(error =>  { dispatch(openResponseDialog({title: "Import Failed",description: error}))})

    }
  }

  const handleSubmit = (blobFile, profile_uuid: string) =>{
    uploadFile(blobFile, profile_uuid)
  } 

  
  return (
    <DragAndDropDialog
      title='Import Group of Contact List'
      open={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      sampleFile={csv.contact_list_upload_template}
      shouldSelectProfile={true} />
  )  
}

export default function CreateDropdown({openCreateDialog}:{openCreateDialog: () => void}){
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openFileDialog,setOpenFileDialog] = useState(false)
  const handleDialogClose = () =>{
    setOpenFileDialog(false)
  }
  return (
    <div>
      <UploadFileDialog open={openFileDialog} handleClose={handleDialogClose}/>
      <Button variant="contained" sx={{ backgroundColor: '#FECD20', borderRadius: '30px', px: '20px' }}    
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}>
        Add Contact
      </Button>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => openCreateDialog()} disableRipple>
          <AddCircleOutlineIcon />
          Add Individual
        </MenuItem>
        <MenuItem onClick={() => setOpenFileDialog(!openFileDialog)} disableRipple>
          <CloudUploadIcon />
          Import CSV
        </MenuItem>

      </StyledMenu>
    </div>
  );
}