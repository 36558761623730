import React from "react";
import { Box, ClickAwayListener, Paper, Tooltip, Typography } from "@mui/material";
import images from "../../../../assets/img";
import icons from "../../../../assets/icons";
import { SIGN_UP } from "../../../../lib/constants/routes";

export default function ReferralTabComponent({ referralCode, statistics }: { referralCode: string, statistics: any }) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const copyLink = () => {
    const signupUrl = process.env.REACT_APP_BASE_URL + SIGN_UP + '?referral_code=' + referralCode
    navigator.clipboard.writeText(signupUrl)
    setTooltipOpen(true);

    setTimeout(() => {
      handleTooltipClose()
    }, 1000);
  }

  return <>
    <Typography sx={{ fontSize: '31px', fontWeight: 400 }} gutterBottom>Referral</Typography>
    <Box sx={{ bgcolor: '#FCF6C3', borderRadius: '8px', p: '20px', mb: '20px' }}>
      <Typography sx={{ fontWeight: 500 }}>Refer Your Friends</Typography>
      <ul style={{
        fontSize: '13px',
        marginBlockStart: '0.5em',
        paddingInlineStart: '25px',
      }}>
        <li>Invite your friends to PixelCard</li>
        <li>Your friends will get discount if they use your code</li>
      </ul>
      <Typography sx={{ fontSize: '13px' }}>Send referral link to your friends via SMS/Email/WhatsApp</Typography>
    </Box>
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '30px',
      p: '30px',
      borderRadius: '16px',
      mb: '20px',
      bgcolor: 'white',
      backgroundImage: `url('${images.cloud}')`,
      animation: 'scrolling 20s linear infinite',
      '@keyframes scrolling': {
        'from': { backgroundPosition: '0 338px' },
        'to': { backgroundPosition: '968px 0' },
      },
      justifyContent: {
        xs: 'center',
        md: 'space-between',
      }
    }}>
      <Box sx={{ flex: 1, display: 'flex' }}>
        <img
          src={images.refer_friends}
          alt=""
          style={{
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '270px'
          }}
        />
      </Box>
      <Box sx={{
        mx: 'auto',
        bgcolor: 'rgba(255, 255, 255, 0.6)',
        p: '20px',
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
      }}>
        <Typography sx={{ color: '#136377', fontWeight: 700, fontSize: '25px' }}>Referral Program</Typography>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            placement='top'
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={tooltipOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Link Copied"
          >
            <Box
              sx={{
                border: '1px solid #C5C5C5',
                borderRadius: '7px',
                bgcolor: '#F7F7F7',
                p: '0.8rem 1rem',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
              onClick={() => copyLink()}
            >
              <Typography sx={{
                flex: 1,
                minWidth: 0,
                width: '160px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                fontWeight: '400'
              }}>
                {referralCode}
              </Typography>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
                color: '#FECD20',
                bgcolor: '#373736',
                borderRadius: '8px',
                p: '10px',
              }}>
                <div className='icon' style={{
                  backgroundColor: '#FECD20',
                  mask: `url(${icons.copy})`,
                  maskSize: '15px 15px',
                  maskRepeat: 'no-repeat',
                  WebkitMask: `url(${icons.copy})`,
                  WebkitMaskSize: '15px 15px',
                  WebkitMaskRepeat: 'no-repeat',
                  width: '15px',
                  height: '15px',
                }} />
                <Typography sx={{ fontWeight: '400' }}>
                  Copy link
                </Typography>
              </Box>
            </Box >
          </Tooltip>
        </ClickAwayListener>
        <Typography>Refer your friends and enjoy mutual benefits!</Typography>
      </Box>
    </Box>
    <Paper sx={{ p: '20px', borderRadius: '8px' }}>
      <Typography sx={{ fontWeight: 500 }} gutterBottom>Statistics</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Box sx={{ bgcolor: '#F9F9F9', borderRadius: '4px', p: '10px 20px', display: 'flex', justifyContent: 'space-between' }}>
          <Typography>Sign Up</Typography>
          <Typography>{statistics.signup_count}</Typography>
        </Box>
        <Box sx={{ bgcolor: '#F9F9F9', borderRadius: '4px', p: '10px 20px', display: 'flex', justifyContent: 'space-between' }}>
          <Typography>Subscription (Lifestyle)</Typography>
          <Typography>{statistics.subscription_count}</Typography>
        </Box>
        <Box sx={{ bgcolor: '#F9F9F9', borderRadius: '4px', p: '10px 20px', display: 'flex', justifyContent: 'space-between' }}>
          <Typography>Card Purchase</Typography>
          <Typography>{statistics.buy_card_count}</Typography>
        </Box>
      </Box>
    </Paper>
  </>
}