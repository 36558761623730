import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import icons from "../../../../../assets/icons";
import styled from "@emotion/styled";
import parse from 'html-react-parser';
import moment from "moment";
import images from "../../../../../assets/img";
import VoucherSlider, { PixelThumbComponent } from "../../../../../components/voucher-slider";

const TitleContainer = styled(Box)({
  display: "flex",
  padding: "1rem",
  ".title": {
    color: '#373736',
    fontSize: 22,
    fontWeight: 700
  },
})

const DescriptionContainer = styled(Box)({
  padding: "1rem",
  ".contain": {
    paddingBottom: "1rem",
  },
  ".description": {
    color: '#373736',
    fontSize: 14,
    fontWeight: 400
  },
  ".title": {
    color: '#373736',
    fontSize: 16,
    fontWeight: 600
  }
})

interface Voucher {
  banner_image: string;
  brand_name: string;
  description: string;
  start_date_time: string;
  end_date_time: string;
  terms_and_condition: string;
  voucher_title: string;
}

interface VoucherDetailsComponentProps {
  voucher: Voucher;
  handleBack: () => void;
  handleCollect: () => void;
}

export default function VoucherDetailsComponent({ voucher, handleBack, handleCollect }: VoucherDetailsComponentProps) {
  const [sliderValue, setSliderValue] = useState(0);

  return <Box>
    <Box sx={{ display: "flex", padding: "rem", justifyContent: 'space-between', p: '20px 10px' }}>
      <IconButton onClick={handleBack}>
        <img src={icons.back_icon} alt="back" />
      </IconButton>
      <Typography variant="h4" sx={{ fontSize: 24, fontWeight: 500, color: '#373736' }}>Promotions</Typography>
      <div style={{ width: 36 }}></div>
    </Box>
    <img
      alt='Voucher banner'
      src={voucher.banner_image ?? images.landing_page_default_image}
      style={{ width: '100%' }}
    />
    <TitleContainer>
      <Typography className="title">
        {voucher.voucher_title}
      </Typography>
    </TitleContainer>
    <Box sx={{ mx: '45px', height: '88px', position: 'relative' }}>
      <Box sx={{
        width: 'calc(100% + 58px)',
        height: 'calc(100% - 30px)',
        position: 'absolute',
        left: '-29px',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        my: '15px',
        bgcolor: '#FECD20',
      }}>
        <Typography sx={{ fontSize: 16, fontWeight: 400 }}>Slide to Collect</Typography>
      </Box>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <VoucherSlider
          slots={{ thumb: PixelThumbComponent }}
          value={sliderValue}
          onChange={(e, val) => setSliderValue(Number(val.toString()))}
          onChangeCommitted={(e, value) => {
            if (value === 100) {
              handleCollect();
            } else {
              setSliderValue(0);
            }
          }}
        />
      </Box>
    </Box>
    <DescriptionContainer sx={{ padding: "1rem" }}>
      <Box className="contain">
        <Typography sx={{ fontSize: 14, fontWeight: 400, color: '#939393' }}>Validity Period</Typography>
        <Typography className="title">{moment(`${voucher.start_date_time}`, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY, h:mm A')} - {moment(`${voucher.end_date_time}`, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY, h:mm A')}</Typography>
      </Box>
      <Box className="contain">
        <Typography className="description">
          {voucher.description}
        </Typography>
      </Box>
      <Box className="contain">
        <Typography className="title">
          Terms & Conditions
        </Typography>
        <Typography className="description">
          <>
            {parse(voucher.terms_and_condition)}
          </>
        </Typography>
      </Box>
    </DescriptionContainer>
  </Box>
}