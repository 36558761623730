import React, { useEffect, useState } from 'react';
import ZakekeComponent from './zakeke.component';
import { useLocation } from 'react-router-dom';
import basicZakeke from '../../api/basicZakeke';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ZakekeContainer() {
  const query = useQuery();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  const user_uuid = user.access_token_payload.uuid;

  useEffect(() => {
    const productId:any = query.get("productid");
    const attrId:any = query.get('attrid');
    const loadZakekeScripts = (tokenoauth: string) => {
      const config = {
        tokenoauth,
        culture: "en-US",
        currency: "USD",
        quantity: Number(query.get("quantity")),
        productinfourl: `${process.env.REACT_APP_API_HOST_URL}/basiczakeke/productInfo?user_uuid=${user_uuid}`,
        selectedattributes: {
          [productId]: attrId
        },
        designid: query.get("designid"),
        loadtemplateid: "",
        isclientpreviewsenabled: true,
        shoppingcarturl: `${process.env.REACT_APP_API_HOST_URL}/basiczakeke/addToCart?user_uuid=${user_uuid}`,
        canSaveDesign: false,
        editshoppingcarturl: `${process.env.REACT_APP_API_HOST_URL}/basiczakeke/editCart?user_uuid=${user_uuid}`,
      };
      const product = {
        id: query.get("productid"),
        name: query.get("productname"),
      };

      // Add the provided JavaScript references dynamically
      const configScript: HTMLScriptElement = document.createElement('script');
      configScript.src = 'https://portal.zakeke.com/scripts/config.js';
      document.body.appendChild(configScript);

      const customizerScript: HTMLScriptElement = document.createElement('script');
      customizerScript.src = 'https://portal.zakeke.com/scripts/integration/api/customizer.js';

      configScript.onload = () => {
        document.body.appendChild(customizerScript);
      };

      customizerScript.onload = () => {
        // Add the following JavaScript block to start the customizer
        const customizerBlock: HTMLScriptElement = document.createElement('script');
        customizerBlock.innerHTML = `customizer = new zakekeDesigner(${JSON.stringify(config)}, ${JSON.stringify(product)});`;
        document.body.appendChild(customizerBlock);
        setIframeLoaded(!iframeLoaded);
      };

      customizerScript.onerror = (error) => {
        console.error('Error loading customizerScript:', error);
      };
    };

    basicZakeke.getAuthKey()
      .then(response => loadZakekeScripts(response.access_token))
      .catch(console.log);

    return () => {
      customizer.removeIframe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  return <ZakekeComponent iframeLoaded={iframeLoaded} />
}