import axios from "axios";

interface Data {
  code: number;
  data: null;
  message: string | null;
  success: boolean;
}

const fail = (orderUUID: string): Promise<Data> => { 
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  const params = new URLSearchParams([['order_uuid', orderUUID]]);
    return new Promise((resolve, reject) => {
        axios.get("order/fail",{params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default fail;
