import axios from "axios";

const baseUrl =  process.env.REACT_APP_API_HOST_URL+'/contact';
const serverBaseURL = process.env.REACT_APP_SERVER_HOST_URL;
// TODO: Use axios
const getList = (search = '', order_by = 'date', order = 'desc', type = '', job_title = '') => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  return fetch(`${baseUrl}/getLists`, {
    method: 'POST',
    headers: { "Authorization": `Bearer ${user.access_token}` },
    body: JSON.stringify({
      search,
      order_by,
      order,
      filter: {
        type,
        job_title,
      }
    }),
  })
  .then(res => res.json())
  .then(data => data.data);
}

const getGuest = (guestUuid: string) => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  return fetch(`${baseUrl}/getGuestDetails?guest_uuid=${guestUuid}`, {
    headers: { "Authorization": `Bearer ${user.access_token}` },
  })
  .then(res => res.json())
  .then(data => data.data);
}

const getDetails = (contactUuid: string) => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  return fetch(`${baseUrl}/details?contact_uuid=${contactUuid}`, {
    headers: { "Authorization": `Bearer ${user.access_token}` },
  })
  .then(res => res.json())
  .then(data => data.data);
}

const getEmailAndCall = (type: string, contactUuid: string) => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  return fetch(`${baseUrl}/getEmailAndCall?contact_uuid=${contactUuid}&type=${type}`, {
    headers: { "Authorization": `Bearer ${user.access_token}` },
  })
  .then(res => res.json())
  .then(data => data.data);
}

const getSocialLink = (contactUuid: string) => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  return new Promise((resolve, reject) => {
    axios.get("/getSocialLink", {
      params: new URLSearchParams([['contact_uuid', contactUuid]]),
      baseURL: baseUrl,
      headers: { "Authorization": `Bearer ${user.access_token}` },
    })
    .then(response => resolve(response.data.data))
    .catch(error => reject(error.response.data.message));
  })
}

const getUrl = async (fullPath: string) => {
  return await fetch(serverBaseURL+'/get-presigned-url', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ fileName: fullPath }),
  })
  .then(res => res.json())
  .then(data => data.url);
}

const deleteContact = (contact_uuid: string) => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  return fetch(`${baseUrl}/delete`, {
    method: "POST",
    headers: { "Authorization": `Bearer ${user.access_token}` },
    body: JSON.stringify({ contact_uuid }),
  })
  .then(res => res.json());
}

const exportContactList = (search='', order_by = 'date', order = 'desc', type = '', job_title = '') => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  var user_uuid = user.access_token_payload.uuid;
  var endppoint = process.env.REACT_APP_API_HOST_URL+'/export';
  return openWindowWithPost(`${endppoint}/contactList`, 
    JSON.stringify({
      user_uuid: user_uuid,
      search,
      order_by,
      order,
      filter: {
        type,
        job_title,
      }
    })
  );
}

function openWindowWithPost(url, data) {
  var form = document.createElement("form");
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.style.display = "none";

  var input = document.createElement("input");
  input.type = "hidden";
  input.name = 'data';
  input.value = data;
  form.appendChild(input);

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}


const contactList = { getList, getGuest, getUrl, deleteContact, exportContactList, getDetails, getEmailAndCall, getSocialLink };

export default contactList;