import React from "react";
import { Box,Button, Dialog, DialogContent, DialogTitle, Link,Typography } from "@mui/material";
import contactListApi from "../../../api/contactList/contactList.api";
import icons from "../../../../src/assets/icons"

interface Number{
  mobile: string[] | [],
  telephone: string[] | []
}

function CallDialog({ open, handleClose, uuid }) {
  const [numbers, setNumbers] = React.useState<Number>({
    mobile : [],
    telephone: []
  });
  React.useEffect(() => {
    if (uuid === '' || open === false) return;
    
    contactListApi.getEmailAndCall('call', uuid)
      .then(data => {
        setNumbers(data);
      })
  }, [uuid,open]);

  const onClose = () =>{
    setNumbers({mobile:[], telephone:[]}) //force clear data
    handleClose()
  }

  return <Dialog open={open} onClose={onClose} fullWidth 
    PaperProps={{ 
      sx: { borderRadius: '25px', maxWidth: '850px',padding: '2rem 1rem',alignItems:'center' } }}>
    <DialogTitle sx={{width:'100%'}}>
        <Box sx={{
      display: 'flex',
      justifyContent: 'space-between'}}>
        Call Number
      <img src={icons.close} alt="close-icon" onClick={() => onClose()} style={{width: '18px',height: '18px'}}/>
        </Box>
    </DialogTitle>
    <DialogContent 
    sx={{
      maxWidth:'700px',
      maxHeight: '350px'
    }}>
    
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      border:'1px solid var(--Status-Default, #C5C5C5)',
      borderRadius: '4px',
      padding:'12px 16px'}}>
      {
        numbers?.mobile &&
        numbers?.mobile.map((number,index)=>{
          return <div key={number} style={{
            display: 'grid',
            gridTemplateColumns: '1fr 4fr 1fr',
            background: '#FAFAFA',
            padding: '10px',
            borderRadius: '8px',
            alignItems:'center'
          }}>
            <img src={icons.phone_multiplecontact_icon} alt="phone-icon"/>
            <Box>
            <Typography sx={{fontSize:'20px',fontWeight:"500"}}>Phone Number</Typography>
            <Typography sx={{ontSize: '16px',fontWeight:'400'}}>{number}</Typography> 
            </Box>
            <Link href={`tel:${number}`}>              
              <Button variant="contained" color="secondary" sx={{ padding: '5px 35px',borderRadius: '30px',}}>Call</Button>
            </Link>
          </div>
        })
      }
      {
        numbers?.telephone &&
        numbers?.telephone.map((number,index)=>{
          return <div key={number} style={{
            display: 'grid',
            gridTemplateColumns: '1fr 4fr 1fr',
            background: '#FAFAFA',
            padding: '10px',
            borderRadius: '8px',
            alignItems:'center'
          }}>
            <img src={icons.telephone_multiplecontact_icon} alt="telephone-icon"/>
            <Box>
            <Typography sx={{fontSize:'20px',fontWeight:"500"}}>Telephone</Typography>
            <Typography sx={{ontSize: '16px',fontWeight:'400'}}>{number}</Typography>
            </Box> 
            <Link href={`tel:${number}`}>
              <Button variant="contained" color="secondary" sx={{ padding: '5px 35px',borderRadius: '30px',}}>Call</Button>
              </Link>
          </div>
        })
      }
      </Box>
    </DialogContent>
  </Dialog>
};

export default CallDialog;