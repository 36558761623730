import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setBreadcrumb } from '../../features/admin';
import { endLoading, startLoading } from '../../features/loading';
import { PORTAL, PORTAL_APPOINTMENT } from "../../lib/constants/routes";
import AppointmentComponent from "./appointment.component";
import appointmentApi from "../../api/appointment";
import profileApi from '../../api/profile';
import randomColor from "randomcolor";
import { setCalView, setListView, setShouldRefresh } from "../../features/appointments/appointments.slice";
import FileHelper from "../../lib/helper/fileHelper";
import auth from "../../api/auth";
import { setAccessLimit } from "../../features/access-limit";
import moment from "moment";
import { apiCalendar, getToken, linkGoogleCalendar } from "./apiCalendar";
import { openResponseDialog } from "../../features/response-dialog";
import { openActionDialog } from "../../features/action-dialog";
import { useNavigate } from "react-router-dom";

interface Appointment {
  appointment_uuid: string;
  date: string;
  name: string;
  profile_picture: string;
  profile_uuid: string;
  status?: string;
  time: string;
  type: string; // requester | respondent
}

interface Profile {
  uuid: string;
  calendar_id: string | null;
  name: string;
  color: string;
  image: string | null;
}

const getDateRange = (year: number, month: number) => {
  const firstDay = new Date(year, month, 1).getDay();
  const startTileDate = moment(new Date(year, month, 1 - firstDay));

  const lastDay = new Date(year, month + 1, 0).getDay();
  const endTileDate = moment(new Date(year, month + 1, 6 - lastDay));

  return {
    from: startTileDate.format('YYYY-MM-DD'),
    to: endTileDate.format('YYYY-MM-DD'),
  };
}

export default function AppointmentContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const calViewAppts: any = useSelector<any>(state => state.appointments.calView);
  const nav: any = useSelector<any>(state => state.nav);
  const listViewAppts: any = useSelector<any>(state => state.appointments.listView);
  const shouldRefresh: any = useSelector<any>(state => state.appointments.shouldRefresh);
  const isConfirmed = useSelector<any>(state => state.actionDialog.isConfirmed);
  const action = useSelector<any>(state => state.actionDialog.action);
  const [viewMode, setViewMode] = useState('calendar');
  const [status, setStatus] = useState('pending');
  const [historyStatus, setHistoryStatus] = useState('');
  const [historyType, setHistoryType] = useState('');
  const [historyOrder, setHistoryOrder] = useState('desc');
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [history, setHistory] = useState<Appointment[]>([]);
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [selectedProfileUUID, setSelectedProfileUUID] = useState('');
  const [hasAccess, setHasAccess] = useState(true);
  const [hasLinked, setHasLinked] = useState(localStorage.getItem('isUsingGcal') === '1');

  const getEvents = (calendarId: string) => {
    const queryOptions = {
      calendarId,
      'timeMin': (new Date()).toISOString(),
      'showDeleted': false,
      'singleEvents': true,
      'maxResults': 100,
      'orderBy': 'startTime',
    };

    return apiCalendar.listEvents(queryOptions)
      .then(({ result }) => result.items)
      .catch(({ result }) => result.error.message);
  }

  const handleSyncEvents = async (profiles: any[]) => {
    // Check Google token
    if (getToken() === null) {
      const link = await linkGoogleCalendar()
        .catch(error => {
          console.error('Error when linking Google Calendar:', error);
          return null;
        });

      if (link === null) return;
    }

    // Get all gcal events from across all profiles
    Promise.all(profiles.map(async (prf) => {
      if (!Boolean(prf.calendar_id)) return Promise.resolve([]);
      const events = await getEvents(`${prf.calendar_id}`);

      // If error happens, events will contain the error message
      if (typeof events === 'string') {
        console.log(events);
        return [];
      }

      return events;
    })).then((results) => {
      let linkedAppointmentUUIDs: string[] = [];

      // Check for differences between the events and linked appointments
      results.forEach(result => {
        result.forEach(item => {
          let changes = {};
          const itemStartDateTime = moment(item.start.dateTime);
          const itemStartDate = itemStartDateTime.format('YYYY-MM-DD');
          const itemStartTime = itemStartDateTime.format('HH:mm:ss');
          const linkedAppointment = calViewAppts.find(appt => appt.gcal_event_id === item.id);

          if (Boolean(linkedAppointment)) {
            linkedAppointmentUUIDs.push(linkedAppointment.appointment_uuid);
          }

          if (Boolean(linkedAppointment)) {
            if (linkedAppointment.date !== itemStartDate) {
              changes['date'] = itemStartDate;
            }

            if (linkedAppointment.time !== itemStartTime) {
              changes['time'] = itemStartTime;
            }
          }

          // If there are changes, reschedule
          if (Object.keys(changes).length > 0) {
            const body = {
              send_email: false,
              appointment_uuid: linkedAppointment.appointment_uuid,
              date: changes['date'] ?? linkedAppointment.date,
              time: changes['time'] ?? linkedAppointment.time,
              remark: '',
            };
            appointmentApi.reschedule(body)
              .then(() => dispatch(setShouldRefresh(true)));
          }
        });
      });

      // Decline appointments that their event ID is not found in the result
      calViewAppts.filter(appt => !linkedAppointmentUUIDs.includes(appt.appointment_uuid)).forEach(missingAppt => {
        if (missingAppt.status !== 'pending' && missingAppt.gcal_event_id !== null) {
          appointmentApi.decline(missingAppt?.appointment_uuid)
            .then(() => dispatch(setShouldRefresh(true)));
        }
      })
    }).catch(e => console.error('Error when syncing:', e));
  }

  const handleLinkGcal = () => {
    linkGoogleCalendar().then(() => {
      localStorage.setItem('isUsingGcal', '1');
      setHasLinked(true);
      dispatch(openResponseDialog({
        title: "Google Calendar Linked",
        description: "Future appointments that are approved will be synced with Google Calendar.",
      }));
    }).catch(console.error);
  }

  const handleUnlinkGcal = () => {
    dispatch(openActionDialog({
      title: "Unlink Google Calendar?",
      description: "Appointments will not be synced with Google Calendar.",
      action: 'unlinkGcal',
    }));
  }

  useEffect(() => {
    if (isConfirmed && action === 'unlinkGcal') {
      apiCalendar.handleSignoutClick();
      localStorage.setItem('isUsingGcal', '0');
      localStorage.removeItem('gcal_auth');
      setHasLinked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed, action])

  useEffect(() => {
    auth.getAccessLimit().then(response => {
      dispatch(setAccessLimit(response.data));
      const accessRights = response.data.access_right;
      //TODO: only check if the user has access to the appointment add seems insufficient
      setHasAccess(accessRights.includes("appointment add"));
    }).catch(() => setHasAccess(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Get profiles and assign color to each
  const getProfiles = () => {
    return profileApi.getList()
      .then((response: any) => {
        const colors = [
          '#E844EC',
          '#5BEBF4',
          '#F2EA35',
        ];

        return Promise.all((response.data ?? []).map(async (prf, i) => {
          let presigned_url = null;
          if (Boolean(prf.profile_picture)) presigned_url = await FileHelper.getUrl(prf.profile_picture);

          return {
            ...prf,
            uuid: prf.profile_uuid,
            name: prf.profile_name,
            color: i < colors.length ? colors[i] : randomColor(),
            image: presigned_url,
          };
        })).then(profs => {
          setProfiles(profs);
          return profs;
        });
      });
  }

  const dispatchSetListView = appointments => {
    Promise.all(appointments.map(async (appt) => {
      let profile_picture = null;
      if (Boolean(appt.profile_picture)) profile_picture = await FileHelper.getUrl(appt.profile_picture);

      return ({
        ...appt,
        profile_picture,
      })
    })).then(appts => dispatch(setListView(appts)));
  }

  const getAppointmentsListView = ({ status, profile_uuid = '' }) => {
    dispatch(startLoading());
    return appointmentApi.getListView({
      status,
      profile_uuid,
    }).then(response => {
      if (status === 'approved') {
        dispatchSetListView([
          ...response.data.data.today,
          ...response.data.data.upcoming,
        ]);
      } else if (status === 'pending') {
        dispatchSetListView(response.data.data.pending);
      }
    }).finally(() => dispatch(endLoading()));
  }

  const getAppointmentsCalView = ({ from, to, profile_uuid = '' }) => {
    dispatch(startLoading());
    return appointmentApi.getInRange({ from, to, profile_uuid, }).then(response => {
      return Promise.all(response.data.map(async (appt) => {
        let profile_picture = null;
        if (Boolean(appt.profile_picture)) profile_picture = await FileHelper.getUrl(appt.profile_picture);

        return ({
          ...appt,
          profile_picture,
        })
      })).then(appts => dispatch(setCalView(appts)));
    }).finally(() => dispatch(endLoading()));
  }

  const getAppointmentsHistory = ({
    status = '',
    profile_uuid = '',
    type = '',
    order = 'desc'
  }) => {
    dispatch(startLoading());
    appointmentApi.getHistory({
      status,
      profile_uuid,
      type,
      order,
    }).then(response => {
      setHistory(response.data.data);
      Promise.all((response.data.data ?? []).map(async (appt, i) => {
        let profile_picture = null;
        if (Boolean(appt.profile_picture)) profile_picture = await FileHelper.getUrl(appt.profile_picture);

        return {
          ...appt,
          profile_picture,
        };
      })).then(setHistory);
    }).finally(
      () => dispatch(endLoading())
    );
  }

  useEffect(() => {
    // Workaround to prevent the browser from blocking the popup
    if (!Boolean(nav?.toAppt)) {
      navigate(PORTAL);
    } else {
      getAppointmentsCalView({ ...getDateRange(year, month), profile_uuid: selectedProfileUUID })
        .then(() => getAppointmentsListView({ status, profile_uuid: selectedProfileUUID }))
        .then(() => getProfiles())
        .then(profs => {
          if (Boolean(Number(localStorage.getItem('isUsingGcal')))) {
            handleSyncEvents(profs);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year, status, selectedProfileUUID]);

  useEffect(() => {
    if (shouldRefresh) {
      getAppointmentsCalView({ ...getDateRange(year, month), profile_uuid: selectedProfileUUID });
      getAppointmentsListView({ status, profile_uuid: selectedProfileUUID });
      getAppointmentsHistory({
        status: historyStatus,
        type: historyType,
        order: historyOrder,
        profile_uuid: selectedProfileUUID,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh])

  useEffect(() => {
    getAppointmentsHistory({
      status: historyStatus,
      type: historyType,
      order: historyOrder,
      profile_uuid: selectedProfileUUID,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyStatus, historyType, historyOrder, selectedProfileUUID]);

  const changeMonth = (plusminus: string) => {
    let mth = month;
    let yr = year;

    if (plusminus === '+') {
      mth = (mth + 1) % 12;

      if (month >= 11) {
        yr += 1;
      }
    } else if (plusminus === '-') {
      if (month > 0) {
        mth -= 1;
      } else {
        mth = 11;
        yr -= 1;
      }
    }

    setMonth(mth);
    setYear(yr);
  }

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "Appointment", path: PORTAL_APPOINTMENT },
        { name: "Appointment List", path: null }
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppointmentComponent
    profiles={profiles}
    setSelectedProfileUUID={setSelectedProfileUUID}
    month={month}
    year={year}
    changeMonth={changeMonth}
    viewMode={viewMode}
    setViewMode={setViewMode}
    appointments={calViewAppts}
    history={history}
    listViewAppointments={listViewAppts}
    status={status}
    setStatus={(stat: string) => {
      getAppointmentsListView({ status: stat })
      setStatus(stat);
    }}
    historyStatus={historyStatus}
    setHistoryStatus={setHistoryStatus}
    historyType={historyType}
    setHistoryType={setHistoryType}
    historyOrder={historyOrder}
    setHistoryOrder={setHistoryOrder}
    hasAccess={hasAccess}
    hasLinked={hasLinked}
    handleLinkGcal={handleLinkGcal}
    handleUnlinkGcal={handleUnlinkGcal}
  />
}