import subscribeFreeTrial from "./subscribeFreeTrial.api";
import subscribeLifestyle from "./subscribeLifestyle.api";
import changePlan from "./changePlan.api";
import businessContact from "./businessContact.api";
import downgradeToFree from "./downgradeToFree.api";
import downgradeImmediately from "./downgradeImmediately.api";
import setAutoRenew from "./setAutoRenew.api";
import keepProfile from "./keepProfile.api";

const subscriptionApi = {
  subscribeFreeTrial,
  subscribeLifestyle,
  changePlan,
  businessContact,
  downgradeToFree,
  downgradeImmediately,
  setAutoRenew,
  keepProfile,
}

export default subscriptionApi