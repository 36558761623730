import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const checkSignType = (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;
    return new Promise((resolve, reject) => {
        axios.get("user/checkSignInType")
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default checkSignType;
