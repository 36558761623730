import React, { useState} from 'react';
import { Box,Typography,Button,Menu, MenuItem,
 Table, TableBody, TableCell, TableContainer, TableHead, TableRow,TablePagination, 
 Paper, Select, TextField,RadioGroup, FormControlLabel,Radio} from "@mui/material";
import FormBox from '../../../component/formBox';
import { SINGLE, MULTIPLE } from '../../../../../lib/constants/voucherCodeType';
import { VoucherSettings } from '../../../../../lib/types/Voucher';
import DragAndDropDialog from '../../../../../components/dialog/drap-and-drop-dialog';
import CustomSwitch from '../../../../../components/general/customSwitch';
import Filter from '../../../../../components/filter';
import icons from '../../../../../assets/icons';
import {VoucherCodeStatistic} from '../../../../../lib/types/Voucher';
import { SELF_VALIDATION,NO_VALIDATION } from '../../../../../lib/constants/voucherValidationType';
import MultipleDialog from '../component/multipleDialog';
import SingleDialog from '../component/singleDialog';
import { useTheme } from '@mui/material/styles';
import DialogFrame from '../../../../../components/dialog-frame/dialogFrame';
import ProfileDialog from '../component/profileDialog';
import csv from '../../../../../assets/csv';
export default function VoucherCodeCreate(
  {
    page,
    accessRights,
    rowsPerPage,
    voucher,
    voucherCodes,
    recordsTotal,
    voucherCodeStatistic,
    breakpointMd,
    addVoucherCode,
    generateCode,
    handleSort,
    handleStatus,
    handleImport,
    handleChangePage,
    handleChangePageMobile,
    handleChangeRowsPerPage,
    handleSearch,
    handleFilterBy,
    handleRefresh,
    selectedSort
  }:
  { 
    page: number,
    accessRights: string[],
    rowsPerPage: number,
    voucher: VoucherSettings,
    voucherCodes: Array<any>,
    recordsTotal: number,
    voucherCodeStatistic: VoucherCodeStatistic,
    breakpointMd: boolean,
    addVoucherCode: ({code}) => void,
    generateCode: ({code,quantity}) => void,
    handleSort: ({field,order}:{field:string,order:string,name:string }) => void,
    handleStatus: ({voucherCodeUUID, status}:{voucherCodeUUID: string, status: boolean}) => void,
    handleImport: (value: any) => void,
    handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void,
    handleChangePageMobile: (targetPage:number) => void,
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleSearch: (search:string|null) => void,
    handleFilterBy: ({redemptionFrom,redemptionTo,collectedStatus,redemptionStatus,codeActivation,option}:{
      redemptionFrom:string,
      redemptionTo:string,
      collectedStatus:number,
      redemptionStatus:number,
      codeActivation:number,
      option:string
    }) => void,
    handleRefresh: () => void,
    selectedSort:string
  },
    ) {
  const theme = useTheme();
  const [openMultiDialog, setOpenMultiDialog] = React.useState(false);
  const [openSingleDialog, setOpenSingleDialog] = React.useState(false);
  const [openUploadDialog,setOpenUploadDialog] = React.useState(false)
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  const [createAnchorEl, setCreateAnchorEl] = React.useState<null | HTMLElement>(null);
  const [filterOption, setFilterOption] = useState('collected_date')
  const [redemptionFrom, setRedemptionFrom] = useState('')
  const [redemptionTo, setRedemptionTo] = useState('')
  const [collectedStatus, setCollectedStatus] = useState(0)
  const [redemptionStatus, setRedemptionStatus] = useState(0)
  const [codeActivation, setCodeActivation] = useState(0)
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [profileUUID, setProfileUUID] = useState('' as string);
  const [sortList] = useState([
    {
      name: "Sort by ID (Asc)",
      order: "asc",
      field: "code"
    },
    {
      name: "Sort by ID (Desc)",
      order: "desc",
      field: "code"
    },
    {
      name: "Sort by Status (Asc)",
      order: "asc",
      field: "is_collect"
    },
    {
      name: "Sort by Status (Desc)",
      order: "desc",
      field: "is_collect"
    },
    {
      name: "Sort by Date (Asc)",
      order: "asc",
      field: "collected_at"
    },
    {
      name: "Sort by Date (Desc)",
      order: "desc",
      field: "collected_at"
    },
    
  ])

  const handleFilterOption = () =>{
    setOpenFilterDialog(!openFilterDialog)
  }

  const handleFilterOptionChange = (value) =>{
    setFilterOption(value)
  }

  const handleFilterSubmit = () => {  
    handleFilterBy({
      redemptionFrom: redemptionFrom,
      redemptionTo: redemptionTo,
      collectedStatus: collectedStatus,
      redemptionStatus: redemptionStatus,
      codeActivation: codeActivation,
      option: filterOption
    })
    setOpenFilterDialog(!openFilterDialog)
  }

  const handleProfileDialog = (profileUUID:string) => {
    setProfileUUID(profileUUID)
    setOpenProfileDialog(!openProfileDialog)
  }

  return <>
    <ProfileDialog 
      open={openProfileDialog} 
      onClose={() => {
        setOpenProfileDialog(!openProfileDialog)
        setProfileUUID('')
      }}
      profileUUID={profileUUID}
    />
    <DialogFrame
      open={openFilterDialog}
      handleSave={() => handleFilterSubmit()}
      handleCancel={() => setOpenFilterDialog(!openFilterDialog)} 
      handleClose={() => setOpenFilterDialog(!openFilterDialog)}>
        <Box sx={{
          display:'flex',
          flexDirection:'column',
          gap:'1rem',
        }}>
          <Box>
            <Typography>Filter by</Typography>
            <Select fullWidth size="small" value={filterOption} onChange={(e) => handleFilterOptionChange(e.target.value)}>
              <MenuItem key='redemption_date' value='redemption_date'>Redemption Date</MenuItem>
              <MenuItem key=' collected_status' value='collected_status'>Collected Status</MenuItem>
              <MenuItem key=' redemption_status' value='redemption_status'>Redemption Status</MenuItem>
              <MenuItem key='code_activation' value='code_activation'>Code Activation</MenuItem>
            </Select>
          </Box>
          {
            filterOption === 'redemption_date' &&
            <>
            <Box>
              <Typography>From</Typography>
              <TextField
                id="date"
                fullWidth
                size="small"
                type="date"
                value={redemptionFrom}
                onChange={(e) => setRedemptionFrom(e.target.value)}
              />
            </Box>
            <Box>
              <Typography>To</Typography>
              <TextField
                id="date"
                fullWidth
                size="small"
                type="date"
                value={redemptionTo}
                onChange={(e) => setRedemptionTo(e.target.value)}
              />
            </Box>
            </>
          }
          {
            filterOption === 'collected_status' &&
            <Box>
                <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="true"
                name="collected_status"
                value={(collectedStatus === 1) ? true : false}
                onChange={(e) => {
                  setCollectedStatus((e.target.value === 'true') ? 1 : 0);
                }}
              >
                <FormControlLabel value="true" control={<Radio />} label="Collected" />
                <FormControlLabel value="false" control={<Radio />} label="Pending" />
              </RadioGroup>
            </Box>
          }
          {
            filterOption === 'redemption_status' &&
            <Box>
                <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="true"
                name="collected_status"
                value={(redemptionStatus === 1) ? true : false}
                onChange={(e) => setRedemptionStatus((e.target.value === 'true') ? 1 : 0)}
              >
                <FormControlLabel value="true" control={<Radio />} label="Redeemed" />
                <FormControlLabel value="false" control={<Radio />} label="Pending" />
              </RadioGroup>
            </Box>
          }
          {
            filterOption === 'code_activation' &&
            <Box>
                <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="true"
                name="code_activation"
                value={(codeActivation === 1) ? true : false}
                onChange={(e) => setCodeActivation((e.target.value === 'true') ? 1 : 0)}
              >
                <FormControlLabel value="true" control={<Radio />} label="Active" />
                <FormControlLabel value="false" control={<Radio />} label="Inactive" />
              </RadioGroup>
            </Box>
          }
        </Box>
    </DialogFrame>
    <MultipleDialog 
      open={openMultiDialog} 
      handleClose={() => setOpenMultiDialog(!openMultiDialog)} 
      handleSubmit={({code,quantity}) => {
        setOpenMultiDialog(!openMultiDialog)
        generateCode({code:code,quantity:quantity})
      }} 
    />
    <SingleDialog
      open={openSingleDialog}
      handleClose={() => setOpenSingleDialog(!openSingleDialog)}
      handleSubmit={({code}) => {
        setOpenSingleDialog(!openSingleDialog)
        addVoucherCode({code:code})
      }}
    />
    <DragAndDropDialog
      open={openUploadDialog}
      handleClose={() => setOpenUploadDialog(!openUploadDialog)}
      handleSubmit={(value) => {
        setOpenUploadDialog(!openUploadDialog)
        handleImport(value)
      }}
      sampleFile={csv.voucher_code_upload_template}
      sampleFileName={'voucher_code_upload_template.csv'}
      title="Import list of voucher code"
      shouldSelectProfile={false} />
    <Box sx={{
      width:'inherit'
    }}>
    <Box sx={{
      [theme.breakpoints.down('sm')]: {
        display: "flex",
        flexDirection: "column"
      },  
      [theme.breakpoints.up('sm')]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"

      },
      pb: '1rem',
      borderBottom: "1px solid black",
    }}>
      <Typography variant="h2"  fontWeight="500"> Code Create</Typography>
      {/* Create Code START */}
      {
        (voucher.code_type === SINGLE) &&
        <Box>
        <Button variant='contained' disabled={!accessRights.includes('campaign voucher code add')} onClick={() => setOpenSingleDialog(!openSingleDialog)}> Create Code</Button> 
        </Box>
      }
      { (voucher.code_type === MULTIPLE && voucher.validation_type === NO_VALIDATION ) &&
          <Box>
          <Button 
          variant="contained" 
          onClick={(e) => setCreateAnchorEl(e.currentTarget)}
          endIcon={<img src={icons.arrow_down_01_icon} alt="upload"/>}
          disabled={!accessRights.includes('campaign voucher code add')}
        >
        Create Code
        </Button>
        <Menu
          anchorEl={createAnchorEl}
          open={Boolean(createAnchorEl)}
          onClose={() => setCreateAnchorEl(null)}
        >
          <MenuItem onClick={() => {
            setCreateAnchorEl(null)
            setOpenMultiDialog(!openMultiDialog)
          }} 
          sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
          }}>
            Generate<br/>
            <Typography sx={{color:'grey',fontSize:'13px'}}>System help to generate code</Typography>
          </MenuItem >
          <MenuItem onClick={() => {
            setCreateAnchorEl(null)
            setOpenUploadDialog(!openUploadDialog)}
          }
          sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
          }}>
            Import
            <Typography  sx={{color:'grey',fontSize:'13px'}}> System help to generate code </Typography>
          </MenuItem>
          <MenuItem onClick={() => {
            setCreateAnchorEl(null)
            setOpenSingleDialog(!openSingleDialog)
          }} sx={{
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
          }}>
            Manual
            <Typography  sx={{color:'grey',fontSize:'13px'}}> Manual key in your code</Typography>
          </MenuItem>
        </Menu>
          </Box>
      }
      {
        (voucher.code_type === MULTIPLE && voucher.validation_type === SELF_VALIDATION ) &&
        <Box>
          <Button variant='contained' disabled={!accessRights.includes('campaign voucher code add')} onClick={() => setOpenMultiDialog(!openMultiDialog)}> Create Code</Button> 
        </Box>
      }
      {/* Create Code END */}
    </Box>
    <Box sx={{pt:'1rem',width:'inherit'}}>
    <Box sx={{
      [theme.breakpoints.down('sm')]: {
        display:'flex', 
        flexDirection:'column'
      },
      [theme.breakpoints.up('sm')]: {
        display:'grid', 
        gridTemplateColumns:'1fr 1fr 1fr',
        gap:'1rem'
      }

      }}>
        <FormBox>
          <Box  sx={{display:'flex',gap:'1rem'}}>
            <Box>
                <img src={icons.folders} alt="folder_icon"></img>
            </Box>
            <Box > 
              <Typography variant="h3" fontWeight="500" fontSize="16px">Total Collected</Typography>
              <Box sx={{display:'flex',alignItems:'flex-end'}}>
                <Typography fontWeight='500' sx={{fontSize:'31px'}}> {voucherCodeStatistic?.total_collected}</Typography>
                { voucher.code_type === MULTIPLE && <Typography sx={{fontSize:'16px'}}> / {voucherCodeStatistic?.total_activation}</Typography>}
              </Box> 
            </Box>
          </Box>
        </FormBox>
        <FormBox>
        <Box sx={{
          display:'flex',
          gap:'1rem'
        }}>
            <Box>
                <img src={icons.folders}  alt="folder_icon"></img>
            </Box>
            <Box > 
              <Typography variant="h3" fontWeight="500" fontSize="16px">Total Redemption</Typography>
              <Box sx={{display:'flex',alignItems:'flex-end'}}>
                <Typography fontWeight='500' sx={{fontSize:'31px'}}> {voucherCodeStatistic?.total_redemption}</Typography>
                { voucher.code_type === MULTIPLE && <Typography sx={{fontSize:'16px'}}> / {voucherCodeStatistic?.total_collected}</Typography>}
              </Box> 
            </Box>
          </Box>
        </FormBox>
        <FormBox>
          <Box  sx={{display:'flex',gap:'1rem'}}>
            <Box>
                <img src={icons.folders}  alt="folder_icon"></img>
            </Box>
            <Box > 
              <Typography variant="h3" fontWeight="500" fontSize="16px">Total Code Activation</Typography>
              <Box sx={{display:'flex',alignItems:'flex-end'}}>
                <Typography fontWeight='500' sx={{fontSize:'31px'}}> {voucher.code_type === MULTIPLE ? voucherCodeStatistic?.total_activation : voucherCodeStatistic?.total_collected}</Typography>
              </Box> 
            </Box>
          </Box>
        </FormBox>
    </Box>
    <Box>
      <Typography variant="h3" fontWeight="500"> Code List</Typography>
      <Box sx={{pt:"1rem"}}>
      <Filter 
        handleSearch={handleSearch} 
        handleSort={handleSort} 
        sortList={sortList} 
        handleFilterOption={handleFilterOption}
        handleRefresh={handleRefresh}
        selectedSort={selectedSort}
      />
          { breakpointMd ?(
            <>
            {
            voucherCodes.map((code) => (
                <FormBox>
                  <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:'2rem',
                    alignContent:'flex-start'
                  }}>
                  <Box>
                    <Typography>
                      Code ID
                    </Typography>
                    <Typography>
                      {code.code}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      Collected Status
                    </Typography>
                    <Typography>
                      {
                        code.is_collect ? <Typography sx={{
                          color: '#26A800'
                        }}> Collected</Typography> : <Typography> Pending </Typography>
                      }
                      </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      Collected Date
                    </Typography>
                    <Typography>
                      {
                        code.collected_at ? code.collected_at : '-'
                      }
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      Collected By
                    </Typography>
                    <Typography>
                      {code.profile_name ?                         
                      <Typography 
                        sx={{color:'blue',textDecoration:'underline',cursor:'pointer'}}
                        onClick={() => handleProfileDialog(code.profile_uuid)} 
                      > {code.profile_name}</Typography>: '-'}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      Redemption
                    </Typography>
                    <Typography>
                      {
                        code.redeemed_at ? code.redeemed_at : '-'
                      }
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>Code Activetion</Typography>
                    <CustomSwitch checked={code.active} disabled={code.redeemed_at ? true : false} onChange={(e) => handleStatus({voucherCodeUUID:code.uuid , status:e.target.checked})} ></CustomSwitch>
                  </Box>
                  </Box>
                </FormBox>
              ))
            }
            <Box sx={{
              display:'flex',
              alignItems:'center',
              justifyContent:'flex-end',
            }}>
              <Typography>
                page {page+1} of {recordsTotal/rowsPerPage}
              </Typography>
              <Box sx={{
                display:'flex'
              }}>
                <img onClick={() => {
                  if(page > 0) handleChangePageMobile(page-1)
                }}  src={icons.arrow_left_icon} alt="arrow_left"/>
                <img onClick={() => {
                  if(page+1 < (recordsTotal/rowsPerPage)) handleChangePageMobile(page+1)
                }} src={icons.next_page_icon} alt="arrow_right"/>
              </Box>
            </Box>
            </>
          )
          : 
          <TableContainer component={Paper} sx={{boxShadow:'none'}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" key={recordsTotal}>
              <TableHead >
                <TableRow sx={{
                  background:"#3C3C3B",
                  color:'#FFF',
                  borderRadius:'6px',
                  '& th':{
                    color:'#FFF',
                  }}}>
                  <TableCell>Code ID</TableCell>
                  <TableCell align="left">Collected Status</TableCell>
                  <TableCell align="left">Collected Date</TableCell>
          
                  <TableCell align="left">Collected By</TableCell>
                  <TableCell align="left">Redemption</TableCell>
                  {voucher.code_type === MULTIPLE && 
                    <TableCell align="left">Action</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {voucherCodes.map((code) => (
                  <TableRow
                    key={code.code}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {code.code}
                    </TableCell>
                    <TableCell align="left">
                      {
                        code.is_collect ? <Typography sx={{
                          color: '#26A800'
                        }}> &#8226; Collected</Typography> : <Typography sx={{
                          color: '#FFAE42'
                        }}> &#8226; Pending</Typography>
                      }
                    </TableCell>
                    <TableCell align="left">
                      {
                        code.collected_at ? code.collected_at : '-'
                      }
                    </TableCell>
                    <TableCell align="left">
                      {code.profile_uuid ?
                        <Typography 
                          sx={{color:'blue',textDecoration:'underline',cursor:'pointer'}}
                          onClick={() => handleProfileDialog(code.profile_uuid)} 
                        > {code.profile_name}</Typography>
                      : '-'}
                    </TableCell>
                    <TableCell align="left">
                      {
                        code.redeemed_at ? code.redeemed_at : '-'
                      }
                    </TableCell>
                    {
                      voucher.code_type === MULTIPLE && 
                      <TableCell align="left">
                      {/* <Button key={code.code} variant="contained" onClick={(event) => handleActionClick(event,{status: parseInt(code.active), voucherCodeUUID: code.uuid})}>Action</Button> */}
                      <CustomSwitch checked={code.active} disabled={
                        code.redeemed_at ? true : false
                      } onChange={(e) => handleStatus({voucherCodeUUID:code.uuid , status:e.target.checked})} ></CustomSwitch>
                      </TableCell>
                    }

                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={recordsTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
          </TableContainer>
          }
      </Box>
    </Box>
    </Box>
    </Box>
  </>
}