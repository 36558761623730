import React, { useEffect } from "react";
import userApi from "../../../../../api/user";
import { startLoading, endLoading } from '../../../../../features/loading';
import { useDispatch } from "react-redux";
import { openResponseDialog } from '../../../../../features/response-dialog';
import { Billing } from "../../../../../lib/types/User";
import { Skeleton } from "@mui/material";
import AddressForm from "../components/addressForm";

export default function BillingAddressContainer() {
  const dispatch = useDispatch();
  const [initialBilling, setInitialBilling] = React.useState<Billing>();

  const getBilling = async () => {
    dispatch(startLoading());
    await userApi.getBilling()
      .then((res) => {
        if (res.success) {
          setInitialBilling(res.data);
        }
      })
      .catch(err => {
        console.error(err);
        setInitialBilling({
          address_1: '',
          address_2: '',
          city: '',
          country: 'MY',
          state: 'Kuala Lumpur',
          zip: '',
        });
      })
      .finally(() => dispatch(endLoading()));
  }

  const handleSubmit = (values: any) => {
    dispatch(startLoading());
    userApi.updateBilling({ requestBody: values }).then((res) => {
      if (res.success) {
        dispatch(openResponseDialog({ title: 'Success', description: 'Billing address updated successfully' }));
      } else {
        dispatch(openResponseDialog({ title: 'Error', description: 'Failed to update billing address' }));
      }
    }).catch((err) => {
      dispatch(openResponseDialog({ title: 'Error', description: 'Failed to update billing address' }));
    }).finally(() => {
      dispatch(endLoading());
    });
  }

  useEffect(() => {
    getBilling();
    // eslint-disable-next-line
  }, []);

  return Boolean(initialBilling) ?
    <AddressForm title="Billing Address" handleSubmit={handleSubmit} initialValues={initialBilling!} />
    : <Skeleton variant="rectangular" width={651} height={200} />;
}