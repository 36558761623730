import React, { useEffect } from "react";
import ContactFormComponent from "./contactForm.component";
import { PORTAL_SETTINGS, SETTINGS, SUBSCRIPTION } from "../../../../lib/constants/routes";
import { setBreadcrumb } from "../../../../features/admin";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import subscriptionApi from "../../../../api/subscription";
import { startLoading, endLoading } from "../../../../features/loading";
import { openResponseDialog } from "../../../../features/response-dialog";
export default function ContactFormContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "Settings", path: SETTINGS },
        { name: "Subscription", path: `${PORTAL_SETTINGS}/${SUBSCRIPTION}/` },
        { name: "Contact Us", path: null },
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => {
    navigate(`${PORTAL_SETTINGS}/${SUBSCRIPTION}`);
  }

  const handleSubmit = async (values) => {
    dispatch(startLoading());
    await subscriptionApi.businessContact(values).then((response) => {
      if (response.success) {
        dispatch(endLoading());
        dispatch(openResponseDialog({
          title: 'Success',
          description: 'Your request has been submitted successfully. Our sales team will contact you soon.',
        }));
      }
    }).catch((error) => {
      console.log(error);
      dispatch(openResponseDialog(
        {
          title: 'Error',
          description: error.message,
        }
      ));
    }).finally(() => {
      dispatch(endLoading());
    });
  }

  return <ContactFormComponent handleBack={handleBack} handleSubmit={handleSubmit} />
}