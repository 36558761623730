import React from "react"
import { Button,Box,Typography,TextField } from "@mui/material";
import { ConfirmDialog,ResponseDialog } from "../../../../components/dialog";
// const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    
//   event.preventDefault();
//   const data = new FormData(event.currentTarget);
//   const body = {
//     email: data.get('email'),
//     password: data.get('password'),
//     remember_me: data.get('remember') === 'remember',
//   };
// };
export default function LoginSecurityComponent(
  {email,handleSubmit,handleDialogConfirm,handleDialogClose,openDialog,handleResponseClose,responseDialogOpen,responseMessage}:
  {email:any,handleSubmit,handleDialogConfirm,handleDialogClose,openDialog,handleResponseClose,responseDialogOpen,responseMessage}) {
  
  return <>
    <ConfirmDialog handleConfirm={handleDialogConfirm} onClose={handleDialogClose} open={openDialog} />
  <ResponseDialog onClose={handleResponseClose} 
      title={responseMessage.title}
      description={responseMessage.description}
      open={responseDialogOpen}/>

      <Typography component="h1" variant="h4">
      Edit Login & Security
    </Typography>
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1,maxWidth:'651px' }}>
        <Typography component='label' variant='h5'>
        Email
      </Typography>
      <TextField
        color='secondary'
        margin="dense"
        required
        fullWidth
        size="small"
        id="email"
        name="email"
        value={email}
        disabled
      />
            <Typography component='label' className='required' variant='h5'>
        Current Password
      </Typography>
      <TextField
        color='secondary'
        margin="dense"
        required
        fullWidth
        size="small"
        type="password"
        id="oldPassword"
        name="oldPassword"
      />
      <Typography component='label' className='required' variant='h5'>
        New Password
      </Typography>
      <TextField
        color='secondary'
        margin="dense"
        required
        fullWidth
        size="small"
        type="password"
        id="newPassword"
        name="newPassword"
      />
      <Typography component='label' className='required' variant='h5'>
        Confirm Password
      </Typography>
      <TextField
        color='secondary'
        margin="dense"
        required
        fullWidth
        size="small"
        type="password"
        id="confirmPassword"
        name="confirmPassword"
      />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2, maxWidth:"150px",borderRadius: '30px' }}
            >
              Save Changes
            </Button>
      </Box>
  </>
  
}