import React, {useState} from 'react'
import { styled } from '@mui/material/styles';
import { Box,Typography } from "@mui/material";
import FileHelper from '../../../lib/helper/fileHelper';
import images from '../../../assets/img';
import { useSelector } from 'react-redux';
export default function BasicInfoComponent({userDetails}) {
  const profieTheme = useSelector((state:any) => state.landingPage.theme);
  const BasicInfoLayout = styled('div')({

  })

  // const UserTier = styled('div')({
  //   display: 'flex',
  //   padding: '0.25rem 0.75rem',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   gap: '0.25rem',
  //   borderRadius: '2.5rem',
  //   background: '#4AACD1'
  // })

  const UserInfo = styled('div')({
    padding: '1rem',
    margin: '1rem 1rem 0 1rem',
    display: 'grid',
    justifyItems: 'start',
    borderLeft: 'solid lightgray',
    gap:'0.4rem',
    color: profieTheme?.primary_font_color || '#000000'
  })

  const UserDescription = styled('div')({
    padding: '1rem',
    color: profieTheme?.primary_font_color || '#000000'
  })
  const [profileImage, setProfileImage] = useState("");
  const [companyImage, setCompanyImage] = useState("");
  const maxRetries = 2;
  let errorCount: any = {};
 
  const getProfileImage = async () =>{
    if ((userDetails.profile_image && userDetails.profile_image.slice(0, 4) === 'blob') || !Boolean(userDetails.profile_image)) return;

    const response = await FileHelper.getUrl(userDetails.profile_image).then(repsonse => repsonse)
    return setProfileImage(response)
  }
  const getCompanyImage = async() =>{
    if ((userDetails.company_image && userDetails.company_image.slice(0, 4) === 'blob') || !Boolean(userDetails.company_image)) return;

    const response = await FileHelper.getUrl(userDetails.company_image).then(repsonse => repsonse)
    return setCompanyImage(response)
  }
  const onImageError = async (e) => {
    errorCount[e.target.src] = Boolean(errorCount[e.target.src]) ? errorCount[e.target.src] + 1 : 1;

    // If exceeds max retries, just set the src to placeholder image
    if (errorCount[e.target.src] > maxRetries) {
      e.target.src = images.landing_page_default_image;
      return;
    }

    let url = new URL(decodeURI(e.target.src));
    let newURL = await FileHelper.getUrl(decodeURI(url.pathname.slice(1)))
    e.target.src = newURL;
  }
  getProfileImage()
  getCompanyImage()
  return <BasicInfoLayout>
    <Box style={{position:'relative'}}>
      {
        userDetails.profile_image || profileImage ?       
          <img
            alt='main display'
            src={userDetails.profile_image && userDetails.profile_image.slice(0, 4) === 'blob' ? userDetails.profile_image : profileImage}
            style={{
              objectFit: 'cover',
              minHeight: '438px',
              width: '100%',
              borderRadius: '0 0 30px 30px'
            }}
            onError={onImageError}
          /> :
        <Box sx={{
          backgroundSize: 'cover',
          backgroundRepeatX: 'no-repeat',
          backgroundRepeatY: 'no-repeat',
          backgroundImage:`url(${images.landing_page_default_image})`,
          backgroundPosition: 'center',
          minHeight:'438px',
          width: '-webkit-fill-available',
          borderRadius: '0px 0px 30px 30px'
        }}>
        </Box>
      }

      {/* <ActivityInfo>
        <Typography style={{color: 'white'}}>
          Connections: 259
        </Typography>
        <Divider/>
        <Typography style={{color: 'white'}}>
          View: 401
        </Typography>
      </ActivityInfo> */}
    </Box>
    <UserInfo>
      {/* <UserTier>
        <Typography style={{
          color: 'var(--White-Color, #FFF)',
          fontSize: '0.875rem',
          fontStyle: 'normal',
          fontWeight: '500'
          }}> Free Tier</Typography></UserTier> */}
      <Box>
      <Typography component='h4' variant='h4' style={{ fontWeight:'700', fontSize: '24px' }}>{userDetails.full_name}</Typography>
      </Box>

      <Box>
      <Typography component='p' style={{fontWeight:'600'}}>{userDetails.job_title}</Typography>
      </Box>
      <Box style={{
            display: 'flex',
            alignItems: 'center',
            gap:'0.5rem'
      }}>
        { (userDetails.company_image || companyImage) &&
          <img alt="company logo" src={userDetails.company_image && userDetails.company_image.slice(0, 4) === 'blob' ? userDetails.company_image : companyImage} style={{
            width: '24px',
            height: '24px',
            objectFit: 'contain',
          }}/>
        }
        <Typography component='p'  style={{fontWeight:'600'}}>{userDetails.company_name}</Typography>
      </Box>

    </UserInfo>
    <UserDescription>
          {userDetails.description}
    </UserDescription>
  </BasicInfoLayout>
}