import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import icons from '../../../../assets/icons';
import defaultTiers from '../../../../lib/constants/pricing';
import SubscriptionColumn from '../../../../components/subscription/subscriptionColumn';
const currency = 'USD';

interface PricingProps {
  tiers?: any;
  expDate?: string;
  handleBack?: () => void;
  handleFree: () => void;
  handleSubscribe: () => void;
  handleContact: () => void;
  handleTrial: () => void;
  trialled?: boolean;
}

export default function Pricing({ tiers = defaultTiers, expDate, handleBack, handleFree, handleSubscribe, handleContact, handleTrial, trialled = false }: PricingProps) {
  const [status, setStatus] = React.useState('monthly');
  const trialDuration = process.env.REACT_APP_LIFESTYLE_TRIAL_DURATION;
  const SubscriptionColumns = ({
    index, tier,
  }) => {
    if (tier.type === 'FREE_TIER') {
      return <SubscriptionColumn
        key={index}
        tier={tier}
        currency={currency}
        status={status}
        handleSubmit={handleFree}
        buttonText={'Subcribe'}
        variant={'outlined'}
      />
    }

    if (tier.type === 'LIFESTYLE_TRIAL')
      return <SubscriptionColumn
        key={index}
        tier={tier}
        currency={currency}
        status={status}
        handleSubmit={handleTrial}
        buttonText={`Start ${trialDuration} days Free Trial`}
        variant={'contained'}
      />


    if (tier.type === 'BUSINESS')
      return <SubscriptionColumn
        key={index}
        tier={tier}
        currency={currency}
        status={status}
        handleSubmit={handleContact}
        buttonText={'Contact Us'}
        variant={'outlined'}
      />
  }

  return <>
    {handleBack !== undefined && <Button color="secondary" startIcon={<img width={12} height={12} src={icons.back_icon} alt="" />} sx={{ mb: '10px' }} onClick={handleBack}>Back</Button>}
    <Box sx={{ display: 'flex', mb: '30px' }}>
      <Typography component="h2" color="text.primary" sx={{ flex: 1, fontSize: '31px' }}>
        Discover your ideal plan
      </Typography>
      <Box sx={{ background: '#E6E6E6', borderRadius: '8px', p: '6px', height: "min-content" }}>
        <Button sx={{ color: status === 'monthly' ? '#FECD20' : '#373736', borderRadius: '4px', background: status === 'monthly' ? '#373736' : '', mr: '10px' }} onClick={() => { setStatus('monthly') }}>Monthly</Button>
        <Button sx={{ color: status === 'monthly' ? '#373736' : '#FECD20', borderRadius: '4px', background: status === 'monthly' ? '' : '#373736' }} onClick={() => setStatus('yearly')}>Yearly</Button>
      </Box>
    </Box>
    <Container
      id="pricing"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'stretch', flexWrap: 'wrap' }}>
        {
          status === 'monthly' ?
            tiers.month.map((tier, index) => <SubscriptionColumns index={index} tier={tier} />) :
            tiers.year.map((tier, index) => <SubscriptionColumns index={index} tier={tier} />)
        }
      </Box>
    </Container >
  </>
}