import memberToGuestExchange from "./memberToGuestExchange.api"
import getProfilePicture from "./getProfilePicture.api"
import getActiveProfile from "./getActiveProfile.api"
import getActiveProfileByCard from "./getActiveProfileByCard.api"
import getFirstCard from "./getFirstCard.api"

const basicContactApi = {
  memberToGuestExchange,
  getProfilePicture,
  getActiveProfileByCard,
  getActiveProfile,
  getFirstCard
}

export default basicContactApi
