import axios from "axios";

interface ReferralStatistics {
    subscription_count: number | 0,
    buy_card_count: number | 0,
    signup_count: number | 0,
}
interface Data{
  data: ReferralStatistics;
  success: boolean;
}

const getReferralStatistics =  (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

    return new Promise((resolve, reject) => {
        axios.get("user/getReferralStatistics")
        .then(response => resolve(response.data))
        .catch(error => reject(error.response));
    })
};

export default getReferralStatistics;
