import axios from "axios";

interface Data {
  code: number,
  data: null,
  message: string | null,
  success: boolean
}

interface Guest {
  owner_card?: string | null;
  owner_profile?: string | null;
  name: string;
  phone_number: string;
  email: string;
  job_title?: string | null;
  company_name?: string | null;
  longitude: number;
  latitude: number;
  ip_address: string;
  is_geolocation_allowed: boolean;
}

const memberToGuestExchange = (requestBody: Guest): Promise<Data> => {
  return new Promise((resolve, reject) => {
    axios.post("basiccontact/memberToGuestExchange", requestBody)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default memberToGuestExchange;
