import React from 'react';
import { Button, Dialog, DialogContent, DialogActions } from "@mui/material";
import Picker from 'react-mobile-picker';

const selections = {
  hour: Array.from({ length: 12 }, (_, i) => `${i + 1}`),
  colon: [':'],
  minute: Array.from({ length: 60 }, (_, i) => `0${i}`.slice(-2)),
  kosong: [' '],
  amPm: ['AM', 'PM'],
}

export default function TimePicker({ openPicker, setOpenPicker, pickerValue, setPickerValue, handleCancel, handleConfirm }) {
  return <Dialog
    open={openPicker}
    onClose={() => setOpenPicker(false)}
    aria-labelledby="start time picker dialog"
    PaperProps={{ style: { borderRadius: '16px', color: '#6c6c6c' } }}
    sx={{ mx: 'auto' }}
  >
    <DialogContent sx={{
      minWidth: '330px',
      padding: '20px 70px',
    }}>
      <Picker value={pickerValue} onChange={setPickerValue} wheelMode='natural'>
        {Object.keys(selections).map(name => (
          <Picker.Column key={name} name={name}>
            {selections[name].map(option => (
              <Picker.Item key={option} value={option}>
                {option}
              </Picker.Item>
            ))}
          </Picker.Column>
        ))}
      </Picker>
    </DialogContent>
    <DialogActions style={{ justifyContent: 'center' }}>
      <Button
        type="button"
        sx={{ color: '#c4c4c4', textTransform: 'none' }}
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        type="button"
        sx={{ color: '#6c6c6c', textTransform: 'none' }}
        onClick={handleConfirm}
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
}