import axios from "axios";

interface ReferralCode {
  referral_code: string | null
}
interface Data{
  data: ReferralCode;
  success: boolean;
}

const getReferralCode =  (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

    return new Promise((resolve, reject) => {
        axios.get("user/getReferralCode")
        .then(response => resolve(response.data))
        .catch(error => reject(error.response));
    })
};

export default getReferralCode;
