import React from 'react';
import FloatingButton from '../../../../landing-page/floating-button/floatingButton.component';

export default function FloatingButtonContainer({ isEnabledAppointment }) {
  return <FloatingButton
    isEnabledAppointment={isEnabledAppointment}
    handleSaveContact={undefined}
    handleShareQR={undefined}
    handleBookAppointment={undefined}
    handleExchangeContact={undefined}
  />
}