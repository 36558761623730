import React from "react";
import { Box, Typography, Paper, FormControl, InputLabel, OutlinedInput, IconButton, Button, TextField, Checkbox } from "@mui/material";
import icons from "../../../../../../assets/icons";
import { Formik } from "formik";
import ItemPreview from "../../../cart/itemPreview";
import LoadingButton from "@mui/lab/LoadingButton";

interface EditTemplateComponentProps {
  type: string;
  cartItem?: any;
  template: any;
  handleBack: () => void;
  handleSubmit: (values: any) => void;
  handleCustomize: (productUUID: string, productName: string, quantity: number, attrUuid: string) => void | undefined;
  isLoading: boolean;
}

export default function EditTemplateComponent({ type, cartItem, template, handleBack, handleSubmit, handleCustomize, isLoading }: EditTemplateComponentProps) {
  const [color, setColor] = React.useState<string>(cartItem?.font_color_code ?? '#586C6C');
  const [overrideIsShowingFront, setOverrideIsShowingFront] = React.useState<boolean>();

  return <>
    <Button color="secondary" startIcon={<img width={12} height={12} src={icons.back_icon} alt="" />} sx={{ mb: '10px' }} onClick={handleBack}>Back</Button>
    <Formik
      initialValues={{
        quantity: cartItem?.quantity ?? 1,
        textColor: cartItem?.font_color_code ?? '#586C6C',
        selectedVariant: Boolean(cartItem?.card_variation) ? template.variants.filter(v => v.name === cartItem.card_variation)[0] : template.variants[0],
        personName: cartItem?.name ?? '',
        jobTitle: cartItem?.position ?? '',
        logo: cartItem?.logo_url,
        customization: Boolean(cartItem?.name) || Boolean(cartItem?.position) || Boolean(cartItem?.logo_url),
        pixelcare: Boolean(cartItem?.pixelcare),
      }}
      onSubmit={values => {
        Promise
          .resolve(setOverrideIsShowingFront(true))
          .then(() => handleSubmit(values));
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <Paper component="form" onSubmit={handleSubmit} elevation={0} sx={{ display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap', p: '20px' }}>
          <ItemPreview formValues={values} overrideIsShowingFront={overrideIsShowingFront} isZakeke={type === 'custom'} />
          <Box sx={{ flex: 1, minWidth: '300px' }}>
            <Typography sx={{ fontSize: '25px', fontWeight: 500 }}>{template.name}</Typography>
            <Typography sx={{ textAlign: 'justify' }}>
              {template.description}
            </Typography>

            <Box sx={{ my: '20px' }}>
              {!Boolean(template.current_price.base) ?
                <Typography component={'span'} sx={{ flex: 1, textAlign: 'start', fontWeight: 500 }}>{template.currency} {template.previous_price.base}</Typography>
                :
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'start', alignItems: 'center' }}>
                  <Typography component={'span'} sx={{ textAlign: 'start', fontWeight: 500, color: '#A7A7A7', textDecoration: 'line-through' }}>{template.currency} {template.previous_price.base}</Typography>
                  <Typography component={'span'} sx={{ textAlign: 'start', fontWeight: 500, color: '#EB3325', fontSize: '20px' }}>{template.currency} {template.current_price.base}</Typography>
                  <Typography component={'span'} sx={{
                    color: 'white',
                    background: '#EB3325',
                    padding: '2px 10px',
                    fontSize: '12px',
                    borderRadius: '30px',
                  }}>
                    {((template.previous_price.base - template.current_price.base) / template.previous_price.base * 100).toFixed(1).replace('.0', '')}% OFF
                  </Typography>
                </Box>
              }
            </Box>

            <FormControl>
              <InputLabel htmlFor="outlined-adornment-quantity">Quantity</InputLabel>
              <OutlinedInput
                id="outlined-adornment-quantity"
                startAdornment={<IconButton onClick={() => { (values.quantity > 1) ? setFieldValue('quantity', values.quantity - 1) : setFieldValue('quantity', 1) }}><img src={icons.minus_sign_circle} alt="" /></IconButton>}
                endAdornment={<IconButton onClick={() => setFieldValue('quantity', values.quantity + 1)}><img src={icons.plus_sign_circle} alt="" /></IconButton>}
                label="Quantity"
                sx={{ width: '180px' }}
                inputProps={{ style: { textAlign: 'center' } }}
                value={values.quantity}
              />
            </FormControl>
          {
            type !== 'template' ? 
              <Box sx={{ mt: '20px',  bgcolor: '#FFF7DD', borderRadius: '8px', p: '15px' }}>
                <Typography sx={{ fontWeight: 500 }} gutterBottom>Card Variation</Typography>
                <Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                  {template.variants.map(variant => <Button
                    key={variant.name}
                    // style={
                    //   (type === 'template') 
                    //   ? { fontWeight: 600, border: values.selectedVariant.name === variant.name ? (variant.name === 'black' ? '3px solid #FECD20' : '3px solid #3C3C3B') : "none", backgroundColor: variant.name, color: variant.name === "white" ? "black" : "white", padding: '5px 20px' }
                    //   : { fontWeight: 600, backgroundColor: variant.name, color: variant.name === "white" ? "black" : "white", height: '32px', width: '32px', minWidth: '0px', borderRadius: '50%' }
                    // }

                    style={
                      { fontWeight: 600, backgroundColor: variant.name, color: variant.name === "white" ? "black" : "white", height: '32px', width: '32px', minWidth: '0px', borderRadius: '50%' }
                    }
                    sx={{ borderRadius: '8px', color: '#373736', boxShadow: 'none' }}
                    variant='contained'
                    onClick={() => setFieldValue('selectedVariant', variant)}
                  >
                    {(type === 'custom' && values.selectedVariant.name === variant.name) &&
                      <span style={{border: "3px solid black", margin: "-5px", borderRadius: "50%", position: "absolute", overflow: "hidden", left: "0", right: "0", top: "0", bottom: "0"}}></span>}
                    {type === 'template' && variant.name}
                    {/* {variant.name} */}
                  </Button>)}
                </Box>
              </Box>
              : null
          }

            {type === 'template' && <>
             { values.selectedVariant?.sample && <>
                <Box sx={{ bgcolor: '#FFF7DD',mt: '20px', borderRadius: '8px', p: '15px' }}>
                  <Typography sx={{ fontWeight: 500 }} gutterBottom>Font Color</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <input
                      type="color"
                      id="textColor"
                      name="textColor"
                      value={color}
                      onChange={e => {
                        setColor(e.target.value);
                        handleChange(e);
                      }}
                      style={{
                        backgroundColor: 'transparent',
                        borderRadius: '4px',
                        border: 'none',
                        width: '66px',
                        height: '66px',
                        marginTop: '4px',
                      }}
                    />
                    <TextField
                      color='primary'
                      margin="dense"
                      name="textColor"
                      sx={{ bgcolor: 'white', borderRadius: '4px' }}
                      value={values.textColor}
                      onChange={e => {
                        setColor(e.target.value);
                        handleChange(e);
                      }}
                    />
                  </Box>
                </Box>

                <Box sx={{ my: '20px', bgcolor: '#FFF7DD', borderRadius: '8px', p: '15px' }}>
                  <Checkbox name="customization" onClick={handleChange} checked={values.customization} sx={{ p: 0, mr: '10px' }} />
                  <Typography component={'span'} sx={{ fontWeight: 500, mr: '10px' }} gutterBottom>Customization</Typography>
                  {!Boolean(template.previous_price) ?
                    <Typography component={'span'} sx={{ flex: 1, textAlign: 'start', fontSize: '13px', fontWeight: 400 }}>+{template.currency}{template.current_price.customization}</Typography>
                    :
                    <>
                      <Typography component={'span'} sx={{ flex: 1, textAlign: 'start', fontSize: '13px', fontWeight: 400, color: '#A7A7A7', textDecoration: 'line-through', mr: '5px' }}>+{template.currency}{template.previous_price.customization}</Typography>
                      <Typography component={'span'} sx={{ flex: 1, textAlign: 'start', fontSize: '13px', fontWeight: 400, color: '#26A800' }}>+{template.currency}{template.current_price.customization}</Typography>
                    </>
                  }
                  <Box>
                    <Typography component='label' htmlFor='personName' variant='h5'>
                      Name
                    </Typography>
                    <TextField
                      color='primary'
                      margin="dense"
                      fullWidth
                      id="personName"
                      name="personName"
                      sx={{
                        bgcolor: values.customization ? 'white' : '#EFEFEF',
                        borderRadius: '4px',
                        '& input': {
                          color: values.customization ? 'currentColor' : 'lightgray',
                        }
                      }}
                      value={values.personName}
                      onChange={handleChange}
                      onClick={() => setFieldValue('customization', true)}
                    />
                    {/* <Typography component='label' className='validation-error' variant='h5'>
              {errors.name}
            </Typography> */}
                  </Box>
                  <Box>
                    <Typography component='label' htmlFor='jobTitle' variant='h5'>
                      Position
                    </Typography>
                    <TextField
                      color='primary'
                      margin="dense"
                      fullWidth
                      id="jobTitle"
                      name="jobTitle"
                      sx={{
                        bgcolor: values.customization ? 'white' : '#EFEFEF',
                        borderRadius: '4px',
                        '& input': {
                          color: values.customization ? 'currentColor' : 'lightgray',
                        }
                      }}
                      value={values.jobTitle}
                      onChange={handleChange}
                      onClick={() => setFieldValue('customization', true)}
                    />
                    {/* <Typography component='label' className='validation-error' variant='h5'>
              {errors.job_title}
            </Typography> */}
                  </Box>
                  {/* <Box sx={{ mt: '10px' }}>
                    <Typography sx={{ fontWeight: 500 }} gutterBottom>Upload Logo</Typography>
                    <div style={{ display: 'flex' }}>
                      <Button
                        disabled={!values.customization}
                        component="label"
                        variant="outlined"
                        sx={{
                          my: 1,
                          height: '40px',
                          border: '1px solid #FECD20',
                          borderRadius: '8px',
                          color: 'black'
                        }}>
                        Upload logo
                        <VisuallyHiddenInput
                          value=''
                          disabled={!values.customization}
                          type="file" id="logo"
                          onChange={(event) => {
                            if (!event.target.files || event.target.files.length === 0) return;

                            setFieldValue('logo', event.target.files[0]);
                          }}
                          accept="image/png, image/jpeg"
                        />
                      </Button>
                      {Boolean(values.logo) && values.customization && <IconButton
                        onClick={() => setFieldValue('logo', undefined)}
                      >
                        <div className='icon' style={{
                          backgroundColor: '#EB3325',
                          mask: `url(${icons.delete_02})`,
                          maskSize: 'cover',
                          maskRepeat: 'no-repeat',
                          WebkitMask: `url(${icons.delete_02})`,
                          WebkitMaskSize: 'cover',
                          WebkitMaskRepeat: 'no-repeat',
                          width: '24px',
                          height: '24px',
                        }} />
                      </IconButton>}
                    </div>
                    <Typography component='h5' variant='h5' sx={{ color: '#AAAAAA' }}>
                      Supported file type: PNG and JPEG only. Max file size: 25 MB. Recommended to use image with 1:1 aspect ratio.
                    </Typography>
                  </Box> */}
                </Box>
              </>}
              <LoadingButton
                loading={isLoading}
                color="secondary"
                variant="contained"
                type="submit"
                style={{display: (type === 'template') ? 'block' : 'initial', marginTop: (type === 'template') ? '20px' : 'initial' }}
              >
                {Boolean(cartItem) ? 'Save Changes' : 'Add to Cart'}
              </LoadingButton>
            </>}
            {type === 'custom' && <Box sx={{ my: '20px' }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => handleCustomize(template.uuid, template.name, values.quantity, values.selectedVariant.uuid)}
              >
                Customize Card
              </Button>
            </Box>}
          </Box>
        </Paper>)}
    </Formik>
  </>
}