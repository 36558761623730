import axios from "axios";

interface Response {
  code: number;
  data: null;
  message: string | null;
  success: boolean;
}

interface SubscriptionParams {
  duration: number;
  referral_code?: string | undefined;
}

const subscribeLifestyle = (params: SubscriptionParams): Promise<Response> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');

  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get("subscription/subscribeLifestyle", { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default subscribeLifestyle;
