import React from "react";
import { Box, Typography, Button, Divider, Radio, Dialog, Link } from "@mui/material";
import icons from "../../../../../assets/icons";
import Item from "./item";
import ItemPreview from "../../../../my-cards/pages/cart/itemPreview";
import { Formik,Form } from "formik";
import AddressDialog from "../../../../my-cards/pages/cart/addressDialog";
import BillingDialog from "../../../../my-cards/pages/cart/billingDialog";

export default function OrderSummaryComponent({
  currency,
  basicInfo,
  handleBack,
  items,
  itemPreview,
  setItemPreview,
  handlePreview,
  openAddressForm,
  setOpenAddressForm,
  address,
  setAddress,
  handleSubmit,
  billing,
  setBilling,
  openBillingForm,
  setOpenBillingForm,
  totalAmount,
  shippingFee,
  setShippingFee,
  shippingRateError,
  setShippingRateError,
  channel,
  setChannel
}) {

  return <>
    <Button color="secondary" startIcon={<img width={12} height={12} src={icons.back_icon} alt="" />} sx={{ mb: '10px' }} onClick={handleBack}>Back</Button>
    <Typography sx={{ fontSize: '31px', fontWeight: 400 }} gutterBottom>Shopping Cart</Typography>
    <Formik
      enableReinitialize
      initialValues={{
        products: items,
        billDesc: "Pixelcard NFC card payment",
        billAddr1: billing.address1,
        billAddr2: billing.address2,
        billCity: billing.city,
        billState: billing.state,
        billZip: billing.postcode,
        billCountry: billing.country,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zip: address.postcode,
        refCode: '',
        country: billing.country,
        currency: currency,
        channel: channel,
        prodType:'NFC',
        shippingFee: shippingFee
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values).finally(() => setSubmitting(false));
      }}
    >
      {({
        handleSubmit
      }) => <Form role="molpayseamless" onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'row', gap: '20px', flexWrap: 'wrap' }}>
          <Box sx={{ flex: 1, minWidth: '330px' }}>
            <Box sx={{ bgcolor: 'white', borderRadius: '8px', border: '1px solid #C5C5C5', p: '15px', mb: '20px' }}>
              <Typography fontWeight={500} gutterBottom>Basic Info</Typography>
              <Typography>{basicInfo.name}</Typography>
              <Typography>{`${basicInfo.phone_code}${basicInfo.phone_number}`}</Typography>
              <Typography>{basicInfo.email}</Typography>
              <Divider sx={{ my: '15px' }} />
              <Box sx={{display:'grid',gridTemplateColumns:'1fr 1fr'}}>
              <Box sx={{pr:"1rem"}}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start',  gap: '1rem', alignItems: 'center' }}>
                  <Typography fontWeight={500} gutterBottom>Shipping Details</Typography>
                  <img src={icons.edit_02} alt="edit-icon" style={{height:'1rem',width:'1rem', cursor:'pointer'}} onClick={() => setOpenAddressForm(true)}/>
                </Box>
        
                <Typography>{address?.address1}</Typography>
                <Typography>{address?.address2}</Typography>
                <Typography>{address?.postcode} {address?.city}</Typography>
                <Typography>{address?.state}</Typography>
                <Typography>{address?.country}</Typography>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start',  gap: '1rem',alignItems: 'center' }}>
                  <Typography fontWeight={500} gutterBottom>Billing Address</Typography>
                  <img src={icons.edit_02} alt="edit-icon" 
                  style={{height:'1rem',width:'1rem' , cursor:'pointer'}} onClick={() => setOpenBillingForm(true)}/>
                </Box>
        
                <Typography>{billing?.address1}</Typography>
                <Typography>{billing?.address2}</Typography>
                <Typography>{billing?.postcode} {billing?.city}</Typography>
                <Typography>{billing?.state}</Typography>
                <Typography>{billing?.country}</Typography>
              </Box>
              </Box>
            </Box>
            <Box sx={{ mb: '20px' }}>
              <Typography sx={{ fontSize: '22px', fontWeight: 400 }}>Payment Method</Typography>
              <Box sx={{ border: '1px solid #C5C5C5', borderRadius: '8px',  overflow: 'hidden', mb: '15px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: channel === 'creditAN' ? '#FECD20' : 'white',
                    cursor: 'pointer',
                    p: '15px',
                  }}
                  onClick={() => {
                    setChannel('creditAN')
                  }}
                >
                  <Typography sx={{ fontWeight: 500 }}>Credit or debit card</Typography>
                  <Radio checked={channel === 'creditAN'} color="secondary" />
                </Box>
              </Box>
              <Box sx={{ border: '1px solid #C5C5C5', borderRadius: '8px', overflow: 'hidden' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: channel === 'fpx' ? '#FECD20' : 'white',
                    cursor: 'pointer',
                    p: '15px',
                  }}
                  onClick={() => {
                    setChannel('fpx')
                  }}
                >
                  <Typography sx={{ fontWeight: 500 }}>Online banking</Typography>
                  <Radio checked={channel === 'fpx'} color="secondary" />
                </Box>
              </Box>
            </Box>

            <Box sx={{
              width: '100%',
              borderRadius: '8px',
              bgcolor: '#E4FFDF',
              p: '15px',
            }}>
              <Typography gutterBottom>
                Only shipping is supported; please allow 4–7 days for delivery.
              </Typography>
              <Link sx={{ color: '#006BCF', textDecorationColor: '#006BCF' }}><Typography sx={{ fontSize: '12px' }}>Shipping & Return Policys</Typography></Link>
              <Link sx={{ color: '#006BCF', textDecorationColor: '#006BCF' }}><Typography sx={{ fontSize: '12px' }}>Payment Policy</Typography></Link>
              <Link sx={{ color: '#006BCF', textDecorationColor: '#006BCF' }}><Typography sx={{ fontSize: '12px' }}>PixelCare+ Policy</Typography></Link>
            </Box>
          </Box>
          <Box sx={{ flex: 1, bgcolor: 'white', borderRadius: '8px', border: '1px solid #C5C5C5', p: '15px' }}>
            <Typography fontWeight={500} gutterBottom>Summary</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
              {items?.length > 0 && items.map((item, i) => <Item
                key={i}
                currency={currency}
                item={item}
                index={i}
                handlePreview={handlePreview}
              />)}
            </Box>
            <Divider sx={{ my: '15px' }} />
            {
              shippingRateError &&  <Typography color="error" variant="caption" sx={{ textAlign: 'start', display: 'block' }}>
                Shipping rate is not available for this shipping address.
              </Typography>
            }
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '0 15px 15px',
            }}>
              <Typography>Shipping fee</Typography>
              <Typography>{Number(shippingFee || 0).toFixed(2)}</Typography>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: '15px',
              borderRadius: '5px',
              bgcolor: '#FFF2C5',
              mb: '15px',
            }}>
              {/* <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Total (USD)</Typography> */}
              <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Total ({currency})</Typography>
              <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                {totalAmount}
              </Typography>
            </Box>
            <Button disabled={shippingRateError} color="secondary" size="large" variant="contained" type="submit">Next</Button>
          </Box>
        </Form>}
    </Formik>
    <Dialog
      open={Boolean(itemPreview)}
      onClose={() => setItemPreview(undefined)}
      fullWidth
      maxWidth='md'
      PaperProps={{ sx: { p: '30px' } }}
    >
      <ItemPreview
        formValues={{
          ...itemPreview,
          selectedVariant: itemPreview?.variant,
        }}
        showPlaceholder={false}
        isZakeke={itemPreview?.variant.front.includes('zakeke.com')}
      />

      {/* <ItemPreview
        formValues={{
          ...itemPreview,
          selectedVariant: itemPreview?.variant,
        }}
        showPlaceholder={false}
        // Items from Zakeke will have its logo null
        type={itemPreview?.logo === null ? 'custom' : 'template'}
      /> */}
    </Dialog>
    <AddressDialog
      open={openAddressForm}
      setOpen={setOpenAddressForm}
      address={address}
      setAddress={setAddress}
      setShippingFee={setShippingFee}
      setShippingRateError={setShippingRateError}
    />
    <BillingDialog
      open={openBillingForm}
      setOpen={setOpenBillingForm}
      billing={billing}
      setBilling={setBilling}
    />
  </>
}