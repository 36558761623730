import React from 'react'
import { styled } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography } from "@mui/material";
import images from '../../../../../assets/img'
import { useSelector } from 'react-redux';

const CustomContainer = styled('div')({
  paddingTop: "3rem",
  display: 'grid',
  flexDirection: 'row'
});

export default function ConfirmedComponent() {
  const navigate = useNavigate();
  const { id } = useParams();
  const landingPage = useSelector((state: any) => state.landingPage);
  const name = landingPage.profile.user_details.full_name;
  const accessPath = landingPage.accessPath;

  return <CustomContainer>
    <img src={images.appointment_confirmed} alt='' style={{ margin: 'auto' }} />
    <Box sx={{
      display: 'grid',
      justifyItems: 'center',
      p: '3rem'
    }}>
      <Typography sx={{ fontSize: '20px', fontWeight: 500, color: '#373736', textAlign: 'center' }}>
        Appointment confirmed with {name}!
      </Typography>
    </Box>

    <Box style={{
      display: 'grid',
      justifyItems: 'center'
    }}>
      <Button
        type="button"
        fullWidth
        variant="contained"
        sx={{
          my: 2,
          gap: '1rem',
          borderRadius: '30px',
          width: '280px',
          height: '52px',
          color: '#FECD20',
          textTransform: 'none',
        }}
        onClick={() => navigate(`${accessPath}/${id}`)}
      >
        Done
      </Button>
    </Box>
  </CustomContainer>
}