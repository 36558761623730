import React, { useEffect, useState, FormEvent } from "react";
import { v4 as uuidv4 } from 'uuid';
import contactListApi from "../../../../../api/contactList";
import FileHelper from '../../../../../lib/helper/fileHelper';
import { useLocation } from 'react-router-dom';
import jobTitles from '../../../../../lib/constants/jobTitles';
import { useDispatch } from 'react-redux';
import { openResponseDialog } from '../../../../../features/response-dialog';
import { refreshDetail } from "../../../../../features/contact";
import CreateDialogComponent from "../../../component/create-dialog/createDialog.component";
import profileApi from "../../../../../api/profile";
const baseUrl = process.env.REACT_APP_API_HOST_URL;

const getUser = () => JSON.parse(localStorage.getItem('user') ?? '{}');

// TODO: Use axios
// TODO: Factor out this function to keep code DRY
async function uploadFile(filePath: string, file) {
  let user = getUser();
  let fullPath = `user/${user.access_token_payload.uuid}/contacts/${filePath}/${uuidv4()}_${file.name}`;

  // Create presigned post
  const { url, fields } = await FileHelper.createUrl(fullPath).then(repsonse => repsonse)
  // Upload the file
  const formData = new FormData();
  Object.keys(fields).forEach(key => {
    formData.append(key, fields[key]);
  });
  formData.append("file", file);
  const res = await fetch(url, {
    method: "POST",
    body: formData,
  });

  // Get the URL if file uploaded successfully
  if (res.status === 204) {
    return fullPath;
  }
}

interface Profile {
  uuid: string;
  name: string;
}

function EditDialog({ open, handleClose, contact, contactUuid = '' }) {
  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [selectedProfileUUID, setSelectedProfileUUID] = useState('');
  const [imageFullPath, setImageFullPath] = useState<string>('');
  const [image, setImage] = useState<any>();
  const [name, setName] = useState<any>();
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>(['']);
  const [birthDate, setBirthDate] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [company, setCompany] = useState<any>();
  const [jobTitle, setJobTitle] = useState<any>();
  const [website, setWebsite] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [otherTitle, setOtherTitle] = useState<any>('');
  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    birthday: '',
    email: '',
    // otherTitle: ''
  });

  const getProfiles = () => {
    profileApi.getProfilesDropdown()
      .then((response: any) => {
        const prfs = (response.data ?? []).map(prf => ({
          uuid: prf.profile_uuid,
          name: prf.profile_name,
        }));

        if (prfs.length === 1) setSelectedProfileUUID(prfs[0].uuid);

        setProfiles(prfs);
      });
  }

  useEffect(() => {
    getProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // get guest uuid from url
  // temporary solution bad practice should get it at parent container
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const guestUUID = queryParams.get('guestUUID');

  const dispatch = useDispatch();

  function handleImageChange(e) {
    setImage(e.target.files[0]);
  }

  function isValidDate(dateString) {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;  // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    var currentDate = new Date(Date.now());
    if (d > currentDate) return false;
    if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateString;
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setErrors({
      name: '',
      phone: '',
      birthday: '',
      email: '',
      // otherTitle: ''
    });
    errors.name = '';
    errors.phone = '';
    errors.birthday = '';
    errors.email = '';

    event.preventDefault();
    var hasError = false;

    if (email === '' || email === undefined) {
      setErrors({
        ...errors,
        email: 'Email required'
      });
      errors.email = 'Email required';
      hasError = true;
    } else {
      var mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (!email.match(mailformat)) {
        setErrors({
          ...errors,
          email: 'Invalid email'
        });
        errors.email = 'Invalid email';
        errors.email = 'Invalid email';
        hasError = true;
      }
    }

    if (name === '') {
      setErrors({
        ...errors,
        name: 'Name required'
      });
      errors.name = 'Name required';
      hasError = true;
    }

    if (!Boolean(phoneNumbers[0])) {
      setErrors({
        ...errors,
        phone: 'Phone number required'
      });
      errors.phone = 'Phone number required';
      hasError = true;
    }

    if (birthDate === '') {
      // setErrors({
      //   ...errors,
      //   birthday: 'Invalid birthday'
      // });
      // errors.birthday = 'Invalid birthday';
      // hasError = true;
    } else {
      if (!isValidDate(birthDate)) {
        setErrors({
          ...errors,
          birthday: 'Invalid birthday'
        });
        errors.birthday = 'Invalid birthday';
        hasError = true;
      }
    }
    // if (jobTitle === 'Others' && otherTitle === '') {
    //   setErrors({
    //     ...errors,
    //     otherTitle: 'Job title required'
    //   });
    //   hasError = true;
    // }

    if (hasError) return;

    event.preventDefault();

    let formData = {};

    if (Boolean(selectedProfileUUID)) formData['profile_uuid'] = selectedProfileUUID;
    if (Boolean(name)) formData['name'] = name;
    if (Boolean(phoneNumbers[0])) formData['phone_number1'] = phoneNumbers[0];
    if (Boolean(phoneNumbers[1])) formData['phone_number2'] = phoneNumbers[1];
    if (Boolean(phoneNumbers[2])) formData['phone_number3'] = phoneNumbers[2];
    if (email !== undefined && email !== '') formData['email'] = email;
    if (jobTitle !== undefined && jobTitle !== '') {
      jobTitle !== 'Others' ? formData['job_title'] = jobTitle : formData['job_title'] = otherTitle;
    }
    if (company !== undefined && company !== '') formData['company_name'] = company;
    if (birthDate !== undefined && birthDate !== '') formData['birthday'] = birthDate;
    if (website !== undefined && website !== '') formData['company_website'] = website;
    if (address !== undefined && address !== '') formData['company_address'] = address;

    let pfp = image !== undefined ? typeof image === 'string' ? imageFullPath : await uploadFile('profile_picture', image) : undefined
    if (pfp !== undefined && pfp !== '') formData['profile_picture'] = pfp;

    let url = baseUrl + '/contact/addGuest';
    let body = JSON.stringify(formData);

    if (guestUUID !== undefined && guestUUID !== '') {
      body = JSON.stringify({
        guest_uuid: guestUUID,
        ...formData,
      });
      url = baseUrl + '/contact/editGuest';
    }

    let response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${getUser().access_token}`,
      },
      body,
    });

    if (response.ok) {
      dispatch(openResponseDialog({ title: "Success", description: "Contact saved successfully" }));
      handleClose(true);
      dispatch(refreshDetail(true));
    }
  }


  useEffect(() => {
    if (contact) {
      setImageFullPath(contact.profileImage);
      contactListApi.contactList.getUrl(contact.profileImage)
        .then(url => setImage(url));
      setName(contact.name);
      setPhoneNumbers(contact.phoneNumber);
      setBirthDate(contact.birthday);
      setEmail(contact.email[0].toString());
      setCompany(contact.companyName);
      const jobTitle = jobTitles.filter(title => title === contact.jobTitle).length > 0 ? contact.jobTitle : 'Others';
      setJobTitle(jobTitle);
      if (jobTitle === 'Others') setOtherTitle(contact.jobTitle);
      setWebsite(contact.website[0]);
      setAddress(contact.companyAddress[0]);
    }
  }, [contact]);

  useEffect(() => {
    setErrors({
      name: '',
      phone: '',
      birthday: '',
      email: ''
    });
  }, [open])

  return <CreateDialogComponent
    open={open}
    handleClose={handleClose}
    handleSubmit={handleSubmit}
    image={image}
    handleImageChange={handleImageChange}
    contactUuid={contactUuid}
    profiles={profiles}
    errors={errors}
    isEditing={true}
    setIsEditing={console.log}
    selectedProfileUUID={selectedProfileUUID}
    setSelectedProfileUUID={setSelectedProfileUUID}
    name={name}
    setName={setName}
    phoneNumbers={phoneNumbers}
    setPhoneNumbers={setPhoneNumbers}
    birthDate={birthDate}
    setBirthDate={setBirthDate}
    email={email}
    setEmail={setEmail}
    company={company}
    setCompany={setCompany}
    jobTitle={jobTitle}
    setJobTitle={setJobTitle}
    otherTitle={otherTitle}
    setOtherTitle={setOtherTitle}
    website={website}
    setWebsite={setWebsite}
    address={address}
    setAddress={setAddress}
  />
};

export default EditDialog;