import orderApi from "../../api/order";

const jQuery = process.env.REACT_APP_FIUU_JQUERY || 'https://ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js';
// const paymentGateway = process.env.REACT_APP_FIUU_SEAMLESS || 'https://sandbox.merchant.razer.com/RMS/API/seamless/3.28/js/MOLPay_seamless.deco.js';
const paymentGateway = process.env.REACT_APP_FIUU_SEAMLESS || 'https://pay.merchant.razer.com/RMS/API/seamless/3.28/js/MOLPay_seamless.deco.js';
const returnUrl = process.env.REACT_APP_FIUU_RETURN_URL || ' https://api.member.pixelcard.co/api/fiuu/return?order_uuid=';
const cancelUrl = process.env.REACT_APP_FIUU_CANCEL_URL || ' https://api.member.pixelcard.co/api/fiuu/cancel?order_uuid=';
const callbackUrl = process.env.REACT_APP_FIUU_CALLBACK_URL || ' https://api.member.pixelcard.co/api/fiuu/callback';

interface openPaymentGatewayProps {
  orderUUID: string,
  merchantId: string,
  channel: string,
  totalAmount: string,
  orderId: string,
  billName: string,
  billEmail: string,
  billMobile: string,
  billAddr1: string,
  billAddr2: string,
  billZip: string,
  description: string,
  vcode: string,
  country: string,
  currency: string,
  langCode: string,
  timer?: string,
  timerbox?: string,
}

class FiuuHelper {
  paymentScript: HTMLScriptElement;
  jQueryScript: HTMLScriptElement;
  returnUrl: string;
  cancelUrl: string;
  callbackUrl: string;
  constructor() {
    this.jQueryScript = document.createElement('script');
    this.jQueryScript.src = jQuery;
    this.returnUrl = returnUrl;
    this.cancelUrl = cancelUrl;
    this.callbackUrl = callbackUrl;
    document.head.appendChild(this.jQueryScript);
    document.body.appendChild(this.jQueryScript);

  }

  openPaymentGateway = ({
    orderUUID,
    merchantId,
    channel,
    totalAmount,
    orderId,
    billName,
    billEmail,
    billMobile,
    billAddr1,
    billAddr2,
    billZip,
    description,
    vcode,
    country = "MY",
    currency = "USD",
    langCode = "en",
    timer = "3",
    timerbox = "#counter",
  }: openPaymentGatewayProps) => {
    try {
      this.jQueryScript.onload = () => {
        this.paymentScript = document.createElement('script');
        this.paymentScript.src = paymentGateway;

        document.head.appendChild(this.paymentScript);
        document.body.appendChild(this.paymentScript);

        const buttonElement: HTMLButtonElement = document.createElement('button');
        buttonElement.type = 'button';
        buttonElement.id = 'myPay';
        buttonElement.setAttribute('data-toggle', 'molpayseamless');
        buttonElement.setAttribute('data-mpsmerchantid', merchantId);
        buttonElement.setAttribute('data-mpschannel', channel);
        buttonElement.setAttribute('data-mpsamount', totalAmount);
        buttonElement.setAttribute('data-mpsorderid', orderId);
        buttonElement.setAttribute('data-mpsbill_name', billName);
        buttonElement.setAttribute('data-mpsbill_email', billEmail);
        buttonElement.setAttribute('data-mpsbill_mobile', billMobile);
        buttonElement.setAttribute('data-mpsbill_desc', description);
        buttonElement.setAttribute('data-mpscountry', country);
        buttonElement.setAttribute('data-mpscurrency', currency);
        buttonElement.setAttribute('data-mpsvcode', vcode);
        buttonElement.setAttribute('data-mpslangcode', 'en');
        buttonElement.setAttribute('data-mpstimer', timer);
        buttonElement.setAttribute('data-mpstimerbox', timerbox);
        buttonElement.setAttribute('data-mpsbill_add', billAddr1 + ', ' + billAddr2);
        buttonElement.setAttribute('data-mpsbill_zip', billZip);
        buttonElement.setAttribute('data-mpscancelurl', this.cancelUrl + orderUUID);
        buttonElement.setAttribute('data-mpsreturnurl', this.returnUrl + orderUUID);
        buttonElement.setAttribute('data-mpsapiversion', '3.28');
        buttonElement.setAttribute('data-mpscallbackurl', this.callbackUrl);
        buttonElement.setAttribute('data-non_3ds', '0');
        buttonElement.setAttribute('data-mpstokenstatus', '1');
        buttonElement.innerText = 'Pay';

        document.body.appendChild(buttonElement);
        this.paymentScript.onload = () => {
          const myPayButton = document.getElementById('myPay') as HTMLButtonElement;
          if (myPayButton) {
            myPayButton.click();
            document.getElementById('myPay')?.remove();
          }
        };
      };
    } catch (error) {
      new Promise((resolve, reject) => {
        orderApi.fail(orderUUID)
          .then(response => resolve(response.data))
          .catch(error => reject(error.response.data));
      })
    }
  }
}

export default FiuuHelper