import axios from 'axios';

interface HistoryBody {
    status?: string; // completed | declined
    profile_uuid?: string;
    type?: string; // requester | respondent
    order?: string; // asc | desc
}

const getHistory = (requestBody: HistoryBody) => {
  const storedUser = localStorage.getItem('user');

  if (storedUser === null) {
    return Promise.resolve({
      code: 404,
      data: null,
      message: 'Please log in',
      success: false,
    });
  }

  const user = JSON.parse(storedUser || '{}')
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return axios.post('/appointment/getHistory', requestBody);
}

export default getHistory;