import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  profiles: [],
  isConfirmed: false,
  isFullWidth: false,
  action: null
};

const selectProfileDialogSlice = createSlice({
  name: 'select_profile_dialog',
  initialState,
  reducers: {
    openSelectProfileDialog: (state, action) => {
      state.open = true;
      const { payload } = action;
      state.profiles = payload;
    },
    closeSelectProfileDialog: (state) => {
      state.isConfirmed = false
      state.open = false;
      state.action = null
    },
    confirmSelectProfileDialog: (state) => {
      state.isConfirmed = true
    }
  },
});

export const { openSelectProfileDialog, closeSelectProfileDialog, confirmSelectProfileDialog } = selectProfileDialogSlice.actions;
export const selectProfileDialogOpen = (state) => state.select_profile_dialog.open;
export const selectProfileDialogReducer = selectProfileDialogSlice.reducer;