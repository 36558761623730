import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import FileHelper from "../../../../lib/helper/fileHelper";

interface ItemPreviewProps {
  formValues: any;
  children?: React.ReactNode;
  isZakeke?: boolean;
  overrideIsShowingFront?: boolean | undefined;
};

export default function ItemPreview({
  formValues,
  children,
  isZakeke = false,
  overrideIsShowingFront,
}: ItemPreviewProps) {
  const [isShowingFront, setIsShowingFront] = useState(true);
  const [frontURL, setFrontURL] = useState<string>();
  const [backURL, setBackURL] = useState<string>();
  const [sampleURL, setSampleURL] = useState<string>();

  // Get URL for thumbnail image, if needed
  useEffect(() => {
    const front_thumb = formValues.selectedVariant?.front;
    const back_thumb = formValues.selectedVariant?.back;
    const sample_thumb = formValues.selectedVariant?.sample;

    if (Boolean(front_thumb)) {
      if (front_thumb.slice(0, 5) !== 'https' && front_thumb.slice(0, 10) !== 'data:image') {
        FileHelper.urlToBase64(front_thumb).then(response => {
          setFrontURL(response.base64);
        })
      } else {
        setFrontURL(front_thumb);
      }
    }

    if (Boolean(back_thumb)) {
      if (back_thumb.slice(0, 5) !== 'https' && back_thumb.slice(0, 10) !== 'data:image') {
        FileHelper.urlToBase64(back_thumb).then(response => {
          setBackURL(response.base64);
        })
      } else {
        setBackURL(back_thumb);
      }
    }

    if (Boolean(sample_thumb)) {
      if (sample_thumb.slice(0, 5) !== 'https' && sample_thumb.slice(0, 10) !== 'data:image') {
        FileHelper.urlToBase64(sample_thumb).then(response => {
          setSampleURL(response.base64);
        })
      } else {
        setSampleURL(sample_thumb);
      }
    }
  }, [formValues]);

  return <Box sx={{ flex: 1, display: 'flex', gap: '10px' }}>
    <Box sx={{ flex: 1, display: { xs: 'none', md: 'flex' }, flexDirection: 'column', gap: '10px' }}>
      <Box
        sx={{
          width: '100%',
          height: '80px',
          minWidth: '75px',
          padding: '5px',
          borderRadius: '8px',
          border: '1px solid #C5C5C5',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            bgcolor: '#F9FAFC',
          }
        }}
        onClick={() => setIsShowingFront(true)}
      >
        <img
          src={((formValues.customization && formValues.selectedVariant?.type === 1 && formValues.selectedVariant?.sampleViewSide === 1) ? sampleURL : frontURL)}
          alt="template thumbnail"
          style={{
            borderRadius: '4px',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '80px',
          minWidth: '75px',
          padding: '5px',
          borderRadius: '8px',
          border: '1px solid #C5C5C5',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            bgcolor: '#F9FAFC',
          }
        }}
        onClick={() => setIsShowingFront(false)}
      >
        <img
          src={((formValues.customization && formValues.selectedVariant?.type === 1 && formValues.selectedVariant?.sampleViewSide === 2) ? sampleURL : backURL)}
          alt="template thumbnail"
          style={{
            borderRadius: '4px',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        />
      </Box>
    </Box>
    <Box sx={{ flex: 4, display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Box sx={{
        borderRadius: '18px',
        border: '1px solid #C5C5C5',
        bgcolor: '#F9FAFC',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
      }}>
        <Box boxShadow={5} id="card-design" sx={{
          borderRadius: '18px',
          position: 'relative',
          overflow: 'clip',
        }}>
          <img
            src={(overrideIsShowingFront ?? isShowingFront) ? ((formValues.customization && formValues.selectedVariant?.type === 1 && formValues.selectedVariant?.sampleViewSide === 1) ? sampleURL : frontURL)
              : ((formValues.customization && formValues.selectedVariant?.type === 1 && formValues.selectedVariant?.sampleViewSide === 2) ? sampleURL : backURL)}
            alt="template design"
            style={{
              display: 'block',
              maxWidth: isZakeke ? '100%' : '290px',
              maxHeight: '100%',
            }}
          />
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: '10px' }}>
        <Box
          sx={{
            width: '100%',
            height: '80px',
            minWidth: '75px',
            maxWidth: '90px',
            padding: '5px',
            borderRadius: '8px',
            border: '1px solid #C5C5C5',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
              bgcolor: '#F9FAFC',
            }
          }}
          onClick={() => setIsShowingFront(true)}
        >
          <img
            src={((formValues.customization && formValues.selectedVariant?.type === 1 && formValues.selectedVariant?.sampleViewSide === 1) ? sampleURL : frontURL)}
            alt="template thumbnail"
            style={{
              borderRadius: '4px',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '80px',
            minWidth: '75px',
            maxWidth: '90px',
            padding: '5px',
            borderRadius: '8px',
            border: '1px solid #C5C5C5',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': {
              bgcolor: '#F9FAFC',
            }
          }}
          onClick={() => setIsShowingFront(false)}
        >
          <img
            src={((formValues.customization && formValues.selectedVariant?.type === 1 && formValues.selectedVariant?.sampleViewSide === 2) ? sampleURL : backURL)}
            alt="template thumbnail"
            style={{
              borderRadius: '4px',
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </Box>
      </Box>
      {children}
    </Box>
  </Box>
}