import React from "react";
import { Dialog, DialogContent, DialogActions, IconButton, Button,Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function DialogFrame({children,open,handleSave,handleCancel,handleClose }:
  {
    children: React.ReactNode,
    open: boolean,
    handleSave: () => void,
    handleCancel: () => void,
    handleClose: () => void,  
  }) {
  return (
    <>

      <Dialog open={open} fullWidth onClose={handleClose} sx={{
        '& .MuiDialog-paper': {
          borderRadius: '25px'
        }
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '20px',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '20%'
          }}>
          <Button  onClick={handleSave} variant="contained" color="secondary">
            Submit
          </Button>
          <Button  onClick={handleCancel} variant="outlined" color="secondary">
            Cancel
          </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}