import axios from "axios";

interface Data{
  code: number,
  data:  Profile[],
  message: string | null,
  success: boolean
}

interface Profile{
  name: string,
  company_name: string,
  profile_name: string,
  profile_uuid: string,
  profile_picture: string;
  serial_number: string,
  profile_active : boolean,
  created_at : string,
  is_expired: boolean;
}

const getList = (): Promise<Data> => {
    const user = JSON.parse(localStorage.getItem('user') || "")
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${user.access_token}`;
    
    return new Promise((resolve, reject) => {
        axios.get("profile/list")
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getList;
