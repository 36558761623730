import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import icons from '../../../../assets/icons';
import defaultTiers from '../../../../lib/constants/pricing';
import SubscriptionColumn from '../../../../components/subscription/subscriptionColumn';
import * as subscriptionType from '../../../../lib/constants/subscriptionType';
const currency = process.env.REACT_APP_SUBSCRIPTION_CURRENCY;

interface PricingProps {
  tiers?: any;
  currentTier: number;
  expDate?: string;
  selectedTier: number;
  handleBack?: () => void;
  handleFree: () => void;
  handleSubscribe: (isYearly: boolean) => void;
  handleUnsubscribe: () => void;
  handleSetAutoRenew: () => void;
  handleContact: () => void;
  handleTrial: () => void;
  trialled: boolean;
}

export default function SubscriptionComponent({ tiers = defaultTiers, currentTier, expDate, selectedTier, handleBack, handleFree, handleSubscribe, handleSetAutoRenew, handleUnsubscribe, handleContact, handleTrial, trialled = false }: PricingProps) {
  const [status, setStatus] = React.useState('monthly');

  const SubscriptionColumns = ({ index, tier }) => {
    let handleSubmit;
    let buttonText;
    let onUnsubscribe;
    const variant = ['LIFESTYLE_TRIAL', 'LIFESTYLE'].includes(tier.type) ? 'contained' : 'outlined';

    if (tier.type === 'FREE_TIER') {
      // Do nothing. Let handleSubmit and buttonText remain undefined
    } else if (tier.type === 'LIFESTYLE_TRIAL' && !trialled) {
      handleSubmit = handleTrial;
      const trialDuration = process.env.REACT_APP_LIFESTYLE_TRIAL_DURATION;
      buttonText = `Start ${trialDuration} days Free Trial`;
    } else if (tier.type === 'LIFESTYLE' && trialled) {
      if (Number(currentTier) === Number(tier.id)) {
        if (Number(selectedTier) === subscriptionType.FREE_TIER) {
          handleSubmit = handleSetAutoRenew;
          buttonText = 'Turn On Auto-Renewal';
        } else {
          buttonText = 'Current Plan';
          onUnsubscribe = handleUnsubscribe;
        }
      } else {
        handleSubmit = () => handleSubscribe(status === 'yearly');
        buttonText = 'Subscribe';
      }
    } else if (tier.type === 'BUSINESS') {
      if (Number(currentTier) === Number(tier.id)) {
        buttonText = 'Current Plan';
      } else {
        handleSubmit = handleContact;
        buttonText = 'Contact Us';
      }
    } else {
      return <></>
    }

    return <SubscriptionColumn
      key={index}
      tier={tier}
      currency={currency}
      status={status}
      handleSubmit={handleSubmit}
      buttonText={buttonText}
      handleUnsubscribe={onUnsubscribe}
      variant={variant}
    />
  }

  return <>
    {handleBack !== undefined && <Button color="secondary" startIcon={<img width={12} height={12} src={icons.back_icon} alt="" />} sx={{ mb: '10px' }} onClick={handleBack}>Back</Button>}
    <Box sx={{ display: 'flex', mb: '30px' }}>
      <Typography component="h2" color="text.primary" sx={{ flex: 1, fontSize: '31px' }}>
        Discover your ideal plan
      </Typography>
      <Box sx={{ background: '#E6E6E6', borderRadius: '8px', p: '6px' }}>
        <Button sx={{ color: status === 'monthly' ? '#FECD20' : '#373736', borderRadius: '4px', background: status === 'monthly' ? '#373736' : '', mr: '10px' }} onClick={() => { setStatus('monthly') }}>Monthly</Button>
        <Button sx={{ color: status === 'monthly' ? '#373736' : '#FECD20', borderRadius: '4px', background: status === 'monthly' ? '' : '#373736' }} onClick={() => setStatus('yearly')}>Yearly</Button>
      </Box>
    </Box>
    <Container
      id="pricing"
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'stretch', flexWrap: 'wrap' }}>
        {
          status === 'monthly' ?
            tiers.month.map((tier, index) => <SubscriptionColumns index={index} tier={tier} />) :
            tiers.year.map((tier, index) => <SubscriptionColumns index={index} tier={tier} />)
        }
      </Box>
    </Container >
  </>
}