const jobTitles = [
  'Company Director',
   'Executive Board', 
   'Financial Accounting Manager', 
   'General Manager', 
   'Head of Personnel', 
   'HR Manager', 
   'IT Manager', 
   'Marketing Executive', 
   'Marketing Manager', 
   'Personal Assistant', 
   'Production Manager', 
   'Project Manager', 
   'Purchasing Manager', 
   'Quality Officer', 
   'Sales Manager', 
   'Sales Person', 
   'Others'
  ]
export default jobTitles