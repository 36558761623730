import { createSlice } from "@reduxjs/toolkit";
import { CART } from "../../lib/constants/slices";

const initialState = {
  items: [],
  open: false,
};

const cartSlice = createSlice({
  name: CART,
  initialState,
  reducers: {
    setItems(state, action) {
      state.items = action.payload;
      localStorage.setItem('cart', JSON.stringify(action.payload));
    },
    addItem(state, action) {
      let newState = [];
      if(state.items === null) {
        newState = [action.payload];
      }else{
        newState = [
          ...state.items,
          action.payload,
        ]
      }
      state.items = newState;
      localStorage.setItem('cart', JSON.stringify(newState));
    },
    removeItem(state, action) {
      const newState = [
        ...state.items.slice(0, action.payload),
        ...state.items.slice(action.payload + 1),
      ]
      state.items = newState;

      if (newState.length > 0) {
        localStorage.setItem('cart', JSON.stringify(newState));
      } else {
        localStorage.removeItem('cart');
      }
    },
    clearItems(state) {
      state.items = initialState.items;
      localStorage.removeItem('cart');
    },
    toggleCartDrawer(state) {
      state.open = !state.open;
    }
  }
});

export const { setItems, addItem, removeItem, clearItems, toggleCartDrawer } = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
