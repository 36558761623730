import { landingPageReducer } from "../../features/landing-page";
import { exchangeContactReducer } from "../../features/exchange-contact";
import { responseDialogReducer } from '../../features/response-dialog';
import { actionDialogReducer } from "../../features/action-dialog";
import { selectProfileDialogReducer } from "../../features/select-profile-dialog";
import { adminReducer } from "../../features/admin";
import { contactReducer } from "../../features/contact";
import { loadingReducer } from "../../features/loading";
import { bookAppointmentReducer } from "../../features/book-appointment";
import { cartReducer } from "../../features/cart";
import { errorDialogReducer } from "../../features/error-dialog";
import { campaignReducer } from "../../features/campaign/campaign.slice";
import { appointmentsReducer } from "../../features/appointments/appointments.slice";
import { accessLimitReducer } from "../../features/access-limit/accessLimit.slice";
import { notificationsReducer } from "../../features/notifications";
import { navReducer } from "../../features/nav";

const allReducer = {
  access: accessLimitReducer,
  landingPage: landingPageReducer,
  exchangeContact: exchangeContactReducer,
  appointments: appointmentsReducer,
  bookAppointment: bookAppointmentReducer,
  notifications: notificationsReducer,
  cart: cartReducer,
  responseDialog: responseDialogReducer,
  actionDialog: actionDialogReducer,
  selectProfileDialog: selectProfileDialogReducer,
  admin: adminReducer,
  contact: contactReducer,
  loading: loadingReducer,
  errorDialog: errorDialogReducer,
  campaign: campaignReducer,
  nav: navReducer,
};

export default allReducer;
