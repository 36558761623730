import React, {useState, useEffect} from "react";
import { Box, Typography, TextField, Select, MenuItem } from "@mui/material";
import {SELF_VALIDATION,NO_VALIDATION}  from "../../../../../lib/constants/voucherValidationType";
// import { QR_BAR_CODE, CODE } from "../../../../../lib/constants/voucherStyle";
import { AMOUNT,PERCENTAGE,OTHER } from "../../../../../lib/constants/voucherDiscountType";
import { SINGLE, MULTIPLE } from "../../../../../lib/constants/voucherCodeType";
import FormBox from "../../../component/formBox";
import CheckCard from "../../../component/checkCard";
import "flatpickr/dist/themes/light.css";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import {  useTheme } from '@mui/material/styles';
// temporay not in use, left it just in case
// 2024-Jan-30
// function DiscountType({type}:{type :string}){
//   const RenderDsicountType = ({type}:{type: string}) => { 
//     switch(type){
//       case  "Amount":
//         return (
//           <>
//           <Typography sx={{pt:"0.5rem",pb:"0.5rem"}}>Discount Amount </Typography>
//           <Box sx={{display:"grid", gridTemplateColumns:"1fr 4fr", gap:"1rem"}}>
//             <Select>
//               <MenuItem selected>MYR (RM)</MenuItem>
//             </Select>  
//             <TextField type="number" fullWidth placeholder="Enter discount value"></TextField>  
//           </Box>
//           </>
//         )
//       case "Percentage":
//         return(
//           <>
//           <Typography sx={{pt:"0.5rem",pb:"0.5rem"}}>Discount Pecentage </Typography>
//           <Box sx={{display:"flex",gap:"1rem"}}>
//           <TextField type="text" disabled fullWidth value="%"  sx={{width:"70px"}}> </TextField>  
//           <TextField type="number" fullWidth placeholder="Enter discount value"></TextField>  
//           </Box>
//           </>
//         )
//       case "Other":
//         return (
//           <>
//           <Typography sx={{pt:"0.5rem",pb:"0.5rem"}}>Discount name </Typography>
//           <TextField type="number" fullWidth placeholder=""></TextField>  
//           </>
//         )
//       default:
//         return null
//     }
//   }
//   return <RenderDsicountType type={type}></RenderDsicountType>

// }

interface VoucherTypeForm{
  validationType: number,
  discountType: string,
  codeType: string,
  styleType: string,
  limitPerUser: number,
  startDateTime: string,
  endDateTime: string,
  collectionStartDate: string, 
  collectionEndDate: string,
}

export default function VoucherType({values,errors,handleChange,isEdit}:{values:VoucherTypeForm,errors:any,handleChange:any,isEdit:boolean}) {
  const theme = useTheme();
  const [validationTypeState, setValidationTypeState] = useState<number>(SELF_VALIDATION); 
  const [codeTypeState, setCodeTypeState] = useState<string>(SINGLE); // not in use yet
  const handleValidationType = (type:number) => {
    values.validationType = type;
    setValidationTypeState(type); // using state to force realtime render lol
  }


  const handleCodeType = (type: string) => {
    values.codeType = type;
    setCodeTypeState(type);
  }
  useEffect(() => {
    // for edit onload to get the type
     setValidationTypeState(values.validationType); 
  }, [values.validationType]) 

  useEffect(() => {
    // for edit onload to get the type
    setCodeTypeState(values.codeType);
  }, [values.codeType])
  return <>
      {
      !isEdit ?
      <Box
        sx={{ 
        mb:"0.5rem",
        padding: '2rem 2rem 2rem 2rem',
        borderRadius:"8px",
        background:"#FFF",
        border: '1px solid #C5C5C5'
        }}
      >
        <Typography sx={{pb:"0.5rem"}} variant="h3">Validation Type</Typography>
        <Box sx={{
          display: "flex",
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '1rem',
        }}>
        <CheckCard 
          id="validation_type_self"
          title='Self Validation'
          description="This voucher requires validation through QR scanning and Pixel login"
          background="#EEEEE4"
          checked={validationTypeState === SELF_VALIDATION}
          onClick={() => handleValidationType(SELF_VALIDATION)}
        />
        <CheckCard
          id="validation_type_no"
          title='Third-Party Validation'
          description="This voucher requires validation through a third-party"
          background="#EEEEE4"
          checked={validationTypeState === NO_VALIDATION}
          onClick={() => handleValidationType(NO_VALIDATION)}
        />
        </Box>
      </Box>
      : null  
      }
      {
        !isEdit ?  <FormBox>
        <Typography variant="h3" sx={{pb:'1rem'}}>Code Type</Typography>
          <Box sx={{
            display: "flex",
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '1rem',
          }}>
            <CheckCard
              id="code_type_single"
              title='Single Code'
              description="1 code for unlimited used until period time end" 
              background="#D8E0FF"
              checked={codeTypeState === SINGLE ? true : false}
              onClick={() => handleCodeType(SINGLE)}/>
            <CheckCard
              id="code_type_multiple"
              title='Multiple Code'
              description="1 code only for 1 time user" 
              background="#D8E0FF"
              checked={codeTypeState === MULTIPLE ? true : false}
              onClick={() => handleCodeType(MULTIPLE)}
            />
          </Box>
        </FormBox> : null
      }
     
      <Box
      sx={{ 
      padding: '2rem 2rem 2rem 2rem',
      borderRadius:"8px",
      background:"#FFF",
      border: '1px solid #C5C5C5'
      }}
      >

      <Typography sx={{pb:"0.5rem"}}>Discount Type </Typography>
      <Select id="voucher_type_select" value={values.discountType} name="voucherType.discountType" type="text" fullWidth onChange={handleChange} >
        <MenuItem value={AMOUNT}>Amount</MenuItem>
        <MenuItem value={PERCENTAGE}>Percentage</MenuItem>
        <MenuItem value={OTHER}>Add Discount Type</MenuItem>
      </Select>
      {
        errors?.discountType ? <Typography sx={{color:"red"}}>{errors.discountType}</Typography> : null
      }
      {/* No longer in use <DiscountType type={discountType}></DiscountType> */}
      {/* <Typography sx={{pb:"0.5rem", pt:"0.5rem"}}>Style Type </Typography>
      <Select id="style_type_select" value={values.styleType} name="voucherType.styleType" type="text" fullWidth onChange={handleChange} >
        <MenuItem value={QR_BAR_CODE}>QR Code & Bar Code</MenuItem>
        <MenuItem value={CODE}>Show code</MenuItem>
      </Select> */}
      {/* {
        errors?.styleType ? <Typography sx={{color:"red"}}>{errors.styleType}</Typography> : null
      } */}
      <Typography sx={{pb:"0.5rem", pt:"0.5rem"}}>Collection Limitation Per User </Typography>
      <TextField id="limit_per_user" type="number" name="voucherType.limitPerUser"
        value={values.limitPerUser} fullWidth placeholder="Amount Limit" onChange={handleChange}></TextField>
      {
        errors?.limitPerUser ? <Typography sx={{color:"red"}}>{errors.limitPerUser}</Typography> : null
      }
      <Typography sx={{pt:"0.5rem"}}>Collection Period </Typography>
      <Box sx={{
        [theme.breakpoints.down('md')]: {
          display:"flex",gap:'1rem',
          flexDirection:"column"
        },
        [theme.breakpoints.up('md')]: {
          display:"grid",
          gap:"1rem",
          gridTemplateColumns:"1fr 1fr",
        }
      }}>
        <Box>
        <Typography sx={{pb:"0.5rem", pt:"0.5rem"}}>Start Period (Date/Time) </Typography>
        <Flatpickr
          id="collection_start_date"
          style={{
            height: '40px',
            width: '100%',
            borderRadius: '4px',
            backgroundColor: 'white',
            color: 'black',
          }}
          data-enable-time
          value={values.collectionStartDate}
          onChange={(date) => {
            values.collectionStartDate = moment(date[0]).format("YYYY-MM-DD HH:mm:ss");
            handleChange({target: {name: "voucherType.collectionStartDate", value: values.collectionStartDate}})
          }}/>
        {
          errors?.collectionStartDate ? <Typography sx={{color:"red"}}>{errors.collectionStartDate}</Typography> : null
        }

        </Box>
        <Box>
        <Typography sx={{pb:"0.5rem", pt:"0.5rem"}}>End Period (Date/Time)</Typography>
        <Flatpickr
          id="collection_end_date"
          style={{
            height: '40px',
            width: '100%',
            borderRadius: '4px',
            backgroundColor: 'white',
            color: 'black',
          }}
          data-enable-time
          value={values.collectionEndDate}
          onChange={(date) => {
            values.collectionEndDate = moment(date[0]).format("YYYY-MM-DD HH:mm:ss");
            handleChange({target: {name: "voucherType.collectionEndDate", value: values.collectionEndDate}})
          }}/>

        {
          errors?.collectionEndDate ? <Typography sx={{color:"red"}}>{errors.collectionEndDate}</Typography> : null
        }
        </Box>
      </Box>

      <Typography sx={{pt:"0.5rem"}}>Redemption Period </Typography>
      <Box sx={{
        [theme.breakpoints.down('md')]: {
          display:"flex",gap:'1rem',
          flexDirection:"column"
        },
        [theme.breakpoints.up('md')]: {
          display:"grid",
          gap:"1rem",
          gridTemplateColumns:"1fr 1fr",
        }
      }}>
      <Box>
      <Typography sx={{pb:"0.5rem", pt:"0.5rem"}}>Start Period (Date/Time) </Typography>
      <Flatpickr
        id="start_date_time"
        style={{
          height: '40px',
          width: '100%',
          borderRadius: '4px',
          backgroundColor: 'white',
          color: 'black',
        }}
        data-enable-time
        value={values.startDateTime}
        onChange={(date) => {
          values.startDateTime = moment(date[0]).format("YYYY-MM-DD HH:mm:ss");
          handleChange({target: {name: "voucherType.startDateTime", value: values.startDateTime}})
        }}/>
      {/* <TextField type="date" value={values.startDateTime} name="voucherType.startDateTime" fullWidth onChange={handleChange}></TextField> */}
      {
        errors?.startDateTime ? <Typography sx={{color:"red"}}>{errors.startDateTime}</Typography> : null
      }

      </Box>
      <Box>
      <Typography sx={{pb:"0.5rem", pt:"0.5rem"}}>End Period (Date/Time)</Typography>
      <Flatpickr
        id="end_date_time"
        style={{
          height: '40px',
          width: '100%',
          borderRadius: '4px',
          backgroundColor: 'white',
          color: 'black',
        }}
        data-enable-time
        value={values.endDateTime}
        onChange={(date) => {
          values.endDateTime = moment(date[0]).format("YYYY-MM-DD HH:mm:ss");
          handleChange({target: {name: "voucherType.endDateTime", value: values.endDateTime}})
        }}/>

      {/* <TextField type="date" value={values.endDateTime} name="voucherType.endDateTime" fullWidth onChange={handleChange}></TextField> */}
      {
        errors?.endDateTime ? <Typography sx={{color:"red"}}>{errors.endDateTime}</Typography> : null
      }
      </Box>
      </Box>
      </Box>
  </>
}