import React, { useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux'
import basicAuthApi from '../../../../api/basicauth'
import "react-phone-input-2/lib/bootstrap.css";
import { openResponseDialog } from '../../../../features/response-dialog';
import { useDispatch } from 'react-redux';
import { CHECK_INBOX } from '../../../../lib/constants/routes';
import { useParams } from 'react-router-dom';
import SignUpComponent from '../sign-up/signUp.component';
import moment from 'moment';
import { Box } from '@mui/material';
const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

export default function VoucherSignUp() {
  const navigate = useNavigate();
  const { voucherUUID } = useParams();
  const dispatch = useDispatch();
  const guestRequest = useSelector((state: any) => state.exchangeContact.guestRequest)
  const profile = useSelector((state: any) => state.landingPage.profile)

  const pickerToString = ({ day, month, year }) => {
    return `${month}/${day}/${year}`;
  }

  useEffect(() => {
    if (guestRequest) {
      // setPhoneNumber(guestRequest?.phoneNumber)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (values) => {
    let dateString = pickerToString(values.datePickerValue);
    if (!moment(dateString, "MM/DD/YYYY", true).isValid()) {
      dateString = '';
    }
    const body = {
      email: values.email || "",
      name: values.name,
      phone_code: values.phoneCode,
      phone_number: values.phoneNumber || "",
      referral_code: values.referralCode,
      birthday: moment(dateString).format('YYYY-MM-DD'),
      password: values.password,
      confirm_password: values.confirmpw,
      owner_profile_uuid: profile?.profile_uuid || "",
      voucher_uuid: voucherUUID || "",
    };

    await basicAuthApi.voucherSignup({ requestBody: body }).then(response => {
      if (response?.success) {
        localStorage.setItem("user", JSON.stringify(response.data));
        navigate(CHECK_INBOX);
        return
      } else {
        dispatch(openResponseDialog({ title: "Registration failed", description: response.message }))
      }
    })
      .catch(error => {
        dispatch(openResponseDialog({ title: "Registration failed", description: error.message }))
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{
        background: "#FECD20",
      }}>
        <SignUpComponent handleSubmit={handleSubmit} />
      </Box>
    </ThemeProvider>
  );
}
