import React from "react";
import { Container, CssBaseline, Paper, Box, Typography, TextField, IconButton, Button, Link } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import icons from '../../../../assets/icons';
import { styled } from '@mui/system';
import images from "../../../../assets/img";
import { Formik } from "formik";
import { SIGN_IN } from "../../../../lib/constants/routes.js";
import useQuery  from '../../../../lib/helper/queryHelper';

const PasswordValidation = styled('div')({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'row',
  alignItems: 'flex-start'
});

export default function SignUpComponent({ handleSubmit }) {
  const query = useQuery();
  const referralCode: string = query.get('referral_code') ?? '';
  const [step, setStep] = React.useState(0);
  const [showPassword, setShowPassword] = React.useState(false);
  const [rtValidation, setRtValidation] = React.useState(false);

  return <Formik
    validateOnChange={rtValidation}
    validateOnBlur={rtValidation}
    initialValues={{
      email: '',
      password: '',
      confirmpw: '',
      datePickerValue: { day: '', month: '', year: '' },
      referralCode,
      cond1: false,
      cond2: false,
      cond3: false,
      cond4: false,
    }}
    validate={values => {
      const errors: any = {};

      // Validate email
      if (!values.email) {
        errors.email = 'Required';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = 'Invalid email address';
      }

      // Validate password
      if (values.password.length <= 8) {
        errors.cond1 = 'Password must be over 8 characters';
      }

      if (values.password.match(/\d/g) === null) {
        errors.cond2 = 'Password must contain 1 number, at least';
      }

      if (values.password.match(/[!@#$%^&*()\-_=+]/g) === null) {
        errors.cond3 = 'Pasword must contain 1 special character, at least';
      }

      if (values.password.match(/(.*[A-Z].*[a-z].*)|(.*[a-z].*[A-Z].*)/g) === null) {
        errors.cond4 = 'Password must contain at least 1 upper case letter and at least 1 lower case letter';
      }

      // Validate password confirmation
      if (values.confirmpw !== values.password) {
        errors.confirmpw = 'Password does not match';
      }

      return errors;
    }}
    onSubmit={(values, { setSubmitting }) => {
      handleSubmit(values).then(() => setSubmitting(false));
    }}
  >
    {({
      values,
      touched,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      validateForm,
      setFieldValue,
    }) => (
      <Container component="main" maxWidth="xs" style={{
        width: '336px',
        height: '100vh',
        display: 'grid',
        alignContent: 'space-around',
        justifyContent: 'center',

      }}>
        <CssBaseline />
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1.3rem',
            borderRadius: '8.78px',
            width: "inherit"
          }}
        >
          <div style={{ margin: '20px' }}>
            <img src={images.pxlicon.toString()} style={{ maxWidth: '133px', maxHeight: "33" }} alt="Pixel Logo" />
          </div>
          <Typography component="h1" variant="h4">
            Let's get started
          </Typography>
          <Typography component="h2" variant="h5">
            {step === 0 ? 'Personal info' : 'Create password'}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <div style={step === 0 ? { display: 'flex', flexDirection: 'column', gap: '10px' } : { display: 'none' }}>
              <Box>
                <Typography component='label' htmlFor='email' className='required' variant='h5'>
                  Email
                </Typography>
                <TextField
                  error={Boolean(errors.email)}
                  color='secondary'
                  margin="dense"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography component='label' className='validation-error' variant='h5'>
                  {errors.email}
                </Typography>
              </Box>
              <Box>
                <Typography component='label' htmlFor='referralCode' variant='h5'>
                  Referral code
                </Typography>
                <TextField
                  color='secondary'
                  margin="dense"
                  value={values.referralCode}
                  fullWidth
                  id="referralCode"
                  name="referralCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>
            </div>
            <div style={{ display: step === 1 ? 'block' : 'none' }}>
              <Typography component='label' className='required' variant='h5'>
                Password
              </Typography>
              <TextField
                color='secondary'
                margin="dense"
                required
                fullWidth
                name="password"
                type={showPassword ? "text" : "password"}
                id="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: <IconButton sx={{ opacity: 0.5 }} aria-label="show" size="small" onClick={() => setShowPassword(!showPassword)}>
                    <img src={showPassword ? icons.eye_close : icons.eye_open_icon} alt="eye_open_icon" />
                  </IconButton>,
                }}
              />

              <Box style={{ padding: '1rem 0' }}>
                <PasswordValidation>
                  {errors.cond1 ? <img src={icons.check_incorrect.toString()} alt="incorrect" /> : <img src={icons.check_correct.toString()} alt="correct" />}
                  <Typography component='p' variant='h5'>
                    Password must be over 8 characters
                  </Typography>
                </PasswordValidation>
                <PasswordValidation>
                  {errors.cond2 ? <img src={icons.check_incorrect.toString()} alt="incorrect" /> : <img src={icons.check_correct.toString()} alt="correct" />}
                  <Typography component='p' variant='h5'>
                    Password must contain 1 number
                  </Typography>
                </PasswordValidation>
                <PasswordValidation>
                  {errors.cond3 ? <img src={icons.check_incorrect.toString()} alt="incorrect" /> : <img src={icons.check_correct.toString()} alt="correct" />}
                  <Typography component='p' variant='h5'>
                    Password must contain 1 special character
                  </Typography>
                </PasswordValidation>
                <PasswordValidation>
                  {errors.cond4 ? <img src={icons.check_incorrect.toString()} alt="incorrect" /> : <img src={icons.check_correct.toString()} alt="correct" />}
                  <Typography component='p' variant='h5'>
                    Password must contain 1 upper case and 1 lower case letter
                  </Typography>
                </PasswordValidation>
              </Box>
              <Typography component='label' className='required' variant='h5'>
                Confirm Password
              </Typography>
              <TextField
                error={Boolean(errors.confirmpw && touched.confirmpw && errors.confirmpw)}
                color='secondary'
                margin="dense"
                required
                fullWidth
                name="confirmpw"
                type={showPassword ? "text" : "password"}
                id="confirmpw"
                InputProps={{
                  endAdornment: <IconButton sx={{ opacity: 0.5 }} aria-label="show" size="small" onClick={() => setShowPassword(!showPassword)}>
                    <img src={showPassword ? icons.eye_close : icons.eye_open_icon} alt="eye_open_icon" />
                  </IconButton>,
                }}
                value={values.confirmpw}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Typography component='label' className='validation-error' variant='h5'>
                {errors.confirmpw && touched.confirmpw && errors.confirmpw}
              </Typography>
            </div>

            {step === 0 && <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: '30px', color: '#FECD20' }}
              onClick={() => {
                validateForm().then((e) => {
                  if (!Boolean(e.email)) {
                    setRtValidation(true);
                    setStep(1);
                  }
                });
              }}
            >
              Continue
            </Button>}

            {step === 1 && <>
              <LoadingButton
                id="signin-button"
                type="submit"
                fullWidth
                variant="contained"
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={isSubmitting ? <div /> : undefined}
                sx={{ mt: 3, mb: 1, borderRadius: '30px', color: '#FECD20' }}
              >
                <span>{isSubmitting ? 'Verifying' : 'Continue'}</span>
              </LoadingButton>
              <Button
                color="primary"
                variant="outlined"
                fullWidth
                startIcon={<img width={12} height={12} src={icons.back_icon} alt="" />}
                sx={{ borderRadius: '30px', mb: 2 }}
                onClick={() => setStep(0)}
              >
                Back
              </Button>
            </>}

            {step === 0 && <Typography variant="body1" align="center">
              Already have an account?&nbsp;
              <Link underline="hover" sx={{ color: "#007AFF" }} href={SIGN_IN}>
                Sign in
              </Link>
            </Typography>}
            {step === 1 && <Typography paddingX={'30px'} textAlign={'center'} fontSize="10.5px" >
              By signing up, you agree to PIXEL’s
              <span style={{ color: "#007AFF" }} onClick={() => window.open(process.env.REACT_APP_PRIVACY_POLICY)}> Privacy Policy </span>and
              <span style={{ color: "#007AFF" }} onClick={() => window.open(process.env.REACT_APP_TERM_OF_SERVICE)}> Terms of Service. </span> We will never disclose your personal information to 3rd parties.
            </Typography>}
          </Box>
        </Paper>
      </Container>
    )}
  </Formik>
}
