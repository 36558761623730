import { Typography, Box, Button } from '@mui/material';
import React from 'react';
import moment from 'moment';
import CardInfo from './component/cardInfo';
import { ORDER_START, ORDER_PENDING, ORDER_READY_TO_SHIP, ORDER_SHIPPING, ORDER_COMPLETED, ORDER_CANCELLED } from '../../../../lib/constants/orderStatus';
import { PAYMENT_PROCESSING, PAYMENT_PAID } from '../../../../lib/constants/paymentStatus';
import Detail from './detail';
import { ListOrder } from '../../../../lib/types/Order';

export default function OrderComponent({ orderList, handlePayNow }: { orderList: ListOrder[], handlePayNow: (order) => void }) {
  const [orderUUID, setOrderUUID] = React.useState('');

  return Boolean(orderUUID) ? <Detail handleView={() => setOrderUUID('')} orderUUID={orderUUID} /> : <>
    <Box sx={{ mb: '1rem', containerType: 'inline-size' }}>
      <Typography variant="h2" fontWeight="400">All Orders</Typography>
    </Box>

    {orderList.map((order: any) => {
      let status_color = 'red';
      let status = 'Unpaid';
      let is_paid = false;

      if ([ORDER_START, ORDER_PENDING].includes(order.status) && order.payment_status === PAYMENT_PROCESSING) {
        status_color = 'orange';
        status = 'Payment Processing';
        is_paid = true;
      } else if ([ORDER_START, ORDER_PENDING].includes(order.status) && order.payment_status === PAYMENT_PAID) {
        status_color = 'orange';
        status = 'Processing';
        is_paid = true;
      } else if (order.status === ORDER_READY_TO_SHIP) {
        status_color = 'green';
        status = 'Processing';
        is_paid = true;
      } else if (order.status === ORDER_SHIPPING) {
        status_color = 'green';
        status = 'Shipping';
        is_paid = true;
      } else if (order.status === ORDER_COMPLETED) {
        status_color = 'green';
        status = 'Delivered';
        is_paid = true;
      } else if (order.status === ORDER_CANCELLED) {
        status_color = 'red';
        status = 'Cancelled';
        is_paid = true;
      }

      return <Box key={order.order_uuid} sx={{
        containerType: 'inline-size',
        padding: '2rem 2rem 2rem 2rem',
        borderRadius: "8px",
        background: "#FFF",
        border: '1px solid #C5C5C5',
        mb: "1rem"
      }}>
        <Box sx={{
          display: "flex",
          gap: "30px",
          "@container (max-width: 880px)": {
            flexDirection: "column",
          },
        }}>
          <Box sx={{ display: "flex", justifyContent: 'space-between', flex: order?.products.length > 0 ? undefined : 1 }}>
            <Box sx={{
              width: '100%',
              "@container (max-width: 880px)": {
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              },
            }}>
              <Box>
                <Typography component={'span'} sx={{ fontSize: '13px' }}>Order ID</Typography>
                <Typography sx={{
                  fontSize: '20px',
                  fontWeight: 500,
                  "@container (max-width: 880px)": {
                    display: 'inline',
                    fontSize: '13px',
                    marginLeft: '5px',
                  },
                }}>{order?.order_ref_id}</Typography>
                <Typography sx={{ fontSize: '13px', pb: '5px' }}>Order Date: {moment(order.created_at).format('YYYY-MM-DD')}</Typography>
              </Box>

              <Button sx={{ height: 'fit-content' }} size='small' variant="contained" color="primary" onClick={() => setOrderUUID(order.order_uuid)}>View Order</Button>
            </Box>
          </Box>
          {order?.products.length > 0 && <Box sx={{
            flex: 1,
            borderLeft: '1px solid #A7A7A7',
            borderRight: '1px solid #A7A7A7',
            pb: '1rem',
            "@container (max-width: 880px)": {
              borderLeft: 'none',
              borderRight: 'none',
              borderTop: '1px solid #A7A7A7',
              borderBottom: '1px solid #A7A7A7',
              py: '30px',
            },
          }}>
            <CardInfo order={order} />
          </Box>}
          <Box>
            <Typography sx={{ color: status_color, fontWeight: '500' }}>{status}</Typography>
            <Typography sx={{ fontSize: '13px', pb: '5px' }}>Status Updated: {moment(order.updated_at ?? order.created_at).format('YYYY-MM-DD hh:mm')}</Typography>
            {!is_paid && <Button variant="contained" color="secondary" onClick={() => handlePayNow(order.order_uuid)}> Pay Now </Button>}
            {/* <Button variant="contained" color="secondary" onClick={() => setOrderUUID(order.order_uuid)}> Track Package </Button> */}
          </Box>
        </Box>
      </Box>
    })}
  </>
}