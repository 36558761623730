import axios from "axios";

interface Data{
  code: number,
  data:  any,
  message: string | null,
  success: boolean
}

const add = ({campaignName} : {campaignName: string}): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;
  
  return new Promise((resolve, reject) => {
      axios.post("campaign/add",{campaign_name: campaignName })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default add;
