import React from 'react';
import { Autocomplete, Box, TextField, createFilterOptions } from '@mui/material';
import countries from '../../lib/constants/countries';
import icons from 'src/assets/icons';

const defaultPhoneValue = countries.filter(c => c.label === 'Malaysia')[0]

interface CountryType {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
}

const filterOptions = () => createFilterOptions({
  stringify: (option: CountryType) => option.label + option.code + option.phone,
});

export interface PhoneInputProps {
  value: string;
  code: string | null;
  disabled: boolean;
  setVal: (code: string | undefined, number: string | undefined) => void;
  defaultValue?: CountryType
}

export default function PhoneInputComponent({ value,code = "+60", disabled = false, setVal, defaultValue } : PhoneInputProps) {

  return (
    <React.Fragment>
      <Autocomplete
        componentsProps={{ popper: { style: { width: 'fit-content' } } }}
        id="country-select-demo"
        sx={{ width: 110 }}
        value={countries.find((country) => country.phone === code)}
        options={countries}
        autoHighlight
        disableClearable
        clearOnBlur
        popupIcon={<img src={icons.arrow_down_01_icon as unknown as string} alt='Open dropdown' />}
        getOptionLabel={option => option.phone}
        filterOptions={filterOptions()}
        defaultValue={defaultValue || defaultPhoneValue}
        onChange={(event: any, newValue: any | null) => setVal(newValue.phone, undefined)}
        renderOption={(props, option: any) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.code}>
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.label} ({option.code}) {option.phone}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            color='secondary'
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            sx={{ background: 'white' }}
          />
        )}
      />
      <TextField
        id="phone_number"
        color='secondary'
        required
        value={value}
        type='tel'
        onChange={e => setVal(undefined, e.target.value)}
        disabled={disabled}
        sx={{ flex: 1, ml: '10px', background: 'white' }}
      />
    </React.Fragment>
  );
}