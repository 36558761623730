import React, { useState } from "react";
import CampaignDetailComponent from "./campaignDetail.component";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setBreadcrumb } from "../../../../features/admin";
import campaignApi from "../../../../api/campaign";
import { useParams } from "react-router-dom";
import { openResponseDialog } from "../../../../features/response-dialog";
import { openErrorDialog } from "../../../../features/error-dialog";
import { startLoading, endLoading } from "../../../../features/loading";
import { VoucherList } from "../../../../lib/types/Voucher";
import { PORTAL, CAMPAIGN, PORTAL_CAMPAIGNS, PORTAL_CAMPAIGN_DETAIL } from "../../../../lib/constants/routes";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setCampaignName as setCampaignNameRedux } from "../../../../features/campaign/campaign.slice";
import auth from "../../../../api/auth";
import { openActionDialog } from "../../../../features/action-dialog";

export default function CampaignDetailContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isConfirmed = useSelector<any>(state => state.actionDialog.isConfirmed);
  const dialogAction = useSelector<any>(state => state.actionDialog.action);
  const campaignNameRedux = useSelector((state: any) => state.campaign.name);
  const [selectedVoucherUuid, setSelectedVoucherUuid] = useState<string>('');
  const [voucherList, setVoucherList] = useState([] as Array<VoucherList>);
  const [campaignName, setCampaignName] = useState(campaignNameRedux);
  const [accessRights, setAccessRights] = React.useState<string[]>([]);
  const { id } = useParams();

  const refreshList = async ({ campaignUUID }) => {
    dispatch(startLoading())
    await campaignApi.getVoucherListByCampaign({ campaignUUID: campaignUUID })
      .then(response => {
        if (response.success) {
          setVoucherList(response.data)
        } else {
          dispatch(openErrorDialog({
            title: "Get Voucher list Error",
            description: response.message
          }))
        }
      })
      .catch(error => {
        dispatch(openErrorDialog({
          title: "Get Voucher list Error",
          description: error.message
        }))
      }).finally(() => {
        dispatch(endLoading())
      })
  }

  const updateCampaign = async (campaignName: string) => {
    await campaignApi.update({ campaignUUID: id || "", campaignName: campaignName }).then(response => {
      if (response.success) {
        dispatch(openResponseDialog({
          title: "Update Campaign",
          description: response.message
        }))
        dispatch(setCampaignNameRedux(campaignName));
        navigate(PORTAL_CAMPAIGN_DETAIL.replace(":id", `${id}`) + `?campaignName=${campaignName}`);
      }
    }).catch(error => {
      dispatch(openErrorDialog({
        title: "Update Campaign Error",
        description: error.message
      }))
    }).finally(() => {
      dispatch(endLoading())
    })
  }

  const handleStatus = async (voucherUUID: string, active: boolean) => {
    dispatch(startLoading());
    await campaignApi.editVoucherStatus({ voucherUUID, active }).then((res) => {
      if (res.success) {
        refreshList({ campaignUUID: id })
      } else {
        dispatch(openErrorDialog({
          title: "Error",
          description: res.message,
        }))
      }
    }).catch((err) => {
      dispatch(openErrorDialog({
        title: "Error",
        description: err.message,
      }))
    }).finally(() => {
      dispatch(endLoading());
    })
  }

  const handleDelete = async (voucherUUID: string) => {
    setSelectedVoucherUuid(voucherUUID);
    dispatch(openActionDialog({
      title: "Delete Voucher?",
      description: "Are you sure you want to delete this voucher?",
      action: 'deleteVoucher',
    }));
  }

  useEffect(() => {
    if (isConfirmed && dialogAction === "deleteVoucher") {
      dispatch(startLoading());
      campaignApi.deleteVoucher({ voucherUUID: selectedVoucherUuid }).then((res) => {
        if (res.success) {
          dispatch(openResponseDialog({ title: "Delete Success", description: "Voucher has been successfully deleted" }))
          refreshList({ campaignUUID: id })
        } else {
          dispatch(openErrorDialog({
            title: "Error",
            description: res.message,
          }))
        }
      }).catch((err) => {
        dispatch(openErrorDialog({
          title: "Error",
          description: err.message,
        }))
      }).finally(() => {
        dispatch(endLoading());
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed, dialogAction])

  const handleNameChange = async (campaignName: string) => {
    setCampaignName(campaignName)
  }
  useEffect(() => {
    auth.getAccessLimit().then(value => {
      setAccessRights(value.data.access_right);
    });
    dispatch(setBreadcrumb({
      breadcrumb: [
        {
          name: "Campaign",
          path: PORTAL_CAMPAIGNS
        }, {
          name: "Campaign List",
          path: PORTAL + "/" + CAMPAIGN + "/" + id
        }, {
          name: "Campaign Detail",
          path: null
        }
      ]
    }))
    refreshList({ campaignUUID: id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <CampaignDetailComponent
    campaignName={campaignName}
    accessRights={accessRights}
    handleNameChange={handleNameChange}
    voucherList={voucherList}
    updateCampaign={updateCampaign}
    handleStatus={handleStatus}
    handleDelete={handleDelete}
  />
}