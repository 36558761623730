import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography, MenuItem, Button, Menu, Card, Avatar, Chip, ListItemIcon, ListItemText, IconButton } from "@mui/material";
import icons from '../../../../assets/icons';
import profileHelper from '../../../../lib/helper/profileHelper';
import ShareProfileDialog from './component/shareProfileDialog.tsx';
import { useNavigate } from 'react-router-dom';
import { PORTAL_PROFILE } from '../../../../lib/constants/routes.js';
import moment from 'moment';
import Placeholder from '../../../../components/placeholder/placeholder.tsx';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const chipStyle = {
  borderRadius: '30px',
  height: '100%',
  py: '2px',
  fontSize: 13,
  fontWeight: 500,
  textTransform: 'capitalize',
};
const activeChipStyle = {
  ...chipStyle,
  background: '#E7F6EA',
  color: '#0BA02C',
  border: '1px solid #47BB68',
};
const deactivatedChipStyle = {
  ...chipStyle,
  background: 'white',
  color: '#373736',
  border: '1px solid #C5C5C5',
};

export default function ProfileListComponent({ profiles, handleActive, handleDuplicate, openProfileUpdate, access }) {
  const [value] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [shareLink, setShareLink] = useState("")
  const [openDialog, setOpenDialog] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState<any>({});
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, profile) => {
    setSelectedProfile(profile);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const shareProfileLink = (serialNo) => {
    let shareLink = profileHelper.getProfileLink({ serialNo })
    setShareLink(shareLink)
    setOpenDialog(true)
    // navigator.share(shareLink)
  }

  const onDialogClose = () => {
    setOpenDialog(false)
  }

  return (
    <>
      <ShareProfileDialog profileLink={shareLink} onClose={onDialogClose} open={openDialog} />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} >
          <Tab sx={{ color: "black !important" }} label="Profile list" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
        <Typography style={{
          fontSize: '31px',
          fontStyle: 'normal',
          fontWeight: '400',
          padding: '1rem 0.5rem 0.2rem 0.2rem'
        }}>
          All Profiles
        </Typography>

        <Typography sx={{ color: '#A7A7A7', fontSize: '25px', paddingTop: '1rem' }} component="span">
          ({profiles.length})
        </Typography>

        {profiles.length < access.access_limit.profile && <Button onClick={() => navigate(PORTAL_PROFILE)}
          variant="contained"
          color="secondary"
          sx={{ mt: 3, mb: 2, maxWidth: "150px", borderRadius: '30px', color: '#FECD20' }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '4px',
            position: 'absolute',
            right: '25px'
          }}>
          <img src={icons.add_icon} alt="add profile" />
          Add Profile
        </Button>}
      </Box>
      <Box style={{ paddingTop: "1rem" }}>
        {profiles.map((profile) => <Card
          key={profile.profile_uuid}
          elevation={0}
          sx={{
            bgcolor: profile.profile_active ? '#CEE1D5' : '#F2F4F8',
            borderRadius: '8px',
            mb: '15px',
            opacity: profile.is_expired ? 0.5 : 1,
          }}
        >
          <Box sx={{ p: '16px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: '20px' }}>
              <Avatar src={profile.imgURL ?? icons.profile_picture_icon} sx={{ width: 57, height: 57 }} alt="" />
              <Box sx={{ flex: 1 }}>
                <Typography component={'span'} sx={{ fontSize: '16px', fontWeight: 500 }} gutterBottom>{profile.name} ({profile.profile_name})</Typography>
                <Typography sx={{ fontSize: '13px', fontWeight: 400, color: '#373736' }} gutterBottom>{profile.company_name}</Typography>
                <Chip sx={profile.profile_active ? activeChipStyle : deactivatedChipStyle} label={profile.profile_active ? 'Active' : 'Deactivated'} />
              </Box>
              {/* <Button variant='contained'>View Card</Button> */}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '13px', fontWeight: 400, color: '#373736' }}>
                Created at: {moment(profile.created_at, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY, h:mm A')}
              </Typography>
              {!profile.is_expired && <Button variant='contained' sx={{ display: { xs: 'none', sm: 'inline-flex' } }} onClick={(event) => handleClick(event, profile)}>
                More Actions
              </Button>}
              {!profile.is_expired && <IconButton sx={{ display: { xs: 'inline-flex', sm: 'none' } }} onClick={(event) => handleClick(event, profile)}>
                <img src={icons.more_horizontal_circle_02} alt="More options" />
              </IconButton>}
            </Box>
          </Box>
        </Card>)}
        {profiles.length === 0 && <Placeholder
          icon={icons.user_circle_icon}
          title="No profile created"
          description="Customize your own profile now!"
          buttonText="Add Profile"
          action={() => navigate(PORTAL_PROFILE)}
        />}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => openProfileUpdate(selectedProfile.profile_uuid)}>
            <ListItemIcon>
              <img src={icons.edit_02} alt="edit" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Edit Profile</ListItemText>
          </MenuItem>
          <MenuItem disabled={!selectedProfile.profile_active} onClick={() => shareProfileLink(selectedProfile.public_name || selectedProfile.serial_number)}>
            <ListItemIcon>
              <img src={icons.share_contact_icon} alt="share contact" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Share Profile</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            handleActive(selectedProfile.profile_uuid, (!selectedProfile.profile_active).toString());
            setAnchorEl(null);
          }}>
            <ListItemIcon>
              <img src={selectedProfile.profile_active ? icons.user_lock : icons.user_unlock} alt="Toggle status" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>{selectedProfile.profile_active ? 'Set as Deactivated' : 'Set as Active'}</ListItemText>
          </MenuItem>
          {profiles.length < access.access_limit.profile && <MenuItem onClick={() => handleDuplicate(selectedProfile.profile_uuid)}>
            <ListItemIcon>
              <img src={icons.copy} alt="edit" />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Duplicate Profile</ListItemText>
          </MenuItem>}
        </Menu>
      </Box>
    </>
  );
}