import axios from "axios";

interface Data {
  code: number,
  data: any,
  message: string | null,
  success: boolean
}

const saveCalendarId = (profile_uuid: string, calendar_id: string): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  const headers = { 'Authorization': `Bearer ${user.access_token}` };
  const requestBody = { profile_uuid, calendar_id };

  return new Promise((resolve, reject) => {
    axios.post("profile/saveCalendarId", requestBody, { headers })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default saveCalendarId;