import { createAsyncThunk } from "@reduxjs/toolkit";
import landingPageApi from "../../api/landingPage";
import { LANDING_PAGE } from "../../lib/constants/slices";
import basicContactAPi from "../../api/basicContact";
export const getActiveProfileByEncode = createAsyncThunk(
  `${LANDING_PAGE}/getActiveProfile`,
  async ({userUUID}:{userUUID : string}, { rejectWithValue }) => {
    return landingPageApi.getActiveProfileByEncode({ userUUID: userUUID})
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const getProfile = createAsyncThunk(
  `${LANDING_PAGE}/getProfile`,
  async ({serialNo}:{serialNo : string}, { rejectWithValue }) => {
    return basicContactAPi.getActiveProfile({ serialNo: serialNo})
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);

export const getProfileWithCard = createAsyncThunk(
  `${LANDING_PAGE}/getProfileWithCard`,
  async ({serialNo}:{serialNo : string}, { rejectWithValue }) => {
    return basicContactAPi.getActiveProfileByCard({ serialNo: serialNo})
      .then((response) => response)
      .catch((error) => rejectWithValue(error));
  }
);
