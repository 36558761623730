import axios from "axios";
import { BindToProfileRequest } from "../../lib/types/Voucher";

interface Data {
  code: number,
  data: null,
  message: string | null,
  success: boolean
}

const bindToProfile = (body: BindToProfileRequest): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.post("campaign/bindToProfile", body)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default bindToProfile;