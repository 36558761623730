import React, {useEffect} from 'react';
import VoucherComponent from './voucher.component';
import {useSelector} from 'react-redux';
import {CampaignList} from '../../../../../../lib/types/Profile';
import {useParams} from 'react-router-dom';
import campaignApi from '../../../../../../api/campaign';
import {useDispatch} from 'react-redux';
import { startLoading,endLoading } from '../../../../../../features/loading';
import { openErrorDialog } from "../../../../../../features/error-dialog";
import profileApi from '../../../../../../api/profile';
import FileHelper from '../../../../../../lib/helper/fileHelper';
export default function VoucherContainer ({ getProfileVouchers }){
  const {profileUuid} = useParams();
  const dispatch = useDispatch();
  const campaignListRedux = useSelector((state: any) => state.campaign.list);
  const [campaignList, setCampaignList] = React.useState([] as CampaignList[]);
  const [campaignOptions, setCampaignOptions] = React.useState([] as any[]);
  const [profileVoucherStatus, setProfileVoucherStatus] = React.useState(false as any);
  const getCampaignList = async () => {
    if(profileUuid === undefined) return;
    dispatch(startLoading());
    await profileApi.getCampaignList(profileUuid)
      .then((response) => {
        if(response.success) {
          const campaigns = response.data.map(async (campaign) => {
            const vouchers = await Promise.all(campaign.vouchers.map(async (voucher) => {
              const url = await FileHelper.getUrl(voucher.banner_image);
              return {
                ...voucher,
                banner_image_url: url
              };
            }));
            return {
              ...campaign,
              vouchers
            };
          });
          Promise.all(campaigns).then((resolvedCampaigns) => {
            setCampaignList(resolvedCampaigns);
          });
        }
      }).catch((error) => {
        dispatch(openErrorDialog({
          title: 'Get Campaign failed',
          description: error.message
        }));
      }).finally(() => {
        dispatch(endLoading());
      });

    await profileApi.getVoucherStatus(profileUuid)
    .then((response) => {
      if (response.success) {
        setProfileVoucherStatus(response?.data?.active);
      }
    })
    .catch((error) => {
      dispatch(openErrorDialog({
        title: 'Get Campaign failed',
        description: error.message
      }));
    })
  }
  const hanldleSelectCampaign = async (campaignUUID: string) => {
    if(profileUuid === undefined) return;
    
    dispatch(startLoading());
      await campaignApi.bindToProfile({
        profile_uuid: profileUuid, 
        campaign_uuid: campaignUUID
      }).then(() => {
        getCampaignList();
      }).catch((error) => {
        dispatch(openErrorDialog({
          title: 'Assign Campaign failed',
          description: error.message
        }));
      }).finally(() => {
        dispatch(endLoading());
      });
  }

  const handleDelete = async (campaignUUID: string) => {
    if(profileUuid === undefined) return;
    dispatch(startLoading());
    await campaignApi.deleteFromProfile({
      profile_uuid: profileUuid, 
      campaign_uuid: campaignUUID
    }).then(() => {
      getCampaignList();
      getProfileVouchers();
    }).catch((error) => {
      dispatch(openErrorDialog({
        title: 'Delete Campaign failed',
        description: error.message
      }));
    }).finally(() => {
      dispatch(endLoading());
    });
  }

  const handleVoucherStatus = async ({campaignUUID,voucherUUID,status}) => {
    if(profileUuid === undefined) return;
    dispatch(startLoading());
    await campaignApi.updateProfileVoucherStatus({
      profile_uuid: profileUuid, 
      voucher_uuid: voucherUUID,
      campaign_uuid: campaignUUID,
      status
    }).then(() => {
      getCampaignList();
      getProfileVouchers();
    }).catch((error) => {
      dispatch(openErrorDialog({
        title: 'Update Voucher Status failed',
        description: error.message
      }));
    }).finally(() => {
      dispatch(endLoading());
    });
  }

  const handleProfileVoucherStatus = async (status: string) => {
    if(profileUuid === undefined) return;
    dispatch(startLoading());
    await profileApi.updateVoucherStatus(
      profileUuid,
      status
    ).then(() => {
      getCampaignList();
      getProfileVouchers();
    }).catch((error) => {
      dispatch(openErrorDialog({
        title: 'Update Voucher Status failed',
        description: error.message
      }));
    }).finally(() => {
      dispatch(endLoading());
    });
    let bool = (status === '1') ? true: false;
    setProfileVoucherStatus(bool);
  }

  useEffect(() => {
    setCampaignOptions(campaignListRedux);
    // eslint-disable-next-line
  },[campaignListRedux])
  useEffect(() => { 
    getCampaignList();
    // eslint-disable-next-line
  },[])
  return <VoucherComponent 
    profileVoucherStatus={profileVoucherStatus}
    campaignList={campaignList}
    campaignOptions={campaignOptions}
    handleSelectCampaign={hanldleSelectCampaign}
    handleDelete={handleDelete}
    handleVoucherStatus={handleVoucherStatus}
    handleProfileVoucherStatus={handleProfileVoucherStatus}
  />
}