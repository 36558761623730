import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import FacebookLogin from '@greatsumini/react-facebook-login';
import images from '../../../../assets/img/index.js';
import icons from '../../../../assets/icons/index.js';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

export default function SignInComponent({
  isLoading,
  signUpRoute,
  signUpSearch,
  forgotRoute,
  forgotSearch,
  handleSubmit,
  handleContactUs,
  googleLogin,
  handleFacebookLogin,
  handleLineLogin,
  handleAppleLogin,
}) {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" style={{
        width: '336px',
        height: '100vh',
        display: 'grid',
        alignContent: 'space-around',
        justifyContent: 'center'
      }}>
        <CssBaseline />
        <Paper elevation={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1.3rem',
            borderRadius: '8.78px',
            width: "inherit"
          }}
        >
          <div style={{ margin: '20px' }}>
            <img src={images.pxlicon.toString()} style={{ maxWidth: '133px', maxHeight: "33" }} alt="Pixel Logo" />
          </div>
          <Typography component="h1" variant="h4">
            Log in to your account
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <Typography component='label' className='required' variant='h5'>
              Email
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              placeholder='enter your email'
            />
            <Typography component='label' className='required' variant='h5'>
              Password
            </Typography>
            <TextField
              color='secondary'
              margin="dense"
              required
              fullWidth
              name="password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              placeholder='enter your password'
              InputProps={{
                endAdornment: <IconButton sx={{ opacity: 0.5 }} aria-label="show" size="small" onClick={() => setShowPassword(!showPassword)}>
                  <img src={showPassword ? icons.eye_close : icons.eye_open_icon} alt="eye_open_icon" />
                </IconButton>,
              }}
            />

            <Grid container>
              <Grid item xs>
                <FormControlLabel
                  name='remember'
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
              </Grid>
              <Grid item sx={{ mt: '13px' }}>
                <Link
                  component={RouterLink}
                  to={{
                    pathname: forgotRoute,
                    search: forgotSearch,
                  }}
                  variant="body1"
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <LoadingButton
              id="signin-button"
              type="submit"
              fullWidth
              variant="contained"
              loading={isLoading}
              loadingPosition={isLoading ? "start" : undefined}
              startIcon={isLoading ? <div /> : undefined}
              sx={{ mt: 3, mb: 2, borderRadius: '30px', color: '#FECD20' }}
            >
              <span>{isLoading ? 'Logging in' : 'Log In'}</span>
            </LoadingButton>
            <Typography component="p" variant="body1" align='center'>
              Having trouble logging in? <Link href="#" onClick={() => handleContactUs()} sx={{ color: '#FECD20' }}>Contact us</Link>
            </Typography>
          </Box>
          <hr style={{ width: '100%', marginTop: '20px' }} />
          <Typography component="p" variant="body1" align='center' sx={{
            bgcolor: '#F9F9F9',
            position: 'relative',
            top: '-15px',
            paddingX: '5px',
          }}>
            Or login with
          </Typography>

          <div>
            <img
              onClick={() => googleLogin()}
              style={{ marginRight: '10px', cursor: 'pointer' }}
              src={icons.login_google_icon.toString()}
              alt='Google logo'
            />
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
              onSuccess={handleFacebookLogin}
              onFail={(error) => {
                console.log('Login Failed!', error);
              }}
              onProfileSuccess={(response) => {
                console.log('Get Profile Success!', response);
              }}
              render={({ onClick, logout }) => (
                <img
                  onClick={onClick}
                  style={{ cursor: 'pointer' }}
                  src={icons.login_facebook_icon.toString()}
                  alt='Facebook logo'
                />
              )}
            />
            <img
              src={images.line_logo}
              alt='LINE logo'
              style={{
                width: '40px',
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => handleLineLogin()}
            />
            {/* <img
              src={images.apple_logo}
              alt='Apple logo'
              style={{
                width: '40px',
                marginLeft: '10px',
                cursor: 'pointer',
              }}
              onClick={() => handleAppleLogin()}
            /> */}
          </div>

          <Typography component="p" variant="body1" align='center' mt={'20px'}>
            Don't have a account? <Link href="#"></Link>
            <Link
              component={RouterLink}
              to={{
                pathname: signUpRoute,
                search: signUpSearch,
              }}
              style={{ color: '#FECD20' }}
            >
              Sign Up
            </Link>
          </Typography>

          <Box sx={{ display: 'flex', gap: '10px', mt: '20px' }}>
            <Link underline="hover" sx={{ color: "#C5C5C5", fontWeight: 400 }} href={'https://pixelcard.co/privacy-policy/'}>
              Privacy Policy
            </Link>
            <Link underline="hover" sx={{ color: "#C5C5C5", fontWeight: 400 }} href={'https://pixelcard.co/terms-of-service/'}>
              Terms of Service
            </Link>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
