import React, {  useEffect, useState } from 'react';
import ContactDetailComponent from "./contactDetail.component";
import { useDispatch } from 'react-redux';
import { startLoading, endLoading } from '../../../../features/loading';
import { setBreadcrumb } from '../../../../features/admin';
import contactApi from '../../../../api/contact';
import { useParams } from 'react-router-dom';
import {openResponseDialog} from '../../../../features/response-dialog';
import FileHelper from '../../../../lib/helper/fileHelper'
import { useSelector } from "react-redux";
import { refreshDetail } from '../../../../features/contact';
import { PORTAL_CONTACTS } from '../../../../lib/constants/routes';
import { PHONE_HOME, PHONE_MOBILE, ADDRESS, EMAIL } from '../../../../lib/constants/basiccontacttypes';
export default function ContactDetailContainter () {
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState<any[]>([]);
  const { contactUUID } = useParams<{ contactUUID: string }>(); // Get contactUUID from URL
  const [profileImage, setProfileImage] = useState("");
  const refresh = useSelector((state: any) => state.contact.refreshDetail);
  const getContactDetails = async (value) => {
    dispatch(startLoading())
    const response = await contactApi.getDetails({ contactUUID: value })
    .then(response => response.data)
    .catch(error => error);
    if(response.success){
      setContacts(response.data.map( (value,index)=>{
        return {
          serialNumber: value.serial_number || "",
          email: value.email || [],
          name: value.name || "",
          contactUserUUID: value.contact_user_uuid || "",
          birthday: value.birthday || "",
          phoneNumber: value.phone_number || [],
          profileImage: value.profile_picture || "",
          timezone: value.timezone || "",
          phoneCode: value.phone_code || "",
          address1: value.address_1 || "",
          address2: value.address_2 || "",
          isDeleted: value.is_deleted || 0,
          profileUUID: value.profile_uuid || "",
          type: value.type || "",
          jobTitle: value.job_title || "",
          companyName: value.company_name || "",
          companyAddress: value.company_address || [],
          website: value.website || [],
          files: value.files || [],
          socialInfo: value.social_info || [],
          createdAt: value.created_at || "",
        };
      }));
    }else{
      dispatch(openResponseDialog({title: "Error",description: response.message}));
    }
    dispatch(endLoading())
  }

  const viewProfile = (serialNumber) => {
    window.open(`/w/${serialNumber}`);
  }

  const getProfileImage = async() =>{
    if(contacts.length > 0  && contacts[0]?.profileImage !== ''){
      const fileResponse =  await FileHelper.getUrl( contacts[0]?.profileImage).then(fileResponse =>  fileResponse)
      return setProfileImage(fileResponse)
    }
    return null;
  }
  
  useEffect(() => {
    
    const fetchData = async () => {
      if(contactUUID){
        try {
          getContactDetails(contactUUID);
        } catch (error) {
          dispatch(openResponseDialog({title: "Error",description: error.message}));
        }
      }
    };
    fetchData();
    dispatch(setBreadcrumb({breadcrumb:[
      {name: "Contact", path: PORTAL_CONTACTS},
      {name: "Contact List",path: PORTAL_CONTACTS},
      {name: "Detail", path: null}
    ]}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setUserContact = async () => {
    let userContact = {
      homePhone: null,
      mobilePhone: null,
      address: null,
      email: null
    };
    if (contacts) {
      if (contacts.length > 0) {
        contacts.map((value) => {
          if (value.category === PHONE_HOME) userContact.homePhone = value.info
          if (value.category === PHONE_MOBILE) userContact.mobilePhone = value.info
          if (value.category === ADDRESS) userContact.address = value.info
          if (value.category === EMAIL) userContact.email = value.info

          return value
        })
      }
    }
    let img = await FileHelper.urlToBase64(contacts[0].profileImage).then(repsonse => repsonse);

    return {
      phone: contacts[0]?.phoneNumber || "",
      address: contacts[0]?.companyAddress || "",
      email: contacts[0]?.email || "",
      name: contacts[0].name,
      company: contacts[0].companyName,
      title: contacts[0].jobTitle,
      img: img
    }
  }

  const handleSaveContact = () => {
    setUserContact().then(response => {
      if (response === undefined) return;

      // create a vcard file
      const makeVCardVersion = () => `VERSION:3.0`;
      const makeVCardName = (name) => `N:;${name};;`;
      const makeVCardOrg = (org) => `ORG:${org}`;
      const makeVCardTitle = (title) => `TITLE:${title}`;
      const makeVCardPhoto = (img) => {
        switch (img.type) {
          case 'image/jpeg':
            return `PHOTO;TYPE=JPEG;ENCODING=b:[${img.base64String}]`
          case 'image/jpg':
            return `PHOTO;TYPE=JPG;ENCODING=b:[${img.base64String}]`
          case 'image/png':
            return `PHOTO;TYPE=PNG;ENCODING=b:[${img.base64String}]`
          default:
            return `PHOTO;TYPE=JPEG;ENCODING=b:[${img.base64String}]`
        }
      };
      const makeVCardTel = (phone) => `TEL;TYPE=WORK,VOICE:${phone}`;
      const makeVCardAdr = (address) => `ADR;TYPE=WORK,PREF:;;${address}`;
      const makeVCardEmail = (email) => `EMAIL:${email}`;
      const makeVCardTimeStamp = () => `REV:${new Date().toISOString()}`;

      const phoneLooping = (phones) => {
        let output = ``;
        for(let i = 0; i < phones.length; i++) {
          output += `
${makeVCardTel(response.phone[i])}
`;
        }
        return output;
      }

      //spacing for vcard is important!!! do not change it 
      var vcard = `BEGIN:VCARD
${makeVCardVersion()}
${makeVCardName(response.name || '')}
${makeVCardOrg(response?.company || '')}
${makeVCardTitle(response?.title || '')}
${makeVCardPhoto(response?.img || '')}
${makeVCardAdr(response.address)}
${makeVCardEmail(response.email)}
${makeVCardTimeStamp()}` +
`
${phoneLooping(response.phone)}
END:VCARD`;

      var blob = new Blob([vcard], { type: "text/vcard" });
      var url = URL.createObjectURL(blob);
      const newLink = document.createElement('a');
      newLink.download = response.name + ".vcf";
      newLink.textContent = response.name;
      newLink.href = url;
      newLink.click();
    })
  }

  useEffect(() => {
    getProfileImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);
  useEffect(() => { 
    if(refresh){
      dispatch(refreshDetail(false))
      getContactDetails(contactUUID);
      getProfileImage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);
  getProfileImage()

  return (
    <ContactDetailComponent contacts={contacts} contactUUID={`${contactUUID}`} viewProfile={viewProfile} profileImage={profileImage} handleSaveContact={handleSaveContact}/>
  )
};