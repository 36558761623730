import React, {useEffect, useState} from 'react';
import { Collapse, Paper, Typography } from '@mui/material';
import ExpandMore from '../component/expandMore.component';
import './style.css';
const extractID = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match&&match[7].length===11)? match[7] : false;
}

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive">
    <iframe
      width="200"
      height="113"
      src={`https://www.youtube.com/embed/${embedId}?feature=oembed`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default function VideoComponent({ videoData }) {
  const [expanded, setExpanded] = useState('videos');
  const[key, setKey] = useState(1)
  useEffect(() =>{
    setTimeout(() => {
      setKey(key+1)
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[videoData])

  return (
    <Paper key={key}  sx={{ m: '10px 14px', p: '5px', borderRadius: '10px' }}>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ margin: '3px 6px', fontSize: '17px', fontWeight: 700, fontFamily: 'Rubik Variable, sans-serif', flex: 1 }}>
          Video
        </Typography>
        <ExpandMore
          expanded={expanded === 'videos'}
          onClick={() => setExpanded(expanded === 'videos' ? '' : 'videos')}
          aria-expanded={expanded === 'videos'}
          aria-label="show more"
        />
      </div>
      <Collapse sx={{ p: 0 }} in={expanded === 'videos'} timeout="auto" unmountOnExit>
        {videoData.map((video, index) => (
          <YoutubeEmbed key={index} embedId={extractID(video.url)} />
        ))}
        
      </Collapse>
    </Paper>
  );
}