import axios from "axios";

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const getProfile = (profileUUID: string): Promise<Data> => {
  
  const params = new URLSearchParams([['profile_uuid', profileUUID]]);
    return new Promise((resolve, reject) => {
        axios.get("myvoucher/getProfile",{params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getProfile;
