import React, {useEffect,MouseEvent} from "react";
import ContactCard from "./component/contactCard.component";
import ConfirmationDialog from "./component/confirmationDialog.component";
import CreateDialog from "./component/create-dialog";
import CallDialog from "./component/callDialog.component";
import EmailDialog from "./component/emailDialog.component";
import FilterDialog from "./component/filterDialog.component";
import contactListApi from "../../api/contactList";
import contactApi from "../../api/contact";
import { Box, CssBaseline, Typography, Container, Checkbox, Grid, Button, IconButton, TextField, Tabs, Tab, Chip, Menu, MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import icons from "../../assets/icons";
import { useDispatch,useSelector} from 'react-redux';
import { setBreadcrumb } from '../../features/admin'
import SocialDialog from "./socialDialog.component";
import { openActionDialog} from '../../features/action-dialog';
import { openResponseDialog } from "../../features/response-dialog";
import CreateDropdown from "./component/createDropdown.component";
import { refreshContacts, setContacts } from "../../features/contact";
import { startLoading, endLoading } from '../../features/loading';
import { PORTAL_CONTACTS } from "../../lib/constants/routes";
import { Dialog } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import auth from "../../api/auth";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PHONE_HOME, PHONE_MOBILE, ADDRESS, EMAIL } from '../../lib/constants/basiccontacttypes';
import FileHelper from '../../lib/helper/fileHelper'
import Placeholder from "../../components/placeholder/placeholder";

export default function ContactListContainer() {
  const theme = useTheme();
  const navigate = useNavigate();
  // const [contacts, setContacts] = React.useState<any>([]);
  const [contact, setContact] = React.useState<any>({});
  const [selectedContact, setSelectedContact] = React.useState<any>();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [openCallDialog, setOpenCallDialog] = React.useState(false);
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);
  const [openSocialDialog, setOpenSocialDialog] = React.useState(false);
  const [guestUuid, setGuestUuid] = React.useState('');
  const [contactUuid, setContactUuid] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [filterField, setFilterField] = React.useState({'search': '', 'position': [], 'type': '', 'sort': {} as any});
  const [search, setSearch] = React.useState('');
  const [guide, setGuide] = React.useState<boolean>(false);
  const textInput = React.useRef(null as any);
  const [selection, setSelection] = React.useState<any>([]);
  const dispatch = useDispatch()
  const contacts : any = useSelector<any>(state => state.contact.list) 
  const refresh = useSelector<any>(state  => state.contact.refresh)
  const isConfirmed = useSelector<any>(state  => state.actionDialog.isConfirmed)
  const dialogAction = useSelector<any>(state  => state.actionDialog.action)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const [accessLimits, setAccessLimits] = React.useState<number>();
  const [contactCount, setContactCount] = React.useState<number>();

  const open = Boolean(anchorEl);

  const getContacts = (search = '', orderBy = 'date', order = 'desc', type = '', jobTitle = '') => {
    dispatch(startLoading());
    contactListApi.contactList.getList(search, orderBy, order, type, jobTitle)
      .then(data => {
        if(data){
          setContactCount(data.total_count);
          Promise.all(data.contact_list.map(async (contact) => {
              let presigned_url = "";
              if(contact.profile_picture) presigned_url=  await contactListApi.contactList.getUrl(contact.profile_picture)
              return ({
                presigned_url: presigned_url,
                ...contact,
              })
            })).then(contacts =>{
            dispatch(setContacts({contacts: contacts}))
            return contacts
          })
        }
      });
  }

  const exportContacts = () => {
    var search = filterField.search;
    var jobTitle = filterField.position.join(',');
    var type = filterField.type;
    var sort = filterField.sort;
    var orderBy = Object.keys(sort);

    var order = 'desc';
    if (orderBy[0] === 'name') {
      order = sort.name;
    } else {
      order = sort.date;
    }

    contactListApi.contactList.exportContactList(search, orderBy[0], order, type, jobTitle);
  }

  const deleteContact = (uuid: string) => {
    contactListApi.contactList.deleteContact(uuid).then(data => {
      getContacts();
    });
  }

  const handleSearch = (event) => {
    event.preventDefault();
    let updatedValue = {"search": search};
    setFilterField(filterField => ({
          ...filterField,
          ...updatedValue
        }));
  }

  const handleReset = () => {
    if (textInput.current !== null) {
      textInput.current.value = null;
    }

    setFilterField({'search': '', 'position': [], 'type': '', 'sort': {}});
    setSelection([]);
    getContacts();
  }

  const handleDelete = () => {
    dispatch(openActionDialog({title: "Delete Selected Contact?",description:"All selected contact will be deleted.", action:"bulkDeleteContact"}))
  }

  const bulkDeleteContact = () => {    
    var uuid = selection.join(',');
    contactListApi.contactList.deleteContact(uuid).then(data => {
      dispatch(openResponseDialog({title: "Delete Successful", description: "Selected contact has been deleted."}))
      dispatch(refreshContacts(true))
    });
  }

  const handleSelectAll = () => {
    var contactList : any[] = [];
    
    if (selection.length !== contacts.length) {
      contacts.forEach(contact => {
        contactList.push(contact.contact_uuid);
      });
    } else {
      contactList = [];
    }
    setSelection(contactList);
  }

  useEffect(() =>{
    if(isConfirmed && dialogAction === "bulkDeleteContact") bulkDeleteContact()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isConfirmed])

  useEffect(() => {
    auth.getAccessLimit().then(value => {
      setAccessLimits(value.data.access_limit.contact);
    });
    // getContacts();
    dispatch(setBreadcrumb({breadcrumb:[
      {name: "Contact", path: PORTAL_CONTACTS},
      {name: "Contact List",path: null}
    ]}))

    if (!localStorage.getItem('contactGuide')) {
      setGuide(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var search = filterField.search;
    var jobTitle = filterField.position.join(',');
    var type = filterField.type;
    var sort = filterField.sort;
    var orderBy = Object.keys(sort);

    var order = 'desc';
    if (orderBy[0] === 'name') {
      order = sort.name;
    } else {
      order = sort.date;
    }
    getContacts(search, orderBy[0], order, type, jobTitle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterField]);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const totalNew = contacts.filter(contact => contact.is_new === '1').length;

  useEffect(() =>{
    if(refresh) getContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[refresh])

  const openCreateDialog = () =>{
    setGuestUuid('');
    setContactUuid('');
    setOpenDialog(true);
  }

  const viewContactDetails = () => {
    if (selectedContact?.user_type === '1') {
      navigate(`/portal/contact/${selectedContact?.contact_uuid}/details`);
    } else {
      navigate({
        pathname: `/portal/contact/${selectedContact?.contact_uuid}/details`,
        search: createSearchParams({
          guestUUID: selectedContact?.contact_guest_uuid
        }).toString()
      })
    }
  }

  const handleSaveContact = async () => {
    const response = await contactApi.getDetails({ contactUUID: selectedContact?.contact_uuid })
    .then(response => response.data)
    .catch(error => error);
    if(response.success){
      setContact(response.data.map( (value,index)=>{
        return {
          serialNumber: value.serial_number || "",
          email: value.email || [],
          name: value.name || "",
          contactUserUUID: value.contact_user_uuid || "",
          birthday: value.birthday || "",
          phoneNumber: value.phone_number || [],
          profileImage: value.profile_picture || "",
          timezone: value.timezone || "",
          phoneCode: value.phone_code || "",
          address1: value.address_1 || "",
          address2: value.address_2 || "",
          isDeleted: value.is_deleted || 0,
          profileUUID: value.profile_uuid || "",
          type: value.type || "",
          jobTitle: value.job_title || "",
          companyName: value.company_name || "",
          companyAddress: value.company_address || [],
          website: value.website || [],
          files: value.files || [],
          socialInfo: value.social_info || [],
          createdAt: value.created_at || "",
        };
      }));
    }else{
      dispatch(openResponseDialog({title: "Error",description: response.message}));
    }
  }

  useEffect(() =>{
    if(contact[0]) saveContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[contact])

  const setUserContact = async () => {
    let userContact = {
      homePhone: null,
      mobilePhone: null,
      address: null,
      email: null
    };
    if (contact) {
      if (contact.length > 0) {
        contact.map((value) => {
          if (value.category === PHONE_HOME) userContact.homePhone = value.info
          if (value.category === PHONE_MOBILE) userContact.mobilePhone = value.info
          if (value.category === ADDRESS) userContact.address = value.info
          if (value.category === EMAIL) userContact.email = value.info

          return value
        })
      }
    }

    let img = await FileHelper.urlToBase64(contact[0]?.profileImage).then(repsonse => repsonse);

    return {
      phone: contact[0]?.phoneNumber || "",
      address: contact[0]?.companyAddress || "",
      email: contact[0]?.email || "",
      name: contact[0].name,
      company: contact[0].companyName,
      title: contact[0].jobTitle,
      img: img
    }
  }

  const saveContact = () => {
    setUserContact().then(response => {
      if (response === undefined) return;

      // create a vcard file
      const makeVCardVersion = () => `VERSION:3.0`;
      const makeVCardName = (name) => `N:;${name};;`;
      const makeVCardOrg = (org) => `ORG:${org}`;
      const makeVCardTitle = (title) => `TITLE:${title}`;
      const makeVCardPhoto = (img) => {
        switch (img.type) {
          case 'image/jpeg':
            return `PHOTO;TYPE=JPEG;ENCODING=b:[${img.base64String}]`
          case 'image/jpg':
            return `PHOTO;TYPE=JPG;ENCODING=b:[${img.base64String}]`
          case 'image/png':
            return `PHOTO;TYPE=PNG;ENCODING=b:[${img.base64String}]`
          default:
            return `PHOTO;TYPE=JPEG;ENCODING=b:[${img.base64String}]`
        }
      };
      const makeVCardTel = (phone) => `TEL;TYPE=WORK,VOICE:${phone}`;
      const makeVCardAdr = (address) => `ADR;TYPE=WORK,PREF:;;${address}`;
      const makeVCardEmail = (email) => `EMAIL:${email}`;
      const makeVCardTimeStamp = () => `REV:${new Date().toISOString()}`;

      const phoneLooping = (phones) => {
        let output = ``;
        for(let i = 0; i < phones.length; i++) {
          output += `
${makeVCardTel(response.phone[i])}
`;
        }
        return output;
      }

      //spacing for vcard is important!!! do not change it 
      var vcard = `BEGIN:VCARD
${makeVCardVersion()}
${makeVCardName(response.name || '')}
${makeVCardOrg(response?.company || '')}
${makeVCardTitle(response?.title || '')}
${makeVCardPhoto(response?.img || '')}
${makeVCardAdr(response.address)}
${makeVCardEmail(response.email)}
${makeVCardTimeStamp()}` +
`
${phoneLooping(response.phone)}
END:VCARD`;

      var blob = new Blob([vcard], { type: "text/vcard" });
      var url = URL.createObjectURL(blob);
      const newLink = document.createElement('a');
      newLink.download = response.name + ".vcf";
      newLink.textContent = response.name;
      newLink.href = url;
      newLink.click();
    })
  }

  const handleDeleteSpecific = () => {
    setOpenConfirmDialog(true);
  }

  const handleConfirmDelete = () => {
    setAnchorEl2(null);
    deleteContact(selectedContact.contact_uuid);
  }
  
  const handleClose = () => {
    localStorage.setItem('contactGuide', 'true');
    setGuide(false);
  }
  dispatch(endLoading());
  return <>
    <Dialog fullWidth onClose={handleClose} open={guide}
      sx={{"& .MuiPaper-root":{
        padding:'2rem',
        [theme.breakpoints.down('md')]: {
          maxWidth:"875px", height:"50%"
        },
        [theme.breakpoints.up('md')]: {
        maxWidth:"875px", height:"100%"
        }
      }}}>
        <img src={icons.close.toString()} alt="close" 
        style={{position:'absolute',right:'10px',top:'10px',cursor:'pointer', height:"15px",width:"15px" }} onClick={handleClose}/>
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      gap: '1rem',
      maxWidth:"875px", height:"100%"
    }}>
      <iframe width="100%" height="100%" 
      src="https://www.youtube.com/embed/vH0YcPcu2Po?si=X4FQFjIb7uWOQTq0" 
      title="YouTube video player" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
      referrerPolicy="strict-origin-when-cross-origin" 
      allowFullScreen></iframe>
      <Button variant="contained" color="primary" onClick={() => handleClose()}>
        OK, Got it
      </Button>
    </Box>
    </Dialog>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={0} onChange={() => {}}>
        <Tab 
          icon={totalNew > 0 ? <Chip sx={{ fontSize: 12, fontWeight: 400, height: '17px' }} label={`${totalNew} New`} size="small" /> : undefined}
          iconPosition="end"
          sx={{ textTransform: "none",color:"black !important" }}
          label="Contact List"
          {...a11yProps(0)}
        />
      </Tabs>
    </Box>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Container maxWidth="lg" style={{ padding: 0 }} sx={{ mt: 4, mb: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '20px', flexWrap: 'wrap' }}>
          <Typography variant="h1" sx={{ fontSize: '31px' }}>
            Contact List
            {(accessLimits !== 0) 
              ?
              <Typography sx={{ color: '#A7A7A7', fontSize: '25px', paddingLeft: '10px' }} component="span">
                ({contactCount}/{accessLimits})
              </Typography> 
              :
              <Typography sx={{ color: '#A7A7A7', fontSize: '25px', paddingLeft: '10px' }} component="span">
                ({contactCount})
              </Typography>
            }
          </Typography>


          <Box  sx={{ display: 'flex', alignItems: 'center' }}>
            <CreateDropdown openCreateDialog={openCreateDialog}/>

            <Button variant="contained" color="primary" sx={{  borderRadius: '30px', px: '20px', marginLeft: '10px' }} onClick={() => {
              exportContacts();
            }}>
              Export as CSV
            </Button>
          </Box>

        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '20px' }}>
          <Checkbox onClick={handleSelectAll} checked={selection.length === contacts.length && contacts.length > 0}/>

          <IconButton onClick={handleReset}>
            <img src={icons.refresh} alt="filter" />
          </IconButton>

          { selection.length > 0 &&
          <IconButton onClick={handleDelete}>
            <img src={icons.delete_02} alt="trash-can" />
          </IconButton>
          }

          <form style={{ flex: 1, marginLeft: '15px', marginRight: '15px' }} onSubmit={handleSearch} >
            <TextField
              fullWidth
              inputRef={textInput}
              onChange={(e) => {setSearch(e.target.value)}}
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <IconButton onClick={handleSearch}>
                    <img src={icons.search_icon} alt="filter" />
                  </IconButton>
                ),
              }}
              variant="standard"
            />
          </form>

          <IconButton onClick={handleClick}>
            <img src={icons.arrow_up_down_round} alt="sort" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              sx: { width: '160px' },
            }}
            slotProps={{
              paper: {
                sx: { borderRadius: '7px' },
              }
            }}
          >
            <MenuItem sx={{ '&:hover': { bgcolor: '#FFEFBA' } }} onClick={() => {
              let updatedValue = { "sort": { "date": "asc" } };
              setFilterField(filterField => ({
                ...filterField,
                ...updatedValue
              }));
              setAnchorEl(null);
            }}>
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Date (Asc)</ListItemText>
            </MenuItem>
            <MenuItem sx={{ '&:hover': { bgcolor: '#FFEFBA' } }} onClick={() => {
              let updatedValue = { "sort": { "date": "desc" } };
              setFilterField(filterField => ({
                ...filterField,
                ...updatedValue
              }));
              setAnchorEl(null);
            }}>
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Date (Desc)</ListItemText>
            </MenuItem>
            <MenuItem sx={{ '&:hover': { bgcolor: '#FFEFBA' } }} onClick={() => {
              let updatedValue = { "sort": { "name": "asc" } };
              setFilterField(filterField => ({
                ...filterField,
                ...updatedValue
              }));
              setAnchorEl(null);
            }}>
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Name (Asc)</ListItemText>
            </MenuItem>
            <MenuItem sx={{ '&:hover': { bgcolor: '#FFEFBA' } }} onClick={() => {
              let updatedValue = { "sort": { "name": "desc" } };
              setFilterField(filterField => ({
                ...filterField,
                ...updatedValue
              }));
              setAnchorEl(null);
            }}>
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Sort by Name (Desc)</ListItemText>
            </MenuItem>
          </Menu>

          <IconButton onClick={() => setOpenFilterDialog(true)}>
            <img src={icons.filter_list} alt="filter" />
          </IconButton>
        </Box>
        
        {contacts.length > 0 && <Grid container spacing={2}>
          {contacts.map(contact => (
            <Grid item xs={12} sm={6} lg={4} key={contact.contact_uuid}>
              <ContactCard selection={selection} setSelection={setSelection} 
                anchorEl={anchorEl2}
                setAnchorEl={setAnchorEl2}
                contact={contact}
                setSelectedContact={setSelectedContact}
                getEmail={() => {
                  setSelectedContact(contact);
                  setOpenEmailDialog(true);
                }}
                getCall={() => {
                  setSelectedContact(contact);
                  setOpenCallDialog(true);
                }}
                getSocial={() => {
                  setSelectedContact(contact);
                  setOpenSocialDialog(true);
                }}
              />
            </Grid>
          ))}
          <Menu
            anchorEl={anchorEl2}
            open={Boolean(anchorEl2)}
            onClose={() => setAnchorEl2(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              sx: { width: '160px' },
            }}
            slotProps={{
              paper: {
                sx: { borderRadius: '7px' },
              }
            }}
          >
            <MenuItem onClick={() => viewContactDetails()} sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}>
              <ListItemIcon>
                <img src={icons.property_view} alt="" />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>View Details</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleSaveContact()} sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}>
              <ListItemIcon>
                <img src={icons.save_contact} alt="" />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Save Contacts</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleDeleteSpecific()} sx={{ '&:hover': { bgcolor: '#FFEFBA' } }}>
              <ListItemIcon>
                <img src={icons.delete_02} alt="share contact" />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '13px', fontWeight: 500 } }}>Delete</ListItemText>
            </MenuItem>
          </Menu>
        </Grid>}

        {contacts.length === 0 && <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Placeholder
            icon={icons.user_multiple}
            title="No contacts"
            description="Time to up your network game to the next level."
            buttonText="Add Contact"
            action={openCreateDialog}
          />
        </Grid>}
        <CreateDialog open={openDialog} handleClose={(shouldRefresh) => {
          setOpenDialog(false);
          if (shouldRefresh) getContacts();
        }} guestUuid={guestUuid} contactUuid={contactUuid} viewOnly={selectedContact?.user_type === '1'} />
        <FilterDialog open={openFilterDialog} handleClose={() => setOpenFilterDialog(false)} setFilterField={setFilterField} filterFn={(jobTitle: string, accountType: string) => getContacts('date', 'desc', accountType, jobTitle)} />
        <ConfirmationDialog open={openConfirmDialog} handleClose={() => setOpenConfirmDialog(false)} handleDelete={handleConfirmDelete} contact={selectedContact} />
        <EmailDialog open={openEmailDialog} handleClose={() => setOpenEmailDialog(false)} uuid={selectedContact?.contact_uuid ?? ''} />
        <CallDialog open={openCallDialog} handleClose={() => setOpenCallDialog(false)} uuid={selectedContact?.contact_uuid ?? ''} />
        <SocialDialog open={openSocialDialog} handleClose={() => setOpenSocialDialog(false)} uuid={selectedContact?.contact_uuid ?? ''} />
      </Container>
    </Box>
  </>
}