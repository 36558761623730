import axios from 'axios';

interface Data{
  code: number,
  data:  null,
  message: string | null,
  success: boolean
}

const getDetails = ({ contactUUID }: { contactUUID: string }) : Promise<Data> =>  {
  const storedUser = localStorage.getItem('user');

  if (storedUser === null) {
    return Promise.resolve({
      code: 404,
      data: null,
      message: 'Please log in',
      success: false,
    });
  }

  const user = JSON.parse(storedUser || '{}')
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return axios.get('/contact/details', {
    params: {
      contact_uuid: contactUUID
    }
  });
}

export default getDetails;