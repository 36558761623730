import { createSlice } from "@reduxjs/toolkit";
import { BOOK_APPOINTMENT } from "../../lib/constants/slices";

const initialState = {
  appointmentRequest: {
    user_uuid: '',
    title: '',
    name: '',
    phone_number: '',
    email: '',
    date: '',
    start_time: '',
    end_time: '',
    notes: '',
  },
};
const bookAppointmentSlice = createSlice({
  name: BOOK_APPOINTMENT,
  initialState,
  reducers: {
    setAppointmentRequest(state, action) {
      state.appointmentRequest = action.payload;
    },
    clearAppointmentRequest(state) {
      state.appointmentRequest = initialState.appointmentRequest;
    },
  }
});


export const { setAppointmentRequest, clearAppointmentRequest } = bookAppointmentSlice.actions;
export const bookAppointmentReducer = bookAppointmentSlice.reducer;
