import axios from "axios";

interface Card {
  uuid: string;
  card_serial_number: string;
  image: string;
  profile_name: string;
  job_title: string;
  company_name: string;
  font_thumbnail?: string;
  pixelcare_start_date: string;
  pixelcare_end_date: string;
  status: number;
}

interface Data {
  success: boolean;
  code: number;
  message: string;
  data: Card[];
}

const getCards = (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get(`card/getCardList`)
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default getCards;