import addProduct from "./addProduct.api";
import addProductVariation from "./addProductVariation.api";
import addToCart from "./addToCart.api";
import getShoppingCartListing from "./getShoppingCartListing.api";
import getCartDetails from "./getCartDetails.api";
import editCart from "./editCart.api";
import deleteCart from "./deleteCart.api";
import editQuantity from "./editQuantity.api";
import getProducts from "./getProductList.api";
import getProductDetails from "./getProductDetails.api";
import editPixelcare from "./editPixelcare.api";

const productApi = {
  addProduct,
  addProductVariation,
  addToCart,
  editCart,
  getShoppingCartListing,
  getCartDetails,
  deleteCart,
  editQuantity,
  getProducts,
  getProductDetails,
  editPixelcare,
};

export default productApi;