import React, {useEffect,useState} from 'react';
import RedeemVoucherComponent from './redeemVoucher.component';
import myVoucherApi from '../../../../api/myVoucher';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { startLoading, endLoading } from '../../../../features/loading';
import { useNavigate } from 'react-router-dom';
import { PORTAL_WALLET } from '../../../../lib/constants/routes';
import { openResponseDialog } from '../../../../features/response-dialog';

export default function RedeemVoucherContainer() {
  const { userVoucherUUID } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [voucher, setVoucher] = useState();
  const [redeemed, setRedeemed] = useState(false);
  const getDetails = async () => {
    dispatch(startLoading());
    if(!userVoucherUUID) return dispatch(endLoading());

    await myVoucherApi.details({userVoucherUUID:userVoucherUUID}).then((response) => {
      if(response.success){
        setVoucher(response.data);
      }
    }).catch((error) => {
      handleDone();
      dispatch(openResponseDialog({title: "Redeem Failed",description: error.message}))
    }).finally(() => {
      dispatch(endLoading());
    })
  }

  const noValidationRedeem = async ({voucherCode}:{
    voucherCode: string
  }) => {
    dispatch(startLoading());
    if(redeemed) return; // slider will sometimes trigger multiple times
    setRedeemed(true);
    await myVoucherApi.noValidateRedeem({
      voucher_code: voucherCode
    }).then((response) => {
      if(response.success){
        dispatch(openResponseDialog({title: "Redeem Success",description: "You have Successfully Redeem the Voucher"}))
      }
    }).catch((error) => { 
      handleDone();
      dispatch(openResponseDialog({title: "Redeem Failed",description: error.message}))
    }).finally(() => {
      dispatch(endLoading());
    })
  }

  const handleDone = () => {  
    navigate(PORTAL_WALLET);
  }
  useEffect(() => {
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userVoucherUUID]);
  return Boolean(voucher) && <RedeemVoucherComponent voucher={voucher} handleDone={handleDone} noValidationRedeem={noValidationRedeem} />
}