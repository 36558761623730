import React, {useState,useEffect} from 'react'
import { styled } from '@mui/system';
import {  useNavigate,useParams,createSearchParams } from 'react-router-dom';
import { Box,Button,Typography } from "@mui/material";
import images from '../../../../assets/img'
import Slide from '@mui/material/Slide';
import Lottie from 'react-lottie-player';
import animation from '../../../../assets/animation'
import { PHONE_MOBILE } from '../../../../lib/constants/basiccontacttypes';
import fileHelper from '../../../../lib/helper/fileHelper';
import { EXCHANGE_CONTACT_MEMBER_SIGN_IN } from '../../../../lib/constants/routes';
import { useSelector } from 'react-redux';
import accessType from '../../../../lib/constants/accessType';
import makeVCard from '../../createVcard';
import FileHelper from '../../../../lib/helper/fileHelper';

const CustomContainer = styled('div')({
  paddingTop:"3rem",
  display: 'grid',
  flexDirection: 'row'
});

export default function GuestConnected({userDetails,contactInfo,contacted}){
  const profileTheme = useSelector((state: any) => state.landingPage.theme)
  const navigate = useNavigate();
  const { id } = useParams();
  const [phone, setPhone] = useState('')
  const [profileImage,setProfileImage] = useState("")
  
  const landingPage = useSelector((state:any) => state.landingPage)

  const accessPath = landingPage.accessPath
  const owner_card_no =  landingPage.accessType  === accessType.CARD ? id : null;
  const owner_profile_no = landingPage.accessType  === accessType.WEB ? id : null;

  const searchParams = new URLSearchParams();
  if (owner_card_no) {
    searchParams.append('owner_card_no', owner_card_no);
  }
  if (owner_profile_no) {
    searchParams.append('owner_profile_no', owner_profile_no);
  }
  
  const handleGuestSignup = async () =>{
    navigate({
      pathname: EXCHANGE_CONTACT_MEMBER_SIGN_IN,
      search: createSearchParams(searchParams).toString()
    });
  }


  const handleSaveContact = async () => {
    const addressItem = contactInfo.content.find(item => item.category.toLowerCase() === 'address');
    const emailItem = contactInfo.content.find(item => item.category.toLowerCase() === 'email');
    const websites = contactInfo.content.filter(item => item.category.toLowerCase() === 'website');
    const social_sites = landingPage.profile.social_info.content;
    const contact = {
      name: userDetails.full_name,
      company: userDetails.company_name,
      title: userDetails.job_title,
      img: Boolean(userDetails.profile_image) ? await FileHelper.urlToBase64(userDetails.profile_image) : null,
      phone: phone,
      address: addressItem?.info ?? '',
      email: emailItem?.info ?? '',
      websites,
      social_sites,
      source: `${process.env.REACT_APP_BASE_URL}/w/${landingPage.profile.public_name ?? landingPage.profile.serial_number}`,
      note: userDetails.description,
    };

    // create a vcard file
    const vcard = makeVCard(contact);
    const blob = new Blob([vcard], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const newLink = document.createElement('a');

    newLink.download = contact.name + ".vcf";
    newLink.textContent = contact.name;
    newLink.href = url;
    newLink.click();
  }

  useEffect(() =>{
    const getContact = () => {
      contactInfo.content.map((value,index) =>{
          if(value.category === PHONE_MOBILE){
            setPhone(value.info)
          }
          return value
      })
    }
    getContact()

    const getProfileImage = async() => {
      const fullpath = userDetails.profile_image
      const profilePicUrl =  await fileHelper.getUrl(fullpath).then(repsonse => repsonse)
      setProfileImage(profilePicUrl)
    }
    getProfileImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return <>
      <Slide direction="left"  in={contacted} mountOnEnter unmountOnExit>
  <CustomContainer>
<Box style={{position:'relative'}}>

<Lottie  
    animationData={animation.exchange_connected}      
    play
    style={{ zIndex: "-1" }}/>
<table
style={{
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: '35%'
}}>
  <tbody>
  <tr>
    <td style={{
      display: 'grid',
      alignItems: 'center',
      justifyItems: 'center'
    }}>
    <Box sx={{
    width: '148.724px',
    height: '148.724px',
    flexShrink: 0,
    borderRadius: '50%',
    background: 'var(--neutral-100, #FFF)',
    backgroundImage: `url(${profileImage || images.empty_avatar})`,
    backgroundSize: 'cover',
    backgroundRepeatX: 'no-repeat',
    backgroundRepeatY: 'no-repeat',
  }}>
  </Box>
    </td>
  </tr>
  </tbody>
</table>

  {/* <h1 style={{
    position: 'absolute',
    bottom: '0px',
    left: '139px'
  }}>
  Congrats!
  </h1> */}
</Box>
<Box style={{
          display: 'grid',
          justifyItems: 'center',
          paddingBottom: '3rem'
    }}>
      <h1>
        Congrats!
      </h1>
    <Typography style={{fontSize:'14px',fontWeight:'400',width:'350px',
  textAlign: 'center'}}>
    You have successfully connected with {userDetails.full_name},
Sign up now for REAL-TIME updates and stay connected with up-to-date contact details.
    </Typography>
    </Box>

    <Box style={{
      display: 'grid',
      justifyItems: 'center'
    }}>
    <Button
            type="button"
            fullWidth
            variant="contained"
            onClick={() => navigate(accessPath+`/${id}`)}
            sx={{ mt: 2, mb:2, gap:'1rem',borderRadius: '30px',width:'280px',height:'52px',
            backgroundColor: profileTheme?.button?.secondary_color || '#FECD20',
            color: profileTheme?.button?.secondary_font_color ||'#FECD20',
            '&:hover': { backgroundColor: profileTheme?.button?.primary_color || '#000000'}
            }}
          >
            Main Page
          </Button>
          { phone ? 
          <Button
            type="button"
            fullWidth
            variant="outlined"
            onClick={() => handleSaveContact()}
            sx={{ borderRadius: '30px',width:'280px',height:'52px',
            color: profileTheme?.highlight?.primary_color || '#FECD20' }}
          >
            Save Contact
          </Button>
          :<></>
        }
    </Box>
    <Box style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '0.8rem'
    }}>
        <div style={{
                  width: '100%',
                  strokeWidth: '1px',
                  stroke: 'var(--status-default, #C5C5C5)',
                  border: 'solid #C5C5C5 1px'
        }}></div><h3>OR</h3>
      <div style={{
                  width: '100%',
                  strokeWidth: '1px',
                  stroke: 'var(--status-default, #C5C5C5)',
                  border: 'solid #C5C5C5 1px'
        }}></div>
    </Box>
    <Box>
      <Lottie  
        onClick={() => handleGuestSignup()}
        animationData={animation.banner_animation}      
        play
        style={{padding:'0rem 2rem'}}/>
      </Box>
    
</CustomContainer>
</Slide>
</>
}