import React, { useEffect, useState } from 'react';
import ProfileOptionComponent from '../profile-option/profileOption.component';
import profileApi from '../../../../api/profile';
import FileHelper from '../../../../lib/helper/fileHelper'
import campaignApi from '../../../../api/campaign';
import { useDispatch, useSelector } from 'react-redux';
import { openErrorDialog } from '../../../../features/error-dialog';
import { useNavigate, useLocation, createSearchParams, useParams } from 'react-router-dom';
import { COLLECT_VOUCHER_CONNECTED, COLLECT_VOUCHER_MEMBER_SIGN_IN, VOUCHER_DETAIL } from '../../../../lib/constants/routes';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ProfileOptionContainer() {
  const { id, voucherUUID } = useParams();
  const landingPage = useSelector((state: any) => state.landingPage);
  const user = localStorage.getItem('user');
  const dispatch = useDispatch();
  let query = useQuery();
  const ownerCardNo = query.get("owner_card_no") || ""
  const ownerProfileNo = query.get("owner_profile_no") || ""
  const accessPath = landingPage.accessPath;
  const [profileList, setProfileList] = useState<Array<Object>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getProfileList = async () => {
    if (user) {
      profileApi.getActiveList().then(response => {
        if (response.success) {
          Promise.all(response.data.map(async (profile: any) => ({
            name: profile.name,
            companyName: profile.company_name,
            serialNumber: profile.serial_number,
            profileName: profile.profile_name,
            profileUUID: profile.profile_uuid,
            profileActive: profile.profile_active,
            profilePicture: profile.profile_picture ? await FileHelper.getUrl(profile.profile_picture).then(repsonse => repsonse) : null
          }))).then(setProfileList);
        }
      }).catch(error => {
        if (error.code === 3) { // expired token
          navigate({
            pathname: COLLECT_VOUCHER_MEMBER_SIGN_IN,
            search: createSearchParams({
              owner_card_no: ownerCardNo,
              owner_profile_no: ownerProfileNo,
            }).toString()
          })
        }
      });
    }
  }

  const handleSelected = async (selectedProfile) => {
    setIsLoading(true);
    await campaignApi.collectVoucherCode({
      voucher_uuid: `${voucherUUID}`,
      voucher_owner_profile: landingPage.profile.profile_uuid,
      user_profile: selectedProfile.profileUUID,
    }).then(response => {
      if (response.success) {
        if (response.data.new_contacts) {
          navigate({
            pathname: `${accessPath}/${id}/voucher/${voucherUUID}/${COLLECT_VOUCHER_CONNECTED}`,
            search: createSearchParams({
              owner_card_no: ownerCardNo,
              owner_profile_no: `${id}`,
              receiver_profile_no: selectedProfile.serialNumber,
              access_path: accessPath
            }).toString()
          });
        } else {
          // Navigate to voucher details page and show popup
          navigate({
            pathname: `${accessPath}/${id}/${VOUCHER_DETAIL}`.replace(':voucherUUID', `${voucherUUID}`),
            search: createSearchParams({ collected: '1' }).toString(),
          }, { replace: true });
        }
      } else {
        dispatch(openErrorDialog({
          title: "Voucher Collection Failed",
          description: response.message,
          redirectPath: `${accessPath}/${id}/voucher/${voucherUUID}`,
        }));
      }
    }).catch(error => {
      console.log('error', error)
      dispatch(openErrorDialog({
        title: "Voucher Collection Failed",
        description: error.message,
        redirectPath: `${accessPath}/${id}/voucher/${voucherUUID}`,
      }));
      return error
    }).then(() => {
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getProfileList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <ProfileOptionComponent profileList={profileList} handleSelected={handleSelected} isLoading={isLoading}/>;
}
