import React from "react";
import { Avatar, Box, Button, Card, CardActions, CardContent, Checkbox, Chip, IconButton, Typography } from "@mui/material";
import icons from "../../../assets/icons";
import moment from "moment";

const ContactCard = ({
  anchorEl,
  setAnchorEl,
  contact,
  setSelectedContact,
  selection,
  setSelection,
  getEmail,
  getCall,
  getSocial,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setSelectedContact(contact);
  };

  const handleSelect = () => {
    if (!selection.includes(contact.contact_uuid)) {
      setSelection(selection.concat([contact.contact_uuid]));
    } else {
      const exclude = selection.filter(function (e) {
        return e !== contact.contact_uuid;
      });
      setSelection(exclude);
    }
  }

  const isMember = Number(contact.user_type) === 1;
  const memberBackground = 'linear-gradient(to right, #EEFFF1, white)';
  const guestBackground = 'linear-gradient(to right, #F6F6F6, white)';
  const newChipStyle = {
    background: '#FFF5D2',
    color: '#FFC700',
    fontSize: 13,
    fontWeight: 500
  };
  const memberChipStyle = {
    borderRadius: '3px',
    background: '#E7F6EA',
    color: '#0BA02C',
    fontSize: 13,
    fontWeight: 500
  };
  const guestChipStyle = {
    borderRadius: '3px',
    background: '#F3F3F3',
    color: '#373736',
    fontSize: 13,
    fontWeight: 500
  };

  return <Card elevation={0} sx={{ height: '100%', display: 'flex', flexDirection: 'column', background: isMember ? memberBackground : guestBackground, borderRadius: '8px', border: '1px solid #E4E5E8', textAlign: 'center' }}>
    <Box sx={{ mx: '10px', mt: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Checkbox onClick={handleSelect} checked={selection.includes(contact.contact_uuid)} />
      <Typography sx={{ flex: 1, fontSize: 20, fontWeight: 500, color: '#18191C', textAlign: 'start', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {contact.job_title} {Number(contact.is_new) === 1 && <Chip sx={newChipStyle} label='NEW' />}
      </Typography>
      <IconButton onClick={handleClick}>
        <img src={icons.more_horizontal_circle_02} alt="More options" />
      </IconButton>
    </Box>
    <Box display={'flex'} sx={{ mx: '23px', alignItems: 'center' }}>
      <Chip sx={isMember ? memberChipStyle : guestChipStyle} label={isMember ? 'MEMBER' : 'GUEST'} />
      <Typography sx={{ ml: 1.5, fontSize: 14, fontWeight: 400, color: '#767F8C', textAlign: 'start' }} color="text.secondary">
        {contact.company_name}
      </Typography>
    </Box>
    <CardContent sx={{ px: '23px', flex: 1 }}>
      <Box display={'flex'} sx={{ alignItems: 'center' }}>
        <Avatar src={Boolean(contact.presigned_url) ? contact.presigned_url : icons.profile_picture_icon} sx={{ width: 56, height: 56, margin: 'auto' }} />
        <Box sx={{ flex: 1 }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, textAlign: 'start', ml: '10px' }}>
            {contact.name}
          </Typography>
          <Typography sx={{ color: '#767F8C', fontSize: 13, fontWeight: 400, textAlign: 'start', ml: '10px' }}>
            Joined {moment(contact.created_at, 'YYYY-MM-DD').format('D MMMM YYYY')}
          </Typography>
        </Box>
      </Box>
    </CardContent>
    <CardActions sx={{ px: '23px', pb: '23px', justifyContent: 'center' }}>
      <Button size="small" sx={{ py: '15px', color: "black", backgroundColor: 'white', flex: 1, borderRadius: '8px', border: '1px solid #C5C5C5' }} startIcon={<img width={16} src={icons.mail_at_sign_02} alt='' />} onClick={getEmail}>Email</Button>
      <Button size="small" sx={{ py: '15px', color: "black", backgroundColor: 'white', flex: 1, borderRadius: '8px', border: '1px solid #C5C5C5' }} startIcon={<img width={16} src={icons.call_ringing_02} alt='' />} onClick={getCall}>Call</Button>
      <Button size="small" sx={{ py: '15px', color: "black", backgroundColor: 'white', flex: 1, borderRadius: '8px', border: '1px solid #C5C5C5' }} startIcon={<img width={16} src={icons.share_icon} alt='' />} onClick={getSocial}>Social</Button>
    </CardActions>
  </Card>
}

export default ContactCard;