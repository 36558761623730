import { useEffect, useState } from "react";
import DrawerComponent from "./drawer.component";
import auth from "../../api/auth";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { openActionDialog } from "src/features/action-dialog";
import { useNavigate } from "react-router-dom";
import { PAYMENT, PORTAL_PROFILE_LIST, PORTAL_SETTINGS, SUBSCRIPTION } from "src/lib/constants/routes";

function DrawerContainer({ toggleDrawer, open }: { toggleDrawer: any, open: boolean }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isConfirmed = useSelector<any>(state => state.actionDialog.isConfirmed);
  const isCancelled = useSelector<any>(state => state.actionDialog.isCancelled);
  const action = useSelector<any>(state => state.actionDialog.action);
  const cancelAction = useSelector<any>(state => state.actionDialog.cancelAction);
  const [subscriptionType, setSubscriptionType] = useState('');
  const [hasShown, setHasShown] = useState(false);
  const [endDate, setEndDate] = useState('');
  const [remainingTrial, setRemainingTrial] = useState(0);

  useEffect(() => {
    auth.getAccessLimit().then(response => {
      const expiredDate = response.data.end_date;
      setEndDate(moment(expiredDate).format('M/D/YYYY'));
      setSubscriptionType(response.data.subscription_type);

      if (localStorage.getItem('showExpirationPopup') === null) {
        localStorage.setItem('showExpirationPopup', '1');
      }

      if (response.data.subscription_type === 'Free Plan' && Boolean(response.data.end_date) && Boolean(Number(localStorage.getItem('showExpirationPopup')))) {
        setHasShown(true);
        dispatch(openActionDialog({
          title: Number(response.data.previous_subscriptions_id) === 4 ? 'Your free trial has expired' : 'Subscription Expired',
          description: 'You no longer have access to Lifestyle features. Multiple profiles, appointments and campaigns cannot be used. To continue using the features, you will need to upgrade your plan to Lifestyle Plan.',
          action: 'goToSubscribePage',
          cancelAction: 'selectProfile',
          cancelText: 'Not Now',
          confirmText: 'Subscribe',
        }));
      }

      var todayDate = moment().format('M/D/YYYY');

      const diffDays = moment(expiredDate).diff(todayDate, 'days');
      setRemainingTrial(diffDays);

    }).catch(console.log);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isConfirmed && action === 'goToSubscribePage') {
      localStorage.setItem('showExpirationPopup', '0');
      navigate(`${PORTAL_SETTINGS}/${SUBSCRIPTION}/${PAYMENT}`);
    }

    if (!Boolean(isConfirmed) && !Boolean(action) && hasShown) {
      localStorage.setItem('showExpirationPopup', '0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfirmed, action])

  useEffect(() => {
    if (isCancelled && cancelAction === 'selectProfile') {
      localStorage.setItem('showExpirationPopup', '0');
      navigate(`${PORTAL_PROFILE_LIST}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelled, cancelAction])

  return <DrawerComponent
    toggleDrawer={toggleDrawer}
    open={open}
    subscriptionType={subscriptionType}
    endDate={endDate}
    remainingTrial={remainingTrial}
  />
}

export default DrawerContainer;