import add from "./add.api";
import addVoucher from "./addVoucher.api";
import addVoucherCode from "./addVoucherCode.api";
import bindToProfile from "./bindToProfile";
import collectVoucherCode from "./collectVoucherCode.api";
import deleteCampaign from "./deleteCampaign.api";
import deleteFromProfile from "./deleteFromProfile.api";
import deleteVoucher from "./deleteVoucher.api";
import editStatus from "./editStatus.api";
import editVoucher from "./editVoucher.api";
import editVoucherCodeStatus from './editVoucherCodeStatus.api';
import editVoucherStatus from "./editVoucherStatus.api";
import generateVoucherCode from "./generateVoucherCode.api";
import getVoucherCodeList from './getVoucherCodeList.api';
import getVoucherCodeStatistic from './getVoucherCodeStatistic.api';
import getList from "./getList.api";
import getVoucherListByCampaign from './getVoucherListByCampaign.api';
import getVoucherSettings from "./getVoucherSettings.api";
import importVoucherCode from "./importVoucherCode.api";
import update from './update.api';
import updateProfileVoucherStatus from "./updateProfileVoucherStatus.api";

const campaignApi = {
  add,
  addVoucher,
  addVoucherCode,
  bindToProfile,
  collectVoucherCode,
  deleteCampaign,
  deleteFromProfile,
  deleteVoucher,
  editStatus,
  editVoucher,
  editVoucherCodeStatus,
  editVoucherStatus,
  generateVoucherCode,
  getVoucherCodeList,
  getVoucherCodeStatistic,
  getList,
  getVoucherListByCampaign,
  getVoucherSettings,
  importVoucherCode,
  update,
  updateProfileVoucherStatus
};

export default campaignApi
