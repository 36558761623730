import React, { useEffect, useState } from 'react'
import LandingPage from "./landingPage.component";
import { Error } from '../public/pages';
import { useSelector } from 'react-redux'
import basicDashboardApi from '../../api/basicDashboard';
import { getLocation } from '../../lib/helper/geoLocationHelper';

export default function LandingPageContainer() {
  const profileUUID = useSelector((state: any) => state.landingPage.profile.profile_uuid);
  const profiles = useSelector((state: any) => state.landingPage.profile);
  const isLoading = useSelector((state: any) => state.landingPage.isLoading);
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
    isGeoLocationAllowed: false
  })

  // Update view count with track location 
  const updateViewCount = async () => {
    getLocation().then(async (e: any) => {
      //check if user accepted location sharing
      if (location?.latitude === e.lat || location?.longitude === e.lng) return;
      setLocation({
        latitude: e.lat,
        longitude: e.lng,
        isGeoLocationAllowed: e.isGeoLocationAllowed
      })
      if (localStorage.getItem('lpMetadata') !== null) {
        const lpMetadata = JSON.parse(localStorage.getItem('lpMetadata') ?? '{}')
        // check if profile is already viewed by user
        if (lpMetadata.viewed) {
          // check if profile is same as viewed profile
          if (lpMetadata.profileUUID === profileUUID) return
          localStorage.setItem('lpMetadata', JSON.stringify({ profileUUID: profileUUID, viewed: true }))
        }
      } else {
        localStorage.setItem('lpMetadata', JSON.stringify({ profileUUID: profileUUID, viewed: true }))
      }

      // 1-Member 2-Guest
      let userType = 2
      if (localStorage.getItem('user') !== null) {
        const user = JSON.parse(localStorage.getItem('user') ?? '{}');
        // session expiration to date time
        var datetime = new Date(0);
        datetime.setUTCSeconds(user.session_expiration ?? 0);
        if (datetime.getMilliseconds() > Date.now()) userType = 1
      }
      await basicDashboardApi.updateViewCount({
        user_type: userType,
        profile_uuid: profileUUID,
        latitude: e?.lat || 0,
        longitude: e?.lng || 0,
        ip_address: '',
        is_geolocation_allowed: e?.isGeoLocationAllowed || false
      })
        .then(response => response)
        .catch(error => error)
    }).catch(() => console.error('Error getting location'));
  }

  useEffect(() => {
    if (profileUUID) {
      setTimeout(updateViewCount, 10000);
    }
    // eslint-disable-next-line
  }, [profileUUID]);

  if (isLoading) {
    return <></>
  } else if (profiles.length === 0) {
    return <Error />
  }

  return <LandingPage />
}
