import axios from "axios";
import { CampaignList } from "../../lib/types/Profile";
interface Data{
  code: number,
  data:  CampaignList[],
  message: string | null,
  success: boolean
}
const getCampaignList = (profileUUID:string): Promise<Data> => {
    const user = JSON.parse(localStorage.getItem('user') || "")
    const params = new URLSearchParams([['profile_uuid', profileUUID]]);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${user.access_token}`;
    
    return new Promise((resolve, reject) => {
        axios.get("profile/getCampaignList",{params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getCampaignList;
