import React from 'react'
import { styled } from '@mui/material/styles';
import { Box,Button, Typography } from '@mui/material';
import Lottie from 'react-lottie-player';
import completed from './completed.json'


const CustomContainer = styled("div")({
  display: "grid",
  justifyItems: "center",
  justifyContent: "space-around",
  minHeight: "100vh",
  alignItems: "center"
});
const BackgroundImage = styled("div")({
  display: "grid",
  width: "100%",
  position: "absolute"
})
const ContainBox = styled("div")({
  display: "grid",
  justifyContent: "space-between",
  justifyItems: "center"
})

export default function CompletedComponent({openProfileList,verifyFail}){
  return (
  <>
    <BackgroundImage>  
    </BackgroundImage>
    <CustomContainer>
      <ContainBox>
      <Lottie  
    animationData={completed}      
    play
    style={{ width: "858px",zIndex: "-1" }}/>
    <Box style={{maxWidth:'400px'}}>
    <Typography style={{
paddingBottom: '2rem',
fontSize: '24px',
fontWeight: '600',
lineHeight: '29px',
letterSpacing: '0em',
textAlign: 'center'


}}>
Welcome to Pixel's Community
</Typography>
<Typography  style={{
fontSize: '20px',
fontWeight: '400',
lineHeight: '24px',
letterSpacing: '0em',
textAlign: 'center'

}}paddingBottom={"3rem"}>
Get ready to engage, explore, and connect with people who share your interests.
</Typography> 
    </Box>

      <Button
        disabled={verifyFail}
              type="button"
              onClick={() => openProfileList()}
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: '30px', backgroundColor: 'black',color: '#FECD20',padding:'1rem 2rem' }}>
                Proceed
              </Button>
      </ContainBox>
    </CustomContainer>
  </>
  );
}