import axios from "axios";

interface Appointment {
  profile_uuid: string;
  appointment_uuid: string;
  name: string;
  profile_picture: string;
  date: string;
  time: string;
  type: string;
  status: string;
}

interface Data {
  success: boolean;
  code: string;
  message: string;
  data: Appointment[];
}

interface getInRangeProps {
  from: string;
  to: string;
  profile_uuid: string;
}

const getInRange = ({ from, to, profile_uuid }: getInRangeProps): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') ?? '{}');
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

  return new Promise((resolve, reject) => {
    axios.get('appointment/getInRange', {
      params: {
        from,
        to,
        profile_uuid,
      }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  });
};

export default getInRange;