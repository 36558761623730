import axios from "axios";
import { VoucherSettings } from '../../lib/types/Voucher'
interface Data{
  code: number,
  data:  VoucherSettings,
  message: string | null,
  success: boolean
}

const getVoucherSettings = ({voucherUUID}:{voucherUUID: string}): Promise<Data> => {
    const user = JSON.parse(localStorage.getItem('user') || "")
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${user.access_token}`;
    
    const params = new URLSearchParams([['voucher_uuid',voucherUUID]]);
    return new Promise((resolve, reject) => {
        axios.get("campaign/getVoucherSettings", {params})
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getVoucherSettings;
