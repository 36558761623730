import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  content: {
    title: "",
    description: "",
    fullWidth: false
  },
  isConfirmed: false,
  action: null,
};

const responseDialogSlice = createSlice({
  name: 'response_dialog',
  initialState,
  reducers: {
    openResponseDialog: (state, action) => {
      state.open = true;
      const { payload } = action;
      state.content.title = payload.title;
      state.content.description = payload.description;
      state.action = payload.action;
      if (payload?.fullWidth) {
        state.content.fullWidth = payload.fullWidth
      } else {
        state.content.fullWidth = false
      }
    },
    confirmResponseDialog: (state) => {
      state.isConfirmed = true;
    },
    closeResponseDialog: (state) => {
      state.open = false;
    },
  },
});

export const { openResponseDialog, confirmResponseDialog, closeResponseDialog } = responseDialogSlice.actions;
export const selectDialogOpen = (state) => state.response_dialog.open;
export const responseDialogReducer = responseDialogSlice.reducer;