import React from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CHECK_INBOX, SIGN_IN } from "../../../../lib/constants/routes.js";
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import accountApi from '../../../../api/account/index.js';
import { openResponseDialog } from '../../../../features/response-dialog/responseDialog.slice.js';
import LoadingButton from '@mui/lab/LoadingButton';
import images from '../../../../assets/img/index.js';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
      paper: '#F9F9F9',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

export default function ForgotPasswordComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs"
        style={{
          width: '336px',
          height: '100vh',
          display: 'grid',
          alignContent: 'space-around',
          justifyContent: 'center'
        }}>
        <CssBaseline />
        <Paper elevation={9}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem',
            borderRadius: '8.78px',
            width: "inherit"
          }}
        >
          <div style={{ margin: '20px' }}>
            <img src={images.pxlicon.toString()} style={{ maxWidth: '133px', maxHeight: "33" }} alt="Pixel Logo" />
          </div>
          <Typography component="h1" variant="h4" style={{ paddingBottom: '1rem' }}>
            Reset Password
          </Typography>
          <Typography sx={{ fontWeight: "400", textAlign: 'center' }}>
            Please enter your email address to reset <br /> your password
          </Typography>
          <Formik
            validateOnChange={false}
            initialValues={{ email: '' }}
            validate={values => {
              const errors: any = {};

              // Validate email
              if (!values.email) {
                errors.email = 'Required';
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              accountApi.getResetPasswordToken(values.email)
                .then(() => navigate(CHECK_INBOX))
                .catch(error => dispatch(openResponseDialog({
                  title: "Error",
                  description: error.response?.data.message ?? error.message,
                })))
                .finally(() => setSubmitting(false));
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: "-webkit-fill-available" }}>
                <Box style={{ paddingBottom: "2rem" }}>
                  <Typography component='label' className='required' variant='h5'>
                    Email
                  </Typography>
                  <TextField
                    error={Boolean(errors.email)}
                    color='secondary'
                    margin="dense"
                    required
                    fullWidth
                    size="small"
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={values.email}
                    onChange={handleChange}
                  />
                  <Typography component='label' className='validation-error' variant='h5'>
                    {errors.email}
                  </Typography>
                </Box>
                <Box style={{ padding: "0px 2rem" }}>
                  <LoadingButton
                    id="reset-button"
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={isSubmitting}
                    loadingPosition="start"
                    startIcon={isSubmitting ? <div /> : undefined}
                    sx={{ mt: 3, mb: 1, borderRadius: '30px', color: '#FECD20' }}
                  >
                    <span>{isSubmitting ? 'Please wait' : 'Continue'}</span>
                  </LoadingButton>

                  <Button
                    type="submit"
                    fullWidth
                    color='secondary'
                    variant="contained"
                    sx={{ mt: 1, mb: 2, borderRadius: '30px' }}
                    onClick={() => { navigate(SIGN_IN) }}
                  >
                    Back To Sign In
                  </Button>
                </Box>
              </Box>)}
          </Formik>

        </Paper>
      </Container>
    </ThemeProvider>
  );
}