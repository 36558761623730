import { useDispatch, useSelector } from "react-redux";
import CartDrawerComponent from "./cartDrawer.component";
import { useState, useEffect } from "react";
import { removeItem,toggleCartDrawer } from "../../features/cart";
import { useNavigate } from "react-router-dom";
import { BUY, CART, PORTAL_CARDS, TEMPLATES } from "src/lib/constants/routes";
import productApi from '../../api/product';
import { setItems } from "../../features/cart";
import { startLoading, endLoading } from "src/features/loading";
function CartDrawerContainer({ setOpen, open }: { setOpen: any, open: boolean }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const items = useSelector((state: any) => state.cart.items);
  const [totalPrice, setTotalPrice] = useState(0);
  const cartOpen = useSelector((state: any) => state.cart.open);

  const handleDelete = (index: number, cart_uuid: string) => {
    productApi.deleteCart(cart_uuid)
      .then(() => dispatch(removeItem(index)))
      .catch(console.log);
  }

  const setCartItem = () => {
    if (localStorage.getItem('cart') !== null && items.length === 0) {
      dispatch(setItems(JSON.parse(localStorage.getItem('cart') || '')));
    }
  }

  const getShoppingCartList = () => {
    dispatch(startLoading());
    productApi.getShoppingCartListing()
      .then(response => {
        if(response.data?.cart === undefined) return;
        
        const items = response.data?.cart.map(item => ({
          product_uuid: item.product_uuid,
          cart_uuid: item.cart_uuid,
          name: item.card_name,
          price: item.price,
          quantity: item.quantity,
          variant: {
            name: item.card_variation,
            front: item.front_thumbnail,
            back: item.back_thumbnail,
          },
          designid: item.design_id,
          personName: item.name,
          jobTitle: item.position,
          pixelcare: item.pixelcare,
          customization: item.customization,
          pixelcarePrice: item.pixelcare_price,
          pixelcareDiscountPrice: item.pixelcare_discount_price,
          totalCustomizationPrice: item.total_customization_price,
          totalPixelcarePrice: item.total_pixelcare_price,
        }));
        setTotalPrice(response.data.total_price);
        if (Boolean(items.length)) {
          dispatch(setItems(items));
        }
      }).finally(() => dispatch(endLoading()));
  }
  useEffect(() => {
    // Restore cart items from local storage
    setCartItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(open){
      getShoppingCartList();
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[open])

  useEffect(() => {
    if (cartOpen) {
      setOpen(true);
      dispatch(toggleCartDrawer()); // Set toggle to false when open throught redux
      getShoppingCartList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[cartOpen])
  return <CartDrawerComponent
    setOpen={setOpen}
    open={open}
    items={items}
    totalPrice={totalPrice}
    handleDelete={handleDelete}
    handleBack={() => {
      navigate(`${PORTAL_CARDS}/${BUY}/${TEMPLATES}`);
      setOpen(false);
    }}
    handleCheckout={() => {
      navigate(`${PORTAL_CARDS}/${CART}`);
      setOpen(false);
    }}
  />
}

export default CartDrawerContainer;