import axios from "axios";

interface Billing {
  address_1: string  | null,
  address_2: string  | null,
  city: string  | null,
  state: string  | null,
  zip: string  | null,
  country: string  | null,
}
interface Data{
  success: boolean,
  code: string,
  data: Billing,
  message: string,
}

const getBilling =  (): Promise<Data> => {
  const user = JSON.parse(localStorage.getItem('user') || "")
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.access_token}`;

    return new Promise((resolve, reject) => {
        axios.get("user/getBilling")
        .then(response => resolve(response.data))
        .catch(error => reject(error.response.data));
    })
};

export default getBilling;
