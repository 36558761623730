import React from 'react';
import { Box, Button, Typography, Stepper, Step, StepLabel, Divider, IconButton } from '@mui/material';
import FormBox from '../../../../../components/form-box';
import icons from '../../../../../assets/icons';
import moment from 'moment';
import NfcInvoice from '../../../../../components/nfc-invoice';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { PAYMENT_PAID } from '../../../../../lib/constants/paymentStatus';
import Card from './card.tsx';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CREDIT_T, CREDIT, FPX } from '../../../../../lib/constants/paymentChannel.js';
import { ORDER_START, ORDER_PENDING, ORDER_READY_TO_SHIP, ORDER_SHIPPING, ORDER_COMPLETED, ORDER_CANCELLED } from "../../../../../lib/constants/orderStatus.js"
const PDFoptions: any = {
  // default is `save`
  method: 'open',
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.NORMAL,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: 'letter',
    // default is 'portrait'
    orientation: 'portrait',
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    mimeType: 'image/png',
    qualityRatio: 1
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break, 
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true
    }
  },
};

const getOrderStatus = (status: number) => {
  switch (status) {
    case ORDER_START:
      return "Started";
    case ORDER_PENDING:
      return "Pending";
    case ORDER_READY_TO_SHIP:
      return "Ready to ship";
    case ORDER_SHIPPING:
      return "Shipping";
    case ORDER_COMPLETED:
      return "Completed";
    case ORDER_CANCELLED:
      return "Cancelled";
    default:
      return "Unknown";
  }
}

export default function DetailComponent({ handleView,
  orderDetail,
  payment,
  shipping,
  products,
  shippingStatus
}: {
  handleView: () => void,
  orderDetail: any,
  payment: any,
  shipping: any,
  products: any,
  shippingStatus: number,
}) {
  const theme = useTheme();
  const breakpointMd = useMediaQuery(theme.breakpoints.down('md'));
  const getTargetElement = () => document.getElementById('invoice-template');
  return (
    <>
      {breakpointMd ?
        // Mobile View
        <>
          <IconButton sx={{ mb: "1rem" }} onClick={() => handleView()}>
            <img src={icons.back_icon} alt="back_icon" />
          </IconButton>
          <FormBox>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              gap: '2rem'
            }}>
              <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <Box>
                  <Typography fontSize='13px'>Order ID: {orderDetail?.order_ref_id} </Typography>
                  <Typography fontSize='13px'>Order Date: {moment(orderDetail?.created_at).format('YYYY-MM-DD')} </Typography>
                </Box>
                {orderDetail?.payment_status === PAYMENT_PAID &&
                  <Button variant="contained" color="secondary" onClick={() => generatePDF(getTargetElement, PDFoptions)}>Invoice</Button>
                }
              </Box>
              <Box>
                <Typography fontWeight="500">Order Status</Typography>
                <Typography fontSize="13px">{getOrderStatus(orderDetail?.status)}</Typography>
              </Box>
              {products?.length > 0 && <Box sx={{ display: "flex", justifyContent: 'space-between' }}>
                <Box>
                  <Typography fontWeight="500"> Shipping Information</Typography>
                  {shipping?.courier &&
                    <>
                      <Typography fontSize="13px">{shipping?.courier}</Typography>
                      <Typography fontSize="13px">{shipping?.track_number}</Typography>
                    </>
                  }
                  {(orderDetail?.status === ORDER_START || orderDetail?.status === ORDER_PENDING) ?
                    <>
                      <Typography fontSize="13px" color="orange">ORDER PROCESSING</Typography>
                    </>
                    : <>
                      <Typography fontSize="13px">ORDER CONFIRMED</Typography>
                      <Typography fontSize="13px">{moment(orderDetail?.updated_at).format('DD-MM-YYYY hh:mm')}</Typography>
                    </>
                  }
                </Box>
                {/* <Link>VIEW</Link> */}
              </Box>}
              {products?.length > 0 && <Box sx={{ display: "flex" }}>
                <Box>
                  <Typography fontWeight="500"> Delivery Address</Typography>
                  <Typography fontSize="13px" lineHeight="1">
                    {shipping?.address_1}<br />
                    {shipping?.address_2}<br />
                    {shipping?.zip} &nbsp;  {shipping?.city}<br />
                    {shipping?.state}
                  </Typography>
                </Box>
              </Box>}
            </Box>
          </FormBox>
          <FormBox>
            {/* Cards Info - Start */}
            {
              products?.length > 0 && products.map((card: any, index: number) => (
                <Box key={index} sx={{ pb: '1rem' }}>
                  <Box sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: "1rem"
                  }}>
                    <Box sx={{ display: "flex" }}>
                      <Card card={card} />
                    </Box>
                    <Box sx={{
                      display: "flex", alignContent: 'flex-end', flexDirection: "column", justifyContent: "flex-start",
                      '& .MuiTypography-root': {
                        fontSize: '13px'
                      }
                    }}>
                      <Typography>{card?.name}</Typography>
                      <Typography>{card?.card_variation} {card?.person_name && " | " + card?.person_name} {card?.position && " | " + card?.position} </Typography>
                      <Typography>USD &nbsp;  {card?.price}</Typography>
                      {/* <Typography>{orderDetail?.currency} &nbsp;  {card?.price}</Typography> */}
                      {card?.customization === 1 &&
                        <Typography sx={{ fontSize: "11px !important" }}>Customize(USD): {card?.customization_price}</Typography>
                        // <Typography sx={{fontSize:"11px !important"}}>Customize({orderDetail?.currency}): {card?.customization_price}</Typography>
                      }
                      {card?.pixelcare === 1 &&
                        <Typography sx={{ fontSize: "11px !important" }}>Pixelcare(USD): {card?.pixelcare_price}</Typography>
                        // <Typography sx={{fontSize:"11px !important"}}>Pixelcare({orderDetail?.currency}): {card?.pixelcare_price}</Typography>
                      }
                      <Typography>Qty: {card?.quantity}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))
            }
            {/* Cards Info - End */}
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem"
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
              }}>
                <Typography fontWeight="500">Order Summary</Typography>
                <Box sx={{
                  display: orderDetail?.discount ? "flex" : "none",
                  justifyContent: "space-between"
                }}>
                  <Typography >Discount </Typography>
                  <Typography >{orderDetail?.discount}</Typography>
                </Box>
                <Box sx={{
                  display: orderDetail?.shipping ? "flex" : "none",
                  justifyContent: "space-between"
                }}>
                  <Typography> Shipping</Typography>
                  <Typography>{orderDetail?.currency} 0.00</Typography>
                </Box>
                <Box sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: "5px"
                }}>
                  <Typography fontWeight="500">Total({orderDetail?.currency})</Typography>
                  <Typography>{orderDetail.total_amount}</Typography>
                </Box>
              </Box>
              <Divider />
              <Box sx={{
                display: "flex",
                justifyContent: "space-between"
              }}>
                {payment?.channel &&
                  <>
                    <Typography>Payment Method</Typography>
                    <Typography>
                      {payment?.channel === CREDIT && 'Credit Card'}
                      {payment?.channel === CREDIT_T && 'Credit Card'}
                      {payment?.channel === FPX && 'Online Banking'}
                    </Typography>
                  </>
                }
              </Box>
            </Box>

          </FormBox>
        </>
        : <>
          {/* Web View */}
          <IconButton sx={{ mb: '1rem' }} onClick={() => handleView()}>
            <img src={icons.back_icon} alt="back_icon" />
          </IconButton>
          <FormBox>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              gap: '2rem'
            }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h2" fontWeight="500">Order ID: {orderDetail?.order_ref_id} </Typography>
                {orderDetail?.payment_status === PAYMENT_PAID &&
                  <Button variant="contained" color="secondary" onClick={() => generatePDF(getTargetElement, PDFoptions)}>Invoice</Button>
                }

              </Box>
              <Box>
                <Typography component={'p'}>Order date: <label> {moment(orderDetail?.created_at).format('MMM DD, YYYY')}</label></Typography>
                <Typography component={'p'}>Order status: <label>{getOrderStatus(orderDetail?.status)}</label></Typography>
                {/* &nbsp; | &nbsp; */}
                {/* {products?.length > 0 && <Typography>
                Estimated delivery: May 16, 2022 <label> (Tracking Number: {shipping?.track_number || "N/A"})</label>
              </Typography>} */}
              </Box>
              {products?.length > 0 && <Box>
                <Stepper activeStep={shippingStatus} alternativeLabel>
                  <Step>
                    <StepLabel>Order Confirmed</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Shipping</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Delivered</StepLabel>
                  </Step>
                </Stepper>
              </Box>}
              {/* Cards Info - Start */}
              {
                products?.length > 0 && products.map((card: any, index: number) => (
                  <Box key={index}>
                    <Box sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr 5fr",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      gap: "2rem"
                    }}>

                      <Card card={card} />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <Typography>{card?.card_variation} {card?.person_name && " | " + card?.person_name} {card?.position && " | " + card?.position} </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <Typography>{card?.name}</Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          <Typography>Qty: {card?.quantity}</Typography>
                          <Typography>USD &nbsp;  {card?.price}</Typography>
                          {/* <Typography>{orderDetail?.currency} &nbsp;  {card?.price}</Typography> */}
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          {card?.customization === 1 &&
                            <>
                              <Typography>Customization</Typography>
                              <Typography>USD &nbsp;  {card?.customization_price}</Typography>
                              {/* <Typography>{orderDetail?.currency} &nbsp;  {card?.customization_price}</Typography> */}
                            </>
                          }
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                          {card?.pixelcare === 1 && <>
                            <Typography> Pixelcare</Typography>
                            <Typography>USD &nbsp;  {card?.pixelcare_price}</Typography>
                            {/* <Typography>{orderDetail?.currency} &nbsp;  {card?.pixelcare_price}</Typography> */}
                          </>}

                        </Box>
                      </Box>

                    </Box>
                  </Box>
                ))
              }
              {/* Cards Info - End */}

              <hr></hr>
              <Box sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr"
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem"
                }}>
                  <Box>
                    <Typography fontWeight="500">Order Time</Typography>
                    <Typography >{moment(orderDetail?.created_at).format('YYYY-MM-DD hh:mm')}</Typography>
                  </Box>
                  <Box>
                    <Typography fontWeight="500">Payment Time</Typography>
                    <Typography >{moment(orderDetail?.payment?.created_at).format('YYYY-MM-DD hh:mm')}</Typography>
                  </Box>
                  <Box>
                    {payment?.channel && <>
                      <Typography fontWeight="500">Payment Method</Typography>
                      <Typography >
                        {payment?.channel === CREDIT && 'Credit Card'}
                        {payment?.channel === CREDIT_T && 'Credit Card'}
                        {payment?.channel === FPX && 'Online Banking'}
                      </Typography>
                    </>
                    }

                  </Box>
                </Box>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem"
                }}>
                  {products?.length > 0 && <Box>
                    <Typography fontWeight="600">Delivery Address :</Typography>
                    <Typography >
                      {shipping?.address_1}<br></br>
                      {shipping?.address_2}<br></br>
                      {shipping?.zip} &nbsp;  {shipping?.city}<br></br>
                      {shipping?.state}
                      {shipping?.country}
                    </Typography>
                  </Box>}
                  <Box>
                    <Typography fontWeight="500">Contact</Typography>
                    <Typography >Phone</Typography>
                    <Typography >{payment?.bill_mobile}</Typography>
                  </Box>
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: '0.5rem'
                  }}>
                    <Typography fontWeight="500">Order Summary</Typography>
                    <Box sx={{
                      display: orderDetail?.discount ? "flex" : "none",
                      justifyContent: "space-between"
                    }}>
                      <Typography >Discount </Typography>
                      <Typography >{orderDetail?.discount}</Typography>
                    </Box>
                    <Box sx={{
                      display: orderDetail?.shipping ? "flex" : "none",
                      justifyContent: "space-between",
                      padding: '1rem',
                    }}>
                      <Typography> Shipping</Typography>
                      <Typography>{orderDetail?.currency} {orderDetail?.shipping_fee}</Typography>
                    </Box>
                    <Box sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: "#FFF2C5",
                      padding: '1rem',
                      borderRadius: "5px"
                    }}>
                      <Typography fontWeight="500">Total({orderDetail?.currency})</Typography>
                      <Typography>{orderDetail.total_amount}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

            </Box>
          </FormBox>
          <div style={{ opacity: 0 }}><NfcInvoice order={orderDetail} /></div>
        </>}
    </>
  );
}