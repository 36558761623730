import React, { useEffect } from "react";
import ApplyCardComponent from "./applyCard.component";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PORTAL_CARDS, TEMPLATES } from "../../../../../../lib/constants/routes";
import { setBreadcrumb } from "../../../../../../features/admin";
import { useDispatch } from "react-redux";

export default function ApplyCardContainer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "My NFC Card", path: PORTAL_CARDS },
        { name: "Buy Card", path: null }
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApplyTemplate = () => navigate({
    pathname: TEMPLATES,
    search: createSearchParams({ type: 'template' }).toString()
  });

  const handleApplyCustom = () => navigate({
    pathname: TEMPLATES,
    search: createSearchParams({ type: 'custom' }).toString()
  });

  return <ApplyCardComponent
    handleBack={() => navigate(PORTAL_CARDS)}
    handleApplyTemplate={handleApplyTemplate}
    handleApplyCustom={handleApplyCustom}
  />
}