import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import FileHelper from "../../../../../lib/helper/fileHelper";

export default function Card({ card }) {
  const isZakeke = Boolean(card.design_id);
  const [frontURL, setFrontURL] = useState<string>();

  useEffect(() => {
    const front_thumb = card?.front_thumbnail;

    if (Boolean(front_thumb)) {
      if (front_thumb.slice(0, 5) !== 'https' && front_thumb.slice(0, 10) !== 'data:image') {
        FileHelper.urlToBase64(front_thumb).then(response => {
          setFrontURL(response.base64);
        })
      } else {
        setFrontURL(front_thumb);
      }
    }
  }, [card]);

  return <Box sx={{
    width: '250px',
    height: '250px',
    border: '1px solid #A7A7A7',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  }}>
    <img
      alt='Card preview'
      src={frontURL}
      style={{
        maxWidth: isZakeke ? '500px' : '90%',
        maxHeight: isZakeke ? 'none' : '90%',
        borderRadius: '8px',
        boxShadow: isZakeke ? 'none' : '0 0 15px rgba(0, 0, 0, 0.2)',
      }}
    />
  </Box>
}