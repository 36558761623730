import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { IconButton, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import icons from '../../../../assets/icons';
import { LoadingButton } from '@mui/lab';
import { Formik } from 'formik';
import accountApi from '../../../../api/account';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openErrorDialog } from '../../../../features/error-dialog';
import { RESET_PASSWORD_EXPIRED } from '../../../../lib/constants/routes';
import { openResponseDialog } from '../../../../features/response-dialog';
import images from '../../../../assets/img';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#FECD20',
    },
    action: {
      selected: '#FECD20',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#FECD20',
    }
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    h4: {
      fontSize: '17px',
      fontWeight: 500,
    },
    h5: {
      fontSize: '13px',
      fontWeight: 400,
    },
    h6: {
      fontSize: '10px',
      fontWeight: 500,
    },
    body1: {
      fontSize: '12px',
      fontWeight: 500,
    },
    body2: {
      fontSize: '10px',
      fontWeight: 400,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#000000',
          backgroundColor: '#FFEFBA',
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          color: '#A7A7A7',
          '&.Mui-selected': {
            color: '#000000',
            backgroundColor: '#FECD20',
          },
          "&:hover": {
            backgroundColor: "#FFEFBA",
          },
        },
      },
    },
  },
});

const PasswordValidation = styled('div')({
  display: 'flex',
  gap: '0.5rem',
  flexDirection: 'row',
  alignItems: 'flex-start'
})

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ResetPasswordComponent() {
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);

  return <ThemeProvider theme={defaultTheme}>
    <Container component="main" maxWidth="xs"
      style={{
        width: '336px',
        height: '100vh',
        display: 'grid',
        alignContent: 'space-around',
        justifyContent: 'center'
      }}>
      <CssBaseline />
      <Paper elevation={9}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '1.5rem 1rem 1rem 1rem',
          borderRadius: '8.78px',
          width: "inherit"
        }}
      >
        <div style={{ margin: '20px' }}>
          <img src={images.pxlicon.toString()} style={{ maxWidth: '133px', maxHeight: "33" }} alt="Pixel Logo" />
        </div>
        <Box style={{ textAlign: 'center' }}>
          <Typography component="h1" variant="h4">
            Reset Password
          </Typography>
          <Typography component="h2" variant="h5">
            Your new password must be different <br />
            from previously used.
          </Typography>
        </Box>
        <Formik
          initialValues={{
            password: '',
            confirmpw: '',
            cond1: false,
            cond2: false,
            cond3: false,
            cond4: false,
          }}
          validate={values => {
            const errors: any = {};

            // Validate password
            if (values.password.length <= 8) {
              errors.cond1 = 'Password must be over 8 characters';
            }

            if (values.password.match(/\d/g) === null) {
              errors.cond2 = 'Password must contain 1 number, at least';
            }

            if (values.password.match(/[!@#$%^&*()\-_=+]/g) === null) {
              errors.cond3 = 'Pasword must contain 1 special character, at least';
            }

            if (values.password.match(/(.*[A-Z].*[a-z].*)|(.*[a-z].*[A-Z].*)/g) === null) {
              errors.cond4 = 'Password must contain at least 1 upper case letter and at least 1 lower case letter';
            }

            // Validate password confirmation
            if (values.confirmpw !== values.password) {
              errors.confirmpw = 'Password does not match';
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            const email = query.get('email') ?? '';
            const requestBody = {
              email,
              reset_password_token: query.get('reset_password_token') ?? '',
              new_password: values.password,
              confirm_password: values.confirmpw,
            }

            accountApi.resetPassword({ requestBody })
              .then(() => navigate('success'))
              .catch(error => {
                if (Number(error.response?.data.code) === 3) {
                  dispatch(openErrorDialog({
                    title: "Token Expired",
                    description: "Reset Password Token has expired",
                    redirectPath: RESET_PASSWORD_EXPIRED + `?email=${encodeURIComponent(email)}`
                  }));
                } else {
                  dispatch(openResponseDialog({
                    title: "Error",
                    description: error.response?.data.message ?? error.message,
                  }))
                }
              })
              .finally(() => setSubmitting(false));
          }}
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <div>
                <Typography component='label' className='required' variant='h5'>
                  New Password
                </Typography>
                <TextField
                  color='secondary'
                  margin="dense"
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: <IconButton sx={{ opacity: 0.5 }} aria-label="show" size="small" onClick={() => setShowPassword(!showPassword)}>
                      <img src={showPassword ? icons.eye_close : icons.eye_open_icon} alt="eye_open_icon" />
                    </IconButton>,
                  }}
                />

                <Box style={{ padding: '1rem 0' }}>
                  <PasswordValidation>
                    {!values.password || errors.cond1 ? <img src={icons.check_incorrect.toString()} alt="incorrect" /> : <img src={icons.check_correct.toString()} alt="correct" />}
                    <Typography component='p' variant='h5'>
                      Password must be over 8 characters
                    </Typography>
                  </PasswordValidation>
                  <PasswordValidation>
                    {!values.password || errors.cond2 ? <img src={icons.check_incorrect.toString()} alt="incorrect" /> : <img src={icons.check_correct.toString()} alt="correct" />}
                    <Typography component='p' variant='h5'>
                      Password must contain 1 number
                    </Typography>
                  </PasswordValidation>
                  <PasswordValidation>
                    {!values.password || errors.cond3 ? <img src={icons.check_incorrect.toString()} alt="incorrect" /> : <img src={icons.check_correct.toString()} alt="correct" />}
                    <Typography component='p' variant='h5'>
                      Password must contain 1 special character
                    </Typography>
                  </PasswordValidation>
                  <PasswordValidation>
                    {!values.password || errors.cond4 ? <img src={icons.check_incorrect.toString()} alt="incorrect" /> : <img src={icons.check_correct.toString()} alt="correct" />}
                    <Typography component='p' variant='h5'>
                      Password must contain 1 upper case and 1 lower case letter
                    </Typography>
                  </PasswordValidation>
                </Box>
                <Typography component='label' className='required' variant='h5'>
                  Confirm Password
                </Typography>
                <TextField
                  error={Boolean(errors.confirmpw && touched.confirmpw && errors.confirmpw)}
                  color='secondary'
                  margin="dense"
                  required
                  fullWidth
                  name="confirmpw"
                  type={showPassword ? "text" : "password"}
                  id="confirmpw"
                  InputProps={{
                    endAdornment: <IconButton sx={{ opacity: 0.5 }} aria-label="show" size="small" onClick={() => setShowPassword(!showPassword)}>
                      <img src={showPassword ? icons.eye_close : icons.eye_open_icon} alt="eye_open_icon" />
                    </IconButton>,
                  }}
                  value={values.confirmpw}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Typography component='label' className='validation-error' variant='h5'>
                  {errors.confirmpw && touched.confirmpw && errors.confirmpw}
                </Typography>
              </div>

              <LoadingButton
                id="signin-button"
                type="submit"
                fullWidth
                variant="contained"
                loading={isSubmitting}
                loadingPosition="start"
                startIcon={isSubmitting ? <div /> : undefined}
                sx={{ mt: 3, mb: 1, borderRadius: '30px', color: '#FECD20' }}
              >
                <span>{isSubmitting ? 'Verifying' : 'Continue'}</span>
              </LoadingButton>
            </Box>)}
        </Formik>
      </Paper>
    </Container>
  </ThemeProvider>
}