import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../../../features/admin';
import { BUY, PORTAL_CARDS } from "../../../../lib/constants/routes";
import SelectTemplateComponent from "./selectTemplate.component";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import productApi from "../../../../api/product";
import moment from 'moment';
import FileHelper from "../../../../lib/helper/fileHelper";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function SelectTemplateContainer() {
  const query = useQuery();
  const type: string = query.get('type') ?? 'template';
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [templates, setTemplates] = useState<any[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [subCategories, setSubCategories] = useState<string[]>([]);
  const [ordering, setOrdering] = useState('newest');

  useEffect(() => {
    dispatch(setBreadcrumb({
      breadcrumb: [
        { name: "My NFC Card", path: PORTAL_CARDS },
        { name: "Buy Card", path: `${PORTAL_CARDS}/${BUY}` },
        { name: "Select Template", path: null },
      ]
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    productApi.getProducts({
      source: type === 'template' ? 1 : 2,
      category: categories.join(','),
      sub_category: subCategories.join(','),
    }).then(response => {
      Promise.all(response.data.map(async (product) => ({
        ...product,
        uuid: product.product_uuid,
        name: product.card_name,
        current_price: Number(product.discount_price),
        previous_price: Number(product.price),
        is_popular: product.is_popular === 1,
        thumbnail: (product.card_thumbnail.slice(0, 5) !== 'https') ? await FileHelper.getUrl(product.card_thumbnail) : product.card_thumbnail,
        orientation: product.orientation
      }))).then(setTemplates);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, subCategories]);

  useEffect(() => {
    if (ordering === 'newest') {
      setTemplates([...templates].sort((a, b) => {
        if (moment(a.created_at).isAfter(b.created_at)) {
          return -1;
        } else if (moment(a.created_at).isBefore(b.created_at)) {
          return 1;
        }

        return 0;
      }));
    } else if (ordering === 'oldest') {
      setTemplates([...templates].sort((a, b) => {
        if (moment(a.created_at).isAfter(b.created_at)) {
          return 1;
        } else if (moment(a.created_at).isBefore(b.created_at)) {
          return -1;
        }

        return 0;
      }));
    } else if (ordering === 'low_high') {
      setTemplates([...templates].sort((a, b) => a.current_price - b.current_price));
    } else if (ordering === 'high_low') {
      setTemplates([...templates].sort((a, b) => b.current_price - a.current_price));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ordering]);

  const handleSelect = (productUUID: string) => {
    if (type === 'template') {
      navigate(productUUID);
    } else {
      navigate({
        pathname: productUUID,
        search: createSearchParams({ type: 'custom' }).toString(),
      })
    }
  }

  return templates.length > 0 && <SelectTemplateComponent
    type={type}
    templates={templates}
    handleSelect={handleSelect}
    setCategories={setCategories}
    setSubCategories={setSubCategories}
    ordering={ordering}
    setOrdering={setOrdering}
  />
}