import axios from "axios";

interface Data {
  code: number;
  data: Array<Voucher>;
  message: string | null;
  success: boolean;
}

interface Voucher {
  campaign_uuid: string;
  campaign_name: string;
  voucher_uuid: string;
  voucher_title: string;
  banner_image: string;
  validation_type: string;
  discount_type: string;
  active: boolean;
  start_date_time: boolean;
  end_date_time: string;
}

const getVoucherListByProfile = ({ profileUUID }: { profileUUID: string }): Promise<Data> => {
  const params = new URLSearchParams([['profile_uuid', profileUUID]]);

  return new Promise((resolve, reject) => {
    axios.get("basiccampaign/getVoucherListByProfile", { params })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default getVoucherListByProfile;
export type { Voucher as VoucherType }