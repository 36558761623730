import React from 'react'
import FloatingButton from './floatingButton.component'
import { useSelector } from "react-redux";
import { PHONE_HOME, PHONE_MOBILE, ADDRESS, EMAIL } from '../../../lib/constants/basiccontacttypes';
import FileHelper from '../../../lib/helper/fileHelper';
import { useNavigate } from 'react-router-dom';
import { BOOK_APPOINTMENT, EXCHANGE_CONTACT, SHARE_QR } from '../../../lib/constants/routes';
import makeVCard from '../createVcard';

export default function FloatingButtonContainer() {
  const navigate = useNavigate();
  const profile = useSelector((state: any) => state.landingPage.profile);
  const userDetails = profile.user_details;
  const contactInfo = profile.contact_info;

  const handleShareQR = () => {
    navigate(SHARE_QR);
  }

  const handleBookAppointment = () => {
    navigate(BOOK_APPOINTMENT)
  }

  const handleExchangeContact = () => {
    return navigate(EXCHANGE_CONTACT);
  }

  const setUserContact = async () => {
    let userContact = {
      homePhone: null,
      mobilePhone: null,
      address: null,
      email: null
    };
    if (contactInfo.content) {
      if (contactInfo?.content.length > 0) {
        contactInfo.content.map((value) => {
          if (value.category === PHONE_HOME) userContact.homePhone = value.info
          if (value.category === PHONE_MOBILE) userContact.mobilePhone = value.info
          if (value.category === ADDRESS) userContact.address = value.info
          if (value.category === EMAIL) userContact.email = value.info

          return value
        })
      }
    }
    let img = await FileHelper.urlToBase64(userDetails.profile_image).then(repsonse => repsonse);

    return {
      phone: userContact?.mobilePhone || userContact?.homePhone,
      address: userContact?.address || "",
      email: userContact?.email || "",
      name: userDetails.full_name,
      company: userDetails.company_name,
      title: userDetails.job_title,
      img: img
    }
  }

  const handleSaveContact = () => {
    setUserContact().then(response => {
      if (response === undefined) return;

      const websites = contactInfo.content.filter(item => item.category.toLowerCase() === 'website');
      const social_sites = profile.social_info.content;
      const contact = {
        ...response,
        websites,
        social_sites,
        source: `${process.env.REACT_APP_BASE_URL}/w/${profile.public_name ?? profile.serial_number}`,
        note: userDetails.description,
      };

      const vcard = makeVCard(contact);
      var blob = new Blob([vcard], { type: "text/vcard" });
      var url = URL.createObjectURL(blob);
      const newLink = document.createElement('a');
      newLink.download = response.name + ".vcf";
      newLink.textContent = response.name;
      newLink.href = url;
      newLink.click();
    })
  }

  return <FloatingButton
    isEnabledAppointment={profile.appointment_status}
    handleSaveContact={handleSaveContact}
    handleShareQR={handleShareQR}
    handleBookAppointment={handleBookAppointment}
    handleExchangeContact={handleExchangeContact}
  />
} 