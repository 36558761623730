import addCard from "./addOrder.api";
import getDetails from "./getDetails.api";
import fail from "./fail.api";
import getList from "./getList.api";
import repaid from "./repaid.api";
import subscribe from "./subscribe.api";
import testSubscribe from "./testSubscribe.api";

const orderApi = {
  addCard,
  getDetails,
  fail,
  getList,
  repaid,
  subscribe,
  testSubscribe
};

export default orderApi;