import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Divider, Link, ThemeProvider, Typography, createTheme } from '@mui/material';
import icons from '../../../../../assets/icons';
import orderApi from '../../../../../api/order';
import { useNavigate } from 'react-router-dom';
import { PORTAL, PORTAL_CARDS } from '../../../../../lib/constants/routes';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import NfcInvoice from '../../../../../components/nfc-invoice';
import { useDispatch } from 'react-redux';
import { setItems } from "../../../../../features/cart";
import useQuery from '../../../../../lib/helper/queryHelper';

const defaultTheme = createTheme({
  palette: {
    secondary: {
      main: '#373736',
      contrastText: '#FECD20',
    },
  },
  typography: {
    fontFamily: 'Rubik Variable, sans-serif',
    button: {
      textTransform: 'none',
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "30px"
        }
      },
    },
  },
});

const PDFoptions = {
  // default is `save`
  method: 'open',
  // default is Resolution.MEDIUM = 3, which should be enough, higher values
  // increases the image quality but also the size of the PDF, so be careful
  // using values higher than 10 when having multiple pages generated, it
  // might cause the page to crash or hang.
  resolution: Resolution.NORMAL,
  page: {
    // margin is in MM, default is Margin.NONE = 0
    margin: Margin.SMALL,
    // default is 'A4'
    format: 'letter',
    // default is 'portrait'
    orientation: 'portrait',
  },
  canvas: {
    // default is 'image/jpeg' for better size performance
    mimeType: 'image/png',
    qualityRatio: 1
  },
  // Customize any value passed to the jsPDF instance and html2canvas
  // function. You probably will not need this and things can break, 
  // so use with caution.
  overrides: {
    // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
    pdf: {
      compress: true
    },
    // see https://html2canvas.hertzen.com/configuration for more options
    canvas: {
      useCORS: true
    }
  },
};

export default function Success() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const orderUUID = useQuery().get("order_uuid") || ''

  const getDetails = async (orderUUID: string) => {
    await orderApi.getDetails(orderUUID)
      .then((response) => {
        setOrderDetails(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (orderUUID) getDetails(orderUUID)
  }, [orderUUID]);

  useEffect(() => {
    // Clear cart
    dispatch(setItems([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getTargetElement = () => document.getElementById('invoice-template');
  return <ThemeProvider theme={defaultTheme}>
    <Container id="content-id" component="main" maxWidth="sm" sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      alignContent: 'space-around',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '20px',
    }}>
      <img
        src={icons.buycard_success}
        alt="Card being delivered on a truck"
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: '270px'
        }}
      />
      <Typography sx={{ fontSize: '31px', fontWeight: 500, textAlign: 'center', mt: '20px' }}>
        Payment Successful
      </Typography>
      <Typography sx={{ fontSize: '20px', fontWeight: 400, textAlign: 'center' }}>
        Your payment was successful. A receipt for this purchase has been sent to your email.
      </Typography>
      <Box sx={{
        bgcolor: '#F5F5F5',
        borderRadius: '10px',
        p: '20px',
        width: '-webkit-fill-available',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>Order ID</Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: 400 }}>{orderDetails?.order_ref_id}</Typography>
        </Box>
        <Divider sx={{ my: '20px' }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Total</Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>{orderDetails?.currency} {orderDetails?.total_amount} (PAID)</Typography>
        </Box>
      </Box>
      <Box sx={{
        bgcolor: '#F5F5F5',
        borderRadius: '10px',
        p: '20px',
        width: '-webkit-fill-available',
      }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>Receipt</Typography>
          <Link sx={{ color: '#006BCF', textDecorationColor: '#006BCF' }}>
            <Typography sx={{ fontSize: '20px', fontWeight: 500, cursor: 'pointer' }} onClick={() => generatePDF(getTargetElement, PDFoptions)}>Download</Typography>
          </Link>
        </Box>
      </Box>
      {Number(orderDetails?.type) === 1 && <Typography sx={{ fontWeight: 400, textAlign: 'center', mb: '20px' }} gutterBottom>
        Your order is on the way! Please allow 4-7 days for shipping.
      </Typography>}
      <Button
        variant='contained'
        color='secondary'
        sx={{ px: '40px', maxWidth: '115px' }}
        onClick={() => {
          if (Number(orderDetails?.type) === 1) {
            navigate(PORTAL_CARDS);
          } else {
            navigate(PORTAL);
          }
        }}
      >
        Done
      </Button>
    </Container>
    <NfcInvoice order={orderDetails} />
  </ThemeProvider>
}