import addCard from "./addCard.api";
import bindProfile from "./bindProfile.api";
import getCards from "./getCardList.api";
import receiveCard from "./receiveCard.api";

const cardApi = {
  addCard,
  bindProfile,
  getCards,
  receiveCard,
};

export default cardApi;