import React,{ useEffect } from "react";
import { Navigate, Route, useLocation,Routes  } from "react-router-dom"
// import logo from './logo.svg';
import './App.css';
import ContactList from './modules/contact-list';
import ContactDetail from "./modules/contact-list/page";
import Dashboard from './Dashboard.tsx';
import Unverified from './Unverified.tsx';
import SignIn from './modules/public/pages/sign-in';
import SignUp from './modules/public/pages/sign-up';
import Setting from './modules/setting';
import {AdminLayout, LandingPageLayout, OldLandingPageLayout} from './components/layout';
import { PersonalInfo, Completed, ForgotPassword, CheckInbox, ResetPassword, GuestSignUp, Error, ProfileOption, VoucherProfileOption } from "./modules/public/pages";
import Success from "./modules/public/pages/reset-password/success"
import Expired from "./modules/public/pages/reset-password/expired/expired";
import LandingPage from "./modules/landing-page";
import ShareQr from "./modules/landing-page/share-qr";
import ExchangeContact from "./modules/landing-page/exchange-contact";
import MemberConnected from "./modules/landing-page/exchange-contact/pages/member-connected";
import VoucherMemberConnected from "./modules/landing-page/vouchers/pages/member-connected";
import ExContactExpired from "./modules/landing-page/exchange-contact/pages/expired"
import VerifyExpired from "./modules/public/pages/completed/verify-expired";
import { MemberSignIn, PersonalInfo as ExContactPersonalInfo, Completed as ExVerifyCompleted} from "./modules/public/pages/exchange-contact"
import ProfileList from "./modules/my-profile/page/profile-list-page";
import CampaignList from "./modules/privilege/page/campaign-list";
import CampaignDetail from "./modules/privilege/page/campaign-detail";
import * as routes from "./lib/constants/routes.js";
import BookAppointment from "./modules/landing-page/book-appointment/index.js";
import BookAppointmentSummary from "./modules/landing-page/book-appointment/pages/summary/index.js";
import BookAppointmentConfirmed from "./modules/landing-page/book-appointment/pages/confirmed/index.js";
import Appointment from "./modules/appointment/index.js";
import ProfileDetails from "./modules/my-profile/components/details";
import VoucherCreate from "./modules/privilege/page/voucher-form/create";
import VoucherCode from "./modules/privilege/page/voucher-code";
import VoucherCodeCreate from "./modules/privilege/page/voucher-code/create";
import VoucherEdit from "./modules/privilege/page/voucher-form/edit";
import CollectedVoucherPage from "./modules/wallet";
import RedeemVoucher from "./modules/wallet/page/redeemVoucher";
import VoucherDetails from "./modules/landing-page/vouchers/pages/voucher-details/index.js";
import VoucherSignIn from "./modules/public/pages/voucher-profile-option/signIn.tsx";
import VoucherSignUp from "./modules/public/pages/voucher-profile-option/signUp.tsx";
import CardList from "./modules/my-cards/index.js";
import SelectTemplate from "./modules/my-cards/pages/select-template/index.js";
import EditTemplate from "./modules/my-cards/pages/select-template/pages/edit/index.js";
import ApplyCard from "./modules/my-cards/pages/select-template/pages/apply";
import Cart from "./modules/my-cards/pages/cart/index.js";
import ValidateVoucher from "./modules/wallet/page/validate-voucher";
import ValidateSuccess from "./modules/wallet/page/validate-success";
import Zakeke from "./modules/zakeke/index.js";
import EditCart from "./modules/my-cards/pages/cart/pages/editCart/index.js";
import { Success as CardSuccess, Pending as CardPending, Fail as CardFail } from "./modules/public/pages/payment/card";
import Subscription from "./modules/subscription/pages/apply/index.js";
import SubscriptionPayment from "./modules/subscription/pages/payment/index.js"; 
import ContactForm from "./modules/subscription/pages/contact-form/index.js"; 
import TestSubscription from "./modules/test-subscription/pages/apply/index.js";
import TestSubscriptionPayment from "./modules/test-subscription/pages/payment/index.js";
import TestContactForm from "./modules/test-subscription/pages/contact-form/index.js";
import Pricing from "./modules/public/pages/pricing/index.js";
import ContactUs from "./modules/public/pages/contact-us/index.js";
import OrderSummary from "./modules/setting/components/order/orderSummary"
import Notifications from "./modules/public/pages/notifications/notifications.container.tsx";
import TrialSubCompleted from "./modules/subscription/pages/completed";
import { getLocation } from "./lib/helper/geoLocationHelper.tsx";
var authed = false;
// const HOST = process.env.REACT_APP_API_HOST_URL;
function RequireAuth({ children }) {
  var verified = false;
  const location = useLocation();

  if (localStorage.getItem('user') !== null) { 
    const user = JSON.parse(localStorage.getItem('user'));
    if(user?.access_token){
      authed = user.access_token !== null || user.access_token !== '';
      verified = user.access_token_payload.verified;
      const sessionExpiration = user.session_expiration;

      if(Number(user.access_token_payload.social_info_verified) === 0)  return <Navigate to={routes.PERSONAL_INFO} replace state={{ path: location.pathname }} />
      // session expiration to date time
      var datetime = new Date(0);
      datetime.setUTCSeconds(sessionExpiration)
      if (datetime < Date.now()) return <Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname, search: location.search }} />
    } else {
      return <Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname, search: location.search }} />
    }
  } else {
    return <Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname, search: location.search }} />
  }

  return authed === true ? (
    verified ? children :  <Navigate to={routes.UNVERIFIED} replace state={{ path: location.pathname }} />
  ) : (
    <Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname, search: location.search }} />
  );
}


function FirstAuth() {
  const location = useLocation();

  if (localStorage.getItem('user') !== null) { 
    const user = JSON.parse(localStorage.getItem('user'));
    if(user?.access_token){
      authed = user.access_token !== null || user.access_token !== '';
      const sessionExpiration = user.session_expiration;

      if(user.access_token_payload.social_info_verified === 0)  return <Navigate to={routes.PERSONAL_INFO} replace state={{ path: location.pathname }} />
      // session expiration to date time
      var datetime = new Date(0);
      datetime.setUTCSeconds(sessionExpiration)
      if (datetime < Date.now()) return <Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname, search: location.search }} />
    } else {
      return <Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname, search: location.search }} />
    }
  } else {
    return <Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname, search: location.search }} />
  }

  return (authed === true) ? (
    <Navigate to={routes.PORTAL} replace state={{ path: location.pathname }} />
  ) : (
    <Navigate to={routes.SIGN_IN} replace state={{ path: location.pathname, search: location.search }} />
  );
}


function App() {
  const location = useLocation();

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line
  }, [location]);

  return (
    <Routes>

      <Route path={''} element={<FirstAuth/>}/>

      <Route path={routes.PORTAL} element={<RequireAuth><AdminLayout/></RequireAuth>}>
        <Route index path="*" element={<RequireAuth><Dashboard /></RequireAuth>} />
        <Route path={routes.SETTINGS}>
          <Route path={''} element={<RequireAuth><Setting/></RequireAuth>}/>
          <Route path={routes.SUBSCRIPTION}>
            <Route path={''} element={<RequireAuth><Subscription /></RequireAuth>}/>
            <Route path={routes.PAYMENT} element={<RequireAuth><SubscriptionPayment /></RequireAuth>}/>
            <Route path={routes.CONTACT} element={<RequireAuth><ContactForm /></RequireAuth>}/>
          </Route>
          <Route path={routes.TEST_SUBSCRIPTION}>
            <Route path={''} element={<RequireAuth><TestSubscription /></RequireAuth>}/>
            <Route path={routes.PAYMENT} element={<RequireAuth><TestSubscriptionPayment /></RequireAuth>}/>
            <Route path={routes.CONTACT} element={<RequireAuth><TestContactForm /></RequireAuth>}/>
          </Route>
        </Route>
        <Route  path={routes.DASHBOARD} element={<RequireAuth><Dashboard /></RequireAuth>} />
        <Route path={routes.PROFILE} element={ <RequireAuth><ProfileDetails/></RequireAuth> } />
        <Route path={routes.PROFILE_LIST} element={<RequireAuth><ProfileList/></RequireAuth>}/>
        <Route path={routes.UPDATE_PROFILE} element={ <RequireAuth><ProfileDetails/></RequireAuth> } />
        <Route path={routes.CONTACTS} element={ <RequireAuth><ContactList/></RequireAuth> } />
        <Route path={routes.CAMPAIGNS} element={ <RequireAuth><CampaignList/></RequireAuth> } />
        <Route path={routes.CAMPAIGN}>
          <Route path=":id" >
            <Route path="" element={ <RequireAuth><CampaignDetail/></RequireAuth> }/>
            <Route path={routes.VOUCHER_CREATE} element={<RequireAuth><VoucherCreate/></RequireAuth>}/>
            <Route path={routes.VOUCHER_DETAIL}>
              <Route path={routes.VOUCHER_EDIT} element={<RequireAuth><VoucherEdit/></RequireAuth>}/>
            </Route>
            <Route path={routes.VOUCHER_CODE}>
              <Route path=":voucherUUID">
                <Route path={''} element={<RequireAuth><VoucherCode/></RequireAuth>}/>
                <Route path={routes.VOUCHER_CODE_CREATE} element={<RequireAuth><VoucherCodeCreate/></RequireAuth>}/>
              </Route>
              
            </Route>
          </Route>
        </Route>
        <Route path={routes.NOTIFICATIONS} element={<RequireAuth><Notifications /></RequireAuth>} />
        <Route path={routes.WALLET} element={<RequireAuth><CollectedVoucherPage/></RequireAuth> }/>
        
        <Route path={routes.CONTACT_DETAIL} element={ <RequireAuth><ContactDetail/></RequireAuth> } />
        <Route path={routes.APPOINTMENT} element={ <RequireAuth><Appointment/></RequireAuth> } />
        <Route path={routes.CARDS}>
          <Route path={''} element={<RequireAuth><CardList /></RequireAuth>} />
          <Route path={routes.CART}>
            <Route path={''} element={<RequireAuth><Cart /></RequireAuth>} />
            <Route path={`:cartUUID/${routes.EDIT}`} element={<RequireAuth><EditCart /></RequireAuth>} />
          </Route>
          <Route path={routes.BUY}>
            <Route path={''} element={<RequireAuth><ApplyCard /></RequireAuth>} />
            <Route path={routes.TEMPLATES}>
              <Route path={''} element={<RequireAuth><SelectTemplate /></RequireAuth>} />
              <Route path={':productUUID'} element={<RequireAuth><EditTemplate /></RequireAuth>} />
            </Route>
          </Route>
        </Route>

        <Route path={'order/repaid/:orderUUID'} element={<RequireAuth><OrderSummary/></RequireAuth>}/>
      </Route>
      <Route path={routes.SUBSCRIPTION}>
        <Route path='COMPLETED' element={<RequireAuth><TrialSubCompleted/></RequireAuth>}/>
      </Route>
      <Route path={routes.PAYMENT}>
        <Route path={routes.SUCCESS} element={<RequireAuth><CardSuccess /></RequireAuth>} />
        <Route path={routes.PENDING} element={<RequireAuth><CardPending /></RequireAuth>} />
        <Route path={routes.FAIL} element={<RequireAuth><CardFail /></RequireAuth>} />
      </Route>

      <Route path={routes.WALLET}>
          <Route path=':userVoucherUUID'>
          <Route path={routes.WALLET_REDEEM_VOUCHER} element={ <RedeemVoucher/> } />
          <Route path={routes.WALLET_VALIDATE_VOUCHER} >
            <Route path=":voucherCodeUUID" element={ <ValidateVoucher/> } />
          </Route>
          <Route path={routes.WALLET_VALIDATE_SUCCESS} element={ <ValidateSuccess/> } />
          </Route>
      </Route>

      {/* <Route index path="" element={ <SignIn/> }/> */}
      <Route path={routes.SIGN_IN} element={ <SignIn/> } />
      <Route path={routes.SIGN_UP} element={ <SignUp/> } />

      <Route path={routes.GUEST_SIGN_UP} element={<GuestSignUp/>}/>
      <Route path={routes.PERSONAL_INFO} element={ <PersonalInfo/>}/>
      <Route path={routes.PRICING} element={<Pricing />} />
      <Route path={routes.CONTACT} element={<ContactUs />} />
      <Route path={routes.COMPLETED} element={<Completed/>}/>
      <Route path={routes.EXPIRED} element={<VerifyExpired/>}/>
      <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword/>}/>
      <Route path={routes.CHECK_INBOX} element={<CheckInbox/>}/>
      <Route path={routes.RESET_PASSWORD} element={<ResetPassword/>}/>
      <Route path={routes.RESET_PASSWORD_SUCCESS} element={<Success/>}/>
      <Route path={routes.RESET_PASSWORD_EXPIRED} element={<Expired/>}/>
      <Route path={routes.UNVERIFIED} element={<Unverified/>}/>

      <Route path={routes.OLD_LANDING_PAGE} element={<OldLandingPageLayout from={"legacy"}/>}>
        <Route path="*" element={<Error/>}/>
      </Route>

      <Route path={routes.LANDING_PAGE} element={<LandingPageLayout from={"web"}/>}>
        {/* <Route path={routes.LANDING_PAGE_ID} element={<LandingPage/>}/> */}
        <Route path={routes.LANDING_PAGE_ID}>
          <Route path=""  element={<LandingPage/>}/>  
          <Route path={routes.SHARE_QR} element={<ShareQr/>}/>
          <Route path={routes.EXCHANGE_CONTACT} exact element={<ExchangeContact/>}/>
          <Route path={routes.BOOK_APPOINTMENT} element={<BookAppointment/>}/>
          <Route path={routes.VOUCHER_DETAIL}>
            <Route path='' element={<VoucherDetails/>}/>
            <Route path={routes.COLLECT_VOUCHER_MEMBER_SIGN_IN} element={<VoucherSignIn />}/>
            <Route path={routes.COLLECT_VOUCHER_GUEST_SIGN_UP} element={<VoucherSignUp />}/>
            <Route path={routes.COLLECT_VOUCHER_CONNECTED} element={<VoucherMemberConnected />}/>
            <Route path={routes.COLLECT_VOUCHER_PROFILE_LIST} element={<VoucherProfileOption />}/>
          </Route>
          <Route path={routes.BOOK_APPOINTMENT}>
            <Route path={routes.BOOK_APPOINTMENT_SUMMARY} element={<BookAppointmentSummary/>}/>
            <Route path={routes.BOOK_APPOINTMENT_CONFIRMED} element={<BookAppointmentConfirmed/>}/>
          </Route>
        </Route>
        {/* <Route path={routes.EXCHANGE_CONTACT_CONNECTED} element={<Connected/>}/> */}
        <Route path={routes.EXCHANGE_CONTACT_MEMBER_CONNECTED} element={<MemberConnected/>}/>
        <Route path={routes.EXCHANGE_CONTACT_EXPIRED} element={<ExContactExpired/>}/>
        <Route></Route>
      </Route>
      <Route path={routes.LANDING_PAGE_FROM_CARD} element={<LandingPageLayout from={"card"}/>}>
   
        <Route path={routes.LANDING_PAGE_ID}>
          <Route path="" element={<LandingPage/>}/>
          <Route path={routes.SHARE_QR} element={<ShareQr/>}/>
          <Route path={routes.EXCHANGE_CONTACT} exact element={<ExchangeContact/>}/>
          <Route path={routes.BOOK_APPOINTMENT} element={<BookAppointment/>}/>

          <Route path={routes.VOUCHER_DETAIL}>
            <Route path='' element={<VoucherDetails/>}/>
            <Route path={routes.COLLECT_VOUCHER_MEMBER_SIGN_IN} element={<VoucherSignIn />}/>
            <Route path={routes.COLLECT_VOUCHER_GUEST_SIGN_UP} element={<VoucherSignUp />}/>
            <Route path={routes.COLLECT_VOUCHER_CONNECTED} element={<VoucherMemberConnected />}/>
            <Route path={routes.COLLECT_VOUCHER_PROFILE_LIST} element={<VoucherProfileOption />}/>
          </Route>
         
          <Route path={routes.BOOK_APPOINTMENT}>
            <Route path={routes.BOOK_APPOINTMENT_SUMMARY} element={<BookAppointmentSummary/>}/>
            <Route path={routes.BOOK_APPOINTMENT_CONFIRMED} element={<BookAppointmentConfirmed/>}/>
          </Route>
          
        </Route>
        <Route path={routes.EXCHANGE_CONTACT_MEMBER_CONNECTED} element={<MemberConnected/>}/>
        <Route path={routes.EXCHANGE_CONTACT_EXPIRED} element={<ExContactExpired/>}/>
        <Route></Route>
      </Route>
      <Route path={routes.EXCHANGE_CONTACT_VERIFY_COMPLETED} element={<ExVerifyCompleted/>}/>
      <Route path={routes.EXCHANGE_CONTACT_MEMBER_SIGN_IN} element={<MemberSignIn/>}/>
      <Route path={routes.EXCHANGE_CONTACT_GUEST_SIGN_UP} element={<GuestSignUp/>}/>
      <Route path={routes.EXCHANGE_CONTACT_PERSONAL_INFO} element={<ExContactPersonalInfo/>}/>
      <Route path={routes.EXCHANGE_CONTACT_PROFILE_LIST} element={<RequireAuth><ProfileOption/></RequireAuth>} />
      <Route path={routes.ERROR} element={<Error/>}/>
      <Route path="*" element={<Error/>}/>
      <Route path={routes.ZAKEKE_CUSTOMIZER} element={<Zakeke/>}/>
    </Routes>
  )
}

// function Error(){
//   return (
//     <>
//       <Typography>404 </Typography>
//       <Typography>Page Not Found </Typography>
//     </>
//   );
// }

export default App;
