import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { endLoading, startLoading } from '../../../../../features/loading';
import basicCampaignApi from '../../../../../api/basicCampaign';
import { openErrorDialog } from '../../../../../features/error-dialog';
import { openResponseDialog } from '../../../../../features/response-dialog';
import VoucherDetailsComponent from './voucherDetails.component';
import FileHelper from '../../../../../lib/helper/fileHelper';
import { COLLECT_VOUCHER_MEMBER_SIGN_IN, COLLECT_VOUCHER_PROFILE_LIST } from '../../../../../lib/constants/routes';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function VoucherDetailsContainer() {
  const query = useQuery();
  const landingPage = useSelector((state: any) => state.landingPage);
  const accessPath = landingPage.accessPath;
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { voucherUUID } = useParams();
  const [voucher, setVoucher] = useState({
    banner_image: '',
    brand_name: '',
    description: '',
    start_date_time: '',
    end_date_time: '',
    terms_and_condition: '',
    voucher_title: '',
  });

  const getVoucherSettings = async (voucherUUID: string) => {
    dispatch(startLoading())
    await basicCampaignApi.getVoucherSettings({ voucherUUID }).then(response => {
      if (response.success) {
        FileHelper.getUrl(response.data.banner_image).then(banner_image => setVoucher({
          ...response.data,
          banner_image,
        }));
      } else {
        dispatch(openErrorDialog({
          title: "Voucher Details Error",
          description: response.message
        }))
      }
    }).catch(error => {
      dispatch(openErrorDialog({
        title: "Voucher Details Error",
        description: error.message
      }))
    }).finally(() => {
      dispatch(endLoading())
    })
  }

  const handleCollect = async () => {
    let authed = false;
    if (localStorage.getItem('user') !== null) {
      const user = JSON.parse(localStorage.getItem('user') || '');
      authed = user.access_token !== null || user.access_token !== '';
      const sessionExpiration = user.session_expiration;

      var datetime = new Date(0);
      datetime.setUTCSeconds(sessionExpiration);
      if (datetime < new Date()) authed = false;
    }

    if (id) {
      if (!authed) {
        navigate(COLLECT_VOUCHER_MEMBER_SIGN_IN);
      } else {
        navigate(COLLECT_VOUCHER_PROFILE_LIST);
      }
    }
  }

  useEffect(() => {
    getVoucherSettings(`${voucherUUID}`);

    if (Boolean(query.get('collected'))) {
      let description = 'View this voucher on portal. Please check Privilege → My Vouchers';

      if (Boolean(query.get('new_user'))) {
        description = 'Please check your inbox to verify your email'
      }

      dispatch(openResponseDialog({ title: 'Voucher Collected', description }));
    }

    if (Boolean(query.get('error'))) {
      dispatch(openErrorDialog({
        title: 'Voucher Collection Failed',
        description: 'Something went wrong. Please try again.',
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <VoucherDetailsComponent voucher={voucher} handleBack={() => navigate(`${accessPath}/${id}`)} handleCollect={handleCollect} />
}