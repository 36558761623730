import axios from "axios";

interface Data {
  code: number,
  data: null,
  message: string | null,
  success: boolean
}

const importContact = ({ requestBody, profile_uuid }:{
  requestBody: any,
  profile_uuid: string
}): Promise<Data> => {

  return new Promise((resolve, reject) => {
    axios.post("contact/importContact", requestBody, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      params: { profile_uuid }
    })
      .then(response => resolve(response.data))
      .catch(error => reject(error.response.data));
  })
};

export default importContact;
